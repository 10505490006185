import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import Main from '../../elements/main';
import {FullGrid} from '../../elements/grid';

import {Button} from '../../common/button';
import {SaveIcon, PlusIcon, DeleteIcon, EditIcon} from '../../common/icon';

import { withAuthorisation } from '../../common/authorisation';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, COSMETIC_REGULATIONS } from '../../../services/util';

import {SecondaryAppLargeTitle, SmallBody, SecondaryAppMediumTitle, TertiaryAppSmallTitle, Body, ExtraSmallBody} from '../../common/text'
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormSearchSelect,
	FormInput,
	FormTextArea,
	FormSelect
} from '../../elements/form';

import UploadFiles from '../../common/upload-files';
import {TagInput, Tag} from '../../common/tag';

const colors = require('../../../color.js');


@inject('manageEvidenceStore')
@observer
class ManageEvidence extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let manageEvidenceStore = this.props.manageEvidenceStore;
		if('evidenceId' in this.props){
			manageEvidenceStore.formSync(this.props.evidenceId);
		}
		manageEvidenceStore.fetchEvidenceTypes();
	}

	componentWillUnmount(){
		let manageEvidenceStore = this.props.manageEvidenceStore;
		manageEvidenceStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let manageEvidenceStore = this.props.manageEvidenceStore;
		return (
			<Main>
				<div className="flex flex-1 flex-col"> 
					<div className="flex flex-row mt-8 items-center justify-between">
						<div className="flex">
							<SecondaryAppLargeTitle className="text-nav-active-color">{manageEvidenceStore.editMode ?  translate('superadmin.manage-evidence.edit-title') : translate('superadmin.manage-evidence.new-title')}</SecondaryAppLargeTitle>
						</div>
						<div className="flex justify-end">
							<Button
								width="w-auto"
								height="h-12"
								className="rounded-lg"
								bgColor="pink-coral"
								fetching={manageEvidenceStore.fetching}
								disabled={!manageEvidenceStore.validForm}
								onClick={() => manageEvidenceStore.saveEvidence()}
							>
								<div className="flex flex-row items-center justify-center mx-4">
									<div className="flex"><TertiaryAppSmallTitle>{manageEvidenceStore.editMode ? translate('superadmin.manage-evidence.update-button') : translate('superadmin.manage-evidence.save-new-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						</div>
					</div>
					<FullGrid className="mt-8">
						<div className="flex flex-row">
							<SecondaryAppMediumTitle>{translate('superadmin.manage-evidence.title')}</SecondaryAppMediumTitle>
						</div>
						<div className="flex flex-1 flex-col lg:flex-row mt-4">
							<div className="flex flex-1 grid grid-cols-1 lg:grid-cols-2 gap-4 flex-col">
								<DataRow label={translate('superadmin.manage-evidence.title.label')}>
									<FormInput 
										placeholder={translate('superadmin.manage-evidence.title.placeholder')}
										value={manageEvidenceStore.evidenceData.title.value}
										error={manageEvidenceStore.evidenceData.title.error}
										onChange={(e) => manageEvidenceStore.evidenceData.title.onChange(e.target.value)}
									/>
								</DataRow>
								<DataRow label={translate('superadmin.manage-evidence.code.label')}>
									<FormInput 
										placeholder={translate('superadmin.manage-evidence.code.placeholder')}
										value={manageEvidenceStore.evidenceData.code.value}
										error={manageEvidenceStore.evidenceData.code.error}
										onChange={(e) => manageEvidenceStore.evidenceData.code.onChange(e.target.value)}
									/>
								</DataRow>
								<DataRow label={translate('superadmin.manage-evidence.author.label')}>
									<FormInput 
										placeholder={translate('superadmin.manage-evidence.author.placeholder')}
										value={manageEvidenceStore.evidenceData.author.value}
										error={manageEvidenceStore.evidenceData.author.error}
										onChange={(e) => manageEvidenceStore.evidenceData.author.onChange(e.target.value)}
									/>
								</DataRow>
								<DataRow label={translate('superadmin.manage-evidence.evidence-type.label')}>
									<FormSelect 
										placeholder={translate('superadmin.manage-evidence.evidence-type.placeholder')}
										options={manageEvidenceStore.evidenceTypeOptions}
										value={manageEvidenceStore.evidenceData.selectedEvidenceType.value}
										onChange={(option) => manageEvidenceStore.evidenceData.selectedEvidenceType.onChange(option.value)}
									/>
								</DataRow>
								<DataRow label={translate('superadmin.manage-evidence.evidence-date.label')}>
									<FormInput 
										placeholder={translate('superadmin.manage-evidence.evidence-date.placeholder')}
										value={manageEvidenceStore.evidenceData.evidenceDate.value}
										error={manageEvidenceStore.evidenceData.evidenceDate.error}
										onChange={(e) => manageEvidenceStore.evidenceData.evidenceDate.onChange(e.target.value)}
									/>
								</DataRow>

								<DataRow label={translate('superadmin.manage-evidence.tags.label')}>
									<div className="relative flex w-full flex-col mt-2">
										<div className="flex w-full">
											<TagInput
												 inputValue={manageEvidenceStore.currentTagInput}
												 t={translate}
												 onNewEntry={(tag) => console.log(tag)}
												 onChange={(tags) => manageEvidenceStore.onChangeTags(tags)}
												 onInputChange={(tag) => manageEvidenceStore.onChangeTagInput(tag)}
												 values={manageEvidenceStore.searchTags} 
											/>
										</div>
										{manageEvidenceStore.possibleTags.length > 0 && 
											<div className="absolute cursor-pointer bg-white z-50 mt-12 top-0 left-0 flex flex-col w-full border border-nav-inactive-color mt-2 rounded-lg py-4 px-4">
												{manageEvidenceStore.possibleTags.map((pt) => 
													<div key={pt.id} className="flex py-1" onClick={(e) => {
														e.stopPropagation();
														manageEvidenceStore.onSelectPossibleTag(pt.id)
													}}>
														<SmallBody>{pt.name}</SmallBody>
													</div>
												)}
											</div>
										}
									</div>
								</DataRow>
								<div className="col-span-1 lg:col-span-2">
									<DataRow label={translate('superadmin.manage-evidence.ingredients.label')}>
										<div className="relative flex w-full flex-col mt-2">
											<div className="flex w-full ">
												<TagInput
													placeholder={translate('superadmin.manage-evidence.ingredients.label')}
													 inputValue={manageEvidenceStore.ingredientSearchText}
													 t={translate}
													 onNewEntry={(tag) => console.log(tag)}
													 onChange={(ingredients) => manageEvidenceStore.onChangeIngredients(ingredients)}
													 onInputChange={(tag) => manageEvidenceStore.onChangeIngredientSearchText(tag)}
													 values={manageEvidenceStore.searchIngredients} 
												/>
												{manageEvidenceStore.possibleIngredients.length > 0 && 
														<div className="absolute cursor-pointer bg-white z-50 mt-12 top-0 left-0 flex flex-col w-full border border-nav-inactive-color mt-2 rounded-lg py-4 px-4">
															{manageEvidenceStore.possibleIngredients.map((pi) => 
																<div key={pi.id} className="flex py-1" onClick={(e) => {
																	e.stopPropagation();
																	manageEvidenceStore.onSelectPossibleIngredient(pi.id)
																}}>
																	<SmallBody>{pi.name} ({pi.latin_name})</SmallBody>
																</div>
															)}
														</div>
													}
											</div>
										</div>
									</DataRow>
								</div>
								<div className="col-span-1 lg:col-span-2">
									<DataRow label={translate('superadmin.manage-evidence.description.label')}>
										<FormTextArea
											placeholder={translate('superadmin.manage-evidence.description.placeholder')}
											value={manageEvidenceStore.evidenceData.description.value}
											error={manageEvidenceStore.evidenceData.description.error}
											onChange={(e) => manageEvidenceStore.evidenceData.description.onChange(e.target.value)}
										/>
									</DataRow>
								</div>
								<DataRow label={translate('superadmin.manage-evidence.reference.label')}>
									<FormInput 
										placeholder={translate('superadmin.manage-evidence.reference.placeholder')}
										value={manageEvidenceStore.evidenceData.references.value}
										error={manageEvidenceStore.evidenceData.references.error}
										onChange={(e) => manageEvidenceStore.evidenceData.references.onChange(e.target.value)}
									/>
								</DataRow>
								<DataRow label={translate('superadmin.manage-evidence.page-number.label')}>
									<FormInput 
										type="number"
										placeholder={translate('superadmin.manage-evidence.page-number.placeholder')}
										value={manageEvidenceStore.evidenceData.pageNumber.value}
										error={manageEvidenceStore.evidenceData.pageNumber.error}
										onChange={(e) => manageEvidenceStore.evidenceData.pageNumber.onChange(e.target.value)}
									/>
								</DataRow>
							</div>
							<div className="flex flex-col w-full lg:w-2/5 lg:ml-8">
								{manageEvidenceStore.currentFileUpload == null && 
									<UploadFiles
										t={translate} 
										title={translate('superadmin.manage-evidence.upload.title')}
										uploadFileTypes={['application/pdf']}
										maxFileSize={20}
										uploading={manageEvidenceStore.uploading}
										description={
											(
												<>
													{translate('superadmin.manage-evidence.upload.part1')} 
												</>
											)
										}
										onChangeFiles={(files) => manageEvidenceStore.changeCurrentUploadFile(files)}
										onDeleteFile={() => console.log('delete')}
									 />
								}
								{manageEvidenceStore.currentFileUploadUrl != null && 
									<div className="flex w-full flex-col mt-2">
										<DataHeader noborder>{translate('superadmin.manage-evidence.file.label')}</DataHeader>
										<div className="flex flex-row w-full items-center justify-between mt-2">
											<div className="flex"><a className="text-nav-active-color bold-font" href={manageEvidenceStore.currentFileUploadUrl}>{translate('superadmin.manage-evidence.evidence-file')} </a></div>
											<div className="flex cursor-pointer" onClick={() => manageEvidenceStore.clearUpload()}>
												<DeleteIcon className="w-4 h-4" color={colors['error']} />
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</FullGrid>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(ManageEvidence, [SUPER_ADMINISTRATOR]));