import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import Main from '../../../elements/main';
import {Grid} from '../../../elements/grid';
import {ArrowRightIcon, SaveIcon, TickIcon, WarningIcon} from '../../../common/icon';

import {Button} from '../../../common/button';

import { withAuthorisation } from '../../../common/authorisation';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, LANGUAGES } from '../../../../services/util';

import {SecondaryAppLargeTitle, SmallBody, SecondaryAppMediumTitle,SecondaryAppSmallTitle, TertiaryAppSmallTitle, Body, ExtraSmallBody} from '../../../common/text'

import {
	DataHeader,
	DataRow,
	DataEntry,
	FormSearchSelect,
	FormInput,
	FormTab,
	FormTextArea,
	FormSelect
} from '../../../elements/form';

const colors = require('../../../../color.js');

@observer
class LanguageOption extends Component{
	render(){
		return (
			<div onClick={() => this.props.onToggle(this.props.id)} className={`flex cursor-pointer text-center mr-8 px-24 py-2 w-auto bg-nav-active-color rounded-full ${this.props.active ? 'opacity-100' : 'opacity-50'}`}>
				<TertiaryAppSmallTitle className="text-white">{this.props.text}</TertiaryAppSmallTitle>
			</div>
		)
	}
}

@observer
class WebTranslation extends Component{
	render(){
		let translation = this.props.translation;
		let translate = this.props.t;
		let isSuperAdmin = this.props.isSuperAdmin;
		let textStyle = {}
		if(translation.translation_request != null && translation.translation_request.status === 'denied'){
			textStyle = {
				color: colors['error']
			}
		}
		let fetching = this.props.fetching;
		return (
			<Grid className="mt-8">
				<div className={`flex flex-1 flex-row items-start`}>
					<div className="flex flex-1 flex-col">
						<DataHeader noborder>{translation.translate_key}</DataHeader>
						<DataEntry>{translation.source}</DataEntry>
					</div>
					<div className="flex flex-1 flex-col ml-4">
						<DataHeader noborder>{translate('superadmin.manage-language.translation')}</DataHeader>
						<FormTextArea
							style={textStyle}
							value={translation.translation}
							onChange={(e) => this.props.onChangeTranslation(translation.uuid, e.target.value)}
						/>
					</div>
					{isSuperAdmin && translation.translation_request != null &&
						<div className="flex ml-4 my-auto flex-col items-center justify-center">
							{translation.translation_request.status === 'pending' &&
								<>
									<div className="flex">
										<Button
											width="w-48"
											height="h-12"
											className="px-4 rounded-lg"
											bgColor="approve-green"
											fetching={fetching}
											onClick={() => this.props.onApprove(translation.uuid)}
										>
											<div className="flex flex-row items-center justify-center">
												<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-language.approve-button')} &nbsp;</TertiaryAppSmallTitle></div>
												<div className="flex"><TickIcon className="w-4 h-4" color="white" /></div>
											</div>
										</Button>
									</div>
									<div className="flex mt-2">
										<Button
											width="w-48"
											height="h-12"
											className="px-4 rounded-lg"
											bgColor="pink-coral"
											fetching={fetching}
											onClick={() => this.props.onDecline(translation.uuid)}
										>
											<div className="flex flex-row items-center justify-center">
												<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-language.decline-button')} &nbsp;</TertiaryAppSmallTitle></div>
												<div className="flex"><WarningIcon className="w-4 h-4" color="white" /></div>
											</div>
										</Button>
									</div>
								</>
							}
							{translation.translation_request.status === 'approved' &&
								<div className="flex">
									<Button
										width="w-48"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="approve-green"
										fetching={fetching}
										onClick={() => console.log()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-language.approved-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><TickIcon className="w-4 h-4" color="white" /></div>
										</div>
									</Button>
								</div>
							}
							{translation.translation_request.status === 'denied' &&
								<div className="flex">
									<Button
										width="w-48"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="error"
										fetching={fetching}
										onClick={() => console.log()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-language.not-approve-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><WarningIcon className="w-4 h-4" color="white" /></div>
										</div>
									</Button>
								</div>
							}
						</div>
					}
				</div>
			</Grid>
		)
	}
}

@observer
class EntityToTranslateItem extends Component{
	render(){
		let translate = this.props.t;
		let entityToTranslate = this.props.entityToTranslate;
		let entityName = this.props.entityName;
		let expanded = this.props.expanded;
		let isSuperAdmin = this.props.isSuperAdmin;
		let currentTranslatingEntity = this.props.currentTranslatingEntity;
		if(this.props.hide) return null;
		let isPendingApproval = entityToTranslate?.status === 'pending';
		let isDenied = entityToTranslate?.status === 'denied';
		let isTranslated = entityToTranslate?.status === 'approved';
		let savingEntityTranslation = this.props.savingEntityTranslation;

		return (
			<>
				<Grid className="mt-8">
					<div className={`flex flex-1 flex-row items-start justify-between cursor-pointer`} onClick={() => this.props.onCancelEdit()}>
						<div className="flex flex-1 flex-col">
							<DataHeader noborder>{translate('superadmin.manage-language.translation-name')}</DataHeader>
							<DataEntry>{entityToTranslate.name}</DataEntry>
						</div>
						{expanded && 
							<div className="flex flex-row items-center">
								<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="pink-coral"
										fetching={savingEntityTranslation}
										onClick={(e) => {
											e.stopPropagation(); 
											this.props.onSave(entityToTranslate.id)
										}}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-language.save-translation-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
										</div>
									</Button>
									{isSuperAdmin && currentTranslatingEntity != null && currentTranslatingEntity.translation_request == null && isTranslated && 
										<div className="flex ml-4">
												<Button
													width="w-auto"
													height="h-12"
													className="px-4 rounded-lg"
													bgColor="approve-green"
													fetching={false}
													onClick={(e) => {
														e.stopPropagation(); 
														window.alert('This has already been approved by another user.')
													}}
												>
												<div className="flex flex-row items-center justify-center">
													<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-language.has-translation-button')} &nbsp;</TertiaryAppSmallTitle></div>
													<div className="flex"><TickIcon className="w-4 h-4" color="white" /></div>
												</div>
											</Button>
										</div>
									}
									{isSuperAdmin && currentTranslatingEntity != null && currentTranslatingEntity.translation_request != null &&
										<div className="flex ml-4">
											<Button
													width="w-auto"
													height="h-12"
													className="px-4 rounded-lg"
													bgColor="approve-green"
													fetching={savingEntityTranslation}
													onClick={(e) => {
														e.stopPropagation(); 
														this.props.onApprove(currentTranslatingEntity.translation_request.id)
													}}
												>
												<div className="flex flex-row items-center justify-center">
													<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-language.approve-translation-button')} &nbsp;</TertiaryAppSmallTitle></div>
													<div className="flex"><TickIcon className="w-4 h-4" color="white" /></div>
												</div>
											</Button>
											<div className="flex ml-4">
												<Button
														width="w-auto"
														height="h-12"
														className="px-4 rounded-lg"
														bgColor="error"
														fetching={savingEntityTranslation}
														onClick={(e) => {
															e.stopPropagation(); 
															this.props.onDecline(currentTranslatingEntity.translation_request.id)
														}}
													>
													<div className="flex flex-row items-center justify-center">
														<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-language.decline-translation-button')} &nbsp;</TertiaryAppSmallTitle></div>
														<div className="flex"><WarningIcon className="w-4 h-4" color="white" /></div>
													</div>
												</Button>
											</div>
										</div>
									}
							</div>
						}
						{!expanded && 
							<div className="flex">
								<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor={isPendingApproval ? "ochre-yellow" : (isTranslated ? "success-green" : "dark-error")}
										fetching={false}
										onClick={() => this.props.onEdit(entityToTranslate.id)}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{isPendingApproval ? translate('superadmin.manage-language.pending-approval-button') : (isDenied ? translate('superadmin.manage-language.denied-button') : (isTranslated ? translate('superadmin.manage-language.has-translation-button') : translate('superadmin.manage-language.needs-update-button'))) } &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><WarningIcon className="w-4 h-4" color="white" /></div>
										</div>
									</Button>
							</div>
						}
					</div>
				</Grid>
				{expanded && this.props.expandedView}
			</>
		)
	}
}

@inject('manageLanguageStore')
@observer
class ManageLanguage extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let manageLanguageStore = this.props.manageLanguageStore;

		let language = this.props.language;
		let languageOption = this.props.languageOption;
		let entityKey = this.props.entityKey;
		let translateKey = this.props.translateKey;
		let entityId = this.props.entityId;

		let comingFromANotification = language != null && languageOption != null && (entityKey != null || translateKey != null);

		if(comingFromANotification){
			if(entityKey != null){
				manageLanguageStore.individualEntityFormSync(language, languageOption, entityKey, entityId);
			}
			if(translateKey != null){
				manageLanguageStore.individualWebFormSync(language, languageOption, translateKey);
			}

		}else{
			manageLanguageStore.formSync(this.props.language);
			manageLanguageStore.fetchLanguageEntities()
		}
	}

	componentWillUnmount(){
		let manageLanguageStore = this.props.manageLanguageStore;
		manageLanguageStore.initStore();	
	}

	render(){
		let translate = this.props.t;
		let manageLanguageStore = this.props.manageLanguageStore;
		return (
			<Main>
				<div className="flex flex-col w-full flex-row mt-8">
					<div className="flex flex-1 flex-col">
						<SecondaryAppLargeTitle className="text-nav-active-color">{translate('superadmin.manage-language.title')} {manageLanguageStore.translateToLanguage?.name}</SecondaryAppLargeTitle>
						<SmallBody>{translate('superadmin.manage-language.description.part1')} <br /> {translate('superadmin.manage-language.description.part2')}</SmallBody>
					</div>
					{manageLanguageStore.isPrimaryCopy && manageLanguageStore.webTranslations.length > 0 &&
						<div className="flex w-full flex-row justify-end mt-2">
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="pink-coral"
								fetching={manageLanguageStore.fetching}
								onClick={() => manageLanguageStore.saveWebTranslations()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-language.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						</div>
					}
					<div className="flex flex-row items-center  w-full mt-8">
						<LanguageOption 
							text="Primary Copy"
							id={0} 
							active={manageLanguageStore.isPrimaryCopy}
							onToggle={(id) => manageLanguageStore.setLanguageOption(id)}
							/>
						<LanguageOption 
							text="Subsequent Copy"
							id={1} 
							active={manageLanguageStore.isSubsequentCopy} 
							onToggle={(id) => manageLanguageStore.setLanguageOption(id)}
						/>
					</div>
					{manageLanguageStore.isPrimaryCopy && 
						<div className="flex flex-row justify-between mt-8">
							<FormTab 
								onClick={() => manageLanguageStore.setCurrentPrimarySection(0)} 
								active={manageLanguageStore.currentPrimarySection === 0}>{translate('superadmin.manage-language.website-section')}</FormTab>
							{false && 
								<FormTab 
								onClick={() => manageLanguageStore.setCurrentPrimarySection(1)} 
								active={manageLanguageStore.currentPrimarySection === 1}>{translate('superadmin.manage-language.software-section')}</FormTab>}
						</div>
					}
					{manageLanguageStore.isPrimaryCopy && manageLanguageStore.currentPrimarySection == 0 &&
						<>
							<div className="flex flex-col mt-8 flex-1 w-full">
							{manageLanguageStore.webTranslations.map((translation, idx) => 
								<WebTranslation 
									t={translate} 
									key={translation.uuid} 
									translation={translation} 
									fetching={manageLanguageStore.fetching}
									isSuperAdmin={manageLanguageStore.appStore.isSuperAdministrator}
									onChangeTranslation={(uuid, val) => manageLanguageStore.onChangeWebTranslation(uuid, val)}
									onApprove={(uuid) => manageLanguageStore.onApproveWebTranslation(uuid)}
									onDecline={(uuid) => manageLanguageStore.onDeclineWebTranslation(uuid)}
								/>
							)}
							</div>
						</>
					}
					{manageLanguageStore.isSubsequentCopy &&
						<>
							<div className="flex flex-row overflow-x-scroll mt-8" style={{ maxWidth: '80vw'}}>
								{manageLanguageStore.translatableEntities.map((translatableEntity) => 
									<FormTab 
										extraInputClassNames="px-8"
										onClick={() => manageLanguageStore.setTranslatableEntitySection(translatableEntity.entity_key)} 
										active={manageLanguageStore.currentTranslatingEntityKey === translatableEntity.entity_key}>{translatableEntity.name}</FormTab>
								)}
							</div>
							<div className="flex flex-1 flex-col w-full">
								{manageLanguageStore.sortedCurrentLanguageEntitiesToTranslate.length == 0 && 
									<div className="flex w-full mt-8">
										<SmallBody>{manageLanguageStore.fetching ? translate('common.loading-text'): translate('superadmin.manage-language.no-entities-found')}</SmallBody>
									</div>
								}
								{manageLanguageStore.sortedCurrentLanguageEntitiesToTranslate.map((entityToTranslate) => {
									let currentTranslatingEntity = manageLanguageStore.currentTranslatingEntity;
									let isCurrentEditingEntity = manageLanguageStore.currentTranslatingEntity?.id === entityToTranslate.id;

									let textStyle = {}
									if(manageLanguageStore.currentTranslatingEntity?.translation_request?.status === 'denied'){
										textStyle = {
											color: colors['error']
										}
									}

									return (
										<EntityToTranslateItem 
											entityToTranslate={entityToTranslate}
											key={entityToTranslate.id}
											onEdit={(id) => manageLanguageStore.onEditEntity(id)}
											onCancelEdit={(id) => manageLanguageStore.onCancelEditEntity()}
											onApprove={(translationRequestId) => manageLanguageStore.onApproveEntityTranslation(translationRequestId)}
											onDecline={(translationRequestId) => manageLanguageStore.onDeclineEntityTranslation(translationRequestId)}
											onSave={(id) => manageLanguageStore.onSaveEntityTranslation(id)}
											hide={false}
											expanded={currentTranslatingEntity?.id === entityToTranslate.id}
											isSuperAdmin={manageLanguageStore.appStore.isSuperAdministrator}
											currentTranslatingEntity={currentTranslatingEntity}
											savingEntityTranslation={manageLanguageStore.savingEntityTranslation}
											expandedView={currentTranslatingEntity == null ? null : 
												(
													<div className="flex flex-1 flex-col">
														{currentTranslatingEntity.entity_translations.map((entityTranslation) => 
															<Grid className="mt-8">
																<div className={`flex flex-1 flex-row items-start`}>
																	<div className="flex flex-1 flex-col">
																		<DataHeader noborder>{entityTranslation.key}</DataHeader>
																		<DataEntry>{entityTranslation.source}</DataEntry>
																	</div>
																	<div className="flex flex-1 flex-col ml-4">
																		<DataHeader noborder>{translate('superadmin.manage-language.translation')}</DataHeader>
																		<FormTextArea
																			style={textStyle}
																			value={entityTranslation.translation}
																			onChange={(e) => entityTranslation.translation = e.target.value}
																		/>
																	</div>
																</div>
															</Grid>
														)}
													</div>
												)
											}
											t={translate}
										/>
									)
								})}
							</div>
						</>
					}
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(ManageLanguage, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR], [LANGUAGES]));