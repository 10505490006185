import { observable, action, computed, autorun } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isEmailValidator, isRequiredValidator } from '../../services/validation';
import UserApi from '../../services/user';

class ForgotPasswordStore{
	appStore;

	@observable email;

	userApi; 
	form;
	@observable isValidForm;

	constructor(appStore){
		this.appStore = appStore;
		this.userApi = new UserApi(appStore);
		this.initStore();

	}

	initStore(){
		this.email = new FieldState('').validators((val) => isEmailValidator(val, this.appStore.i18n));
		this.form = new FormState({
			email: this.email,
		});
		this.isValidForm = false;
		this.fetching = false;
	}

	@action onChangeEmail(val){
		this.email.onChange(val);
		this.validateForm();
	}	

	validateForm(){
		this.form.validate()
			.then((res) => {
				this.isValidForm = !res.hasError;
			})
	}

	sendReminder(){
		this.userApi.forgotPassword(this.email.value)
			.then((response) => {
				this.appStore.alertSuccess(this.appStore.i18n.t('success.check-reset-email-message'));
				this.appStore.goToLogin();
			})
			.catch((error) => {
				console.log(error);
			})
	}
}

export default ForgotPasswordStore;
