import { observable, action, computed } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isRequiredValidator, isHexColorValidator, isNotNullValidator } from '../../../services/validation';

import CommonApi from '../../../services/common';
import AcidApi from '../../../services/acid';
import S3Api from '../../../services/s3';

class ManageAcidComponent{
	appStore;

	id;

	@observable editMode;

	@observable componentData;

	@observable fetching;

	@observable imageFileUrl;
	@observable imageFile;

	@observable changedPhoto;

	@observable acidFamilies;

	commonApi;
	s3Api;


	constructor(appStore){
		this.appStore = appStore
		this.commonApi = new CommonApi(this.appStore);
		this.acidApi = new AcidApi(this.appStore);
		this.s3Api = new S3Api(this.appStore);
		this.initStore();
	}

	initStore(){
		this.componentData = {
			name: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			selectedFamily: new FieldState(null).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			bio: new FieldState(null)
		}
		this.componentDataForm = new FormState({
			name: this.componentData.name,
			selectedFamily: this.componentData.selectedFamily,
			bio: this.componentData.bio
		})
		this.editMode = false;
		this.fetching = false;
		this.imageFileUrl = null;
		this.imageFile = null;
		this.id = null;
		this.acidFamilies = [];
	}

	fetchAcidFamilies = async () => {
		try{
			this.acidFamilies = await this.acidApi.getAcidFamilies();
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get acidFamilyOptions(){
		return this.acidFamilies.map((cf) => {
			return {
				value: cf.id,
				label: cf.name
			}
		})
	}

	@action changeMolecularImage(file){
		this.imageFile = file;
		this.imageFileUrl = URL.createObjectURL(file);
		this.changedPhoto = true;
	}

	@action deleteMolecularImage(){
		this.imageFileUrl = null;
		this.imageFile = null;
		this.changedPhoto = true;
	}

	@computed get validForm(){
		let requiredFields = ['name', 'selectedFamily'];
		let isValidForm = true;
		for(let field of requiredFields){
			if(this.componentData[field].value == null || this.componentData[field].value.length == 0){
				isValidForm = false;
			}else{
				let fieldError = this.componentData[field].error;
				isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
			}
		}
		return isValidForm;
	}

	formSync(id){
		this.acidApi.getAcidComponentById(id)
			.then((response) => {
				let acidComponent = response;
				this.editMode = true;
				this.id = id;
				this.componentData.name.value = acidComponent.name;
				this.componentData.selectedFamily.value = acidComponent.acid_family.id;
				this.componentData.bio.value = acidComponent.bio;
				this.imageFileUrl = acidComponent.photo_url;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	handleSuccess(response){
		let signedUrlPayload = response.presigned_url;
		if(signedUrlPayload == null){
			this.appStore.displaySaveDialog();
			this.appStore.goToChemistrySettings();
			this.fetching = false;
		}else{
			this.s3Api.uploadToS3(this.imageFile, signedUrlPayload)
				.then((response) => {
					this.appStore.displaySaveDialog();
					this.appStore.goToChemistrySettings();
				})
				.catch((error) => {
					this.appStore.alertError(this.appStore.i18n.t('error.uploading-photo-message'));
				})
				.finally(() => {
					this.fetching = false;
				});
		}
	}

	saveAcidComponent = async () => {
		let res = await this.componentDataForm.validate()
		if(res.hasError) return;

		this.fetching = true;
		
		if(this.editMode){
			let acidComponentPayload = {
				name: this.componentData.name.value,
				acid_family_id: this.componentData.selectedFamily.value,
				bio: this.componentData.bio.value,
				has_photo: this.imageFile != null,
				changed_photo: this.changedPhoto,
				file_type: this.imageFile != null ? this.imageFile.type : null
			}
			this.acidApi.updateAcidComponent(this.id, acidComponentPayload)
				.then((response) => {
					this.handleSuccess(response);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}else{
			let acidComponentPayload = {
				name: this.componentData.name.value,
				acid_family_id: this.componentData.selectedFamily.value,
				bio: this.componentData.bio.value,
				has_photo: this.imageFile != null,
				file_type: this.imageFile != null ? this.imageFile.type : null
			}
			this.acidApi.newAcidComponent(acidComponentPayload)
				.then((response) => {
					this.handleSuccess(response)
				})
				.catch((error) => {
					this.fetching = false;
				})
				.finally(() => {
					this.fetching = false;
				})
		}

		
	}
}

export default ManageAcidComponent;