import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';

import { PDFDownloadLink, BlobProvider, Font, StyleSheet } from '@react-pdf/renderer';
import { DownloadIcon} from './icon';
import {ToolTipItem} from './input';

import {TertiaryAppSmallTitle} from './text'
import printJS from 'print-js'

import japaneseFont from '../../assets/fonts/keifont.ttf';

const colors = require('../../color.js');

@inject('appStore')
@observer
class DownloadPDF extends Component{
	constructor(props){
		super(props);
		this.state = {
			showDialog: false
		}
		this.toggleDialog = this.toggleDialog.bind(this);
    this.handleOnDownload = this.handleOnDownload.bind(this);
	}


	componentDidMount(){
    let isJapanese = 'isJapanese' in this.props ? this.props.isJapanese : false;

    if(isJapanese){
      Font.register({
          family: "Proxima Nova",
          fonts: [
              {src: japaneseFont, format: "truetype"},
              {src: japaneseFont, format: "truetype", fontStyle: 'bold', fontWeight: 700}, 
              {src: japaneseFont, format: "truetype", fontStyle: 'bold', fontWeight: 400}, 
              {src: japaneseFont, format: "truetype", fontStyle: 'italic'} 
          ]
      })
    }else{
      Font.register({
          family: "Proxima Nova",
          fonts: [
              {src: "https://labaroma-production.s3-eu-west-1.amazonaws.com/fonts/ProximaNovaAlt-Regular.woff"}, 
              {src: "https://labaroma-production.s3-eu-west-1.amazonaws.com/fonts/ProximaNovaAlt-Bold.woff", fontStyle: 'bold', fontWeight: 700}, 
              {src: "https://labaroma-production.s3-eu-west-1.amazonaws.com/fonts/ProximaNovaAlt-Semibold.woff", fontStyle: 'bold', fontWeight: 400}, 
              {src: "https://labaroma-production.s3-eu-west-1.amazonaws.com/fonts/ProximaNovaAlt-Regular-Italic.woff", fontStyle: 'italic'} 
          ]
      })
    }
	}

	toggleDialog(){
		this.setState({
			showDialog: !this.state.showDialog
		})
	}

  handleOnDownload(url){
    let showModal = 'showModal' in this.props;
    if(showModal){
      this.props.onShowModal('download');
    }else{
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        this.props.filename,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  handleOnPrint(blobUrl){
    let showModal = 'showModal' in this.props;
    if(showModal){
      this.props.onShowModal('print');
    }else{
      printJS(blobUrl);
    }
  }

	render(){
    let appStore = this.props.appStore;
		let translate = this.props.t;
		return (
			<div className="relative" onClick={this.toggleDialog} >
        <div className="flex flex-row items-center">
          {!this.state.showDialog && 
            <ToolTipItem 
              text={appStore.getTooltip('tooltip-blend-download-and-print')}
              icon={<DownloadIcon className="w-4 h-4" />}
            />
          }
          {this.state.showDialog && <DownloadIcon className="w-4 h-4" /> }
        </div>
				{this.state.showDialog && 
            <div className="absolute z-50 top-0 right-0 shadow-lg bg-white px-4 py-2 rounded-lg mt-4 w-48 h-auto flex flex-col">						
               {this.props.document == null && 
                   <div className="flex flex-col w-full">    
                        <div className="flex mt-2">
                          <TertiaryAppSmallTitle>{translate('common.preparing-pdf')}</TertiaryAppSmallTitle>
                        </div>
                    </div>
               }
               {this.props.document != null &&
               <BlobProvider document={this.props.document}>
                  {({ blob, url, loading, error }) => {
                    if(blob == null || loading){
                      return (
                        <div className="flex flex-col w-full">    
                            <div className="flex mt-2">
                              <TertiaryAppSmallTitle>{translate('common.loading-pdf')}</TertiaryAppSmallTitle>
                            </div>
                        </div>
                      );
                    }
                    const blobUrl = url;
                    
                    return (
                      <div className="flex flex-col w-full">    
                        <div className="flex mt-2" onClick={(e) => {
                           e.stopPropagation();
                           this.handleOnDownload(blobUrl);
                        }}>
                          <TertiaryAppSmallTitle>{translate('common.download')}</TertiaryAppSmallTitle>
                        </div>
                        <div className="flex mt-2" onClick={(e) => {
                          e.stopPropagation();
                          this.handleOnPrint(blobUrl);
                        }}>
                          <TertiaryAppSmallTitle>{translate('common.print')}</TertiaryAppSmallTitle>
                        </div>
                      </div>
                  )}}
                </BlobProvider>
              }
               
					  </div>
				}
			</div>
		)
	}
}

const pdfStyles = StyleSheet.create({
  page: {
  	flex:1,
    fontFamily: 'Proxima Nova',
    backgroundColor: '#EFF3F1',
    padding: 25
  },
  row: {
  	flexDirection: 'row',
  	marginTop: 20,
    marginRight: 5
  },
  rowBetween:{
  	justifyContent: 'space-between',
  	flexDirection: 'row',
  	marginTop: 20,
    marginRight: 5
  },
  rowBetweenAlignCenter:{
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 20,
  },
  rowBetweenNoMargin:{
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row',
    flex:1
  },
  columnFull:{
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flexDirection: 'column',
    flex:1
  },
  fullRow:{
  	flexDirection: 'row', 
  	justifyContent: 'space-between', 
  	flex: 1
  },
  gridColumn: {
  	flex:1,
  	flexDirection: 'column'
  },
  marginBottom: {
    marginBottom: 10
  },
  column:{
    flexDirection: 'column'
  },
  columnWithMarginBottom:{
    flexDirection: 'column',
    marginBottom: 10
  },
  columnWithSmallMarginTop:{
    flexDirection: 'column',
    marginTop: 5
  },
  container: {
  	margin: 20,
    flex: 1,
    flexDirection: 'column'
  },
  title: {
  	fontSize: 10,
  	fontWeight: 700,
  	fontStyle: 'bold',
  	letterSpacing: 1.5
  },
  italic: {
    fontStyle: 'italic'
  },
  borderBottom: {
  	borderColor: colors['nav-inactive-color'],
  	paddingBottom:5,
  	borderBottomWidth: 1, 
  },
  tableTitle: {
  	fontSize: 18,
  	fontWeight: 700,
  	fontStyle: 'bold'
  },
  datapointTitle: {
    fontSize: 12,
    fontWeight: 700,
    fontStyle: 'bold',
    marginRight: 5
  },
  datapoint: {
  	fontSize: 10,
  	fontWeight: 700,
  	fontStyle: 'bold',
    marginRight: 5
  },
  smalldatapoint: {
  	fontSize: 10,
  	fontWeight: 700,
  	fontStyle: 'bold'
  },
  date:{
    fontSize: 10,
    fontWeight: 700,
    fontStyle: 'bold',
    color: colors['nav-inactive-color']
  },
  logo:{
  	width: 80,
  	height: 12
  },
  image: {
  	width: 400,
  	height:400
  },
  marginTop: {
  	marginTop: 20
  },
  smallMarginTop: {
  	marginTop: 10
  },
  marginLeft: {
  	marginLeft: 20
  },
  pieChartImage: {
  	width: 300,
  	height: 300
  },
  image: {
  	width: 200,
  	height: 200
  },
  list: {
  	flexDirection: 'column',
  	flex:1
  },
  circle: {
  	width: 10, 
  	height: 10, 
  	borderTopLeftRadius: 5, 
  	borderTopRightRadius:5, 
  	borderBottomRightRadius: 5, 
  	borderBottomLeftRadius: 5 
  },
  listItem: {
  	flexDirection: 'row',
  	alignItems: 'center',
  	justifyContent: 'space-between',
  	marginTop: 10
  },
  tableListItem: {
  	flexDirection: 'row',
  	alignItems: 'center',
  	justifyContent: 'space-between',
  	marginTop: 10,
  	borderColor: colors['nav-inactive-color'],
  	paddingBottom:5,
  	borderBottomWidth: 1, 
  },
  box: {
  	width: 10, 
  	height:10, 
  	borderTopLeftRadius: 2, 
  	borderTopRightRadius: 2, 
  	borderBottomLeftRadius: 2, 
  	borderBottomRightRadius: 2,
  }
});

export {
	DownloadPDF,
	pdfStyles
}