import BaseApi from '../services/base';

class SearchApi extends BaseApi{
	constructor(props){
		super(props);
	}

	search(payload){
		return this.fetch(`v1/search`, {
			method: 'POST',
			auth:true,
			body: JSON.stringify(payload)
		})
	}

}

export default SearchApi;