import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import { DataHeader, DataEntry} from '../elements/form';
import { SmallBody} from '../common/text';

@observer
class ChemicalFamilyDialog extends Component{
	render(){
		let chemicalFamily = this.props.chemicalFamily;
		let currentChemicalFamilyComponents = this.props.currentChemicalFamilyComponents;
		return (
	    	<div className="flex w-full flex-col overflow-y-scroll" style={{backgroundColor: chemicalFamily.hex_color}}>
	        	<div className="flex flex-col w-full mt-4 px-8 py-8 mt-8 overflow-y-scroll">
	        		<div className="flex flex-col"><DataHeader>{chemicalFamily.name}</DataHeader></div>
	        		<div className="flex flex-col mt-4"><SmallBody>{chemicalFamily.bio}</SmallBody></div>
	        		{chemicalFamily.molecular_name && 
	        			<div className="flex  flex-col  mt-4"><DataHeader noborder>{chemicalFamily.molecular_name}</DataHeader></div>
	        		}
	        		{chemicalFamily.photo_url != null &&
    					<div className="flex flex-col mt-8 w-full items-center">
        					<div className="flex flex-col w-1/2">
        						<img src={chemicalFamily.photo_url} alt="Family Structure" />
        					</div>
        				</div>
	        		}
	        	</div>
	    	</div>
		)
	}
}

export default ChemicalFamilyDialog;