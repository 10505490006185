import { observable, action, computed, extendObservable } from 'mobx';

import BlendApi from '../../../../services/blend';
import LibraryApi from '../../../../services/library';

import moment from 'moment';

class BlendLibraryStore{

	@observable blends;
	@observable showArchived;

	@observable showSortDialog;
	@observable showFilterDialog;
	@observable sortBy;
	@observable currentFilterValue;
	@observable currentFilters;
	@observable searchTagFilters;

	@observable showPublicLibrary;
	@observable libraryCategoryId;
	@observable currentLibraryCategory;

	@observable fetching;

	@observable page;
	@observable hasMore;

	blendApi;
	libraryApi;

	constructor(appStore){
		this.appStore = appStore;
		this.blendApi = new BlendApi(appStore);
		this.libraryApi = new LibraryApi(appStore);
		this.initStore();
	}

	initStore(){
		this.blends = [];
		this.showArchived = false;
		this.showSortDialog = false;
		this.showFilterDialog = false;
		this.sortBy = null;
		this.currentFilterValue = '';
		this.currentFilters = [];
		this.searchTagFilters = [];
		this.showPublicLibrary = false;
		this.libraryCategoryId = null;
		this.currentLibraryCategory = null;
		this.fetching = false;
		this.hasMore = true;
		this.page = 1;
	}

	@action onNewBlend(){
		this.appStore.goToNewBlend();
	}	

	computeBlendComplianceStatus(blendId){
		this.blendApi.getBlendComplianceStatusById(blendId)
			.then((response) => {
				let existingBlendIdx = this.blends.findIndex((b) => b.id == blendId);
				if(existingBlendIdx != -1){
					this.blends[existingBlendIdx] = extendObservable(this.blends[existingBlendIdx], {
						is_compliant:response.is_compliant
					});
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	fetchBlends(reset=false){
		let filterBy = this.searchTagFilters != null && this.searchTagFilters.length > 0 ? {
			'tags': this.searchTagFilters.map((f) => f.value)
		} : null;

		this.fetching = true;
		this.blendApi.getAllBlends(this.page, null, this.sortBy, filterBy, null, this.showArchived, this.showPublicLibrary, this.libraryCategoryId)
			.then((response) => {
				response.blends.map((blend) => {
					this.computeBlendComplianceStatus(blend.id)
				})
				this.blends = reset ? response.blends.map((b) => this.toBlend(b)) : this.blends.concat(response.blends.map((b) => this.toBlend(b)));
				this.hasMore = response.blends.length > 0;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	computeBlendComplianceStatus(blendId){
		this.blendApi.getBlendComplianceStatusById(blendId)
			.then((response) => {
				let existingBlendIdx = this.blends.findIndex((b) => b.id == blendId);
				if(existingBlendIdx != -1){
					this.blends[existingBlendIdx] = extendObservable(this.blends[existingBlendIdx], {
						is_compliant:response.is_compliant
					});
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	fetchMyLatestBlends(){
		this.fetching = true;
		this.blendApi.getAllBlends(1, null, null, null, null, null, false, null, null, 5)
			.then((response) => {
				let latestBlends = response.blends;
				latestBlends.map((b) => {
					this.computeBlendComplianceStatus(b.id);
				})
				this.blends = latestBlends.map((b) => this.toBlend(b))
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	toBlend(b){
		b.last_edited_at = moment(b.last_edited_at).tz(this.appStore.timezone);
		return b;
	}

	@action onEditBlend(id){
		this.appStore.goToEditBlend(id, true);
	}

	@action onViewBlend(id){
		this.appStore.goToEditBlend(id, false);
	}

	@action toggleArchived(){
		this.showArchived = !this.showArchived;
		this.resetFetchBlends();
	}

	onArchiveBlend(id){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-blend-library.archive-confirm'));
		if(confirmed){
			this.onToggleArchivedBlend(id);
		}
	}

	onRestoreBlend(id){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-blend-library.restore-confirm'));
		if(confirmed){
			this.onToggleArchivedBlend(id);
		}
	}

	@action onToggleArchivedBlend(id){
		let blendIdx = this.blends.findIndex((i) => i.id == id);
		if(blendIdx != -1){
			let blend = this.blends[blendIdx];
			this.fetching = true;
			this.blendApi.patchBlend(blend.id, {
				archived: !blend.archived
			})
			.then((response) => {
				let updatedBlend = response.blend;
				this.blends[blendIdx].archived = updatedBlend.archived;
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.fetching = false;
			})
		}
	}

	onDuplicateBlend(blendId){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-blend-library.duplicate-confirm'));
		if(confirmed){
			let blendIdx = this.blends.findIndex((i) => i.id == blendId);
			if(blendIdx != -1){
				this.fetching = true;
				this.blendApi.duplicateBlend(blendId)
					.then((response) => {
						this.blends.splice(blendIdx, 0, this.toBlend(response.blend));
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.fetching = false;
					})
			}
		}
	}

	@action toggleSortDialog(){
		this.showSortDialog = !this.showSortDialog;
		this.showFilterDialog = false;
	}

	@action setSortByField(val){
		this.sortBy = val;
	}

	@action applySort(){
		this.showSortDialog = false;
		this.resetFetchBlends();
	}

	@computed get sortOptions(){
		if(this.appStore == null) return [];
		return [
			{
				label: this.appStore.i18n.t("user.my-blend-library.sortoptions.last_edited"),
				value: "last_edited"
			},
			{
				label: this.appStore.i18n.t("user.my-blend-library.sortoptions.name"),
				value: "name"
			},
			{
				label: this.appStore.i18n.t("user.my-blend-library.sortoptions.blend_category"),
				value: "blend_category"
			}
		]
	}

	@action toggleFilterDialog(){
		this.showFilterDialog = !this.showFilterDialog;
		this.showSortDialog = false;
	}

	resetFilterValue(){
		this.currentFilterValue = '';
	}

	@action newFilterEntry(val){
		let strippedVal = val.trim();
		if(!this.currentFilters.filter((x) => x.value).includes(strippedVal)){
			this.currentFilters.push({
				label: val,
				value: val
			});
		}
		this.resetFilterValue();
	}

	@action setFilters(values){
		this.currentFilters = values != null ? values : [];
	}

	@action setCurrentFilterValue(val){
		this.currentFilterValue = val;
	}

	@action applyFilters(){
		this.searchTagFilters = this.currentFilters;
		this.showFilterDialog = false;
		this.resetFetchBlends();
	}

	onLoad(showPublic, libraryCategoryId=null){
		this.showPublicLibrary = showPublic;
		this.libraryCategoryId = libraryCategoryId;
		if(this.libraryCategoryId != null){
			this.fetchLibraryCategory();
		}
		this.fetchBlends();
	}

	fetchLibraryCategory(){
		this.libraryApi.getLibraryCategoryById(this.libraryCategoryId)
			.then((response) => {
				this.currentLibraryCategory = response;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get canViewCompliance(){
		let currentUser = this.appStore.currentUser;
		if(currentUser == null) return false;
		return currentUser.is_pro_user;
	}

	hasEditAccessToBlend(blend){
		if(blend.public){
			if(this.appStore.isSuperAdministrator){
				return true;
			}else{
				return false;
			}
		}else{
			let currentUser = this.appStore.currentUser;
			return blend.user_id == currentUser.id;
		}
	}

	@computed get showAvailability(){
		return this.appStore.isSuperAdministrator;
	}

	onPublicBlend(id){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-blend-library.public-confirm'));
		if(confirmed){
			this.onTogglePublicBlend(id);
		}
	}

	@action onTogglePublicBlend(id){
		let blendIdx = this.blends.findIndex((i) => i.id == id);
		if(blendIdx != -1){
			let blend = this.blends[blendIdx];
			this.fetching = true;
			this.blendApi.patchBlend(blend.id, {
				public: !blend.public
			})
			.then((response) => {
				let updatedBlend = response.blend;
				this.blends[blendIdx].public = updatedBlend.public;
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.fetching = false;
			})
		}
	}

	@action resetFetchBlends(){
		this.page = 1;
		this.fetchBlends(true);
	}

	@action loadNextPage(){
		this.page += 1;
		this.fetchBlends();
	}
}

export default BlendLibraryStore;