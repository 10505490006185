import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {inject, observer} from 'mobx-react';

import Main from '../../elements/main';
import { SmallBody, Body, SecondaryAppLargeTitle, SecondaryAppExtraSmallTitle, SecondaryAppMediumTitle, TertiaryAppSmallTitle, TertiaryAppMediumTitle} from '../../common/text';
import { ArrowRightIcon, SearchIcon, PlusIcon, SaveIcon, CompareIngredients, ChemicalComponentSearchIcon} from '../../common/icon'
import {FullGrid, Grid} from '../../elements/grid';
import {SortDialog, SortingField} from '../../elements/list';
import {Button} from '../../common/button';
import BaseModal from '../../common/modal';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormSearchSelect,
	FormInput,
	FormTextArea,
	FormSelect
} from '../../elements/form';

import {IngredientBreakdown} from '../ingredients/comparison/manage-tool';

import InfiniteScroll from 'react-infinite-scroll-component';

const colors = require('../../../color.js');

const SPECIAL_FORM_INPUT_CLASS_NAME = "h-8 w-16 overflow-hidden text-sm bg-white border border-black-10 rounded-md text-center items-center"

@observer
class ComponentSearchItem extends Component{
	render(){
		let translate = this.props.t;
		let searchEntry = this.props.searchEntry;
		return (
			<div className="grid w-full xl:w-1/2 grid-cols-1 lg:grid-cols-2 col-gap-8 row-gap-4 mt-8">
				<div className="col-span-1 lg:col-span-2">
					<DataHeader noborder>{translate('user.component-search.chemical-component.label')}</DataHeader>
					<FormSearchSelect
						placeholder={translate('user.component-search.chemical-component.placeholder')}
						value={searchEntry.componentSearchText.value}
						hasSelection={searchEntry.selectedComponent.value != null}
						onChange={(val) => this.props.onChangeComponentName(searchEntry.uuid, val)}
						onClear={() => this.props.onClear(searchEntry.uuid)}
						options={searchEntry.componentOptions.value}
						onSelection={(componentId) => this.props.onSelection(searchEntry.uuid, componentId)}
					 />
				</div>
				<div className="col-span-1">
					<FormSelect 
						placeholder={translate('user.component-search.comparison-placeholder')}
						options={this.props.comparisonOptions}
						value={searchEntry.selectedComparison.value}
						onChange={(option) => searchEntry.selectedComparison.onChange(option.value)}
					/>
				</div>
				<div className="flex flex-row items-center col-span-1">
					<FormInput 
						placeholder={translate('user.component-search.amount.placeholder')}
						value={searchEntry.amount.value}
						error={searchEntry.amount.error}
						onChange={(e) => searchEntry.amount.onChange(e.target.value)}
					/>
					<div className="flex ml-4">
						<SmallBody>{translate('user.component-search.percentage-of')}</SmallBody>
					</div>
				</div>
			</div>
		)
	}
}

@inject('componentSearchStore')
@observer
class ComponentSearch extends Component{
	constructor(props){
		super(props);
		this.performSearch = this.performSearch.bind(this);
		this.resultsRef = null;
	}

	componentDidMount(){
		let componentSearchStore = this.props.componentSearchStore;

		if(this.props.componentSearchId && this.props.editMode){
			componentSearchStore.formSync(this.props.componentSearchId);
		}
	}

	componentWillUnmount(){
		let componentSearchStore = this.props.componentSearchStore;
		componentSearchStore.initStore();
	}

	performSearch(){
		let componentSearchStore = this.props.componentSearchStore;
		componentSearchStore.freshSearchForIngredients();

		setTimeout(() => {
			if(this.resultsRef != null){
				this.resultsRef.scrollIntoView({ behavior: 'smooth' })
			}
		}, 1000);
	}

	render(){
		let translate = this.props.t;
		let componentSearchStore = this.props.componentSearchStore;
		return (
			<Main>
				<div className="flex max-w-full flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.component-search.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.component-search.paragraph.part1')} </SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={() => window.open('http://help.labaroma.com/en/articles/4745438-9-chemical-component-search-tutorial', '_blank')}>
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.component-search.video-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex hidden lg:block ml-4">
							<ChemicalComponentSearchIcon className="w-48 h-32" />
						</div>
					</div>
					<FullGrid className="mt-8">
						<div className="flex  flex-col">
							{componentSearchStore.searchCriteria.map((searchEntry, idx) => 
								<div className="flex flex-row w-full">
									<ComponentSearchItem 
										key={searchEntry.uuid}
										searchEntry={searchEntry}
										onChangeComponentName={(uuid, val) => componentSearchStore.onChangeComponentName(uuid, val)}
										onClear={(uuid) => componentSearchStore.onClear(uuid)}
										comparisonOptions={componentSearchStore.comparisonOptions} 
										onSelection={(uuid, componentId) => componentSearchStore.onSelection(uuid, componentId)}
										t={translate} 
									/>
								</div>
							)}
							<div className="flex flex-col lg:flex-row items-center mt-8">
								<div className="flex flex-row items-center">
									<div className="flex"><PlusIcon className="w-4 h-4" color="black" /></div>
									<div className="flex cursor-pointer" onClick={() => componentSearchStore.addSearchCriteriaEntry()}><TertiaryAppSmallTitle>{translate('user.component-search.add-button')}</TertiaryAppSmallTitle></div>
								</div>
								<div className="flex flex-1 justify-end items-end mt-8 lg:mt-0">
									<div className="flex">
										<Button
											width="w-auto"
											height="h-12"
											className="px-4 rounded-lg"
											bgColor="pink-coral"
											fetching={componentSearchStore.fetching}
											onClick={() => this.performSearch()}
										>
											<div className="flex flex-row items-center justify-center" onClick={() => console.log()}>
												<div className="flex"><TertiaryAppSmallTitle>{translate('user.component-search.search-button')} &nbsp;</TertiaryAppSmallTitle></div>
												<div className="flex"><SearchIcon className="w-4 h-4" color="white" /></div>
											</div>
										</Button>
									</div>

									{componentSearchStore.searchCriteriaLength > 0 &&
										<div className="flex ml-4">
											<Button
												width="w-auto"
												height="h-12"
												className="px-4 rounded-lg"
												bgColor="pink-coral"
												fetching={componentSearchStore.fetching}
												onClick={() => componentSearchStore.displaySaveSearchModal()}
											>
												<div className="flex flex-row items-center justify-center" onClick={() => console.log()}>
													<div className="flex"><TertiaryAppSmallTitle>{translate('user.component-search.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
													<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
												</div>
											</Button>
										</div>
									}

								</div>
							</div>
						</div>							
					</FullGrid>
					<div className="flex flex-1 flex-col" ref={(ref) => this.resultsRef=ref }>
						{componentSearchStore.showSearchResults && 
							<>
								<div className="flex w-full justify-end mt-8">
										<div className="relative flex flex-row items-center">
											<SortingField 
												text={translate('user.component-search.sort')}
												onClick={() => componentSearchStore.toggleSortDialog()}
											/>
											{componentSearchStore.showSortDialog && 
													<SortDialog
													 	t={translate}
														sortOptions={componentSearchStore.sortOptions}
														currentOption={componentSearchStore.sortBy}
														onOption={(val) => componentSearchStore.setSortByField(val)}
														onApply={(val) => componentSearchStore.applySort()}
													/>
												}
										</div>
								</div>
								{componentSearchStore.compareIngredients.length > 0 && 
									<div className="flex flex-row w-full justify-end mt-8">
										<>
											<Button
												width="w-auto"
												height="h-12"
												className="px-4 rounded-lg"
												bgColor="pink-coral"
												fetching={componentSearchStore.fetching}
												onClick={() => componentSearchStore.displaySaveModal()}
											>
												<div className="flex flex-row items-center justify-center">
													<div className="flex"><TertiaryAppSmallTitle>{translate('user.comparison-tool.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
													<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
												</div>
											</Button>
										</>
									</div>
								}
								<div className="flex flex-row overflow-x-scroll mt-16" style={{ width: '70vw'}}>
									{componentSearchStore.compareIngredients.map((ingredient) => 
										<IngredientBreakdown
											t={translate}
											key={ingredient.id}
											ingredient={ingredient}
											editMode={true}
											measurementOptions={componentSearchStore.measurementOptions}
											onToggleFavourite={(id) => componentSearchStore.toggleIngredientFavourite(id)}
											onRemove={(id) => componentSearchStore.removeComparison(id)}
											onChangeBlendSearchText={(id, val) => componentSearchStore.onChangeBlendSearchText(id, val)}
											onClearSelectedBlend={(id) => componentSearchStore.onClearSelectedBlend(id)}
											onBlendSelection={(id, blendId) => componentSearchStore.onBlendSelection(id, blendId)}
											addIngredientToBlend={(id, blendId) => componentSearchStore.addIngredientToBlend(id, blendId)}
											addIngredientToNewBlend={(id) => componentSearchStore.addIngredientToNewBlend(id)}
										/>
									)}
								</div>

								<div className="flex flex-1 flex-col">
									<InfiniteScroll
										  dataLength={componentSearchStore.sortedIngredients.length}
										  next={() => componentSearchStore.loadNextPage()}
										  hasMore={componentSearchStore.hasMore}
										  loader={null}
										  endMessage={null}
									>
										{componentSearchStore.sortedIngredients.map((ingredient) => {
											let ingredientName = ingredient.name;
											if(ingredient.latin_name != null) {
												ingredientName = `${ingredientName} (${ingredient.latin_name})`
											}
											if(ingredient.origin != null){
												ingredientName = `${ingredientName} - ${ingredient.origin}`	
											}
											return (
												<Grid className="mt-8">
												<div className="flex grid grid-cols-1 lg:grid-cols-2 gap-8">
													<div className="flex">
														<DataRow includeBorder label={ingredientName}>
															<div className="flex flex-col">
																{ingredient.searchComponents.map((searchComponent) => 
																	<div className="flex mt-1">
																		<DataEntry>{searchComponent.chemical_component.name} {searchComponent.amount}%</DataEntry>
																	</div>
																)}
															</div>
														</DataRow>

													</div>
													<div className="grid grid-cols-3 gap-2 flex flex-row items-center">
														<div className="col-span-3 lg:col-span-2">
															<FormSearchSelect
																placeholder={translate('user.component-search.add-to-blend.placeholder')}
																value={ingredient.blendSearchText.value}
																hasSelection={ingredient.selectedBlend.value != null}
																onChange={(val) => componentSearchStore.onChangeBlendSearchText(ingredient.id, val)}
																onClear={() => componentSearchStore.onClearSelectedBlend(ingredient.id)}
																options={ingredient.blendOptions.value}
																onSelection={(blendId) => componentSearchStore.onBlendSelection(ingredient.id, blendId)}
															 />
														</div>

														 <div className="flex col-span-3 lg:col-span-1 w-full -mr-4">
															<div className="flex">
																<FormInput
																	extraInputClassNames="rounded-r-none" 
																	placeholder={translate('user.component-search.volume.placeholder')}
																	value={ingredient.volume.value}
																	error={ingredient.volume.error}
																	onChange={(e) => ingredient.volume.onChange(e.target.value)}
																/>
															</div>
															<div className="flex">
																<FormSelect 
																	className="rounded-l-none rounded-lg border border-black-10 bg-white"
																	placeholder={translate('user.component-search.measurement.placeholder')}
																	options={componentSearchStore.measurementOptions}
																	value={ingredient.selectedVolumeMeasurement.value}
																	error={ingredient.selectedVolumeMeasurement.error}
																	onChange={(option) => ingredient.selectedVolumeMeasurement.onChange(option.value)}
																/>
															</div>
														</div>

														{ingredient.canAddToBlend && 
														 	<div className="col-span-3 col-start-0 lg:col-start-2">
																 <Button
																	width="w-full"
																	height="h-12"
																	className="px-4 rounded-lg"
																	bgColor="pink-coral"
																	fetching={componentSearchStore.fetching}
																	onClick={() => componentSearchStore.addIngredientToBlend(ingredient.id, ingredient.selectedBlend.value)}
																>
																	<div className="flex flex-row items-center justify-center">
																		<div className="flex"><TertiaryAppSmallTitle>{translate('user.component-search.add-blend-button')} &nbsp;</TertiaryAppSmallTitle></div>
																		<div className="flex"><PlusIcon className="w-4 h-4" color="white" /></div>
																	</div>
																</Button>
															</div>
														}

														<div className="col-span-3 col-start-0 lg:col-start-2">
															 <Button
																width="w-full"
																height="h-12"
																className="px-4 rounded-lg"
																bgColor="pink-coral"
																fetching={componentSearchStore.fetching}
																onClick={() => componentSearchStore.addIngredientToNewBlend(ingredient.id)}
															>
																<div className="flex flex-row items-center justify-center">
																	<div className="flex"><TertiaryAppSmallTitle>{translate('user.component-search.add-new-blend-button')} &nbsp;</TertiaryAppSmallTitle></div>
																	<div className="flex"><PlusIcon className="w-4 h-4" color="white" /></div>
																</div>
															</Button>
														</div>
														
													</div>

													<div className="flex flex-1 flex-row items-center cursor-pointer" onClick={() => componentSearchStore.goToEditIngredient(ingredient.id)}>
														<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.component-search.view-ingredient')} </TertiaryAppSmallTitle></div>
														<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
													</div>
													<div className="flex justify-center lg:justify-end">
														<Button
															width="w-full lg:w-auto"
															height="h-12"
															className="px-4 rounded-lg"
															bgColor={ingredient.isComparing ? "aubergine" : "pink-coral"}
															fetching={componentSearchStore.fetching}
															onClick={() => componentSearchStore.toggleCompareIngredient(ingredient)}
														>
															<div className="flex flex-row items-center justify-center">
																<div className="flex"><TertiaryAppSmallTitle>{ingredient.isComparing ? translate('user.component-search.is-comparing-button') : translate('user.component-search.compare-ingredient-button')} &nbsp;</TertiaryAppSmallTitle></div>
																<div className="flex"><CompareIngredients className="w-4 h-4" color="white" /></div>
															</div>
														</Button>
													</div>
												</div>
												</Grid>
											)
										})}
									</InfiniteScroll>
								</div>
							</>
						}
						{componentSearchStore.sortedIngredients.length == 0 && componentSearchStore.showSearchResults &&
							<FullGrid className="flex mt-8 py-32">
								<div className="flex flex-1 flex-col items-center justify-center text-center">
									<Body className="nav-inactive-color text-2xl">{translate('user.component-search.no-search-results.part1')}</Body>
									<div className="flex mt-16">
										<TertiaryAppMediumTitle className="text-nav-active-color">{translate('user.component-search.no-search-results.part2')}</TertiaryAppMediumTitle>
									</div>
									<div className="flex mt-16">
										<Button
											width="w-auto"
											height="h-12"
											className="px-4 rounded-lg"
											bgColor="nav-active-color"
											onClick={() => componentSearchStore.refreshSearch()}
										>
											<div className="flex flex-row items-center justify-center">
												<div className="flex"><TertiaryAppSmallTitle>{translate('user.component-search.no-search-results.search-again-button')} &nbsp;</TertiaryAppSmallTitle></div>
											</div>
										</Button>
									</div>
								</div>
							</FullGrid>
						}
					</div>
				</div>
				<BaseModal
						small
			            isOpen={componentSearchStore.showSaveModal}
			            onClose={() => componentSearchStore.hideSaveModal()}
			            label="Save Modal"
			        >
		        	<div className="flex flex-1 w-720-px h-auto overflow-y-scroll bg-white rounded-lg shadow-sm">
						<div className="flex w-full py-8 px-8 mt-4 flex-col">
		        			<SecondaryAppMediumTitle className="text-nav-active-color">{translate('user.comparison-tool.save-tagline')}</SecondaryAppMediumTitle>
		        			<div className="flex w-full mt-2">
			        			<DataRow label={translate('user.comparison-tool.name.label')}>
									<FormInput 
										placeholder={translate('user.comparison-tool.name.placeholder')}
										value={componentSearchStore.name.value}
										error={componentSearchStore.name.error}
										onChange={(e) => componentSearchStore.name.onChange(e.target.value)}
									/>
								</DataRow>
							</div>
							<div className="flex w-full mt-4">
								<DataRow label={translate('user.comparison-tool.comparisons.label')}>
									<div className="flex w-full flex-col">
										{componentSearchStore.compareIngredients.map((ingredient) => 
											<div className="flex flex-row items-center mt-2">
												<div className="w-3 h-3 border-radius-50" style={{ backgroundColor: ingredient.hex_color}}></div>
												<div className="flex ml-4"><SmallBody>{ingredient.name}</SmallBody></div>
											</div>
										)}
									</div>
								</DataRow>
							</div>
							<div className="flex w-full mt-4">
								<DataRow label={translate('user.comparison-tool.notes.label')}>
									<FormTextArea 
										placeholder={translate('user.comparison-tool.notes.placeholder')}
										value={componentSearchStore.notes.value}
										error={componentSearchStore.notes.error}
										onChange={(e) => componentSearchStore.notes.onChange(e.target.value)}
									/>
								</DataRow>
							</div>
							<div className="flex w-full justify-center mt-8">
								<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									disabled={!componentSearchStore.canSaveReport}
									fetching={componentSearchStore.fetching}
									onClick={() => componentSearchStore.saveComparisonReport()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.comparison-tool.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</div>
		        		</div>
		        	</div>
	        	</BaseModal>

	        	<BaseModal
						small
			            isOpen={componentSearchStore.showSaveSearchModal}
			            onClose={() => componentSearchStore.hideSaveSearchModal()}
			            label="Save Search Modal"
			        >
		        	<div className="flex flex-1 w-720-px h-auto overflow-y-scroll bg-white rounded-lg shadow-sm">
						<div className="flex w-full py-8 px-8 mt-4 flex-col">
		        			<SecondaryAppMediumTitle className="text-nav-active-color">{translate('user.component-search.save-tagline')}</SecondaryAppMediumTitle>
		        			<div className="flex w-full mt-2">
			        			<DataRow label={translate('user.component-search.report-name.label')}>
									<FormInput 
										placeholder={translate('user.component-search.report-name.placeholder')}
										value={componentSearchStore.reportName.value}
										error={componentSearchStore.reportName.error}
										onChange={(e) => componentSearchStore.reportName.onChange(e.target.value)}
									/>
								</DataRow>
							</div>
							<div className="flex w-full mt-4">
								<DataRow label={translate('user.component-search.components.label')}>
									<div className="flex w-full flex-col">
										{componentSearchStore.components.map((component) => 
											<div className="flex flex-row items-center mt-2">
												<div className="flex"><SmallBody>{component.description} {component.name}</SmallBody></div>
											</div>
										)}
									</div>
								</DataRow>
							</div>
							<div className="flex w-full mt-4">
								<DataRow label={translate('user.component-search.search-notes.label')}>
									<FormTextArea 
										placeholder={translate('user.component-search.search-notes.placeholder')}
										value={componentSearchStore.searchNotes.value}
										error={componentSearchStore.searchNotes.error}
										onChange={(e) => componentSearchStore.searchNotes.onChange(e.target.value)}
									/>
								</DataRow>
							</div>
							<div className="flex w-full justify-center mt-8">
								<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									disabled={!componentSearchStore.canSaveSearch}
									fetching={componentSearchStore.fetching}
									onClick={() => componentSearchStore.saveComponentSearch()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.component-search.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</div>
		        		</div>
		        	</div>
	        	</BaseModal>

			</Main>
		)
	}
}

export default withTranslation()(ComponentSearch);