import React, { Component } from 'react';
import {inject, observer} from 'mobx-react';
import { withTranslation } from 'react-i18next';

import BaseModal from '../common/modal';

import {Body, SecondaryWebLargerTitle} from '../common/text';
import {Button} from '../common/button';


import blendDropSrc from '../../assets/img/feature1.svg';

@inject('cartStore', 'appStore')
@observer
class AbandonedCart extends Component{
	constructor(props){
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.onExit = this.onExit.bind(this);
		this.onContinue = this.onContinue.bind(this);
	}

	componentDidMount(){
	}

	componentWillUnmount(){
		let cartStore = this.props.cartStore;
		cartStore.initStore();
	}
	onSubmit(e){
		e.preventDefault();
		let cartStore = this.props.cartStore;
		cartStore.submit();
	}

	onExit(){
		let cartStore = this.props.cartStore;
		cartStore.exitCart();
	}

	onContinue(){
		let cartStore = this.props.cartStore;
		cartStore.continue();
	}

	render(){
		let cartStore = this.props.cartStore;
		let translate = this.props.t;
		let appStore = this.props.appStore;

		let currentCart = cartStore.currentCart();
		if(currentCart == null) return null;
		let title = (
			<>
				{translate('cart.title.part1')} {currentCart.username != null && currentCart.username != '' && 
					<>
						<span>,</span><br /> {currentCart.username}
					</>
				}
			</>
		);
		return (
			<BaseModal
	            isOpen={appStore.showAbandonedCartModal && !appStore.showUpgradeSubscription}
	            onClose={this.onExit}
	            label="Cart"
	        >
				<div className="flex flex-1 flex-col lg:flex-row">
					<div className="flex flex-1 flex-col bg-modal-color justify-center">
						<div className="flex h-auto lg:h-64 flex-col justify-center items-center mt-4 lg:mt-0 lg:items-start w-full p-8 lg:w-4/5 lg:px-20 lg:py-0">
							<SecondaryWebLargerTitle className="text-black text-center lg:text-left">{title}</SecondaryWebLargerTitle>
							<div className="text-center lg:text-left">
								<Body className="text-black text-lg mt-8">{translate('cart.description.part1')}</Body>
								<Body className="text-black text-lg mt-8">{translate('cart.description.part2')}</Body>
							</div>
						</div>
					</div>
					<div className="flex flex-1 flex-col h-auto bg-white items-center justify-center">
						<div className="flex flex-1 justify-center flex-col p-8 lg:p-0 lg:w-2/3">
							<div className="flex ">
								<img src={blendDropSrc} alt="Blend Drop" />
							</div>

							<div className="flex h-48 items-center lg:items-end w-full mt-8 mb-8 lg:mt-0">
								<Button
									 bgColor="black"
									 width="w-full"
									 fgColor="white"
									 onClick={this.onContinue}>{translate("cart.button")}</Button>
							</div>
						</div>
					</div>
				</div>
			</BaseModal>
		)
	}
}

export default withTranslation()(AbandonedCart);