import { observable, action, computed } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isEmailValidator, isRequiredValidator, isPasswordValidator } from '../../../services/validation';

import UserApi from '../../../services/user';
import CommonApi from '../../../services/common';
import RoleApi from '../../../services/role';
import CountryApi from '../../../services/country';
import PlanApi from '../../../services/plan';
import OrganisationApi from '../../../services/organisation';

import { CHEMISTRY, COSMETIC_REGULATIONS, LANGUAGES, USERS } from '../../../services/util';

import moment from 'moment';

class ManageUser{
	appStore;
	userApi;
	countryApi;
	regionApi;
	roleApi;
	organisationApi;
	planApi;

	@observable editMode;
	@observable currentUser;

	@observable userData;

	@observable countries;
	@observable regions;
	@observable roles;
	@observable organisations;
	@observable permissions;

	@observable fetching;

	constructor(appStore){
		this.appStore = appStore;
		this.userApi = new UserApi(appStore);
		this.commonApi = new CommonApi(appStore);
		this.countryApi = new CountryApi(appStore);
		this.roleApi = new RoleApi(appStore);
		this.organisationApi = new OrganisationApi(appStore);
		this.planApi = new PlanApi(appStore);
		this.initStore();
	}

	initStore(){
		this.currentUser = null;
		this.editMode = false;
		this.userData = {
			id: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			name: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			email: new FieldState(null).validators((val) => isEmailValidator(val, this.appStore.i18n)),
			password: new FieldState(null).validators((val) => isPasswordValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.password'))),
			confirmPassword: new FieldState(null).validators((val) => this.userData.password.value.length > 0 && val != this.userData.password.value && this.appStore.i18n.t('validation.non-matching-password-message')),
			occupation: new FieldState(null),
			selectedCountry: new FieldState(null),
			selectedLanguage: new FieldState(null),
			selectedRegion: new FieldState(null),
			selectedRole: new FieldState(null),
			selectedStatus: new FieldState(null),
			selectedOrganisation: new FieldState(null),
			selectedPermissions: new FieldState([]),
			addressLine1: new FieldState(''),
			addressLine2: new FieldState(''),
			addressCity: new FieldState(''),
			addressState: new FieldState(''),
			addressZip: new FieldState(''),
			taxId: new FieldState(null),
			selectedBillingCountry: new FieldState(null),
			selectedState: new FieldState(null),
			subscriptionExpiryDateTime: new FieldState(null),
			selectedPlan: new FieldState(null)
		}
		this.countries = [];
		this.regions = [];
		this.roles = [];
		this.organisations = [];
		this.permissions = [];
		this.fetching = false;
	}

	fetchOrganisations = async() => {
		let page = 1;
		let hasMoreResults = true;
		if(!this.appStore.isSuperAdministrator) return;
		while(hasMoreResults){
			try{
				let response = await this.organisationApi.getAll(page);
				if(response.organisations.length == 0){
					hasMoreResults = false;
				}else{
					this.organisations = this.organisations.concat(response.organisations);
					page += 1;
				}
			}catch(error){
				hasMoreResults = false;
			}
		}
	}

	@computed get organisationOptions(){
		return this.organisations.map((c) => {
			return {
				label: c.name,
				value: c.id
			}
		})
	}

	fetchRegions(){
		this.commonApi.getRegions()
			.then((response) => {
				this.regions = response.regions;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	@computed get regionOptions(){
		return this.regions.map((c) => {
			return {
				label: c.name,
				value: c.id
			}
		})
	}

	fetchPlans(){
		// include archived
		this.planApi.getAll()
			.then((response) => {
				this.plans = response.plans;
			})
			.catch((error) => {
				console.log(error);
			});
	}


	@computed get planOptions(){
		return this.plans.map((p) => {
			return {
				label: `${p.name} ${p.period} (${p.currency.name})`,
				value: p.id
			}
		})
	}

	fetchPermissions(){
		if(!this.appStore.isSuperAdministrator) return;
		this.commonApi.getPermissions()
			.then((response) => {
				this.permissions = response.permissions;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	fetchRoles(){
		this.roleApi.getRoles()
			.then((response) => {
				this.roles = response.roles;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	@computed get roleOptions(){
		return this.roles.map((c) => {
			return {
				label: c.role_name,
				value: c.id
			}
		})
	}

	fetchCountries(){
		this.countryApi.getAll()
			.then((response) => {
				this.countries = response.countries;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get countryOptions(){
		return this.countries.map((c) => {
			return {
				label: c.name,
				value: c.id
			}
		})
	}


	toggleEditMode(){
		this.editMode = true;
		this.userData.id.onChange(this.currentUser.id);
		this.userData.name.onChange(this.currentUser.name);
		this.userData.email.onChange(this.currentUser.email);
		this.userData.occupation.onChange(this.currentUser.occupation);
		this.userData.selectedCountry.onChange(this.currentUser.country?.id);
		this.userData.selectedLanguage.onChange(this.currentUser.language);
		this.userData.selectedRegion.onChange(this.currentUser.region?.id);
		// Support multiple perhaps done the line
		this.userData.selectedRole.onChange(this.currentUser.roles[0].id);
		if(this.currentUser.organisation != null){
			this.userData.selectedOrganisation.onChange(this.currentUser.organisation.id);
		}
		this.userData.selectedStatus.onChange(this.currentUser.archived ? 1 : -1);
		this.userData.selectedPermissions.value  = this.currentUser.permissions.map((p) => p.name);

		this.userData.addressLine1.onChange(this.currentUser.billing_address_line_1);
		this.userData.addressLine2.onChange(this.currentUser.billing_address_line_2);
		this.userData.addressCity.onChange(this.currentUser.billing_address_city);
		this.userData.addressState.onChange(this.currentUser.billing_address_state);
		this.userData.addressZip.onChange(this.currentUser.billing_address_zip);
		this.userData.selectedBillingCountry.onChange(this.currentUser.billing_country?.id);
		if(this.showStateDropdown){
			this.userData.selectedState.onChange(this.currentUser.billing_address_state);
		}
		this.userData.taxId.onChange(this.currentUser.vat_number);
		if(this.currentUser?.subscription?.plan != null){
			this.userData.selectedPlan.onChange(this.currentUser.subscription.plan.id);
			this.userData.subscriptionExpiryDateTime.onChange(this.subscriptionPeriodEnd);
		}
	}

	@computed get subscriptionPeriodEnd(){
		let subscriptionPeriodEnd = this.currentUser?.subscription?.current_period_end;
		if(subscriptionPeriodEnd != null){
			subscriptionPeriodEnd = moment(subscriptionPeriodEnd).format('DD/MM/YYYY HH:mm')
		}
		return subscriptionPeriodEnd
	}

	@computed get currentSubscriptionPlan(){
		let subscriptionPlan = this.currentUser?.subscription?.plan;
		if(subscriptionPlan == null){
			return null;
		}
		return `${subscriptionPlan.name} ${subscriptionPlan.period} (${subscriptionPlan.currency.name})`
	}

	cancelEditMode(){
		this.editMode = false;
		this.clearPasswordInputs();
	}

	clearPasswordInputs(){
		this.userData.password.value = null;
		this.userData.confirmPassword.value = null;
	}

	setUserId(userId){
		this.userApi.getUserById(userId)
			.then((response) => {
				this.currentUser = response;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	getPermissionIds(){
		let selectedPerms = this.userData.selectedPermissions.value;
		let permissionIds = [];
		for(let perm of selectedPerms){
			let matchingPermission = this.permissions.find((x) => x.name == perm);
			if(matchingPermission != null){
				permissionIds.push(matchingPermission.id)
			}
		}
		return permissionIds;
	}

	saveUser(){
		if(this.userData.selectedLanguage.value == null){
			this.appStore.alertError(this.appStore.i18n.t('validation.select-language-message'));
			return;
		}

		if(this.showStateDropdown && this.userData.selectedState.value == null){
			this.appStore.alertError(this.appStore.i18n.t('error.missing-state-message'));
			return;
		}

        this.fetching = true;
        let userPayload = {
        	name: this.userData.name.value,
        	email: this.userData.email.value,
        	occupation: this.userData.occupation.value,
        	country_id: this.userData.selectedCountry.value,
        	region_id: this.userData.selectedRegion.value,
        	language: this.userData.selectedLanguage.value,
        	role_id: this.userData.selectedRole.value,
        	organisation_id: this.userData.selectedOrganisation.value,
        	archived: this.userData.selectedStatus.value == 1 ? true : false,
        	permission_ids: this.getPermissionIds(),
        	billing_country_id: this.userData.selectedBillingCountry.value,
        	billing_address_line_1: this.userData.addressLine1.value,
        	billing_address_line_2: this.userData.addressLine2.value,
        	billing_address_city: this.userData.addressCity.value,
        	billing_address_state: this.showStateDropdown ? this.userData.selectedState.value : this.userData.addressState.value,
        	billing_address_zip: this.userData.addressZip.value,
        	vat_number: this.userData.taxId.value,
        }
        if(this.userData.password.value != null && this.userData.password.value == this.userData.confirmPassword.value){
        	userPayload['password'] = this.userData.password.value;
        }


        this.userApi.updateUser(this.userData.id.value, userPayload)
	        .then((response) => {
	        	let updatedUser = response;
	        	this.currentUser.name = updatedUser.name;
	        	this.currentUser.email = updatedUser.email;
	        	this.currentUser.occupation = updatedUser.occupation;
	        	this.currentUser.country_id = updatedUser.country.id;
	        	this.currentUser.region.id = updatedUser.region.id;
	        	this.currentUser.roles = updatedUser.roles;
				this.currentUser.language = updatedUser.language;
	        	this.currentUser.archived = updatedUser.archived;
	        	this.currentUser.organisation = updatedUser.organisation;
	        	this.currentUser.permissions = updatedUser.permissions;
	        	this.currentUser.billing_address_line_1 = updatedUser.billing_address_line_1;
	        	this.currentUser.billing_address_line_2 = updatedUser.billing_address_line_2;
	        	this.currentUser.billing_address_city = updatedUser.billing_address_city;
	        	this.currentUser.billing_address_state = updatedUser.billing_address_state;
	        	this.currentUser.billing_address_zip = updatedUser.billing_address_zip;
	        	this.currentUser.billing_country = updatedUser.billing_country;
	        	this.currentUser.vat_number = updatedUser.vat_number;
	        	this.clearPasswordInputs();

	        	this.editMode = false;
	        })
	        .catch((error) => {
	        	console.log(error);
	        })
	        .finally(() => {
	        	this.fetching = false;
	        })
    }

    @action changeSubscriptionExpiry(){
    	if(this.currentUser == null) return;
    	let confirmed = window.confirm("Are you sure you want to change this user's subscription expiry date?. This should only be done for users billed manually.")
    	if(confirmed){
    		if(this.userData.selectedPlan.value == null) return;
    		if(this.userData.subscriptionExpiryDateTime.value == null) return;

    		let planId = this.userData.selectedPlan.value;
    		let updatedSubscriptionEnd = moment(this.userData.subscriptionExpiryDateTime.value, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
    		if(updatedSubscriptionEnd == null) return;

    		this.userApi.updateManualFreeSubscription(this.currentUser.id, planId, updatedSubscriptionEnd)
    			.then((response) => {
    				let subscription = response.subscription;
    				if(this.currentUser.subscription != null){
    					this.currentUser.subscription.current_period_end = subscription.current_period_end;
    				}else{
    					this.currentUser.subscription = subscription;
    				}
    				this.appStore.alertSuccess('User subscription expiration date has been changed.');
    			})
    			.catch((error) => {
    				console.log(error);
    			})
    	}
    }

	@computed get validForm(){
		let requiredFields = ['name', 'email', 'selectedCountry', 'selectedLanguage', 'selectedRegion'];
		if(this.appStore.isSuperAdministrator){
			requiredFields.push('selectedRole');
		}
		if(this.userData.password.value != null || this.userData.confirmPassword.value != null){
			if(this.userData.password.value != this.userData.confirmPassword.value){
				return false;
			}
		}

		let isValidForm = true;
		for(let field of requiredFields){
			let fieldError = this.userData[field].error;
			isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
		}
		return isValidForm;
	}

	@computed get statusOptions(){
		return [
			{
				value: -1,
				label: this.appStore.i18n.t('superadmin.users.list.active')
			},
			{
				value: 1,
				label: this.appStore.i18n.t('superadmin.users.list.archived')
			}
		]
	}

	@computed get hasCosmeticRegulationPermission(){
		if(this.userData == null) return false;

		return this.userData.selectedPermissions.value.includes(COSMETIC_REGULATIONS)
	}

	@computed get hasChemistryPermission(){
		if(this.userData == null) return false;
		return this.userData.selectedPermissions.value.includes(CHEMISTRY)
	}

	@computed get hasLanguagesPermission(){
		if(this.userData == null) return false;
		return this.userData.selectedPermissions.value.includes(LANGUAGES);
	}

	@computed get hasUsersPermission(){
		if(this.userData == null) return false;
		return this.userData.selectedPermissions.value.includes(USERS);
	}

	handlePermissionChange(checked, permission){
		let currentPermissions = this.userData.selectedPermissions.value;
		if(checked && !currentPermissions.includes(permission)){
			this.userData.selectedPermissions.value.push(permission);
		}else{
			this.userData.selectedPermissions.value = this.userData.selectedPermissions.value.filter((p) => p != permission);
		}
	}

	@action setCosmeticRegulationPermission(checked){
		this.handlePermissionChange(checked, COSMETIC_REGULATIONS);
	}

	@action setChemistryPermission(checked){
		this.handlePermissionChange(checked, CHEMISTRY);
	}

	@action setLanguagesPermission(checked){
		this.handlePermissionChange(checked, LANGUAGES);
	}

	@action setUsersPermission(checked){
		this.handlePermissionChange(checked, USERS);
	}

	@computed get billingCountryIsoAlpha2LetterCode(){
		if(this.userData.selectedBillingCountry.value == null) return null;
		let foundCountry = this.countries.find((c) => c.id == this.userData.selectedBillingCountry.value);
		return foundCountry?.iso_3166_alpha_2_code;
	}

	@computed get showStateDropdown(){
		return this.billingCountryIsoAlpha2LetterCode === 'US';
	}

	stateOptions(){
		let allStates = [
			this.appStore.i18n.t('common.states.option-alabama'),
			this.appStore.i18n.t('common.states.option-alaska'),
			this.appStore.i18n.t('common.states.option-arizona'),
			this.appStore.i18n.t('common.states.option-arkansas'),
			this.appStore.i18n.t('common.states.option-california'),
			this.appStore.i18n.t('common.states.option-colorado'),
			this.appStore.i18n.t('common.states.option-connecticut'),
			this.appStore.i18n.t('common.states.option-delaware'),
			this.appStore.i18n.t('common.states.option-florida'),
			this.appStore.i18n.t('common.states.option-georgia'),
			this.appStore.i18n.t('common.states.option-hawaii'),
			this.appStore.i18n.t('common.states.option-idaho'),
			this.appStore.i18n.t('common.states.option-illinois'),
			this.appStore.i18n.t('common.states.option-indiana'),
			this.appStore.i18n.t('common.states.option-iowa'),
			this.appStore.i18n.t('common.states.option-kansas'),
			this.appStore.i18n.t('common.states.option-kentucky'),
			this.appStore.i18n.t('common.states.option-louisiana'),
			this.appStore.i18n.t('common.states.option-maine'),
			this.appStore.i18n.t('common.states.option-maryland'),
			this.appStore.i18n.t('common.states.option-massachusetts'),
			this.appStore.i18n.t('common.states.option-michigan'),
			this.appStore.i18n.t('common.states.option-minnesota'),
			this.appStore.i18n.t('common.states.option-mississippi'),
			this.appStore.i18n.t('common.states.option-missouri'),
			this.appStore.i18n.t('common.states.option-montana'),
			this.appStore.i18n.t('common.states.option-nebraska'),
			this.appStore.i18n.t('common.states.option-nevada'),
			this.appStore.i18n.t('common.states.option-new-hampshire'),
			this.appStore.i18n.t('common.states.option-new-jersey'),
			this.appStore.i18n.t('common.states.option-new-mexico'),
			this.appStore.i18n.t('common.states.option-new-york'),
			this.appStore.i18n.t('common.states.option-north-carolina'),
			this.appStore.i18n.t('common.states.option-north-dakota'),
			this.appStore.i18n.t('common.states.option-ohio'),
			this.appStore.i18n.t('common.states.option-oklahoma'),
			this.appStore.i18n.t('common.states.option-oregon'),
			this.appStore.i18n.t('common.states.option-pennsylvania'),
			this.appStore.i18n.t('common.states.option-rhode-island'),
			this.appStore.i18n.t('common.states.option-south-carolina'),
			this.appStore.i18n.t('common.states.option-south-dakota'),
			this.appStore.i18n.t('common.states.option-tennessee'),
			this.appStore.i18n.t('common.states.option-texas'),
			this.appStore.i18n.t('common.states.option-utah'),
			this.appStore.i18n.t('common.states.option-vermont'),
			this.appStore.i18n.t('common.states.option-virginia'),
			this.appStore.i18n.t('common.states.option-washington'),
			this.appStore.i18n.t('common.states.option-west Virginia'),
			this.appStore.i18n.t('common.states.option-wisconsin'),
			this.appStore.i18n.t('common.states.option-wyoming')
		]
		return allStates.map((state) => {
			return {
				value: state,
				label: state
			}
		})
	}
}

export default ManageUser;