import React from 'react';
import {inject, observer} from 'mobx-react';

import {intersect} from '../../services/util';


import Index from '../index';
var createReactClass = require('create-react-class');

export const withAuthorisation = (WrappedComponent, allowedRoles, allowedPermissions = null) => {
  let authorisationWrapper = createReactClass({
	render(){
	     const roles = this.props.appStore.userRoles;
	     
	     let intersectedRoles = intersect(roles, allowedRoles);
	     if(intersectedRoles.length == 0){
	     	this.props.appStore.alertUnauthorised();
	       	return <Index />;
	     }

	     if(allowedPermissions != null){
	     	let intersectedPermissions = intersect(this.props.appStore.currentUserPermissions, allowedPermissions);
		    if(intersectedPermissions.length == 0){
		    	this.props.appStore.alertUnauthorised();
		        return <Index /> 
		    }
		 }

	     return <WrappedComponent {...this.props} />;
	}
  });
  return inject('appStore')(observer(authorisationWrapper));
}