import BaseApi from '../services/base';

class MarketingAssetApi extends BaseApi{
	constructor(props){
		super(props);
	}

	searchMarketingAssets(page, sortBy=null, searchText=null, limit=null,){
		let payload = {
			page: page,
			search_text: searchText,
			sort_by: sortBy
		}
		if(limit != null){
			payload.limit = limit;
		}
		return this.fetch(`v1/marketing-assets/search`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getMarketingAssetById(id){
		return this.fetch(`v1/marketing-assets/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	newMarketingAsset(payload){
		return this.fetch(`v1/marketing-assets`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	updateMarketingAsset(id, payload){
		return this.fetch(`v1/marketing-assets/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	deleteMarketingAssetFile(id){
		return this.fetch(`v1/marketing-assets/files/${id}`, {
			method: 'DELETE',
			auth: true
		})
	}
}

export default MarketingAssetApi;