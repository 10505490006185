import React, { Component, Fragment } from 'react';
import {inject, observer} from 'mobx-react';

import Logo from '../common/logo';

import {Button} from '../common/button';
import { withTranslation } from 'react-i18next';
import {Body, TertiaryAppSmallTitle, TertiaryAppExtraSmallTitle} from '../common/text';

import {
	DashboardIcon, 
	ArrowRightIcon, 
	ArrowDownIcon, 
	BarIcon, 
	PeopleIcon,
	FilterIcon,
	DropIcon,
	LibraryIcon,
	GridIcon,
	ChemistryIcon,
	HelpIcon,
	ComponentSearchIcon,
	HierarchyIcon,
	CompareIngredients,
	HandIcon,
	CogIcon,
	WarningIcon,
	ChemicalFamilyIcon,
	EvidenceIcon
} from '../common/icon';

const colors = require('../../color.js');


@observer
class NavSubItem extends Component{
	render(){
		let hoverColor = this.props.hoverColor != null ? this.props.hoverColor : 'light-pink';
		let fgColor = this.props.fgColor != null ? this.props.fgColor : 'black';
		let bgColor = this.props.active ? hoverColor : 'transparent';
		return (
			<div className={`flex flex-1 flex-row ml-10 bg-${bgColor} hover:bg-${hoverColor} cursor-pointer rounded-full p-2 mt-2`} onClick={this.props.onClick}>
				<TertiaryAppExtraSmallTitle className={`px-2 text-${fgColor} `}>{this.props.text}</TertiaryAppExtraSmallTitle>
			</div>
		)
	}
}

@observer
class HelpDialog extends Component{
	render(){
		let translate = this.props.t;
		return (
			<div className="bg-help-color-20 px-4 py-8 rounded-xl mt-24 flex flex-1 items-center justify-end flex-col mx-8">
				<div className="flex">
					<HandIcon className="flex flex-1 w-full" />
				</div>
				<div className="flex flex-col text-center items-center mt-4">
					<div className="flex"><Body>{translate('user.nav.help.part1')} <span className="bold-font">{translate('user.nav.help.part2')}</span></Body></div>
					<div className="flex mt-4"><Body>{translate('user.nav.help.part3')} <br /> {translate('user.nav.help.part4')}</Body></div>
				</div>
				<div className="flex mt-4">
					<Button 
						className="rounded-lg h-10"
						fgColor="white" 
						bgColor="nav-active-color"
						onClick={() => this.props.onHelp()}>{translate('user.nav.help.chat')}</Button>
				</div>
			</div>
		)
	}
}

@observer
class NavItem extends Component{
	constructor(props){
		super(props);
		this.toggleSublist = this.toggleSublist.bind(this);
		this.onClickNavItem = this.onClickNavItem.bind(this);
		this.state = {
			showSublist: false
		}
	}

	toggleSublist(e){
		e.stopPropagation();
		this.setState({
			showSublist: !this.state.showSublist
		})
	}

	onClickNavItem(e){
		if(!this.props.sublist){
			this.props.onClick();
		}else{
			this.toggleSublist(e);
		}
	}

	render(){
		let color = this.props.active ? 'nav-inactive-color' : 'black';
		let navItemClassName = "flex flex-1 flex-row cursor-pointer -mr-8";
		if(this.props.active){
			navItemClassName = `${navItemClassName} border-r-4 border-nav-inactive-color`;
		}
		let currentActiveColor = this.props.active ? colors['nav-inactive-color'] : colors['black'];
		return (
			<div className="flex flex-1 flex-col px-8 mt-2 lg:mt-4">
				<div className={navItemClassName}>
					<div className="flex flex-1 flex-row py-2 items-center" onClick={this.onClickNavItem}>
						<div className="flex w-4 h-4">{this.props.icon}</div>
						<div className="flex ml-4">
							<TertiaryAppSmallTitle className={`text-${color} hover:text-nav-inactive-color`}>{this.props.text}</TertiaryAppSmallTitle>
						</div>
						{this.props.sublist && 
							<div className="flex flex-1 justify-end mr-4" onClick={this.toggleSublist} >
								{this.state.showSublist ? <ArrowDownIcon color={currentActiveColor} className="w-4 h-4" /> : <ArrowRightIcon color={currentActiveColor} className="w-4 h-4" />}
							</div>
						}
					</div>
				</div>
				{this.state.showSublist && this.props.sublist}
			</div>
		)
	}
}

@inject('appStore')
@observer
class Navbar extends Component{
	constructor(props){
		super(props);
		this.renderSuperAdministratorNavbar = this.renderSuperAdministratorNavbar.bind(this);
		this.renderSuperAdministratorMobileNavbar = this.renderSuperAdministratorMobileNavbar.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.onChangeLanguage = this.onChangeLanguage.bind(this);
		this.onChangeMenuOption = this.onChangeMenuOption.bind(this);
		this.toggleItems = this.toggleItems.bind(this);
		this.superAdminNavItems = this.superAdminNavItems.bind(this);
		this.isCurrentView = this.isCurrentView.bind(this);
		this.renderOrganisationAdminNavItems = this.renderOrganisationAdminNavItems.bind(this);
		this.renderOrganisationAdminNavbar = this.renderOrganisationAdminNavbar.bind(this);
		this.renderOrganisationAdminMobileNavbar = this.renderOrganisationAdminMobileNavbar.bind(this);
		this.renderUserNavItems = this.renderUserNavItems.bind(this);
		this.renderUserMobileNavbar = this.renderUserMobileNavbar.bind(this);
		this.renderUserNavbar = this.renderUserNavbar.bind(this);
		this.renderBlendLibraryCategories = this.renderBlendLibraryCategories.bind(this);
		this.renderLogo = this.renderLogo.bind(this);
		this.superAdminHoverColor = 'range-option-active-bg-color';
		this.superAdminFgColor = 'black';
		this.adminHoverColor = 'light-pink';
		this.adminFgColor = 'black';
		this.userHoverColor = 'ochre-yellow-25';
		this.userFgColor = 'black';
		this.state = {
			showItems: false
		}
	}

	toggleItems(){
		this.setState({
			showItems: !this.state.showItems
		})
	}

	onLogout(){
		this.props.appStore.logout();
	}

	onChangeLanguage(option){
		let language = option.value;
		this.props.i18n.changeLanguage(language);
    	this.props.appStore.changeLanguage(language);
	}

	onChangeMenuOption(option){
		this.props.appStore.onChangeMenuOption(option.value);
	}

	isCurrentView(name){
		let currentView = this.props.appStore.currentView.name;
		return (currentView == name);
	}

	renderBlendLibraryCategories(hoverColor, fgColor){
		let appStore = this.props.appStore;
		return (
			<>
				{appStore.ownedLibraryCategories.map((libraryCategory) => 
					<NavSubItem 
						active={appStore.isCurrentLibraryCategory(libraryCategory.id)} 
						hoverColor={hoverColor}
						fgColor={fgColor}
						text={libraryCategory.name}
						onClick={() => appStore.goToBlendLibraryWithCategory(libraryCategory.id)}
					/>
				)}
			</>
		)
	}


	superAdminNavItems(){
		let translate = this.props.t;

		let activeIconColor = colors['nav-inactive-color'];
		let inactiveIconColor = colors['black'];

		let isDashboard = this.isCurrentView('super-administrator-home');
		let isUsers = this.isCurrentView('administrator-users');
		let isUserHome = this.isCurrentView('home');
		let isOrganisations = this.isCurrentView('super-administrator-organisations');
		let isCreateBlend = this.isCurrentView('new-blend');
		let isBlendLibrary = this.isCurrentView('my-blend-library');
		let isBlendCategories = this.isCurrentView('blend-categories');
		let isLabaromaBlendLibrary = this.isCurrentView('labaroma-blend-library');
		let isLibraryCategories = this.isCurrentView('library-categories');
		let isIngredientLibrary = this.isCurrentView('ingredient-library');
		let isMyIngredientLibrary = this.isCurrentView('my-ingredient-library');
		let isLabaromaIngredientLibrary = this.isCurrentView('labaroma-ingredient-library');
		let isHelp = this.isCurrentView('super-administrator-help');
		let isSettings = this.isCurrentView('settings');
		let isCosmeticRegulations = this.isCurrentView('cosmetic-regulations');
		let isChemistrySettings = this.isCurrentView('chemistry-settings');
		let isLanguages = this.isCurrentView('languages');
		let isCompareIngredients = this.isCurrentView('compare-ingredients');
		let isComponentSearch = this.isCurrentView('component-search-list');
		let isComparisonTool = this.isCurrentView('comparison-tool');
		let isEvidence = this.isCurrentView('evidence-search');
		return (
			<Fragment>
				<NavItem
					icon={<DashboardIcon className="w-4 h-4" color={isDashboard ? activeIconColor : inactiveIconColor} />}
					text={translate('superadmin.nav.dashboard.title')}
					active={isDashboard}
					sublist={(
						<>
							<NavSubItem 
								onClick={() => this.props.appStore.goToSuperAdministratorHome()} 
								active={isDashboard} 
								hoverColor={this.superAdminHoverColor}
								fgColor={this.superAdminFgColor}
								text={translate('superadmin.nav.dashboard.subtitle')} 
							/>
							<NavSubItem 
								onClick={() => this.props.appStore.goToUserHome()} 
								active={isUserHome} 
								hoverColor={this.superAdminHoverColor}
								fgColor={this.superAdminFgColor}
								text={translate('superadmin.nav.mylabaroma.title')} 
							/>
						</>
					)}
				/>
				<NavItem 
					icon={<PeopleIcon className="w-4 h-4" color={isUsers ? activeIconColor : inactiveIconColor}  />}
					text={translate('superadmin.nav.users.title')}
					active={isUsers}
					onClick={() => this.props.appStore.goToManageUsers()}
				/>
				<NavItem 
					icon={<HierarchyIcon className="w-4 h-4" color={isOrganisations ? activeIconColor : inactiveIconColor}  />}
					text={translate('superadmin.nav.organisations.title')}
					active={isOrganisations}
					onClick={() => this.props.appStore.goToSuperAdministratorManageOrganisations()}
				/>
				<NavItem 
					icon={<FilterIcon className="w-4 h-4" color={isCreateBlend ? activeIconColor : inactiveIconColor} />}
					text={translate('user.nav.create-blend.title')}
					active={isCreateBlend}
					onClick={() => this.props.appStore.goToNewBlend()}
				/>
				<NavItem 
					icon={<LibraryIcon className="w-4 h-4" color={isBlendLibrary ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.blend-library.title')}
					active={isBlendLibrary}
					sublist={(
						<>
							<NavSubItem 
								active={isBlendLibrary} 
								hoverColor={this.superAdminHoverColor}
								fgColor={this.superAdminFgColor}
								text={translate('user.nav.my-blend-library.title')}
								onClick={() => this.props.appStore.goToMyBlendLibrary()}
							/>
							<NavSubItem 
								active={isLabaromaBlendLibrary} 
								hoverColor={this.superAdminHoverColor}
								fgColor={this.superAdminFgColor}
								text={translate('user.nav.labaroma-blend-library.title')}
								onClick={() => this.props.appStore.goToLabAromaBlendLibrary()}
							/>
							<NavSubItem 
								active={isBlendCategories} 
								hoverColor={this.superAdminHoverColor}
								fgColor={this.superAdminFgColor}
								text={translate('user.nav.blend-categories.title')}
								onClick={() => this.props.appStore.goToBlendCategories()}
							/>
							{this.renderBlendLibraryCategories(this.superAdminHoverColor, this.superAdminFgColor)}
						</>
					)}
				/>
				<NavItem 
					icon={<DropIcon className="w-4 h-4" color={isIngredientLibrary ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.ingredient-library.title')}
					active={isIngredientLibrary}
					sublist={(
						<>
							<NavSubItem 
								active={isMyIngredientLibrary} 
								hoverColor={this.superAdminHoverColor}
								fgColor={this.superAdminFgColor}
								text={translate('user.nav.my-ingredient-library.title')}
								onClick={() => this.props.appStore.goToMyIngredientLibrary()}
							/>
							<NavSubItem 
								active={isLabaromaIngredientLibrary} 
								hoverColor={this.superAdminHoverColor}
								fgColor={this.superAdminFgColor}
								text={translate('user.nav.labaroma-ingredient-library.title')}
								onClick={() => this.props.appStore.goToLabAromaIngredientLibrary()}
							/>
						</>
					)}
				/>
				<NavItem 
					icon={<CompareIngredients id={-1} color={inactiveIconColor} className="w-4 h-4" />} 
					color={isCompareIngredients ? activeIconColor : inactiveIconColor}
					text={translate('user.nav.compare-ingredients.title')}
					active={isCompareIngredients}
					hoverColor={this.superAdminHoverColor}
					fgColor={this.superAdminFgColor}
					onClick={() => this.props.appStore.goToCompareIngredients()}
				/>

				<NavItem 
					icon={<ComponentSearchIcon className="w-4 h-4" color={isComponentSearch ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.component-search.title')}
					active={isComponentSearch}
					onClick={() => this.props.appStore.goToComponentSearchList()}
				/>
				<NavItem 
					icon={<EvidenceIcon className="w-4 h-4" color={isEvidence ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.evidence.title')}
					active={isEvidence}
					onClick={() => this.props.appStore.goToEvidenceSearch()}
				/>
				<NavItem 
					icon={<HelpIcon className="w-4 h-4" color={isHelp ? activeIconColor : inactiveIconColor} />}
					text={translate('superadmin.nav.help-centre.title')}
					active={isHelp}
					onClick={() => this.props.appStore.goToHelpCenter()}
				/>
				<NavItem 
					icon={<CogIcon className="w-4 h-4" color={isSettings ? activeIconColor : inactiveIconColor} />}
					text={translate('superadmin.nav.settings.title')}
					active={isSettings}
					sublist={(
						<>
							<NavSubItem 
								text={translate('user.nav.library-category.title')}
								active={isLibraryCategories}
								hoverColor={this.superAdminHoverColor}
								fgColor={this.superAdminFgColor}
								onClick={() => this.props.appStore.goToLibraryCategories()}
							/>
							{this.props.appStore.hasCosmeticRegulationPermission &&
								<NavSubItem 
									active={isCosmeticRegulations} 
									hoverColor={this.superAdminHoverColor}
									fgColor={this.superAdminFgColor}
									text={translate('superadmin.nav.cosmetic-regulations.title')} 
									onClick={() => this.props.appStore.goToRegulations()}
								/>
							}
							{this.props.appStore.hasChemistryPermission &&
								<NavSubItem 
									onClick={() => this.props.appStore.goToChemistrySettings()}
									active={isChemistrySettings} 
									hoverColor={this.superAdminHoverColor}
									fgColor={this.superAdminFgColor}
									text={translate('superadmin.nav.chemistry-settings.title')}  
								/>
							}
							{this.props.appStore.hasLanguagesPermission &&
								<NavSubItem 
									active={isLanguages} 
									hoverColor={this.superAdminHoverColor}
									fgColor={this.superAdminFgColor}
									text={translate('superadmin.nav.language.title')}  
									onClick={() => this.props.appStore.goToLanguages()}
								/>
							}
							
						</>
					)}
				/>
			</Fragment>
		)
	}

	renderSuperAdministratorNavbar(){
		let appStore = this.props.appStore
		return (
			<div className="flex hidden lg:block w-full h-auto flex-col">
				{this.superAdminNavItems()}
			</div>
		)
	}

	renderSuperAdministratorMobileNavbar(){
		let appStore = this.props.appStore
		return (
			<div className="flex lg:hidden w-full min-h-screen overflow-scroll flex-col">
				{this.superAdminNavItems()}
			</div>
		)
	}

	renderOrganisationAdminNavItems(){
		let translate = this.props.t;
		let activeIconColor = colors['nav-inactive-color'];
		let inactiveIconColor = colors['black'];

		let isDashboard = this.isCurrentView('organisation-administrator-home');
		let isUserHome = this.isCurrentView('home');
		let isUsers = this.isCurrentView('administrator-users');
		let isCreateBlend = this.isCurrentView('new-blend');
		let isBlendLibrary = this.isCurrentView('my-blend-library');
		let isBlendCategories = this.isCurrentView('blend-categories');
		let isLabaromaBlendLibrary = this.isCurrentView('labaroma-blend-library');
		let isLibraryCategories = this.isCurrentView('library-categories');
		let isIngredientLibrary = this.isCurrentView('ingredient-library');
		let isMyIngredientLibrary = this.isCurrentView('my-ingredient-library');
		let isLabaromaIngredientLibrary = this.isCurrentView('labaroma-ingredient-library');
		let isSettings = this.isCurrentView('settings');
		let isCompareIngredients = this.isCurrentView('compare-ingredients');
		let isComponentSearch = this.isCurrentView('component-search-list');
		let isComparisonTool = this.isCurrentView('comparison-tool');
		let isLanguages = this.isCurrentView('languages');
		let isChemistrySettings = this.isCurrentView('chemistry-settings');
		let isCosmeticRegulations = this.isCurrentView('cosmetic-regulations');
		let isEvidence = this.isCurrentView('evidence-search');
		let canViewUsers = this.props.appStore.hasUsersPermission;
		return (
			<Fragment>
				<NavItem
					icon={<DashboardIcon className="w-4 h-4" color={isDashboard ? activeIconColor : inactiveIconColor} />}
					text={translate('org-admin.nav.dashboard.title')}
					active={isDashboard}
					sublist={(
						<>
							<NavSubItem 
								onClick={() => this.props.appStore.goToOrganisationAdministratorHome()} 
								active={isDashboard} 
								hoverColor={this.adminHoverColor}
								fgColor={this.adminFgColor}
								text={translate('org-admin.nav.dashboard.subtitle')} 
							/>
							<NavSubItem 
								onClick={() => this.props.appStore.goToUserHome()} 
								hoverColor={this.adminHoverColor}
								fgColor={this.adminFgColor}
								active={isUserHome} 
								text={translate('org-admin.nav.mylabaroma.title')} 
							/>
						</>
					)}
				/>
				{canViewUsers && 
					<NavItem 
						icon={<PeopleIcon className="w-4 h-4" color={isUsers ? activeIconColor : inactiveIconColor}  />}
						text={translate('org-admin.nav.users.title')}
						active={isUsers}
						onClick={() => this.props.appStore.goToManageUsers()}
					/>
				}
				<NavItem 
					icon={<FilterIcon className="w-4 h-4" color={isCreateBlend ? activeIconColor : inactiveIconColor} />}
					text={translate('user.nav.create-blend.title')}
					active={isCreateBlend}
					onClick={() => this.props.appStore.goToNewBlend()}
				/>
				<NavItem 
					icon={<LibraryIcon className="w-4 h-4" color={isBlendLibrary ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.blend-library.title')}
					active={isBlendLibrary}
					sublist={(
						<>
							<NavSubItem 
								active={isBlendLibrary} 
								hoverColor={this.adminHoverColor}
								fgColor={this.adminFgColor}
								text={translate('user.nav.my-blend-library.title')}
								onClick={() => this.props.appStore.goToMyBlendLibrary()}
							/>
							<NavSubItem 
								active={isLabaromaBlendLibrary} 
								hoverColor={this.adminHoverColor}
								fgColor={this.adminFgColor}
								text={translate('user.nav.labaroma-blend-library.title')}
								onClick={() => this.props.appStore.goToLabAromaBlendLibrary()}
							/>
							<NavSubItem 
								active={isBlendCategories} 
								hoverColor={this.adminHoverColor}
								fgColor={this.adminFgColor}
								text={translate('user.nav.blend-categories.title')}
								onClick={() => this.props.appStore.goToBlendCategories()}
							/>
							{this.renderBlendLibraryCategories(this.adminHoverColor, this.adminFgColor)}
						</>
					)}
				/>
				<NavItem 
					icon={<DropIcon className="w-4 h-4" color={isIngredientLibrary ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.ingredient-library.title')}
					active={isIngredientLibrary}
					sublist={(
						<>
							<NavSubItem 
								active={isMyIngredientLibrary} 
								hoverColor={this.adminHoverColor}
								fgColor={this.adminFgColor}
								text={translate('user.nav.my-ingredient-library.title')}
								onClick={() => this.props.appStore.goToMyIngredientLibrary()}
							/>
							<NavSubItem 
								hoverColor={this.adminHoverColor}
								fgColor={this.adminFgColor}
								text={translate('user.nav.labaroma-ingredient-library.title')}
								onClick={() => this.props.appStore.goToLabAromaIngredientLibrary()}
							/>
						</>
					)}
				/>
				<NavItem 
					icon={<CompareIngredients id={-1} color={inactiveIconColor} className="w-4 h-4" />} 
					color={isCompareIngredients ? activeIconColor : inactiveIconColor}
					text={translate('user.nav.compare-ingredients.title')}
					active={isCompareIngredients}
					hoverColor={this.adminHoverColor}
					fgColor={this.adminFgColor}
					onClick={() => this.props.appStore.goToCompareIngredients()}
				/>

				<NavItem 
					icon={<ComponentSearchIcon className="w-4 h-4" color={isComponentSearch ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.component-search.title')}
					active={isComponentSearch}
					onClick={() => this.props.appStore.goToComponentSearchList()}
				/>

				<NavItem 
					icon={<EvidenceIcon className="w-4 h-4" color={isEvidence ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.evidence.title')}
					active={isEvidence}
					onClick={() => this.props.appStore.goToEvidenceSearch()}
				/>

				<NavItem 
					icon={<GridIcon className="w-4 h-4" color={isLibraryCategories ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.library-category.title')}
					active={isLibraryCategories}
					onClick={() => this.props.appStore.goToLibraryCategories()}
				/>
				
				{this.props.appStore.hasCosmeticRegulationPermission &&
					<NavItem 
						icon={<WarningIcon className="w-4 h-4" color={isCosmeticRegulations ? activeIconColor : inactiveIconColor}  />}
						text={translate('superadmin.nav.cosmetic-regulations.title')}
						active={isCosmeticRegulations}
						onClick={() => this.props.appStore.goToRegulations()}
					/>
				}
				{this.props.appStore.hasLanguagesPermission && 
					<NavItem 
						icon={<ChemicalFamilyIcon className="w-4 h-4" color={isLanguages ? activeIconColor : inactiveIconColor}  />}
						text={translate('superadmin.nav.language.title')}
						active={isLanguages}
						onClick={() => this.props.appStore.goToLanguages()}
					/>
				}
				{this.props.appStore.hasChemistryPermission && 
					<NavItem 
						icon={<ChemistryIcon className="w-4 h-4" color={isChemistrySettings ? activeIconColor : inactiveIconColor}  />}
						text={translate('superadmin.nav.chemistry-settings.title')}
						onClick={() => this.props.appStore.goToChemistrySettings()}
						active={isChemistrySettings} 
					/>
				}
			</Fragment>
		)
	}

	renderOrganisationAdminNavbar(){
		let translate = this.props.t;
		let appStore = this.props.appStore
		return (
			<div className="flex hidden lg:block w-full h-auto flex-col">
				{this.renderOrganisationAdminNavItems()}

				<HelpDialog t={translate} onHelp={() => appStore.openIntercom()} />
			</div>
		)
	}

	renderOrganisationAdminMobileNavbar(){
		let appStore = this.props.appStore
		return (
			<div className="flex lg:hidden w-full min-h-screen overflow-scroll flex-col">
				{this.renderOrganisationAdminNavItems()}
			</div>
		)
	}

	renderUserNavItems(){
		let translate = this.props.t;
		let activeIconColor = colors['nav-inactive-color'];
		let inactiveIconColor = colors['black'];

		let appStore = this.props.appStore;

		let isUserHome = this.isCurrentView('home');
		let isCreateBlend = this.isCurrentView('new-blend');
		let isBlendLibrary = this.isCurrentView('my-blend-library');
		let isBlendCategories = this.isCurrentView('blend-categories');
		let isLabaromaBlendLibrary = this.isCurrentView('labaroma-blend-library');
		let isIngredientLibrary = this.isCurrentView('ingredient-library');
		let isMyIngredientLibrary = this.isCurrentView('my-ingredient-library');
		let isLabaromaIngredientLibrary = this.isCurrentView('labaroma-ingredient-library');
		let isEvidence = this.isCurrentView('evidence-search');
		let isCompareIngredients = this.isCurrentView('compare-ingredients');
		let isComponentSearch = this.isCurrentView('component-search-list');
		let isComparisonTool = this.isCurrentView('comparison-tool');
		return (
			<Fragment>
				<NavItem
					icon={<DashboardIcon className="w-4 h-4" color={isUserHome ? activeIconColor : inactiveIconColor} />}
					text={translate('user.nav.dashboard.title')}
					active={isUserHome}
					onClick={() => this.props.appStore.goToUserHome()}
				/>
				
				<NavItem 
					icon={<FilterIcon className="w-4 h-4" color={isCreateBlend ? activeIconColor : inactiveIconColor} />}
					text={translate('user.nav.create-blend.title')}
					active={isCreateBlend}
					onClick={() => this.props.appStore.goToNewBlend()}
				/>
				<NavItem 
					icon={<LibraryIcon className="w-4 h-4" color={isBlendLibrary ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.blend-library.title')}
					active={isBlendLibrary}
					sublist={(
						<>
							<NavSubItem 
								active={isBlendLibrary} 
								hoverColor={this.userHoverColor}
								fgColor={this.userFgColor}
								text={translate('user.nav.my-blend-library.title')}
								onClick={() => this.props.appStore.goToMyBlendLibrary()}
							/>
							<NavSubItem 
								active={isLabaromaBlendLibrary} 
								hoverColor={this.userHoverColor}
								fgColor={this.userFgColor}
								text={translate('user.nav.labaroma-blend-library.title')}
								onClick={() => this.props.appStore.goToLabAromaBlendLibrary()}
							/>
							<NavSubItem 
								active={isBlendCategories} 
								hoverColor={this.userHoverColor}
								fgColor={this.userFgColor}
								text={translate('user.nav.blend-categories.title')}
								onClick={() => this.props.appStore.goToBlendCategories()}
							/>
							{this.renderBlendLibraryCategories(this.userHoverColor, this.userFgColor)}
						</>
					)}
				/>
				
				<NavItem 
					icon={<DropIcon className="w-4 h-4" color={isIngredientLibrary ? activeIconColor : inactiveIconColor}  />}
					text={translate('user.nav.ingredient-library.title')}
					active={isIngredientLibrary}
					sublist={(
						<>
							<NavSubItem 
								active={isMyIngredientLibrary} 
								hoverColor={this.userHoverColor}
								fgColor={this.userFgColor}
								text={translate('user.nav.my-ingredient-library.title')}
								onClick={() => this.props.appStore.goToMyIngredientLibrary()}
							/>
							<NavSubItem 
								hoverColor={this.userHoverColor}
								fgColor={this.userFgColor}
								text={translate('user.nav.labaroma-ingredient-library.title')}
								onClick={() => this.props.appStore.goToLabAromaIngredientLibrary()}
							/>
						</>
					)}
				/>

				{!appStore.isHomeUser && 
					<>
						<NavItem 
							icon={<CompareIngredients id={-1} color={inactiveIconColor} className="w-4 h-4" />} 
							color={isCompareIngredients ? activeIconColor : inactiveIconColor}
							text={translate('user.nav.compare-ingredients.title')}
							active={isCompareIngredients}
							hoverColor={this.userHoverColor}
							fgColor={this.userFgColor}
							onClick={() => this.props.appStore.goToCompareIngredients()}
						/>

						<NavItem 
							icon={<ComponentSearchIcon className="w-4 h-4" color={isComponentSearch ? activeIconColor : inactiveIconColor}  />}
							text={translate('user.nav.component-search.title')}
							active={isComponentSearch}
							onClick={() => this.props.appStore.goToComponentSearchList()}
						/>

						<NavItem 
							icon={<EvidenceIcon className="w-4 h-4" color={isEvidence ? activeIconColor : inactiveIconColor}  />}
							text={translate('user.nav.evidence.title')}
							active={isEvidence}
							onClick={() => this.props.appStore.goToEvidenceSearch()}
						/>
					</>
				}
			</Fragment>
		)
	}

	renderUserNavbar(){
		let translate = this.props.t;
		let appStore = this.props.appStore
		return (
			<div className="flex hidden lg:block w-full h-auto flex-col">
				{this.renderUserNavItems()}

				<HelpDialog t={translate} onHelp={() => appStore.openIntercom()} />
			</div>
		)
	}

	renderUserMobileNavbar(){
		let appStore = this.props.appStore
		return (
			<div className="flex lg:hidden w-full min-h-screen overflow-scroll flex-col">
				{this.renderUserNavItems()}
			</div>
		)
	}

	renderLogo(){
		let appStore = this.props.appStore
		return (
			<Logo 
				superAdmin={appStore.isSuperAdministrator}
				admin={appStore.isOrganisationAdministrator}
				small
				className="flex items-start" />
		)
	}

	render(){
		let appStore = this.props.appStore;
		let renderItems = null;
		let renderMobileItems = null;
		if(appStore.isSuperAdministrator){
			renderItems = this.renderSuperAdministratorNavbar();
			renderMobileItems = this.renderSuperAdministratorMobileNavbar();
		}else if(appStore.isOrganisationAdministrator){
			renderItems = this.renderOrganisationAdminNavbar();
			renderMobileItems = this.renderOrganisationAdminMobileNavbar();
		}else{
			renderItems = this.renderUserNavbar();
			renderMobileItems = this.renderUserMobileNavbar();
		}
		return (
			<div className="flex flex-col w-full lg:w-64 bg-white">
				<div className="flex justify-between items-center w-full lg:w-auto flex-row items-start px-8 py-4 mt-4 lg:mt-8">
					<div className="flex items-start">
						<div className="h-12 w-full">{this.renderLogo()}</div>
					</div>
					<div className="block lg:hidden h-12" onClick={this.toggleItems}>
						<BarIcon className="w-5 h-5" color={colors['black']} />
					</div>
				</div>
				{renderItems}
				{this.state.showItems && renderMobileItems}
			</div>
		)
	}
}

export default withTranslation()(Navbar);