import { observable, action, computed} from 'mobx';

import IngredientApi from '../../../../services/ingredient';
import moment from 'moment'

class IngredientComparisons{

	appStore;

	@observable currentComparisons;
	@observable hasMoreComparisons;
	@observable comparisonsPage;

	@observable editDeleteMode;

	@observable fetching;
	
	ingredientApi;
	
	constructor(appStore){
		this.appStore = appStore;
		this.ingredientApi = new IngredientApi(appStore);
		this.initStore();
	}

	initStore(){
		this.fetching = false;
		this.currentComparisons = [];
		this.hasMoreComparisons = true;
		this.comparisonsPage = 1;
		this.editDeleteMode = false;
	}

	toggleEditDeleteMode(){
		this.editDeleteMode = !this.editDeleteMode;
	}

	toComparisonReport(report){
		report.created_on = moment(report.created_on);
		return report;
	}

	fetchComparisonReports(reset=false){
		this.fetching = true;

		this.ingredientApi.getAllComparisonReports(this.comparisonsPage, null)
			.then((response) => {
				let newComparisons = response.comparison_reports.map(this.toComparisonReport);

				this.currentComparisons = reset ? newComparisons : this.currentComparisons.concat(newComparisons);
				this.hasMoreComparisons = newComparisons.length > 0;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action loadNextComparisonsPage(){
		this.comparisonsPage += 1;
		this.fetchComparisonReports();
	}


	@action goToComparisonTool(){
		this.appStore.goToComparisonTool();
	}

	@action onDeleteComparisonReport(id){
		this.fetching = true;

		this.ingredientApi.deleteComparisonReportById(id)
			.then((response) => {
				this.currentComparisons = this.currentComparisons.filter((c) => c.id != id);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action onEditComparisonReport(id){
		this.appStore.goToEditComparisonTool(id);
	}

}

export default IngredientComparisons;