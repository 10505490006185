import BaseApi from '../services/base';

class CommonApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getRegions(){
		return this.fetch(`v1/regions`, {
			method: 'GET'
		})
	}

	getLanguages(){
		return this.fetch(`v1/languages`, {
			method: 'GET'
		})
	}

	getLanguageById(language){
		return this.fetch(`v1/languages/${language}`, {
			method: 'GET'
		})
	}

	getTooltips(){
		return this.fetch(`v1/tooltips`, {
			method: 'GET'
		})
	}

	newRegion(payload){
		return this.fetch('v1/regions', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getPermissions(){
		return this.fetch(`v1/permissions`, {
			method: 'GET',
			auth: true
		})
	}

	getPresignedUrl(fileType){
		return this.fetch('v1/url', {
			method: 'POST',
			auth: true,
			body: JSON.stringify({
				file_type: fileType
			})
		})
	}

	getScores(){
		return this.fetch('v1/scores', {
			method: 'GET'
		})
	}
}

export default CommonApi;