import { observable, action, computed } from 'mobx';

import UserApi from '../../services/user';
import CommonApi from '../../services/common';
import BlendApi from '../../services/blend';
import S3Api from '../../services/s3';
import StatApi from '../../services/stat';

import moment from 'moment';

import { FieldState, FormState } from 'formstate';


class UserStore{
	appStore;

	userApi;
	commonApi;
	s3Api;
	blendApi;
	statApi;

	@observable currentUser;
	@observable regions;

	@observable selectedRegion;
	@observable profileImageUrl;

	@observable uploadingImage;

	@observable topScentNotes;
	@observable topPhysicalRankings;
	@observable topPsychologicalRankings;


	constructor(appStore){
		this.appStore = appStore;
		this.userApi = new UserApi(appStore);
		this.commonApi = new CommonApi(appStore);
		this.blendApi = new BlendApi(appStore);
		this.statApi = new StatApi(appStore);
		this.s3Api = new S3Api();
		this.initStore();
	}

	initStore(){
		this.regions = [];
		this.selectedRegion = null;
		this.currentUser = null;
		this.profileImageUrl = null;
		this.uploadingImage = false;
		this.topScentNotes = [];
		this.topPhysicalRankings = [];
		this.topPsychologicalRankings = [];
	}

	fetchRegions(){
		this.commonApi.getRegions()
			.then((response) => {
				this.regions = response.regions;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchUserSettings(){
		this.userApi.getUser()
			.then((response) => {
				this.currentUser = response;
				this.selectedRegion = this.currentUser.region.id;
				this.profileImageUrl = this.currentUser.profile_photo_url;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get regionOptions(){
		return this.regions.map((r) => {
			return {
				value: r.id,
				label: r.name
			}
		})
	}

	@action changeSelectedRegion(val){
		this.selectedRegion = val;

		this.userApi.changeUserRegion(this.appStore.currentUser.id, this.selectedRegion)
			.then((response) => {
				this.appStore.alertSuccess(this.appStore.i18n.t('success.region-saved-message'));
			})
			.catch((error) => {
				console.log(error);
			});
	}

	@action changeProfileImage(file){
		this.uploadingImage = true;
		this.commonApi.getPresignedUrl(file.type)
			.then((response) => {
				let filename = response.filename;
				let signedUrlPayload = response.signed_url_payload;
				this.s3Api.uploadToS3(file, signedUrlPayload)
					.then((response) => {
						this.userApi.changeUserProfilePhotoFilename(this.appStore.currentUser.id, filename)
							.then((response) => {
								this.profileImageUrl = response.profile_photo_url;
								this.appStore.alertSuccess(this.appStore.i18n.t('success.profile-photo-saved-message'));
							})
							.catch((error) => {
								this.appStore.alertError(this.appStore.i18n.t('error.uploading-photo-message'));
							})
							.finally(() => {
								this.uploadingImage = false;
							})
					})
					.catch((error) => {
						this.uploadingImage = false;
						this.appStore.alertError(this.appStore.i18n.t('error.uploading-photo-message'));
					});
			})
			.catch((error) => {
				this.uploadingImage = false;
				this.appStore.alertError(this.appStore.i18n.t('error.uploading-photo-message'));
			})
	}

	@action updateUserProfile(){
		this.appStore.goToCurrentUserProfile();
	}

	@action fetchTopStats(){
		this.statApi.fetchCurrentUserTopStats()
			.then((response) => {
				this.topScentNotes = response.top_scent_notes;
				this.topPhysicalRankings = response.top_physical_rankings.map((pr) => {
					pr.ranking = pr.physical_ranking;
					return pr;
				});;
				this.topPsychologicalRankings = response.top_psychological_rankings.map((ps) => {
					ps.ranking = ps.psychological_ranking;
					return ps;
				});
			})
			.catch((error) => {
				console.log(error);
			})
	}
}

export default UserStore;