import BaseApi from '../services/base';

class EvidenceApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(page){
		return this.fetch(`v1/evidence?page=${page}`, {
			method: 'GET',
			auth: true
		})
	}

	searchEvidence(page, ingredientId){
		return this.fetch(`v1/evidence/search`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify({
				page: page,
				ingredient_id: ingredientId
			})
		})
	}

	getEvidenceById(id){
		return this.fetch(`v1/evidence/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	getAllEvidenceTypes(){
		return this.fetch(`v1/evidence/types`, {
			method: 'GET',
			auth: true
		})
	}

	newEvidence(payload){
		return this.fetch(`v1/evidence`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	updateEvidence(id, payload){
		return this.fetch(`v1/evidence/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	deleteEvidenceFile(id){
		return this.fetch(`v1/evidence/files/${id}`, {
			method: 'DELETE',
			auth: true
		})
	}
}

export default EvidenceApi;