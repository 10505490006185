import { observable, action, computed } from 'mobx';

import UserApi from '../../../services/user';

class UserStore{

	@observable users;
	@observable page;
	@observable total;
	@observable has_next;
	@observable has_prev;
	@observable offset;
	@observable limit;
	@observable archived;

	@observable showSortDialog;
	@observable sortBy;
	@observable showEditTooltip;

	@observable fetching;

	@observable searchEmail;

	constructor(appStore){
		this.appStore = appStore;
		this.userApi = new UserApi(this.appStore);
		this.initStore();
	}

	initStore(){
		this.users = [];
		this.page = 1;
		this.total = 0;
		this.offset = null;
		this.limit = null;
		this.showSortDialog = false;
		this.sortBy = null;
		this.archived = false;
		this.showEditTooltip = null;
		this.searchEmail = null;
		this.fetching = false;
	}

	fetchUsers(){
		this.fetching = true;
		this.userApi.getAll(this.page, this.sortBy, this.archived, this.searchEmail)
			.then((response) => {
				this.users = response.users;
				this.limit = response.limit;
				this.page = response.page;
				this.total = response.total;
				this.offset = response.offset;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	onNextPage(){
		this.page += 1;
		this.fetchUsers();
	}

	onPreviousPage(){
		this.page = Math.max(this.page - 1, 0);

		this.fetchUsers();
	}

	onPage(page){
		this.page = page;
		this.fetchUsers();
	}

	@computed get hasNext(){
		if(this.total == null || this.page == null || this.limit == null) return false;
		return (this.page * this.limit) < this.total
	}

	@computed get hasPrev(){
		if(this.limit == null || this.offset == null) return false;
		return this.offset >= this.limit;
	}

	@computed get pages(){
		if(this.users.length == 0 || this.total == null) return [];
		
		let noOfPages = Math.floor(this.total / this.limit);
		let pages = []
		for(let i = 0; i < noOfPages + 1; i++){
			pages.push(i+1)
		}
		return pages;
	}

	onEditUser(id){
		this.appStore.goToEditUser(id);
	}

	@action toggleSortDialog(){
		this.showSortDialog = !this.showSortDialog;
	}

	@action setSortByField(sortBy){
		if(this.sortBy == sortBy){
			this.sortBy = null;
		}else{
			this.sortBy = sortBy;
		}
	}

	@action applySort(){
		this.fetchUsers();
		this.showSortDialog = false;
	}

	@computed get sortOptions(){
		if(this.appStore == null) return [];
		return [
			{
				label: this.appStore.i18n.t("superadmin.users.sortoptions.id"),
				value: "id"
			},
			{
				label: this.appStore.i18n.t("superadmin.users.sortoptions.name"),
				value: "name"
			},
			{
				label: this.appStore.i18n.t("superadmin.users.sortoptions.plan"),
				value: "plan"
			},
			{
				label: this.appStore.i18n.t("superadmin.users.sortoptions.status"),
				value: "status"
			}
		]
	}

	@action onArchivedUsers(){
		this.archived = !this.archived;
		this.fetchUsers();
	}

	@action onNewInvite(){
		this.appStore.goToInviteNewUser();
	}

	@action setEditTooltip(id){
		this.showEditTooltip = id;
	}

	@action onChangeSearchEmail(val){
		this.searchEmail = val.length > 0 ? val : null;
		this.fetchUsers();
	}
}

export default UserStore;