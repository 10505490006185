import React, {Component} from 'react';

import {observer} from 'mobx-react';

import {ArrowLeftIcon, ArrowRightIcon, ArchiveIcon, TickIcon, StarIcon, EditIcon, SortIcon, FilterDialIcon} from '../common/icon';

import {Body, ApplyText, TertiaryAppSmallTitle,   ExtraSmallBody} from '../common/text'

const colors = require('../../color.js');

@observer
class ListItem extends Component{
	render(){
		return (
			<div className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out" onClick={this.props.onEdit}>
		        <div className="flex items-center px-4 py-4 sm:px-6">
		          <div className="min-w-0 flex-1 flex items-center">
		            {this.props.imgSrc && this.props.imgAlt &&
		            	<div className="flex-shrink-0">
			              <img className="h-12 w-12 rounded-full" src={this.props.imgSrc} alt={this.props.imgAlt} />
			            </div>
			        }
		            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
		              {this.props.children}
		            </div>
		          </div>
		          {this.props.onEdit && 
		          	<div onClick={this.props.onEdit}>
		            	<AngleRightIcon  />
		          	</div>
			      } 
		        </div>
		    </div>
		)
	}
}

const NoListItemsMessage = ({type}) => (
	<div className="flex w-full items-center justify-center py-4">
		<span className="text-sm leading-5 font-medium text-gray-600 truncate">No {type == null ? 'entries' : type} found</span>
	</div>
)

const FetchingItemsMessage = () => (
	<div className="flex w-full items-center justify-center py-4">
		<span className="text-sm leading-5 font-medium text-gray-600 truncate">Fetching...</span>
	</div>
)

@observer
class List extends Component{
	render(){
		let fetching = 'fetching' in this.props ? this.props.fetching : false;
		return (
			<div className="flex flex-1 flex-col justify-start w-full">
				{this.props.items.length > 0 && 
					<table className="table-auto w-full">
						<thead>
							{this.props.renderListHeaders()}
						</thead>
						<tbody className="bg-white">
						     {this.props.items.map(this.props.renderListItem)}
						</tbody>
					</table>
				}
				{fetching &&
			     	<FetchingItemsMessage type={this.props.type} />
			    }
				{this.props.items.length == 0 && !fetching &&
			     	<NoListItemsMessage type={this.props.type} />
			    }
				<>
				{this.props.items.length > 0 && 
					<div className="flex flex-1 items-end">
						<Pagination
							pages={this.props.pages}
							page={this.props.page}
							hasPrev={this.props.hasPrev}
							hasNext={this.props.hasNext}
							onPage={this.props.onPage}
							onPreviousPage={this.props.onPreviousPage}
							onNextPage={this.props.onNextPage}
						 />
					</div>
				}
				</>
			</div>
		)
	}
}

const PaginationBox = ({children, onClick=null, active=false}) => (
	<div onClick={onClick} className={`flex justify-center items-center h-8 w-8 rounded-md ml-1 cursor-pointer border border-${active ? 'black' : 'list-text'}`}>
		{children}
	</div>
)

const PaginationElement = ({children, active=false}) => (
	<span className={`text-xs regular-font text-${active ? 'black': 'list-text'}`}>{children}</span>
)

class Pagination extends Component{
	render(){
		let currentPage = this.props.page;
		let threshold = 5;
		let maxPrevPage = Math.max(currentPage - threshold, 0);
		let maxNextPage = Math.max(maxPrevPage + threshold * 2, 0)
		let pages = this.props.pages.filter((p) => p >= maxPrevPage && p <= maxNextPage);
		return (
			<div className="flex w-full flex-row items-center justify-center mt-8">
			  	{this.props.hasPrev && 
			  		<PaginationBox onClick={this.props.onPreviousPage}>
			  			<PaginationElement><ArrowLeftIcon color="black" className="w-2 h-2" /></PaginationElement>
			  		</PaginationBox>
			  	}
			  	{pages.map((page, idx) => 
			  		<PaginationBox key={idx} active={this.props.page == page} onClick={() => this.props.onPage(page)}>
			  			<PaginationElement active={this.props.page == page}>{page}</PaginationElement> 
			  		</PaginationBox>
			  	)} 
			  	{this.props.hasNext && 
			  		<PaginationBox onClick={this.props.onNextPage}>
				  		<PaginationElement><ArrowRightIcon color="black" className="w-2 h-2" /></PaginationElement>
				  	</PaginationBox>
				}
		  	</div>
		)
	}
}

@observer
class Status extends Component{
	render(){
		return (
			<div className="flex items-center justify-start">
				<span className={`w-auto py-1 px-2 inline-flex justify-center text-xs semibold-font rounded-md bg-${this.props.bgColor} text-${this.props.color}`}>
			        {this.props.text}
			    </span>
			</div>
		)
	}
}

@observer
class ArchivedStatus extends Component{
	render(){
		let translate = this.props.t;
		return (
			<Status 
				color={this.props.archived ? 'list-text' : 'flat-green'} 
				bgColor={this.props.archived ? 'black-10' : 'active-status' } 
				text={this.props.archived ? translate("superadmin.users.list.archived") : translate("superadmin.users.list.active")} 
			/>
		)
	}
}

const TableRow = ({children}) => (
	<tr className="border-t border-b border-1 border-list-text">
		{children}
	</tr>
)


const TableCell = ({children}) => (
	<td className="py-4 text-gray-700">
		{children}
	</td>
)

const TableHeader = ({children, className=null}) => {
	let tableHeaderClassName = 'text-left semibold-font pb-2';
	if(className != null){
		tableHeaderClassName = `${tableHeaderClassName} ${className}`
	}
	return (
		<th className={tableHeaderClassName}>
			{children}
		</th>
	)
}

@observer
class SortOption extends Component{
	render(){
		let sortOption = this.props.sortOption;

		return (
			<div className="flex flex-row items-center mt-2" onClick={this.props.onClick}>
				<div className="flex"><span className="semibold-font text-base">{sortOption.label}</span></div>
				{sortOption.value == this.props.currentOption && <div className="flex flex-1 justify-end ml-4"><TickIcon className="w-4 h-4" color={colors['petroleum-blue'].default} /></div>}
				{sortOption.value != this.props.currentOption && <div className="flex ml-4 w-4 h-4 justify-end"></div>}
			</div>
		)
	}
}

@observer
class SortDialog extends Component{
	render(){
		let translate = this.props.t;
		return (
			<div className="z-50 bg-white absolute top-0 mt-10 py-4 px-8 border border-black-10 full-shadow right-0 flex flex-col rounded-lg border-black w-auto h-auto">
				{this.props.sortOptions.map((sortOption) => 
					<SortOption sortOption={sortOption} currentOption={this.props.currentOption} onClick={(e) => {
						e.stopPropagation();
						this.props.onOption(sortOption.value)
					}}/>
				)}
				{this.props.sortOptions.length > 0 && 
					<div className="flex justify-end mt-2" onClick={(e) => {
						e.stopPropagation();
						this.props.onApply()
					}}>
						<ApplyText t={translate} />
					</div>
				}
			</div>
		)
	}
}

const SortingField = ({text, onClick=null, extraClassNames=null}) => (
	<div className="flex flex-row items-center bold-font" onClick={onClick}>
		<div className="flex"><SortIcon color="black" className="w-4 h-4" /></div>
		<div className="flex ml-2"><Body className={extraClassNames}>{text}</Body></div>
	</div>
)

const FilterField = ({text, onClick=null}) => (
	<div className="flex flex-row items-center bold-font" onClick={onClick}>
		<div className="flex"><FilterDialIcon color="black" className="w-4 h-4" /></div>
		<div className="flex ml-2"><Body>{text}</Body></div>
	</div>
)

@observer
class FilterDialog extends Component{
	render(){
		let translate = this.props.t;
		return (
			<div className="z-40 bg-white absolute top-0 mt-10 py-4 px-8 border border-black-10 full-shadow right-0 flex flex-col rounded-lg border-black w-auto h-auto">
				{this.props.children}
				<div className="flex justify-end mt-2" onClick={(e) => {
					e.stopPropagation();
					this.props.onApply()
				}}>
					<ApplyText t={translate} />
				</div>
			</div>
		)
	}
}

@observer
class ListTile extends Component{
	render(){
		let translate = this.props.t;
		return (
			<div className="flex flex-1 flex-col items-between" style={{ height: '18rem' }}>
				<div className="flex flex-1 rounded-t-lg" style={{ backgroundColor: this.props.hexColor}}></div>
				<div className="flex flex-col h-2/5 bg-white px-4 py-2 rounded-b-lg">
					<div className="flex flex-row items-center justify-between">
						<div className="flex flex-col">
							<div className="flex"><TertiaryAppSmallTitle>{this.props.name}</TertiaryAppSmallTitle></div>
							<div className="flex"><ExtraSmallBody className="italic">{this.props.description}</ExtraSmallBody></div>
						</div>
						<div className="flex flex-row items-center">
							{'onArchived' in this.props && 'archived' in this.props &&  
								<div className="flex cursor-pointer self-start justify-end mt-1" onClick={() => this.props.onArchived(this.props.id)}>
									{!this.props.archived ? (
										<ArchiveIcon 
											id={this.props.id} 
											className="w-4 h-4" 
											fill={colors['list-text']}
											color={colors['black']} />
										) : (
											<TickIcon 
												id={this.props.id} 
												className="w-4 h-4" 
												fill={colors['white']}
												color={colors['list-text']} />
										)
									}
								</div>
							}
							{'onFavourite' in this.props && 'favourited' in this.props &&  
								<div className="flex ml-4 cursor-pointer self-start justify-end mt-1" onClick={() => this.props.onFavourite(this.props.id)}>
									<StarIcon 
										id={this.props.id} 
										className="w-4 h-4" 
										fill={this.props.favourited ? colors['black'] : colors['white']}
										color={colors['black']} />
								</div>
							}
							<div className="flex ml-4 cursor-pointer self-start justify-end mt-1" onClick={() => this.props.onEdit(this.props.id)}>
								<EditIcon 
									id={this.props.id} 
									className="w-4 h-4" 
									color={colors['black']} />
							</div>
						</div>
					</div>
					<div className="flex flex-row justify-end mt-2 cursor-pointer"  onClick={() => this.props.onView(this.props.id)}>
						{'viewDescription' in this.props && 
							<div className="flex flex-row items-center ">
								<div className="flex"><ExtraSmallBody>{this.props.viewDescription}</ExtraSmallBody></div>
								<div className="flex ml-2"><ArrowRightIcon className="w-2 h-2" color={colors['black']} /></div>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

export {
	List,
	ListItem,
	Pagination,
	Status,
	TableRow,
	TableCell,
	TableHeader,
	SortDialog,
	SortOption,
	SortingField,
	FilterField,
	FilterDialog,
	ListTile,
	ArchivedStatus
}