import { observable, action, computed, extendObservable } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isEmailValidator, isRequiredValidator, isNotNullValidator, isPositiveNumberValidator } from '../../../services/validation';

import CommonApi from '../../../services/common';
import IngredientApi from '../../../services/ingredient';
import EvidenceApi from '../../../services/evidence';
import ChemistryApi from '../../../services/chemistry';
import AcidApi from '../../../services/acid';
import S3Api from '../../../services/s3';

import {parseAndSanitizeHtml} from '../../../components/common/html';

import {getIngredientAvatarUrl} from './util'
import moment from 'moment';
import uuidv4 from 'uuid/v4';

const colors = require('../../../color.js');

class ManageIngredientsStore{
	@observable loaded;
	@observable addMode;
	@observable viewMode;
	@observable editMode;

	@observable currentIngredient;

	@observable ingredientData;
	@observable ingredientTypes;
	@observable conservationStatuses;
	@observable scentNotes;
	@observable psychologicalRankings;
	@observable physicalRankings;

	@observable chemicalComponents;
	@observable hasMoreChemicalComponents;
	@observable currentChemicalComponentPage;

	@observable chemicalFamilies;

	@observable acidComponents;
	@observable acidFamilies;
	
	@observable acidComponentSearchText;
	@observable selectedAcidComponent;
	@observable addAcidAmount;

	@observable currentAcidComponentPage;
	@observable hasMoreAcidComponents;

	@observable scores;

	@observable componentSearchText;
	@observable selectedComponent;
	@observable addAmount;

	@observable showChemicalFamilyModal;
	@observable currentChemicalFamily;

	@observable showAcidFamilyModal;
	@observable currentAcidFamily;

	@observable ingredientForm;

	@observable fetching;

	@observable linkedEvidence;
	@observable showChemicalComponentBreakdown;
	@observable showAcidComponentBreakdown;

	@observable renderedPieChartBase64Url;
	@observable renderedAcidPieChartBase64Url;
	@observable ingredientImageBase64Url;

	@observable pdfDownloadOptions;
	@observable pdfGenerating;
	@observable currentAction;
	@observable showPDFDownloadModal;

	INITIAL_BREAKDOWN_LIMIT = 7;

	countryApi;
	ingredientApi;
	evidenceApi;
	chemistryApi;
	acidApi;
	s3Api;

	constructor(appStore){
		this.appStore = appStore;
		this.commonApi = new CommonApi(appStore);
		this.ingredientApi = new IngredientApi(appStore);
		this.evidenceApi = new EvidenceApi(appStore);
		this.chemistryApi = new ChemistryApi(appStore);
		this.acidApi = new AcidApi(appStore);
		this.s3Api = new S3Api(appStore);
		this.initStore();
	}

	initStore(){
		this.loaded = false;
		this.addMode = true;
		this.viewMode = false;
		this.editMode = false;
		this.currentIngredient = null;
		this.ingredientData = {
			name: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			latinName: new FieldState(null),
			origin: new FieldState(null),
			selectedIngredientType: new FieldState(null).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			benchmarkPrice: new FieldState(null).validators((val) => val.length > 0 && isPositiveNumberValidator(val, this.appStore.i18n)),
			botanicalFamily: new FieldState(null),
			partOfPlant: new FieldState(null),
			safetyNote: new FieldState(null),
			selectedConservationStatus: new FieldState(null),
			therapeuticProperties: new FieldState(null),
			comedogenicScale:new FieldState(null),
			synergy: new FieldState(null),
			energetics: new FieldState(null),
			profile: new FieldState(null),
			formulationGuidance: new FieldState(null),
			ingredientNotes: new FieldState(null),
			selectedScentNote: new FieldState(null),
			psychologicalRankings: [],
			physicalRankings: [],
			currentImageFile: new FieldState(null),
			currentImageUrl: new FieldState(null),
			uploadedFiles: new FormState([]),
			chemicalComponents: [],
			acidComponents: [],
			legacyItemCode: new FieldState(null),
		}
		this.ingredientForm = new FormState({
			name: this.ingredientData.name,
			selectedIngredientType: this.ingredientData.selectedIngredientType
		})
		this.ingredientTypes = [];
		this.conservationStatuses = [];
		this.scentNotes = [];
		this.psychologicalRankings = [];
		this.physicalRankings = [];
		this.scores = [];
		this.chemicalComponents = [];
		this.hasMoreChemicalComponents = true;
		this.currentChemicalComponentPage = 1;
		this.chemicalFamilies = [];
		this.acidComponents = [];
		this.acidFamilies = [];
		this.pdfDownloadOptions = [];
		this.componentSearchText = null;
		this.selectedComponent = null;
		this.addAmount = new FieldState(0).validators((val) => isRequiredValidator(val, this.appStore.i18n));

		this.acidComponentSearchText = null;
		this.selectedAcidComponent = null;
		this.addAcidAmount = new FieldState(0).validators((val) => isRequiredValidator(val, this.appStore.i18n));
		this.showChemicalFamilyModal = false;
		this.currentChemicalFamily = null;
		this.showAcidFamilyModal = false;
		this.currentAcidFamily = null;
		this.fetching = false;
		this.currentAcidComponentPage = 1;
		this.hasMoreAcidComponents = true;
		this.linkedEvidence = [];
		this.showPDFDownloadModal = false;
		this.showChemicalComponentBreakdown = false;
		this.showAcidComponentBreakdown = false;
		this.renderedPieChartBase64Url = null;
		this.renderedAcidPieChartBase64Url = null;
		this.ingredientImageBase64Url = null;
		this.pdfGenerating = false;
		this.currentAction = null;
	}

	setupPDFOptions(){
		this.pdfDownloadOptions = [
			{
				label: `${this.appStore.i18n.t("user.manage-ingredient.download-all")}`,
				id: 0, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-ingredient.profile.label")}`,
				id: 1, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-ingredient.therapeutic-properties.label")}`,
				id: 2, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-ingredient.energetics.label")}`,
				id: 3, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-ingredient.synergy.label")}`,
				id: 4, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t( "user.manage-ingredient.chemical-breakdown.title")}`,
				id: 5, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-ingredient.scent-notes.label")}`,
				id: 6, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-ingredient.physical-rankings.effects.label")}`,
				id: 7, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-ingredient.ingredient-notes.label")}`,
				id: 8, 
				checked: true
			}
		];
	}

	@computed get showProfile(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 1 && x.checked == true);
	}

	@computed get showTherapeuticProperties(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 2 && x.checked == true);
	}

	@computed get showEnergeticsDownload(){
		return this.showEnergetics && this.currentPDFDownloadOptions.some((x) => x.id == 3 && x.checked == true);
	}

	@computed get showChemicalBreakdown(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 5 && x.checked == true);
	}

	@computed get showScentNotes(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 6 && x.checked == true);
	}

	@computed get showEffects(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 7 && x.checked == true);
	}

	@computed get showNotes(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 8 && x.checked == true);
	}

	@computed get showSynergy(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 4 && x.checked == true);
	}

	fetchScores(){
		this.commonApi.getScores()
			.then((response) => {
				this.scores = response.scores;
			})
			.catch((error) => {
				console.log(error);
			})
	}


	fetchIngredientTypes(){
		this.ingredientApi.getAllIngredientTypes()
			.then((response) => {
				this.ingredientTypes = response.ingredient_types;
			})
			.catch((error) => {
				console.log(error);
			})
	}
	
	@computed get ingredientTypeOptions(){
		return this.ingredientTypes.map((type) => {
			return {
				value: type.id,
				label: type.name
			}
		})
	}

	fetchConservationStatuses(){
		this.ingredientApi.getAllConservationStatuses()
			.then((response) => {
				this.conservationStatuses = response.conservation_statuses;
			})
			.catch((error) => {
				console.log(error);
			})
	}
	
	@computed get conservationStatusOptions(){
		return this.conservationStatuses.map((status) => {
			return {
				value: status.id,
				label: `${status.name}`
			}
		})
	}

	fetchScentNotes(){
		this.ingredientApi.getAllScentNotes()
			.then((response) => {
				this.scentNotes = response.scent_notes;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchPsychologicalRankings(){
		this.ingredientApi.getAllPsychologicalRankings()
			.then((response) => {
				this.psychologicalRankings = response.psychological_rankings;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get psychologicalRankingsOptions(){
		return this.psychologicalRankings.map((ranking) => {
			return {
				value: ranking.id,
				label: ranking.name
			}
		})
	}

	fetchPhysicalRankings(){
		this.ingredientApi.getAllPhysicalRankings()
			.then((response) => {
				this.physicalRankings = response.physical_rankings;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get physicalRankingsOptions(){
		return this.physicalRankings.map((ranking) => {
			return {
				value: ranking.id,
				label: ranking.name
			}
		})
	}

	@computed get scoreOptions(){
		return this.scores.map((s) => {
			return {
				value: s,
				label: s
			}
		})
	}

	generatePsychologicalRanking(effect=null, score=null, id=null){
		return new FormState({
			id: id,
			uuid: uuidv4(),
			selectedEffect: new FieldState(effect).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			selectedScore: new FieldState(score).validators((val) => isNotNullValidator(val, this.appStore.i18n))
		})
	}

	@action addPsychologicalRanking(){
		this.ingredientData.psychologicalRankings.push(this.generatePsychologicalRanking());
	}

	generatePhysicalRanking(effect=null, score=null, id=null){
		return new FormState({
			id: id,
			uuid: uuidv4(),
			selectedEffect: new FieldState(effect).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			selectedScore: new FieldState(score).validators((val) => isNotNullValidator(val, this.appStore.i18n))
		})
	}

	@action addPhysicalRanking(){
		this.ingredientData.physicalRankings.push(this.generatePhysicalRanking());	
	}

	@action onChangeAvatar(file){
		this.ingredientData.currentImageFile.value = file;
		this.ingredientData.currentImageUrl.value = URL.createObjectURL(file);
	}

	@action onUploadFiles(files){
		this.fetching = true;
		for(let newFile of files){
			this.ingredientData.uploadedFiles.$.push({
				id: null,
				name: newFile.name,
				file_url: newFile.uri,
				file: newFile
			});	
		}
		this.fetching = false;
	}

	fetchChemicalFamilies = async () => {
		try{
			this.chemicalFamilies = await this.chemistryApi.getChemicalFamilies();
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get chemicalFamilyOptions(){
		return this.chemicalFamilies.map((cf) => {
			return {
				value: cf.id,
				label: cf.name
			}
		})
	}

	fetchAcidFamilies = async () => {
		try{
			this.acidFamilies = await this.acidApi.getAcidFamilies();
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get acidFamilyOptions(){
		return this.acidFamilies.map((af) => {
			return {
				value: af.id,
				label: af.name
			}
		})
	}

	fetchChemicalComponents(reset=false){
		this.chemistryApi.getAllChemicalComponents(this.currentChemicalComponentPage, this.componentSearchText)
			.then((response) => {
					let newChemicalComponents = response.chemical_components;
					this.chemicalComponents = this.chemicalComponents.concat(newChemicalComponents);
					this.hasMoreChemicalComponents = newChemicalComponents.length > 0;
				})
				.catch((error) => {
					console.log(error);
				})
	}

	@computed get chemicalComponentOptions(){
		return Array.from(new Set(this.chemicalComponents)).map((c) => {
			return {
				value: c.id,
				label: c.name
			}
		})
	}

	@computed get searchComponentOptions(){
		if(this.componentSearchText == null || this.componentSearchText == '') return [];
		return this.chemicalComponents.filter((c) => c.name.toLowerCase().includes(this.componentSearchText)).map((c) => {
			return {
				value: c.id,
				label: c.name
			}
		});
	}

	fetchAcidComponents(reset=false){
		this.acidApi.getAllAcidComponents(this.currentAcidComponentPage, this.acidComponentSearchText)
			.then((response) => {
					let newAcidComponents = response.acid_components;
					this.acidComponents = this.acidComponents.concat(newAcidComponents);
					this.hasMoreAcidComponents = newAcidComponents.length > 0;
				})
				.catch((error) => {
					console.log(error);
				})
	}

	@computed get acidComponentOptions(){
		return Array.from(new Set(this.acidComponents)).map((c) => {
			return {
				value: c.id,
				label: c.name
			}
		})
	}

	@computed get searchAcidComponentOptions(){
		if(this.acidComponentSearchText == null || this.acidComponentSearchText == '') return [];
		return this.acidComponents.filter((c) => c.name.toLowerCase().includes(this.acidComponentSearchText)).map((c) => {
			return {
				value: c.id,
				label: c.name
			}
		});
	}


	@action onChangeComponentSearchText(val){
		this.componentSearchText = val;
		this.selectedComponent = null;
		this.fetchChemicalComponents(true);	
	}

	@action onChangeAcidComponentSearchText(val){
		this.acidComponentSearchText = val;
		this.selectedAcidComponent = null;
		this.fetchAcidComponents(true);
	}

	@action onChooseComponent(id){
		this.selectedComponent = id;
		if(this.currentChemicalComponent != null){
			this.componentSearchText = this.currentChemicalComponent.name;
		}
	}

	@action onChooseAcidComponent(id){
		this.selectedAcidComponent = id;
		if(this.currentAcidComponent != null){
			this.acidComponentSearchText = this.currentAcidComponent.name;
		}
	}

	@computed get currentChemicalComponent(){
		if(this.selectedComponent == null) return null;
		return this.chemicalComponents.find((c) => c.id == this.selectedComponent);
	}

	@computed get currentAcidComponent(){
		if(this.selectedAcidComponent == null) return null;
		return this.acidComponents.find((a) => a.id == this.selectedAcidComponent);
	}

	@action onClearComponentSelection(){
		this.componentSearchText = '';
		this.selectedComponent = null;
	}

	@action onClearAcidComponentSelection(){
		this.acidComponentSearchText = '';
		this.selectedAcidComponent = null;
	}

	generateChemicalComponent(chemicalComponentId, amount, id=null){
		let newChemicalComponent = {
			id: id,
			uuid: uuidv4(),
			chemicalComponentId: new FieldState(chemicalComponentId).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			amount: new FieldState(amount).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n))
		}
		return new FormState(newChemicalComponent)
	}

	onAddComponent = async() => {
		if(this.selectedComponent == null){
			this.appStore.alertError(this.appStore.i18n.t('user.manage-ingredient.chemical-breakdown.select-a-component-message'));
			return;
		}

		let addAmountForm = new FormState({
			addAmount: this.addAmount
		})

		let res = await addAmountForm.validate();
		if(res.hasError) return;

		if(this.ingredientData.chemicalComponents.map((c) => c.$.chemicalComponentId.value).includes(this.selectedComponent)){
			this.appStore.alertError(this.appStore.i18n.t('user.manage-ingredient.chemical-breakdown.component-already-exists-message'));
			return;
		}

		let newChemicalComponent = this.generateChemicalComponent(this.selectedComponent, parseFloat(this.addAmount.value))
		this.ingredientData.chemicalComponents.push(newChemicalComponent);

		this.addAmount.value = 0;
		this.selectedComponent = null;
		this.componentSearchText = '';
	}

	generateAcidComponent(acidComponentId, amount, id=null){
		let newAcidComponent = {
			id: id,
			uuid: uuidv4(),
			acidComponentId: new FieldState(acidComponentId).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			amount: new FieldState(amount).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n))
		}
		return new FormState(newAcidComponent)
	}

	onAddAcidComponent = async() => {
		if(this.selectedAcidComponent == null){
			this.appStore.alertError(this.appStore.i18n.t('user.manage-ingredient.chemical-breakdown.select-a-component-message'));
			return;
		}

		let addAmountForm = new FormState({
			addAcidAmount: this.addAcidAmount
		})

		let res = await addAmountForm.validate();
		if(res.hasError) return;

		if(this.ingredientData.acidComponents.map((c) => c.$.acidComponentId.value).includes(this.selectedAcidComponent)){
			this.appStore.alertError(this.appStore.i18n.t('user.manage-ingredient.chemical-breakdown.component-already-exists-message'));
			return;
		}

		let newAcidComponent = this.generateAcidComponent(this.selectedAcidComponent, parseFloat(this.addAcidAmount.value))
		this.ingredientData.acidComponents.push(newAcidComponent);

		this.addAcidAmount.value = 0;
		this.selectedAcidComponent = null;
		this.acidComponentSearchText = '';
	}

	@computed get chemicalFamilyPieData(){
		let data = this.currentIngredientFamilyBreakdown;
		let pieData = data.map((d, idx) => {
		  	return {
		  		x: d['family'].name, 
		  		y: d['percentage'],
		  		family: d['family'],
		  		color: d['color']
		  	}
		})
		return pieData;
	}

	@computed get acidFamilyPieData(){
		let data = this.currentIngredientAcidFamilyBreakdown;
		let pieData = data.map((d, idx) => {
		  	return {
		  		x: d['family'].name, 
		  		y: d['percentage'],
		  		family: d['family'],
		  		color: d['color']
		  	}
		  })
		return pieData;
	}

	@computed get currentIngredientFamilyBreakdown(){
		let datapoints = [];
		let totalAmount = 0;
		if(this.addMode || this.editMode){
			this.ingredientData.chemicalComponents.map((c) => {
				let chemicalComponentId = c.$.chemicalComponentId.value;
				let foundChemicalComponent = this.chemicalComponents.find((c) => c.id == chemicalComponentId);
				let amount = parseFloat(c.$.amount.value);
				if(foundChemicalComponent != null){
					datapoints.push({
						'family': foundChemicalComponent.chemical_family.id,
						'amount': amount
					});
					totalAmount += amount;
				}
			})
		}else{
			this.currentIngredient?.chemical_components.map((c) => {
				if(c.chemical_component?.chemical_family != null){
					datapoints.push({
						'family': c.chemical_component.chemical_family.id,
						'amount': c.amount
					});
					totalAmount += c.amount;
				}
			})
		}
		

		let chemicalFamilyBreakdown = new Map();
		datapoints.map((datapoint) => {
			let family = datapoint['family'];
			let amount = datapoint['amount'];
			let hasFamily = chemicalFamilyBreakdown.has(family);
			if(!hasFamily){
				chemicalFamilyBreakdown.set(family, amount)
			}else{
				let currentAmount = chemicalFamilyBreakdown.get(family);
				chemicalFamilyBreakdown.set(family, currentAmount + amount);
			}
		})
		let chemicalFamilyPercentages = [];

		chemicalFamilyBreakdown.forEach((v,k) => {
			let chemicalFamily = this.chemicalFamilies.find((v) => v.id == k);
			if(chemicalFamily==null) return;
			let familyPercentage = parseFloat(v).toFixed(4);
			if(isNaN(familyPercentage)){
				familyPercentage = 0;
			}
			chemicalFamilyPercentages.push({
				'family': chemicalFamily,
				'color': chemicalFamily.hex_color,
				'percentage': parseFloat(familyPercentage)
			})
		});
		let results = chemicalFamilyPercentages.sort((cf1, cf2) => {
			return parseFloat(cf1['percentage']) > parseFloat(cf2['percentage']) ? -1 : 1;
		})
		return results;
	}

	@computed get currentIngredientType(){
		if(this.editMode || this.addMode){
			let ingredientTypeId = this.ingredientData.selectedIngredientType.value;
			if(ingredientTypeId == null) return null;
			let currentIngredientType = this.ingredientTypes.find((i) => i.id == ingredientTypeId);
			return currentIngredientType;
		}else{
			if(this.currentIngredient == null) return null;
			return this.currentIngredient.ingredient_type;
		}
	}

	@computed get showFattyAcidBreakdown(){
		if(this.currentIngredientType == null) return false;
		return this.currentIngredientType.is_fatty_acid;
	}

	@computed get isEssentialOil(){
		if(this.currentIngredientType == null) return false;
		return this.currentIngredientType.is_essential_oil;
	}

	@computed get isExtract(){
		if(this.currentIngredientType == null) return false;
		return this.currentIngredientType.english_name.toLowerCase() === 'extracts';
	}

	@computed get isCarrier(){
		if(this.currentIngredientType == null) return false;
		return this.currentIngredientType.english_name.toLowerCase() === 'carrier oils';
	}

	@computed get isHerbal(){
		if(this.currentIngredientType == null) return false;
		return this.currentIngredientType.english_name.toLowerCase() === 'herbal';
	}

	@computed get isCosmetic(){
		if(this.currentIngredientType == null) return false;
		return this.currentIngredientType.english_name.toLowerCase() === 'cosmetic';
	}

	@computed get showComedogenicScale(){
		if(this.isEssentialOil || this.isExtract || this.isHerbal || this.isCosmetic) return false;
		return true;
	}

	@computed get showEnergetics(){
		if(this.isHerbal || this.isCosmetic || this.isCarrier || this.isExtract) return false;
		return true;
	}

	@computed get showFormulationGuidance(){
		if(this.isEssentialOil || this.isExtract || this.isHerbal) return false;
		return true;
	}

	@computed get showIngredientEffects(){
		if(this.isCosmetic || this.isHerbal || this.isCarrier) return false;
		return true;
	}

	@computed get currentIngredientAcidFamilyBreakdown(){
		let datapoints = [];
		let totalAmount = 0;
		if(!this.viewMode){
			this.ingredientData.acidComponents.map((c) => {
				let acidComponentId = c.$.acidComponentId.value;
				let foundAcidComponent = this.acidComponents.find((c) => c.id == acidComponentId);
				let amount = parseFloat(c.$.amount.value);
				if(foundAcidComponent != null){
					datapoints.push({
						'family': foundAcidComponent.acid_family.id,
						'amount': amount
					});
					totalAmount += amount;
				}
			})
		}else{
			this.currentIngredient?.acid_components.map((c) => {
				if(c.acid_component?.acid_family != null){
					datapoints.push({
						'family': c.acid_component.acid_family.id,
						'amount': c.amount
					});
					totalAmount += c.amount;
				}
			})
		}
		

		let acidFamilyBreakdown = new Map();
		datapoints.map((datapoint) => {
			let family = datapoint['family'];
			let amount = datapoint['amount'];
			let hasFamily = acidFamilyBreakdown.has(family);
			if(!hasFamily){
				acidFamilyBreakdown.set(family, amount)
			}else{
				let currentAmount = acidFamilyBreakdown.get(family);
				acidFamilyBreakdown.set(family, currentAmount + amount);
			}
		})
		let acidFamilyPercentages = [];

		acidFamilyBreakdown.forEach((v,k) => {
			let acidFamily = this.acidFamilies.find((v) => v.id == k);
			if(acidFamily==null) return;
			let familyPercentage = parseFloat(v).toFixed(4);
			if(isNaN(familyPercentage)){
				familyPercentage = 0;
			}
			acidFamilyPercentages.push({
				'family': acidFamily,
				'color': acidFamily.hex_color,
				'percentage': parseFloat(familyPercentage)
			})
		});
		return acidFamilyPercentages.sort((af1, af2) => {
			return parseFloat(af1['percentage']) > parseFloat(af2['percentage']) ? -1 : 1;
		});
	}

	@action deleteChemicalComponent(uuid){
		let chemicalComponentIdx = this.ingredientData.chemicalComponents.findIndex((c) => c.$.uuid == uuid);
		if(chemicalComponentIdx != -1){
			this.fetching = true;
			this.ingredientData.chemicalComponents.splice(chemicalComponentIdx, 1);
			this.fetching = false;
		}
	}

	@action deleteAcidComponent(uuid){
		let acidComponentIdx = this.ingredientData.acidComponents.findIndex((c) => c.$.uuid == uuid);
		if(acidComponentIdx != -1){
			this.fetching = true;
			this.ingredientData.acidComponents.splice(acidComponentIdx, 1);
			this.fetching = false;
		}
	}

	@action deletePsychologicalRanking(uuid){
		let psychologicalRankingIdx = this.ingredientData.psychologicalRankings.findIndex((p) => p.$.uuid == uuid);
		if(psychologicalRankingIdx != -1){
			this.fetching = true;
			this.ingredientData.psychologicalRankings.splice(psychologicalRankingIdx, 1);
			this.fetching = false;
		}
	}

	@action deletePhysicalRanking(uuid){
		let physicalRankingIdx = this.ingredientData.physicalRankings.findIndex((p) => p.$.uuid == uuid);
		if(physicalRankingIdx != -1){
			this.fetching = true;
			this.ingredientData.physicalRankings.splice(physicalRankingIdx, 1);
			this.fetching = false;
		}
	}

	save = async () => {
		let res = await this.ingredientForm.validate();
		if(res.hasError) return;

		let newFileMap = this.ingredientData.uploadedFiles.$.filter((file) => file.id == null).map((file) => {
			return {
				uuid: uuidv4(),
				file: file.file,
			}
		})
		let ingredientPayload = {
			has_avatar: this.ingredientData.currentImageUrl.value != null,
			avatar_file_type: this.ingredientData.currentImageFile.value != null ? this.ingredientData.currentImageFile.value.type: null,
			name: this.ingredientData.name.value,
			latin_name: this.ingredientData.latinName.value,
			origin: this.ingredientData.origin.value,
			ingredient_type_id: this.ingredientData.selectedIngredientType.value,
			benchmark_price_per_15ml: parseFloat(this.ingredientData.benchmarkPrice.value),
			botanical_family: this.ingredientData.botanicalFamily.value,
			part_of_plant: this.ingredientData.partOfPlant.value,
			safety_note: this.ingredientData.safetyNote.value,
			conservation_status_id: this.ingredientData.selectedConservationStatus.value,
			profile: this.ingredientData.profile.value,
			therapeutic_properties: this.ingredientData.therapeuticProperties.value,
			comedogenic_scale: this.ingredientData.comedogenicScale.value,
			synergy: this.ingredientData.synergy.value,
			energetics: this.ingredientData.energetics.value,
			chemical_components: this.ingredientData.chemicalComponents.map((c) => {
				return {
					id: c.$.id,
					chemical_component_id: c.$.chemicalComponentId.value,
					amount: parseFloat(c.$.amount.value)
				}
			}),
			acid_components: this.ingredientData.acidComponents.map((c) => {
				return {
					id: c.$.id,
					acid_component_id: c.$.acidComponentId.value,
					amount: parseFloat(c.$.amount.value)
				}
			}),
			scent_note_id: this.ingredientData.selectedScentNote.value,
			psychological_rankings: this.ingredientData.psychologicalRankings.map((p) => {
				return {
					id: p.$.id,
					ranking_id: p.$.selectedEffect.value,
					score: p.$.selectedScore.value
				}
			}),
			physical_rankings: this.ingredientData.physicalRankings.map((p) => {
				return {
					id: p.$.id,
					ranking_id: p.$.selectedEffect.value,
					score: p.$.selectedScore.value
				}
			}),
			formulation_guidance: this.ingredientData.formulationGuidance.value,
			ingredient_notes: this.ingredientData.ingredientNotes.value,
			files: newFileMap.map((fileEntry) => {
				return {
					uuid: fileEntry.uuid,
					file_type: fileEntry.file.type,
					name: fileEntry.file.name
				}
			}),
			legacy_item_code: this.ingredientData.legacyItemCode.value
		}

		this.fetching = true;

		try{
			let response = null;
			if(this.editMode){
				response = await this.ingredientApi.updateIngredient(this.currentIngredient.id, ingredientPayload)
			}else{
				response = await this.ingredientApi.newIngredient(ingredientPayload);
				let newPath = `/ingredients/${response.ingredient.id}`;
  				window.history.pushState(null, null, newPath);
			}
			let avatarPresignedPost = response.avatar_presigned_post;
			if(avatarPresignedPost != null && this.ingredientData.currentImageFile.value != null){
				try{
					let avatarS3Response = await this.s3Api.uploadToS3(this.ingredientData.currentImageFile.value, avatarPresignedPost);
				}
				catch(e){
					let uploadFileErrorMsg = `${this.appStore.i18n.t('error.uploading')} ${this.ingredientData.currentImageFile.value.name}`
					this.appStore.alertError(uploadFileErrorMsg);
				}
			}

			let filesPresignedPost = response.files_presigned_post;
			for(let fileToPost of filesPresignedPost){
				let uuid = fileToPost.uuid;
				let matchingFileEntry = newFileMap.find((f) => f.uuid == uuid);
				if(matchingFileEntry != null){
					try{
						let fileS3Response = await this.s3Api.uploadToS3(matchingFileEntry.file, fileToPost.presigned_post);
					}catch(e){
						let uploadFileErrorMsg = `${this.appStore.i18n.t('error.uploading')} ${matchingFileEntry.file.name}`;
						this.appStore.alertError(uploadFileErrorMsg);
					}
					
				}
			}
			
			this.currentIngredient = response.ingredient;
			this.editMode = false;
			this.addMode = false;
			this.viewMode = true;
			this.appStore.changesSaved();
			this.appStore.displaySaveDialog();
		}catch(e){
			console.log(e);
		}finally{
			this.fetching = false;
		}
		
	}

	@computed get isHomeUser(){
		return this.appStore.isHomeUser;
	}

	fetchLinkedEvidenceForIngredient(ingredientId){
		if(this.isHomeUser) return;
		this.evidenceApi.searchEvidence(1, ingredientId)
			.then((response) => {
				this.linkedEvidence = response.evidence;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	viewEvidence(evidenceId){
		this.appStore.goToViewEvidence(evidenceId);
	}

	formSync = async (id, editMode=false) => {
		try{
			this.fetching = false;
			let response = await this.ingredientApi.getIngredientById(id);
			this.currentIngredient = response.ingredient;
			this.fetchLinkedEvidenceForIngredient(this.currentIngredient.id);
			if(!editMode){
				this.editMode = false;
				this.addMode = false;
				this.viewMode = true;
			}else{
				this.toggleEditMode();
			}
			this.loadComplete();
			
		}catch(e){
			console.log(e);
		}finally{
			this.fetching = false
		}
	}

	cancelEditMode(){
		this.addMode = false;
		this.editMode = false;
		this.viewMode = true;
	}


	loadComplete(){
		this.loaded = true;
	}

	@action toggleEditMode(){
		let currentIngredient = this.currentIngredient;
		this.ingredientData.name.value = currentIngredient.name;
		this.ingredientData.latinName.value = currentIngredient.latin_name;
		this.ingredientData.origin.value = currentIngredient.origin;
		this.ingredientData.selectedIngredientType.value = currentIngredient.ingredient_type?.id;
		this.ingredientData.benchmarkPrice.value = currentIngredient.benchmark_price_per_15ml;
		this.ingredientData.botanicalFamily.value = currentIngredient.botanical_family;
		this.ingredientData.partOfPlant.value = currentIngredient.part_of_plant;
		this.ingredientData.safetyNote.value = currentIngredient.safety_note;
		this.ingredientData.selectedConservationStatus.value = currentIngredient.conservation_status?.id;
		this.ingredientData.therapeuticProperties.value = currentIngredient.therapeutic_properties;
		this.ingredientData.comedogenicScale.value = currentIngredient.comedogenic_scale;
		this.ingredientData.synergy.value = currentIngredient.synergy;
		this.ingredientData.energetics.value = currentIngredient.energetics;
		this.ingredientData.synergy.value = currentIngredient.synergy;
		this.ingredientData.energetics.value = currentIngredient.energetics;
		this.ingredientData.profile.value = currentIngredient.profile;
		this.ingredientData.ingredientNotes.value = currentIngredient.ingredient_notes;
		this.ingredientData.formulationGuidance.value = currentIngredient.formulation_guidance;
		this.ingredientData.selectedScentNote.value = currentIngredient.scent_note?.id;
		currentIngredient.psychological_rankings.map((p) => {
			let psychologicalRanking = this.generatePsychologicalRanking(p.psychological_ranking.id, p.score, p.id)
			this.ingredientData.psychologicalRankings.push(psychologicalRanking)
		});
		currentIngredient.physical_rankings.map((p) => {
			let physicalRanking = this.generatePhysicalRanking(p.physical_ranking.id, p.score, p.id);
			this.ingredientData.physicalRankings.push(physicalRanking);
		});
		this.ingredientData.currentImageUrl.value = currentIngredient.photo_url;
		this.ingredientData.chemicalComponents = currentIngredient.chemical_components.map((c) => {
			let chemicalComponent = this.generateChemicalComponent(c.chemical_component.id, c.amount, c.id)
			this.chemicalComponents.push(c.chemical_component);
			return chemicalComponent;
		});
		this.ingredientData.acidComponents = currentIngredient.acid_components.map((c) => {
			let acidComponent = this.generateAcidComponent(c.acid_component.id, c.amount, c.id)
			this.acidComponents.push(c.acid_component);
			return acidComponent;
		})
		this.ingredientData.uploadedFiles.$ = currentIngredient.files.map((f) => {
			return {
				id: f.id,
				name: f.name,
				file_url: f.file_url,
				file: null
			}
		})
		this.ingredientData.legacyItemCode.value = currentIngredient.legacy_item_code;

		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	@action deleteFile(id){
		let confirmMsg = this.appStore.i18n.t('user.manage-ingredient.confirm-delete-file-message');
		let confirmed = window.confirm(confirmMsg);
		if(confirmed){
			this.ingredientApi.deleteIngredientFile(id)
				.then((response) => {
					this.currentIngredient.files = this.currentIngredient.files.filter((f) => f.id != id);
				})
				.catch((error) => {
					console.log(error);
				})
		}
	}

	@action toggleFavouriteIngredient(){
		this.fetching = true;
		this.ingredientApi.patchIngredient(this.currentIngredient.id, {
			favourited: !this.currentIngredient.favourited
		})
			.then((response) => {
				this.currentIngredient = response.ingredient;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action getPieColorSlice(family){
		let sliceColor = 'black'
		Object.entries(this.chemicalFamilyPieData).map((entry) => {
			if(entry[1]['x'] == family){
				sliceColor = entry[1]['color'];
			}
		})
		return sliceColor;
	}

	@action getAcidPieColorSlice(family){
		let sliceColor = 'black'
		Object.entries(this.acidFamilyPieData).map((entry) => {
			if(entry[1]['x'] == family){
				sliceColor = entry[1]['color'];
			}
		})
		return sliceColor;
	}

	@computed get hasEditAccessToIngredient(){
		if(this.currentIngredient == null) return false;
		if(this.currentIngredient.public){
			if(this.appStore.isSuperAdministrator){
				return true;
			}else{
				return false;
			}
		}else{
			let currentUser = this.appStore.currentUser;
			return this.currentIngredient.user_id == currentUser.id;
		}
	}

	@action duplicateIngredient(){
		if(this.currentIngredient == null) return;
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-ingredient-library.duplicate-confirm'));
		if(confirmed){
			this.fetching = true;
			this.ingredientApi.duplicateIngredient(this.currentIngredient.id)
				.then((response) => {
					this.appStore.displaySaveDialog();
					this.appStore.goToMyIngredientLibrary();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}

	@action showFamilyDialog(props){
	 	let familyId = props.datum.family.id;
	 	let chemicalFamily = this.chemicalFamilies.find((f) => f.id == familyId);
	 	if(chemicalFamily != null){
	 		this.showChemicalFamilyModal = true;
	 		this.currentChemicalFamily = chemicalFamily;
	 	}

	 }

	 @action showAcidFamilyDialog(props){
	 	let familyId = props.datum.family.id;
	 	let acidFamily = this.acidFamilies.find((f) => f.id == familyId);
	 	if(acidFamily != null){
	 		this.showAcidFamilyModal = true;
	 		this.currentAcidFamily = acidFamily;
	 	}
	 }

	 @action hideFamilyModal(){
	 	this.showChemicalFamilyModal = false;
	 	this.currentChemicalFamily = null;
	 }

	 @action hideAcidFamilyModal(){
	 	this.showAcidFamilyModal = false;
	 	this.currentAcidFamily = null;
	 }

	 @computed get currentChemicalFamilyComponents(){
	 	if(this.currentChemicalFamily == null) return [];
	 	if(this.currentIngredient == null) return null;
	 	return this.currentIngredient.chemical_components.filter((c) => c.chemical_component.chemical_family.id == this.currentChemicalFamily.id).map((c) => c.chemical_component);
	 }

	 @computed get currentAcidFamilyComponents(){
	 	if(this.currentAcidFamily == null) return [];
	 	if(this.currentIngredient == null) return null;
	 	return this.currentIngredient.acid_components.filter((c) => c.acid_component.acid_family.id == this.currentAcidFamily.id).map((c) => c.acid_component);
	 }

	 @computed get editIngredientChemicalComponents(){
	 	return this.ingredientData.chemicalComponents;
	 }

	 @computed get viewIngredientChemicalComponents(){
	 	if(this.currentIngredient == null) return [];
	 	return this.currentIngredient.chemical_components.sort((c1, c2) => {
	 		let amount1 = parseFloat(c1.amount);
	 		let amount2 = parseFloat(c2.amount);
	 		return amount1 > amount2 ? -1 : 1;
	 	})
	 }

	 @computed get viewIngredientAcidComponents(){
	 	if(this.currentIngredient == null) return [];
	 	return this.currentIngredient.acid_components.sort((a1, a2) => {
	 		let amount1 = parseFloat(a1.amount);
	 		let amount2 = parseFloat(a2.amount);
	 		return amount1 > amount2 ? -1 : 1;
	 	})
	 }

	 @action compareIngredients(){
	 	if(this.currentIngredient == null) return null;
	 	this.appStore.goToComparisonTool([this.currentIngredient.id]);
	 }

	 @computed get chemicalComponentBreakdownByFamily(){
		if(this.currentIngredient == null) return [];
		let chemicalComponentBreakdown = this.currentIngredient.chemical_components;

		let chemicalBreakdownMap = new Map();
		chemicalComponentBreakdown.map((entry) => {
			let key = entry.chemical_component.chemical_family.name;
			if(chemicalBreakdownMap.has(key)){
				let existingEntries = chemicalBreakdownMap.get(key);
				chemicalBreakdownMap.set(key, [...existingEntries, entry]);
			}else{
				chemicalBreakdownMap.set(key, [entry]);
			}
		});
		let results = Array.from(chemicalBreakdownMap);
		return results.sort((cfBreakdown1, cfBreakdown2) => {
			let totalBreakdown1 = cfBreakdown1[1].map((component) => component.amount).reduce((sum, x) => sum + x, 0)
			let totalBreakdown2 = cfBreakdown2[1].map((component) => component.amount).reduce((sum, x) => sum + x, 0)
			return totalBreakdown1 > totalBreakdown2 ? -1 : 1;
		})
	}

	@computed get acidComponentBreakdownByFamily(){
		if(this.currentIngredient == null) return [];
		let acidComponentBreakdown = this.currentIngredient.acid_components;

		let acidBreakdownMap = new Map();
		acidComponentBreakdown.map((entry) => {
			let key = entry.acid_component.acid_family.name;
			if(acidBreakdownMap.has(key)){
				let existingEntries = acidBreakdownMap.get(key);
				acidBreakdownMap.set(key, [...existingEntries, entry]);
			}else{
				acidBreakdownMap.set(key, [entry]);
			}
		});
		let results = Array.from(acidBreakdownMap);
		return results.sort((cfBreakdown1, cfBreakdown2) => {
			let totalBreakdown1 = cfBreakdown1[1].map((component) => component.amount).reduce((sum, x) => sum + x, 0)
			let totalBreakdown2 = cfBreakdown2[1].map((component) => component.amount).reduce((sum, x) => sum + x, 0)
			return totalBreakdown1 > totalBreakdown2 ? -1 : 1;
		})
	}

	@action onShowChemicalComponentBreakdown(){
		this.showChemicalComponentBreakdown = true;
	}

	@action onCloseChemicalComponentBreakdown(){
		this.showChemicalComponentBreakdown = false;
	}

	@action onShowAcidComponentBreakdown(){
		this.showAcidComponentBreakdown = true;
	}

	@action onCloseAcidComponentBreakdown(){
		this.showAcidComponentBreakdown = false;
	}

	@computed get currentConservationStatusDescription(){
		if(this.ingredientData.selectedConservationStatus.value == null) return null;
		let matchingConservationStatus = this.conservationStatuses.find((c) => c.id == this.ingredientData.selectedConservationStatus.value);
		return matchingConservationStatus?.description;
	}

	sortByDescendingPercentageEntry(entry1, entry2){
		return entry1.percentage > entry2.percentage ? -1 : 1;
	}

	@computed get flattenChemicalComponentBreakdownByFamily(){		
		let results = [];
		this.chemicalComponentBreakdownByFamily.map((entry) => {
			if(!results.includes(entry[0])){
				results.push({
					type: 'title',
					name: entry[0]
				})
			}
			entry[1].sort(this.sortByDescendingPercentageEntry).map((componentEntry) => {
				results.push({
					type: 'data',
					entry: componentEntry
				})
			})
		});
		return results;
	}

	@computed get flattenAcidComponentBreakdownByFamily(){		
		let results = []
		this.acidComponentBreakdownByFamily.map((entry) => {
			if(!results.includes(entry[0])){
				results.push({
					type: 'title',
					name: entry[0]
				})
			}
			entry[1].sort(this.sortByDescendingPercentageEntry).map((componentEntry) => {
				results.push({
					type: 'data',
					entry: componentEntry
				})
			})
		});
		return results;
	}

	@computed get selectedScentNoteColor(){
		let baseColor = '#000';
		let currentScentNote = this.editMode || this.addMode ? this.ingredientData.selectedScentNote.value : this.currentIngredient?.scent_note?.id;

		if(currentScentNote== null) return baseColor;
		let foundScentNote = this.scentNotes.find((s) => s.id == currentScentNote);
		if(foundScentNote == null) return baseColor;
		return foundScentNote.hex_color;
	}

	@action getAvatarUrl(ingredient){
		return getIngredientAvatarUrl(ingredient);
	}

	@computed get canDownloadPDF(){
		return true;
	}

	@computed get ingredientPDFName(){
		let dateTime = moment().format('DD_MM_YYYY HH:MM');
		if(this.currentIngredient == null) return `LabAroma_${dateTime}.pdf`
		return `LabAroma_${this.currentIngredient.name.replace(' ', '_')}_${dateTime}.pdf`
	}

	@action setRenderedPieChartUrl(base64url){
		this.renderedPieChartBase64Url = base64url;
	}

	@action setRenderedAcidPieChartUrl(base64url){
		this.renderedAcidPieChartBase64Url = base64url;
	}

	@action setIngredientImageUrl(base64url){
		this.ingredientImageBase64Url = base64url;
	}

	@action markChange(){
		this.appStore.markDirty();
	}

	onChangeDownloadOption(id, checked){
		let pdfDownloadIdx = this.pdfDownloadOptions.findIndex((p) => p.id == id);
		if(pdfDownloadIdx != -1){
			let pdfOption = this.pdfDownloadOptions[pdfDownloadIdx];
			if(pdfOption.id == 0){
				this.pdfDownloadOptions = this.pdfDownloadOptions.map((p) => {
					p.checked = checked;
					return p;
				})
			}else{
				this.pdfDownloadOptions[pdfDownloadIdx].checked = checked;
				if(!checked){
					this.pdfDownloadOptions[0].checked = checked;
				}
			}
		}
	}


	@computed get currentPDFDownloadOptions(){
		let currentPDFDownloadOptions = this.pdfDownloadOptions;

		if(!this.showEnergetics){
			currentPDFDownloadOptions = currentPDFDownloadOptions.filter((x) => x.id != 3);
		}

		return currentPDFDownloadOptions;
	}

	resetPDFModal(){
		this.currentAction = null;
		this.showPDFDownloadModal = false;
		this.pdfGenerating = false;
	}

	showPDFDownload(action){
		this.showPDFDownloadModal = true;
		this.currentAction = action;
	}

	toggleShowPDFDownload(){
		this.showPDFDownloadModal = !this.showPDFDownloadModal;
	}

	togglePDFGenerating(){
		this.pdfGenerating = !this.pdfGenerating;
	}

	showDownloadSaveDialog(){
		this.appStore.displaySaveDialog(null, this.appStore.i18n.t('common.download-pdf-automatically'), true);
	}

}

export default ManageIngredientsStore;