import React, { Component } from 'react';
import {inject, observer} from 'mobx-react';

import { withTranslation } from 'react-i18next';
import {Label, Body, SecondaryWebLargerTitle} from '../common/text';
import LeftBlockRightContent from '../common/left-block-right-content';
import {PasswordInput} from '../common/input'

import {Button} from '../common/button';
import { HorizontalFormColumn } from '../common/form';

@inject('resetPasswordStore', 'appStore')
@observer
class PasswordReset extends Component{
	constructor(props){
		super(props);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
		this.goToLogin = this.goToLogin.bind(this);
		this.onResetPassword = this.onResetPassword.bind(this);
		this.goToSignUp = this.goToSignUp.bind(this);
	}

	componentDidMount(){
		let appStore = this.props.appStore;
		if(appStore.isAuthenticated){
			this.props.appStore.goToHome();
		}

		this.props.resetPasswordStore.setToken(this.props.token);
	}

	componentWillUnmount(){
		let resetPasswordStore = this.props.resetPasswordStore;
		resetPasswordStore.initStore();
	}

	onChangePassword(e){
		let resetPasswordStore = this.props.resetPasswordStore;
		resetPasswordStore.onChangePassword(e.target.value);
	}

	onChangeConfirmPassword(e){
		let resetPasswordStore = this.props.resetPasswordStore;
		resetPasswordStore.onChangeConfirmPassword(e.target.value);
	}

	goToLogin(){
		this.props.appStore.goToLogin();
	}

	onResetPassword(){
		let resetPasswordStore = this.props.resetPasswordStore;
		resetPasswordStore.resetPassword();
	}

	goToSignUp(){
		this.props.appStore.goToPickAPlan();
	}

	render(){
		let resetPasswordStore = this.props.resetPasswordStore;
		let translate = this.props.t;
		return (
			<LeftBlockRightContent
				leftBgColor='flat-blue-25'
				left={
					<>
						<SecondaryWebLargerTitle className="text-center text-black lg:text-left">{translate('reset-password.title.part1')} <br /> {translate('reset-password.title.part2')} </SecondaryWebLargerTitle>
						<Body className="text-center text-black lg:text-left mt-8">{translate('reset-password.description')}</Body>
					</>
				}
				right={
					<div className="flex flex-1 flex-col justify-between">
						<div className="flex"></div>
						<div className="flex flex-col">
							<HorizontalFormColumn>
								<Label>{translate('reset-password.new-password.label')}</Label>
								<PasswordInput 
									onChange={this.onChangePassword}
									value={resetPasswordStore.password.value}
									error={resetPasswordStore.password.error}
									placeholder={translate('reset-password.new-password.placeholder')}
								/>
							</HorizontalFormColumn>
							<HorizontalFormColumn>
								<Label>{translate('reset-password.confirm-password.label')}</Label>
								<PasswordInput 
									onChange={this.onChangeConfirmPassword}
									value={resetPasswordStore.confirmPassword.value}
									error={resetPasswordStore.confirmPassword.error}
									placeholder={translate('reset-password.confirm-password.placeholder')}
								/>
							</HorizontalFormColumn>
						</div>
						<div className="flex flex-col mb-6">
							<Button
							 className="uppercase"
							 width='w-full'
							 bgColor='flat-blue'
							 fetching={this.props.appStore.loggingIn}
							 onClick={this.onResetPassword}
							 disabled={!resetPasswordStore.isValidForm}>{translate('reset-password.button')}</Button>
							 <div className="flex flex-1 flex-row mb-4 mt-2">
								<Body 
									className="inline-flex tracking-wider" 
									onClick={this.goToSignUp}>{translate('reset-password.no-account.part1')}&nbsp;</Body>
								<div className="inline-flex bold-font underline hover:text-flat-blue cursor-pointer" onClick={this.goToSignUp}>{translate('reset-password.no-account.part2')}</div>
							</div>
						</div>
					</div>
				}
			/>			
		)
	}
}

export default withTranslation()(PasswordReset);