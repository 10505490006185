import BaseApi from '../services/base';

class CurrencyApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(){
		return this.fetch(`v1/currencies`, {
			method: 'GET'
		})
	}

	getCurrencySpotRates(currencies){
		return fetch(`https://api.currencyapi.com/v3/latest?apikey=cur_live_BmgNkZJdG9X0rPH0p1mRpFJWWpHZqiIBlDZL5wjz&currencies=${currencies}`);
	}
}

export default CurrencyApi;