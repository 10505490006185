import BaseApi from '../services/base';

class CouponApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getCoupon(discountCode){
		return this.fetch(`v1/coupons/${discountCode}`, {
			method: 'GET',
			auth: true
		})
	}
}

export default CouponApi;