import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';

import { withTranslation } from 'react-i18next';

import {SearchIcon, BellIcon} from '../common/icon';
import {SearchBar} from '../common/input';
import NotificationCenter from '../elements/notification-center';
import {SecondaryAppMediumTitle, ExtraSmallBody, SmallBody} from '../common/text';
import {
	UserAvatar,
	ProfileSettings
} from '../user/profile';
import Navbar from '../nav/navbar';


@observer
class NotificationBell extends Component{
	render(){
		let hasNotifications = this.props.hasNotifications;
		return (
			<div className="flex items-center ml-4 mr-4">
				<div className="relative">
					<BellIcon className="w-5 h-5" />
					{hasNotifications && <div className="absolute top-0 right-0 w-1 h-1 bg-error" style={{ marginRight: '0.16rem', marginTop:'0.35rem'}}></div>}
				</div>
			</div>
		)
	}
}

@observer
class TopBar extends Component{
	render(){
		let translate = this.props.t;
		let appStore = this.props.appStore;
		let totalNotifications = appStore.totalNotifications;
		return (
			<div className="flex">
				<div className="flex flex-1 flex-col lg:flex-row items-center lg:justify-end flex-row">
					{!appStore.isOnAdvancedSearch && 
						<>
							<div onClick={() => appStore.goToAdvancedSearch()} className="flex w-auto cursor-pointer mr-4"><ExtraSmallBody className="text-nav-active-color bold-font tracking-widest">{translate('common.advanced-search')}</ExtraSmallBody></div>
							<div className="flex w-full lg:w-1/3">
								<SearchBar 
									t={this.props.t} 
									value={appStore.searchQuery}
									onTriggerSearch={() => appStore.goToAdvancedSearchWithQuery()}
									onChange={(e) => appStore.changeSearchQuery(e.target.value)}
									/>
							</div>
						</>
					}
					
					<div className="flex flex-row w-full mt-2 lg:mt-0 lg:w-auto justify-end lg:justify-start">
						{(appStore.isOrganisationAdministrator || appStore.isSuperAdministrator) && 
							<div className="flex relative ml-0 lg:ml-2 cursor-pointer" onClick={() => appStore.toggleNotificationDropdown()}>
								<NotificationBell 
									hasNotifications={appStore.hasNotifications}
									 />
									{appStore.showNotificationDropdown && 
										<div className="absolute full-shadow z-50 top-0 right-0 w-360px overflow-y-scroll px-8 py-8 mt-12 bg-white rounded-lg" style={{ maxHeight: '400px' }}>
											<NotificationCenter
												t={translate}
												tooltipText={appStore.getTooltip('tooltip-notifications')}
												notifications={appStore.notifications}
												totalNotifications={appStore.totalNotifications}
												goToNotification={(notification) => appStore.goToNotification(notification)}
											/>
										</div>
									}
							</div>
						}
						<div className="flex ml-0 lg:ml-2"><UserAvatar noborder t={this.props.t} /></div>
					</div>
				</div>
			</div>
		)
	}
}


@inject('appStore')
@observer
class Main extends Component{
	constructor(props){
	super(props);
	}

	render(){
		let translate = this.props.t;
		return (
			<div className="flex flex-1 flex-col lg:flex-row">
				<Navbar />
				<div className="flex flex-1 flex-col" onMouseLeave={this.checkForUnsavedChanges}>
					<div className="flex flex-1 flex-col bg-flat-gray lg:rounded-xl ml-0 mr-0 lg:mt-4 lg:mb-4 lg:mr-4 px-8 lg:px-16 py-8">
						<TopBar 
							appStore={this.props.appStore} t={translate} />
						<ProfileSettings t={translate} />
						<div className="flex flex-col">
							{this.props.children}
						</div>
						<div className="flex flex-1 flex-row items-center">
							<div className="flex w-full justify-end flex-col items-center">
								<div className="flex flex-1 text-center mt-16">
				    				<SmallBody>© Copyright LabAroma {new Date().getFullYear()}</SmallBody>
				    			</div>
				    			<div className="flex flex-1 items-center justify-center text-center mt-4 w-1/2">
				    				<SmallBody>{translate('footer.browser-support.part1')} <br /> {translate('footer.browser-support.part2')}</SmallBody>
				    			</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(Main);