import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {SecondaryAppMediumTitle, SmallBody, TertiaryAppSmallTitle} from '../../common/text'
import { withAuthorisation } from '../../common/authorisation';

import {Button} from '../../common/button';

import {EditIcon, SaveIcon, ExitIcon} from '../../common/icon';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormSelect,
	FormPasswordInput
} from '../../elements/form';
import Main from '../../elements/main';
import {FullGrid, Grid} from '../../elements/grid';
import {ArchivedStatus} from '../../elements/list';

import {CosmeticRegulationPermission, ChemistryPermission, LanguagesPermission, UsersPermission} from '../../elements/permission';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, USERS, DISPLAY_DATE_FORMAT } from '../../../services/util';

import moment from 'moment';

@inject('manageUserStore')
@observer
class ManageUser extends Component{
	constructor(props){
		super(props);
		
	}

	componentDidMount(){
		let manageUserStore = this.props.manageUserStore;
		manageUserStore.setUserId(this.props.userId);

		manageUserStore.fetchRegions();
		manageUserStore.fetchCountries();
		manageUserStore.fetchRoles();
		manageUserStore.fetchOrganisations();
		manageUserStore.fetchPermissions();
		manageUserStore.fetchPlans();
	}

	componentWillUnmount(){
		let manageUserStore = this.props.manageUserStore;
		manageUserStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let manageUserStore = this.props.manageUserStore;
		if(manageUserStore.currentUser == null) return null;
		let currentUser = manageUserStore.currentUser;
		let currentPlan = currentUser.subscription != null ? currentUser.subscription.plan : null;
		let editMode = manageUserStore.editMode;
		return (
			<Main>
				<div className="flex w-full justify-end mt-8">
					{!editMode && 
						<Button
							width="w-auto"
							height="h-12"
							className="px-4 rounded-lg"
							bgColor="pink-coral"
							onClick={() => manageUserStore.toggleEditMode()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{translate('manage-user.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
							</div>
						</Button>
					}
					{editMode && 
						<>
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="pink-coral"
								disabled={!manageUserStore.validForm}
								fetching={manageUserStore.fetching}
								onClick={() => manageUserStore.saveUser()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('manage-user.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
							<Button
								width="w-auto"
								height="h-12"
								className="ml-4 px-4 rounded-lg text-pink-coral"
								bgColor="bg-list-text"
								fetching={manageUserStore.fetching}
								onClick={() => manageUserStore.cancelEditMode()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('manage-user.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
								</div>
							</Button>
						</>
					}
				</div>
				<div className="flex flex-col flex-col lg:flex-row mt-8">
					<div className="flex w-full lg:w-3/5"> 
						<FullGrid>
							<div className="flex flex-row items-center">
								<div className="flex"><SecondaryAppMediumTitle>{translate('manage-user.account-title')} </SecondaryAppMediumTitle></div>
								<div className="flex flex-1 justify-end">
									<ArchivedStatus t={translate} archived={manageUserStore.currentUser.archived} />
								</div>
							</div>
							<div className="grid grid-cols-2 gap-8 mt-4 overflow-x-scroll">
								<DataRow label={translate('manage-user.name.label')}>
									{!editMode && <DataEntry>{currentUser.name}</DataEntry>}
									{editMode && 
										<FormInput 
											value={manageUserStore.userData.name.value}
											error={manageUserStore.userData.name.error}
											onChange={(e) => manageUserStore.userData.name.onChange(e.target.value)}
										/>
									}
								</DataRow>
								<DataRow label={translate('manage-user.email.label')}>
									{!editMode && <DataEntry>{currentUser.email}</DataEntry>}
									{editMode && 
										<FormInput 
											value={manageUserStore.userData.email.value}
											error={manageUserStore.userData.email.error}
											onChange={(e) => manageUserStore.userData.email.onChange(e.target.value)}
										/>
									}
								</DataRow>
								<DataRow label={translate('manage-user.occupation.label')}>
									{!editMode && <DataEntry>{currentUser.occupation}</DataEntry>}
									{editMode && 
										<FormInput 
											value={manageUserStore.userData.occupation.value}
											error={manageUserStore.userData.occupation.error}
											onChange={(e) => manageUserStore.userData.occupation.onChange(e.target.value)}
										/>
									}
								</DataRow>
								<DataRow label={translate('manage-user.country.label')}>
									{!editMode && <DataEntry>{currentUser.country != null ? currentUser.country.name : ''}</DataEntry>}
									{editMode && 
										<FormSelect 
											options={manageUserStore.countryOptions}
											value={manageUserStore.userData.selectedCountry.value}
											onChange={(option) => manageUserStore.userData.selectedCountry.onChange(option.value)}
										/>
									}
								</DataRow>
								<DataRow label={translate('manage-user.region.label')}>
									{!editMode && <DataEntry>{currentUser.region != null ? currentUser.region.name : ''}</DataEntry>}
									{editMode && 
										<FormSelect 
											options={manageUserStore.regionOptions}
											value={manageUserStore.userData.selectedRegion.value}
											onChange={(option) => manageUserStore.userData.selectedRegion.onChange(option.value)}
										/>
									}
								</DataRow>
								{manageUserStore.appStore.isSuperAdministrator && 
									<>
										<DataRow label={translate('manage-user.organisation.label')}>
											{!editMode && <DataEntry>{currentUser.organisation != null ? currentUser.organisation.name : ''}</DataEntry>}
											{editMode && 
												<FormSelect 
													clearable
													options={manageUserStore.organisationOptions}
													value={manageUserStore.userData.selectedOrganisation.value}
													onChange={(option) => manageUserStore.userData.selectedOrganisation.onChange(option.value)}
												/>
											}
										</DataRow>
										<DataRow label={translate('manage-user.language.label')}>
											{!editMode && <DataEntry>{currentUser.language}</DataEntry>}
											{editMode && 
												<FormSelect 
													placeholder={translate('manage-user.language.placeholder')}
													options={manageUserStore.appStore.languageOptions}
													value={manageUserStore.userData.selectedLanguage.value}
													onChange={(option) => manageUserStore.userData.selectedLanguage.onChange(option.value)}
												/>
											}
										</DataRow>
										<DataRow label={translate('manage-user.user-role.label')}>
											{!editMode && <DataEntry>{currentUser.roles != null ? currentUser.roles[0].role_name : ''}</DataEntry>}
											{editMode && 
												<FormSelect 
													options={manageUserStore.roleOptions}
													value={manageUserStore.userData.selectedRole.value}
													onChange={(option) => manageUserStore.userData.selectedRole.onChange(option.value)}
												/>
											}
										</DataRow>
										<div className="col-span-2">
											<DataRow label={translate('manage-user.status.label')}>
												{!editMode && <DataEntry>{currentUser.archived ?  translate("superadmin.users.list.archived") : translate("superadmin.users.list.active")}</DataEntry>}
												{editMode && 
													<FormSelect 
														options={manageUserStore.statusOptions}
														value={manageUserStore.userData.selectedStatus.value}
														onChange={(option) => manageUserStore.userData.selectedStatus.onChange(option.value)}
													/>
												}
											</DataRow>
										</div>

										<DataRow label={translate('registration.step3.address-line-1.label')}>
											{!editMode && <DataEntry>{currentUser.billing_address_line_1}</DataEntry>}
											{editMode && 
												<FormInput 
													value={manageUserStore.userData.addressLine1.value}
													error={manageUserStore.userData.addressLine1.error}
													onChange={(e) => manageUserStore.userData.addressLine1.onChange(e.target.value)}
												/>
											}
										</DataRow>

										<DataRow label={translate('registration.step3.address-line-2.label')}>
											{!editMode && <DataEntry>{currentUser.billing_address_line_2}</DataEntry>}
											{editMode && 
												<FormInput 
													value={manageUserStore.userData.addressLine2.value}
													error={manageUserStore.userData.addressLine2.error}
													onChange={(e) => manageUserStore.userData.addressLine2.onChange(e.target.value)}
												/>
											}
										</DataRow>

										<DataRow label={translate('registration.step3.address-city.label')}>
											{!editMode && <DataEntry>{currentUser.billing_address_city}</DataEntry>}
											{editMode && 
												<FormInput 
													value={manageUserStore.userData.addressCity.value}
													error={manageUserStore.userData.addressCity.error}
													onChange={(e) => manageUserStore.userData.addressCity.onChange(e.target.value)}
												/>
											}
										</DataRow>

										<DataRow label={translate('registration.step3.address-state.label')}>
											{!editMode && <DataEntry>{currentUser.billing_address_state}</DataEntry>}
											{editMode && 
												<>
													{!manageUserStore.showStateDropdown && 
														<FormInput 
															value={manageUserStore.userData.addressState.value}
															error={manageUserStore.userData.addressState.error}
															onChange={(e) => manageUserStore.userData.addressState.onChange(e.target.value)}
														/>
													}
													{manageUserStore.showStateDropdown && 
														<FormSelect 
															options={manageUserStore.stateOptions()}
															value={manageUserStore.userData.selectedState.value}
															onChange={(option) => manageUserStore.userData.selectedState.onChange(option.value)}
														/>
													}
												</>
											}
										</DataRow>

										<DataRow label={translate('registration.step3.address-zip.label')}>
											{!editMode && <DataEntry>{currentUser.billing_address_zip}</DataEntry>}
											{editMode && 
												<FormInput 
													value={manageUserStore.userData.addressZip.value}
													error={manageUserStore.userData.addressZip.error}
													onChange={(e) => manageUserStore.userData.addressZip.onChange(e.target.value)}
												/>
											}
										</DataRow>

										<DataRow label={translate('registration.step3.address-country.label')}>
											{!editMode && <DataEntry>{currentUser.billing_country != null ? currentUser.billing_country.name : ''}</DataEntry>}
											{editMode && 
												<FormSelect 
													options={manageUserStore.countryOptions}
													placeholder={translate('registration.step3.address-country.placeholder')}
													value={manageUserStore.userData.selectedBillingCountry.value}
													onChange={(option) => manageUserStore.userData.selectedBillingCountry.onChange(option.value)}
												/>
											}
										</DataRow>


										<DataRow label={translate('registration.step3.tax-id.label')}>
											{!editMode && <DataEntry>{currentUser.vat_number}</DataEntry>}
											{editMode && 
												<FormInput 
													value={manageUserStore.userData.taxId.value}
													error={manageUserStore.userData.taxId.error}
													onChange={(e) => manageUserStore.userData.taxId.onChange(e.target.value)}
												/>
											}
										</DataRow>

										{editMode && 
											<div className="col-span-2">
												<DataRow label={translate('manage-user.password.label')}>
													<FormPasswordInput 
														placeholder='**********'
														value={manageUserStore.userData.password.value}
														error={manageUserStore.userData.password.error}
														onChange={(e) => manageUserStore.userData.password.onChange(e.target.value)}
													/>
												</DataRow>
												<DataRow label={translate('manage-user.confirm-password.label')}>
													<FormPasswordInput
														placeholder='**********'
														value={manageUserStore.userData.confirmPassword.value}
														error={manageUserStore.userData.confirmPassword.error}
														onChange={(e) => manageUserStore.userData.confirmPassword.onChange(e.target.value)}
													/>
												</DataRow>
											</div>
										}
									</>
								}
							</div>
							{editMode && manageUserStore.appStore.isSuperAdministrator && 
								<div className="flex flex-1 flex-col mt-4">
									<DataHeader noborder>{translate('manage-user.access.label')}</DataHeader>
									<div className="flex flex-1 grid grid-cols-4 gap-4 mt-2">
										<CosmeticRegulationPermission 
											checked={manageUserStore.hasCosmeticRegulationPermission}
											onChange={(e) => manageUserStore.setCosmeticRegulationPermission(e.target.checked)}
											 />
										<ChemistryPermission checked={manageUserStore.hasChemistryPermission} 
											onChange={(e) => manageUserStore.setChemistryPermission(e.target.checked)}/>
										<LanguagesPermission checked={manageUserStore.hasLanguagesPermission}
											 onChange={(e) => manageUserStore.setLanguagesPermission(e.target.checked)}/>
										<UsersPermission checked={manageUserStore.hasUsersPermission}
											 onChange={(e) => manageUserStore.setUsersPermission(e.target.checked)}/>
									</div>
								</div>
							}
							{manageUserStore.appStore.isSuperAdministrator && 
								<DataRow label={translate('manage-user.subscription-expiry.label')}>
									<div className="flex flex-col">
										<DataEntry>{manageUserStore.currentSubscriptionPlan} expires on {manageUserStore.subscriptionPeriodEnd}</DataEntry>
										{editMode && 
											<div className="flex flex-col">
												<div className="flex">
													<SmallBody className="text-error">{translate('manage-user.subscription-expiry.info')}</SmallBody>
												</div>
												<DataRow label={translate('manage-user.subscription-plan.label')}>
													
													<FormSelect 
														options={manageUserStore.planOptions}
														value={manageUserStore.userData.selectedPlan.value}
														onChange={(option) => manageUserStore.userData.selectedPlan.onChange(option.value)}
													/>
												</DataRow>
												<DataRow label={translate('manage-user.subscription-expiry.label')}>
													<FormInput 
														value={manageUserStore.userData.subscriptionExpiryDateTime.value}
														error={manageUserStore.userData.subscriptionExpiryDateTime.error}
														onChange={(e) => manageUserStore.userData.subscriptionExpiryDateTime.onChange(e.target.value)}
													/>
												</DataRow>
												<div className="flex mt-4">
													<Button
														width="w-auto"
														height="h-12"
														className="px-4 rounded-lg"
														bgColor="error"
														onClick={() => manageUserStore.changeSubscriptionExpiry()}
													>
														<div className="flex flex-row items-center justify-center">
															<div className="flex"><TertiaryAppSmallTitle>{translate('manage-user.manual-renew-button')} &nbsp;</TertiaryAppSmallTitle></div>
															<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
														</div>
													</Button>
												</div>
											</div>
									}
									</div>
								</DataRow>
							}
						</FullGrid>
					</div>
					<div className="flex w-full lg:w-2/5 flex-col mt-8 lg:mt-0 ml-0 lg:ml-8">
						<Grid>
							<SecondaryAppMediumTitle>{translate('manage-user.plan-title')}</SecondaryAppMediumTitle>

							<div className="flex-1 flex-row">
								<div className="grid grid-cols-3 mt-4">
									<DataHeader>{translate('manage-user.plan.label')}</DataHeader>
									<DataHeader>{translate('manage-user.period.label')}</DataHeader>
									<DataHeader>{translate('manage-user.renewal.label')}</DataHeader>
									{currentPlan != null && 
										<>
											<DataEntry>{currentPlan.name}</DataEntry>
											<DataEntry>{currentPlan.period}</DataEntry>
											<DataEntry>{moment(currentPlan.current_period_end).format(DISPLAY_DATE_FORMAT)}</DataEntry>
										</>
									}
									{currentPlan == null &&
										<div className="col-span-3">
											<DataEntry>{translate('manage-user.no-plan')}</DataEntry>
										</div>
									}
								</div>
							</div>
						</Grid>
						<Grid className="mt-8">
							<SecondaryAppMediumTitle>{translate('manage-user.plan-history-title')}</SecondaryAppMediumTitle>
							<div className="grid grid-cols-4 mt-4">
									<DataHeader>{translate('manage-user.plan.label')}</DataHeader>
									<DataHeader>{translate('manage-user.period.label')}</DataHeader>
									<DataHeader>{translate('manage-user.started.label')}</DataHeader>
									<DataHeader>{translate('manage-user.ended.label')}</DataHeader>
									{currentUser.plan_history.map((planHistory) => 
										<>
											<DataEntry>{planHistory.plan.name}</DataEntry>
											<DataEntry>{planHistory.plan.period}</DataEntry>
											<DataEntry>{moment(planHistory.started).format(DISPLAY_DATE_FORMAT)}</DataEntry>
											<DataEntry>{planHistory.ended != null && moment(planHistory.ended).format(DISPLAY_DATE_FORMAT)}</DataEntry>
										</>
									)}
									{currentUser.plan_history.length == 0 &&
										<div className="col-span-4">
											<DataEntry>{translate('manage-user.no-plan-history')}</DataEntry>
										</div>
									}
							</div>
						</Grid>
					</div>
				</div>
			</Main>
		)
	}
}
export default withTranslation()(withAuthorisation(ManageUser, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR], [USERS]));