import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {ToolTipItem, ToggleSwitch} from '../../common/input';
import {Tag} from '../../common/tag';
import { BottleIcon, ArrowRightIcon, ViewIcon, PlusIcon, EditIcon, EyeIcon, DuplicateIcon, ArchiveIcon, TickIcon} from '../../common/icon'
import { SmallBody, TertiaryAppSmallTitle} from '../../common/text';
import {Status} from '../../elements/list';
const colors = require('../../../color.js');

const getRandomColor = (id) => {
	let possibleColors = ["#eabe3b", "#eaacbd", "#b6ecc4"];
	return possibleColors[id % 3];
}

@observer
class BlendPreview extends Component{
	constructor(props){
		super(props);
	}

	render(){
		let blend = this.props.blend;
		let translate = this.props.t;
		let actionGridCols = this.props.canManage ? 4 : 2;
		if(blend == null) return null;

		let interactGridCols = 3;
		if(!this.props.canManage && !this.props.showAvailability){
			interactGridCols = 3
		}
		if(this.props.showAvailability){
			interactGridCols = 4;
		}

		let compliantStatus = translate('user.my-blend-library.compliance.good')
		let nonCompliantStatus = translate('user.my-blend-library.compliance.bad')

		let draftStatus = translate('user.my-blend-library.draft-status');
		let liveStatus = translate('user.my-blend-library.live-status');
		return (
			<div className="flex flex-1 bg-white rounded-lg py-8 px-6 h-auto mt-8 cursor-pointer" onClick={(e) => {
		 		e.stopPropagation();
		 		this.props.onView(blend.id)
		 	}}>
				<div className="flex flex-1 flex-row justify-between items-center">
					<div className="flex flex-row items-center  self-start">
						<div className="flex ml-2">
							<div className={`flex w-16 h-16 rounded-lg flex justify-center items-center`} style={{backgroundColor: getRandomColor(blend.id)}}>
								<BottleIcon className="w-1/2 h-1/2" color={colors['black']} />
							</div>
						</div>
						<div className="flex flex-col ml-8 w-32  self-start">
							<div className="flex"><SmallBody>{translate('user.my-blend-library.headers.name')}</SmallBody></div>
							<div className="flex mt-1"><TertiaryAppSmallTitle>{blend.name}</TertiaryAppSmallTitle></div>
						</div>
					</div>
					<div className="flex hidden lg:block flex-col ml-2 w-32 self-start">
						<div className="flex"><SmallBody>{translate('user.my-blend-library.headers.blend-category')}</SmallBody></div>
						<div className="flex mt-1"><TertiaryAppSmallTitle>{blend.blend_category?.name}</TertiaryAppSmallTitle></div>
					</div>
					<div className="flex hidden lg:block flex-col ml-2 w-32 self-start">
						<div className="flex"><SmallBody>{translate('user.my-blend-library.headers.last-edited')}</SmallBody></div>
						<div className="flex mt-1"><TertiaryAppSmallTitle>{blend.last_edited_at.format('DD MMM YYYY')}</TertiaryAppSmallTitle></div>
					</div>
					<div className="flex hidden lg:block flex-col ml-2 w-32 self-start">
						<div className="flex"><SmallBody>{translate('user.my-blend-library.headers.tags')}</SmallBody></div>
						<div className="flex mt-1 overflow-x-scroll">
							{blend.tags.map((tag) => 
								<Tag key={tag} name={tag} />
							)}
							{blend.tags.length == 0 && 
								<TertiaryAppSmallTitle>{translate('common.tags.notags')}</TertiaryAppSmallTitle>
							}
						</div>
					</div>
					{this.props.canViewCompliance && 'is_compliant' in blend && 
						<div className="flex flex-col ml-2 w-32 self-start">
							<div className="flex"><SmallBody>{translate('user.my-blend-library.headers.compliant')}</SmallBody></div>
							<div className="flex mt-1"><Status bgColor={blend.is_compliant ? "success-green" : "black-10"} text={blend.is_compliant ? compliantStatus : nonCompliantStatus} /></div>
						</div>
					}
					<div className="flex flex-col ml-2 w-32 self-start">
						<div className="flex"><SmallBody>{translate('user.my-blend-library.headers.status')}</SmallBody></div>
						<div className="flex mt-1"><Status bgColor={blend.draft ? "black-10" : "success-green"} text={blend.draft ? draftStatus : liveStatus} /></div>
					</div>
					<div className={`flex grid grid-cols-${interactGridCols} gap-8  self-start`}>
						<div className="col-span-2 hidden lg:block">
							<div className="flex flex-col items-center ">
								<div className="flex"><SmallBody>{translate('user.my-blend-library.headers.actions')}</SmallBody></div>

								<div className={`flex flex-row grid grid-cols-${actionGridCols} mt-2`}>
									{this.props.canManage && 
										<ToolTipItem
										 	onClick={(e) => {
										 		e.stopPropagation();
										 		this.props.onEdit(blend.id)
										 	}}
										 	title={translate('user.my-blend-library.edit-tooltip.title')}
										 	text={translate('user.my-blend-library.edit-tooltip.text')}
										 	icon={<EditIcon className="w-4 h-4" color={colors['black']} />}
										 />
									 }
									 <ToolTipItem
									 	onClick={(e) => {
									 		e.stopPropagation();
									 		this.props.onView(blend.id)
									 	}}
									 	title={translate('user.my-blend-library.view-tooltip.title')}
									 	text={translate('user.my-blend-library.view-tooltip.text')}
									 	icon={<EyeIcon className="w-4 h-4" color={colors['black']} />}
									 />

									 {this.props.canManage && 
									 	 <>
									 	 	<ToolTipItem
											 	onClick={(e) => {
											 		e.stopPropagation();
											 		this.props.onDuplicate(blend.id)
												 }}
											 	title={translate('user.my-blend-library.duplicate-tooltip.title')}
											 	text={translate('user.my-blend-library.duplicate-tooltip.text')}
											 	icon={<DuplicateIcon className="w-4 h-4" color={colors['black']} />}
											 />
											 {!blend.archived && 
											 	<ToolTipItem
												 	onClick={(e) => {
												 		e.stopPropagation();
												 		this.props.onArchive(blend.id)
												 	}}
												 	title={translate('user.my-blend-library.archive-tooltip.title')}
												 	text={translate('user.my-blend-library.archive-tooltip.text')}
												 	icon={<ArchiveIcon className="w-4 h-4" color={colors['black']} />}
												 />
											 }
											 {blend.archived && 
											 	<ToolTipItem
												 	onClick={(e) => {
												 		e.stopPropagation();
												 		this.props.onRestore(blend.id)
												 	}}
												 	title={translate('user.my-blend-library.restore-tooltip.title')}
												 	text={translate('user.my-blend-library.restore-tooltip.text')}
												 	icon={<TickIcon className="w-4 h-4" color={colors['black']} />}
												 />
											}
										</>
									}
								</div>
							</div>
						</div>
						{this.props.showAvailability && 
							<div className="flex hidden lg:block flex-col items-center">
								<div className="flex"><SmallBody>{translate('user.my-blend-library.headers.availability')}</SmallBody></div>
								<div className="flex flex-row justify-center mt-2">
									<ToggleSwitch 
										checked={blend.public} 
										onChange={(e, checked) => {
											e.stopPropagation();
											this.props.onToggleAvailable(blend.id)
										}} 
									/>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		)	
	}
}

export default withTranslation()(BlendPreview);