import React from 'react';
import {inject, observer} from 'mobx-react';

@inject('appStore')
@observer
class Alert extends React.Component{
	constructor(props){
		super(props);
		this.dismiss = this.dismiss.bind(this);
	}

	componentDidMount(){
    	window.scrollTo(0,0);
	}

	dismiss(){
		let appStore = this.props.appStore;
		appStore.removeAlert(this.props.alertId);
	}

	render(){
		let alertTypeClass = null;
		switch(this.props.alertType){
			case 'info':
				alertTypeClass = 'bg-blue-500';
				break;
			case 'warning':
				alertTypeClass = 'bg-orange-500';
				break;
			case 'error':
				alertTypeClass = 'bg-error';
				break;
			case 'success':
				alertTypeClass = 'bg-green-500';
				break;
		}

		let alertClassName = `relative top-0 left-0 flex flex-1 w-full justify-center items-center py-3 ${alertTypeClass}`;
		return (
			<div className={alertClassName} onClick={this.dismiss} style={{zIndex: 100000}}>
				<div className='text-white'>{this.props.message} </div>
			</div>
		)	
	}
}

export default Alert;