import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import { withTranslation } from 'react-i18next';

import LeftBlockRightContent from '../common/left-block-right-content';
import {Body, SecondaryWebLargerTitle, SecondaryWebSmallTitle} from '../common/text'

import runtimeEnv from '@mars/heroku-js-runtime-env';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {InjectedCheckoutForm} from './sign-up';

const env = runtimeEnv();
const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY);

@inject('signUpStore', 'appStore')
@observer
class UpgradeSubscription extends Component{
	constructor(props){
		super(props);
		this.onChangeCurrency = this.onChangeCurrency.bind(this);
		this.onChangeDiscountCode = this.onChangeDiscountCode.bind(this);
		this.onApplyDiscountCode = this.onApplyDiscountCode.bind(this);
		this.onChangeSubscriptionPeriod = this.onChangeSubscriptionPeriod.bind(this);
		this.onToggleTermsAndConditions = this.onToggleTermsAndConditions.bind(this);
		this.onChangePlan = this.onChangePlan.bind(this);
		this.setSubscribing = this.setSubscribing.bind(this);
		this.onPaymentMethod = this.onPaymentMethod.bind(this);
		this.onPaymentMethodFailed = this.onPaymentMethodFailed.bind(this);
		this.onPaymentMethodSuccess = this.onPaymentMethodSuccess.bind(this);
		this.onPaymentMethodSuccessWithPaymentIntent = this.onPaymentMethodSuccessWithPaymentIntent.bind(this);
		this.onTerms = this.onTerms.bind(this);
		this.onReferral= this.onReferral.bind(this);
		this.logout = this.logout.bind(this);
		this.retrieveUserIntervalId = null;
	}

	componentDidMount(){
		let appStore = this.props.appStore;
		appStore.getCurrentUser();

		this.retrieveUserIntervalId = setInterval(() => {
			appStore.getCurrentUser();
		}, 60000);
		let signUpStore = this.props.signUpStore;
		signUpStore.activateUpgradeFlow();
	}

	componentWillUnmount(){
		let signUpStore = this.props.signUpStore;
		signUpStore.initStore();

		if(this.retrieveUserIntervalId != null){
			clearInterval(this.retrieveUserIntervalId);
		}
	}

	onPaymentMethod(paymentMethod){
		let signUpStore = this.props.signUpStore;
		// Return promise to handle intent/secrets
		return signUpStore.subscribe(paymentMethod);
	}

	onPaymentMethodFailed(){
		let signUpStore = this.props.signUpStore;
		signUpStore.subscriptionFailed();
	}

	onPaymentMethodSuccess(){
		let signUpStore = this.props.signUpStore;
		signUpStore.markSubscribed();
	}

	onPaymentMethodSuccessWithPaymentIntent(paymentIntent){
		let signUpStore = this.props.signUpStore;
		signUpStore.subscribedWithPaymentIntent(paymentIntent);
	}

	onChangeCurrency(option){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeCurrency(option.value);
	}

	onChangeDiscountCode(val){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeDiscountCode(val);
	}

	onChangeSubscriptionPeriod(val){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeSubscriptionPeriod(val);
	}

	onToggleTermsAndConditions(){
		let signUpStore = this.props.signUpStore;
		signUpStore.onToggleTermsAndConditions();
	}

	onChangePlan(value){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangePlan(value);
	}

	onApplyDiscountCode(){
		let signUpStore = this.props.signUpStore;
		signUpStore.applyDiscountCode();
	}

	setSubscribing(){
		let signUpStore = this.props.signUpStore;
		signUpStore.setSubscribing();
	}

	onTerms(){
		let appStore = this.props.appStore;
		appStore.goToTerms();
	}

	onReferral(referrer){
		let signUpStore = this.props.signUpStore;
		signUpStore.onReferral(referrer);
	}

	logout(){
		let appStore = this.props.appStore;
		appStore.logout();
	}

	render(){
		let translate = this.props.t;
		const {stripe} = this.props;
		let signUpStore = this.props.signUpStore;
		if(signUpStore.currentPlan == null) return null;
		let currentPlan = signUpStore.currentPlan;
		let planName = currentPlan.name.split(' ');
		let lastWord = planName.pop();

		return (
				<>
					<LeftBlockRightContent
						leftBgColor={`${currentPlan.color}-25`}
						left={
							<div className="flex flex-col h-75vh justify-center">
								<div className="flex flex-col">
									<Body className="text-center text-black lg:text-left mt-8">{translate('registration.indicator')}</Body>
									<SecondaryWebLargerTitle className="text-center text-black lg:text-left">{planName} <br /> <span className={`uppercase text-${currentPlan.color}`}>{lastWord}</span></SecondaryWebLargerTitle>
								</div>
								<div className="flex flex-col lg:mt-16">
									<SecondaryWebSmallTitle className="text-black">{translate('registration.order.title')}</SecondaryWebSmallTitle>
								</div>
								<div className="flex w-full flex-row justify-between mt-3">
									<div className="flex"><Body className="text-black">{translate('registration.order.subtotal')}</Body></div>
									<div className="flex">
										<Body className="text-black"> {currentPlan.currency.symbol}{signUpStore.currentPlanPeriodValue}</Body>
									</div>
								</div>
								<div className="flex w-full w-full flex-row justify-between mt-3">
									<div className="flex"><SecondaryWebSmallTitle className="text-black bold-font">{translate('registration.order.total')}</SecondaryWebSmallTitle></div>
									<div className="flex">
										<SecondaryWebSmallTitle className="text-black"> {currentPlan.currency.symbol}{signUpStore.currentPlanPeriodValue} <span className="regular-font">/ {currentPlan.displayPeriod}</span></SecondaryWebSmallTitle>
									</div>
								</div>
								<div className="flex w-full lg:w-1/2 flex-row justify-between mt-3">
									<div className="flex w-32 items-end lg:items-start text-left" onClick={this.logout}>
										<SecondaryWebSmallTitle className="text-black underline cursor-pointer" >{translate('menu.logout')}</SecondaryWebSmallTitle>
									</div>
								</div>
							</div>
						}
						right={
							<Elements 
								options={{
							        fonts: [
							          {
							            family: "Proxima Nova Alt Regular",
							            src: `url(https://labaroma-production.s3-eu-west-1.amazonaws.com/fonts/ProximaNovaAlt-Regular.woff)`,
							            weight: "normal",
							          },
							        ],
							      }}
								stripe={stripePromise}>
								<InjectedCheckoutForm
									t={translate}
									appStore={this.props.appStore}
									color={currentPlan.color}
									setSubscribing={this.setSubscribing}
									email={signUpStore.email.value}
									onChangeDiscountCode={this.onChangeDiscountCode}
					      			discountCode={signUpStore.discountCode}
					      			onApplyDiscountCode={this.onApplyDiscountCode}
					      			onToggleTermsAndConditions={this.onToggleTermsAndConditions}
					      			termsAndConditions={signUpStore.termsAndConditions}
					      			planDescription={signUpStore.planDescription}
					      			onSubmit={this.props.onSubmit}
					      			subscribing={signUpStore.subscribing}
					      			canPlaceOrder={signUpStore.canPlaceOrder}
					      			onPaymentMethod={this.onPaymentMethod}
					      			onPaymentMethodSuccess={this.onPaymentMethodSuccess}
					       			onPaymentMethodSuccessWithPaymentIntent={this.onPaymentMethodSuccessWithPaymentIntent}
					       			onPaymentMethodFailed={this.onPaymentMethodFailed}
					       			onTerms={this.onTerms}
					       			payToday={`${signUpStore.currentPlan.currency.symbol}${signUpStore.currentPlanPeriodValue}`}
					       			showOptions={true}
					       			planOptions={signUpStore.planOptions}
					      			selectedPlan={signUpStore.selectedPlan}
					      			onChangePlan={this.onChangePlan}
					      			currencyOptions={signUpStore.currencyOptions}
					      			selectedCurrency={signUpStore.selectedCurrency}
					      			onChangeCurrency={this.onChangeCurrency}
					      			onChangeSubscriptionPeriod={this.onChangeSubscriptionPeriod}
					      			subscriptionPeriod={signUpStore.subscriptionPeriod}
					      			subscriptionOptions={signUpStore.subscriptionOptions}
					      			onReferral={this.onReferral}
					      			addressLine1={signUpStore.addressLine1}
					      			onChangeAddressLine1={(val) => signUpStore.addressLine1.onChange(val)}
					      			addressLine2={signUpStore.addressLine2}
					      			onChangeAddressLine2={(val) => signUpStore.addressLine2.onChange(val)}
					      			addressCity={signUpStore.addressCity}
					      			onChangeAddressCity={(val) => signUpStore.addressCity.onChange(val)}
					      			addressState={signUpStore.addressState}
					      			onChangeAddressState={(val) => signUpStore.addressState.onChange(val)}
					      			addressZip={signUpStore.addressZip}
					      			onChangeAddressZip={(val) => signUpStore.addressZip.onChange(val)}
					      			taxId={signUpStore.taxId}
					      			onChangeTaxId={(val) => signUpStore.onChangeTaxId(val)}
					      			cardHolderName={signUpStore.cardHolderName}
			      					onChangeCardHolderName={(val) => signUpStore.cardHolderName.onChange(val)}
			      					countryOptions={signUpStore.countryOptions}
			      					selectedBillingCountry={signUpStore.selectedBillingCountry}
			      					onChangeBillingCountry={(option) => signUpStore.selectedBillingCountry = option?.value}
			      					billingCountryIsoAlpha2LetterCode={signUpStore.billingCountryIsoAlpha2LetterCode}
			      					showStateDropdown={signUpStore.showStateDropdown}
			      					onChangeState={(option) => signUpStore.selectedState = option?.value}
			      					selectedState={signUpStore.selectedState}
			      					stateOptions={signUpStore.stateOptions()}
								 />
							</Elements>
						}
					/>
				</>
			)
	}
}

export default withTranslation()(UpgradeSubscription);