import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import { withTranslation } from 'react-i18next';
import LeftBlockRightContent from '../common/left-block-right-content';
import { Form, HorizontalFormColumn } from '../common/form';
import {Label, Body, SecondaryWebLargerTitle} from '../common/text';
import {TextInput, TextArea} from '../common/input'
import {Button} from '../common/button';

import openHandLeafSrc from '../../assets/img/icons/open-hand-leaf.svg';

@inject('appStore')
@observer
class PaymentConfirmation extends Component{
	constructor(props){
		super(props);
		this.onNext = this.onNext.bind(this);
	}

	onNext(){
		let appStore = this.props.appStore;
		appStore.goToHome();
	}

	componentDidMount(){
		
	}

	render(){
		let translate = this.props.t;
		return (
			<LeftBlockRightContent
				leftBgColor={`${this.props.color}-25`}
				left={
					<>
						<SecondaryWebLargerTitle className="text-center text-black lg:text-left">{translate('payment-confirmation.title.part1')} <br /> {translate('payment-confirmation.title.part2')}</SecondaryWebLargerTitle>
						<Body className="text-center text-black lg:text-left mt-8">{translate('payment-confirmation.description.part1')}</Body>
						<Body className="text-center text-black lg:text-left mt-8">{translate('payment-confirmation.description.part2')}</Body>
					</>
				}
				right={
					<div className="flex flex-1 flex-col justify-center items-center p-8 lg:p-0 lg:w-2/3">
						<div className="flex items-center">
							<img style={{ width: '300px', height: '300px'}} src={openHandLeafSrc} alt="Subscription received" />
						</div>

						<div className="flex w-full items-center lg:items-end w-full mt-8">
							<Button
								 bgColor="black"
								 width="w-full"
								 fgColor="white"
								 onClick={this.onNext}>{translate("payment-confirmation.login")}</Button>
						</div>
					</div>
				}
			/>
		)
	}
}

export default withTranslation()(PaymentConfirmation);