import BaseApi from '../services/base';

class ChemistryApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAllChemicalFamilies(page, searchText=null){
		let queryUrl = `v1/chemistry/families?page=${page}`
		if(searchText != null){
			queryUrl = `${queryUrl}&search_text=${searchText}`
		}
		return this.fetch(queryUrl, {
			method: 'GET',
			auth: true
		})
	}	

	getChemicalFamilies = async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAllChemicalFamilies(page);
			let data = response.chemical_families;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}

	newChemicalFamily(payload){
		return this.fetch('v1/chemistry/families', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getChemicalFamilyById(id){
		return this.fetch(`v1/chemistry/families/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	updateChemicalFamily(id, payload){
		return this.fetch(`v1/chemistry/families/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getAllChemicalComponents(page, searchText=null, limit=null){
		let queryUrl = `v1/chemistry/components?page=${page}`
		if(searchText != null){
			queryUrl = `${queryUrl}&search_text=${searchText}`
		}
		if(limit != null){
			queryUrl = `${queryUrl}&limit=${limit}`	
		}
		return this.fetch(queryUrl, {
			method: 'GET',
			auth: true
		})
	}	

	getChemicalComponents = async (limit=null) => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAllChemicalComponents(page, null, limit);
			let data = response.chemical_components;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}

	newChemicalComponent(payload){
		return this.fetch('v1/chemistry/components', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getChemicalComponentById(id){
		return this.fetch(`v1/chemistry/components/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	updateChemicalComponent(id, payload){
		return this.fetch(`v1/chemistry/components/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}
}

export default ChemistryApi;