import { observable, action, computed} from 'mobx';

import LibraryApi from '../../../services/library';
import BlendApi from '../../../services/blend';

import { isEmailValidator, isRequiredValidator } from '../../../services/validation';

import {FieldState} from 'formstate'

import moment from 'moment'

class LibraryCategories{

	appStore;

	@observable showSortDialog;
	@observable showFilterDialog;

	@observable currentFilterValue;
	@observable currentFilters;

	@observable searchTagFilters;

	@observable sortBy;

	@observable libraryCategories;

	@observable hasMoreLibraries;
	@observable librariesPage;

	@observable currentBlends;
	@observable hasMoreBlends;
	@observable blendsPage;

	@observable selectedBlendLibraryCategory;

	@observable showShareModal;
	@observable notificationText;
	@observable currentUserEmail;

	@observable fetching;

	libraryApi;
	blendApi;

	constructor(appStore){
		this.appStore = appStore;
		this.libraryApi = new LibraryApi(appStore);
		this.blendApi = new BlendApi(appStore);
		this.initStore();
	}

	initStore(){
		this.showSortDialog = false;
		this.showFilterDialog = false;
		this.sortBy = null;
		this.currentFilterValue = '';
		this.currentFilters = [];
		this.searchTagFilters = [];
		this.libraryCategories = [];
		this.selectedBlendLibraryCategory = null;
		this.hasMoreLibraries = true;
		this.librariesPage = 1;
		this.currentBlends = [];
		this.hasMoreBlends = true;
		this.blendsPage = 1;
		this.showShareModal = false;
		this.notificationText = new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n));
		this.currentUserEmail = new FieldState(null).validators((val) => isEmailValidator(val, this.appStore.i18n));
		this.fetching = false;
	}

	fetchLibraryCategories(reset=false){
		try{
			this.fetching = true;

			let sortBy = this.sortBy;
			let filterBy = this.searchTagFilters != null && this.searchTagFilters.length > 0 ? {
				'tags': this.searchTagFilters.map((f) => f.value)
			} : null;

			this.libraryApi.getAllLibraryCategories(this.librariesPage, sortBy, filterBy, null, false)
				.then((response) => {
					this.libraryCategories = reset ? response.blend_library_categories : this.libraryCategories.concat(response.blend_library_categories);
					this.hasMoreLibraries = response.blend_library_categories.length > 0;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})

		}catch(e){
			console.log(e)
		}
	}

	@action onNewLibraryCategory(){
		this.appStore.goToNewLibraryCategory();
	}

	@action onEditLibraryCategory(id){
		this.appStore.goToEditLibraryCategory(id);
	}

	@action onShareCurrentLibraryCategory(){
		this.showShareModal = true;
	}

	@action closeShareModal(){
		this.showShareModal = false;
		this.notificationText.value = null;
		this.currentUserEmail.value = null;
	}

	@action toggleSortDialog(){
		this.showSortDialog = !this.showSortDialog;
		this.showFilterDialog = false;
	}

	@action toggleFilterDialog(){
		this.showFilterDialog = !this.showFilterDialog;
		this.showSortDialog = false;
	}

	@action setSortByField(val){
		this.sortBy = val;
	}

	@action applySort(){
		this.showSortDialog = false;
		this.resetLibraryCategories();
	}

	@computed get sortOptions(){
		if(this.appStore == null) return [];
		return [
			{
				label: this.appStore.i18n.t("org-admin.library-categories.sortoptions.name"),
				value: "name"
			}
		]
	}

	resetFilterValue(){
		this.currentFilterValue = '';
	}

	@action newFilterEntry(val){
		let strippedVal = val.trim();
		if(!this.currentFilters.map((f) => f.value).includes(strippedVal)){
			this.currentFilters.push({
				label: val,
				value: val
			});
		}
		this.resetFilterValue();
	}

	@action setFilters(values){
		this.currentFilters = values != null ? values : [];
	}

	@action setCurrentFilterValue(val){
		this.currentFilterValue = val;
	}

	@action applyFilters(){
		this.searchTagFilters = this.currentFilters;
		this.showFilterDialog = false;
		this.resetLibraryCategories();
	}

	toBlend(b){
		b.last_edited_at = moment(b.last_edited_at).tz(this.appStore.timezone);
		return b;
	}

	@action onSelectLibraryCategory(id){
		let libraryCategory = this.libraryCategories.find((b) => b.id == id);
		if(libraryCategory != null){
			this.libraryApi.getLibraryCategoryById(id)
				.then((response) => {
					this.selectedBlendLibraryCategory = response;
					this.resetFetchBlendsForSelectedLibraryCategory();
				})
				.catch((error) => {
					console.log(error);
				})
			
		}
	}

	fetchBlendsForSelectedBlendLibraryCategory(reset=false){
		this.blendApi.getAllBlends(this.blendsPage, null, null, null, null, null, null, this.selectedBlendLibraryCategory?.id,)
			.then((response) => {
				let mappedBlends = response.blends.map((b) => this.toBlend(b));
				this.currentBlends = reset ? mappedBlends : this.currentBlends.concat(mappedBlends);
				this.hasMoreBlends = mappedBlends.length > 0;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action resetFetchBlendsForSelectedLibraryCategory(){
		this.blendsPage = 1;
		this.fetchBlendsForSelectedBlendLibraryCategory(true);
	}

	@action loadNextLibrariesPage(){
		this.librariesPage += 1;
		this.fetchLibraryCategories();
	}

	@action loadNextBlendsPage(){
		this.blendsPage += 1;
		this.fetchBlendsForSelectedBlendLibraryCategory();
	}

	@action onBackToCategories(){
		this.selectedBlendLibraryCategory = null;
		this.currentBlends = [];
	}

	@action resetLibraryCategories(){
		this.page = 1;
		this.fetchLibraryCategories(true);
	}

	@action loadNextPage(){
		this.page += 1;
		this.fetchLibraryCategories()
	}

	@action onViewBlend(id){
		this.appStore.goToEditBlend(id, false);
	}

	@action addNewUserEmail(){
		if(this.currentUserEmail.value == null || this.currentUserEmail.error != null ) return;
		
		let expectedNewEmails = this.selectedBlendLibraryCategory.user_emails.map((entry) => entry.user_email).concat([this.currentUserEmail.value])
		this.patchCurrentBlendLibraryCategoryWithEmails(expectedNewEmails);
	}

	@action patchCurrentBlendLibraryCategoryWithEmails(emails){
		this.fetching = true;
		this.libraryApi.patchLibraryCategoryById(this.selectedBlendLibraryCategory.id, {
			user_emails: emails
		})
		.then((response) => {
			let libraryCategory = response;
			this.selectedBlendLibraryCategory = libraryCategory;
			this.currentUserEmail.value = '';
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			this.fetching = false;
		})
	}

	@action deleteUserEmailFromBlendCategory(email){
		let expectedNewEmails = this.selectedBlendLibraryCategory.user_emails.filter((entry) => entry.user_email != email).map((entry) => entry.user_email);
		this.patchCurrentBlendLibraryCategoryWithEmails(expectedNewEmails);
	}

	@computed get canShareBlendLibraryCategory(){
		if(this.selectedBlendLibraryCategory == null) return false;
		let hasEmails = this.selectedBlendLibraryCategory.user_emails.length > 0;
		let hasNotificationText = this.notificationText.value != null;
		return hasEmails && hasNotificationText;
	}

	@action shareCurrentLibraryCategory(){
		if(this.selectedBlendLibraryCategory == null) return;
		this.fetching = true;

		this.libraryApi.shareLibraryCategoryById(this.selectedBlendLibraryCategory.id, {
			notification_text: this.notificationText.value
		})
			.then((response) => {
				this.closeShareModal();
				this.appStore.displaySaveDialog();
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action onArchiveBlendLibraryCategory(id){
		let confirmMsg = this.appStore.i18n.t('org-admin.library-categories.archive-message');
		let confirmed = window.confirm(confirmMsg);
		if(confirmed){
			this.libraryApi.patchLibraryCategoryById(id, {
				user_emails: null,
				archived: true
			})
			.then((response) => {
				let libraryCategory = response;
				this.libraryCategories = this.libraryCategories.filter((l) => !l.archived);
				this.selectedBlendLibraryCategory = null;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
		}
	}
}

export default LibraryCategories;