import { observable, action, computed } from 'mobx';

import SearchApi from '../../../services/search';
import EvidenceApi from '../../../services/evidence';
import TagApi from '../../../services/tag';

const VIEW_MODE = {
	TILE: 'tile',
	LIST: 'list'
}

class EvidenceSearchStore{
	appStore;

	searchApi;
	evidenceApi;
	tagApi;

	@observable query;
	@observable viewMode;
	@observable noOfResults;
	@observable evidenceResults;

	@observable searchTags;
	@observable currentTagInput;
	@observable showSearchResults;

	@observable hasMoreSearchResults;

	@observable showRefinedSearch;
	@observable showSortDialog;
	@observable sortBy;

	@observable possibleTags;

	@observable currentSearchQuery;
	@observable searchResultsPage;

	@observable initialLoading;

	@observable viewingCurrentEvidence;
	@observable searching;

	constructor(appStore){
		this.appStore = appStore;
		this.searchApi = new SearchApi(appStore);
		this.evidenceApi = new EvidenceApi(appStore);
		this.tagApi = new TagApi(appStore);
		this.initStore();
	}

	@action initStore(){
		this.viewMode = VIEW_MODE.TILE;
		this.noOfResults = 0;
		this.showSearchResults = false;
		this.evidenceResults = [];
		this.query = null;
		this.currentTagInput = null;
		this.searchTags = [];
		this.showSortDialog = false;
		this.sortBy = this.getDefaultSortBy();
		this.possibleTags = [];
		this.searchResultsPage = 1;
		this.showRefinedSearch = false;
		this.currentSearchQuery = null;
		this.hasMoreSearchResults = true;
		this.searching = false;
		this.viewingCurrentEvidence = null;
		this.initialLoading = false;
	}

	getDefaultSortBy(){
		return this.sortOptions[0].value;
	}

	@action changeToListViewMode(){
		this.viewMode = VIEW_MODE.LIST;
	}

	@action changeToTileViewMode(){
		this.viewMode = VIEW_MODE.TILE;
	}

	@action changeSearchQuery(val){
		this.query = val;
	}

	@action triggerSearch(){
		if(this.query != null && this.query.length > 0){
			this.searchResultsPage = 1;
			this.showSearchResults = false;
			this.performSearch();
		}
	}

	@computed get isListView(){
		return this.viewMode === VIEW_MODE.LIST;
	}

	@computed get isTileView(){
		return this.viewMode === VIEW_MODE.TILE;
	}

	@computed get currentSearchTags(){
 		return this.searchTags.map((searchTag) => {
 			return {
 				value: searchTag,
 				label: searchTag
 			}
 		});
	}

	onNewTag(tag){
	 	if(this.searchTags.includes(tag)) return;
	 	this.searchTags.push(tag);
	 	this.currentTagInput = '';
	 	this.possibleTags = [];
	}
	
	onChangeTagInput(val){
		this.currentTagInput = val;
		if(this.currentTagInput != null && this.currentTagInput.length > 0){
			this.searchPossibleTags();
		}
	}	

	searchPossibleTags(){
		if(this.currentTagInput == null || this.currentTagInput === '') return;
		this.tagApi.searchTags(this.currentTagInput)
			.then((response) => {
				this.possibleTags = response.tags;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onChangeTags(tags){
	 	this.searchTags = tags == null ? [] : tags.map((t) => t.value);
	}

	onSelectPossibleTag(id){
		let matchingTag = this.possibleTags.find((t) => t.id == id);
		if(matchingTag != null){
			this.onNewTag(matchingTag.name);
		}
	}

	@computed get sortOptions(){
		return [
			{
				value: 'relevance',
				label: this.appStore.i18n.t('user.advanced-search.refine-search.sort-options.relevance')
			},
			{
				value: 'created_on',
				label: this.appStore.i18n.t('user.advanced-search.refine-search.sort-options.created-on')
			}
		]
	}

	@computed get currentSortAttribute(){
		if(this.sortBy === null) return null;
		let sortOption = this.sortOptions.find((s) => s.value == this.sortBy);
		return sortOption?.label;
	}

	@action onToggleSortDialog(){
		this.showSortDialog = !this.showSortDialog;
	}

	@action onChangeSortOption(val){
		this.sortBy = val;
	}

	@action onApplySort(){
		this.onToggleSortDialog();
	}

	nextSearchResultsPage(){
		if(this.query == null || this.query.length == 0) return;
		this.searchResultsPage += 1;
		this.performSearch(true);
	}

	previousSearchResultsPage(){
		if(this.query == null || this.query.length == 0) return;
		this.searchResultsPage = Math.max(this.searchResultsPage - 1, 0);
		this.performSearch(true);
	}

	maptoSearchResult(searchResult){
		let foundIn = null;
		if(searchResult.type == 'blend'){
			searchResult.foundIn = searchResult.public ? this.appStore.i18n.t('user.advanced-search.search-results.public-blend-library') : this.appStore.i18n.t('user.advanced-search.search-results.private-blend-library');
		}else if(searchResult.type == 'ingredient'){
			searchResult.foundIn = searchResult.public ? this.appStore.i18n.t('user.advanced-search.search-results.public-ingredient-library') : this.appStore.i18n.t('user.advanced-search.search-results.private-ingredient-library');
		}
		return searchResult;
	}

	resetSearch(){
		this.showRefinedSearch = false;
		this.showSearchResults = false;
		this.evidenceResults = [];
		this.searchResultsPage = 1;
		this.hasMoreSearchResults = true;
		this.searchTags = [];
		this.sortBy = this.getDefaultSortBy();
		this.viewingCurrentEvidence = null;
	}

	backToEvidenceSearch(){
		this.viewingCurrentEvidence = null;
	}

	searchWithQuery(triggerQuery){
		if(triggerQuery == null || triggerQuery.length == 0) return;
		this.query = triggerQuery;
		this.triggerSearch();
	}

	performSearch(append=false){
		this.initialLoading = false;
		this.currentSearchQuery = this.query;
		let payload = {
			query: this.currentSearchQuery,
			page: this.searchResultsPage,
			tags: this.searchTags.length > 0 ? this.searchTags : null,
			filter_by: 'evidence',
			sort_by: this.sortBy
		}
		this.searching = true;
		this.searchApi.search(payload)
			.then((response) => {
				let results = response.results;
				let newEvidenceResults = results.evidence;
				if(append){
					this.evidenceResults = [...this.evidenceResults, ...newEvidenceResults];
				}else{
					this.evidenceResults = newEvidenceResults;
				}
				this.noOfResults = results.no_of_results;
				this.showSearchResults = true;
				this.showRefinedSearch = true;
				this.hasMoreSearchResults = newEvidenceResults.length > 0;
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.searching = false;
			})
	}

	@computed get canUploadEvidence(){
		return this.appStore.isSuperAdministrator;
	}

	@action goToAddEvidence(){
		this.appStore.goToNewEvidence();
	}

	@action manageEvidence(id){
		this.appStore.goToEditEvidence(id);
	}

	@action viewEvidence(id){
		this.evidenceApi.getEvidenceById(id)
			.then((response) => {
				this.viewingCurrentEvidence = response;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	@action fetchEvidence(){
		this.initialLoading = true;
		this.evidenceApi.getAll(1)
			.then((response) => {
				this.showSearchResults = true;
				this.showRefinedSearch = false;
				this.evidenceResults = response.evidence;
				this.noOfResults = response.total;
			})
			.catch((error) => {
				console.log(error);
			})
	}
}

export default EvidenceSearchStore;