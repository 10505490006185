import { observable, action, computed } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isEmailValidator, isRequiredValidator, isPasswordValidator } from '../../../services/validation';

import OrganisationApi from '../../../services/organisation';
import CurrencyApi from '../../../services/currency';
import PlanApi from '../../../services/plan';
import CommonApi from '../../../services/common';

import { CHEMISTRY, COSMETIC_REGULATIONS, LANGUAGES, USERS } from '../../../services/util';

class InviteOrganisation{
	appStore;
	organisationApi;
	currencyApi;
	planApi;
	commonApi;

	@observable fetching;

	@observable currencies;
	@observable plans;
	@observable permissions;

	@observable organisationData;

	constructor(appStore){
		this.appStore = appStore;
		this.organisationApi = new OrganisationApi(appStore);
		this.currencyApi = new CurrencyApi(appStore);
		this.planApi = new PlanApi(appStore);
		this.commonApi = new CommonApi(appStore);
		this.initStore();
	}

	initStore(){
		this.plans = [];
		this.currencies = [];
		this.permissions = [];
		this.fetching = false;
		this.organisationData = {
			name: new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.full-name'))),
			email: new FieldState('').validators((val) => isEmailValidator(val, this.appStore.i18n)),
			message: new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.message'))),
			selectedCurrency: new FieldState(null),
			selectedLanguage: new FieldState(null),
			selectedPlan: new FieldState(null),
			selectedPermissions: new FieldState([])
		}
	}

	fetchPermissions(){
		this.commonApi.getPermissions()
			.then((response) => {
				this.permissions = response.permissions;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	@action onChangeCurrency(val){
		this.organisationData.selectedCurrency.value = val;
	}

	@action onChangePlan(val){
		this.organisationData.selectedPlan.value = val;
	}

	@action fetchPlans(){
		return this.planApi.getSpecificPlans(this.organisationData.selectedCurrency.value, 'annually')
			.then((response) => {
				this.plans = response.plans;
				if(this.plans.length > 0){
					this.organisationData.selectedPlan.value = this.plans[0].id;
				}
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action fetchCurrencies(){
		return this.currencyApi.getAll()
			.then((response) => {
				this.currencies = response.currencies;
				if(this.currencies.length > 0){
					this.organisationData.selectedCurrency.value = this.currencies[0].id;
					this.fetchPlans();
				}
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get currencyOptions(){
		return this.currencies.map((c) => {
			return {
				value: c.id,
				label: c.name,
				key: c.id
			}
		})
	}


	@computed get planOptions(){
		return this.plans.map((plan) => {
			return {
				value: plan.id,
				label: plan.name
			}
		})
	}

	@computed get validForm(){
		let requiredFields = ['name', 'email', 'message', 'selectedCurrency', 'selectedPlan'];
		let isValidForm = true;
		for(let field of requiredFields){
			if(this.organisationData[field].value == null || this.organisationData[field].value.length == 0){
				isValidForm = false;
			}else{
				let fieldError = this.organisationData[field].error;
				isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
			}
		}
		return isValidForm;
	}

	@computed get hasCosmeticRegulationPermission(){
		if(this.organisationData == null) return false;

		return this.organisationData.selectedPermissions.value.includes(COSMETIC_REGULATIONS)
	}

	@computed get hasChemistryPermission(){
		if(this.organisationData == null) return false;
		return this.organisationData.selectedPermissions.value.includes(CHEMISTRY)
	}

	@computed get hasLanguagesPermission(){
		if(this.organisationData == null) return false;
		return this.organisationData.selectedPermissions.value.includes(LANGUAGES);
	}

	@computed get hasUsersPermission(){
		if(this.organisationData == null) return false;
		return this.organisationData.selectedPermissions.value.includes(USERS);
	}

	handlePermissionChange(checked, permission){
		let currentPermissions = this.organisationData.selectedPermissions.value;
		if(checked && !currentPermissions.includes(permission)){
			this.organisationData.selectedPermissions.value.push(permission);
		}else{
			this.organisationData.selectedPermissions.value = this.organisationData.selectedPermissions.value.filter((p) => p != permission);
		}
	}

	@action setCosmeticRegulationPermission(checked){
		this.handlePermissionChange(checked, COSMETIC_REGULATIONS);
	}

	@action setChemistryPermission(checked){
		this.handlePermissionChange(checked, CHEMISTRY);
	}

	@action setLanguagesPermission(checked){
		this.handlePermissionChange(checked, LANGUAGES);
	}

	@action setUsersPermission(checked){
		this.handlePermissionChange(checked, USERS);
	}

	getPermissionIds(){
		let selectedPerms = this.organisationData.selectedPermissions.value;
		let permissionIds = [];
		for(let perm of selectedPerms){
			let matchingPermission = this.permissions.find((x) => x.name == perm);
			if(matchingPermission != null){
				permissionIds.push(matchingPermission.id)
			}
		}
		return permissionIds;
	}

	invite(){
		if(this.organisationData.selectedPlan.value == null){
			this.appStore.alertError(this.appStore.i18n.t('validation.select-plan-message'));
			return;
		}

		if(this.organisationData.selectedCurrency.value == null){
			this.appStore.alertError(this.appStore.i18n.t('validation.select-currency-message'));
			return;
		}

		let userLanguage = this.organisationData.selectedLanguage.value;
		if(userLanguage == null){
			this.appStore.alertError(this.appStore.i18n.t('validation.select-language-message'));
			return;
		}

		this.fetching = true;
		this.organisationApi.inviteOrganisation({
			name: this.organisationData.name.value,
			email: this.organisationData.email.value,
			plan_id: this.organisationData.selectedPlan.value,
			language: userLanguage,
			currency_id: this.organisationData.selectedCurrency.value,
			message: this.organisationData.message.value,
			permission_ids: this.getPermissionIds()
		})
		.then((response) => {
			this.appStore.displaySaveDialog('invite', this.appStore.i18n.t('alert.invited-new-organisation'));
			this.appStore.goToSuperAdministratorManageOrganisations();
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			this.fetching = false;
		})
	}

}

export default InviteOrganisation;