import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {SmallBody, ExtraSmallBody, SecondaryAppLargeTitle, TertiaryAppSmallTitle, ApplyText, SecondaryAppMediumTitle} from '../common/text'
import { EditIcon, ArrowRightIcon, DrawBlueIcon} from '../common/icon'
import {DownloadPDF, pdfStyles} from '../common/pdf';
import CampaignBreakdown from '../common/campaign-breakdown';
import {StatBox, StatOverview} from '../elements/stat';

import Main from '../elements/main';
import {FullGrid} from '../elements/grid';
import NotificationCenter from '../elements/notification-center';
import { withAuthorisation } from '../common/authorisation';
import { SUPER_ADMINISTRATOR } from '../../services/util';
import logoPinkSrc from '../../assets/img/logo.png';

import {MetricChartView} from '../common/chart';
import {MarketingAssets, AffiliateLinks} from '../marketing';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';

import moment from 'moment';

const colors = require('../../color.js');

const SalesOverviewPDF = ({logoSrc, dateRange, mrrTitle, mrr, arrTitle, arr, mrrChangeTitle, mrrChange, affiliateCampaignCommissionTitle, affiliateCampaignCommission}) => (
	<Document creator="LabAroma" producer="LabAroma">
	    <Page orientation="landscape" size="A4" style={pdfStyles.page} wrap={false}>
	      	<View style={pdfStyles.container}>
	      		<View style={pdfStyles.rowBetween}>
	      			<Image src={logoSrc} style={pdfStyles.logo} />
	      			<Text style={[pdfStyles.date]}>{dateRange}</Text>
	      		</View>
	      		<View style={pdfStyles.row}>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{mrrTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{mrr}</Text></View>
		      		</View>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{arrTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{arr}</Text></View>
		      		</View>
		      	</View>
		      	<View style={pdfStyles.row}>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{mrrChangeTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{mrrChange}</Text></View>
		      		</View>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{affiliateCampaignCommissionTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{affiliateCampaignCommission}</Text></View>
		      		</View>
		      	</View>
	      	</View>
	    </Page>
	</Document>
)

const UsersOverviewPDF = ({logoSrc, dateRange, activeUsersTitle, activeUsers, usersChangeTitle, usersChange, newUsersTitle, newUsers, newSubscribersTitle, newSubscribers}) => (
	<Document creator="LabAroma" producer="LabAroma">
	    <Page orientation="landscape" size="A4" style={pdfStyles.page} wrap={false}>
	      	<View style={pdfStyles.container}>
	      		<View style={pdfStyles.rowBetween}>
	      			<Image src={logoSrc} style={pdfStyles.logo} />
	      			<Text style={[pdfStyles.date]}>{dateRange}</Text>
	      		</View>
	      		<View style={pdfStyles.row}>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{activeUsersTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{activeUsers}</Text></View>
		      		</View>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{usersChangeTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{usersChange}</Text></View>
		      		</View>
		      	</View>
		      	<View style={pdfStyles.row}>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{newUsersTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{newUsers}</Text></View>
		      		</View>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{newSubscribersTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{newSubscribers}</Text></View>
		      		</View>
		      	</View>
	      		
	      	</View>
	    </Page>
	</Document>
)

const OrganisationsOverviewPDF = ({logoSrc, dateRange, activeOrganisationsTitle, activeOrganisations, organisationsChangeTitle, organisationsChange, newOrganisationsTitle, newOrganisations}) => (
	<Document creator="LabAroma" producer="LabAroma">
	    <Page orientation="landscape" size="A4" style={pdfStyles.page} wrap={false}>
	      	<View style={pdfStyles.container}>
	      		<View style={pdfStyles.rowBetween}>
	      			<Image src={logoSrc} style={pdfStyles.logo} />
	      			<Text style={[pdfStyles.date]}>{dateRange}</Text>
	      		</View>
	      		<View style={pdfStyles.row}>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{activeOrganisationsTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{activeOrganisations}</Text></View>
		      		</View>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{organisationsChangeTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{organisationsChange}</Text></View>
		      		</View>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{newOrganisationsTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{newOrganisations}</Text></View>
		      		</View>
		      	</View>
	      	</View>
	    </Page>
	</Document>
)

@inject('appStore', 'superAdminHomeStore')
@observer
class SuperAdministratorHome extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let superAdminHomeStore = this.props.superAdminHomeStore;
		superAdminHomeStore.fetchSalesStats();
		superAdminHomeStore.fetchOrganisationStats();
		superAdminHomeStore.fetchUserStats();
		superAdminHomeStore.fetchLatestNotifications();

		superAdminHomeStore.fetchMRRGeographyBreakdown();
		superAdminHomeStore.fetchUserAffiliateLinks();
		superAdminHomeStore.fetchLatestMarketingAssets();
	}

	componentWillUnmount(){
		let superAdminHomeStore = this.props.superAdminHomeStore;
		superAdminHomeStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let appStore = this.props.appStore;
		let currentUser = appStore.currentUser;
		if(currentUser == null) return null;
		let superAdminHomeStore = this.props.superAdminHomeStore;
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center">
						<div className="flex flex-1 flex-col">
							<SmallBody>{translate('superadmin.home.intro')} {currentUser.name},</SmallBody>
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('superadmin.home.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('superadmin.home.paragraph.part1')} <br /> {translate('superadmin.home.paragraph.part2')}</SmallBody>

							<div className="flex flex-1 flex-row items-center mt-8">
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('superadmin.home.manage-videos')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
							
						</div>
						<div className="flex hidden lg:block ml-4">
							<DrawBlueIcon className="w-64 h-48" />
						</div>
					</div>
					<div className="flex flex-1 flex-col lg:flex-row mt-8">
						<div className="flex flex-1 flex-col lg:flex-row">
							<div className="flex flex-1 flex-col">
								<SalesOverview 
									queryDates={superAdminHomeStore.salesQueryDates}
									salesStats={superAdminHomeStore.salesStats}
									salesStartDate={superAdminHomeStore.salesStartDate}
									salesEndDate={superAdminHomeStore.salesEndDate}
									tempSalesStartDate={superAdminHomeStore.tempSalesStartDate}
									tempSalesEndDate={superAdminHomeStore.tempSalesEndDate}
									onDateChange={({startDate, endDate}) => superAdminHomeStore.changeSalesStartEndDate(startDate, endDate)}
									onApply={() => superAdminHomeStore.applySalesDateChange()}
									onToggleMRR={() => superAdminHomeStore.onToggleMRR()}
									onToggleARR={() => superAdminHomeStore.onToggleARR()}
									onToggleAffiliateCampaign={() => superAdminHomeStore.onToggleAffiliateCampaign()}
									mrrGeographyBreakdown={superAdminHomeStore.mrrGeographyBreakdown}
									showStatDialog={superAdminHomeStore.showStatDialog}
									t={translate}
								/>
								<div className="mt-8"></div>
								<UsersOverview 
									userStats={superAdminHomeStore.userStats} 
									usersStartDate={superAdminHomeStore.usersStartDate}
									usersEndDate={superAdminHomeStore.usersEndDate}
									tempUsersStartDate={superAdminHomeStore.tempUsersStartDate}
									tempUsersEndDate={superAdminHomeStore.tempUsersEndDate}
									onDateChange={({startDate, endDate}) => superAdminHomeStore.changeUsersStartEndDate(startDate, endDate)}
									onApply={() => superAdminHomeStore.applyUsersDateChange()}
									t={translate} 
								/>
							</div>
							<div className="flex flex-col w-full mt-8 lg:mt-0 lg:w-1/3 h-720-px overflow-y-scroll lg:ml-8">
								<div className="bg-white px-8 py-8 rounded-lg">
									<NotificationCenter 
										t={translate}
										tooltipText={appStore.getTooltip('tooltip-notifications')}
										notifications={superAdminHomeStore.sortedNotifications}
										totalNotifications={superAdminHomeStore.totalNewNotifications}
										goToNotification={(notification) => superAdminHomeStore.appStore.goToNotification(notification)}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-1 flex-col lg:flex-row mt-8">
						<OrganisationsOverview 
							organisationStats={superAdminHomeStore.organisationStats} 
							organisationsStartDate={superAdminHomeStore.organisationsStartDate}
							organisationsEndDate={superAdminHomeStore.organisationsEndDate}
							tempOrganisationsStartDate={superAdminHomeStore.tempOrganisationsStartDate}
							tempOrganisationsEndDate={superAdminHomeStore.tempOrganisationsEndDate}
							onDateChange={({startDate, endDate}) => superAdminHomeStore.changeOrganisationsStartEndDate(startDate, endDate)}
							onApply={() => superAdminHomeStore.applyOrganisationsDateChange()}
							t={translate} />
						<div className="flex flex-col w-full mt-8 lg:mt-0 lg:w-1/3 lg:ml-8">
							<AffiliateLinks affiliateLinks={superAdminHomeStore.affiliateLinks} t={translate} />
							<div className="mt-8"></div>
							<MarketingAssets 
								t={translate}
								marketingAssets={superAdminHomeStore.marketingAssets}
								onMarketingAssets={() => superAdminHomeStore.goToMarketingAssetSearch()}
							/>
						</div>
					</div>
				</div>
			</Main>
		)
	}
}


@observer
class SalesOverview extends Component{
	render(){
		let translate = this.props.t;
		let salesStats = this.props.salesStats;
		let lastMrr = 0;
		let lastArr = 0;
		if(salesStats.mrr.length > 0){
			lastMrr = `£${parseInt(salesStats.mrr[salesStats.mrr.length - 1].metric, 10)}`;
		}
		if(salesStats.arr.length > 0){
			lastArr = `£${parseInt(salesStats.arr[salesStats.arr.length - 1].metric, 10)}`;
		}

		let showingMRR = this.props.showStatDialog == 'mrr';
		let showingARR = this.props.showStatDialog == 'arr';
		let showingAffiliateCampaign = this.props.showStatDialog == 'affiliate-campaign';
		let activeColor = 'pink-coral'
		let defaultColor = this.props.showStatDialog == null ? activeColor : 'black-30';
		let mrrGeographyBreakdown = this.props.mrrGeographyBreakdown;
		return (
			<StatOverview
				t={translate}
				startDate={this.props.salesStartDate}
				endDate={this.props.salesEndDate}
				tempStartDate={this.props.tempSalesStartDate}
				tempEndDate={this.props.tempSalesEndDate}
				onDateChange={this.props.onDateChange}
				onApply={this.props.onApply}
				title="Sales Overview"
				tooltipText="View sales overview of LabAroma below. Data sourced from Baremetrics/database."
				description="Click on the individual statsitics below in order to view full detailed analytics or download full CSV report by clicking the download icon in the top right corner."
				downloadPDF={
					<DownloadPDF 
						t={translate}
						document={
							<SalesOverviewPDF 
								logoSrc={logoPinkSrc} 
								dateRange={`${this.props.salesStartDate.format('ll')} - ${this.props.salesEndDate.format('ll')}`}
								mrrTitle={translate('superadmin.home.sales-stats.mrr')}
								mrr={lastMrr}
								arrTitle={translate('superadmin.home.sales-stats.arr')}
								arr={lastArr}
								mrrChangeTitle={translate('superadmin.home.sales-stats.mrr-change')}
								mrrChange={`${salesStats.mrr_percentage_change}%`}
								affiliateCampaignCommissionTitle={translate('superadmin.home.sales-stats.affilliate-campaign')}
								affiliateCampaignCommission={salesStats.total_affiliate_commissions}
							/>
						}
						filename="Sales.pdf"
						onDownload={() => console.log('download')}
					/>
				}
				>
					<StatBox
					 title={translate('superadmin.home.sales-stats.mrr')}
					 value={`${lastMrr}`}
					 color={!showingMRR ? defaultColor : activeColor} 
					 onOpen={this.props.onToggleMRR}
					 onClose={this.props.onToggleMRR}
					 expandable={true}
					 expanded={showingMRR}
					 />
					<StatBox 
						title={translate('superadmin.home.sales-stats.arr')}
						value={`${lastArr}`}
						color={!showingARR ? defaultColor : activeColor} 
						onOpen={this.props.onToggleARR}
					 	onClose={this.props.onToggleARR}
					 	expandable={true}
					 	expanded={showingARR}
					/>
					<StatBox 
						title={translate('superadmin.home.sales-stats.mrr-change')}
						value={`${salesStats.mrr_percentage_change}%`}
						color={defaultColor} />
					<StatBox 
						title={translate('superadmin.home.sales-stats.affilliate-campaign')}
						value={`£${salesStats.total_affiliate_commissions}`}
						color={!showingAffiliateCampaign ? defaultColor : activeColor} 
					 	onOpen={this.props.onToggleAffiliateCampaign}
					 	onClose={this.props.onToggleAffiliateCampaign}
					 	expandable={true}
					 	expanded={showingAffiliateCampaign}
					/>

					{this.props.showStatDialog != null &&
						 <div className='col-span-1 lg:col-span-4 overflow-x-scroll flex flex-col bg-white mt-4'>
							 {this.props.showStatDialog == 'mrr' && 
								<div className="flex flex-col">
									<div className="flex flex-1 flex-col lg:flex-row mt-4">
										<div className="flex flex-1 flex-col">
											<div className="flex flex-col">
												<SecondaryAppMediumTitle>{translate('superadmin.home.mrr-stat.title')}</SecondaryAppMediumTitle>
												<SmallBody className="text-list-text">{this.props.queryDates}</SmallBody>
											</div>
											<div className="flex flex-1 flex-col">
												 <MetricChartView 
												 	data={salesStats.mrr}
												  />
											</div>
										</div>
										<div className="flex w-full lg:w-2/5 flex-col ml-0 mt-8 lg:mt-0 lg:ml-8">
											<div className="flex flex-col">
												<SecondaryAppMediumTitle>{translate('superadmin.home.mrr-stat.geography.part1')}</SecondaryAppMediumTitle>
												<SmallBody className="text-list-text">{translate('superadmin.home.mrr-stat.geography.part2')}</SmallBody>
											</div>
											<div className="flex flex-1 flex-col">
												{mrrGeographyBreakdown.length == 0 && 
														<div className="flex flex-1 flex-row justify-center mt-8">
															<SmallBody className="text-list-text">{translate('superadmin.home.mrr-stat.geography.nodata')}</SmallBody>
														</div>
												}
												{mrrGeographyBreakdown.map((entry) => {
													return (
														<div className="flex flex-row justify-between mt-8">
															<div className="flex flex-row items-center">
																<div className="flex"><div className="w-4 h-4 bg-white border border-black rounded-full"></div></div>
																<div className="flex ml-4"><SmallBody className="bold-font text-black">{entry.country}</SmallBody></div>
															</div>
															<div className="flex flex-1 flex-row justify-end">
																<div className="flex">
																	<SmallBody className="bold-font text-black">£{entry.metric}</SmallBody>
																</div>
																<div className="flex ml-2">
																	<SmallBody className="bold-font text-list-text">({entry.percentage}%)</SmallBody>
																</div>
															</div>
														</div>
													)
												})}
											</div>
										</div>
									</div>
									<div className="flex items-center justify-center mt-8 cursor-pointer" onClick={this.props.onToggleMRR}>
										<SmallBody className="text-list-text bold-font underline" >{translate('common.close')}</SmallBody>
									</div>
								</div>	 
							 }
							 {this.props.showStatDialog == 'arr' && 	
								<div className="flex flex-col">
									<div className="flex flex-1 mt-4">
										<div className="flex flex-1 flex-col">
											<div className="flex flex-col">
												<SecondaryAppMediumTitle>{translate('superadmin.home.arr-stat.title')}</SecondaryAppMediumTitle>
												<SmallBody className="text-list-text">{this.props.queryDates}</SmallBody>
											</div>
											<div className="flex flex-1 flex-col">
												 <MetricChartView 
												 	data={salesStats.arr}
												  />
											</div>
										</div>
									</div>
									<div className="flex items-center justify-center mt-8 cursor-pointer" onClick={this.props.onToggleARR}>
										<SmallBody className="text-list-text bold-font underline" >{translate('common.close')}</SmallBody>
									</div>
								</div>
							 }
							 {this.props.showStatDialog == 'affiliate-campaign' && 
					 			<div className="flex flex-row">
						 			{salesStats.affiliate_commission_sales.map((affiliate_commission_sale, idx) => 
							 			<CampaignBreakdown 
							 				key={idx}
							 				t={translate}
							 				campaign={affiliate_commission_sale}
							 				bgColor={activeColor} />
						 			)}
						 		</div>
							 }
						 </div>
					}
			</StatOverview>
		)
	}
}

@observer
class UsersOverview extends Component{
	render(){
		let userStats = this.props.userStats;
		let translate = this.props.t;
		let ochreYellow = 'ochre-yellow';
		return (
			<StatOverview
				t={translate}
				startDate={this.props.usersStartDate}
				endDate={this.props.usersEndDate}
				tempStartDate={this.props.tempUsersStartDate}
				tempEndDate={this.props.tempUsersEndDate}
				onDateChange={this.props.onDateChange}
				onApply={this.props.onApply}
				title={translate('superadmin.home.users-overview.title')}
				tooltipText="View users and mailchimp subscribers overview of LabAroma below."
				description={translate('superadmin.home.users-overview.description')}
				downloadPDF={
					<DownloadPDF 
						t={translate}
						document={
							<UsersOverviewPDF 
								logoSrc={logoPinkSrc}
								dateRange={`${this.props.usersStartDate.format('ll')} - ${this.props.usersEndDate.format('ll')}`}
								activeUsersTitle={translate('superadmin.home.user-stats.active-users')}
								activeUsers={userStats.active_users}
								usersChangeTitle={translate('superadmin.home.user-stats.user-change')}
								usersChange={`${userStats.user_percentage_change}%`}
								newUsersTitle={translate('superadmin.home.user-stats.new-users')}
								newUsers={userStats.new_users}
								newSubscribersTitle={translate('superadmin.home.user-stats.new-subscribers')}
								newSubscribers={userStats.new_subscribers}
							/>
						}
						filename="Users.pdf"
						onDownload={() => console.log('download')}
					/>
				}
				>
					<StatBox
					 title={translate('superadmin.home.user-stats.active-users')}
					 value={userStats.active_users}
					 color={ochreYellow} />

					<StatBox 
						title={translate('superadmin.home.user-stats.user-change')}
						value={`${userStats.user_percentage_change}%`}
						color={ochreYellow} />

					<StatBox 
						title={translate('superadmin.home.user-stats.new-users')}
						value={userStats.new_users}
						color={ochreYellow} />
					<StatBox 
						title={translate('superadmin.home.user-stats.new-subscribers')}
						value={userStats.new_subscribers}
						color={ochreYellow} />
			</StatOverview>
		)
	}
}

@observer
class OrganisationsOverview extends Component{
	render(){
		let organisationStats = this.props.organisationStats;
		let translate = this.props.t;
		let midBlue = 'mid-blue';
		return (
			<StatOverview
				t={translate}
				startDate={this.props.organisationsStartDate}
				endDate={this.props.organisationsEndDate}
				tempStartDate={this.props.tempOrganisationsStartDate}
				tempEndDate={this.props.tempOrganisationsEndDate}
				onDateChange={this.props.onDateChange}
				onApply={this.props.onApply}
				title={translate('superadmin.home.organisations-overview.title')}
				tooltipText="View organisations on LabAroma overview below"
				description={translate('superadmin.home.organisations-overview.description')}
				downloadPDF={
					<DownloadPDF 
						t={translate}
						document={
							<OrganisationsOverviewPDF 
								logoSrc={logoPinkSrc} 
								dateRange={`${this.props.organisationsStartDate.format('ll')} - ${this.props.organisationsEndDate.format('ll')}`}
								activeOrganisationsTitle={translate('superadmin.home.organisation-stats.active-organisations')}
								activeOrganisations={organisationStats.active_organisations}
								organisationsChangeTitle={translate('superadmin.home.organisation-stats.organisation-change')}
								organisationsChange={`${organisationStats.organisation_percentage_change}%`}
								newOrganisationsTitle={translate('superadmin.home.organisation-stats.new-organisations')}
								newOrganisations={organisationStats.new_organisations}
							/>
						}
						filename="Organisations.pdf"
						onDownload={() => console.log('download')}
					/>
				}
				>
					<StatBox
					 title={translate('superadmin.home.organisation-stats.active-organisations')}
					 value={organisationStats.active_organisations}
					 color={midBlue} />
					<StatBox
						 title={translate('superadmin.home.organisation-stats.organisation-change')}
						 value={`${organisationStats.organisation_percentage_change}%`}
						 color={midBlue} />
					<StatBox
						 title={translate('superadmin.home.organisation-stats.new-organisations')}
						 value={organisationStats.new_organisations}
						 color={midBlue} />
			</StatOverview>
		)
	}
}

export default withTranslation()(withAuthorisation(SuperAdministratorHome, SUPER_ADMINISTRATOR));