import { observable, action, computed } from 'mobx';

import TrainingApi from '../../services/training';

import moment from 'moment';

class TrainingStore{
	appStore; 
	
	trainingApi;

	@observable tutorials;

	constructor(appStore){
		this.appStore = appStore;
		this.trainingApi = new TrainingApi(appStore);
		this.initStore();
	}

	initStore(){
		this.tutorials = [];
	}

	fetchTutorials(){
		this.trainingApi.getAll()
			.then((response) => {
				this.tutorials = response.tutorials.map((tutorial) => {
					tutorial.created_on = moment(tutorial.created_on);
					return tutorial;
				});
			})
			.catch((error) => {
				console.log(error);
			})
	}
}

export default TrainingStore;