import React, {Component} from 'react';

class FullGrid extends Component{
	render(){
		return (
			<div className={`flex flex-1 flex-col bg-white px-8 py-8 rounded-xl ${this.props.className}`}>
				{this.props.children}
			</div>
		)
	}
}

class Grid extends Component{
	render(){
		return (
			<div className={`flex h-auto flex-col bg-white px-8 py-8 rounded-xl ${this.props.className}`}>
				{this.props.children}
			</div>
		)
	}
}

export {
	FullGrid,
	Grid
}