import React, {Component} from 'react';

import {observer} from 'mobx-react';

@observer
class Button extends Component{
	render(){
		let disabled = false;
		if('disabled' in this.props){
			disabled = this.props.disabled;
		}

		if(this.props.fetching){
			disabled = true;
		}

		let fgColor = this.props.fgColor != null ? this.props.fgColor : 'white';
		let bgColor = this.props.bgColor != null ? this.props.bgColor : 'black';

		let bgHoverColor = fgColor;
		let fgHoverColor = bgColor != 'transparent' ? bgColor : 'black';
		let width = this.props.width ? this.props.width : 'w-36';
		let height = this.props.height ? this.props.height : 'h-18';
		let textSize = this.props.textSize ? this.props.textSize : 'text-lg';
		let btnClassName = `${width} ${height} justify-center items-center bg-${bgColor} text-${fgColor} ${textSize} semibold-font outline-none focus:outline-none`;
		if(this.props.className){
			btnClassName = `${btnClassName} ${this.props.className}`
		}
		if(!btnClassName.includes('text')){
			btnClassName = `${btnClassName} ${fgColor}`
		}
		if(this.props.borderColor){
			btnClassName = `${btnClassName} border border-${this.props.borderColor}`;
		}
		if(disabled){
			btnClassName = `${btnClassName} opacity-25`
		}


		return (
				<button 
					className={btnClassName}
					onClick={this.props.onClick}
					disabled={disabled}>{this.props.fetching ? '...' : this.props.children}</button>)
	}
}


@observer
class OrangeButton extends Component{
	render(){
		return <Button {...this.props} className={`bg-orange ${this.props.className}`} />
	}
}


@observer
class MidGreenButton extends Component{
	render(){
		return <Button {...this.props} className={`bg-mid-green ${this.props.className}`} />
	}
}


@observer
class MidYellowButton extends Component{
	render(){
		return <Button {...this.props} className={`bg-mid-yellow ${this.props.className}`} />
	}
}


@observer
class LightBlueButton extends Component{
	render(){
		return <Button {...this.props} className={`bg-light-blue ${this.props.className}`} />
	}
}

@observer
class DarkBlueButton extends Component{
	render(){
		return <Button {...this.props} className={`bg-dark-blue ${this.props.className}`} />
	}
}

export { Button, OrangeButton, MidGreenButton, MidYellowButton, LightBlueButton, DarkBlueButton};