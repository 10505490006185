import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';

import {inject, observer} from 'mobx-react';
import { SmallBody, Body, SecondaryAppLargeTitle, TertiaryAppSmallTitle, ExtraSmallBody} from '../../common/text';
import { LibraryBookIcon, ArrowRightIcon, PlusIcon, StarIcon, EditIcon, EyeIcon, DuplicateIcon, CompareIngredients, ArchiveIcon, TickIcon} from '../../common/icon'

import {ToolTipItem, ToggleSwitch} from '../../common/input'
import {FormTab} from '../../elements/form';
import {Button} from '../../common/button';
import {SortDialog, FilterField, FilterDialog, SortingField} from '../../elements/list';
import Main from '../../elements/main';
import {ImageAvatar, SearchBar, CheckBox} from '../../common/input';
import {TagInput, Tag} from '../../common/tag';

const colors = require('../../../color.js');


@observer
class IngredientPreview extends Component{
	constructor(props){
		super(props);
		this.onToggleFavouriteIngredient = this.onToggleFavouriteIngredient.bind(this);
		this.onToggleTagEditMode = this.onToggleTagEditMode.bind(this);
		this.onIngredientTagsChange = this.onIngredientTagsChange.bind(this);
		this.onNewIngredientTag = this.onNewIngredientTag.bind(this);
		this.onTagChange = this.onTagChange.bind(this);
		this.onEditIngredient = this.onEditIngredient.bind(this);
		this.onViewIngredient = this.onViewIngredient.bind(this);
		this.onArchiveIngredient = this.onArchiveIngredient.bind(this);
		this.onRestoreIngredient = this.onRestoreIngredient.bind(this);
		this.onDuplicateIngredient = this.onDuplicateIngredient.bind(this);
		this.toggleAvailability = this.toggleAvailability.bind(this);
	}

	onToggleFavouriteIngredient(e){
		e.stopPropagation();
		let ingredient = this.props.ingredient;
		this.props.toggleFavourite(ingredient.id)
	}

	onToggleTagEditMode(e){
		e.stopPropagation();
		let ingredient = this.props.ingredient;
		this.props.toggleTagEditMode(ingredient.id)
	}

	onIngredientTagsChange(newTags){
		let ingredient = this.props.ingredient;
		this.props.onTagsChange(ingredient.id, newTags);
	}

	onNewIngredientTag(tag){
		let ingredient = this.props.ingredient;
		this.props.onNewTag(ingredient.id, tag);
	}

	onTagChange(inputValue){
		let ingredient = this.props.ingredient;
		this.props.onTagInputChange(ingredient.id, inputValue);
	}

	onEditIngredient(e){
		e.stopPropagation();
		let ingredient = this.props.ingredient;
		this.props.onEdit(ingredient.id);
	}

	onViewIngredient(e){
		e.stopPropagation();
		let ingredient = this.props.ingredient;
		this.props.onView(ingredient.id);
	}

	onArchiveIngredient(e){
		e.stopPropagation();
		let ingredient = this.props.ingredient;
		this.props.onArchive(ingredient.id);
	}

	onRestoreIngredient(e){
		e.stopPropagation();
		let ingredient = this.props.ingredient;
		this.props.onRestore(ingredient.id);
	}

	onDuplicateIngredient(e){
		e.stopPropagation();
		let ingredient = this.props.ingredient;
		this.props.onDuplicate(ingredient.id);
	}

	toggleAvailability(e){
		e.stopPropagation();
		let ingredient = this.props.ingredient;
		this.props.onToggleAvailable(ingredient.id);
	}

	render(){
		let ingredient = this.props.ingredient;
		let translate = this.props.t;

		let actionGridCols = this.props.canManage ? 4 : 2;
		let interactGridCols = 4;
		if(!this.props.canManage && !this.props.showAvailability){
			interactGridCols = 4
		}
		if(this.props.showAvailability){
			interactGridCols = 5;
		}
		return (
			<div className="flex flex-1 bg-white rounded-lg py-8 px-6 h-auto mt-8 cursor-pointer" onClick={this.onViewIngredient}>
				<div className="flex flex-1 flex-row justify-between items-center">
					<div className="flex flex-row items-center">
						<div className="flex ml-2">
							<ImageAvatar
								disabled={true}
								backupAvatarUrl={this.props.ingredientAvatarUrl}
								width="w-20"
								height="h-20"
								t={translate}
								noborder
								url={ingredient.photo_url} />
						</div>
						<div className="flex flex-col ml-8 w-32">
							<div className="flex"><SmallBody>{translate('user.my-ingredient-library.headers.name')}</SmallBody></div>
							<div className="flex mt-1"><TertiaryAppSmallTitle>{ingredient.name}</TertiaryAppSmallTitle></div>
							<div className="flex"><SmallBody className="italic">{ingredient.latin_name}</SmallBody></div>
						</div>
					</div>
					<div className="flex flex-col ml-2 w-32">
						<div className="flex"><SmallBody>{translate('user.my-ingredient-library.headers.country-of-origin')}</SmallBody></div>
						<div className="flex mt-1"><TertiaryAppSmallTitle>{ingredient.origin}</TertiaryAppSmallTitle></div>
					</div>
					<div className="flex flex-col ml-2 w-32">
						<div className="flex"><SmallBody>{translate('user.my-ingredient-library.headers.classification')}</SmallBody></div>
						<div className="flex mt-1"><TertiaryAppSmallTitle>{ingredient.ingredient_type?.name}</TertiaryAppSmallTitle></div>
					</div>
					{!ingredient.public && 
						<div className="flex hidden xl:block flex-col ml-2 w-32 overflow-x-scroll" >
							<div className="flex flex-row items-center" onClick={this.onToggleTagEditMode}>
								<SmallBody>{translate('user.my-ingredient-library.headers.tags')}</SmallBody>
							</div>
							<div className="flex flex-row w-32 overflow-x-scroll" onClick={this.onToggleTagEditMode}>
								<div className="flex flex-col">
									{!ingredient.editTags && ingredient.tags.map((ingredient_tag) => 
										<div className="mt-1">
											<Tag key={ingredient_tag.id} name={ingredient_tag.tag.name} />
										</div>
									)}
								</div>
								{!ingredient.editTags && ingredient.tags.length == 0 && 
									<TertiaryAppSmallTitle>{translate('common.tags.notags')}</TertiaryAppSmallTitle>
								}
								{ingredient.editTags && 
									<TagInput
										 inputValue={ingredient.currentValue}
										 onInputChange={this.onTagChange}
										 t={translate}
										 onChange={this.onIngredientTagsChange}
										 onNewEntry={this.onNewIngredientTag}
										 values={ingredient.tagOptions} />
								}
							</div>
						</div>
					}
					<div className={`flex grid grid-cols-${interactGridCols} gap-8`}>
						{this.props.showCompare && 
							<div className="flex flex-col items-center">
								<div className="flex"><SmallBody>{translate('user.my-ingredient-library.headers.compare')}</SmallBody></div>
								<div className="flex flex-row justify-center mt-2" onClick={(e) => e.stopPropagation()}>
									<CheckBox 
										extraInputClassNames={`cursor-pointer rounded-full text-pink-coral w-4 h-4`} 
										checked={this.props.checkedIngredientIds.includes(ingredient.id)} 
										onChange={(e) => {
											e.stopPropagation();
											this.props.onCompareIngredient(ingredient.id);
										}}
									/>
								</div>
							</div>
						}
						
						<div className="flex flex-col items-center">
							<div className="flex"><SmallBody>{translate('user.my-ingredient-library.headers.favourite')}</SmallBody></div>
							<div className="flex flex-row justify-center mt-2">
								
								<div className="flex cursor-pointer" onClick={this.onToggleFavouriteIngredient}>
									<StarIcon fill={ingredient.favourited ? colors['dark-green'].default : 'none'} className='w-4 h-4' color={colors['dark-green'].default}  />
								</div>
							</div>
						</div>
						<div className="col-span-2">
							<div className="flex flex-col items-center">
								<div className="flex"><SmallBody>{translate('user.my-ingredient-library.headers.actions')}</SmallBody></div>

								<div className={`flex flex-row grid grid-cols-${actionGridCols} mt-2`}>
									{this.props.canManage && 
										<ToolTipItem
										 	onClick={this.onEditIngredient}
										 	title={translate('user.my-ingredient-library.edit-tooltip.title')}
										 	text={translate('user.my-ingredient-library.edit-tooltip.text')}
										 	icon={<EditIcon className="w-4 h-4" color={colors['black']} />}
										 />
									 }
									 <ToolTipItem
									 	onClick={this.onViewIngredient}
									 	title={translate('user.my-ingredient-library.view-tooltip.title')}
									 	text={translate('user.my-ingredient-library.view-tooltip.text')}
									 	icon={<EyeIcon className="w-4 h-4" color={colors['black']} />}
									 />
									 

									 <ToolTipItem
									 	onClick={this.onDuplicateIngredient}
									 	title={translate('user.my-ingredient-library.duplicate-tooltip.title')}
									 	text={translate('user.my-ingredient-library.duplicate-tooltip.text')}
									 	icon={<DuplicateIcon className="w-4 h-4" color={colors['black']} />}
									 />
									 {this.props.canManage && 
									 	 <>
											 {!ingredient.archived && 
											 	<ToolTipItem
												 	onClick={this.onArchiveIngredient}
												 	title={translate('user.my-ingredient-library.archive-tooltip.title')}
												 	text={translate('user.my-ingredient-library.archive-tooltip.text')}
												 	icon={<ArchiveIcon className="w-4 h-4" color={colors['black']} />}
												 />
											 }
											 {ingredient.archived && 
											 	<ToolTipItem
												 	onClick={this.onRestoreIngredient}
												 	title={translate('user.my-ingredient-library.restore-tooltip.title')}
												 	text={translate('user.my-ingredient-library.restore-tooltip.text')}
												 	icon={<TickIcon className="w-4 h-4" color={colors['black']} />}
												 />
											}
										</>
									}
								</div>
							</div>
						</div>
						{this.props.showAvailability && 
							<div className="flex flex-col items-center">
								<div className="flex"><SmallBody>{translate('user.my-ingredient-library.headers.availability')}</SmallBody></div>
								<div className="flex flex-row justify-center mt-2">
									<ToggleSwitch 
										checked={ingredient.public} 
										onChange={this.toggleAvailability} 
									/>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		)	
	}
}


@observer
class IngredientLibrary extends Component{
	constructor(props){
		super(props);
		this.onSortOption = this.onSortOption.bind(this);
		this.onApplySort = this.onApplySort.bind(this);
		this.onViewTutorial = this.onViewTutorial.bind(this);
	}

	componentWillUnmount(){
		let ingredientsLibraryStore = this.props.ingredientsLibraryStore;
		ingredientsLibraryStore.initStore();
	}

	onSortOption(val){
		let ingredientsLibraryStore = this.props.ingredientsLibraryStore;
		ingredientsLibraryStore.setSortByField(val);
	}

	onApplySort(){
		let ingredientsLibraryStore = this.props.ingredientsLibraryStore;
		ingredientsLibraryStore.applySort();
	}

	onViewTutorial(){
		let ingredientsLibraryStore = this.props.ingredientsLibraryStore;

		let url = null;
		if(ingredientsLibraryStore.showPublicLibrary){
			url = 'http://help.labaroma.com/en/articles/4745418-6-labaroma-ingredient-library-tutorial';
		}else{
			// todo replace with my ingredient library
			url = 'http://help.labaroma.com/en/articles/4745415-5-my-ingredient-library-tutorial';
		}
		window.open(url, '_blank');
	}

	render(){
		let ingredientsLibraryStore = this.props.ingredientsLibraryStore;
		let translate = this.props.t;
		let archivedButtonText = ingredientsLibraryStore.showArchived ? translate('user.my-ingredient-library.active-ingredients-button') : translate('user.my-ingredient-library.archived-ingredients-button');
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					{!ingredientsLibraryStore.showPublicLibrary && 
						<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
							<div className="flex flex-1 flex-col">
								<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.my-ingredient-library.tagline')}</SecondaryAppLargeTitle>
								<SmallBody>{translate('user.my-ingredient-library.paragraph.part1')} <br /> {translate('user.my-ingredient-library.paragraph.part2')}</SmallBody>
								
								<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={this.onViewTutorial}>
									<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.my-ingredient-library.video-tutorial')} </TertiaryAppSmallTitle></div>
									<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
								</div>
							</div>
							<div className="flex hidden lg:block ml-4">
								<LibraryBookIcon color='pink' className="w-32 h-32" />
							</div>
						</div>
					}
					{ingredientsLibraryStore.showPublicLibrary && 
						<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
							<div className="flex flex-1 flex-col">
								<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.labaroma-ingredient-library.tagline')}</SecondaryAppLargeTitle>
								<SmallBody>{translate('user.labaroma-ingredient-library.paragraph.part1')} <br /> {translate('user.labaroma-ingredient-library.paragraph.part2')}</SmallBody>
								
								<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={this.onViewTutorial}>
									<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.labaroma-ingredient-library.video-tutorial')} </TertiaryAppSmallTitle></div>
									<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
								</div>
							</div>
							<div className="flex ml-4">
								<LibraryBookIcon color='blue' className="w-32 h-32" />
							</div>
						</div>
					}
					<div className="flex w-full justify-end mt-2">
						<Button
							width="w-auto"
							height="h-12"
							className="px-4 rounded-lg"
							fgColor={ingredientsLibraryStore.showArchived ? "list-text" : "list-text"}
							bgColor={ingredientsLibraryStore.showArchived ? "gray-bg-button" : "white"}
							borderColor={ingredientsLibraryStore.showArchived ? "gray-bg-button" : "list-text"}
							fetching={ingredientsLibraryStore.fetching}
							onClick={() => ingredientsLibraryStore.toggleArchived()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{archivedButtonText} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><ArchiveIcon color={colors['list-text']} className="w-4 h-4" /></div>
							</div>
						</Button>
						{ingredientsLibraryStore.compareIngredientIds.length > 0 && 
							<div className="flex ml-4">
								<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									onClick={() => ingredientsLibraryStore.compareIngredients()}
								>
									<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-ingredient-library.compare-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><CompareIngredients className="w-4 h-4" color='white' /></div>
									</div>
								</Button>
							</div>
						}
						{ingredientsLibraryStore.canCreateIngredients && 
							<div className="flex ml-4">
								<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									fetching={ingredientsLibraryStore.fetching}
									onClick={() => ingredientsLibraryStore.onNewIngredient()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-ingredient-library.new-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><PlusIcon /></div>
									</div>
								</Button>
							</div>
						}
					</div>

					<div className="flex flex-row overflow-x-scroll mt-8">
						{ingredientsLibraryStore.headerOptions.map((ingredientType) => 
							<FormTab onClick={() => ingredientsLibraryStore.changeCurrentIngredientType(ingredientType.id)} active={ingredientsLibraryStore.currentIngredientTypeId == ingredientType.id}>{ingredientType.name}</FormTab>
						)}
					</div>

					<div className="flex flex-1 flex-col">
						
						<div className="flex flex-1 flex-row mt-8">
							<div className="flex w-1/3">
								<SearchBar 
									onChange={(e) => ingredientsLibraryStore.changeSearchText(e.target.value)}
									t={translate}
									extraInputClassNames="h-12"
									placeholder={translate('user.my-ingredient-library.search-bar.placeholder')}
								/>
							</div>
						</div>
						<div className="relative flex flex-row justify-end items-center mt-2">
							<div className="relative flex flex-row items-center">
								<FilterField text={translate('user.my-ingredient-library.filter')} onClick={() => ingredientsLibraryStore.toggleFilterDialog()}/>
								{ingredientsLibraryStore.showFilterDialog && 
										<FilterDialog
										 	t={translate}
										 	onApply={() => ingredientsLibraryStore.applyFilters()}
										>
											<div className="flex flex-col w-64">
												<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-ingredient-library.headers.tags')}</TertiaryAppSmallTitle></div>
												<div className="flex">
													<TagInput
													 inputValue={ingredientsLibraryStore.currentFilterValue}
													 onInputChange={(val) => ingredientsLibraryStore.setCurrentFilterValue(val)}
													 t={translate}
													 onChange={(values) => ingredientsLibraryStore.setFilters(values)}
													 onNewEntry={(val) => ingredientsLibraryStore.newFilterEntry(val)}
													 values={ingredientsLibraryStore.currentFilters} 
													/>
												</div>
											</div>
										</FilterDialog>
									}
							</div>
							<div className="flex ml-8">
								<div className="relative flex flex-row items-center">
									<SortingField 
										text={translate('user.my-ingredient-library.sort')}
										onClick={() => ingredientsLibraryStore.toggleSortDialog()}
									/>
									{ingredientsLibraryStore.showSortDialog && 
										<SortDialog
										 	t={translate}
											sortOptions={ingredientsLibraryStore.sortOptions}
											currentOption={ingredientsLibraryStore.sortBy}
											onOption={this.onSortOption}
											onApply={this.onApplySort}
										/>
									}
							</div>
							</div>
						</div>
						<InfiniteScroll
						  dataLength={ingredientsLibraryStore.ingredientsWithTags.length}
						  next={() => ingredientsLibraryStore.loadNextPage()}
						  hasMore={ingredientsLibraryStore.hasMore}
						  loader={null}
						  endMessage={null}
						>
						  {ingredientsLibraryStore.ingredientsWithTags.map((ingredient) => 
								<IngredientPreview 
									t={translate}
									key={ingredient.id} 
									ingredient={ingredient}
									ingredientAvatarUrl={ingredientsLibraryStore.getAvatarUrl(ingredient)}
									toggleFavourite={(id) => ingredientsLibraryStore.onToggleFavouriteIngredient(id)}
									toggleTagEditMode={(id) => ingredientsLibraryStore.onToggleTagEditMode(id)}
									onEdit={(id) => ingredientsLibraryStore.onEditIngredient(id)}
									onView={(id) => ingredientsLibraryStore.onViewIngredient(id)}
									onArchive={(id) => ingredientsLibraryStore.onArchiveIngredient(id)}
									onRestore={(id) => ingredientsLibraryStore.onRestoreIngredient(id)}
									onDuplicate={(id) => ingredientsLibraryStore.onDuplicateIngredient(id)}
									onNewTag={(id, tag) => ingredientsLibraryStore.newIngredientTag(id, tag)}
									onTagsChange={(id, tags) => ingredientsLibraryStore.onIngredientTagsChange(id, tags)}
									onTagInputChange={(id, tag) => ingredientsLibraryStore.changeIngredientTagInput(id, tag)}
									canManage={ingredientsLibraryStore.hasEditAccessToIngredient(ingredient)}
									onToggleAvailable={(id) => ingredientsLibraryStore.onPublicIngredient(id)}
									showAvailability={ingredientsLibraryStore.showAvailability}
									checkedIngredientIds={ingredientsLibraryStore.compareIngredientIds}
									onCompareIngredient={(id) => ingredientsLibraryStore.toggleIngredientIdToCompare(id)}
									showCompare={ingredientsLibraryStore.showCompare}
								/>
							)}
							{ingredientsLibraryStore.ingredients.length == 0&& !ingredientsLibraryStore.fetching && 
								<div className="flex w-full justify-center mt-8">
									<Body>{translate('user.my-ingredient-library.no-ingredients')}</Body>
								</div>
							}
							{ingredientsLibraryStore.ingredients.length == 0 && ingredientsLibraryStore.fetching &&
								<div className="flex w-full justify-center mt-8">
									<Body>{translate('user.my-ingredient-library.fetching-ingredients')}</Body>
								</div>
							}
						</InfiniteScroll>
					</div>
				</div>
			</Main>
		)
	}
}


export default withTranslation()(IngredientLibrary)