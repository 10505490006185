import React, {Component, useRef} from 'react';

import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroll-component';

import { SmallBody, Body, SecondaryAppLargeTitle, TertiaryAppSmallTitle, SecondaryAppMediumTitle, SecondaryWebSmallTitle, ExtraSmallBody, ApplyText} from '../../../common/text';
import {SaveIcon, DeleteIcon, DuplicateIcon, PlusIcon, StarIcon, EditIcon, ViewBlendIcon, CreateNewBlend, ArrowDownIcon, ArrowUpIcon, ArrowLeftIcon, ArrowRightIcon, UpgradeIcon, EyeIcon, ScentNoteIcon, DownloadIcon, CircleIcon} from '../../../common/icon';
import {Button} from '../../../common/button';
import BaseModal from '../../../common/modal';
import Loader from '../../../common/loader';
import Main from '../../../elements/main';
import {Status} from '../../../elements/list';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormSelect,
	FormSearchSelect,
	FormTextArea,
	FormPasswordInput,
	FormTab,
	TwoColumnReadList,
	AddListButton,
	GridSection
} from '../../../elements/form';
import {SearchBar, FileEntry, SliderInput, Select, CheckBox, HelpTooltip} from '../../../common/input';
import {DownloadPDF, pdfStyles} from '../../../common/pdf';
import {PieChart} from '../../../common/chart';
import UploadFiles from '../../../common/upload-files';
import {TagInput, Tag} from '../../../common/tag';

import ChemicalFamilyDialog from '../../modals';

import blendingTubIconSrc from '../../../../assets/img/icons/blending-tub.svg';
import logoPinkSrc from '../../../../assets/img/logo.png';

import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import {isMobile} from "react-device-detect";

import { pdf, Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import printJS from 'print-js'
import * as htmlToImage from 'html-to-image';


const ItemTypes = {
  INGREDIENT: 'ingredient',
  BLEND_INGREDIENT: 'blend_ingredient'
}

const colors = require('../../../../color.js');


const SPECIAL_FORM_INPUT_CLASS_NAME = "h-8 w-16 overflow-hidden text-sm bg-white border border-black-10 rounded-md text-center items-center"

const sortByPercentage = (c1, c2) => c1.percentage > c2.percentage ? -1 : 1;

@observer
class BlendEntry extends Component{
	render(){
		let entry = this.props.entry;
		return (
			<div className="flex flex-row w-full items-center justify-between mt-2 no-underline border-b border-transparent hover:border-black-10 cursor-pointer py-1" onClick={() => this.props.onEdit(entry.ingredient.id)}>
				<div className="flex flex-row items-center">
					<div className="flex w-3 h-3 border-radius-50" style={{backgroundColor: entry.ingredient.hex_color}} />
					<div className="flex flex-col ml-2">
						<div className="flex"><SmallBody>{entry.ingredient.name}</SmallBody></div>
						<div className="flex"><ExtraSmallBody className="italic">{entry.amount}</ExtraSmallBody></div>
					</div>
				</div>
				<div className="flex flex-1 justify-end"><SmallBody>{this.props.entryAmountToShow}</SmallBody></div>
			</div>
		)
	}
}

const IngredientPanel = observer(({t, currentIngredients, onFinishDrop=null, onLoadMoreIngredients=null, hasMoreIngredients=true, onClickIngredient=null, loading=false, onFavouriteIngredient=null}) => {
	let translate = t;
	const [collectedProps, drop] = useDrop({
	    accept: ItemTypes.BLEND_INGREDIENT,
	    drop: () => {},
	    canDrop: () => true,
	    collect: monitor => ({
	      isOver: !!monitor.isOver(),
      	  canDrop: !!monitor.canDrop()
	    })
	})

	return (	
		<div id="scrollable-ingredients" className="flex flex-col w-full overflow-y-scroll border border-black-10 rounded-lg" style={{ height: '400px'}}>
			<InfiniteScroll
			  dataLength={currentIngredients.length}
			  next={() => onLoadMoreIngredients()}
			  style={{ display: 'flex', flex: 1}}
			  hasMore={hasMoreIngredients}
			  loader={null}
			  endMessage={null}
			  scrollableTarget="scrollable-ingredients"
			>
				<div ref={drop} className="flex flex-1 w-full h-full justify-end grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8 overflow-scroll mt-8 px-8 py-4">
					{currentIngredients.map((ingredient) => 
						<IngredientBubble 
							ingredient={ingredient}
							onFinishDrop={onFinishDrop}
							onClickIngredient={onClickIngredient}
							onFavouriteIngredient={onFavouriteIngredient}
						/>
					)}
					{currentIngredients.length == 0 &&
						<div className="col-span-4 flex w-full justify-center items-center">
							<SmallBody>{loading ? translate('user.manage-blend.ingredients.loading') : translate('user.manage-blend.ingredients.none-found')}</SmallBody>	
						</div>
					}
				</div>
			</InfiniteScroll>
		</div>
	)
})

const BlendPanel = observer(({t, currentBlends, onFinishDrop=null, onLoadMoreBlends=null, hasMoreBlends=true, onClickBlend=null, loading=false}) => {
	let translate = t;
	const [collectedProps, drop] = useDrop({
	    accept: ItemTypes.BLEND_INGREDIENT,
	    drop: () => {},
	    canDrop: () => true,
	    collect: monitor => ({
	      isOver: !!monitor.isOver(),
      	  canDrop: !!monitor.canDrop()
	    })
	})

	return (	
		<div id="scrollable-blends" className="flex flex-col w-full overflow-y-scroll border border-black-10 rounded-lg" style={{ height: '400px'}}>
			<InfiniteScroll
			  dataLength={currentBlends.length}
			  next={() => onLoadMoreBlends()}
			  style={{ display: 'flex', flex: 1}}
			  hasMore={hasMoreBlends}
			  loader={null}
			  endMessage={null}
			  scrollableTarget="scrollable-ingredients"
			>
				<div ref={drop} className="flex flex-1 w-full h-full justify-end grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8 overflow-scroll mt-8 px-8 py-4">
					{currentBlends.map((blend) => 
						<BlendBubble 
							blend={blend}
							t={translate}
							onFinishDrop={onFinishDrop}
							onClickBlend={onClickBlend}
						/>
					)}
					{currentBlends.length == 0 &&
						<div className="col-span-4 flex w-full justify-center items-center">
							<SmallBody>{loading ? translate('user.manage-blend.blends.loading') : translate('user.manage-blend.blends.none-found')}</SmallBody>	
						</div>
					}
				</div>
			</InfiniteScroll>
		</div>
	)
})

@observer
class BlendingLab extends Component{
	constructor(props){
		super(props);
	}
	render(){
		let translate = this.props.t;
		let manageBlendStore = this.props.manageBlendStore;
		let editMode = 'editMode' in this.props ? this.props.editMode : false;
		let editBlendIngredient = manageBlendStore.blendData.editBlendIngredient;

		return (
			<DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
				{editMode && 
					<div className="col-span-6 xl:col-span-3">
						<GridSection>
							<div className="flex flex-row items-center">
								<SecondaryWebSmallTitle>{translate('user.manage-blend.ingredients.title')}</SecondaryWebSmallTitle>
								<HelpTooltip text={this.props.tooltipText} />
							</div>

							<div className="flex flex-1 flex-col justify-between mt-2">
								<div className="flex flex-row overflow-x-scroll w-full">
									{manageBlendStore.ingredientTypeOptions.map((ingredientType) => 
										<FormTab 
											small
											onClick={() => manageBlendStore.changeCurrentIngredientType(ingredientType.id)} 
											active={manageBlendStore.currentIngredientTypeId == ingredientType.id}>{ingredientType.name}</FormTab>
									)}
								</div>
								<div className="flex flex-row items-center mt-4">
									<div className="flex flex-1">
										<SearchBar 
											onChange={(e) => {
												manageBlendStore.changeIngredientSearchText(e.target.value)
											}}
											value={manageBlendStore.ingredientSearchText != null ? manageBlendStore.ingredientSearchText : ''}
											t={translate}
											extraInputClassNames="h-12 bg-black-10"
											placeholder={translate('user.manage-blend.ingredients.search-bar.placeholder')}
										/>
									</div>
									<div className="flex flex-1 ml-4">
										<SmallBody className="tracking-wider">{false && translate('user.manage-blend.ingredients.advanced-search.label')}</SmallBody>
									</div>
								</div>
								<div className="flex flex-1 flex-col w-full">
									<div className="flex mt-2">
										<SmallBody className="italic">{manageBlendStore.showingBlends ? translate('user.manage-blend.blends.instruction') : translate('user.manage-blend.ingredients.instruction')}</SmallBody>
									</div>
									{!manageBlendStore.showingBlends && 
										<IngredientPanel 
											currentIngredients={manageBlendStore.currentIngredients}
											t={translate}
											onFinishDrop={(id) => {
												manageBlendStore.markChange();
												manageBlendStore.onAddIngredientToBlend(id)
											}}
											onLoadMoreIngredients={() => manageBlendStore.loadNextIngredientsPage()}
											onClickIngredient={(id) => manageBlendStore.goToIngredient(id)}
											onFavouriteIngredient={(id) => manageBlendStore.toggleFavouriteIngredient(id)}
											hasMoreIngredients={manageBlendStore.hasMoreIngredients}
											loading={manageBlendStore.fetchingIngredients}
										/>
									}
									{manageBlendStore.showingBlends && 
										<BlendPanel 
											currentBlends={manageBlendStore.currentBlends}
											t={translate}
											onFinishDrop={(id) => {
												manageBlendStore.markChange();
												manageBlendStore.onAddBlendToBlend(id)
											}}
											onLoadMoreBlends={() => manageBlendStore.loadNextBlendsPage()}
											onClickBlend={(id) => manageBlendStore.goToBlend(id)}
											hasMoreBlends={manageBlendStore.hasMoreBlends}
											loading={manageBlendStore.fetchingBlends}
										/>
									}
									<div className="flex w-full mt-4 flex-row justify-between">
										{manageBlendStore.alphabetOptions().map((option) => 
											<div className="flex cursor-pointer" onClick={() => manageBlendStore.triggerSearch(option.value)}>
												<SmallBody className={`bold-font ${option.label === manageBlendStore.ingredientSearchText ? 'text-pink-coral border-b border-pink-coral': 'text-black'}`}>{option.label}</SmallBody>
											</div>
										)}
									</div>
								</div>
							</div>
						</GridSection>
					</div>
				}
				<div className={editMode ? "col-span-6 xl:col-span-3" : "col-span-6"}>
					<GridSection>
						<div className="flex flex-row items-center">
							<SecondaryWebSmallTitle>{translate('user.manage-blend.blend-lab.title')}</SecondaryWebSmallTitle>
							<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-blend-lab')} />
						</div>
						{manageBlendStore.formulating && 
							<div className="flex flex-row w-full justify-start">
								<SmallBody className="italic">{translate('user.manage-blend.formulating')}</SmallBody>
							</div>
						}

						{editMode && 
							<>
								<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 border-b border-black py-1 mt-2">
									<div className="flex flex-row items-center">
										<div className="flex"><SmallBody className="tracking-wide bold-font">{translate('user.manage-blend.blend-lab.essential-oil-percentage.label')}</SmallBody></div>
										<div className="flex ml-4">
											<FormInput 
												className={SPECIAL_FORM_INPUT_CLASS_NAME}
												placeholder={translate('user.manage-blend.blend-lab.essential-oil-percentage.placeholder')}
												value={manageBlendStore.blendData.essentialOilBlendPercentage.value}
												error={manageBlendStore.blendData.essentialOilBlendPercentage.error}
												onChange={(e) => {
													manageBlendStore.markChange();
													manageBlendStore.changeEssentialOilBlendPercentage(e.target.value, false)
												}}
												onBlur={(e) => {
													manageBlendStore.getBlendPreview();
												}}
											/>
										</div>
									</div>
									<div className="flex flex-row items-center justify-end">
										<div className="flex"><SmallBody className="tracking-wide bold-font">{translate('user.manage-blend.blend-lab.blend-volume.label')}</SmallBody></div>
										<div className="flex flex-row justify-end items-center ml-4">
											<div className="flex">
												<FormInput 
													className={`${SPECIAL_FORM_INPUT_CLASS_NAME} rounded-r-none`}
													placeholder={translate('user.manage-blend.blend-lab.blend-volume.placeholder')}
													value={manageBlendStore.blendData.totalBlendVolume.value}
													error={manageBlendStore.blendData.totalBlendVolume.error}
													onChange={(e) => {
														manageBlendStore.markChange();
														manageBlendStore.changeBlendVolume(e.target.value)
													}}
													onBlur={(e) => {
														manageBlendStore.getBlendPreview();
													}}
												/>
											</div>
											<div className="flex">
												<FormSelect 
													className={`${SPECIAL_FORM_INPUT_CLASS_NAME} rounded-l-none`}
													placeholder={translate('user.manage-blend.measurements.choose-measurement')}
													options={manageBlendStore.measurementOptions}
													value={manageBlendStore.blendData.selectedBlendMeasurement.value}
													error={manageBlendStore.blendData.selectedBlendMeasurement.error}
													onChange={(option) => {
														manageBlendStore.markChange();
														manageBlendStore.changeBlendMeasurement(option.value)
													}}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="flex flex-row w-full mt-8">
									<SliderInput
										value={manageBlendStore.blendData.essentialOilBlendPercentage.value}
										onChange={(v) => {
											manageBlendStore.markChange();
											manageBlendStore.changeEssentialOilBlendPercentage(v)
										}}
										onAfterChange={() => manageBlendStore.getBlendPreview()}
										min={0}
										max={100}
										step={1}
										disabled={false}
										bgColor={colors['flat-green'].default}
										showLabels={true}
										startLabel={translate('user.manage-blend.blend-lab.essential-oil')}
										endLabel={translate('user.manage-blend.blend-lab.base')}
									 />
								</div>
							</>
						}
						{editBlendIngredient && 
							<div className="flex w-full bg-white flex-col relative h-auto border-list-text border px-4 py-2 rounded-lg mt-8">
								<div style={{
									position: 'absolute',
									bottom:0,
									left:0,
									marginBottom:'-18px',
									marginLeft: '25%',
									height:0, 
									width:0,
									zIndex: 50
								}}></div>

								<div className="flex w-full flex-row items-center">
									
									<div className="flex flex-row items-center">
										<div className="flex w-12 h-12 border-radius-50 cursor-pointer" onClick={() => manageBlendStore.goToIngredient(editBlendIngredient.id)} style={{backgroundColor: editBlendIngredient.hex_color}}></div>
										
										<div className="flex flex-col ml-2">
											<ExtraSmallBody>{editBlendIngredient.name}</ExtraSmallBody>
											<ExtraSmallBody className="italic">{editBlendIngredient.latin_name}</ExtraSmallBody>
										</div>
									</div>
									<div className="flex flex-1 justify-end">
										<div className="flex w-32 flex-row items-center ml-4">
											<FormInput 
												className={`${SPECIAL_FORM_INPUT_CLASS_NAME} w-full rounded-r-none`}
												placeholder={translate('user.manage-blend.blend-lab.volume.placeholder')}
												value={manageBlendStore.blendData.editBlendVolume.value}
												error={manageBlendStore.blendData.editBlendVolume.error}
												onChange={(e) => {
													manageBlendStore.markChange();
													manageBlendStore.blendData.editBlendVolume.onChange(e.target.value)
												}}
											/>
											<div className="flex w-auto">
												<FormSelect 
													className={`${SPECIAL_FORM_INPUT_CLASS_NAME} bg-pink-coral text-white rounded-l-none`}
													iconColor="white"
													placeholder={translate('user.manage-blend.measurements.choose-measurement')}
													options={manageBlendStore.measurementOptions}
													value={manageBlendStore.blendData.editBlendMeasurement.value}
													error={manageBlendStore.blendData.editBlendMeasurement.error}
													onChange={(option) => {
														manageBlendStore.markChange();
														manageBlendStore.blendData.editBlendMeasurement.onChange(option.value)
													}}
												/>
											</div>
										</div>
										<div className="flex flex-row justify-between items-center ml-4">
											<div className="flex justify-end">
												{!manageBlendStore.blendData.editingBlendIngredient &&
													<ApplyText 
														onClick={() => manageBlendStore.addToBlend()}
														text={translate('user.manage-blend.blend-lab.add-to-blend')} />
												}
												{manageBlendStore.blendData.editingBlendIngredient && manageBlendStore.canAddToBlend &&
													<ApplyText 
														onClick={() => manageBlendStore.updateBlendIngredient()}
														text={translate('user.manage-blend.blend-lab.update-blend')} />
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						<div className="flex flex-1 flex-col">
							<div className="flex flex-1 flex-row flex-wrap mt-8">
								<div className="flex flex-1 flex-shrink-0 flex-col justify-start overflow-x-scroll items-start" style={{minWidth: "400px"}}>
									<BlendJar 
										getIngredientHeight={(id) => manageBlendStore.getIngredientHeight(id)}
										currentBlendIngredients={manageBlendStore.blendJarIngredients} 
										totalBlendVolume={manageBlendStore.blendData.totalBlendVolume.value}
										onEditBlendIngredient={(uuid) => manageBlendStore.onEditBlendIngredient(uuid)}
										onRemoveBlendIngredient={(uuid) => manageBlendStore.removeBlendIngredientByUUID(uuid)}
									/>
								</div>
								{manageBlendStore.showBlendResults && 
									<div className="flex flex-1 flex-col ml-0 lg:ml-4 lg:pr-4 mt-4">
										<div className="flex flex-row items-center justify-between mt-4">
											<div className="flex"><SmallBody><span className="bold-font">{translate('user.manage-blend.blend-lab.total-blend-volume')}</span> {manageBlendStore.currentBlendResults?.total_amount}</SmallBody></div>
											<div className="flex ml-2">
												<Select 
													className="border-0 border-white"
													placeholder={null}
													options={manageBlendStore.displayMeasurementOptions}
													value={manageBlendStore.displayMeasurement}
													onChange={(option) => {
														manageBlendStore.changeDisplayBlendMeasurement(option.value)
													}}
												/>
											</div>
										</div>
										<div className="flex mt-4"><SmallBody className="bold-font">{translate('user.manage-blend.blend-lab.essential-oils')} ({manageBlendStore.currentBlendResults?.essential_oil_percentage}%)</SmallBody></div>
										{manageBlendStore.essentialOilBlends.map((entry, idx) =>
											<BlendEntry 
												entry={entry} 
												key={idx} 
												onEdit={(id) => {
													manageBlendStore.onEditBlendIngredientById(id)
												}} 
												entryAmountToShow={manageBlendStore.getBlendEntryAmountToShow(entry)}
											/>
										)}
										<div className="flex mt-4"><SmallBody className="bold-font">{translate('user.manage-blend.blend-lab.base-oils')} ({manageBlendStore.currentBlendResults?.base_oil_percentage}%)</SmallBody></div>
										{manageBlendStore.baseOilBlends.map((entry, idx) =>
											<BlendEntry 
												entry={entry} 
												key={idx} 
												onEdit={(id) => manageBlendStore.onEditBlendIngredientById(id)}
												entryAmountToShow={manageBlendStore.getBlendEntryAmountToShow(entry)}
												 />
										)}
									</div>
								}
								
							</div>
							{manageBlendStore.showBlendResults && 
								<div className="flex flex-row items-center w-full">
									<div className="flex flex-col lg:ml-4 mt-8">
										<div className="flex"><SmallBody className="bold-font">{translate('user.manage-blend.blend-lab.cost-estimate')} ({manageBlendStore.currentBlendResults?.total_amount})</SmallBody></div>
										<div className="flex mt-2"><SmallBody>{manageBlendStore.costEstimate}</SmallBody></div>
									</div>
									<div className="flex ml-2">
										<Select 
											className="border-0 border-white"
											placeholder={null}
											options={manageBlendStore.currencyOptions}
											value={manageBlendStore.currentCurrency}
											onChange={(option) => {
												manageBlendStore.changeCurrency(option.value)
											}}
										/>
									</div>
								</div>
							}
						</div>
					</GridSection>
				</div>
			</DndProvider>
		)
	}
}

const BlendJarIngredient = observer(({blendIngredient, idx, lastIdx, getIngredientHeight, onRemoveBlendIngredient=null, onEditBlendIngredient=null}) => {
	let heightInPixels = getIngredientHeight(blendIngredient.ingredient.id);
	let ingredientClassName = `w-full z-40 mb-2 mx-auto flex justify-center items-center text-center`;
	if(idx == lastIdx){
		ingredientClassName = `${ingredientClassName} rounded-b-lg`
	}

	const [{isDragging}, drag] = useDrag({
	    item: { 
	    	type: ItemTypes.BLEND_INGREDIENT,
	    	uuid: blendIngredient.uuid
	    },
	    collect: monitor => ({
	      isDragging: !!monitor.isDragging(),
	    }),
	    end(item, monitor){
	    	const dropResult = monitor.getDropResult()
      		if(item && dropResult){	
      			onRemoveBlendIngredient(item.uuid);
      		}
	    }
	})

	let blendIngredientStyles = {
		backgroundColor: blendIngredient.ingredient.hex_color, 
		width: "240px", 
		height: heightInPixels,
		marginLeft: "60px",
		transform: "translateZ(0)"
	}
	if(isDragging){
		blendIngredientStyles.opacity = 0.5;
	}

	return (
		<div 
			ref={drag}
			className={ingredientClassName}
			style={blendIngredientStyles}
			onClick={() => onEditBlendIngredient(blendIngredient.uuid)}
			>
			<ExtraSmallBody>{blendIngredient.ingredient.name}</ExtraSmallBody>
		</div>
	)
})

const BlendJar = observer(({currentBlendIngredients, totalBlendVolume, getIngredientHeight, onEditBlendIngredient=null, onRemoveBlendIngredient=null}) => {
	const [collectedProps, drop] = useDrop({
	    accept: ItemTypes.INGREDIENT,
	    drop: () => {},
	    canDrop: () => true,
	    collect: monitor => ({
	      isOver: !!monitor.isOver(),
      	  canDrop: !!monitor.canDrop()
	    })
	})

	let lastIdx = currentBlendIngredients.length > 0 ? currentBlendIngredients.length - 1: null;
	return (
		<div id="blend-jar" 
			ref={drop} 
			style={{
					backgroundImage: `url(${blendingTubIconSrc}`,
					backgroundSize: "100% 100%",
					width: "354px",
					height:"450px",
					maxHeight:"600px",
					display: "flex",
					justifyContent: "flex-end",
					flexDirection: "column",
					paddingBottom: "5px"
			}}
		>
			<div style={{
				width: "350px",
				maxWidth: "350px",
				paddingTop: "10px",
				overflow: "scroll",
				margin: "0 auto"
			}}>
				{currentBlendIngredients.map((blendIngredient, idx) => 
					<BlendJarIngredient 
						blendIngredient={blendIngredient}
						getIngredientHeight={getIngredientHeight} 
						idx={idx} 
						lastIdx={lastIdx}
						onEditBlendIngredient={onEditBlendIngredient}
						onRemoveBlendIngredient={onRemoveBlendIngredient}
					/>
				)}

			</div>
		</div>
	)
})

const IngredientBubble = observer(({ingredient, onFinishDrop=null, onClickIngredient=null, onFavouriteIngredient=null}) => {
	const [{isDragging}, drag] = useDrag({
	    item: { 
	    	type: ItemTypes.INGREDIENT,
	    	id: ingredient.id
	    },
	    collect: monitor => ({
	      isDragging: !!monitor.isDragging(),
	    }),
	    end(item, monitor){
	    	const dropResult = monitor.getDropResult();
      		if(item && dropResult){	
      			onFinishDrop(item.id)
      		}
	    }
	})

	let ingredientClassName = `flex flex-1 flex-col relative cursor-pointer overflow-scroll px-1 py-1 justify-center items-center w-24 h-24 border-radius-50`;
	let ingredientStyles = {
		backgroundColor: ingredient.hex_color,
		transform: "translateZ(0)"
	}
	if(isDragging){
		ingredientStyles.opacity = 0.5;
	}
	return (
		<>
			<div 
				ref={drag}
				className={ingredientClassName} 
				onClick={() => onClickIngredient(ingredient.id)}
				style={ingredientStyles}>
					<ExtraSmallBody className={`z-50 w-full py-1 px-2 text-center text-black bold-font break-words`}>{ingredient.name}</ExtraSmallBody>
					<ExtraSmallBody  className={`z-50 w-full px-2 text-center text-black italic`}>{ingredient.origin}</ExtraSmallBody>
					<div className="flex cursor-pointer" onClick={(e) => {
						e.stopPropagation();
						onFavouriteIngredient(ingredient.id)
					}}>
						<StarIcon fill={ingredient.favourited ? colors['black'] : 'none'} className='w-4 h-4' color={colors['black']}  />
					</div>
			</div>
		</>
	)
})

const BlendBubble = observer(({blend, t, onFinishDrop=null, onClickBlend=null}) => {
	const [{isDragging}, drag] = useDrag({
	    item: { 
	    	type: ItemTypes.INGREDIENT,
	    	id: blend.id
	    },
	    collect: monitor => ({
	      isDragging: !!monitor.isDragging(),
	    }),
	    end(item, monitor){
	    	const dropResult = monitor.getDropResult();
      		if(item && dropResult){	
      			onFinishDrop(item.id)
      		}
	    }
	})

	let blendClassName = `flex flex-1 flex-col relative cursor-pointer overflow-scroll px-1 py-1 justify-center items-center w-24 h-24 border-radius-50`;
	let blendStyles = {
		backgroundColor: '#becdc4',
		transform: "translateZ(0)"
	}
	if(isDragging){
		blendStyles.opacity = 0.5;
	}
	return (
		<>
			<div 
				ref={drag}
				className={blendClassName} 
				onClick={() => onClickBlend(blend.id)}
				style={blendStyles}>
					<ExtraSmallBody className={`z-50 w-full py-1 px-2 text-center text-black bold-font break-words`}>{blend.name}</ExtraSmallBody>
					<ExtraSmallBody  className={`z-50 w-full px-2 text-center text-black italic`}>{blend.public ? t('user.advanced-search.search-results.public-blend-library') : t('user.advanced-search.search-results.private-blend-library')}</ExtraSmallBody>
			</div>
		</>
	)
})

@observer
class NoteSection extends Component{
	constructor(props){
		super(props);
		this.toggleNoteSection = this.toggleNoteSection.bind(this);
		this.state = {
			showNoteSection: true
		}
	}

	toggleNoteSection(){
		this.setState({
			showNoteSection: !this.state.showNoteSection
		})
	}

	render(){
		let translate = this.props.t;
		let editMode = 'editMode' in this.props ? this.props.editMode : false;
		let blendNote = this.props.blendNote;
		return (
			<div className="flex flex-row justify-between mt-4">
				<div className={`flex w-1/4 flex-row justify-between ${editMode ? "items-start" : "items-center"}`}>
					{!editMode && <div className="lex flex-row items-center ml-4 -mt-2"><SmallBody className="bold-font">{blendNote.note_section}</SmallBody></div>}
					{editMode && 
						<div className="flex flex-row items-center -mt-2">
							<div className="flex cursor-pointer" onClick={() => this.props.onDelete(blendNote.uuid)}><DeleteIcon className="w-4 h-4" color={colors['error']} /></div>
							<div className="flex ml-4">
								<FormInput 
									placeholder={translate('user.manage-blend.blend-notes.note-section.placeholder')}
									value={blendNote.note_section.value}
									error={blendNote.note_section.error}
									onChange={(e) => blendNote.note_section.onChange(e.target.value)}
								/>
							</div>
						</div>
					}
					<div onClick={this.toggleNoteSection} className="flex h-full items-start mt-4 lg:ml-6 cursor-pointer">{this.state.showNoteSection ? <ArrowLeftIcon className="w-3 h-3" color="black" /> : <ArrowRightIcon className="w-3 h-3" color="black" />}</div>
				</div>
				{this.state.showNoteSection &&
					<div className="flex flex-1 lg:ml-8">
						{!editMode && 
							<div className="flex"><ExtraSmallBody>{blendNote.note}</ExtraSmallBody></div>
						}
						{editMode && 
							<FormTextArea 
								placeholder={translate('user.manage-blend.blend-notes.note.placeholder')}
								value={blendNote.note.value}
								error={blendNote.note.error}
								onChange={(e) => blendNote.note.onChange(e.target.value)}
							/>
						}
					</div>
				}
			</div>
		)
	}
}

@observer
class ScentNoteBreakdown extends Component{
	render(){
		let data = this.props.data;
		return (
			<div className="flex flex-col mt-4">
				{data.map((entry) => 
					<div className="flex w-full flex-col mb-4">
						<div className="flex flex-col border-b border-black py-1">
							<div className="flex flex-row w-full items-center">
								<div className="flex w-2 h-2 border-radius-50" style={{backgroundColor: entry.scent_note.hex_color}}></div>
								<div className="flex ml-2 lg:ml-6"><SmallBody className="bold-font">{entry.scent_note.name}</SmallBody></div>
							</div>
							
						</div>
						<div className="flex flex-col mt-2">
							{entry.ingredients.map((ingredient) => 
								<div className="flex"><SmallBody>{ingredient.name}</SmallBody></div>
							)}
						</div>
					</div>
				)}
			</div>
		)
	}
}

@observer
class IngredientEffectRow extends Component{
	constructor(props){
		super(props);
		this.toggleShowIngredients = this.toggleShowIngredients.bind(this);
		this.state = {
			showIngredients: false
		}
	}

	toggleShowIngredients(){
		this.setState({
			showIngredients: !this.state.showIngredients
		})
	}

	render(){
		let attribute = this.props.attribute;
		let idx = this.props.idx;
		let entry = this.props.entry;
		return (
			<div className="flex flex-col">
				<div className="flex w-full justify-between flex-row items-center border-black border-b py-1">
					<div className="flex"><Body className="bold-font">{idx}. {entry[attribute].name}</Body></div>
					<div className="flex cursor-pointer" onClick={this.toggleShowIngredients}>
						{this.state.showIngredients ? <ArrowUpIcon className="w-3 h-3" color={colors['list-text']} /> : <ArrowDownIcon className="w-3 h-3" color={colors['list-text']} />}
					</div>
				</div>

				{this.state.showIngredients && 
					<div className="flex w-full flex-col mt-1">
						{entry.ingredient_scores.map((ingredientScore) => 
							<div className="flex">
								<SmallBody>{ingredientScore.ingredient.name} ({ingredientScore.score})</SmallBody>
							</div>
						)}
					</div>
				}
			</div>
		)
	}
}

@observer
class IngredientEffectBreakdown extends Component{
	render(){
		let attribute = this.props.attribute;
		return (
			<div className="flex flex-col mt-4">
				{this.props.data.map((entry, idx) => 
					<IngredientEffectRow 
						attribute={attribute}
						entry={entry} 
						idx={idx+1} />
				)}
			</div>
		)
	}
}

@observer
class ScoreUnit extends Component{
	render(){
		let bgColor = this.props.bgColor;
		return (
			<div className={`flex w-4 h-4 rounded-md bg-${bgColor} ml-2`}></div>
		)
	}	
}

@observer
export class RankingScoreGrid extends Component{
	render(){
		let color = this.props.color;

		let smallText = 'smallBody' in this.props;
		
		return (
			<div className="flex flex-col items-center justify-center">
				<div className="grid grid-cols-2 gap-4">
					{this.props.data.map((rankingScore) =>
						<>
							<div className="flex">{smallText ? 
									<SmallBody>{rankingScore.ranking.name}</SmallBody> : 
									<ExtraSmallBody>{rankingScore.ranking.name}</ExtraSmallBody>
							}</div>
							<div className="flex flex-row ">
								<ScoreUnit bgColor={rankingScore.score > 0 ? color : "black-10"} />
								<ScoreUnit bgColor={rankingScore.score > 3 ? color : "black-10"} />
								<ScoreUnit bgColor={rankingScore.score > 6 ? color : "black-10"} />
							</div>
						</>
					)}
				</div>
			</div>
		)
	}
}

@observer
class UpgradeComplianceScreen extends Component{
	render(){
		let translate = this.props.t;
		return (
			<div className="flex flex-1 flex-col items-center justify-center text-center overflow-y-scroll">
				<div className="flex">
					<UpgradeIcon className="w-full h-32" />
				</div>
				<div className="flex mt-4">
					<Body>{translate('user.manage-blend.pro-required-compliance.part1')}</Body>
				</div>
				<div className="flex mt-4">
					<Body>{translate('user.manage-blend.pro-required-compliance.part2')} <span className="bold-font">{translate('user.manage-blend.pro-required-compliance.part3')}</span> <br /> {translate('user.manage-blend.pro-required-compliance.part4')}</Body>
				</div>
				<div className="flex mt-4">
					<Button
						width="w-auto"
						height="h-12"
						className="ml-4 px-4 rounded-lg text-white"
						bgColor="nav-active-color"
						onClick={this.props.onClick}
					>
						<div className="flex flex-row items-center justify-center">
							<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-blend.pro-required-compliance.upgrade-button')} &nbsp;</TertiaryAppSmallTitle></div>
						</div>
					</Button>
				</div>
			</div>
		)
	}
}

@observer
class ChemicalComponentEntry extends Component{
	constructor(props){
		super(props);
		this.toggleMouseEnter = this.toggleMouseEnter.bind(this);
		this.toggleMouseLeave = this.toggleMouseLeave.bind(this);
		this.state = {
			hovering: false
		}
	}

	toggleMouseEnter(){
		this.setState({
			hovering: true
		})
	}

	toggleMouseLeave(){
		this.setState({
			hovering: false
		})
	}

	render(){
		let entry = this.props.entry;
		let textColorClassName = entry.compliant ? 'text-black' : 'text-error';
		let translate = this.props.t;
		let bio = null;
		if('chemical_component' in entry){
			if(entry.chemical_component.bio != null && entry.chemical_component.bio.length > 0){
				bio = entry.chemical_component.bio;
			}
		}

		if('acid_component' in entry){
			if(entry.acid_component.bio != null && entry.acid_component.bio.length > 0){
				bio = entry.acid_component.bio;
			}
		}

		let name = 'chemical_component' in entry ? entry.chemical_component.name : entry.acid_component.name;
		return (
			<div className="relative flex w-full flex-row justify-between border-b border-black py-1" onMouseOver={this.toggleMouseEnter} onMouseOut={this.toggleMouseLeave}>
				<div className="flex flex-col">
					<SmallBody className={textColorClassName}>{name}</SmallBody>
				</div>
				<div className="flex justify-end flex-col">
					<SmallBody className={textColorClassName}>{`${entry.percentage}%`}</SmallBody>
				</div>
				{this.state.hovering && !entry.compliant &&
					<div className="absolute flex flex-col z-50 top-0 left-0 mt-8 ml-16 mx-auto border border-black-10 px-4 py-4 bg-compliance-bg w-64 h-auto shadow-lg rounded-lg">
						<div className="flex"><SmallBody className="bold-font text-error">{translate('user.manage-blend.compliance.compliance-warning')}</SmallBody></div>
						<div className="flex mt-2"><SmallBody className="text-error">{entry.compliance_regulation?.regulation_information}</SmallBody></div>
					</div>
				}
				{this.state.hovering && entry.compliant && bio != null &&
					<div className="absolute flex flex-col z-50 top-0 left-0 mt-4 ml-16 mx-auto border border-black-10 px-2 py-2 bg-hover-bg w-48 h-auto shadow-lg rounded-lg">
						<div className="flex"><SmallBody className="bold-font text-black">{name}</SmallBody></div>
						<div className="flex"><SmallBody className="text-black">{bio}</SmallBody></div>
					</div>
				}

			</div>
		)
	}
}

@observer
class ChemicalComponentHeader extends Component{
	render(){
		return (
			<div className="flex flex-col border-b border-black">
				<SmallBody className="bold-font">{this.props.header}</SmallBody>
			</div>
		)
	}
}

const EffectRowPDF = ({effect, attribute, color, idx, rankingScore=null}) => (
	<View style={pdfStyles.column}>
			<View style={[pdfStyles.rowBetween, pdfStyles.borderBottom]}>
				<Text style={pdfStyles.datapoint}>{idx + 1}. {effect[attribute].name}</Text>
				
				{rankingScore != null && 
					<View style={{flexDirection: 'row'}}>
	  					{rankingScore.score > 0 && <View style={[pdfStyles.box, { backgroundColor: color}]}></View>}
	  					{rankingScore.score > 3 && <View style={[pdfStyles.box, { marginLeft: 5, backgroundColor: color}]}></View>}
	  					{rankingScore.score > 6 && <View style={[pdfStyles.box, { marginLeft:5, backgroundColor: color}]}></View>}
	  				</View>
	  			}
			</View>
			{effect.ingredient_scores.map((ingredientScore) => 
	  			<View style={pdfStyles.column}>
	      			<View style={pdfStyles.row}>
	      				<Text style={pdfStyles.smalldatapoint}>{ingredientScore.ingredient.name} ({ingredientScore.score})</Text>
	      			</View>
	      		</View>
	  		)}
		</View>
)

const BlendPDF = ({logoSrc, nameTitle, name, 
					categoryTitle, category, tagsTitle, 
					tags, blendVolumeTitle, blendVolume, 
					costTitle, cost, chemicalFamilyBreakdownTitle,
					essentialOilPercentageTitle, essentialOilPercentage,
					baseOilPercentageTitle, baseOilPercentage,
					essentialOils, baseOils,
					chemicalFamilyBreakdown,
					chemicalComponentBreakdownByFamily,
					chemicalComponentBreakdownTitle,
					complianceWarnings=null, 
					complianceWarningsTitle=null, 
					canViewCompliance=false, 
					physicalEffectsTitle,
					psychologicalEffectsTitle,
					getBlendEntryAmountToShow,
					noComplianceWarnings=null, 
					complianceRegion=null,
					scentNotesTitle=null,
					scentNoteBreakdown=null,
					blendNotesTitle,
					blendNotes=null,
					physicalEffectsBreakdown=null,
					psychologicalEffectsBreakdown=null,
					physicalRankingScores=null,
					psychologicalRankingScores=null,
					chartSrc=null, blendJarSrc=null, isHomeUser=false,
					showBlendLab=true,
					showChemicalFamilyBreakdown=true, showChemicalBreakdown=true,
					showEffects=true, 
					showNotes=true,
					showPricing=true}) => {
	let footer = (
		<View style={pdfStyles.rowBetweenNoMargin} wrap={false}>
  			<Image src={logoSrc} style={pdfStyles.logo} />
  			<Text style={[pdfStyles.title, pdfStyles.italic]}>www.labaroma.com</Text>
  		</View>
  	);						
	return (
		<Document creator="LabAroma" producer="LabAroma">
		    <Page orientation="portait" size="A4" style={pdfStyles.page} wrap={false}>
		      	<View style={pdfStyles.container}>
		      		<View style={pdfStyles.row}>
		      			<Image src={logoSrc} style={pdfStyles.logo} />
		      		</View>
		      		<View style={pdfStyles.row}>
			      		<View style={pdfStyles.gridColumn}>
			      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{nameTitle}</Text>
			      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{name}</Text></View>
			      		</View>
			      		<View style={pdfStyles.gridColumn}>
			      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{categoryTitle}</Text>
			      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{category}</Text></View>
			      		</View>
			      		<View style={pdfStyles.gridColumn}>
			      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{tagsTitle}</Text>
			      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{tags}</Text></View>
			      		</View>
			      	</View>
			      	<View style={pdfStyles.row}>
			      		<View style={pdfStyles.gridColumn}>
			      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{blendVolumeTitle}</Text>
			      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{blendVolume}</Text></View>
			      		</View>
			      		{showPricing &&
			      			<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{costTitle}</Text>
				      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{cost}</Text></View>
				      		</View>
				      	}
			      		<View style={pdfStyles.gridColumn}>
			      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{essentialOilPercentageTitle}</Text>
			      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{essentialOilPercentage}%</Text></View>
			      		</View>
			      	</View>
			      	{!isHomeUser && showChemicalFamilyBreakdown &&
			      		<View style={pdfStyles.row}>
				      		{chartSrc != null && <Image src={chartSrc} style={pdfStyles.pieChartImage} />}
				      		<View style={pdfStyles.list}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{chemicalFamilyBreakdownTitle}</Text>
				      			{chemicalFamilyBreakdown.map((familyBreakdown) =>
				      				<View style={pdfStyles.listItem}>
					      				<Text style={pdfStyles.smalldatapoint}>{familyBreakdown.x}</Text>
					      				<Text style={pdfStyles.smalldatapoint}>{familyBreakdown.yLabel}%</Text>
					      			</View>
				      			)}
				      		</View>
				      	</View>
				    }
			      	{footer}
		      	</View>
		    </Page>
		    <Page orientation="portait" size="A4" style={pdfStyles.page}>
		    	{showBlendLab && 
			      	<View style={pdfStyles.container}>
			      		<View style={pdfStyles.fullRow}>
			      			<Image src={blendJarSrc} style={{flex: 0.9}} />
			      			<View style={{ flexDirection: 'column', flex: 1, marginLeft: 20}}>
				      			<View style={pdfStyles.list}>
				      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{essentialOilPercentageTitle} ({essentialOilPercentage}%)</Text>
				      				{essentialOils.map((essentialOil) =>
					      				<View style={pdfStyles.listItem}>
						      				<View style={[pdfStyles.circle, { backgroundColor: essentialOil.ingredient.hex_color}]}></View>
						      				<View style={[pdfStyles.gridColumn, pdfStyles.marginLeft]}>
						      					<Text style={pdfStyles.smalldatapoint}>{essentialOil.ingredient.name}</Text>
						      					<Text style={pdfStyles.smalldatapoint}>{essentialOil.amount}</Text>
						      				</View>
						      				<Text style={pdfStyles.smalldatapoint}>{getBlendEntryAmountToShow(essentialOil)}</Text>
						      			</View>
					      			)}
				      			</View>
				      			<View style={[pdfStyles.list, pdfStyles.smallMarginTop]}>
				      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{baseOilPercentageTitle} ({baseOilPercentage}%)</Text>
				      				{baseOils.map((baseOil) =>
					      				<View style={pdfStyles.listItem}>
						      				<View style={[pdfStyles.circle, { backgroundColor: baseOil.ingredient.hex_color}]}></View>
						      				<View style={[pdfStyles.gridColumn, pdfStyles.marginLeft]}>
						      					<Text style={pdfStyles.smalldatapoint}>{baseOil.ingredient.name}</Text>
						      					<Text style={pdfStyles.smalldatapoint}>{baseOil.amount}</Text>
						      				</View>
						      				<Text style={pdfStyles.smalldatapoint}>{getBlendEntryAmountToShow(baseOil)}</Text>
						      			</View>
					      			)}
				      			</View>
				      		</View>
			      		</View>
			      	</View>
				}
			    {!isHomeUser && showChemicalBreakdown &&
			      	<View style={pdfStyles.container}>
			      		<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{chemicalComponentBreakdownTitle}</Text>
						
						<View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1, flexWrap: 'wrap'}}>
							{chemicalComponentBreakdownByFamily.sort(sortByPercentage).map((entry, idx) => 
								<View style={{flexDirection: 'column', width: "40%", marginLeft: idx % 2 > 0 ? "5%" : 0 }}>
									<View style={pdfStyles.tableListItem}>
			      						<Text style={pdfStyles.tableTitle}>{entry[0]}</Text>
			      					</View>
			      					{entry[1].map((component) => 
			      						<View style={pdfStyles.tableListItem}>
			      							<Text style={pdfStyles.smalldatapoint}>{'chemical_component' in component ? component.chemical_component.name : component.acid_component.name}</Text>
			      							<Text style={pdfStyles.smalldatapoint}>{component.percentage}%</Text>
			      						</View>
			      					)}
			      				</View>
							)}
						</View>
						{footer}
			      	</View>
			    }
			</Page>
		    {canViewCompliance && 
		    	<Page orientation="portait" size="A4" style={pdfStyles.page}>
			      	<View style={pdfStyles.container}>
			      		<View style={pdfStyles.list}>
			      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{complianceWarningsTitle} | {complianceRegion} </Text>
				      		
				      		{complianceWarnings.length == 0 &&
				      			<View style={pdfStyles.row}> 
				      				<Text style={pdfStyles.datapoint}>{noComplianceWarnings}</Text>
				      			</View>
				      		}

				      		{complianceWarnings.map((complianceWarning) => 
				      			<View style={[pdfStyles.column, pdfStyles.smallMarginTop]}>
				      				<View style={pdfStyles.rowBetween}>
				      					<Text style={[pdfStyles.datapoint, {color: colors['error']}]}>{complianceWarning.chemical_component.name}</Text>
				      					<Text style={pdfStyles.datapoint}>{complianceWarning.percentage}%</Text>
				      				</View>
				      				<View style={pdfStyles.smallMarginTop}>
				      					<Text style={pdfStyles.datapoint}>{complianceWarning.regulation.regulation_information}</Text>
				      				</View>
				      			</View>
				      		)}
				      	</View>
				      	{footer}
			      	</View>
			    </Page>
			}
			{(showEffects || showNotes) && 
				<Page orientation="portait" size="A4" style={pdfStyles.page}>
					{showEffects && 
					     <View style={pdfStyles.container}>
					     	<View style={pdfStyles.rowBetween}>
					     		<View style={pdfStyles.gridColumn}>
							     	<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{scentNotesTitle}</Text>
							     	<View style={pdfStyles.column}>
							      		{scentNoteBreakdown.map((entry, idx) => 
							      			<View style={pdfStyles.column}>
								      			<View style={[pdfStyles.row, pdfStyles.borderBottom]}>
								      				<View style={[pdfStyles.circle, {backgroundColor: entry.scent_note.hex_color}]}></View>
								      				<Text style={[pdfStyles.datapoint, pdfStyles.marginLeft]}>{entry.scent_note.name}</Text>
								      			</View>
								      			{entry.ingredients.map((ingredient) => 
								      				<View style={pdfStyles.listItem}>
														<Text style={pdfStyles.smalldatapoint}>{ingredient.name}</Text>
													</View>
												)}
								      		</View>
							      		)}
							      	</View>
							    </View>
							    <View style={[pdfStyles.gridColumn, pdfStyles.marginLeft]}>
							     	<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{physicalEffectsTitle}</Text>
							     	<View style={pdfStyles.column}>
							      		{physicalEffectsBreakdown.map((physicalEffect, idx) => 
							      			<EffectRowPDF 
							      				effect={physicalEffect} 
							      				attribute="physical_ranking" 
							      				color={colors["pink-coral"].default}
							      				rankingScore={physicalRankingScores.find((r) => r.ranking.id == physicalEffect.physical_ranking.id)}
							      				idx={idx}

							      			/>
							      		)}
							      	</View>
							    </View>
							    <View style={[pdfStyles.gridColumn, pdfStyles.marginLeft]}>
							     	<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{psychologicalEffectsTitle}</Text>
							     	<View style={pdfStyles.column}>
							      		{psychologicalEffectsBreakdown.map((psychologicalEffect, idx) => 
							      			<EffectRowPDF 
							      				effect={psychologicalEffect} 
							      				attribute="psychological_ranking" 
							      				idx={idx} 
							      				rankingScore={psychologicalRankingScores.find((r) => r.ranking.id == psychologicalEffect.psychological_ranking.id)}
							      				color={colors["success-green"]}
							      			/>
							      		)}
							      	</View>
							    </View>
							</View>
						</View>
					}
					{showNotes && 
					     <View style={pdfStyles.container}>
			     			<View style={pdfStyles.column}>
					     	<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{blendNotesTitle}</Text>
					     	<View style={pdfStyles.column}>
					      		{blendNotes.map((blendNote) => 
					      			<View style={pdfStyles.column}>
						      			<View style={[pdfStyles.row, pdfStyles.borderBottom]}>
						      				<Text style={pdfStyles.datapoint}>{blendNote.note_section}</Text>
						      			</View>
					      				<View style={pdfStyles.listItem}>
											<Text style={pdfStyles.datapoint}>{blendNote.note}</Text>
										</View>
						      		</View>
					      		)}
					      	</View>
					    	</View>
						   	{footer}
						</View>
					}
				</Page>
			}
		</Document>
	)
}

@inject('manageBlendStore')
@observer
class ManageBlend extends Component{
	constructor(props){
		super(props);
		this.chemicalFamilyPieChartRef = React.createRef();
		this.renderPieChartToImage = this.renderPieChartToImage.bind(this);
		this.renderBlendJarToImage = this.renderBlendJarToImage.bind(this);
		this.getRenderedBlendPDF = this.getRenderedBlendPDF.bind(this);
		this.handleModalAction = this.handleModalAction.bind(this);
		this.saveBlend = this.saveBlend.bind(this);
		this.saveBlendAsDraft = this.saveBlendAsDraft.bind(this);
		this.triggerPDFAction = this.triggerPDFAction.bind(this);
		this.intervalId = null;
	}

	componentDidMount(){
		let manageBlendStore = this.props.manageBlendStore;
		
		manageBlendStore.fetchBlendCategories();
		manageBlendStore.fetchIngredients();
		manageBlendStore.fetchIngredientTypes();
		manageBlendStore.setDefaultBlendMeasurement();
		manageBlendStore.fetchPsychologicalRankings();
		manageBlendStore.fetchPhysicalRankings();
		manageBlendStore.fetchRegions();
		manageBlendStore.fetchCurrencies();
		manageBlendStore.setCurrentUserBlendRegion();
		manageBlendStore.fetchChemicalFamilies();
		manageBlendStore.fetchBlendLibraryCategories();
		manageBlendStore.setupPDFOptions();
		manageBlendStore.setDefaultCurrency();

		if('blendId' in this.props){
			manageBlendStore.formSync(this.props.blendId, this.props.editMode, this.props.ingredients);
		}else{
			if('ingredients' in this.props && this.props.ingredients != null){
				manageBlendStore.syncWithIngredients(this.props.ingredients);
			}
		}

		this.intervalId = setInterval(() => {
			this.renderPieChartToImage();
			this.renderBlendJarToImage();
		}, 5000);
	}

	componentWillUnmount(){
		let manageBlendStore = this.props.manageBlendStore;
		manageBlendStore.initStore();

		clearInterval(this.intervalId);
	}

	renderPieChartToImage(){
		if(this.chemicalFamilyPieChartRef != null && this.chemicalFamilyPieChartRef.chartRef != null && this.chemicalFamilyPieChartRef.chartRef.children != null){
			let manageBlendStore = this.props.manageBlendStore;
			let svgHtml = this.chemicalFamilyPieChartRef.chartRef.children[0];
			htmlToImage.toPng(svgHtml)
		  		.then((dataUrl) => {
		  			manageBlendStore.setRenderedPieChartUrl(dataUrl);
		  		})
		  		.catch((error) => {
		  			console.log('render to pie chart error');
		  		})
		}
	}

	renderBlendJarToImage(){
		var blendJarNode = document.getElementById('blend-jar');
		if(blendJarNode){
			htmlToImage.toPng(blendJarNode)
			  		.then((dataUrl) => {
			  			let manageBlendStore = this.props.manageBlendStore;
			  			manageBlendStore.setRenderedBlendJarUrl(dataUrl);
			  		})
			  		.catch((error) => {
			  			console.log('render to blend jar error');
			  		})
		}
	}

	saveBlend(){
		let manageBlendStore = this.props.manageBlendStore;
		manageBlendStore.saveBlend();
	}

	saveBlendAsDraft(){
		let manageBlendStore = this.props.manageBlendStore;
		manageBlendStore.saveBlend(true);
	}

	getRenderedBlendPDF(){
		let manageBlendStore = this.props.manageBlendStore;
		let translate = this.props.t;
		return (
				<BlendPDF 
					logoSrc={logoPinkSrc}
					isHomeUser={manageBlendStore.isHomeUser}
					nameTitle={translate('user.manage-blend.blend-details.name.label')}
					name={manageBlendStore.currentBlend?.name}
					categoryTitle={translate('user.manage-blend.blend-details.category.label')}
					category={manageBlendStore.currentBlend?.blend_category?.name}
					tagsTitle={translate('user.manage-blend.blend-details.tags.label')}
					tags={manageBlendStore.currentTags.map((tag) => tag.label).join(',')}
					blendVolumeTitle={translate('user.manage-blend.blend-lab.blend-volume.label')}
					blendVolume={`${manageBlendStore.currentBlend?.final_blend_volume}${manageBlendStore.currentBlend?.final_blend_volume_measurement}`}
					costTitle={`${translate('user.manage-blend.blend-lab.cost-estimate')} (${manageBlendStore.currentBlendResults?.total_amount})`}
					cost={manageBlendStore.costEstimate}
					chartSrc={manageBlendStore.renderedPieChartBase64Url}
					blendJarSrc={manageBlendStore.renderedBlendJarBase64Url}
					chemicalFamilyBreakdownTitle={translate('user.manage-blend.chemical-breakdown.title')}
					essentialOilPercentageTitle={translate('user.manage-blend.blend-lab.essential-oils')}
					essentialOilPercentage={manageBlendStore.currentBlendResults?.essential_oil_percentage}
					baseOilPercentageTitle={translate('user.manage-blend.blend-lab.base-oils')}
					baseOilPercentage={manageBlendStore.currentBlendResults?.base_oil_percentage}
					essentialOils={manageBlendStore.essentialOilBlends}
					getBlendEntryAmountToShow={(entry) => manageBlendStore.getBlendEntryAmountToShow(entry)}
					baseOils={manageBlendStore.baseOilBlends}
					chemicalFamilyBreakdown={manageBlendStore.chemicalFamilyBreakdownData}
					chemicalComponentBreakdownByFamily={manageBlendStore.chemicalComponentBreakdownByFamily}
					complianceWarnings={manageBlendStore.complianceWarnings}
					complianceWarningsTitle={translate('user.manage-blend.compliance.title')}
					noComplianceWarnings={translate('user.manage-blend.compliance.no-compliance-warnings')}
					canViewCompliance={manageBlendStore.canViewCompliance && manageBlendStore.showCompliance}
					chemicalComponentBreakdownTitle={translate('user.manage-blend.chemical-breakdown.title')}
					complianceRegion={manageBlendStore.currentComplianceRegionName}
					scentNotesTitle={translate('user.manage-blend.scent-notes.title')}
					scentNoteBreakdown={manageBlendStore.scentNoteBreakdown}
					physicalEffectsTitle={translate('user.manage-blend.blend-results.physical-effects')}
					physicalEffectsBreakdown={manageBlendStore.physicalEffectsBreakdown}
					psychologicalEffectsTitle={translate('user.manage-blend.blend-results.psychological-effects')}
					psychologicalEffectsBreakdown={manageBlendStore.psychologicalEffectsBreakdown}
					blendNotesTitle={translate('user.manage-blend.blend-notes.title')}
					blendNotes={manageBlendStore.currentBlend?.notes}
					physicalRankingScores={manageBlendStore.allPhysicalRankingScores}
					psychologicalRankingScores={manageBlendStore.allPsychologicalRankingScores}
					showBlendLab={manageBlendStore.showBlendLab}
					showChemicalBreakdown={manageBlendStore.showChemicalBreakdown}
					showChemicalFamilyBreakdown={manageBlendStore.showChemicalFamilyBreakdown}
					showNotes={manageBlendStore.showNotes}
					showPricing={manageBlendStore.showPricing}
					showEffects={manageBlendStore.showEffects}
				/>
			)
	}

	handleModalAction(action){
		let manageBlendStore = this.props.manageBlendStore;
		manageBlendStore.showPDFDownload(action);
	}

	triggerPDFAction = async () => {
		let manageBlendStore = this.props.manageBlendStore;
		if(!manageBlendStore.canDownloadPDF) return;
		manageBlendStore.togglePDFGenerating();
		let renderedBlendPDF = this.getRenderedBlendPDF();
		const myBlendPDF = pdf({});
		myBlendPDF.updateContainer(this.getRenderedBlendPDF());
		const blob = await myBlendPDF.toBlob();
		
		let blobUrl = URL.createObjectURL(blob);
		if(manageBlendStore.currentAction == 'download'){
			const link = document.createElement('a');
	      	link.href = blobUrl;
			link.setAttribute(
				'download',
				manageBlendStore.blendPDFName
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);

			// show saved dialo
			manageBlendStore.showDownloadSaveDialog();
		}else{
			 printJS(blobUrl);
		}
		manageBlendStore.resetPDFModal();
	}

	render(){
		let translate = this.props.t;
		let manageBlendStore = this.props.manageBlendStore;
		let isHomeUser = manageBlendStore.isHomeUser;
		let title = translate('user.manage-blend.new-tagline');
		let editMode = manageBlendStore.editMode;
		let manageMode = editMode || manageBlendStore.addMode;
		let viewMode = manageBlendStore.viewMode;
		if(viewMode){
			title = translate('user.manage-blend.view-tagline')
		}
		if(editMode){
			title = translate('user.manage-blend.edit-tagline')
		}
		let currentChemicalFamily = manageBlendStore.currentChemicalFamily;
		let renderedBlendPDF = null;
		if(manageBlendStore.canDownloadPDF){
			renderedBlendPDF = this.getRenderedBlendPDF();
		}
		let saveDraftBlendBtn = (
			<Button
				width="w-auto"
				height="h-12"
				className="px-4 rounded-lg"
				bgColor="pink-coral"
				fetching={manageBlendStore.fetching}
				disabled={!manageBlendStore.canSaveBlend}
				onClick={this.saveBlendAsDraft}
			>
				<div className="flex flex-row items-center justify-center">
					<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-blend.save-draft-blend')} &nbsp;</TertiaryAppSmallTitle></div>
					<div className="flex"><ArrowUpIcon className="w-4 h-4" color="white" /></div>
				</div>
			</Button>
		)
		let saveBlendBtn = (
			<Button
				width="w-auto"
				height="h-12"
				className="ml-4 px-4 rounded-lg"
				bgColor="pink-coral"
				fetching={manageBlendStore.fetching}
				disabled={!manageBlendStore.canSaveBlend}
				onClick={this.saveBlend}
			>
				<div className="flex flex-row items-center justify-center">
					<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-blend.save-blend')} &nbsp;</TertiaryAppSmallTitle></div>
					<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
				</div>
			</Button>
		)
		return (
			<Main>
				<BaseModal
		            isOpen={manageBlendStore.showChemicalFamilyModal}
		            onClose={() => manageBlendStore.hideFamilyModal()}
		            label="Chemical Family Overview"
		        >
		        	{currentChemicalFamily != null && 
		        		<ChemicalFamilyDialog 
			        		chemicalFamily={currentChemicalFamily}
			        		currentChemicalFamilyComponents={manageBlendStore.currentChemicalFamilyComponents}
			        	/>
			        }
		        </BaseModal>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{title}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.manage-blend.new-paragraph.part1')} <br /> {translate('user.manage-blend.new-paragraph.part2')}</SmallBody>

							<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={() => window.open('http://help.labaroma.com/en/articles/4732949-1-blending-tutorial', '_blank')}>
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.manage-blend.blend-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex hidden lg:block ml-4">
							<CreateNewBlend className="w-48 h-48" />
						</div>
					</div>
					{manageMode &&
						<div className="flex w-full justify-end mt-4">
							<div className="flex flex-row items-center">
								{editMode && 
									<Button
										width="w-auto"
										height="h-12"
										className="ml-4 px-4 rounded-lg text-pink-coral"
										bgColor="bg-list-text"
										onClick={() => manageBlendStore.toggleViewMode()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-blend.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
										</div>
									</Button>
								}
								{saveDraftBlendBtn}
								{saveBlendBtn}
							</div>
						</div>
					}
					{viewMode && 
						<div className="flex w-full justify-end mt-4">
							<div className="flex flex-row items-center">
								{manageBlendStore.canEditCurrentBlend && 
									<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="pink-coral"
										fetching={manageBlendStore.fetching}
										onClick={() => manageBlendStore.toggleEditMode()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-blend.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
										</div>
									</Button>
								}
								<Button
									width="w-auto"
									height="h-12"
									className="ml-4 px-4 rounded-lg"
									bgColor="pink-coral"
									fetching={manageBlendStore.fetching}
									onClick={() => manageBlendStore.duplicateBlend()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-blend.duplicate-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><DuplicateIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</div>

						</div>
					}
					<div className="grid grid-cols-6 gap-8 mt-8">
						<div className="col-span-6">
							<GridSection>
								<div className="flex flex-col">
									<div className="flex w-full flex-row justify-between items-center">
										<div className="flex flex-row items-center">
											<SecondaryWebSmallTitle>{translate('user.manage-blend.blend-details.title')}</SecondaryWebSmallTitle>
											<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-blend-details')} />

											{editMode || viewMode && manageBlendStore.currentBlend != null &&
												<div className="flex ml-4">
													<Status 
														bgColor={manageBlendStore.currentBlend.draft ? "black-10": "success-green"}
														text={manageBlendStore.currentBlend.draft ? translate('user.my-blend-library.draft-status') : translate('user.my-blend-library.live-status')} /></div>
											}

										</div>
										{viewMode && 
											<div className="flex mr-4 cursor-pointer">
												<BaseModal
													autoHeight
										            isOpen={manageBlendStore.showPDFDownloadModal}
										            onClose={() => manageBlendStore.toggleShowPDFDownload()}
										            label="PDF Download Modal"
										        >
										        	<div className="flex w-full h-auto py-16 px-32 flex-col overflow-scroll bg-white rounded-lg">
										        		<div className="flex w-full justify-center">
										        			<SecondaryAppMediumTitle className="text-nav-active-color">{translate('user.manage-blend.pdf-modal-details.title')}</SecondaryAppMediumTitle>
										        		</div>
										        		{manageBlendStore.currentPDFDownloadOptions.map((downloadOption) =>
										        			<div className="flex flex-row justify-between items-center mt-2">
										        				
										        				<div className="flex">
										        					<TertiaryAppSmallTitle>{downloadOption.label}</TertiaryAppSmallTitle>
										        				</div>
										        				<CheckBox 
																	extraInputClassNames="h-5 w-5 border border-black text-pink-coral border-radius-50"
																	checked={downloadOption.checked}
																	onChange={(e) => manageBlendStore.onChangeDownloadOption(downloadOption.id, e.target.checked)}
																	 />
										        			</div>
										        		)}

										        		{manageBlendStore.currentPDFDownloadOptions.some((p) => p.checked) && 
										        			<div className="flex justify-center flex-row items-center mt-8">
																<Button
																	width="w-full"
																	height="h-12"
																	className="px-4 rounded-lg"
																	bgColor="nav-active-color"
																	disabled={manageBlendStore.pdfGenerating}
																	onClick={this.triggerPDFAction}
																>
																	<div className="flex flex-row items-center justify-center">
																		<div className="flex"><TertiaryAppSmallTitle>{manageBlendStore.pdfGenerating ? translate('common.loading') : translate('user.manage-blend.save-pdf-settings')} &nbsp;</TertiaryAppSmallTitle></div>
																	</div>
																</Button>
															</div>
														}
										        	</div>
										        </BaseModal>

												<div className="flex flex-row items-center">
													<DownloadPDF 
														t={translate}
														showModal={true}
														isJapanese={manageBlendStore.appStore.isJapaneseUser}
														onShowModal={this.handleModalAction}
														document={manageBlendStore.canDownloadPDF ? renderedBlendPDF : null}
														filename={manageBlendStore.blendPDFName}
														onDownload={() => console.log()}
														onPrint={() => {}}
													/>
												</div>	
											</div>
										}
									</div>
									<div className="grid grid-cols-1 xl:grid-cols-4 gap-4 flex items-start">
										<DataRow label={translate('user.manage-blend.blend-details.name.label')}>
											{manageMode && 
												<FormInput 
													placeholder={translate('user.manage-blend.blend-details.name.placeholder')}
													value={manageBlendStore.blendData.name.value}
													error={manageBlendStore.blendData.name.error}
													onChange={(e) => {
														manageBlendStore.markChange();
														manageBlendStore.blendData.name.onChange(e.target.value)
													}}
												/>
											}
											{viewMode && 
												<DataEntry>{manageBlendStore.currentBlend?.name}</DataEntry>
											}
										</DataRow>
										<DataRow label={translate('user.manage-blend.blend-details.category.label')} tooltip={<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-blend-category')} />}>
											{manageMode &&
												<FormSelect 
													placeholder={translate('user.manage-blend.blend-details.category.placeholder')}
													options={manageBlendStore.blendCategoryOptions}
													value={manageBlendStore.blendData.selectedCategory.value}
													error={manageBlendStore.blendData.selectedCategory.error}
													onChange={(option) => {
														manageBlendStore.markChange();
														manageBlendStore.blendData.selectedCategory.onChange(option.value)
													}}
												/>
											}
											{viewMode && 
												<DataEntry>{manageBlendStore.currentBlend?.blend_category?.name}</DataEntry>
											}
										</DataRow>
										{(manageBlendStore.appStore.isSuperAdministrator || manageBlendStore.appStore.isAdministrator) && manageMode &&
											<DataRow label={translate('user.manage-blend.blend-details.blend-library.label')}>
												<div className="flex w-full flex-col">
													<div className="flex w-full flex-row items-center">
														<FormSelect 
															placeholder={translate('user.manage-blend.blend-details.blend-library.placeholder')}
															options={manageBlendStore.blendLibraryCategoryOptions}
															value={manageBlendStore.blendData.selectedBlendLibraryCategory}
															onChange={(option) => {
																manageBlendStore.markChange();
																manageBlendStore.onChangeSelectedBlendLibraryCategory(option)
															}}
														/>	
														{manageBlendStore.blendData.selectedBlendLibraryCategory != null &&
															<div className="flex ml-2 flex-row items-center cursor-pointer" onClick={() => manageBlendStore.onAddBlendLibraryCategory()}>
																<PlusIcon className="w-4 h-4" color={colors['black']} />
																<TertiaryAppSmallTitle>{translate('user.manage-blend.blend-details.blend-library.add-button')}</TertiaryAppSmallTitle>
															</div>
														}
													</div>
													<div className="flex flex-row overflow-x-scroll">
														{manageBlendStore.blendData.blendLibraryCategories.map((blendLibraryCategory) => 
															<>
																<Tag 
																	id={blendLibraryCategory.id} c
																	canDelete 
																	key={blendLibraryCategory.id} 
																	name={blendLibraryCategory.name}
																	onDelete={(id) => manageBlendStore.removeLibraryCategoryById(id)}
																/>
															</>
														)}
													</div>
												</div>
											</DataRow>
										}
										{(manageBlendStore.appStore.isSuperAdministrator || manageBlendStore.appStore.isAdministrator) && viewMode && 
											<DataRow label={translate('user.manage-blend.blend-details.blend-library.label')}>
												<div className="flex flex-row overflow-x-scroll">
													{manageBlendStore.currentBlend?.library_categories.map((entry) => 
														<>
															<Tag key={entry.blend_library_category.id} name={entry.blend_library_category.name} />
														</>
													)}
												</div>
											</DataRow>
										}
										<DataRow label={translate('user.manage-blend.blend-details.tags.label')} tooltip={<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-tags')} />}>
											<div className="flex mt-2 w-full flex-col overflow-hidden">
												{manageMode &&
													<TagInput
														 inputValue={manageBlendStore.blendData.currentTag}
														 t={translate}
														 onNewEntry={(tag) => {
														 	manageBlendStore.markChange();
														 	manageBlendStore.onNewTag(tag)
														 }}
														 onChange={(tags) => {
														 	manageBlendStore.markChange();
														 	manageBlendStore.onChangeTags(tags)
														 }}
														 onInputChange={(tag) => {
														 	manageBlendStore.markChange();
														 	manageBlendStore.onChangeTag(tag)
														 }}
														 values={manageBlendStore.currentTags} 
														/>
												}
												<div className="flex flex-row overflow-x-scroll">
													{manageBlendStore.currentTags.map((tag) => 
														<>
															<Tag key={tag.value} name={tag.label} />
														</>
													)}
												</div>
											</div>
										</DataRow>
									</div>
								</div>
							</GridSection>
						</div>
						{manageBlendStore.showBlendResults && manageMode && !isHomeUser &&
							<div className="col-span-6">
								<GridSection>
									<div className="border-b border-black flex w-full py-1">
										<SecondaryWebSmallTitle>{translate('user.manage-blend.blend-results.title')}</SecondaryWebSmallTitle>
									</div>
									<div className="grid grid-cols-2 xl:grid-cols-4 gap-8 mt-4">
										<div className="flex flex-1 flex-col">
											<SecondaryWebSmallTitle>{translate('user.manage-blend.blend-results.chemical-families')}</SecondaryWebSmallTitle>


											<div className="flex flex-1" onMouseLeave={() => manageBlendStore.hideFamilyLabel()}>
												<PieChart 
													showLabels={false}
													data={manageBlendStore.chemicalFamilyBreakdownData}
													onClick={(props) => manageBlendStore.showFamilyDialog(props)}
													onMouseOver={(props) => manageBlendStore.showFamilyLabel(props)}
												/>
												{manageBlendStore.currentHoverChemicalFamily != null &&
													<div className="absolute w-48 h-auto px-4 py-4  flex flex-col items-start justify-center bg-white shadow-xl border border-black-10 rounded-lg" style={{ left: manageBlendStore.currentHoverChemicalFamily.mouseX, top: manageBlendStore.currentHoverChemicalFamily.mouseY}}>
														<div className="flex"><SmallBody className="bold-font">{manageBlendStore.currentHoverChemicalFamily.family.name}: {manageBlendStore.currentHoverChemicalFamily.percentage}%</SmallBody></div>
														<div className="flex mt-1"><SmallBody>{manageBlendStore.currentHoverChemicalFamily.family.bio.substring(0, 100)}</SmallBody></div>
													</div>
												}													
											</div>
										</div>
										<div className="flex flex-1 flex-col">
											<SecondaryWebSmallTitle>{translate('user.manage-blend.blend-results.physical-effects')}</SecondaryWebSmallTitle>

											<IngredientEffectBreakdown 
												data={manageBlendStore.physicalEffectsBreakdown}
												attribute="physical_ranking"
											/>
										</div>
										<div className="flex flex-1 flex-col">
											<SecondaryWebSmallTitle>{translate('user.manage-blend.blend-results.psychological-effects')}</SecondaryWebSmallTitle>

											<IngredientEffectBreakdown 
												data={manageBlendStore.psychologicalEffectsBreakdown}
												attribute="psychological_ranking"
											/>
										</div>
										<div className="flex flex-1 flex-col">
											<SecondaryWebSmallTitle>{translate('user.manage-blend.blend-results.scent-notes')}</SecondaryWebSmallTitle>

											<ScentNoteBreakdown data={manageBlendStore.scentNoteBreakdown} />
										</div>
									</div>
								</GridSection>
							</div>
						}
						<BlendingLab 
							editMode={manageMode}
							t={translate} 
							manageBlendStore={manageBlendStore} 
							tooltipText={manageBlendStore.appStore.getTooltip('tooltip-database-ingredients')}
						/>
						{manageMode && 
							<div className="col-span-6">
								<div className="flex w-full justify-center flex-row items-center">
									<Button
										width="w-full"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="pink-coral"
										onClick={() => manageBlendStore.toggleDetailedBlendResults()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{manageBlendStore.showDetailedBlendResults ? translate('user.manage-blend.hide-detailed-results') : translate('user.manage-blend.view-detailed-results')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex">{manageBlendStore.showDetailedBlendResults ? <ArrowUpIcon className="w-4 h-4" color="white" /> : <ArrowDownIcon className="w-4 h-4" color="white" />}</div>
										</div>
									</Button>
								</div>
							</div>
						}
						{(manageBlendStore.showDetailedBlendResults || viewMode) &&
							<>
								{manageBlendStore.showBlendResults && 
									<>
										{!isHomeUser && 
											<>
												<div className="col-span-6">
													<GridSection>
														<div className="flex flex-row items-center">
															<SecondaryWebSmallTitle>{translate('user.manage-blend.chemical-families.title')}</SecondaryWebSmallTitle>
															<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-blend-chemical-family-breakdown')} />
														</div>


														<div className="flex relative flex-row w-full justify-center items-center">
															<div className="flex" onMouseLeave={() => manageBlendStore.hideLargeFamilyLabel()}>
																<PieChart 
																	labelFontSize={24}
																	labelCols={1}
																	showBelow
																	y={75}
																	ref={(ref) => { this.chemicalFamilyPieChartRef = ref; }}
																	data={manageBlendStore.chemicalFamilyBreakdownData}
																	onClick={(props) => manageBlendStore.showFamilyDialog(props)}
																	onMouseOver={(props) => manageBlendStore.showLargeFamilyLabel(props)}
																/>
															</div>
															<div className="flex absolute right-0 flex-col" style={{ top: '30%', left: '45%'}}>
																{manageBlendStore.currentLargeHoverChemicalFamily != null &&
																	<div className="flex flex-col relative w-full h-auto px-4 py-8 h-auto shadow-lg border-black-10 border rounded-lg" style={{backgroundColor: manageBlendStore.currentLargeHoverChemicalFamily.family.hex_color}}>
																		<div className="flex"><Body className="bold-font">{manageBlendStore.currentLargeHoverChemicalFamily.family.name}</Body></div>
																		<div className="flex mt-1"><Body>{manageBlendStore.currentLargeHoverChemicalFamily.family.bio.substring(0, 100)}</Body></div>
																		<div style={{
																			position: 'absolute',
																			left:0,
																			top:"50%",
																			transform: "translateY(-50%)",
																			marginTop:0,
																			marginLeft: '-20px',
																			height:0, 
																			width:0,
																			borderTop: '16px solid transparent', 
																			borderBottom: '16px solid transparent', 
																			borderRight: `32px solid ${manageBlendStore.currentLargeHoverChemicalFamily.family.hex_color}`
																		}}></div>
																	</div>
																}
															</div>
														</div>
													</GridSection>
												</div>
												<div className="col-span-6 xl:col-span-3">
													<GridSection>
														<div className="flex flex-row items-center">
															<SecondaryWebSmallTitle>{translate('user.manage-blend.chemical-breakdown.title')}</SecondaryWebSmallTitle>
															<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-blend-chemical-breakdown')} />
														</div>

														<div className="flex flex-1 flex-col mt-4">
															{manageBlendStore.flattenChemicalComponentBreakdownByFamily.slice(0, manageBlendStore.INITIAL_BREAKDOWN_LIMIT).map((entry) => 
																<div className="flex w-full flex-col mt-2">
																	{entry.type === 'title' && <ChemicalComponentHeader header={entry.name} />}
																	{entry.type === 'data' && 
																		<ChemicalComponentEntry t={translate} entry={entry.entry} key={entry.id} />
																	}
																</div>
															)}
															{manageBlendStore.flattenChemicalComponentBreakdownByFamily.length > manageBlendStore.INITIAL_BREAKDOWN_LIMIT &&
																<div className="relative flex w-full flex-row justify-center border-b border-black py-1">
																	<SmallBody>...</SmallBody>
																</div>
															}
															{manageBlendStore.flattenChemicalComponentBreakdownByFamily.length > manageBlendStore.INITIAL_BREAKDOWN_LIMIT && 
																<div className="flex w-full items-center justify-center mt-8">
																	<Button
																		width="w-1/3"
																		height="h-12"
																		className="px-4 rounded-lg"
																		bgColor="pink-coral"
																		onClick={() => manageBlendStore.onShowChemicalComponentBreakdown()}
																	>
																		<div className="flex flex-row items-center justify-center">
																			<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-blend.chemical-breakdown.complete-breakdown')} &nbsp;</TertiaryAppSmallTitle></div>
																		</div>
																	</Button>
																	
																</div>	
															}

															<BaseModal
													            isOpen={manageBlendStore.showChemicalComponentBreakdown}
													            onClose={() => manageBlendStore.onCloseChemicalComponentBreakdown()}
													            label="Chemical Component Breakdown"
													        >
													        	<div className="flex w-full h-full overflow-scroll bg-white rounded-lg">
													        		<div className="w-full mt-4 grid grid-cols-1 row-gap-2 col-gap-8 bg-white px-8 py-8 mt-8">
														        		{manageBlendStore.chemicalComponentBreakdownByFamily.map((entry) => 
														        			<div className="flex w-full flex-col mt-2">
																				<ChemicalComponentHeader header={entry[0]} />
																				{entry[1].sort(sortByPercentage).map((entry) => 
																					<ChemicalComponentEntry t={translate} entry={entry} />
																				)}
																			</div>
														        		)}
														        	</div>
													        	</div>
													        </BaseModal>

														</div>
													</GridSection>
												</div>
												<div className="col-span-6 xl:col-span-3" style={{ height: '475px'}}>
													<GridSection>
														<div className="flex flex-row items-center justify-between">
															<div className="flex flex-row items-center">
																<div className="flex"><SecondaryWebSmallTitle>{translate('user.manage-blend.compliance.title')}</SecondaryWebSmallTitle></div>
																<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-blend-compliance')} />
															</div>
															{manageBlendStore.canViewCompliance && 
																<div className="flex w-64">
																	<FormSelect 
																		className={`${SPECIAL_FORM_INPUT_CLASS_NAME} w-full`}
																		placeholder={translate('user.manage-blend.compliance.choose-region')}
																		options={manageBlendStore.regionOptions}
																		value={manageBlendStore.blendData.selectedBlendRegion.value}
																		error={manageBlendStore.blendData.selectedBlendRegion.error}
																		onChange={(option) => {
																			manageBlendStore.onChangeBlendRegion(option.value)
																		}}
																	/>
																</div>
															}
														</div>
														{manageBlendStore.canViewCompliance && manageBlendStore.complianceWarnings.length > 0 &&
															<div className="flex flex-col overflow-y-scroll">
																{manageBlendStore.complianceWarnings.map((warning) => 
																	<div className="flex flex-shrink-0 flex-col border border-black-10 rounded-lg px-4 py-4 mt-8">
																		<div className="flex flex-row items-center justify-between">
																			<div className="flex"><SmallBody className="text-error">{warning.chemical_component.name}</SmallBody></div>
																			<div className="flex"><SmallBody className="text-error">{warning.percentage}%</SmallBody></div>
																		</div>
																		<div className="flex flex-row">
																			<SmallBody>{warning.regulation.regulation_information}</SmallBody>
																		</div>
																	</div>
																)}
															</div>
														}
														{manageBlendStore.canViewCompliance && manageBlendStore.complianceWarnings.length == 0 && 
																<div className="flex flex-1 mt-4 justify-center">
																	<SmallBody>{translate('user.manage-blend.compliance.no-compliance-warnings')}</SmallBody>
																</div>
														}
														{!manageBlendStore.canViewCompliance && 
															<UpgradeComplianceScreen t={translate} onClick={() => manageBlendStore.onUpgradeForCompliance()} />
														}
													</GridSection>
												</div>
											</>
										}
										<div className="col-span-6 xl:col-span-2">
											<GridSection>
												<div className="flex flex-row items-center">
													<SecondaryWebSmallTitle>{translate('user.manage-blend.scent-notes.title')}</SecondaryWebSmallTitle>
													<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-blend-scent-notes')} />
												</div>
												<ScentNoteBreakdown data={manageBlendStore.scentNoteBreakdown} />
												<div className="flex flex-1 justify-end items-end">
													<div className="flex flex-1 w-full justify-center mt-32"><ScentNoteIcon className="w-full h-24" /></div>
												</div>

											</GridSection>
										</div>
										<div className="col-span-6 xl:col-span-2">
											<GridSection>
												<div className="flex flex-row items-center">
													<SecondaryWebSmallTitle>{translate('user.manage-blend.physical-effects.title')}</SecondaryWebSmallTitle>
													<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-psychological-effects')} />
												</div>

												<div className="flex flex-1 justify-between w-full flex-col mt-4">
													<RankingScoreGrid 
														data={manageBlendStore.allPhysicalRankingScores}
														color="pink-coral"
													/>
													<div className="flex w-full flex-col mt-4">
														<IngredientEffectBreakdown 
															data={manageBlendStore.physicalEffectsBreakdown}
															attribute="physical_ranking"
														/>

													</div>
												</div>
											</GridSection>
										</div>
										<div className="col-span-6 xl:col-span-2">
											<GridSection>
												<div className="flex flex-row items-center">
													<SecondaryWebSmallTitle>{translate('user.manage-blend.psychological-effects.title')}</SecondaryWebSmallTitle>
													<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-psychological-effects')} />
												</div>

												<div className="flex flex-1 w-full justify-between flex-col mt-4">
													<RankingScoreGrid 
														data={manageBlendStore.allPsychologicalRankingScores}
														color="success-green"
													/>
													<div className="flex w-full flex-col mt-4">
														<IngredientEffectBreakdown 
															data={manageBlendStore.psychologicalEffectsBreakdown}
															attribute="psychological_ranking"
														/>
													</div>
												</div>
											</GridSection>
										</div>
										
									</>
								}
								<div className="col-span-6 xl:col-span-4">
									<GridSection>
										<div className="flex flex-row items-center">
											<SecondaryWebSmallTitle>{translate('user.manage-blend.blend-notes.title')}</SecondaryWebSmallTitle>
											<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-blend-notes')} />
										</div>
										<div className="flex flex-1 flex-col">
											{manageMode && manageBlendStore.blendData.blendNotes.map((blendNote) => 
												<NoteSection 
													t={translate} 
													editMode={true}
													blendNote={blendNote}
													onDelete={(uuid) => manageBlendStore.deleteBlendNoteSection(uuid)}
												/>

											)}
											{viewMode && manageBlendStore.currentBlend?.notes.map((blendNote) => 
												<NoteSection 
													t={translate} 
													editMode={false}
													blendNote={blendNote}
													onDelete={(uuid) => manageBlendStore.deleteBlendNoteSection(uuid)}
												/>

											)}
											{manageMode &&
												<div className="mt-2">
													<AddListButton onClick={() => manageBlendStore.addNoteSection()} text={translate('user.manage-blend.blend-notes.add-button')} />
												</div>
											}
										</div>
									</GridSection>
								</div>
								<div className="col-span-6 xl:col-span-2">
									<GridSection>
										<div className="flex flex-row items-center">
											<SecondaryWebSmallTitle>{translate('user.manage-blend.files.title')}</SecondaryWebSmallTitle>
											<HelpTooltip text={manageBlendStore.appStore.getTooltip('tooltip-blend-files')} />
										</div>
										
										<div className="flex flex-1 flex-col">
											{manageMode && 
												<>
													<div className="flex flex-1 flex-col">
														{manageBlendStore.blendData.uploadedFiles.map((file) => 
															<FileEntry 
																editMode={manageMode}
																file={file}
																onDelete={(uuid) => manageBlendStore.deleteFileByUUID(uuid)}
															/>
														)}
													</div>
													<UploadFiles
														t={translate} 
														maxFileSize={2}
														description={
															(
																<>
																	{translate('common.upload-files.valid-files.part1')} <br /> {translate('common.upload-files.valid-files.part2')} 
																</>
															)
														}
														onChangeFiles={(files) => {
															manageBlendStore.markChange();
															manageBlendStore.onUploadFiles(files)
														}}
														onDeleteFile={() => console.log('delete')}
													 />
												</>
											}
											{viewMode && 
												<TwoColumnReadList
													headers={
														[
															translate('user.manage-blend.files.name.label'),
															translate('user.manage-blend.files.view.label')
														]
													}
													data={manageBlendStore.currentBlend?.files.map((f) => [
														f.name, (
															<div className="flex flex-1 flex-row">
																<div className="flex cursor-pointer"><EyeIcon className="w-4 h-4" color={colors['black']} onClick={() => window.open(f.file_url)} /></div>
															</div>
															) 
														])}
													nodata={translate('user.manage-ingredient.files.nodata')}
												/>
											}
										</div>

									</GridSection>
								</div>
							</>
						}
					</div>
					<div className="flex flex-row justify-end w-full mt-4">
						{saveDraftBlendBtn}
						{saveBlendBtn}
					</div>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(ManageBlend);