import BaseApi from '../services/base';

class ContactApi extends BaseApi{
	constructor(props){
		super(props);
	}

	submit(message){
		return this.fetch('v1/contact', {
			method: 'POST',
			body: JSON.stringify(message)
		})
	}
}

export default ContactApi;