class S3Api{
	async uploadToS3(file, s3Data){
		var formData = new FormData();
		for(var key in s3Data.fields){
			formData.append(key, s3Data.fields[key]);
		}
		formData.append('file', file);
		return fetch(s3Data.url, {
		 	method: 'POST',
		 	body: formData
		});
	}
}

export default S3Api;