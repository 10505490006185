import { observable, action, computed } from 'mobx';

import SearchApi from '../../services/search';
import TagApi from '../../services/tag';

const VIEW_MODE = {
	TILE: 'tile',
	LIST: 'list'
}

class AdvancedSearchStore{
	appStore;

	searchApi;
	tagApi;

	@observable query;
	@observable viewMode;
	@observable noOfResults;
	@observable searchResults;

	@observable showSearchResults;

	@observable hasMoreSearchResults;

	@observable showRefinedSearch;
	@observable selectedFilter;
	@observable showSortDialog;
	@observable sortBy;

	@observable searchTags;
	@observable currentTagInput;
	@observable possibleTags;

	@observable currentSearchQuery;
	@observable searchResultsPage;

	@observable searching;

	constructor(appStore){
		this.appStore = appStore;
		this.searchApi = new SearchApi(appStore);
		this.tagApi = new TagApi(appStore);
		this.initStore();
	}

	@action initStore(){
		this.viewMode = VIEW_MODE.TILE;
		this.noOfResults = 0;
		this.showSearchResults = false;
		this.searchResults = [];
		this.query = null;
		this.currentTagInput = null;
		this.searchTags = [];
		this.selectedFilter = this.getDefaultFilterBy();
		this.showSortDialog = false;
		this.sortBy = this.getDefaultSortBy();
		this.possibleTags = [];
		this.searchResultsPage = 1;
		this.showRefinedSearch = false;
		this.currentSearchQuery = null;
		this.hasMoreSearchResults = true;
		this.searching = false;
	}

	getDefaultFilterBy(){
		return this.filterOptions[0].value;
	}

	getDefaultSortBy(){
		return this.sortOptions[0].value;
	}

	@action changeToListViewMode(){
		this.viewMode = VIEW_MODE.LIST;
	}

	@action changeToTileViewMode(){
		this.viewMode = VIEW_MODE.TILE;
	}

	@action changeSearchQuery(val){
		this.query = val;
	}

	@action triggerSearch(){
		if(this.query != null && this.query.length > 0){
			this.searchResultsPage = 1;
			this.showSearchResults = false;
			this.performSearch();
		}
	}

	@computed get isListView(){
		return this.viewMode === VIEW_MODE.LIST;
	}

	@computed get isTileView(){
		return this.viewMode === VIEW_MODE.TILE;
	}

	@computed get currentSearchTags(){
 		return this.searchTags.map((searchTag) => {
 			return {
 				value: searchTag,
 				label: searchTag
 			}
 		});
	}

	onNewTag(tag){
	 	if(this.searchTags.includes(tag)) return;
	 	this.searchTags.push(tag);
	 	this.currentTagInput = '';
	 	this.possibleTags = [];
	}
	
	onChangeTagInput(val){
		this.currentTagInput = val;
		if(this.currentTagInput != null && this.currentTagInput.length > 0){
			this.searchPossibleTags();
		}
	}	

	searchPossibleTags(){
		if(this.currentTagInput == null || this.currentTagInput === '') return;
		this.tagApi.searchTags(this.currentTagInput)
			.then((response) => {
				this.possibleTags = response.tags;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onChangeTags(tags){
	 	this.searchTags = tags == null ? [] : tags.map((t) => t.value);
	}

	onSelectPossibleTag(id){
		let matchingTag = this.possibleTags.find((t) => t.id == id);
		if(matchingTag != null){
			this.onNewTag(matchingTag.name);
		}
	}

	@action onChangeFilter(val){
		this.selectedFilter = val;
	}

	@computed get filterOptions(){
		return [
			{
				value: 'all',
				label: this.appStore.i18n.t('user.advanced-search.refine-search.filter-options.all')
			},
			{
				value: 'ingredient',
				label: this.appStore.i18n.t('user.advanced-search.refine-search.filter-options.ingredients')
			},
			{
				value: 'blend',
				label: this.appStore.i18n.t('user.advanced-search.refine-search.filter-options.blends')
			},
			{
				value: 'evidence',
				label: this.appStore.i18n.t('user.advanced-search.refine-search.filter-options.evidence')
			}
		]
	}

	@computed get sortOptions(){
		return [
			{
				value: 'relevance',
				label: this.appStore.i18n.t('user.advanced-search.refine-search.sort-options.relevance')
			},
			{
				value: 'created_on',
				label: this.appStore.i18n.t('user.advanced-search.refine-search.sort-options.created-on')
			}
		]
	}

	@computed get currentSortAttribute(){
		if(this.sortBy === null) return null;
		let sortOption = this.sortOptions.find((s) => s.value == this.sortBy);
		return sortOption?.label;
	}

	@action onToggleSortDialog(){
		this.showSortDialog = !this.showSortDialog;
	}

	@action onChangeSortOption(val){
		this.sortBy = val;
	}

	@action onApplySort(){
		this.onToggleSortDialog();
	}

	nextSearchResultsPage(){
		this.searchResultsPage += 1;
		this.performSearch(true);
	}

	previousSearchResultsPage(){
		this.searchResultsPage = Math.max(this.searchResultsPage - 1, 0);
		this.performSearch(true);
	}

	maptoSearchResult(searchResult){
		let foundIn = null;
		if(searchResult.type == 'blend'){
			searchResult.foundIn = searchResult.public ? this.appStore.i18n.t('user.advanced-search.search-results.public-blend-library') : this.appStore.i18n.t('user.advanced-search.search-results.private-blend-library');
		}else if(searchResult.type == 'ingredient'){
			searchResult.foundIn = searchResult.public ? this.appStore.i18n.t('user.advanced-search.search-results.public-ingredient-library') : this.appStore.i18n.t('user.advanced-search.search-results.private-ingredient-library');
		}else if(searchResult.type == 'evidence'){
			searchResult.foundIn = this.appStore.i18n.t('user.advanced-search.search-results.evidence');
		}
		return searchResult;
	}

	resetSearch(){
		this.showRefinedSearch = false;
		this.showSearchResults = false;
		this.searchResults = [];
		this.searchResultsPage = 1;
		this.hasMoreSearchResults = true;
		this.selectedFilter = this.getDefaultFilterBy();
		this.searchTags = [];
		this.sortBy = this.getDefaultSortBy();
	}

	searchWithQuery(triggerQuery){
		if(triggerQuery == null || triggerQuery.length == 0) return;
		this.query = triggerQuery;
		this.triggerSearch();
	}

	onClickSearchResult(uuid){
		let foundSearchResult = this.searchResults.find((s) => s.uuid == uuid);
		if(foundSearchResult != null){
			if(foundSearchResult.type === 'ingredient'){
				this.appStore.goToEditIngredient(foundSearchResult.id);
			}else if(foundSearchResult.type === 'blend'){
				this.appStore.goToEditBlend(foundSearchResult.id);
			}else if(foundSearchResult.type == 'evidence'){
				this.appStore.goToViewEvidence(foundSearchResult.id);
			}
		}
	}

	performSearch(append=false){
		this.currentSearchQuery = this.query;
		window.history.pushState(null, null, `advanced-search/${this.currentSearchQuery}`);
		let payload = {
			query: this.currentSearchQuery,
			page: this.searchResultsPage,
			tags: this.searchTags.length > 0 ? this.searchTags : null,
			filter_by: this.selectedFilter,
			sort_by: this.sortBy
		}
		this.searching = true;
		this.searchApi.search(payload)
			.then((response) => {
				let results = response.results;
				let newSearchResults = results.search_results.map((searchResult) => this.maptoSearchResult(searchResult));
				if(append){
					this.searchResults = [...this.searchResults, ...newSearchResults];
				}else{
					this.searchResults = newSearchResults;
				}
				this.noOfResults = results.no_of_results;
				this.showSearchResults = true;
				this.showRefinedSearch = true;
				this.hasMoreSearchResults = newSearchResults.length > 0;
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.searching = false;
			})
	}
}

export default AdvancedSearchStore;