export const intersect = (arrA, arrB) => arrA.filter((x) => arrB.includes(x));

export const SUPER_ADMINISTRATOR = 'Super Administrator';
export const ORGANISATION_ADMINISTRATOR = 'Organisation Administrator';
export const USER = 'User';

export const COSMETIC_REGULATIONS = 'Cosmetic Regulations';
export const CHEMISTRY = 'Chemistry';
export const LANGUAGES = 'Languages';
export const USERS = 'Users';

export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const IMAGE_FILE_TYPES = ['image/jpeg', 'image/png'];
export const UPLOAD_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
	
export const computeUserLanguage = () => {
    let browserLang = navigator.language || navigator.userLanguage;
    if(browserLang.includes('-')){
        return browserLang.split('-')[0]
    }else{
        return browserLang;
    }
}

export function getDisplayPeriod(val){
	if(val == 'monthly'){
		return 'monthly'
	}else if(val == 'annually'){
		return 'annual';
	}
}

export function sortCountriesAlphabetically(c1, c2){
	if (c1.name.toLowerCase()  > c2.name.toLowerCase() ) {
        return 1;
    }
    if (c2.name.toLowerCase()  > c1.name.toLowerCase() ) {
        return -1;
    }
    return 0;
}

export const MEASUREMENTS = {
    ml: 'ml',
    drops: 'drops',
    ounces: 'ounces',
    grams: 'grams',
    percentage: '%'
}

