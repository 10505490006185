import { observable, action, computed } from 'mobx';
import { FieldState, FormState } from 'formstate';
import { isRequiredValidator } from '../../../services/validation';
import LanguageApi from '../../../services/language';

class LanguagesStore{

	@observable languages;

	@observable showEditTooltip;

	@observable fetching;

	languageApi;

	constructor(appStore){
		this.appStore = appStore;
		this.languageApi = new LanguageApi(this.appStore);
		this.initStore();
	}

	initStore(){
		this.languages = [];
		this.showEditTooltip = null;
		this.addMode = false;
		this.fetching = false;
	}

	fetchLanguages(){
		this.fetching = true;
		this.languageApi.getLanguages()
			.then((response) => {
				this.languages = response.languages.filter((l) => l.id != 'en');
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	onEditLanguage(languageId){
		this.appStore.goToEditLanguage(languageId);
	}

	@action setEditTooltip(id){
		this.showEditTooltip = id;
	}
}

export default LanguagesStore;