import React, { Component } from 'react';
import {inject, observer} from 'mobx-react';
import { withTranslation } from 'react-i18next';

import BaseModal from '../common/modal';

import {Label, Body, SecondaryWebLargerTitle, SecondaryWebSmallTitle} from '../common/text';
import {TextInput, CheckBox, Select} from '../common/input'
import {Button} from '../common/button';

import { Form, HorizontalFormColumn, FormButtonContainer } from '../common/form';

import envelopeSwooshSrc from '../../assets/img/icons/envelope-swoosh.svg';
import {parseAndSanitizeHtml} from '../common/html';

import Recaptcha from 'react-recaptcha';

const RECAPTCHA_SITEKEY = "6LellscZAAAAAMZ1TRwAoU32MOhSY-kHNFzWX4M3";

@inject('newsletterStore', 'appStore')
@observer
class Newsletter extends Component{
	constructor(props){
		super(props);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeName = this.onChangeName.bind(this);
		this.onChangeLocation = this.onChangeLocation.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onExit = this.onExit.bind(this);
		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.onChangeCountry = this.onChangeCountry.bind(this);
		this.onTerms = this.onTerms.bind(this);
		this.hideForever = this.hideForever.bind(this);
	}

	componentDidMount(){
		this.props.newsletterStore.load();
	}

	componentWillUnmount(){
		let newsletterStore = this.props.newsletterStore;
		newsletterStore.initStore();
	}

	onChangeEmail(e){
		let newsletterStore = this.props.newsletterStore;
		newsletterStore.onChangeEmail(e.target.value);
	}	

	onChangeName(e){
		let newsletterStore = this.props.newsletterStore;
		newsletterStore.onChangeName(e.target.value);
	}

	onChangeLocation(e){
		let newsletterStore = this.props.newsletterStore;
		newsletterStore.onChangeLocation(e.target.value);
	}	

	onSubmit(e){
		e.preventDefault();
		let newsletterStore = this.props.newsletterStore;
		newsletterStore.submit();
	}

	onExit(){
		let newsletterStore = this.props.newsletterStore;
		newsletterStore.exitNewsletter();
	}

	onChangeCheckbox(e){
		let newsletterStore = this.props.newsletterStore;
		newsletterStore.onChangeOptIn(e.target.checked);
	}

	onChangeCountry(option){
		let newsletterStore = this.props.newsletterStore;
		newsletterStore.onChangeCountry(option.value);
	}

	onTerms(){
		let newsletterStore = this.props.newsletterStore;
		newsletterStore.goToTerms();
	}

	hideForever(){
		let newsletterStore = this.props.newsletterStore;
		newsletterStore.hideNewsletterForever();
	}

	render(){
		let newsletterStore = this.props.newsletterStore;
		let newsletterIntroHtml = newsletterStore.introTextHTML != null ? parseAndSanitizeHtml(newsletterStore.introTextHTML) : null;
		let translate = this.props.t;
		let appStore = this.props.appStore;

		if(!newsletterStore.loaded) return null;
		let title = (
			<>
				{translate('newsletter.title.part1')} <br /> {translate('newsletter.title.part2')}
			</>
		);
		let body = newsletterIntroHtml;
		if(newsletterStore.subscribed){
			title = (
				<>
					{translate('newsletter.subscribed.title')}
				</>
			)

			body = (
				<>
					{translate('newsletter.subscribed.body')}
				</>
			)
		}
		return (
			<BaseModal
	            isOpen={appStore.showNewsletterModal}
	            onClose={this.onExit}
	            label="Newsletter"
	        >
				<div className="flex min-h-screen w-full flex-col xl:flex-row bg-white">
					<div className="flex h-1/2 xl:h-full w-full xl:w-1/2 flex-col bg-modal-color justify-center px-8 py-8 xl:py-0 xl:px-0">
						<div className="flex h-12 xl:h-64 flex-col justify-center items-center mt-4 xl:mt-0 xl:items-start w-full py-0xl:py-16 xl:w-4/5 xl:px-20 xl:py-0">
							<div className="hidden xl:block"><SecondaryWebLargerTitle className="text-black text-center xl:text-left">{title}</SecondaryWebLargerTitle></div>
							<div className="block text-center xl:hidden mt-8"><SecondaryWebSmallTitle className="text-black text-center xl:text-left">{title}</SecondaryWebSmallTitle></div>
							{newsletterIntroHtml != null && 
								<div className="hidden lg:block text-center xl:text-left">
									<Body className="text-black text-lg mt-8">{body}</Body>
								</div>
							}
						</div>
					</div>
					<div className="flex flex-col w-full xl:w-1/2 bg-white items-center justify-center overflow-y-scroll">
						{newsletterStore.subscribed && 
							<div className="flex items-center flex-col py-8 xl:p-0 xl:w-2/3">
								<div className="flex flex-1"></div>
								<div className="flex flex-1">
									<img src={envelopeSwooshSrc} className="w-64 h-64" alt="Subscription received" />
								</div>

								<div className="flex h-auto xl:h-48 items-center xl:items-end w-full mt-8 mb-8 xl:mt-0">
									<Button
										 bgColor="black"
										 width="w-full"
										 fgColor="white"
										 onClick={this.onExit}>{translate("newsletter.return")}</Button>
								</div>
							</div>
						}
						{!newsletterStore.subscribed &&
							<div className="flex flex-col py-4 px-4 xl:py-16 xl:px-8 w-full xl:w-2/3">
								<div className="flex flex-col mt-4">
									<div className="flex flex-row"><Label>{translate("newsletter.name.label")}</Label></div>
									<div className="flex flex-row"><TextInput 
										placeholder={translate("newsletter.name.placeholder")}
										onChange={this.onChangeName}
										value={newsletterStore.name.value}
										error={newsletterStore.name.error}
									/></div>
								</div>
								<div className="flex flex-col mt-4">
									<div className="flex flex-row"><Label>{translate("newsletter.email.label")}</Label></div>
									<div className="flex flex-row"><TextInput 
										placeholder={translate("newsletter.email.placeholder")}
										onChange={this.onChangeEmail}
										value={newsletterStore.email.value}
										error={newsletterStore.email.error}
									/></div>
								</div>
								<div className="flex flex-col mt-4">
									<div className="flex flex-row"><Label>{translate("newsletter.location.label")}</Label></div>
									<div className="flex flex-row w-full">
										<Select 
											placeholder={translate('newsletter.location.placeholder')}
											value={newsletterStore.currentCountry}
											options={newsletterStore.countryOptions}
											onChange={this.onChangeCountry}
										/>
									</div>
								</div>
								<div className="flex w-full flex-row items-center mt-8">
									<div className="flex flex-row"><Body>{translate("newsletter.confirm.part1")} <span onClick={this.onTerms} className="bold-font hover:text-black cursor-pointer">{translate("newsletter.confirm.part2")}</span></Body></div>
									<div className="flex flex-row"><CheckBox 
										checked={newsletterStore.optInNewsletter.value}
										extraInputClassNames="text-black"
										onChange={this.onChangeCheckbox}
									/></div>
								</div>

								<div className="flex w-full flex-row mt-4 overflow-x-scroll">
					       			<Recaptcha
					       				className='recaptcha-container'
										sitekey={RECAPTCHA_SITEKEY}
										render="explicit"
										onloadCallback={() => console.log('captcha loaded')}
										verifyCallback={(response) => newsletterStore.verifyRecaptcha(response)}
									 />
								</div>

								<div className="flex flex-col mt-4">
									<Button
									 bgColor="black"
									 width="w-full"
									 fgColor="white"
									 fetching={newsletterStore.fetching}
									 disabled={!newsletterStore.isValidForm}
									 onClick={this.onSubmit}>{translate("newsletter.button")}</Button>

									<div className="text-center mt-4" onClick={this.hideForever}>
										<Body className="bold-font cursor-pointer hover:text-red-500">{translate("newsletter.dismiss")}</Body>
									</div>
								</div>
								
							</div>
						}
					</div>
				</div>
			</BaseModal>
		)
	}
}

export default withTranslation()(Newsletter);