import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';

import {inject, observer} from 'mobx-react';
import { SmallBody, Body, SecondaryAppLargeTitle, SecondaryAppSmallTitle, TertiaryAppSmallTitle,TertiaryAppExtraSmallTitle, ExtraSmallBody} from '../../common/text';
import { ChemicalComponentSearchIcon, DeleteIcon, DownloadIcon, EditIngredientIcon, ArrowRightIcon, PlusIcon, StarIcon, EditIcon, EyeIcon, DuplicateIcon, ArchiveIcon, TickIcon} from '../../common/icon'
import {Button} from '../../common/button';
import Main from '../../elements/main';

const colors = require('../../../color.js');


class IngredientCircle extends Component{
	render(){
		return (
			<div className={`w-20 h-20 xl:w-28 xl:h-28 flex items-center justify-center border-radius-50 bg-${this.props.color}`}>
				<EditIngredientIcon color="transparent" className="w-1/2 h-1/2" />
			</div>
		)
	}
}


@observer
class SearchOverview extends Component{
	render(){
		let translate = this.props.t;
		let search = this.props.search;
		return (
			<div className="flex flex-1 flex-col bg-white rounded-lg px-6 py-8">
				<div className="flex w-full flex-col border-black border-b py-2">
					<SecondaryAppSmallTitle>{search.name}</SecondaryAppSmallTitle>
					<div className="flex flex-row w-full items-center justify-between mt-2">
						<SmallBody>{search.created_on.format('DD MMM, YYYY')}</SmallBody>
					</div>
				</div>
				<div className="flex flex-1 w-full flex-col border-black border-b py-8">
					<TertiaryAppSmallTitle>{translate('user.component-search-list.components')}</TertiaryAppSmallTitle>

					<div className="flex flex-col mt-4">
						{search.search_criteria.map((searchCriteriaEntry) =>
							<div className="flex flex-row items-center mt-1">
								<div><SmallBody>{searchCriteriaEntry.description} {searchCriteriaEntry.chemical_component?.name} </SmallBody></div>
							</div>
						)}
					</div>
					
				</div>
				<div className="flex flex-1 w-full flex-col border-black py-4">
					<TertiaryAppSmallTitle>{translate('user.component-search-list.search-notes')}</TertiaryAppSmallTitle>

					<SmallBody className="mt-2">{search.notes != null ? search.notes.substring(0, 100) : translate('user.component-search-list.no-notes')}</SmallBody>

					<div className="flex flex-col justify-end mt-8">
						<Button
							width="w-full"
							height="h-12"
							className="px-4 rounded-lg"
							bgColor="nav-active-color"
							fetching={false}
							onClick={() => this.props.onEdit(search.id)}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppExtraSmallTitle>{translate('user.component-search-list.view-edit-button')} &nbsp;</TertiaryAppExtraSmallTitle></div>
								<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
							</div>
						</Button>
						{this.props.editDeleteMode && 
							<div className="flex mt-4">
								<Button
									width="w-full"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="error"
									fetching={this.props.fetching}
									onClick={() => this.props.onDelete(search.id)}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppExtraSmallTitle>{translate('user.component-search-list.delete-report-button')} &nbsp;</TertiaryAppExtraSmallTitle></div>
										<div className="flex"><DeleteIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

@inject('componentSearchListStore')
@observer
class ComponentSearchList extends Component{
	componentDidMount(){
		let componentSearchListStore = this.props.componentSearchListStore;
		componentSearchListStore.fetchSearchReports();
	}

	componentWillUnmount(){
		let componentSearchListStore = this.props.componentSearchListStore;
		componentSearchListStore.initStore();	
	}

	render(){
		let translate = this.props.t;
		let componentSearchListStore = this.props.componentSearchListStore;
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.component-search-list.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.component-search-list.paragraph.part1')} <br /> {translate('user.component-search-list.paragraph.part2')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={() => window.open('http://help.labaroma.com/en/articles/4745438-chemical-component-search-tutorial', '_blank')}>
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.component-search-list.video-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex ml-4">
							<ChemicalComponentSearchIcon color='pink' className="w-48 h-48" />
						</div>
					</div>
					<div className="flex flex-row flex-wrap w-full rounded-lg px-4 py-8 bg-white mt-8 cursor-pointer" onClick={() => componentSearchListStore.goToComponentSearchTool()}>
						<div className="grid grid-cols-4 gap-4 xl:w-3/5 justify-between ">
							<IngredientCircle color="pale-yellow" />
							<IngredientCircle color="pale-orange" />
							<IngredientCircle color="pale-red" />
							<IngredientCircle color="pale-teal" />
						</div>
						<div className="flex justify-center mt-4 xl:mt-0 xl:justify-end w-full xl:w-2/5 flex-row items-center">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.component-search-list.component-search-tool')}</SecondaryAppLargeTitle>
							<ArrowRightIcon className="w-6 h-6 ml-2" color={colors["nav-active-color"]} />
						</div>
					</div>
					<div className="flex flex-row justify-between items-center w-full mt-8">
						<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.component-search-list.search-reports.title')}</SecondaryAppLargeTitle>
						<div className="flex justify-end">
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor={componentSearchListStore.editDeleteMode ? "transparent" : "pink-coral"}
								fgColor={componentSearchListStore.editDeleteMode ? "pink-coral": "white"}
								onClick={() => componentSearchListStore.toggleEditDeleteMode()}
							>
								<div className="flex flex-row items-center justify-center">
									{!componentSearchListStore.editDeleteMode && 
										<>
											<div className="flex">
												<TertiaryAppSmallTitle>{translate('user.component-search-list.edit-delete-button')} &nbsp;</TertiaryAppSmallTitle>
											</div>
											<div className="flex"><EditIcon className="w-4 h-4" color={colors['white']} /></div>
										</>
									}
									{componentSearchListStore.editDeleteMode && 
										<>
											<div className="flex">
												<TertiaryAppSmallTitle>{translate('user.component-search-list.cancel-button')} &nbsp;</TertiaryAppSmallTitle>
											</div>
										</>
									}
								</div>
							</Button>
						</div>
					</div>
					{componentSearchListStore.currentSearches.length == 0 && !componentSearchListStore.fetching &&
						<div className="flex flex-row items-center justify-center mt-8">
							<SmallBody>{translate('user.component-search-list.no-reports')}</SmallBody>
						</div>
					}
					{componentSearchListStore.currentSearches.length == 0 && componentSearchListStore.fetching &&
						<div className="flex flex-row items-center justify-center mt-8">
							<SmallBody>{translate('user.component-search-list.fetching-reports')}</SmallBody>
						</div>
					}
					<InfiniteScroll
						  dataLength={componentSearchListStore.currentSearches.length}
						  next={() => componentSearchListStore.loadNextSearchesPage()}
						  hasMore={componentSearchListStore.hasMoreSearches}
						  loader={null}
						  endMessage={null}
						>
							<div className="flex grid grid-cols-3 gap-8 mt-8">
								{componentSearchListStore.currentSearches.map((search) => 
									<SearchOverview
										t={translate}
										editDeleteMode={componentSearchListStore.editDeleteMode}
										onDelete={(id) => componentSearchListStore.onDeleteComponentSearchReport(id)}
										onEdit={(id) => componentSearchListStore.onEditComponentSearchReport(id)}
										key={search.id}
										fetching={componentSearchListStore.fetching}
										search={search}
									/>
								)}
							</div>
					</InfiniteScroll>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(ComponentSearchList);