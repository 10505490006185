import React, { Component } from 'react';
import {inject, observer} from 'mobx-react';
import { withTranslation } from 'react-i18next';

import {TextInput, PasswordInput} from '../common/input'
import LeftBlockRightContent from '../common/left-block-right-content';
import { HorizontalFormColumn } from '../common/form';
import { Label, Body, SecondaryWebLargerTitle} from '../common/text';
import {Button} from '../common/button';

@inject('loginStore', 'appStore')
@observer
class Login extends Component{
	constructor(props){
		super(props);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.onLogin = this.onLogin.bind(this);
		this.goToForgotPassword = this.goToForgotPassword.bind(this);
		this.goToSignUp = this.goToSignUp.bind(this);
	}

	componentDidMount(){
		let appStore = this.props.appStore;
		if(appStore.isAuthenticated){
			this.props.appStore.goToHome();
		}
	}

	componentWillUnmount(){
		let loginStore = this.props.loginStore;
		loginStore.initStore();
	}

	onChangeEmail(e){
		let loginStore = this.props.loginStore;
		loginStore.onChangeEmail(e.target.value);
	}	


	onChangePassword(e){
		let loginStore = this.props.loginStore;
		loginStore.onChangePassword(e.target.value);
	}

	onLogin(e){
		e.preventDefault();
		let loginStore = this.props.loginStore;
		loginStore.login();
	}

	goToForgotPassword(e){
		let appStore = this.props.appStore;
		appStore.goToForgotPassword();
	}

	goToSignUp(){
		let appStore = this.props.appStore;
		appStore.goToPickAPlan();
	}

	render(){
		let loginStore = this.props.loginStore;
		let translate = this.props.t;
		return (
			<LeftBlockRightContent
				leftBgColor='pink-coral-25'
				left={
					<>
						<SecondaryWebLargerTitle className="text-center text-black lg:text-left">{translate('login.title.part1')} <br /> {translate('login.title.part2')} </SecondaryWebLargerTitle>
						<Body className="text-center text-black lg:text-left mt-8">{translate('login.description')}</Body>
					</>
				}
				right={
					<div className="flex flex-1 flex-col justify-center">
						<div className="flex flex-col">
							<HorizontalFormColumn>
								<Label>{translate('login.email.label')}</Label>
								<TextInput 
									placeholder={translate('login.email.placeholder')}
									onChange={this.onChangeEmail}
									value={loginStore.email.value}
									error={loginStore.email.error}
								/>
							</HorizontalFormColumn>
							<HorizontalFormColumn>
								<Label>{translate('login.password.label')}</Label>
								<PasswordInput 
									placeholder={translate('login.password.placeholder')}
									onChange={this.onChangePassword}
									value={loginStore.password.value}
									error={loginStore.password.error}
								/>
							</HorizontalFormColumn>
							
							<div className="flex mb-4 mt-2">
								<Body className="flex flex-1 justify-end underline cursor-pointer hover:text-black" onClick={this.goToForgotPassword}>{translate('login.forgot-password')}</Body>
							</div>
						</div>
						<div className="flex flex-col mt-8">
							<Button
							className="uppercase"
							 width='w-full'
							 bgColor='black'
							 fetching={this.props.appStore.loggingIn}
							 onClick={this.onLogin}
							 disabled={!loginStore.isValidForm}>{translate('login.button')}</Button>
							 <div className="flex flex-row mb-4 mt-2">
								<Body 
									className="inline-flex tracking-wider" 
									onClick={this.goToSignUp}>{translate('login.no-account.part1')}&nbsp;</Body>
								<div className="inline-flex bold-font underline hover:text-candy-pink cursor-pointer" onClick={this.goToSignUp}>{translate('login.no-account.part2')}</div>
							</div>
						</div>
					</div>
				}
			/>
		)
	}
}

export default withTranslation()(Login);