import React, { Component } from 'react';

import {inject, observer} from 'mobx-react';

import BlendLibrary from './blend-library';

@inject('blendsLibraryStore')
@observer
class MyBlendLibrary extends Component{
	componentDidMount(){
		let blendsLibraryStore = this.props.blendsLibraryStore;
		blendsLibraryStore.onLoad(false);
	}

	componentWillUnmount(){
		let blendsLibraryStore = this.props.blendsLibraryStore;
		blendsLibraryStore.initStore();
	}
	
	render(){
		return <BlendLibrary blendsLibraryStore={this.props.blendsLibraryStore} />
	}
}

export default MyBlendLibrary;