import { observable, action, computed, extendObservable } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isPositiveNumberValidator, isNotNullValidator, isRequiredValidator } from '../../../services/validation';

import ChemistryApi from '../../../services/chemistry';
import IngredientApi from '../../../services/ingredient';
import BlendApi from '../../../services/blend';

import {MEASUREMENTS} from '../../../services/util';

 import uuidv4 from 'uuid/v4';

class ComponentSearchStore{

	id;

	@observable fetching;
	@observable searchCriteria;

	@observable searchIngredients;

	@observable showSearchResults;
	@observable compareIngredients;

	@observable showSortDialog;
	@observable sortBy;
	@observable showSaveModal;

	@observable name;
	@observable notes;

	@observable page;
	@observable hasMore;

	@observable showSaveSearchModal;
	@observable reportName;
	@observable searchNotes;

	chemistryApi;
	ingredientApi;
	blendApi;

	constructor(appStore){
		this.appStore = appStore;
		this.chemistryApi = new ChemistryApi(appStore);
		this.ingredientApi = new IngredientApi(appStore);
		this.blendApi = new BlendApi(appStore);

		this.initStore();
	}

	initStore(){
		this.id = null;
		this.fetching = false;
		this.searchCriteria = [
			this.generateSearchCriteriaEntry()
		];
		this.searchIngredients = [];
		this.showSearchResults = false;
		this.showSortDialog = false;
		this.sortBy = null;
		this.compareIngredients = [];
		this.showSaveModal = false;
		this.name = new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n));
		this.notes = new FieldState(null);
		this.page = 1;
		this.hasMore = true;
		this.showSaveSearchModal = false;
		this.reportName = new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n));
		this.searchNotes = new FieldState(null);
	}

	generateSearchCriteriaEntry(){
		return {
			uuid: uuidv4(),
			selectedComponent: new FieldState(null),
			componentSearchText: new FieldState(null),
			componentOptions: new FieldState([]),
			selectedComparison: new FieldState(null),
			amount: new FieldState(0).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n))
		}
	}

	toSearchCriteriaEntry(searchCriteria){
		return {
			uuid: uuidv4(),
			selectedComponent: new FieldState(searchCriteria.chemical_component.id),
			componentSearchText: new FieldState(searchCriteria.chemical_component.name),
			componentOptions: new FieldState([{
				value: searchCriteria.chemical_component.id,
				label: searchCriteria.chemical_component.name
			}]),
			selectedComparison: new FieldState(searchCriteria.comparison),
			amount: new FieldState(searchCriteria.amount).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n))
		}
	}

	onChangeComponentName(uuid, val){
		let searchCriteriaIdx = this.searchCriteria.findIndex((s) => s.uuid == uuid);
		if(searchCriteriaIdx == -1) return;

		this.searchCriteria[searchCriteriaIdx].componentSearchText.onChange(val);
		this.fetching = true;
		this.chemistryApi.getAllChemicalComponents(1, this.searchCriteria[searchCriteriaIdx].componentSearchText.value)
			.then((response) => {
				this.searchCriteria[searchCriteriaIdx].componentOptions.value = response.chemical_components.map((chemicalComponent) => {
					return {
						value: chemicalComponent.id,
						label: chemicalComponent.name
					}
				});
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	onClear(uuid){
		let searchCriteriaIdx = this.searchCriteria.findIndex((s) => s.uuid == uuid);
		if(searchCriteriaIdx == -1) return;
		this.searchCriteria[searchCriteriaIdx].selectedComponent.value = null;
		this.searchCriteria[searchCriteriaIdx].componentSearchText.value = '';
	}

	onSelection(uuid, componentId){
		let searchCriteriaIdx = this.searchCriteria.findIndex((s) => s.uuid == uuid);
		if(searchCriteriaIdx == -1) return;
		let chemicalComponent = this.searchCriteria[searchCriteriaIdx].componentOptions.value.find((c) => c.value == componentId);
		if(chemicalComponent == null) return;
		this.searchCriteria[searchCriteriaIdx].selectedComponent.value = chemicalComponent.value;
		this.searchCriteria[searchCriteriaIdx].componentSearchText.value = chemicalComponent.label;
	}

	addSearchCriteriaEntry(){
		this.searchCriteria.push(this.generateSearchCriteriaEntry());
	}

	freshSearchForIngredients(){
		this.page = 1;
		this.hasMore = true;
		this.searchForIngredients();
	}

	getSearchCriteria(){
		let ingredientSearchCriteria = [];
		for(let searchCriteriaEntry of this.searchCriteria){
			let amount = parseFloat(searchCriteriaEntry.amount.value);
			let componentId = parseInt(searchCriteriaEntry.selectedComponent.value, 10);
			let comparison = searchCriteriaEntry.selectedComparison.value;
			if(isNaN(amount) || isNaN(componentId) || comparison == null) continue;
			ingredientSearchCriteria.push({
				amount: amount,
				component_id: componentId,
				comparison: comparison
			})
		}
		return ingredientSearchCriteria;
	}

	searchForIngredients(append=false){
		let ingredientSearchCriteria = this.getSearchCriteria();

		if(ingredientSearchCriteria.length > 0){
			let payload = {
				search_criteria: ingredientSearchCriteria,
				page: this.page,
				sort_by: this.sortBy
			}
			this.fetching = true;
			this.ingredientApi.getIngredientsForComponents(payload)
				.then((response) => {
					this.showSearchResults = true;
					let ingredients = response.ingredients;
					let that = this;
					let newIngredients = ingredients.map((ingredient) => {
						let searchComponentIds = ingredientSearchCriteria.map((i) => i.component_id);
						let matchingComponents = ingredient.chemical_components.filter((c) => searchComponentIds.includes(c.chemical_component.id));
						extendObservable(ingredient, {
							searchComponents: matchingComponents,
							selectedBlend: new FieldState(null),
							blendSearchText: new FieldState(null),
							volume: new FieldState(0).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n)),
							selectedVolumeMeasurement: new FieldState(this.measurementOptions[0].value).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
							blendOptions: new FieldState([]),
							get canAddToBlend() {
								return this.selectedBlend.value != null && this.volume.value != null && this.volume.value.length > 0 && this.volume.error == null && this.selectedVolumeMeasurement.value != null;
							},
							get isComparing(){
								let compareIngredientIds = that.compareIngredients.map((i) => i.id);
								return compareIngredientIds.includes(this.id);
							}
						})
						return ingredient;
					});
					if(append){
						this.searchIngredients = this.searchIngredients.concat(newIngredients);
					}else{
						this.searchIngredients = newIngredients;
					}
					this.hasMore = newIngredients.length > 0;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}

	@action refreshSearch(){
		this.showSearchResults = false;
		this.page = 1;
		this.hasMore = true;
	}

	@action goToEditIngredient(id){
		window.open(`/ingredients/${id}`, '_blank');
	}

	@computed get measurementOptions(){
		return [
			{
				value: MEASUREMENTS.ml,
				label: this.appStore.i18n.t('user.manage-blend.measurements.ml')
			},
			{
				value: MEASUREMENTS.drops,
				label: this.appStore.i18n.t('user.manage-blend.measurements.drops')
			},
			{
				value: MEASUREMENTS.grams,
				label: this.appStore.i18n.t('user.manage-blend.measurements.grams')
			},
			{
				value: MEASUREMENTS.ounces,
				label: this.appStore.i18n.t('user.manage-blend.measurements.ounces')
			},
			{
				value: MEASUREMENTS.percentage,
				label: this.appStore.i18n.t('user.manage-blend.measurements.percentage')
			}
		]
	}

	@action onBlendSelection(id, blendId){
		let ingredientIdx = this.searchIngredients.findIndex((i) => i.id == id);
		if(ingredientIdx == -1) return;
		let blendOption = this.searchIngredients[ingredientIdx].blendOptions.value.find((b) => b.value == blendId);
		if(blendOption == null) return;
		this.searchIngredients[ingredientIdx].selectedBlend.value = blendOption.value;
		this.searchIngredients[ingredientIdx].blendSearchText.value = blendOption.label;
	}

	@action onChangeBlendSearchText(id, val){
		let ingredientIdx = this.searchIngredients.findIndex((i) => i.id == id);
		if(ingredientIdx == -1) return;
		this.searchIngredients[ingredientIdx].blendSearchText.onChange(val);
		this.fetching = true;

		this.blendApi.getAllBlends(1, this.searchIngredients[ingredientIdx].blendSearchText.value, null, null, null, null, null, null, true)
			.then((response) => {
				let blends = response.blends;
				this.searchIngredients[ingredientIdx].blendOptions.value = blends.map((blend) => {
					return {
						value: blend.id,
						label: blend.name
					}
				})
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action onClearSelectedBlend(id){
		let ingredientIdx = this.searchIngredients.findIndex((i) => i.id == id);
		if(ingredientIdx == -1) return;
		this.searchIngredients[ingredientIdx].selectedBlend.value = null;
		this.searchIngredients[ingredientIdx].blendSearchText.value = '';
	}


	@computed get comparisonOptions(){
		return [
			{
				value: 'gte',
				label: this.appStore.i18n.t('user.component-search.comparison-options.greater-than-or-equal-to')
			},
			{
				value: 'lt',
				label: this.appStore.i18n.t('user.component-search.comparison-options.less-than')
			}
		]
	}

	@action toggleSortDialog(){
		this.showSortDialog = !this.showSortDialog;
	}

	@action setSortByField(val){
		this.page = 1;
		this.sortBy = val;
		this.searchForIngredients();
	}

	@action applySort(){
		this.showSortDialog = false;
	}

	@computed get sortedIngredients(){
		// if(this.sortBy == null || this.sortBy === 'highest'){
		// 	return this.searchIngredients.sort((i1, i2) => {
		// 		if(i1 == null || i2 == null) return 1;
		// 		let maxAmountInIngredient1 = Math.max(i1.searchComponents.map((i) => i.amount));
		// 		let maxAmountInIngredient2 = Math.max(i2.searchComponents.map((i) => i.amount));
		// 		return maxAmountInIngredient1 >= maxAmountInIngredient2 ? -1 : 1;
		// 	})
		// }
		// if(this.sortBy === 'lowest'){
		// 	return this.searchIngredients.sort((i1, i2) => {
		// 		if(i1 == null || i2 == null) return 1;
		// 		let maxAmountInIngredient1 = Math.min(i1.searchComponents.map((i) => i.amount));
		// 		let maxAmountInIngredient2 = Math.min(i2.searchComponents.map((i) => i.amount));
		// 		return maxAmountInIngredient1 < maxAmountInIngredient2 ? -1 : 1;
		// 	})
		// }
		// if(this.sortBy === 'atoz'){
		// 	return this.searchIngredients.sort((i1, i2) => {
		// 		if(i1 == null || i2 == null) return 1;
		// 		return i1.name.toLowerCase() < i2.name.toLowerCase() ? -1 : 1;
		// 	})
		// }
		// if(this.sortBy === 'ztoa'){
		// 	return this.searchIngredients.sort((i1, i2) => {
		// 		if(i1 == null || i2 == null) return 1;
		// 		return i1.name.toLowerCase() >= i2.name.toLowerCase() ? -1 : 1;
		// 	})
		// }
		return this.searchIngredients;
	}

	@action addIngredientToBlend(ingredientId, blendId){
		let ingredientIdx = this.searchIngredients.findIndex((i) => i.id == ingredientId);
		if(ingredientIdx == -1) return;
		let ingredientVolume = parseFloat(this.searchIngredients[ingredientIdx].volume.value);
		if(isNaN(ingredientVolume)) return;
		let ingredientMeasurement = this.searchIngredients[ingredientIdx].selectedVolumeMeasurement.value;
		if(ingredientMeasurement == null) return;
		this.appStore.goToEditBlendWithIngredients(blendId, [{
			'ingredient': this.searchIngredients[ingredientIdx],
			'volume': ingredientVolume.toFixed(2),
			'measurement': ingredientMeasurement
		}]);
	}

	@action addIngredientToNewBlend(ingredientId){
		let ingredientIdx = this.searchIngredients.findIndex((i) => i.id == ingredientId);
		if(ingredientIdx == -1) return;
		let ingredientVolume = parseFloat(this.searchIngredients[ingredientIdx].volume.value);
		if(isNaN(ingredientVolume)){
			ingredientVolume = 10
		}
		let ingredientMeasurement = this.searchIngredients[ingredientIdx].selectedVolumeMeasurement.value;
		if(ingredientMeasurement == null){
			ingredientMeasurement = 'ml'
		};
		this.appStore.goToNewBlendWithIngredients([{
			'ingredient': this.searchIngredients[ingredientIdx],
			'volume': ingredientVolume,
			'measurement': ingredientMeasurement
		}]);
	}

	@computed get sortOptions(){
		if(this.appStore == null) return [];
		return [
			{
				label: this.appStore.i18n.t("user.component-search.sortoptions.highest"),
				value: "highest"
			},
			{
				label: this.appStore.i18n.t("user.component-search.sortoptions.lowest"),
				value: "lowest"
			},
			{
				label: this.appStore.i18n.t("user.component-search.sortoptions.atoz"),
				value: "atoz"
			},
			{
				label: this.appStore.i18n.t("user.component-search.sortoptions.ztoa"),
				value: "ztoa"
			}
		]
	}

	@action toggleIngredientFavourite(id){
		let ingredientIdx = this.compareIngredients.findIndex((c) => c.id == id);
		if(ingredientIdx == -1) return;
		let ingredient = this.compareIngredients[ingredientIdx];
		let newValue = !ingredient.favourited;

		this.fetching = true;
		this.ingredientApi.patchIngredient(id, {
			favourited: newValue
		})	
		.then((response) => {
			let updatedIngredient = response.ingredient;
			this.compareIngredients[ingredientIdx].favourited = updatedIngredient.favourited;
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			this.fetching = false;
		})
	}

	@action toggleCompareIngredient(ingredient){
		let compareIngredient = this.compareIngredients.find((c) => c.id == ingredient.id);
		let isAlreadyCompareIngredient = compareIngredient != null;
		if(isAlreadyCompareIngredient){
			this.compareIngredients = this.compareIngredients.filter((c) => c.id != ingredient.id);
		}else{
			this.compareIngredients.push(ingredient);
		}
	}

	@action removeComparison(id){
		this.compareIngredients = this.compareIngredients.filter((c) => c.id != id);
	}

	@action hideSaveModal(){
		this.showSaveModal = false;
	}

	@action displaySaveModal(){
		this.showSaveModal = true;
	}

	@computed get canSaveReport(){
		return this.compareIngredients.length > 0 && this.name.value?.length > 0;
	}

	saveComparisonReport(){
		this.fetching = true;
		let payload = {
			name: this.name.value,
			comparison_notes: this.notes.value,
			ingredient_ids: this.compareIngredients.map((c) => c.id)
		}
		
		this.ingredientApi.newIngredientComparisonReport(payload)
			.then((response) => {
				this.appStore.displaySaveDialog();
				this.appStore.goToCompareIngredients();
				this.showSaveModal = false;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	loadNextPage(){
		this.page += 1;
		this.searchForIngredients(true);
	}

	@computed get searchCriteriaLength(){
		return this.searchCriteria.filter((s) => s.selectedComponent.value != null && s.selectedComparison.value != null).length;
	}

	@computed get canSaveSearch(){
		return this.searchCriteriaLength > 0 && this.reportName.value?.length > 0;
	}

	@action hideSaveSearchModal(){
		this.showSaveSearchModal = false;
	}

	@action displaySaveSearchModal(){
		this.showSaveSearchModal = true;
	}

	@computed get components(){
		let compareOptions = this.comparisonOptions;
		if(this.searchCriteria == null) return [];
		let searchComponents = this.searchCriteria.filter((x) => x.selectedComponent.value != null && x.selectedComparison.value != null).map((s) => {
			let comparison = compareOptions.find((m) => m.value == s.selectedComparison.value);
			if(comparison != null){
				let compareSign = comparison.value === 'gte' ? '>=' : '<';
				return {
					id:  parseInt(s.selectedComponent.value, 10),
					name: s.componentSearchText.value,
					description: `${compareSign} ${s.amount.value} ${this.appStore.i18n.t('user.component-search.percentage-of')}`
				}
			}
		});
		return searchComponents;
	}

	formSync(componentSearchId){
		this.ingredientApi.getComponentSearchReportById(componentSearchId)
			.then((response) => {
				let componentSearchReport = response.component_search_report;
				this.id = componentSearchReport.id;

				this.reportName.value = componentSearchReport.name;
				this.searchNotes.value = componentSearchReport.notes;
				this.searchCriteria = componentSearchReport.search_criteria.map((sc) => this.toSearchCriteriaEntry(sc));

				this.searchForIngredients();
			})
			.catch((error) => {
				console.log(error);
			})
	}

	saveComponentSearch(){
		let searchCriteria = this.getSearchCriteria();
		if(searchCriteria.length == 0) return;

		this.fetching = true;
		let payload = {
			name: this.reportName.value,
			notes: this.searchNotes.value,
			search_criteria: searchCriteria
		}

		if(this.id != null){
			this.ingredientApi.updateComponentSearch(this.id, payload)
				.then((response) => {
					this.appStore.displaySaveDialog();
					this.appStore.goToComponentSearchList();
					this.showSaveSearchModal = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}else{
			this.ingredientApi.saveComponentSearch(payload)
				.then((response) => {
					this.appStore.displaySaveDialog();
					this.appStore.goToComponentSearchList();
					this.showSaveSearchModal = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}

		
	}
}

export default ComponentSearchStore;