import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';

import {ArrowDownIcon, DownloadIcon, EyeIcon, ExitIcon, CameraIcon, HelpIcon, DeleteIcon, SearchIcon} from './icon';

import {ExtraSmallBody, TertiaryAppExtraSmallTitle, TertiaryAppSmallTitle, SmallBody} from './text'

import { DateRangePicker } from 'react-dates';
import {START_DATE} from 'react-dates/constants'

import moment from 'moment';

import {IMAGE_FILE_TYPES} from '../../services/util';

import defaultAvatarUrl from '../../assets/img/icons/edit-ingredient.svg'
import Switch from "react-switch";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


const colors = require('../../color.js');


@observer
class TextInput extends Component{
	render(){
		let inputClassName = "base-input regular-font block placeholder-black mt-2";
		let errorAndValue = this.props.error;
		if(errorAndValue){
			inputClassName = `${inputClassName} border border-red-700`;
		}
		if('extraInputClassNames' in this.props){
			inputClassName = `${inputClassName} ${this.props.extraInputClassNames}`;
		}


		return (
			<div className="flex flex-1 flex-col relative">
				{'insetoption' in this.props && 
					<span className="absolute z-50 cursor-pointer text-black inset-y-0 right-0 flex items-center mr-4">
						{this.props.insetoption}
					</span>
				}
				<input 
					className={inputClassName} 
					type={this.props.type ? this.props.type : "text"} 
					value={this.props.value}
					onChange={this.props.onChange} 
					{...this.props} />

				{errorAndValue && <span className="text-error block mb-2 px-2">{this.props.error}</span>}
			</div>
		)
	}
}

@observer
class PasswordInput extends Component{
	render(){
		return <TextInput type="password" {...this.props} />
	}
}

@observer
class TextArea extends Component{
	render(){
		let inputClassName = "base-input border border-black mt-2 outline-none focus:outline-none";
		let errorAndValue = this.props.error;
		if(errorAndValue){
			inputClassName = `${inputClassName} border border-red-700`;
		}

		if(this.props.className){
			inputClassName = `${inputClassName} ${this.props.className}`
		}


		return (
			<div className="flex flex-1 flex-col">
				<div className="flex flex-1">
					<textarea 
						className={inputClassName} type="text" {...this.props}></textarea>
				</div>
				{errorAndValue && 
					<div className="flex">
						<span className="text-error block mb-2 px-2">{this.props.error}</span>
					</div>
				}
			</div>
		)
	}
}

@observer
class CheckBox extends Component{
	render(){
		let inputClassName = "form-checkbox ml-2 outline-none focus:outline-none";
		if('extraInputClassNames' in this.props){
			inputClassName = `${inputClassName} ${this.props.extraInputClassNames}`;
		}
		return <input 
					type="checkbox" 
					className={inputClassName} 
					checked={this.props.checked}
					onChange={this.props.onChange} />
	}
}

@observer
class RadioButton extends Component{
	render(){
		let inputClassName = "form-radio  ml-2 outline-none focus:outline-none";
		let style = null;
		if('textColor' in this.props){
			style = {
				color: this.props.textColor
			}
		}
		if('extraInputClassNames' in this.props){
			inputClassName = `${inputClassName} ${this.props.extraInputClassNames}`;
		}
		return <input 
					type="radio" 
					style={style}
					className={inputClassName} 
					checked={this.props.checked}
					onChange={this.props.onChange}
					{...this.props}

					 />
	}
}

@observer
class Select extends Component{
	constructor(props){
		super(props);
		this.toggleDropdown = this.toggleDropdown.bind(this);
		this.onTriggerChange = this.onTriggerChange.bind(this);
		this.onClear = this.onClear.bind(this);
		this.state = {
			showDropdown: false
		}
	}

	onTriggerChange(option){
		if('onChange' in this.props){
			this.props.onChange(option);
		}
		this.toggleDropdown();
	}

	onClear(e){
		e.stopPropagation();
		if('onChange' in this.props){
			this.props.onChange({
				value: null
			});
		}
	}

	toggleDropdown(e){
		this.setState({
			showDropdown: !this.state.showDropdown
		})
	}

	render(){
		let color = 'color' in this.props ? this.props.color : 'orange';
		let textColor = 'textColor' in this.props ? this.props.textColor : color;
		let bgColor = 'bgColor' in this.props ? this.props.bgColor : 'white';
		let iconColor = 'iconColor' in this.props ? this.props.iconColor : colors['nav-inactive-color'];
		let borderColor = 'borderColor' in this.props ? this.props.borderColor : colors['nav-inactive-color'];


		let currentValue = null;
		if('options' in this.props && this.props.options.length > 0){
			if(this.props.value){
				let matchingEntry = this.props.options.find((x) => x.value == this.props.value);
				if(matchingEntry){
					currentValue = matchingEntry.label;
				}
			}
		}
		let inputClassName= `base-input flex flex-1 w-full block no-outline cursor-pointer bg-${bgColor} text-${textColor} pl-2 pr-8 z-30 border-${borderColor}`;
		
		if(this.props.uppercase){
			inputClassName = `${inputClassName} uppercase`;
		}
		if(!this.state.showDropdown){
			inputClassName = `${inputClassName}`;
		}

		if(this.props.error){
			inputClassName = `${inputClassName} border border-red-700`;
		}

		if(this.props.className){
			inputClassName = `${inputClassName} ${this.props.className}`;
		}

		let optionClassName = `cursor-pointer text-left text-normal px-2 py-1`;
		if(this.props.uppercase){
			optionClassName = `${optionClassName} uppercase`;
		}


		return (
			<div className="flex flex-1 flex-col">
				<div className="flex flex-1 relative mt-2 inline-block w-auto h-auto">
					{!this.state.showDropdown && <div className={`z-40 pointer-events-none absolute inset-y-0 right-0 pb-2 mr-2 flex items-center text-${color}`}>
					  	<ArrowDownIcon color={iconColor} className="w-4 h-4" />
					  </div>
					}


					<div 
						className={inputClassName}
						onClick={this.toggleDropdown}
					>
						{currentValue != null ? (
							<>
								{this.props.clearable && 
									<div className={`flex items-center mr-2 cursor-pointer text-${color}`} onClick={this.onClear}>
									  	<ExitIcon color="black" className="w-4 h-4" />
									  </div>
								}
								{currentValue}
							</>) : (<span className="text-list-text">{this.props.placeholder}</span>)
						}
					</div>
					{this.state.showDropdown && 
						<div style={{ minWidth: '8rem' }} className={`absolute z-50 select-max-height mt-12 py-2 px-4 w-auto overflow-scroll rounded-lg border border-${borderColor} right-0 top-0 bg-white`}>
							{this.props.options.map((option) => 
								<div key={option.value} onClick={this.onTriggerChange.bind(this, option)}
									className={optionClassName}><SmallBody>{option.label}</SmallBody></div>
							)}
						</div>
					}
					

				</div>
				{this.props.error != null && 
					<div className="flex block text-error block mb-2 px-2">{this.props.error}</div>
				}
			</div>
		)
	}
}

@observer
class ToolTip extends Component{
	render(){
		return (
			<div className="absolute h-8 w-16 top-0 right-0 h-auto mt-6 py-2 px-2 rounded-lg w-auto bg-tooltip-bg text-white z-50" style={{ width: '200px'}}>
				<div style={{
					position: 'absolute',
					right:0,
					top:0,
					marginTop:'-7px',
					marginRight: '0px',
					height:0, 
					width:0,
					borderLeft: '8px solid transparent', 
					borderRight: '8px solid transparent', 
					borderBottom: `16px solid ${colors['tooltip-bg']}`
				}}></div>
				<div className="flex flex-1 flex-col px-2 py-2">
					{this.props.title != null && <TertiaryAppExtraSmallTitle className="mt-1">{this.props.title}</TertiaryAppExtraSmallTitle>}
					<ExtraSmallBody className="mt-1 text-flat-gray">{this.props.text}</ExtraSmallBody>
				</div>
			</div>
		)
	}
}

@observer
class ToolTipItem extends Component{
	constructor(props){
		super(props);
		this.state = {
			hovering: false
		}
	}


	render(){
		return (
			<div className="flex flex-1 relative ml-2" 
				onMouseEnter={(e) => this.setState({hovering: true})}
				onMouseLeave={(e) => this.setState({hovering: false})} 
				onClick={this.props.onClick}>
				<>
					{this.props.icon}
					
					{this.state.hovering && 
						<ToolTip
							title={this.props.title}
							text={this.props.text} 
						/>
					}
				</>
			</div>
		)
	}
}

@observer 
class HelpTooltip extends Component{
	render(){
		return (
			<div className="flex">
				<ToolTipItem
				 	text={this.props.text}
				 	icon={<HelpIcon className="w-4 h-4" color={colors['black-30']} />}
				 />
			</div>
		)
	}
}

@observer
class RangeOption extends Component{
	render(){
		let isActive = 'active' in this.props ? this.props.active : false;
		let bgColor = isActive ? 'range-option-active-bg-color' : 'range-option-inactive-bg-color';
		let fgColor = isActive ? 'range-option-active-fg-color' : 'range-option-inactive-fg-color';
		return (
			<div className={`flex flex-1 bg-${bgColor} cursor-pointer rounded-xl w-auto h-auto ${this.props.margin}`} onClick={this.props.onClick}>
				<ExtraSmallBody className={`flex justify-center semibold-font text-${fgColor} items-center w-16 h-6`}>{this.props.title}</ExtraSmallBody>
			</div>
		)
	}
}

@observer
class DateRange extends Component{
	constructor(props){
		super(props);
		this.togglePicker = this.togglePicker.bind(this);
		this.apply = this.apply.bind(this);
		this.on7Days = this.on7Days.bind(this);
		this.on30Days = this.on30Days.bind(this);
		this.on90Days = this.on90Days.bind(this);
		this.openDatePicker = this.openDatePicker.bind(this);
		this.state = {
			showPicker: false,
			currentOption: 1,
			focusedInput: null	
		}
	}

	togglePicker(){
		this.setState({
			showPicker: !this.state.showPicker
		})
	}

	apply(){
		this.props.onApply();
		this.togglePicker();
	}

	on7Days(){
		let today = moment();
		let sevenDaysAgo = moment().subtract(7, 'days');
		this.setState({
			currentOption: 0
		})
		this.props.onDateChange({
			startDate:sevenDaysAgo, 
			endDate:today
		});
	}

	on30Days(){
		let today = moment();
		let thirtyDaysAgo = moment().subtract(30, 'days');
		this.setState({
			currentOption: 1
		})
		this.props.onDateChange({
			startDate: thirtyDaysAgo,
			endDate: today
		});
	}

	on90Days(){
		let today = moment();
		let ninetyDaysAgo = moment().subtract(90, 'days');
		this.setState({
			currentOption: 2
		})
		this.props.onDateChange({
			startDate:ninetyDaysAgo, 
			endDate:today
		});
	}

	openDatePicker(){
		this.setState({
			focusedInput: START_DATE,
			currentOption: 3
		})
	}

	render(){
		let translate = this.props.t;
		if(this.props.startDate == null || this.props.endDate == null) return null;
		let startDateEndDateDescription = `${this.props.startDate.format('ll')} - ${this.props.endDate.format('ll')}`;
		return (
			<div className="flex h-8 flex-col relative">
				<div className="flex flex-row items-center border-b border-black-10 py-1" onClick={this.togglePicker}>
					<div className="flex"><SmallBody>{startDateEndDateDescription}</SmallBody></div>
					<div className="flex ml-2"><ArrowDownIcon className="w-2 h-2" color={colors['list-text']} /></div>
				</div>
				{this.state.showPicker &&
					<div className="absolute z-40 w-auto bg-white top-0 mt-8 py-4 px-4 border border-black-10 full-shadow right-0 rounded-lg border-black">
					<div className="flex"><ExtraSmallBody>{translate('common.drp.title')}</ExtraSmallBody></div>
					<div className="flex flex-1 flex-row items-center mt-2">
						<RangeOption 
							active={this.state.currentOption == 0} 
							title={translate('common.drp.7days')}
							onClick={this.on7Days}
							 />
						<RangeOption active={this.state.currentOption == 1}  title={translate('common.drp.30days')} margin="ml-2" onClick={this.on30Days} />
						<RangeOption active={this.state.currentOption == 2} title={translate('common.drp.90days')} margin="ml-2" onClick={this.on90Days} />
						<RangeOption active={this.state.currentOption == 3} title={translate('common.drp.custom')} margin="ml-2" onClick={this.openDatePicker} />
					</div>
					<div className="flex flex-col mt-4">
						<div className="flex"><ExtraSmallBody>{translate('common.drp.range')}</ExtraSmallBody></div>
						<div className="flex w-full mt-2">
							<DateRangePicker
							  small
							  noBorder
							  numberOfMonths={1}
							  hideKeyboardShortcutsPanel
							  startDate={this.props.tempStartDate}
							  startDateId="drp_start_date_id"
							  endDate={this.props.tempEndDate}
							  endDateId="drp_end_date_id" // PropTypes.string.isRequired,
							  onDatesChange={this.props.onDateChange}
							  focusedInput={this.state.focusedInput}
							  onFocusChange={focusedInput => this.setState({ focusedInput })}
							  isOutsideRange={day => false}
							/>
						</div>
						<div className="flex flex-row justify-end mr-2 mt-2" onClick={this.apply}>
							<span className="text-sm semibold-font text-petroleum-blue mt-2 cursor-pointer">{translate('common.apply')}</span>
						</div>
					</div>
					</div>
				}
			</div>
		)
	}
}

@inject('appStore')
@observer
class ImageAvatar extends Component{
	constructor(props){
		super(props);
		this.renderAvatar = this.renderAvatar.bind(this);
		this.onClickImage = this.onClickImage.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);
		this.fileUploadRef = React.createRef();
	}

	onClickImage(){
		if(this.props.disabled) return;
		this.fileUploadRef.current.click();
	}

	onChangeFile(e){
		let translate = this.props.t;
		let files = [...e.target.files];
		let validFiles = files.filter((f) => IMAGE_FILE_TYPES.includes(f.type));
		if(validFiles.length == 0){
			this.props.appStore.alertError(translate('error.valid-image-format-message'));
		}else if(validFiles.length > 1){
			this.props.appStore.alertError(translate('error.one-file-only-message'));
		}else{

			let currentFile = validFiles[0];
			var sizeInMB = (currentFile.size / (1024*1024)).toFixed(2);
			let maxFileSize = 'maxFileSize' in this.props ? this.props.maxFileSize : 2;
			if(sizeInMB > maxFileSize){
				let exceededMaxFileSizeMessage = `${translate('error.max-file-size')} ${maxFileSize}MB`;
				this.props.appStore.alertError(exceededMaxFileSizeMessage);
			}else{
				this.props.onChangeFile(files[0]);
			}
		}
	}


	renderAvatar(){
		let appStore = this.props.appStore;
		let border = this.props.noborder ? null : `1px solid ${colors['list-text']}`;
		let hasAvatar = this.props.url != null;
		let backupAvatarUrl = this.props.backupAvatarUrl != null ? this.props.backupAvatarUrl : defaultAvatarUrl;
		return (
			<Fragment>
					<img 
						style={{
							position: 'absolute',
							width: '100%',
							borderRadius: '50%',
							backgroundColor: 'white',
							border: border,
							height: '100%',
							objectFit: 'cover',
							alignSelf: 'center',
							imageOrientation: 'from-image',
						}}
						src={this.props.url != null ? this.props.url : backupAvatarUrl} 
						alt="Photo" />
			</Fragment>
			
		)
	}

	render(){
		let translate = this.props.t;
		let appStore = this.props.appStore;
		let width = 'width' in this.props ? this.props.width : 'w-32';
		let height = 'height' in this.props ? this.props.height : 'h-32';
		let avatarClassName = `flex ${width} ${height} relative cursor-pointer items-center justify-center rounded-full bg-candy-pink`;
		return (
			<div className={avatarClassName} onClick={this.onClickImage}>
				{this.renderAvatar()}
				{!this.props.disabled && 
					<>
						<input type="file" 
							ref={this.fileUploadRef} 
							onChange={this.onChangeFile} 
							style={{ display: 'none' }} 
						/>
						<div className="flex justify-center items-center absolute w-8 h-8 rounded-full bg-pink-coral bottom-0 right-0 mb-2">
							<CameraIcon className="w-3/5 h-3/5" color="white" />
						</div>
					</>
				}
			</div>
		)
	}
}

@observer
class SearchBar extends Component{
	constructor(props){
		super(props);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.onSearch = this.onSearch.bind(this);
	}

	handleKeyDown(event){
		switch (event.key) {
	      case 'Enter':
	      case 'Tab':
	        this.props.onTriggerSearch();
	        event.preventDefault();
	    }
	}

	onSearch(){
		if(this.props.value?.length > 0){
			this.props.onTriggerSearch();
		}
	}

	render(){
		let translate = this.props.t;
		if(translate == null) return null;
		let placeholder = 'placeholder' in this.props ? this.props.placeholder : translate('common.search');
		let searchClassName = "border-0 rounded-md text-sm text-nav-inactive-color placeholder-nav-inactive-color";
		if(this.props.extraInputClassNames){
			searchClassName = `${searchClassName} ${this.props.extraInputClassNames}`
		}
		return (
			<div className="flex flex-row items-center flex-1 mr-4">		
				<TextInput 
					value={this.props.value}
					onKeyDown={this.handleKeyDown}
					onChange={this.props.onChange}
					insetoption={
						<div onClick={this.onSearch}>
							<SearchIcon className="w-3 h-3 mb-1" />
						</div>
					} 
					extraInputClassNames={searchClassName}
					placeholder={placeholder} />
			</div>
		)
	}
}


@observer
class ToggleSwitch extends Component{
	constructor(props){
		super(props);
		this.onToggleChange = this.onToggleChange.bind(this);
	}

	onToggleChange(checked, e){
		this.props.onChange(e, checked);
	}

	render(){
		return (
			<div onClick={e => e.stopPropagation()}>
				<Switch 
					onChange={this.onToggleChange} 
					checked={this.props.checked}
					uncheckedIcon={false}
					height={20}
					width={40}
					checkedIcon={false}
					offColor="#fff"
					offHandleColor={colors['pink-coral'].hex}
					onColor="#fff"
					activeBoxShadow="0 0 2px 3px #eee"
					className={this.props.checked ? "border-mint-green border" : "border-pink-coral border"}
					onHandleColor={colors['mint-green'].hex}
				/>
			</div>
		)
	}
}


@observer
class SliderInput extends Component{
	render(){
		let bgColor = 'bgColor' in this.props ? this.props.bgColor : colors['flat-green'].default;
		return (
			<div className="flex flex-1 flex-col flex-row">
				<div className="flex w-full">
					<Slider 
						value={this.props.value}
						onChange={this.props.onChange}
						onAfterChange={this.props.onAfterChange}
						min={this.props.min}
						max={this.props.max}
						step={this.props.step}
						disabled={this.props.disabled}
						handleStyle={[{
							backgroundColor: "white",
							border: `solid 1px ${colors['black-10']}`,
							borderTopLeftRadius: "25%",
							borderTopRightRadius: "25%",
							borderBottomLeftRadius: "25%",
							borderBottomRightRadius: "25%",
							width: "9px",
							height: "20px",
							marginTop: "-5px"
						}]}
						trackStyle={[{
							backgroundColor: bgColor,
							height: "10px"
						}]}
						railStyle={{
							backgroundColor: colors['black-10'],
							height: "10px"
						}}
					 />
				</div>
				{this.props.showLabels && 
					<div className="flex w-full justify-between mt-2">
						<ExtraSmallBody>{this.props.startLabel}</ExtraSmallBody>
						<ExtraSmallBody>{this.props.endLabel}</ExtraSmallBody>
					</div>
				}
			</div>
		)
	}
}

@observer
class FileEntry extends Component{
	render(){
		let file = this.props.file;
		let editMode = 'editMode' in this.props ? this.props.editMode : false;
		return (
			<div className="flex flex-1 flex-row items-center justify-between mt-2">
				<div className="flex flex-1">
					{file.name}
				</div>
				<div className="flex flex-row items-center justify-end">
					<div className="flex ml-2"><EyeIcon className="w-4 h-4" color={colors['black']} onClick={() => window.open(file.id == null ? URL.createObjectURL(file.file) : window.open(file.file_url))} /></div>
					{editMode && <div className="flex ml-2"><DeleteIcon className="w-4 h-4" color={colors['error']} onClick={() => this.props.onDelete(file.id)} /></div>}
				</div>
			</div>
		)
	}
}


export {
	TextInput,
	PasswordInput,
	TextArea,
	CheckBox,
	RadioButton,
	Select,
	ToolTip,
	RangeOption, 
	DateRange,
	ImageAvatar,
	SearchBar,
	ToolTipItem,
	HelpTooltip,
	ToggleSwitch,
	SliderInput,
	FileEntry
}