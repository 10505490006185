import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {SecondaryAppMediumTitle, TertiaryAppSmallTitle} from '../../common/text'
import { withAuthorisation } from '../../common/authorisation';

import {Button} from '../../common/button';

import {EnvelopeIcon} from '../../common/icon';


import Main from '../../elements/main';
import {Grid} from '../../elements/grid';

import {CosmeticRegulationPermission, ChemistryPermission, LanguagesPermission, UsersPermission} from '../../elements/permission';
import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, USERS } from '../../../services/util';

import {
	DataHeader,
	DataRow,
	FormInput,
	FormTextArea,
	FormSelect
} from '../../elements/form';


@inject('inviteUserStore')
@observer
class InviteUser extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let inviteUserStore = this.props.inviteUserStore;
		inviteUserStore.fetchPermissions();
	}

	componentWillUnmount(){
		let inviteUserStore = this.props.inviteUserStore;
		inviteUserStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let inviteUserStore = this.props.inviteUserStore;
		return (
			<Main>
				<div className="flex flex-1 flex-col"> 
					<div className="flex w-full justify-end mt-8">
						<Button
							width="w-auto"
							height="h-12"
							className="px-4 rounded-lg"
							bgColor="pink-coral"
							fetching={inviteUserStore.fetching}
							disabled={!inviteUserStore.validForm}
							onClick={() => inviteUserStore.invite()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{translate('invite-user.invite-button')} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><EnvelopeIcon className="w-4 h-4" color="white" /></div>
							</div>
						</Button>
					</div>
					<Grid className="mt-4">
						<SecondaryAppMediumTitle>{translate('invite-user.title')}</SecondaryAppMediumTitle>

						<div className="flex flex-1 flex-col">
							<div className="flex flex-1 flex-col">
								<div className="flex flex-1 flex-row flex-wrap">
									<DataRow label={translate('invite-user.name.label')}>
										<FormInput 
											placeholder={translate('invite-user.name.placeholder')}
											value={inviteUserStore.userData.name.value}
											error={inviteUserStore.userData.name.error}
											onChange={(e) => inviteUserStore.userData.name.onChange(e.target.value)}
										/>
									</DataRow>
									<div className="mt-4 lg:mt-0 ml-0 lg:ml-8"></div>
									<DataRow label={translate('invite-user.email.label')}>
										<FormInput 
											placeholder={translate('invite-user.email.placeholder')}
											value={inviteUserStore.userData.email.value}
											error={inviteUserStore.userData.email.error}
											onChange={(e) => inviteUserStore.userData.email.onChange(e.target.value)}
										/>
									</DataRow>
									<div className="mt-4 lg:mt-0 ml-0 lg:ml-8"></div>
									<DataRow label={translate('invite-user.discount-code.label')}>
										<FormInput 
											placeholder={translate('invite-user.discount-code.placeholder')}
											value={inviteUserStore.userData.discountCode.value}
											error={inviteUserStore.userData.discountCode.error}
											onChange={(e) => inviteUserStore.userData.discountCode.onChange(e.target.value)}
										/>
									</DataRow>
								</div>
								<div className="flex flex-1">
									<DataRow label={translate('invite-user.message.label')}>
										<FormTextArea
											placeholder={translate('invite-user.message.placeholder')}
											value={inviteUserStore.userData.message.value}
											error={inviteUserStore.userData.message.error}
											onChange={(e) => inviteUserStore.userData.message.onChange(e.target.value)}
										/>
									</DataRow>
								</div>

								{inviteUserStore.appStore.isSuperAdministrator && 
									<div className="flex flex-1 flex-row mt-4">
										<div className="flex w-1/3">
											<DataRow label={translate('invite-user.language.label')}>
												<FormSelect 
													placeholder={translate('invite-user.language.placeholder')}
													options={inviteUserStore.appStore.languageOptions}
													value={inviteUserStore.userData.selectedLanguage.value}
													onChange={(option) => inviteUserStore.userData.selectedLanguage.onChange(option.value)}
												/>
											</DataRow>
										</div>
										<div className="ml-0 lg:ml-8"></div>
										<div className="flex flex-1 flex-col">
											<DataHeader noborder>{translate('invite-user.access.label')}</DataHeader>
											<div className="flex flex-1 grid grid-cols-4 gap-4">
												<CosmeticRegulationPermission 
													checked={inviteUserStore.hasCosmeticRegulationPermission}
													onChange={(e) => inviteUserStore.setCosmeticRegulationPermission(e.target.checked)}
													 />
												<ChemistryPermission checked={inviteUserStore.hasChemistryPermission} 
													onChange={(e) => inviteUserStore.setChemistryPermission(e.target.checked)}/>
												<LanguagesPermission checked={inviteUserStore.hasLanguagesPermission}
													 onChange={(e) => inviteUserStore.setLanguagesPermission(e.target.checked)}/>
												<UsersPermission checked={inviteUserStore.hasUsersPermission}
													 onChange={(e) => inviteUserStore.setUsersPermission(e.target.checked)}/>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</Grid>
				</div>
			</Main>
		)
	}
}
export default withTranslation()(withAuthorisation(InviteUser, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR], [USERS]));