import { observable, action, computed } from 'mobx';

import OrganisationApi from '../../../services/organisation';

class OrganisationStore{

	@observable organisations;
	@observable page;
	@observable total;
	@observable has_next;
	@observable has_prev;
	@observable offset;
	@observable limit;

	@observable showSortDialog;
	@observable sortBy;

	@observable showEditTooltip;
	@observable showArchiveTooltip;

	@observable fetching;

	constructor(appStore){
		this.appStore = appStore;
		this.organisationApi = new OrganisationApi(this.appStore);
		this.initStore();
	}

	initStore(){
		this.organisations = [];
		this.page = 1;
		this.total = 0;
		this.offset = null;
		this.limit = null;
		this.showSortDialog = false;
		this.sortBy = null;
		this.showEditTooltip = null;
		this.showArchiveTooltip = null;
		this.fetching = false;
	}

	fetchOrganisations(){
		this.fetching = true;
		this.organisationApi.getAll(this.page, this.sortBy)
			.then((response) => {
				this.organisations = response.organisations;
				this.limit = response.limit;
				this.page = response.page;
				this.total = response.total;
				this.offset = response.offset;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	onNextPage(){
		this.page += 1;
		this.fetchOrganisations();
	}

	onPreviousPage(){
		this.page = Math.max(this.page - 1, 0);

		this.fetchOrganisations();
	}

	onPage(page){
		this.page = page;
		this.fetchOrganisations();
	}

	@computed get hasNext(){
		if(this.total == null || this.page == null || this.limit == null) return false;
		return (this.page * this.limit) < this.total
	}

	@computed get hasPrev(){
		if(this.limit == null || this.offset == null) return false;
		return this.offset >= this.limit;
	}

	@computed get pages(){
		if(this.organisations.length == 0 || this.total == null) return [];
		
		let noOfPages = Math.floor(this.total / this.limit);
		let pages = []
		for(let i = 0; i < noOfPages + 1; i++){
			pages.push(i+1)
		}
		return pages;
	}

	onEditOrganisation(id){
		this.appStore.goToEditOrganisation(id);
	}

	@action toggleSortDialog(){
		this.showSortDialog = !this.showSortDialog;
	}

	@action setSortByField(sortBy){
		if(this.sortBy == sortBy){
			this.sortBy = null;
		}else{
			this.sortBy = sortBy;
		}
	}

	@action applySort(){
		this.fetchOrganisations();
		this.showSortDialog = false;
	}

	@action goToInviteNewOrganisation(){
		this.appStore.goToInviteNewOrganisation();
	}

	@action setEditTooltip(val){
		this.showEditTooltip = val;
	}

	@action setArchiveTooltip(val){
		this.showArchiveTooltip = val;
	}

	@action onPromptArchive(id){
		let confirmed = window.confirm('Are you sure you want to archive this organisation?');
		if(confirmed){
			this.organisationApi.archive(id)
				.then((response) => {
					let updatedOrganisation = response;
					let organisation = this.organisations.find((o) => o.id == updatedOrganisation.id);
					if(organisation != null){
						organisation.archived = updatedOrganisation.archived;
					}
				})	
				.catch((error) => {
					console.log(error);
				})
		}
	}

	@action onPromptRestore(id){
		let confirmed = window.confirm('Are you sure you want to restore this organisation?')
		if(confirmed){
			this.organisationApi.restore(id)
				.then((response) => {
					let updatedOrganisation = response;
					let organisation = this.organisations.find((o) => o.id == updatedOrganisation.id);
					if(organisation != null){
						organisation.archived = updatedOrganisation.archived;
					}
				})	
				.catch((error) => {
					console.log(error);
				})
		}
	}

	@computed get sortOptions(){
		if(this.appStore == null) return [];
		return [
			{
				label: this.appStore.i18n.t("superadmin.organisations.sortoptions.id"),
				value: "id"
			},
			{
				label: this.appStore.i18n.t("superadmin.organisations.sortoptions.name"),
				value: "name"
			},
			{
				label: this.appStore.i18n.t("superadmin.organisations.sortoptions.status"),
				value: "status"
			}
		]
	}
}

export default OrganisationStore;