import BaseApi from '../services/base';

class IngredientApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAllIngredients(page, searchText=null, sortBy=null, filterBy=null, ingredientTypeId=null, favourites=null, archived=null, show_public=null){
		let queryUrl = `v1/ingredients/search?page=${page}`
		let payload = {
			page: page,
			search_text: searchText,
			sort_by: sortBy,
			filter_by: filterBy,
			ingredient_type_id: ingredientTypeId,
			favourites: favourites,
			archived: archived,
			public: show_public
		}

		return this.fetch(queryUrl, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getIngredients = async (searchText=null, sortBy=null, filterBy=null, ingredientTypeId=null, favourites=false, archived=null, show_public=null) => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAllIngredients(page, searchText, sortBy, filterBy, ingredientTypeId, favourites, archived, show_public);
			let data = response.ingredients;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}

	getAllIngredientTypes(){
		return this.fetch(`v1/ingredients/types`, {
			method: 'GET',
			auth: true
		})
	}

	getAllConservationStatuses(){
		return this.fetch(`v1/ingredients/conservation-statuses`, {
			method: 'GET',
			auth: true
		})
	}

	getAllScentNotes(){
		return this.fetch(`v1/ingredients/scent-notes`, {
			method: 'GET',
			auth: true
		})
	}

	getAllPsychologicalRankings(){
		return this.fetch(`v1/ingredients/psychological-rankings`, {
			method: 'GET',
			auth: true
		})
	}

	getAllPhysicalRankings(){
		return this.fetch(`v1/ingredients/physical-rankings`, {
			method: 'GET',
			auth: true
		})
	}

	newIngredient(payload){
		return this.fetch(`v1/ingredients`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	updateIngredient(id, payload){
		return this.fetch(`v1/ingredients/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	patchIngredient(id, payload){
		return this.fetch(`v1/ingredients/${id}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getIngredientById(ingredientId){
		return this.fetch(`v1/ingredients/${ingredientId}`, {
			method: 'GET',
			auth: true
		})
	}

	getAllIngredientByIds = async(ingredientIds) => {
		let allIngredients = [];
		for(let ingredientId of ingredientIds){
			let response = await this.getIngredientById(ingredientId);
			allIngredients.push(response.ingredient);
		}
		return allIngredients
	}

	deleteIngredientFile(ingredientFileId){
		return this.fetch(`v1/ingredients/files/${ingredientFileId}`, {
			method: 'DELETE',
			auth: true
		})
	}

	addIngredientTag(ingredientId, tag){
		return this.fetch(`v1/ingredients/${ingredientId}/tags`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify({
				tag: tag
			})
		})
	}

	updateIngredientTag(ingredientId, tagIds){
		return this.fetch(`v1/ingredients/${ingredientId}/tags`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify({
				tag_ids: tagIds
			})
		})
	}

	duplicateIngredient(ingredientId){
		return this.fetch(`v1/ingredients/${ingredientId}/duplicate`, {
			method: 'POST',
			auth: true
		})
	}

	getAllComparisonReports(page, searchText=null){
		let queryUrl = `v1/ingredients/comparisons?page=${page}`
		if(searchText != null){
			queryUrl = `${queryUrl}&search_text=${searchText}`
		}

		return this.fetch(queryUrl, {
			method: 'GET',
			auth: true
		})
	}

	getComparisonReportById(id){
		return this.fetch(`v1/ingredients/comparisons/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	newIngredientComparisonReport(payload){
		return this.fetch(`v1/ingredients/comparisons`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	updateIngredientComparisonReportById(id, payload){
		return this.fetch(`v1/ingredients/comparisons/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	deleteComparisonReportById(id){
		return this.fetch(`v1/ingredients/comparisons/${id}`, {
			method: 'DELETE',
			auth: true
		})
	}

	getIngredientsForComponents(payload){
		return this.fetch(`v1/ingredients/components/search`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getAllComponentSearchReports(page, searchText=null){
		let queryUrl = `v1/ingredients/components/search/reports?page=${page}`
		if(searchText != null){
			queryUrl = `${queryUrl}&search_text=${searchText}`
		}

		return this.fetch(queryUrl, {
			method: 'GET',
			auth: true
		})
	}

	saveComponentSearch(payload){
		return this.fetch(`v1/ingredients/components/search/reports`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	updateComponentSearch(id, payload){
		return this.fetch(`v1/ingredients/components/search/reports/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		});
	}

	deleteComponentSearchReportById(id){
		return this.fetch(`v1/ingredients/components/search/reports/${id}`, {
			method: 'DELETE',
			auth: true
		});
	}

	getComponentSearchReportById(id){
		return this.fetch(`v1/ingredients/components/search/reports/${id}`, {
			method: 'GET',
			auth: true
		});
	}
}

export default IngredientApi;