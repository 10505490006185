import React, {Component} from 'react';

import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';
import Main from '../../../elements/main';
import { SmallBody, Body, SecondaryAppLargeTitle, TertiaryAppSmallTitle, SecondaryAppMediumTitle} from '../../../common/text';
import { TestTubeIcon, ArrowRightIcon, PlusIcon, SaveIcon, EditIcon} from '../../../common/icon'
import {Button} from '../../../common/button';
import {SortDialog, FilterField, FilterDialog, SortingField} from '../../../elements/list';
import {FullGrid} from '../../../elements/grid';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormTextArea
} from '../../../elements/form';
import ColorPicker from '../../../common/color-picker';

import {TagInput} from '../../../common/tag';

const colors = require('../../../../color.js');

@inject('manageBlendCategoryStore')
@observer
class ManageBlendCategory extends Component{
	componentDidMount(){
		let manageBlendCategoryStore = this.props.manageBlendCategoryStore;
		if('blendCategoryId' in this.props){
			manageBlendCategoryStore.formSync(this.props.blendCategoryId);
		}
	}

	componentWillUnmount(){
		let manageBlendCategoryStore = this.props.manageBlendCategoryStore;
		manageBlendCategoryStore.initStore();
	}
	render(){
		let translate = this.props.t;
		let manageBlendCategoryStore = this.props.manageBlendCategoryStore;

		let editMode = manageBlendCategoryStore.editMode;

		let managingMode = manageBlendCategoryStore.addMode || manageBlendCategoryStore.editMode;

		let title = translate('user.manage-blend-categories.new-tagline');
		if(editMode || manageBlendCategoryStore.viewMode){
			title = translate('user.manage-blend-categories.edit-tagline');
		}

		let currentBlendCategory = manageBlendCategoryStore.currentBlendCategory;

		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{title}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.manage-blend-categories.new-paragraph.part1')} <br /> {translate('user.manage-blend-categories.new-paragraph.part2')}</SmallBody>
						</div>
						<div className="flex hidden lg:block ml-4">
							<TestTubeIcon className="w-48 h-48" />
						</div>
					</div>
					<div className="flex flex-1 flex-row mt-8 lg:mt-0 justify-end">
						{manageBlendCategoryStore.viewMode && 
							<>
								<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									fetching={manageBlendCategoryStore.fetching}
									onClick={() => manageBlendCategoryStore.toggleEditMode()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-blend-categories.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</>
						}
						{manageBlendCategoryStore.editMode && 
								<Button
									width="w-auto"
									height="h-12"
									className="ml-4 px-4 rounded-lg text-pink-coral"
									bgColor="bg-list-text"
									fetching={manageBlendCategoryStore.fetching}
									onClick={() => manageBlendCategoryStore.cancelChanges()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-blend-categories.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
									</div>
								</Button>
						}
						{managingMode && 
							<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									fetching={manageBlendCategoryStore.fetching}
									onClick={() => manageBlendCategoryStore.save()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-blend-categories.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
						}
					</div>
					<FullGrid className="mt-8">
						<div className="flex flex-row">
							<SecondaryAppMediumTitle>{translate('user.manage-blend-categories.new-category-title')}</SecondaryAppMediumTitle>
						</div>
						<div className="flex flex-1 flex-row mt-4">
							<div className="flex flex-1 flex-col">
								<div className="flex flex-row items-center grid grid-cols-1 lg:grid-cols-3 gap-4">
									<div className="flex self-start">
										<DataRow label={translate('user.manage-blend-categories.blend-category.label')}>
											{manageBlendCategoryStore.viewMode && <DataEntry>{currentBlendCategory?.name}</DataEntry>}
											{managingMode &&
												<FormInput 
													placeholder={translate('user.manage-blend-categories.blend-category.placeholder')}
													value={manageBlendCategoryStore.blendCategoryData.name.value}
													error={manageBlendCategoryStore.blendCategoryData.name.error}
													onChange={(e) => manageBlendCategoryStore.blendCategoryData.name.onChange(e.target.value)}
												/>
											}
										</DataRow>
									</div>
									<div className="flex self-start">
										<DataRow label={translate('user.manage-blend-categories.tags.label')}>
											<div className="flex w-full flex-col">
												{manageBlendCategoryStore.viewMode && 
													<TagInput
													 inputValue={null}
													 hidePlaceholder
													 hideIsSearchable
													 isDisabled
													 t={translate}
													 values={currentBlendCategory?.tags.map((t) => {
													 	return {
													 		value: t.tag.name,
													 		label: t.tag.name
													 	}
													 }) } 
													/>
												}
												{managingMode && 
													<>
														<FormInput 
															placeholder={translate('user.manage-blend-categories.tags.placeholder')}
															value={manageBlendCategoryStore.blendCategoryData.currentTag.value}
															error={manageBlendCategoryStore.blendCategoryData.currentTag.error}
															onChange={(e) => manageBlendCategoryStore.blendCategoryData.currentTag.onChange(e.target.value)}
															onKeyDown={(e) => {
																if(e.keyCode == 13){
																	manageBlendCategoryStore.onSaveTag();
																}
															}}
														/>
														<TagInput
															 inputValue={null}
															 hidePlaceholder
															 hideIsSearchable
															 isDisabled
															 t={translate}
															 onInputChange={() => console.log('a')}
															 onChange={(values) => manageBlendCategoryStore.onChangeTags(values)}
															 values={manageBlendCategoryStore.blendCategoryData.tags.value.map((t) => {
															 	return {
															 		value: t,
															 		label: t
															 	}
															 }) } 
															/>
													</>
												}
											</div>
										</DataRow>
									</div>
									<div className="flex self-start">
										<div className="flex flex-1 flex-col self-start mt-2">
											<div className="flex"><DataHeader noborder>{translate('user.manage-blend-categories.hex-colour.label')}</DataHeader></div>
											{manageBlendCategoryStore.viewMode && <DataEntry>{currentBlendCategory?.hex_color}</DataEntry>}
											{managingMode && 
												<div className="flex flex-row items-center">
													<ColorPicker
														hexColor={manageBlendCategoryStore.blendCategoryData.hexColor}
														onChange={(val) => manageBlendCategoryStore.blendCategoryData.hexColor.onChange(val)}
														 />
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</FullGrid>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(ManageBlendCategory);