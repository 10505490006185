import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {computeUserLanguage, S3_TRANSLATION_HOST} from './services/util';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import runtimeEnv from '@mars/heroku-js-runtime-env';
const env = runtimeEnv();

let backendOptions = {}
if(process.env.NODE_ENV === 'production'){ 
  backendOptions = {
    loadPath: `${env.REACT_APP_S3_TRANSLATION_HOST}/locales/{{lng}}/{{ns}}.json`,
    crossDomain: true
  }
}else{
  backendOptions = {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    crossDomain: true
  }
}



i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: computeUserLanguage(),
    fallbackLng: 'en',
    keySeparator: false,
    // debug: process.env.NODE_ENV === 'development',
    debug: true,
    saveMissing: false,
    backend: backendOptions,
    react: {
      wait: true
    }
  });

export default i18n;