import React, {Component} from 'react';

import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroll-component';

import Main from '../../../elements/main';
import { SmallBody, Body, SecondaryAppLargeTitle, TertiaryAppSmallTitle, ExtraSmallBody} from '../../../common/text';
import { TestTubeIcon, ArchiveIcon, TickIcon, ArrowRightIcon, PlusIcon, EditIcon, StarIcon, DuplicateIcon} from '../../../common/icon'
import {Button} from '../../../common/button';
import {SortDialog, ListTile, FilterField, FilterDialog, SortingField} from '../../../elements/list';
import {TagInput} from '../../../common/tag';
import BlendPreview from '../blend-preview';

const colors = require('../../../../color.js');

@observer
class ExpandedBlendCategory extends Component{
	render(){
		let blendCategory = this.props.blendCategory;
		let translate = this.props.t;
		let currentBlends = this.props.currentBlends;
		let fetching = this.props.fetching;
		return (
			<div className="flex flex-1 flex-col">
				<div className="flex flex-row rounded-lg h-32">
					<div className="flex w-1/5 rounded-l-lg" style={{ backgroundColor: blendCategory.hex_color}}>
					</div>
					<div className="flex flex-1 flex-col justify-center bg-white rounded-r-lg px-4">
						<div className="flex"><TertiaryAppSmallTitle>{blendCategory.name}</TertiaryAppSmallTitle></div>
						<div className="flex"><ExtraSmallBody className="italic">{blendCategory.no_of_blends} {translate('user.my-blend-categories.blends')}</ExtraSmallBody></div>
					</div>
				</div>
				<div className="flex flex-1 flex-col">
					{currentBlends.length == 0 && !fetching &&
						<div className="flex flex-1 flex-col items-center mt-8">
							<Body>{translate('user.my-blend-categories.no-blends')}</Body>
						</div>
					}
					{currentBlends.length == 0 && fetching &&
						<div className="flex flex-1 flex-col items-center mt-8">
							<Body>{translate('user.my-blend-categories.fetching-blends')}</Body>
						</div>
					}
					<InfiniteScroll
					  dataLength={currentBlends.length}
					  next={() => this.props.loadNextBlendsPage()}
					  hasMore={this.props.hasMoreBlends}
					  loader={null}
					  endMessage={null}
					>
						{currentBlends.map((blend) => 
							<BlendPreview 
								t={translate} 
								canManage={false} 
								blend={blend}
								onView={(id) => this.props.onViewBlend(id)}
								onEdit={(id) => this.props.onEditBlend(id)}
								onArchive={(id) => this.props.onArchiveBlend(id)}
								onRestore={(id) => this.props.onRestoreBlend(id)}
								onDuplicate={(id) => this.props.onDuplicateBlend(id)}
								onToggleAvailable={(id) => this.props.onToggleAvailable(id)}
								canManage={() => this.props.canManage(blend)}
								canViewCompliance={this.props.canViewCompliance} 
								showAvailability={this.props.showAvailability}
							/>
						)}
					</InfiniteScroll>
				</div>
			</div>
		);
	}
}

@inject('blendCategoriesStore', 'blendsLibraryStore')
@observer
class BlendCategories extends Component{
	componentDidMount(){
		let blendCategoriesStore = this.props.blendCategoriesStore;
		blendCategoriesStore.fetchBlendCategories();
	}

	componentWillUnmount(){
		let blendCategoriesStore = this.props.blendCategoriesStore;
		blendCategoriesStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let blendCategoriesStore = this.props.blendCategoriesStore;
		let blendsLibraryStore = this.props.blendsLibraryStore;
		let archivedButtonText = blendCategoriesStore.archived ? translate('user.my-ingredient-library.active-ingredients-button') : translate('user.my-ingredient-library.archived-ingredients-button');
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.my-blend-categories.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.my-blend-categories.paragraph.part1')} <br /> {translate('user.my-blend-categories.paragraph.part2')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={() => window.open('http://help.labaroma.com/en/articles/4745405-4-my-blend-categories-tutorial', '_blank')}>
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.my-blend-categories.video-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex hidden lg:block ml-4">
							<TestTubeIcon className="w-48 h-48" />
						</div>
					</div>
						
					{blendCategoriesStore.selectedBlendCategory == null && 
						<>
							<div className="flex w-full mt-8 lg:mt-0 justify-end mt-2">
								<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									fgColor={blendCategoriesStore.archived ? "list-text" : "list-text"}
									bgColor={blendCategoriesStore.archived ? "gray-bg-button" : "white"}
									borderColor={blendCategoriesStore.archived ? "gray-bg-button" : "list-text"}
									onClick={() => blendCategoriesStore.onToggleArchived()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{archivedButtonText} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><ArchiveIcon color={colors['list-text']} className="w-4 h-4" /></div>
									</div>
								</Button>
								<div className="flex ml-4">
									<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="pink-coral"
										onClick={() => blendCategoriesStore.onNewCategory()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-blend-categories.new-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><PlusIcon /></div>
										</div>
									</Button>
								</div>
							</div>
							<div className="relative flex flex-row justify-end items-center mt-4">
								<div className="relative flex flex-row items-center">
									<FilterField text={translate('user.my-blend-categories.filter')} onClick={() => blendCategoriesStore.toggleFilterDialog()}/>
									{blendCategoriesStore.showFilterDialog && 
												<FilterDialog
												 	t={translate}
												 	onApply={() => blendCategoriesStore.applyFilters()}
												>
													<div className="flex flex-col w-32">
														<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-blend-categories.headers.tags')}</TertiaryAppSmallTitle></div>
														<div className="flex">
															<TagInput
															 inputValue={blendCategoriesStore.currentFilterValue}
															 onInputChange={(val) => blendCategoriesStore.setCurrentFilterValue(val)}
															 t={translate}
															 onChange={(values) => blendCategoriesStore.setFilters(values)}
															 onNewEntry={(val) => blendCategoriesStore.newFilterEntry(val)}
															 values={blendCategoriesStore.currentFilters} 
															/>
														</div>
													</div>
												</FilterDialog>
											}
								</div>
								<div className="flex ml-8">
									<div className="relative flex flex-row items-center">
										<SortingField 
											text={translate('user.my-blend-categories.sort')}
											onClick={() => blendCategoriesStore.toggleSortDialog()}
										/>
										{blendCategoriesStore.showSortDialog && 
												<SortDialog
												 	t={translate}
													sortOptions={blendCategoriesStore.sortOptions}
													currentOption={blendCategoriesStore.sortBy}
													onOption={(val) => blendCategoriesStore.setSortByField(val)}
													onApply={(val) => blendCategoriesStore.applySort()}
												/>
											}
									</div>
								</div>
							</div>
							<InfiniteScroll
							  dataLength={blendCategoriesStore.blendCategories.length}
							  next={() => blendCategoriesStore.loadNextPage()}
							  hasMore={blendCategoriesStore.hasMore}
							  loader={null}
							  endMessage={null}
							>
								<div className="flex w-full grid grid-cols-1 lg:grid-cols-4 col-gap-8 row-gap-16 mt-8">
									{blendCategoriesStore.blendCategories.map((blendCategory) => 
										<ListTile
											key={blendCategory.id}
											id={blendCategory.id}
											name={blendCategory.name}
											hexColor={blendCategory.hex_color}
											t={translate} 
											description={`${blendCategory.no_of_blends} ${translate('user.my-blend-categories.blends')}`}
											viewDescription={translate('user.my-blend-categories.view-blends')}
											onEdit={(id) => blendCategoriesStore.onEditCategory(id)}
											onView={(id) => blendCategoriesStore.onSelectBlendCategory(id)}
											onFavourite={(id) => blendCategoriesStore.toggleFavouriteBlendCategoryById(id)}
											favourited={blendCategory.favourited}
											onArchived={(id) => blendCategoriesStore.toggleArchivedBlendCategoryById(id)}
											archived={blendCategory.archived}
											/>
									)}
									{blendCategoriesStore.blendCategories.length == 0 && !blendCategoriesStore.fetching &&
										<div className="col-span-4 flex justify-center mt-8">
											<Body>{translate('user.my-blend-categories.no-blend-categories')}</Body>
										</div>
									}
									{blendCategoriesStore.blendCategories.length == 0 && blendCategoriesStore.fetching &&
										<div className="col-span-4 flex justify-center mt-8">
											<Body>{translate('user.my-blend-categories.fetching-blend-categories')}</Body>
										</div>
									}
								</div>
							</InfiniteScroll>
						</>		
					}
					{blendCategoriesStore.selectedBlendCategory != null &&
						<>
							<div className="flex w-full justify-end mt-2">
								<div className="flex ml-2">
									<Button
										width="w-auto"
										height="h-12"
										className="ml-4 px-4 rounded-lg text-pink-coral"
										bgColor="bg-list-text"
										onClick={() => blendCategoriesStore.onBackToCategories()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-blend-categories.back-button')} &nbsp;</TertiaryAppSmallTitle></div>
										</div>
									</Button>
								</div>
								<div className="flex flex-row items-center ml-4">
									<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										fgColor={blendCategoriesStore.selectedBlendCategory.archived  ? "list-text" : "list-text"}
										bgColor={blendCategoriesStore.selectedBlendCategory.archived  ? "gray-bg-button" : "white"}
										borderColor={blendCategoriesStore.selectedBlendCategory.archived  ? "gray-bg-button" : "list-text"}
										fetching={blendCategoriesStore.fetching}
										onClick={() => blendCategoriesStore.toggleArchivedBlendCategory()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{blendCategoriesStore.selectedBlendCategory.archived ? translate('user.my-blend-categories.unarchive-button') : translate('user.my-blend-categories.archive-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex">{!blendCategoriesStore.selectedBlendCategory.archived ? <ArchiveIcon className="w-4 h-4" color={colors['list-text']} /> : <TickIcon className="w-4 h-4" color={colors['list-text']} />}</div>
										</div>
									</Button>
								</div>
								<div className="flex flex-row items-center ml-4">
									{!blendCategoriesStore.selectedBlendCategory.favourited && 
										<Button
											width="w-auto"
											height="h-12"
											className="px-4 rounded-lg"
											bgColor="dark-green-60"
											fetching={blendCategoriesStore.fetching}
											onClick={() => blendCategoriesStore.toggleFavouriteBlendCategory()}
										>
											<div className="flex flex-row items-center justify-center">
												<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-blend-categories.favourite-button')} &nbsp;</TertiaryAppSmallTitle></div>
												<div className="flex"><StarIcon fill="white" className="w-4 h-4" color="white" /></div>
											</div>
										</Button>
									}
									{blendCategoriesStore.selectedBlendCategory.favourited && 
										<Button
											width="w-auto"
											height="h-12"
											className="px-4 rounded-lg"
											bgColor="list-text"
											fetching={blendCategoriesStore.fetching}
											onClick={() => blendCategoriesStore.toggleFavouriteBlendCategory()}
										>
											<div className="flex flex-row items-center justify-center">
												<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-blend-categories.unfavourite-button')} &nbsp;</TertiaryAppSmallTitle></div>
												<div className="flex"><StarIcon className="w-4 h-4" color="white" /></div>
											</div>
										</Button>
									}
								</div>
								<div className="flex ml-4">
									<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="pink-coral"
										onClick={() => blendCategoriesStore.onEditCategory(blendCategoriesStore.selectedBlendCategory.id)}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-blend-categories.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><EditIcon id={blendCategoriesStore.selectedBlendCategory.id} className="w-4 h-4" color={colors['white']} /></div>
										</div>
									</Button>
								</div>
							</div>
							<div className="flex flex-1 flex-col mt-8">
								<ExpandedBlendCategory
									t={translate}
									fetching={blendCategoriesStore.fetchingBlends}
									blendCategory={blendCategoriesStore.selectedBlendCategory}
									currentBlends={blendCategoriesStore.currentBlends}
									hasMoreBlends={blendCategoriesStore.hasMoreBlends}
									loadNextBlendsPage={() => blendCategoriesStore.loadNextBlendsPage()}
									onViewBlend={(id) => blendsLibraryStore.onViewBlend(id)}
									onEditBlend={(id) => blendsLibraryStore.onEditBlend(id)}
									onArchiveBlend={(id) => blendCategoriesStore.onArchiveBlend(id)}
									onRestoreBlend={(id) => blendCategoriesStore.onRestoreBlend(id)}
									onDuplicateBlend={(id) => blendCategoriesStore.onDuplicateBlend(id)}
									onToggleAvailable={(id) => blendCategoriesStore.onPublicBlend(id)}
									canManage={(blend) => blendsLibraryStore.hasEditAccessToBlend(blend)}
									canViewCompliance={blendsLibraryStore.canViewCompliance} 
									showAvailability={blendsLibraryStore.showAvailability}
								/>
							</div>
						</>
					}		
				</div>
			</Main>
		)
	}
}

export default withTranslation()(BlendCategories);