import { observable, action, computed, extendObservable } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isEmailValidator, isRequiredValidator, isNotNullValidator, isPositiveNumberValidator, isHexColorValidator } from '../../../services/validation';

import LibraryApi from '../../../services/library';
import BlendApi from '../../../services/blend';

import moment from 'moment';

import { readString } from 'react-papaparse'

class ManageLibraryCategoryStore{
	@observable addMode;
	@observable viewMode;
	@observable editMode;

	@observable fetching;

	@observable currentLibraryCategory;

	@observable libraryCategoryData;

	@observable fetching;
	@observable blends;

	@observable uploading;

	id;

	libraryApi;
	blendApi;

	constructor(appStore){
		this.appStore = appStore;
		this.libraryApi = new LibraryApi(appStore);
		this.blendApi = new BlendApi(appStore);
		this.initStore();
	}

	initStore(){
		this.addMode = true;
		this.viewMode = false;
		this.editMode = false;
		this.fetching = false;
		this.currentLibraryCategory = null;
		this.libraryCategoryData = {
			name: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			bio: new FieldState(null),
			currentUserEmail: new FieldState(null).validators((val) => isEmailValidator(val, this.appStore.i18n)),
			hexColor: new FieldState('#17273c').validators((val) => isHexColorValidator(val, this.appStore.i18n)),
			blendSearchText: new FieldState(null),
			selectedBlend: new FieldState(null),
			addedBlends: [],
			addedUserEmails: []
		}

		this.libraryCategoryForm = new FormState({
			name: this.libraryCategoryData.name,
			bio: this.libraryCategoryData.bio,
			hexColor: this.libraryCategoryData.hexColor,
		})

		this.fetching = false;
		this.uploading = false;
		this.blends = [];
	}

	toLibraryCategory(libraryCategory){
		libraryCategory.user_emails = libraryCategory.user_emails.map((x) => {
			x.added_on = moment(x.added_on);
			return x;
		});
		return libraryCategory;
	}

	formSync = async(id) => {
		this.fetching = true;

		try{
			let libraryCategory = await this.libraryApi.getLibraryCategoryById(id);
			this.currentLibraryCategory = this.toLibraryCategory(libraryCategory);
			this.viewMode = true;
			this.addMode = false;
			this.editMode = false;
			this.id = id;
		}
		catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}

	@action cancelChanges(){
		this.editMode = false;
		this.addMode = false;
		this.viewMode = true;
	}

	@action toggleEditMode(){
		this.libraryCategoryData.name.value = this.currentLibraryCategory.name;
		this.libraryCategoryData.hexColor.value = this.currentLibraryCategory.hex_color;
		this.libraryCategoryData.bio.value = this.currentLibraryCategory.bio;
		this.libraryCategoryData.addedBlends = this.currentLibraryCategory.blends;
		this.libraryCategoryData.addedUserEmails = this.currentLibraryCategory.user_emails.map((u) => u.user_email);
		this.editMode = true;
		this.addMode = false;
		this.viewMode = false;
	}

	@action addNewBlend(){
		let blendIdToAdd = this.libraryCategoryData.selectedBlend.value;
		if(blendIdToAdd == null) return;
		let foundBlend = this.blends.find((blend) => blend.id == blendIdToAdd);
		if(foundBlend == null) return null;
		let existingBlend = this.libraryCategoryData.addedBlends.find((addedBlend) => addedBlend.id == blendIdToAdd);
		if(existingBlend != null) return null;
		this.libraryCategoryData.addedBlends.push(foundBlend);
		this.libraryCategoryData.selectedBlend.value = null;
		this.libraryCategoryData.blendSearchText.value = '';
	}

	@action fetchBlends(searchText=null){
		this.blendApi.getAllBlends(1, searchText, null, null, null, null, null)
			.then((response) => {
				this.blends = response.blends;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action onChangeBlendSearchText(val){
		this.libraryCategoryData.blendSearchText.onChange(val);
		this.libraryCategoryData.selectedBlend.value = null;
		this.fetchBlends(this.libraryCategoryData.blendSearchText.value)
			
	}

	@action clearBlendSelection(){
		this.libraryCategoryData.blendSearchText.value = '';
		this.libraryCategoryData.selectedBlend.value = null;
	}

	@computed get blendOptions(){
		if(this.libraryCategoryData.blendSearchText.value == null || this.libraryCategoryData.blendSearchText.value == '') return [];
		let addedBlendIds = this.libraryCategoryData.addedBlends.map((addedBlend) => addedBlend.id);
		return this.blends.filter((b) => !addedBlendIds.includes(b.id)).map((blend) => {
			return {
				value: blend.id,
				label: blend.name
			}
		}).sort((a, b) => a.name > b.name ? 1 : -1);
	}

	@action onChooseBlend(id){
		let foundBlend = this.blends.find((blend) => blend.id == id);
		if(foundBlend != null){
			this.libraryCategoryData.blendSearchText.onChange(foundBlend.name);
			this.libraryCategoryData.selectedBlend.onChange(id);
		}
	}

	@action deleteAddedBlend(id){
		this.libraryCategoryData.addedBlends = this.libraryCategoryData.addedBlends.filter((blend) => blend.id != id);
	}

	@action addNewUserEmail(){
		let emailToAdd = this.libraryCategoryData.currentUserEmail.value;
		if(emailToAdd == null) return;
		emailToAdd = emailToAdd.toLowerCase();
		let existingEmail = this.libraryCategoryData.addedUserEmails.find((x) => x == emailToAdd);
		if(existingEmail != null) return null;
		if(this.libraryCategoryData.currentUserEmail.error != null) return;
		this.libraryCategoryData.addedUserEmails.push(emailToAdd);
		this.libraryCategoryData.currentUserEmail.value = '';
	}

	@action deleteAddedUserByEmail(email){
		this.libraryCategoryData.addedUserEmails = this.libraryCategoryData.addedUserEmails.filter((userEmail) =>userEmail != email);	
	}

	@action onReadCSVFiles(files){
		if(files.length > 1){
			this.appStore.alertError(this.appStore.i18n.t('error.one-file-only-message'));
			return;
		}
		let csvFile = files[0];
		this.uploading = true;
		let csvFileReader = new FileReader();
		csvFileReader.onload = (e) => {
			let csvData = readString(csvFileReader.result, {
				header: true,
				delimiter: ',',
				skipEmptyLines: true
			})
			if(csvData.errors.length > 0){
				this.appStore.alertError(this.appStore.i18n.t('error.csv-file-has-errors'));
				return;
			}else{
				let emailsAdded = 0;
				for(let emailFound of csvData.data){
					if('email' in emailFound){
						let emailToAdd = emailFound.email.toLowerCase();
						let existingEmail = this.libraryCategoryData.addedUserEmails.find((x) => x == emailToAdd);
						if(existingEmail == null){
							this.libraryCategoryData.addedUserEmails.push(emailFound.email);
							emailsAdded += 1;
						}
					}
				}
				if(emailsAdded > 0){ 
					this.appStore.alertSuccess(`${emailsAdded} ${this.appStore.i18n.t('success.emails-found')}`)
				}
			}
			this.uploading = false;				
		}
		csvFileReader.readAsText(csvFile);
	}

	save = async () => {
		let res = await this.libraryCategoryForm.validate()
		if(res.hasError) return;

		let payload = {
			name: this.libraryCategoryData.name.value,
			hex_color: this.libraryCategoryData.hexColor.value,
			bio: this.libraryCategoryData.bio.value,
			blend_ids: this.libraryCategoryData.addedBlends.map((b) => b.id),
			user_emails: this.libraryCategoryData.addedUserEmails
		}

		this.fetching = true;

		try{
			if(this.addMode){
				let response = await this.libraryApi.newLibraryCategory(payload);
			}else{
				let response = await this.libraryApi.updateLibraryCategory(this.id, payload);
			}
			this.appStore.displaySaveDialog();
			this.appStore.getOwnedBlendLibraryCategories();
			this.appStore.goToLibraryCategories();
		}catch(e){
			console.log(e);
		}finally{
			this.fetching = false;
		}
	}
}

export default ManageLibraryCategoryStore;