const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const URL_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const HEX_COLOR_REGEX = /^#[0-9a-f]{3,6}$/i;
/* 
EXAMPLE 
validate('test', isBetween, { options }, 'Error Msg')
*/
export const validate = (val, validateFn, options, onError) => {
	let result = validateFn(val, options);
	let errorMsg = onError === null ? 'Error' : onError;
	return result ? '' : errorMsg;
}

export const validator = {
	isBetween: (val, options) => {
		return val.length > options['min'] && val.length < options['max'];
	},
	isRequired: (val) => {
		return val != null && val.length > 0;
	},
	isEmail: (val) => {
		return EMAIL_REGEX.test(val);
	},
	isUrl: (val) => {
		return URL_REGEX.test(val);
	},
	isEqualTo: (val, compareTo) => {
		return val === compareTo;
	},
	isHexColor: (val) => {
		return HEX_COLOR_REGEX.test(val);
	},
	isNumber: (val) => {
		if(val === '' || val === undefined) return false;
		return !isNaN(val);
	},
	isPositiveNumber: (val) => {
		return val !== '' && validator.isNumber(val) && parseFloat(val, 2) >= 0;
	},
	isGreaterThanZero: (val) => {
		return validator.isPositiveNumber(val) && parseFloat(val, 2) > 0;
	},
	isPhoneNumber: (val) => {
		if(val == '' || val == null) return false; 
		if(val.length < 5 || val.length > 15) return false;
		for(let i = 0;i < val.length;i++){
			if(!validator.isNumber(val[i]) && val[i] != '+'){
				return false;
			}
		}
		return true;
	},
	isNullOrUndefined: (val) => {
		return val == null || val == undefined;
	}
};

const MIN_PASSWORD_LENGTH = 8;

export const isRequiredValidator = (val, i18n, field) => !validator.isRequired(val) && `${i18n.t('validation.required-part-1-message')} ${field != null ? field : i18n.t('validation.required-part-2-message')}`;
export const isEmailValidator = (val, i18n) => !validator.isEmail(val) && i18n.t('validation.invalid-email-message');
export const isUrlValidator = (val, i18n) => !validator.isUrl(val) && i18n.t('validation.invalid-url-message');
export const isPasswordValidator = (val, i18n) => (!validator.isRequired(val) || val.length < MIN_PASSWORD_LENGTH) && `${i18n.t('validation.invalid-password-length-message')}`;
export const isHexColorValidator = (val, i18n) => !validator.isHexColor(val) && i18n.t('validation.invalid-hex-color-message');
export const isPositiveNumberValidator = (val, i18n) => val != '' && val != null && !validator.isPositiveNumber(val) && i18n.t('validation.invalid-positive-number');
export const isNotNullValidator = (val, i18n) => (val == null || val == undefined) && i18n.t('validation.not-null-message');