import BaseApi from '../services/base';

class TagApi extends BaseApi{
	constructor(props){
		super(props);
	}

	searchTags(tag){
		return this.fetch(`v1/tags/search`, {
			method: 'POST',
			auth:true,
			body: JSON.stringify({
				name: tag
			})
		})
	}
}

export default TagApi;