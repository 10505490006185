import { observable, action, computed} from 'mobx';

import { isEmailValidator, isRequiredValidator } from '../../services/validation';
import { FieldState, FormState } from 'formstate';

import NewsletterApi from '../../services/newsletter';
import CountryApi from '../../services/country';
import RecaptchaApi from '../../services/recaptcha';

import { sortCountriesAlphabetically} from '../../services/util';

class NewsletterStore{
	appStore;

	@observable introTextHTML;

	@observable email;
	@observable name;
	@observable currentCountry;

	@observable countries;

	@observable optInNewsletter;
	@observable isValidForm;

	@observable fetching;
	@observable error;

	@observable subscribed;
	@observable loaded;

	@observable recaptchaVerified;

	newsletterApi;
	countryApi;

	form;

	constructor(appStore){
    	this.appStore = appStore;
    	this.initStore();
		this.newsletterApi = new NewsletterApi(appStore);
		this.countryApi = new CountryApi(appStore);
		this.recaptchaApi = new RecaptchaApi(appStore);
    }

    initStore(){
    	this.introTextHTML = null;
    	this.email = new FieldState('').validators((val) => isEmailValidator(val, this.appStore.i18n));
		this.name = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, 'name'));
		this.currentCountry = null;
		this.optInNewsletter = new FieldState(false).validators((val) => val === false && this.appStore.i18n.t('validation.newsletter-opt-in-message'));
		this.isValidForm = false;
		this.recaptchaVerified = new FieldState(false).validators((val) => val === false && this.appStore.i18n.t('validation.recaptcha-required'));
		this.form = new FormState({
			name: this.name,
			email: this.email,
			optInNewsletter: this.optInNewsletter,
			recaptchaVerified: this.recaptchaVerified
		});
		this.countries = [];
		this.error = null;
		this.fetching = false;
		this.loaded = false;
		this.subscribed = false;
    }

    load(){
    	this.fetchCountries()
    		.then(() => {
    			this.fetchIntroText()
    				.then(() => {
    					this.loaded = true;
    				})
    		})
    }

    verifyRecaptcha(recaptchaResponse){
		this.recaptchaApi.verify(recaptchaResponse)
			.then((response) => {
				this.recaptchaVerified.onChange(response.data.success);
				this.validateForm();
				if(!this.recaptchaVerified.value){
					this.appStore.alertError(this.appStore.i18n.t('common.recaptcha-fail'));
				}
			})
			.catch((error) => {
				this.appStore.alertError(this.appStore.i18n.t('common.recaptcha-fail'));
				this.recaptchaVerified.onChange(false);
			});
	}

    fetchCountries(){
		return this.countryApi.getAll()
			.then((response) => {
				this.countries = response.countries.sort(sortCountriesAlphabetically);
			})
			.catch((error) => {
				console.log(error);
			})
	}

    fetchIntroText(){
    	return this.newsletterApi.getIntroSnippet()
    		.then((response) => {
    			this.introTextHTML = response.html;
    		})
    		.catch((error) => {
    			console.log(error);
    		})
    }

    @action onChangeName(val){
		this.name.onChange(val);
		this.validateForm();
	}

	@action onChangeEmail(val){
		this.email.onChange(val);
		this.validateForm();
	}

	@action onChangeCountry(val){
		this.currentCountry = val;
	}

	@action onChangeOptIn(checked){
		this.optInNewsletter.onChange(checked);
		this.validateForm();
	}

	@action goToTerms(){
		this.appStore.goToTerms();
	}

	@computed get countryOptions(){
		return this.countries.map((c) => {
			return {
				value: c.id,
				label: c.name
			}
		})
	}

	validateForm(){
		this.form.validate()
			.then((res) => {
				this.isValidForm = !res.hasError;
			})
	}

	submit(){
		this.fetching = true;
		this.newsletterApi.subscribe(this.name.value, this.email.value, this.currentCountry)
			.then((response) => {
				this.storeHideNewsletterKey();
				this.subscribed = true;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action storeHideNewsletterKey(){
		localStorage.setItem(this.appStore.HIDE_NEWSLETTER_KEY, JSON.stringify(true));
	}

	@action exitNewsletter(){
		this.appStore.closeNewsletterModal();
	}

	@action hideNewsletterForever(){
		this.storeHideNewsletterKey();
		this.appStore.closeNewsletterModal();
	}

}

export default NewsletterStore;