import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';
import { SmallBody, Body, SecondaryAppLargeTitle, SecondaryAppSmallTitle, SecondaryAppExtraSmallTitle, TertiaryAppMediumTitle, TertiaryAppSmallTitle, TertiaryAppExtraExtraSmallTitle, SecondaryAppMediumTitle} from '../common/text';
import { BlendingIcon, ArrowRightIcon, FilterIcon, DropIcon, BlendLibraryIcon, CompareIngredients, EditIcon, ViewIcon, DuplicateIcon, ArchiveIcon} from '../common/icon'
import {HelpTooltip} from '../common/input';

import {ListTile} from '../elements/list';
import Main from '../elements/main';
import {FullGrid} from '../elements/grid';
import BlendPreview from '../user/blends/blend-preview';
import {RankingScoreGrid} from '../user/blends/libraries/manage-blend';

const colors = require('../../color.js');

@observer
class QuickActionButton extends Component{
	render(){
		return (
			<div className="bg-white h-auto rounded-lg flex grid grid-cols-3 gap-4 flex-row items-start py-4 px-4 cursor-pointer" onClick={this.props.onClick}>
				<div className="flex h-full bg-ochre-yellow-25 rounded-lg flex justify-center items-center">
					{this.props.icon}
				</div>
				<div className="col-span-2 flex flex-col">
					<div className="flex"><SecondaryAppSmallTitle>{this.props.title}</SecondaryAppSmallTitle></div>
					<div className="flex w-1/2"><SmallBody>{this.props.subtitle}</SmallBody></div>
				</div>
			</div>
		)
	}
}

@inject('appStore', 'userStore', 'blendsLibraryStore', 'blendCategoriesStore')
@observer
class Home extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let appStore = this.props.appStore;
		if(!appStore.isAuthenticated){
			appStore.goToLogin();
		}else{
			let userStore = this.props.userStore;
			userStore.fetchRegions();

			userStore.fetchUserSettings();

			userStore.fetchTopStats();

			let blendsLibraryStore = this.props.blendsLibraryStore;
			blendsLibraryStore.fetchMyLatestBlends();

			let blendCategoriesStore = this.props.blendCategoriesStore;
			blendCategoriesStore.fetchFavouriteBlendCategories();
		}
	}

	componentWillUnmount(){
		let userStore = this.props.userStore;
		userStore.initStore();

		let blendsLibraryStore = this.props.blendsLibraryStore;
		blendsLibraryStore.initStore();

		let blendCategoriesStore = this.props.blendCategoriesStore;
		blendCategoriesStore.initStore();
	}
	
	render(){
		let appStore = this.props.appStore;
		let userStore = this.props.userStore;
		let blendsLibraryStore = this.props.blendsLibraryStore;
		let blendCategoriesStore = this.props.blendCategoriesStore;

		let currentUser = userStore.currentUser;
		if(currentUser == null) return null;
		let translate = this.props.t;
		let isHomeUser = appStore.isHomeUser;
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center">
						<div className="flex flex-col">
							<SmallBody>{translate('user.home.intro')} {currentUser.name},</SmallBody>
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.home.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.home.paragraph.part1')} <br /> {translate('user.home.paragraph.part2')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={() => window.open('http://help.labaroma.com/en/articles/4732949-1-blending-tutorial', '_blank')}>
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.home.blending-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex hidden lg:block ml-4">
							<BlendingIcon className="w-64 h-48" />
						</div>
					</div>
					<div className={`grid grid-cols-1 lg:grid-cols-${isHomeUser ? 3 : 2} gap-8 mt-4`}>
						<QuickActionButton
							icon={
								<FilterIcon className="w-10 h-10" color={colors['black']} />
							}
							title={translate('user.home.blend-action.title')}
							subtitle={
								(
									<>{translate('user.home.blend-action.subtitle.part1')}</>
								)
							}
							onClick={() => userStore.appStore.goToNewBlend()}
						/>
						<QuickActionButton
							icon={
								<BlendLibraryIcon className="w-10 h-10" color={colors['black']} />
							}
							title={translate('user.home.blend-library.title')}
							subtitle={
								<>{translate('user.home.blend-library.subtitle.part1')}</>
							}
							onClick={() => userStore.appStore.goToMyBlendLibrary()}
						/>
						<QuickActionButton
							icon={
								<DropIcon className="w-10 h-10" color={colors['black']} />
							}
							title={translate('user.home.ingredient-library.title')}
							subtitle={
								<>{translate('user.home.ingredient-library.subtitle.part1')} </>
							}
							onClick={() => userStore.appStore.goToMyIngredientLibrary()}
						/>
						{!isHomeUser && 
							<QuickActionButton
								icon={
									<CompareIngredients className="w-10 h-10" color={colors['black']} />
								}
								title={translate('user.home.compare-ingredients.title')}
								subtitle={
									<>{translate('user.home.compare-ingredients.subtitle.part1')}</>
								}
								onClick={() => userStore.appStore.goToCompareIngredients()}
							/>
						}
					</div>
					<div className="flex flex-1 flex-col mt-8">
						<SecondaryAppMediumTitle className="text-nav-active-color">{translate('user.home.blends-title')}</SecondaryAppMediumTitle>
						
						{blendsLibraryStore.blends.map((blend) => 
							<BlendPreview 
									t={translate} 
									blend={blend}
									onView={(id) => blendsLibraryStore.onViewBlend(id)}
									onEdit={(id) => blendsLibraryStore.onEditBlend(id)}
									onArchive={(id) => blendsLibraryStore.onArchiveBlend(id)}
									onRestore={(id) => blendsLibraryStore.onRestoreBlend(id)}
									onDuplicate={(id) => blendsLibraryStore.onDuplicateBlend(id)}
									onToggleAvailable={(id) => blendsLibraryStore.onPublicBlend(id)}
									canManage={blendsLibraryStore.hasEditAccessToBlend(blend)}
									canViewCompliance={blendsLibraryStore.canViewCompliance} 
									showAvailability={blendsLibraryStore.showAvailability}
								/>
						)}
						{blendsLibraryStore.blends.length == 0 && !blendsLibraryStore.fetching &&
							<div className="flex w-full justify-center mt-8">
								<Body>{translate('user.home.no-blends')}</Body>
							</div>
						}
						{blendsLibraryStore.blends.length == 0 && blendsLibraryStore.fetching &&
							<div className="flex w-full justify-center mt-8">
								<Body>{translate('user.home.fetching-blends')}</Body>
							</div>
						}

					</div>
					<div className="flex flex-col mt-8">
						<SecondaryAppMediumTitle className="text-nav-active-color">{translate('user.home.favourite-blend-categories-title')}</SecondaryAppMediumTitle>
						
						<div className="grid grid-cols-4 gap-4 mt-8">
							{blendCategoriesStore.favouriteBlendCategories.map((blendCategory) => 
								<ListTile
									key={blendCategory.id}
									id={blendCategory.id}
									name={blendCategory.name}
									hexColor={blendCategory.hex_color}
									t={translate} 
									description={`${blendCategory.no_of_blends} ${translate('user.my-blend-categories.blends')}`}
									onEdit={(id) => blendCategoriesStore.onEditCategory(id)}
								/>
							)}
						</div>
						
						{blendCategoriesStore.favouriteBlendCategories.length == 0 && !blendCategoriesStore.fetching &&
							<div className="flex w-full justify-center mt-8">
								<Body>{translate('user.home.no-favourite-blend-categories')}</Body>
							</div>
						}
						{blendCategoriesStore.favouriteBlendCategories.length == 0 && blendCategoriesStore.fetching &&
							<div className="flex w-full justify-center mt-8">
								<Body>{translate('user.home.fetching-favourite-blend-categories')}</Body>
							</div>
						}

					</div>
					<div className="flex flex-col mt-8">
						<div className="flex flex-row items-center">
							<SecondaryAppMediumTitle className="text-nav-active-color">{translate('user.home.top-stats')}</SecondaryAppMediumTitle>
							<div className="flex"><HelpTooltip text={appStore.getTooltip('tooltip-your-stats')} /></div>
						</div>
						<div className="flex w-full grid grid-cols-1 xl:grid-cols-3 gap-4 mt-8">
							<FullGrid>
								<div className="flex flex-col px-4">
									<div className="flex"><SecondaryAppSmallTitle>{translate('user.home.top-scent-notes')}</SecondaryAppSmallTitle></div>
									{userStore.topScentNotes.length == 0 && 
										<div className="flex w-full items-center justify-center mt-8">
											<SmallBody>{translate('user.home.no-scent-notes')}</SmallBody>
										</div>
									}

									<div className="grid grid-rows-2 flex flex-1">
										{userStore.topScentNotes.map((topScentNote) => 
											<div className="flex flex-1 flex-col mt-8">
												<div className="flex border-b border-black py-1 px-4">
													<TertiaryAppSmallTitle>{topScentNote.scent_note.name}</TertiaryAppSmallTitle>
												</div>
												<div className="flex flex-col w-full mt-1 px-4">
													{topScentNote.ingredients.map((ingredient, idx) => 
														<div className="flex py-1"><SmallBody>{idx+1}. {ingredient.name}</SmallBody></div>
													)}
												</div>
											</div>
										)}
									</div>
								</div>
							</FullGrid>

							<FullGrid>
								<div className="flex flex-col px-4">
									<div className="flex"><SecondaryAppSmallTitle>{translate('user.home.top-physical-effects')}</SecondaryAppSmallTitle></div>
									{userStore.topPhysicalRankings.length == 0 && 
										<div className="flex w-full items-center justify-center mt-8">
											<SmallBody>{translate('user.home.no-physical-effects')}</SmallBody>
										</div>
									}
									<div className="flex flex-1 justify-between w-full flex-col mt-8">
										<RankingScoreGrid 
											data={userStore.topPhysicalRankings}
											color="pink-coral"
											smallBody
										/>
									</div>
								</div>
							</FullGrid>

							<FullGrid>
								<div className="flex flex-col px-4">
									<div className="flex"><SecondaryAppSmallTitle>{translate('user.home.top-psychological-effects')}</SecondaryAppSmallTitle></div>
									{userStore.topPsychologicalRankings.length == 0 && 
										<div className="flex w-full items-center justify-center mt-8">
											<SmallBody>{translate('user.home.no-psychological-effects')}</SmallBody>
										</div>
									}
									<div className="flex flex-1 items-center w-full flex-col mt-8">
										<RankingScoreGrid 
											data={userStore.topPsychologicalRankings}
											color="success-green"
											smallBody
										/>
									</div>
								</div>
							</FullGrid>
						</div>
					</div>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(Home);