import React, {Component} from 'react';

import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';
import {SecondaryAppSmallTitle, ExtraSmallBody, SecondaryAppLargeTitle, SecondaryAppMediumTitle, Body, SmallBody, ApplyText, TertiaryAppSmallTitle} from '../../../common/text'
import {AddIcon, StarIcon, SaveIcon, PlusIcon, EditIcon, ExitIcon, ArrowRightIcon, EditIngredientIcon} from '../../../common/icon';
import InfiniteScroll from 'react-infinite-scroll-component';

import Main from '../../../elements/main';
import {Button} from '../../../common/button';
import BaseModal from '../../../common/modal';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormSearchSelect,
	FormInput,
	FormTextArea,
	TwoColumnReadList,
	FormSelect
} from '../../../elements/form';
import {ImageAvatar} from '../../../common/input';
import {PieChart} from '../../../common/chart';

import {DownloadPDF, pdfStyles} from '../../../common/pdf';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import logoSrc from '../../../../assets/img/logo.png';

import * as htmlToImage from 'html-to-image';

const colors = require('../../../../color.js');

const PANEL_WIDTH = '450px';

@observer
class DataRowWrapper extends Component{
	render(){
		return (
			<div className="flex flex-col h-64 overflow-y-scroll">
				{this.props.children}
			</div>
		)
	}
}

@observer
class IngredientDataSection extends Component{
	render(){
		let ingredient = this.props.ingredient;
		let height = 'auto' in this.props ? 'auto' : '600px';
		return (
			<div className="flex flex-col overflow-y-scroll show-scrollbars px-6 py-4" style={{ height: height}}>
				{this.props.children}
			</div>
		)
	}
}

@observer
class SectionBreak extends Component{
	render(){
		return (
			<div className="flex flex-col w-full px-6 py-1 bg-white">
				<SecondaryAppSmallTitle>{this.props.title}</SecondaryAppSmallTitle>
			</div>
		)
	}
}

@observer
class IngredientChemicalComponentEntry extends Component{
	constructor(props){
		super(props);
		this.toggleMouseEnter = this.toggleMouseEnter.bind(this);
		this.toggleMouseLeave = this.toggleMouseLeave.bind(this);
		this.state = {
			hovering: false
		}
	}

	toggleMouseEnter(){
		this.setState({
			hovering: true
		})
	}

	toggleMouseLeave(){
		this.setState({
			hovering: false
		})
	}


	render(){
		let c = this.props.c;
		let isHovering = this.state.hovering;
		return (
			<div className="relative flex flex-row col-span-2 border-b border-black justify-between" onMouseOver={this.toggleMouseEnter} onMouseOut={this.toggleMouseLeave}>
				<div className="flex"><DataEntry color="black">{c.chemical_component?.name}</DataEntry></div>
				<div className="flex"><DataEntry color="black">{`${c.amount}%`}</DataEntry></div>
				{isHovering && c.chemical_component?.bio != null && c.chemical_component?.bio.length > 0 && 
					<div className="absolute flex flex-col z-50 top-0 left-0 mt-4 ml-16 mx-auto border border-black-10 px-2 py-2 bg-hover-bg w-48 h-auto shadow-lg rounded-lg">
						<div className="flex"><SmallBody className="bold-font text-black">{c.chemical_component?.name}</SmallBody></div>
						<div className="flex"><SmallBody className="text-black">{c.chemical_component?.bio}</SmallBody></div>
					</div>
				}
			</div>
		)
	}
}


@observer
class IngredientAcidComponentEntry extends Component{
	constructor(props){
		super(props);
		this.toggleMouseEnter = this.toggleMouseEnter.bind(this);
		this.toggleMouseLeave = this.toggleMouseLeave.bind(this);
		this.state = {
			hovering: false
		}
	}

	toggleMouseEnter(){
		this.setState({
			hovering: true
		})
	}

	toggleMouseLeave(){
		this.setState({
			hovering: false
		})
	}


	render(){
		let c = this.props.c;
		let isHovering = this.state.hovering;
		return (
			<div className="relative flex flex-row col-span-2 border-b border-black justify-between" onMouseOver={this.toggleMouseEnter} onMouseOut={this.toggleMouseLeave}>
				<div className="flex"><DataEntry color="black">{c.acid_component?.name}</DataEntry></div>
				<div className="flex"><DataEntry color="black">{`${c.amount}%`}</DataEntry></div>
				{isHovering && c.acid_component?.bio != null && c.acid_component?.bio.length > 0 && 
					<div className="absolute flex flex-col z-50 top-0 left-0 mt-4 ml-16 mx-auto border border-black-10 px-2 py-2 bg-hover-bg w-48 h-auto shadow-lg rounded-lg">
						<div className="flex"><SmallBody className="bold-font text-black">{c.acid_component?.name}</SmallBody></div>
						<div className="flex"><SmallBody className="text-black">{c.acid_component?.bio}</SmallBody></div>
					</div>
				}
			</div>
		)
	}
}

@observer
export class IngredientBreakdown extends Component{
	constructor(props){
		super(props);
		this.renderChemicalFamilyPieChartToImage = this.renderChemicalFamilyPieChartToImage.bind(this);
		this.renderAcidFamilyPieChartToImage = this.renderAcidFamilyPieChartToImage.bind(this);
	}

	renderChemicalFamilyPieChartToImage(id, pieChartRef){
		if(pieChartRef != null && pieChartRef.chartRef != null && pieChartRef.chartRef.children != null){
			let svgHtml = pieChartRef.chartRef.children[0];
			htmlToImage.toPng(svgHtml)
		  		.then((dataUrl) => {
		  			if('setIngredientChemicalFamilyPieChart' in this.props){
		  				this.props.setIngredientChemicalFamilyPieChart(id, dataUrl);
		  			}
		  		})
		}
	}

	renderAcidFamilyPieChartToImage(id, pieChartRef){
		if(pieChartRef != null && pieChartRef.chartRef != null && pieChartRef.chartRef.children != null){
			let svgHtml = pieChartRef.chartRef.children[0];
			htmlToImage.toPng(svgHtml)
		  		.then((dataUrl) => {
		  			this.props.setIngredientAcidFamilyPieChart(id, dataUrl);
		  		})
		}
	}

	render(){
		let translate = this.props.t;
		let ingredient = this.props.ingredient;
		return (
			<div className="flex flex-col rounded-lg bg-white py-8 mr-16" style={{ width: PANEL_WIDTH, minWidth: PANEL_WIDTH, maxWidth: PANEL_WIDTH}}>
				<div className="flex flex-col relative h-64 px-6 bg-white">
					{this.props.editMode && 
						<div className="absolute flex flex-row items-center top-0 right-0 cursor-pointer" onClick={() => this.props.onRemove(ingredient.id)}>
							<div className="flex mr-1"><SmallBody className="text-nav-inactive-color">{translate('user.manage-ingredient.remove-button')}</SmallBody></div>
							<div className="flex"><ExitIcon className="w-6 h-6 mr-4" color={colors['black']} /></div>
						</div>
					}
					<div className="flex items-center justify-center mt-8">
						<ImageAvatar 
							disabled={true}
							t={translate}
							noborder
							url={ingredient.photo_url}
							backupAvatarUrl={this.props.ingredientAvatarUrl}
							/>
					</div>
					<div className="flex flex-col text-center items-center justify-center mt-4">
						<SecondaryAppSmallTitle>{ingredient.name}</SecondaryAppSmallTitle>
						<div className="flex"><DataEntry color="black" color="black"><span className="italic">{ingredient.latin_name}</span></DataEntry></div>
					</div>
				</div>
				<IngredientDataSection ingredient={ingredient} auto>
					<DataRow includeBorder label={translate('user.manage-ingredient.country-of-origin.label')}>
						<DataEntry color="black">{ingredient.origin}</DataEntry>
					</DataRow>
					<DataRow includeBorder label={translate('user.manage-ingredient.type.label')}>
						<DataEntry color="black">{ingredient.ingredient_type?.name}</DataEntry>
					</DataRow>
					<DataRow includeBorder label={translate('user.manage-ingredient.benchmark-price-15ml.label')}>
						<DataEntry color="black">{ingredient.benchmark_price_per_15ml != null && `$${parseFloat(ingredient.benchmark_price_per_15ml).toFixed(2)}`}</DataEntry>
					</DataRow>
				</IngredientDataSection>
				<SectionBreak title={translate('user.manage-ingredient.botanical-family.label')} />
				<IngredientDataSection ingredient={ingredient}>
					<DataRowWrapper>
						<DataRow includeBorder label={translate('user.manage-ingredient.botanical-family.label')}>
							<DataEntry color="black">{ingredient.botanical_family}</DataEntry>
						</DataRow>
					</DataRowWrapper>
					<DataRowWrapper>
						<DataRow includeBorder label={translate('user.manage-ingredient.part-of-plant.label')}>
							<DataEntry color="black">{ingredient.part_of_plant}</DataEntry>
						</DataRow>
					</DataRowWrapper>
					<DataRowWrapper>
						<DataRow includeBorder label={translate('user.manage-ingredient.safety-note.label')}>
							<DataEntry color="black">{ingredient.safety_note}</DataEntry>
						</DataRow>
					</DataRowWrapper>
					<DataRowWrapper>
						<DataRow includeBorder label={translate('user.manage-ingredient.conservation-status.label')}>
							<DataEntry color="black">{ingredient.conservation_status?.name}</DataEntry>
						</DataRow>
					</DataRowWrapper>
					<DataRowWrapper>
						<DataRow includeBorder label={translate('user.manage-ingredient.profile.label')}>
							<DataEntry color="black">{ingredient.profile}</DataEntry>
						</DataRow>
					</DataRowWrapper>
				</IngredientDataSection>
				<SectionBreak title={translate('user.manage-ingredient.therapeutic-properties.label')} />
				<IngredientDataSection ingredient={ingredient}>
					<DataEntry color="black">{ingredient.therapeutic_properties}</DataEntry>
				</IngredientDataSection>
				{!ingredient.ingredient_type?.is_fatty_acid && 
					<>
						<SectionBreak title={translate('user.manage-ingredient.chemical-breakdown.family-title')} />
						<IngredientDataSection ingredient={ingredient}>
							<div className="flex flex-col">
								{ingredient.chemical_family_percentage_breakdown.length == 0 && 
									<div className="flex w-full justify-center mt-2">
										<SmallBody>{translate('user.manage-ingredient.chemical-families.nodata')}</SmallBody>
									</div>
								}
								<PieChart
									ref={(ref) => { 
										this.renderChemicalFamilyPieChartToImage(ingredient.id, ref);
									}}
									showLabels={false}
									data={ingredient.chemical_family_percentage_breakdown.map((d) => {
									  	return {
									  		x: d.chemical_family.name, 
									  		y: d.percentage,
									  		family: d.chemical_family,
									  		color: d.chemical_family.hex_color
									  	}
									 })}
									onClick={(props) => console.log(props)}
								/>
								<div className="grid grid-cols-2 col-gap-4 row-gap-2 mx-4">
									{ingredient.chemical_family_percentage_breakdown.map((data) => 
										<div className="flex flex-row items-center">
											<div className="w-3 h-3 border-radius-50" style={{ backgroundColor: data.chemical_family.hex_color}}></div>
											<div className="flex ml-4"><ExtraSmallBody>{data.chemical_family.name} - {data.percentage}%</ExtraSmallBody></div>
										</div>
									)}
								</div>
							</div>
						</IngredientDataSection>
					</>
				}
				{!ingredient.ingredient_type?.is_fatty_acid && 
					<>
						<SectionBreak title={translate('user.manage-ingredient.chemical-breakdown.title')} />
						<IngredientDataSection ingredient={ingredient}>
							<div className="grid grid-cols-2 col-gap-8 row-gap-0 mt-4">
								<div className="flex flex-row col-span-2 border-b border-black justify-between">
									<DataHeader noborder>{translate('user.manage-ingredient.chemical-breakdown.chemical-component')}</DataHeader>
									<DataHeader noborder>{translate('user.manage-ingredient.chemical-breakdown.amount')}</DataHeader>
								</div>
								{ingredient.chemical_components.map((c) => 
									<IngredientChemicalComponentEntry c={c} />
								)}
								{ingredient.chemical_components.length == 0 &&
									<div className="col-span-2 mt-2">
										{translate('user.manage-ingredient.chemical-breakdown.nodata')}
									</div>
								}
							</div>
						</IngredientDataSection>
					</>
				}
				{ingredient.ingredient_type?.is_fatty_acid && 
					<>
						<>
							<SectionBreak title={translate('user.manage-ingredient.acid-breakdown.family-title')} />
							<IngredientDataSection ingredient={ingredient}>
								<div className="flex flex-col mt-2">
									{ingredient.acid_family_percentage_breakdown.length == 0 && 
										<div className="flex w-full justify-center">
											<SmallBody>{translate('user.manage-ingredient.acid-families.nodata')}</SmallBody>
										</div>
									}
									<PieChart
										ref={(ref) => { 
											this.renderAcidFamilyPieChartToImage(ingredient.id, ref);
										}}
										showLabels={false}
										data={ingredient.acid_family_percentage_breakdown.map((d) => {
										  	return {
										  		x: d.acid_family.name, 
										  		y: d.percentage,
										  		family: d.acid_family,
										  		color: d.acid_family.hex_color
										  	}
										 })}
										onClick={(props) => console.log(props)}
									/>
									<div className="grid grid-cols-2 col-gap-4 row-gap-2 mx-4">
										{ingredient.acid_family_percentage_breakdown.map((data) => 
											<div className="flex flex-row items-center">
												<div className="w-3 h-3 border-radius-50" style={{ backgroundColor: data.acid_family.hex_color}}></div>
												<div className="flex ml-4"><ExtraSmallBody>{data.acid_family.name} - {data.percentage}%</ExtraSmallBody></div>
											</div>
										)}
									</div>
								</div>
							</IngredientDataSection>
						</>
						<SectionBreak title={translate('user.manage-ingredient.acid-breakdown.title')} />
						<IngredientDataSection ingredient={ingredient}>
							<div className="grid grid-cols-2 col-gap-8 row-gap-0 mt-4">
								<div className="flex flex-row col-span-2 border-b border-black justify-between">
									<DataHeader noborder>{translate('user.manage-ingredient.acid-breakdown.acid-component')}</DataHeader>
									<DataHeader noborder>{translate('user.manage-ingredient.acid-breakdown.amount')}</DataHeader>
								</div>
								{ingredient.acid_components.map((c) => 
									<IngredientAcidComponentEntry c={c} />
								)}
								{ingredient.acid_components.length == 0 &&
									<div className="col-span-2 mt-2">
										{translate('user.manage-ingredient.acid-breakdown.nodata')}
									</div>
								}
							</div>
						</IngredientDataSection>
					</>
				}
				<div className="flex flex-col items-center justify-center py-8 px-6">
					<div className="grid grid-cols-3 gap-2 flex flex-row items-center">
						<div className="col-span-2">
							<FormSearchSelect
								placeholder={translate('user.comparison-tool.add-to-blend.placeholder')}
								value={ingredient.blendSearchText.value}
								hasSelection={ingredient.selectedBlend.value != null}
								onChange={(val) => this.props.onChangeBlendSearchText(ingredient.id, val)}
								onClear={() => this.props.onClearSelectedBlend(ingredient.id)}
								options={ingredient.blendOptions.value}
								onSelection={(blendId) => this.props.onBlendSelection(ingredient.id, blendId)}
							 />
						</div>

						 <div className="flex w-full -mr-4">
							<div className="flex">
								<FormInput
									extraInputClassNames="rounded-r-none" 
									placeholder={translate('user.comparison-tool.volume.placeholder')}
									value={ingredient.volume.value}
									error={ingredient.volume.error}
									onChange={(e) => ingredient.volume.onChange(e.target.value)}
								/>
							</div>
							<div className="flex">
								<FormSelect 
									className="rounded-l-none rounded-lg border border-black-10 bg-white"
									placeholder={translate('user.comparison-tool.placeholder')}
									options={this.props.measurementOptions}
									value={ingredient.selectedVolumeMeasurement.value}
									error={ingredient.selectedVolumeMeasurement.error}
									onChange={(option) => ingredient.selectedVolumeMeasurement.onChange(option.value)}
								/>
							</div>
						</div>

						{ingredient.canAddToBlend && 
						 	<div className="col-span-3">
								 <Button
									width="w-full"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									fetching={this.props.fetching}
									onClick={() => this.props.addIngredientToBlend(ingredient.id, ingredient.selectedBlend.value)}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.comparison-tool.add-blend-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><PlusIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</div>
						}
						
					</div>
					<div className="flex w-full flex-col mt-4">
						<Button
								width="w-full"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="pink-coral"
								fgColor="white"
								fetching={false}
								onClick={() => this.props.addIngredientToNewBlend(ingredient.id)}
							>
								<div className="flex flex-1 flex-row relative justify-center items-center cursor-pointer">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.comparison-tool.add-new-blend-button')}</TertiaryAppSmallTitle></div>
									<div className="flex"><PlusIcon className="w-4 h-4" color="white" /></div>

								</div>
							</Button>
					</div>
					<div className="flex w-full flex-col mt-4">
						{!ingredient.favourited && 
							<Button
								width="w-full"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="dark-green-60"
								fgColor="white"
								fetching={false}
								onClick={() => this.props.onToggleFavourite(ingredient.id)}
							>
								<div className="flex flex-1 flex-row relative justify-center items-center cursor-pointer">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.comparison-tool.favourite')}</TertiaryAppSmallTitle></div>
									<div className="absolute right-0"><StarIcon className="w-4 h-4" color="white" /></div>

								</div>
							</Button>
						}
						{ingredient.favourited && 
							<Button
								width="w-full"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="list-text"
								fgColor="white"
								fetching={false}
								onClick={() => this.props.onToggleFavourite(ingredient.id)}
							>
								<div className="flex flex-1 flex-row relative justify-center items-center cursor-pointer">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.comparison-tool.unfavourite')}</TertiaryAppSmallTitle></div>
									<div className="absolute right-0"><ExitIcon className="w-4 h-4" color="white" /></div>

								</div>
							</Button>
						}
					</div>
				</div>
			</div>
		)
	}
}

@observer
class ChooseIngredientPanel extends Component{
	render(){
		let translate = this.props.t;
		return (
			<div className="flex flex-col bg-white rounded-lg px-6 h-auto py-8" style={{ width: PANEL_WIDTH, height: '500px'}}>
				<div className="flex items-start mt-4">
					<SecondaryAppSmallTitle>{translate('user.comparison-tool.choose-ingredient.title')}</SecondaryAppSmallTitle>
				</div>
				<div className="flex flex-col mt-4">
					<TertiaryAppSmallTitle>{translate('user.comparison-tool.choose-ingredient.name.label')}</TertiaryAppSmallTitle>
					<FormSearchSelect
						placeholder={translate('user.comparison-tool.choose-ingredient.name.placeholder')}
						value={this.props.value}
						hasSelection={this.props.hasSelection}
						onChange={this.props.onChange}
						onClear={this.props.onClear}
						options={this.props.options}
						onSelection={this.props.onSelection}
					 />
				</div>
				{this.props.hasSelection && 
					<div className="flex w-full flex-row justify-end mt-4">
						<ApplyText t={translate} onClick={this.props.onApply}/>
					</div>
				}
			</div>
		)
	}
}

const renderIngredientPDF = (ingredient, 
								nameTitle, latinNameTitle, 
								originTitle, chemicalFamilyBreakdownTitle,
								ingredientTypeTitle, botanicalFamilyTitle, benchmarkPricePer15mlTitle,
								partOfPlantTitle,
								safetyNoteTitle, 
								conservationStatusTitle,
								profileTitle,
								therapeuticPropertiesTitle,
								comedogenicScaleTitle,
								formulationGuidanceTitle, 
								synergyTitle, 
								energeticsTitle, 
								scentNoteTitle, 
								chemicalComponentBreakdownTitle, 
								acidComponentBreakdownTitle,
								acidFamilyBreakdownTitle, 
								ingredientNotesTitle, 
								psychologicalRankingsTitle,
								physicalRankingsTitle,
								getAvatarUrl) => {
  		let mainView = ( 
  			<View 
  			style={{ 
      			flexDirection: 'column', 
      			width: `30%`,
      			maxWidth: `30%`,
      			height: '100%',
      			borderColor: colors['nav-inactive-color'],
      			borderTopLeftRadius: 5, 
			  	borderTopRightRadius:5, 
			  	borderBottomRightRadius: 5, 
			  	borderBottomLeftRadius: 5,
      			borderWidth: 1,
      			padding: 10,
      			marginTop: 30,
      			marginRight: 10 
      		}}
      		>
      			<View style={[{
      				flexDirection: 'column',
  					alignItems: 'flex-start',
  					width: '100%'
      			}, { marginTop: 10, marginBottom: 10}]}>
	      			<View style={pdfStyles.rowBetweenNoMargin}>
	      				<View style={{
	      					flexDirection: 'column',
	      					width: '75%'
	      				}}>
		      				<Text style={[pdfStyles.tableTitle]}>{ingredient.name}</Text>
		      				<Text style={[pdfStyles.title, pdfStyles.italic]}>{ingredient.latin_name}</Text>
		      			</View>
	      				<Image src={ingredient.photo_url != null ? ingredient.photo_url : getAvatarUrl(ingredient)} style={{
      						width: 50, 
      						height: 50
      					}} />
		      		</View>

      				<View style={{ flexDirection: 'column', width: '100%'}, {marginTop: 30}}>
	      				<View style={[pdfStyles.gridColumn,  pdfStyles.borderBottom]}><Text style={[pdfStyles.title]}>{originTitle}</Text></View>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.origin}</Text></View>
			      		</View>
	      			</View>
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{ingredientTypeTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.ingredient_type?.name}</Text></View>
			      		</View>
	      			</View>
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{benchmarkPricePer15mlTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.benchmark_price_per_15ml != null ? `$${ingredient.benchmark_price_per_15ml}`: null}</Text></View>
			      		</View>
	      			</View>
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{botanicalFamilyTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.botanical_family}</Text></View>
			      		</View>
	      			</View>
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{partOfPlantTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.part_of_plant}</Text></View>
			      		</View>
	      			</View>
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{safetyNoteTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.safety_note}</Text></View>
			      		</View>
	      			</View>
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{conservationStatusTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.conservation_status?.name}</Text></View>
			      		</View>
	      			</View>
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{profileTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.profile}</Text></View>
			      		</View>
	      			</View>
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{therapeuticPropertiesTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.therapeutic_properties}</Text></View>
			      		</View>
	      			</View>
	      			{ingredient.comedogenic_scale?.length > 0 && 
	      				<View style={pdfStyles.gridColumn, {marginTop: 10}}>
		      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{comedogenicScaleTitle}</Text>
		      				<View style={pdfStyles.columnWithSmallMarginTop}>
				      			<View><Text style={pdfStyles.datapoint}>{ingredient.comedogenic_scale}</Text></View>
				      		</View>
		      			</View>
		      		}
	      			{ingredient.formulation_guidance?.length > 0 && 
	      				<View style={pdfStyles.gridColumn, {marginTop: 10}}>
		      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{formulationGuidanceTitle}</Text>
		      				<View style={pdfStyles.columnWithSmallMarginTop}>
				      			<View><Text style={pdfStyles.datapoint}>{ingredient.formulation_guidance}</Text></View>
				      		</View>
		      			</View>
		      		}
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{synergyTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.synergy}</Text></View>
			      		</View>
	      			</View>
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{energeticsTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.energetics}</Text></View>
			      		</View>
	      			</View>
	      			<View style={pdfStyles.gridColumn, {marginTop: 10}}>
	      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{scentNoteTitle}</Text>
	      				<View style={pdfStyles.columnWithSmallMarginTop}>
			      			<View><Text style={pdfStyles.datapoint}>{ingredient.scent_note?.name}</Text></View>
			      		</View>
	      			</View>
	      			{ingredient.chemical_family_percentage_breakdown.length > 0 && 
	      				<View style={pdfStyles.gridColumn, {marginTop: 20}}>
		      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{chemicalFamilyBreakdownTitle}</Text>
		      				{ingredient.chemicalFamilySrc != null && 
	      						<Image src={ingredient.chemicalFamilySrc} style={{
	      							width: '100%'
	      						}} />
	      					}
		      				<View style={pdfStyles.column}>
			      				{ingredient.chemical_family_percentage_breakdown.map((chemicalFamilyBreakdown) =>
			      					<View style={[pdfStyles.fullRow, { marginTop: 20}]}>
							  			<View style={{ flexDirection: 'row', width: '60%'}}>
							  				<View style={[pdfStyles.circle, { backgroundColor: chemicalFamilyBreakdown.chemical_family.hex_color, marginRight: 10}]}></View>
							  				<Text style={pdfStyles.datapoint}>{chemicalFamilyBreakdown.chemical_family.name}</Text>
							  			</View>
										<Text style={pdfStyles.datapoint}>{chemicalFamilyBreakdown.percentage}%</Text>
							  		</View>
			      				)}
			      			</View>
		      			</View>
		      		}
	      			{ingredient.chemical_components.length > 0 && 
	      				<View style={pdfStyles.gridColumn, {marginTop: 20}}>
		      				<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{chemicalComponentBreakdownTitle}</Text>
		      				<View style={pdfStyles.column}>
			      				{ingredient.chemical_components.map((chemicalComponent) =>
			      					<View style={[pdfStyles.fullRow, { marginTop: 20}]}>
							  			<View style={{width: '75%'}}><Text style={pdfStyles.datapoint}>{chemicalComponent.chemical_component.name}</Text></View>
										<Text style={pdfStyles.datapoint}>{chemicalComponent.amount}%</Text>
							  		</View>
			      				)}
			      			</View>
		      			</View>
		      		}
					{ingredient.acid_family_percentage_breakdown.length > 0 && 
						<View style={pdfStyles.gridColumn, {marginTop: 20}}>
							<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{acidFamilyBreakdownTitle}</Text>
							{ingredient.acidFamilySrc != null && 
	      						<Image src={ingredient.acidFamilySrc} style={{
	      							width: '100%'
	      						}} />
	      					}
							<View style={pdfStyles.column}>
			      				{ingredient.acid_family_percentage_breakdown.map((acidFamilyBreakdown) =>
			      					<View style={[pdfStyles.fullRow, { marginTop: 20}]}>
							  			<View style={{ flexDirection: 'row', width: '60%'}}>
							  				<View style={[pdfStyles.circle, { backgroundColor: acidFamilyBreakdown.acid_family.hex_color, marginRight: 10}]}></View>
							  				<Text style={pdfStyles.datapoint}>{acidFamilyBreakdown.acid_family.name}</Text>
							  			</View>
										<Text style={pdfStyles.datapoint}>{acidFamilyBreakdown.percentage}%</Text>
							  		</View>
			      				)}
			      			</View>
						</View>
					}
					{ingredient.acid_components.length > 0 && 
						<View style={pdfStyles.gridColumn, {marginTop: 20}}>
							<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{acidComponentBreakdownTitle}</Text>
							<View style={pdfStyles.column}>
								{ingredient.acid_components.map((acidComponent) =>
									<View style={[pdfStyles.fullRow, { marginTop: 20}]}>
						  			<View style={{width: '75%'}}><Text style={pdfStyles.datapoint}>{acidComponent.acid_component.name}</Text></View>
									<Text style={pdfStyles.datapoint}>{acidComponent.amount}%</Text>
						  		</View>
								)}
							</View>
						</View>
					}
					<View style={pdfStyles.gridColumn, {marginTop: 20}}>
						<View style={pdfStyles.fullRow}><Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{ingredientNotesTitle}</Text></View>
						<View style={pdfStyles.columnWithSmallMarginTop}>
							<View><Text style={pdfStyles.datapoint}>{ingredient.ingredient_notes}</Text></View>
						</View>
					</View>
					<View style={pdfStyles.gridColumn, {marginTop: 20}}>
						<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{physicalRankingsTitle}</Text>
						<View style={pdfStyles.column}>
							{ingredient.physical_rankings.map((physicalRanking) =>
								<View style={[pdfStyles.fullRow, { marginTop: 20}]}>
					  			<View style={{width: '75%'}}><Text style={pdfStyles.datapoint}>{physicalRanking.physical_ranking.name}</Text></View>
								<Text style={pdfStyles.datapoint}>{physicalRanking.score}</Text>
					  		</View>
							)}
						</View>
					</View>
					<View style={pdfStyles.gridColumn, {marginTop: 20}}>
						<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{physicalRankingsTitle}</Text>
						<View style={pdfStyles.column}>
							{ingredient.psychological_rankings.map((psychologicalRanking) =>
								<View style={[pdfStyles.fullRow, { marginTop: 20}]}>
					  			<View style={{width: '75%'}}><Text style={pdfStyles.datapoint}>{psychologicalRanking.psychological_ranking.name}</Text></View>
								<Text style={pdfStyles.datapoint}>{psychologicalRanking.score}</Text>
					  		</View>
							)}
						</View>
					</View>
	      		</View>
      		</View>
      	)
  		return mainView;
}

const CompareIngredientPDF = ({logoSrc, ingredientsTitle, reportNameTitle, reportName, 
								notesTitle, notes, dateTitle, date, ingredients,
								nameTitle, latinNameTitle, originTitle, chemicalFamilyBreakdownTitle,
								ingredientTypeTitle, botanicalFamilyTitle, benchmarkPricePer15mlTitle,
								partOfPlantTitle,
								safetyNoteTitle, 
								conservationStatusTitle,
								profileTitle,
								therapeuticPropertiesTitle,
								comedogenicScaleTitle,
								formulationGuidanceTitle, 
								synergyTitle, 
								energeticsTitle, 
								scentNoteTitle, 
								chemicalComponentBreakdownTitle, 
								acidComponentBreakdownTitle,
								acidFamilyBreakdownTitle, 
								ingredientNotesTitle, 
								psychologicalRankingsTitle,
								physicalRankingsTitle,
								getAvatarUrl}) => {
	let footer = (
		<View style={pdfStyles.rowBetweenNoMargin} wrap={false}>
  			<Image src={logoSrc} style={pdfStyles.logo} />
  			<Text style={[pdfStyles.title, pdfStyles.italic]}>www.labaroma.com</Text>
  		</View>
  	);						
  	let hasFattyAcids = ingredients.filter((i) => i.ingredient_type?.is_fatty_acid).length > 0;
	return (
		<Document creator="LabAroma" producer="LabAroma">
		    <Page orientation="landscape" size="A4" style={pdfStyles.page}>
		      	<View style={pdfStyles.container}>
		      		<View style={pdfStyles.rowBetweenAlignCenter}>
		      			<Image src={logoSrc} style={pdfStyles.logo} />
		      		</View>
		      		<View style={pdfStyles.row}>
			      		<View style={pdfStyles.gridColumn}>
			      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{reportNameTitle}</Text>
			      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{reportName}</Text></View>
			      		</View>
			      		<View style={pdfStyles.gridColumn}>
			      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{notesTitle}</Text>
			      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{notes}</Text></View>
			      		</View>
			      		<View style={pdfStyles.gridColumn}>
			      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{dateTitle}</Text>
			      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{date}</Text></View>
			      		</View>
			      	</View>
			      	<View style={[{
			      		flexDirection: 'row',
			      		width: '100%',
			      		justifyContent: 'space-between'
			      	}, pdfStyles.smallMarginTop]}>
				      	{ingredients.slice(0, 3).map((ingredient) => renderIngredientPDF(ingredient, nameTitle, latinNameTitle, 
								originTitle, chemicalFamilyBreakdownTitle,
								ingredientTypeTitle, botanicalFamilyTitle, benchmarkPricePer15mlTitle,
								partOfPlantTitle,
								safetyNoteTitle, 
								conservationStatusTitle,
								profileTitle,
								therapeuticPropertiesTitle,
								comedogenicScaleTitle,
								formulationGuidanceTitle, 
								synergyTitle, 
								energeticsTitle, 
								scentNoteTitle, 
								chemicalComponentBreakdownTitle, 
								acidComponentBreakdownTitle,
								acidFamilyBreakdownTitle, 
								ingredientNotesTitle, 
								psychologicalRankingsTitle,
								physicalRankingsTitle, getAvatarUrl))}
				    </View>
				    {footer}
			    </View>
			</Page>
			{ingredients.length > 3 && 
				<Page orientation="landscape" size="A4" style={pdfStyles.page}>
					<View style={pdfStyles.container}>
						<View style={[{
				      		flexDirection: 'row',
				      		width: '100%',
				      		justifyContent: 'space-between'
				      	}, pdfStyles.smallMarginTop]}>
					      	{ingredients.slice(3, 6).map((ingredient) => renderIngredientPDF(ingredient, nameTitle, latinNameTitle, 
									originTitle, chemicalFamilyBreakdownTitle,
									ingredientTypeTitle, botanicalFamilyTitle, benchmarkPricePer15mlTitle,
									partOfPlantTitle,
									safetyNoteTitle, 
									conservationStatusTitle,
									profileTitle,
									therapeuticPropertiesTitle,
									comedogenicScaleTitle,
									formulationGuidanceTitle, 
									synergyTitle, 
									energeticsTitle, 
									scentNoteTitle, 
									chemicalComponentBreakdownTitle, 
									acidComponentBreakdownTitle,
									acidFamilyBreakdownTitle, 
									ingredientNotesTitle, 
									psychologicalRankingsTitle,
									physicalRankingsTitle, getAvatarUrl))}
					    </View>
					    {footer}
					</View>
				</Page>
			}
			{ingredients.length > 6 && 
				<Page orientation="landscape" size="A4" style={pdfStyles.page}>
					<View style={pdfStyles.container}>
						<View style={[{
				      		flexDirection: 'row',
				      		width: '100%',
				      		justifyContent: 'space-between'
				      	}, pdfStyles.smallMarginTop]}>
					      	{ingredients.slice(6, 9).map((ingredient) => renderIngredientPDF(ingredient, nameTitle, latinNameTitle, 
									originTitle, chemicalFamilyBreakdownTitle,
									ingredientTypeTitle, botanicalFamilyTitle, benchmarkPricePer15mlTitle,
									partOfPlantTitle,
									safetyNoteTitle, 
									conservationStatusTitle,
									profileTitle,
									therapeuticPropertiesTitle,
									comedogenicScaleTitle,
									formulationGuidanceTitle, 
									synergyTitle, 
									energeticsTitle, 
									scentNoteTitle, 
									chemicalComponentBreakdownTitle, 
									acidComponentBreakdownTitle,
									acidFamilyBreakdownTitle, 
									ingredientNotesTitle, 
									psychologicalRankingsTitle,
									physicalRankingsTitle, getAvatarUrl))}
					    </View>
					    {footer}
					</View>
				</Page>
			}
		</Document>
	)
}


@inject('ingredientCompareToolStore')
@observer
class IngredientComparisonTool extends Component{
	componentDidMount(){
		let ingredientCompareToolStore = this.props.ingredientCompareToolStore;

		if('comparisonReportId' in this.props){
			ingredientCompareToolStore.formSync(this.props.comparisonReportId, this.props.editMode);
		}

		if('ingredientIds' in this.props && this.props.ingredientIds != null){
			ingredientCompareToolStore.fetchIngredientsWithIds(this.props.ingredientIds);
		}
	}

	componentWillUnmount(){
		let ingredientCompareToolStore = this.props.ingredientCompareToolStore;
		ingredientCompareToolStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let ingredientCompareToolStore = this.props.ingredientCompareToolStore;
		let manageMode = ingredientCompareToolStore.addMode || ingredientCompareToolStore.editMode;

		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<BaseModal
						small
			            isOpen={ingredientCompareToolStore.showSaveModal}
			            onClose={() => ingredientCompareToolStore.hideSaveModal()}
			            label="Save Modal"
			        >
			        	<div className="flex flex-1 w-720-px h-auto overflow-y-scroll bg-white rounded-lg shadow-sm">
							<div className="flex w-full py-8 px-8 mt-4 flex-col">
			        			<SecondaryAppMediumTitle className="text-nav-active-color">{translate('user.comparison-tool.save-tagline')}</SecondaryAppMediumTitle>
			        			<div className="flex w-full mt-2">
				        			<DataRow label={translate('user.comparison-tool.name.label')}>
										<FormInput 
											placeholder={translate('user.comparison-tool.name.placeholder')}
											value={ingredientCompareToolStore.name.value}
											error={ingredientCompareToolStore.name.error}
											onChange={(e) => ingredientCompareToolStore.name.onChange(e.target.value)}
										/>
									</DataRow>
								</div>
								<div className="flex w-full mt-4">
									<DataRow label={translate('user.comparison-tool.comparisons.label')}>
										<div className="flex w-full flex-col">
											{ingredientCompareToolStore.comparisons.map((ingredient) => 
												<div className="flex flex-row items-center mt-2">
													<div className="w-3 h-3 border-radius-50" style={{ backgroundColor: ingredient.hex_color}}></div>
													<div className="flex ml-4"><SmallBody>{ingredient.name}</SmallBody></div>
												</div>
											)}
										</div>
									</DataRow>
								</div>
								<div className="flex w-full mt-4">
									<DataRow label={translate('user.comparison-tool.notes.label')}>
										<FormTextArea 
											placeholder={translate('user.comparison-tool.notes.placeholder')}
											value={ingredientCompareToolStore.notes.value}
											error={ingredientCompareToolStore.notes.error}
											onChange={(e) => ingredientCompareToolStore.notes.onChange(e.target.value)}
										/>
									</DataRow>
								</div>
								<div className="flex w-full justify-center mt-8">
									<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="pink-coral"
										disabled={!ingredientCompareToolStore.canSaveReport}
										fetching={ingredientCompareToolStore.fetching}
										onClick={() => ingredientCompareToolStore.save()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.comparison-tool.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
										</div>
									</Button>
								</div>
			        		</div>
			        	</div>
		        	</BaseModal>
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.comparison-tool.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.comparison-tool.paragraph.part1')} <br /> {translate('user.comparison-tool.paragraph.part2')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8">
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.comparison-tool.video-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex ml-4">
							<EditIngredientIcon className="w-32 h-32" />
						</div>
					</div>

					<div className="flex flex-row w-full justify-end mt-8">
						{manageMode && 
							<>
								{ingredientCompareToolStore.editMode &&
									<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="transparent"
										fgColor="pink-coral"
										fetching={ingredientCompareToolStore.fetching}
										onClick={() => ingredientCompareToolStore.cancelEditMode()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.comparison-tool.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
										</div>
									</Button>
								}

								<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									fetching={ingredientCompareToolStore.fetching}
									onClick={() => ingredientCompareToolStore.displaySaveModal()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.comparison-tool.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</>
						}
						{ingredientCompareToolStore.viewMode && 
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="pink-coral"
								fetching={ingredientCompareToolStore.fetching}
								onClick={() => ingredientCompareToolStore.toggleEditMode()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.comparison-tool.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						}
					</div>

					{ingredientCompareToolStore.viewMode && 
						<div className="flex flex-row w-full relative">
							<div className="absolute top-0 right-0 mr-4 mt-4 cursor-pointer">
								<DownloadPDF 
									t={translate}
									isJapanese={ingredientCompareToolStore.appStore.isJapaneseUser}
									document={ingredientCompareToolStore.canDownloadPDF ? 
										(<CompareIngredientPDF 
											logoSrc={logoSrc} 
											ingredientsTitle={translate("user.advanced-search.refine-search.filter-options.ingredients")}
											reportNameTitle={translate('user.comparison-tool.name.label')}
											reportName={ingredientCompareToolStore.name.value}
											notesTitle={translate('user.comparison-tool.notes.label')}
											notes={ingredientCompareToolStore.notes.value}
											ingredients={ingredientCompareToolStore.comparisons}
											dateTitle={translate('user.manage-ingredient.created-on')}
											date={ingredientCompareToolStore.createdOn?.format('LLL')}
											nameTitle={translate('user.manage-ingredient.name.label')}
											latinNameTitle={translate('user.manage-ingredient.latin-name.label')}
											originTitle={translate('user.manage-ingredient.country-of-origin.label')}
											chemicalFamilyBreakdownTitle={translate('user.manage-ingredient.chemical-families.title')}
											acidFamilyBreakdownTitle={translate('user.manage-ingredient.acid-families.title')}
											ingredientTypeTitle={translate('user.manage-ingredient.type.label')}
											benchmarkPricePer15mlTitle={translate('user.manage-ingredient.benchmark-price-15ml.label')}
											botanicalFamilyTitle={translate('user.manage-ingredient.botanical-family.label')}
											partOfPlantTitle={translate('user.manage-ingredient.part-of-plant.label')}
											safetyNoteTitle={translate('user.manage-ingredient.safety-note.label')}
											conservationStatusTitle={translate('user.manage-ingredient.conservation-status.label')}
											profileTitle={translate('user.manage-ingredient.profile.label')}
											therapeuticPropertiesTitle={translate('user.manage-ingredient.therapeutic-properties.label')}
											comedogenicScaleTitle={translate('user.manage-ingredient.comedogenic-scale.label')}
											formulationGuidanceTitle={translate('user.manage-ingredient.formulation-guidance.label')}
											synergyTitle={translate('user.manage-ingredient.synergy.label')}
											energeticsTitle={translate('user.manage-ingredient.energetics.label')}
											scentNoteTitle={translate('user.manage-ingredient.scent-notes.label')}
											chemicalComponentBreakdownTitle={translate('user.manage-ingredient.chemical-breakdown.title')}
											acidComponentBreakdownTitle={translate('user.manage-ingredient.acid-breakdown.title')}
											ingredientNotesTitle={translate('user.manage-ingredient.ingredient-notes.label')}
											psychologicalRankingsTitle={translate('user.manage-ingredient.psychological-rankings.label')}
											physicalRankingsTitle={translate('user.manage-ingredient.physical-rankings.label')}
											getAvatarUrl={(ingredient) => ingredientCompareToolStore.getAvatarUrl(ingredient, true)}
										/>) : null
									}
									filename={ingredientCompareToolStore.compareIngredientPDFName}
									onDownload={() => console.log('download')}
									onPrint={() => console.log('print')}
								/>
							</div>
						</div>
					}

					<div className="flex flex-row overflow-x-scroll mt-16 relative">
						{ingredientCompareToolStore.comparisons.map((ingredient) => 
							<>
								<IngredientBreakdown
									t={translate}
									key={ingredient.id}
									ingredient={ingredient}
									ingredientAvatarUrl={ingredientCompareToolStore.getAvatarUrl(ingredient)}
									editMode={ingredientCompareToolStore.editMode || ingredientCompareToolStore.addMode}
									onRemove={(id) => ingredientCompareToolStore.removeComparison(id)}
									onToggleFavourite={(id) => ingredientCompareToolStore.toggleFavourite(id)}
									measurementOptions={ingredientCompareToolStore.measurementOptions}
									onChangeBlendSearchText={(id, val) => ingredientCompareToolStore.onChangeBlendSearchText(id, val)}
									onClearSelectedBlend={(id) => ingredientCompareToolStore.onClearSelectedBlend(id)}
									onBlendSelection={(id, blendId) => ingredientCompareToolStore.onBlendSelection(id, blendId)}
									addIngredientToBlend={(id, blendId) => ingredientCompareToolStore.addIngredientToBlend(id, blendId)}
									addIngredientToNewBlend={(id) => ingredientCompareToolStore.addIngredientToNewBlend(id)}
									setIngredientChemicalFamilyPieChart={(id, dataUrl) => ingredientCompareToolStore.setIngredientChemicalFamilyChartSrc(id, dataUrl)}
									setIngredientAcidFamilyPieChart={(id, dataUrl) => ingredientCompareToolStore.setIngredientAcidFamilyChartSrc(id, dataUrl)}

								/>
							</>
						)}
						{(ingredientCompareToolStore.addMode || ingredientCompareToolStore.editMode) && 
							<ChooseIngredientPanel 
								t={translate}
								value={ingredientCompareToolStore.ingredientSearchText}
								hasSelection={ingredientCompareToolStore.selectedIngredientOption != null}
								onChange={(val) => ingredientCompareToolStore.onChangeIngredientSearchText(val)}
								onClear={() => ingredientCompareToolStore.onClearSelectedIngredient()}
								options={ingredientCompareToolStore.ingredientOptions}
								onSelection={(id) => ingredientCompareToolStore.onSelectIngredient(id)}
								onApply={() => ingredientCompareToolStore.onAddToComparison()}
							/>
						}
					</div>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(IngredientComparisonTool);