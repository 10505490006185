import { observable, action, computed } from 'mobx';

import MarketingAssetApi from '../../../services/marketing-asset';
import TagApi from '../../../services/tag';

const VIEW_MODE = {
	TILE: 'tile',
	LIST: 'list'
}

class MarketingAssetSearchStore{
	appStore;

	searchApi;
	marketingAssetApi;
	tagApi;

	@observable query;
	@observable viewMode;
	@observable marketingAssetResults;

	@observable hasMoreMarketingAssetResults;

	@observable hasSearched;
	@observable showSortDialog;
	@observable sortBy;

	@observable currentSearchQuery;
	@observable marketingAssetPage;

	@observable currentMarketingAsset;

	@observable searching;

	constructor(appStore){
		this.appStore = appStore;
		this.marketingAssetApi = new MarketingAssetApi(appStore);
		this.initStore();
	}

	@action initStore(){
		this.viewMode = VIEW_MODE.TILE;
		this.marketingAssetResults = [];
		this.query = null;
		this.showSortDialog = false;
		this.sortBy = this.getDefaultSortBy();
		this.marketingAssetPage = 1;
		this.currentSearchQuery = null;
		this.hasMoreMarketingAssetResults = true;
		this.searching = false;
		this.currentMarketingAsset = null;
		this.hasSearched = false;
	}

	getDefaultSortBy(){
		return this.sortOptions[0].value;
	}

	@action changeToListViewMode(){
		this.viewMode = VIEW_MODE.LIST;
	}

	@action changeToTileViewMode(){
		this.viewMode = VIEW_MODE.TILE;
	}

	@action changeSearchQuery(val){
		this.query = val;
	}

	@action triggerSearch(){
		if(this.query != null && this.query.length > 0){
			this.marketingAssetPage = 1;
			this.performSearch();
		}
	}

	@computed get isListView(){
		return this.viewMode === VIEW_MODE.LIST;
	}

	@computed get isTileView(){
		return this.viewMode === VIEW_MODE.TILE;
	}

	@computed get sortOptions(){
		return [
			{
				value: 'atoz',
				label: this.appStore.i18n.t('user.advanced-search.refine-search.sort-options.atoz')
			},
			{
				value: 'ztoa',
				label: this.appStore.i18n.t('user.advanced-search.refine-search.sort-options.ztoa')
			}
		]
	}

	@computed get currentSortAttribute(){
		if(this.sortBy === null) return null;
		let sortOption = this.sortOptions.find((s) => s.value == this.sortBy);
		return sortOption?.label;
	}

	@action onToggleSortDialog(){
		this.showSortDialog = !this.showSortDialog;
	}

	@action onChangeSortOption(val){
		this.sortBy = val;
	}

	@action onApplySort(){
		this.onToggleSortDialog();
	}

	nextMarketingAssetsPage(){
		this.marketingAssetPage += 1;
		this.performSearch(true);
	}

	previousMarketingAssetsPage(){
		this.marketingAssetPage = Math.max(this.marketingAssetPage - 1, 0);
		this.performSearch(true);
	}

	resetSearch(){
		this.marketingAssetResults = [];
		this.marketingAssetPage = 1;
		this.hasMoreMarketingAssetResults = true;
		this.sortBy = this.getDefaultSortBy();
	}

	searchWithQuery(triggerQuery){
		if(triggerQuery == null || triggerQuery.length == 0) return;
		this.query = triggerQuery;
		this.triggerSearch();
	}

	performSearch(append=false){
		if(this.query == null || this.query.length == 0) return;
		this.currentSearchQuery = this.query;
		this.searching = true;

		this.marketingAssetApi.searchMarketingAssets(this.marketingAssetPage, this.sortBy, this.currentSearchQuery)
			.then((response) => {
				let newMarketingAssets = response.marketing_assets;
				if(!append){
					this.marketingAssetResults = newMarketingAssets;
				}else{
					this.marketingAssetResults = [...this.marketingAssetResults, ...newMarketingAssets]
				}
				this.hasMoreMarketingAssetResults = newMarketingAssets.length > 0;
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.searching = false;
				this.hasSearched = true;
			})
	}

	@computed get canUploadMarketingAsset(){
		return this.appStore.isSuperAdministrator;
	}

	@action goToAddMarketingAsset(){
		this.appStore.goToNewMarketingAsset();
	}

	@action manageMarketingAsset(id){
		this.appStore.goToEditMarketingAsset(id);
	}

	@action viewMarketingAsset(id){
		this.marketingAssetApi.getMarketingAssetById(id)
			.then((response) => {
				this.currentMarketingAsset = response;
			})
			.catch((error) => {
				console.log(error);
			})

	}

	@action editMarketingAsset(id){
		this.appStore.goToEditMarketingAsset(id);
	}

	@action downloadMarketingAsset(id){
		this.marketingAssetApi.getMarketingAssetById(id)
			.then((response) => {
				let downloadMarketingAsset = response;

				let downloadFile = downloadMarketingAsset.marketing_asset_file;
				if(downloadFile == null) return;

				const link = document.createElement('a');
                link.href = downloadFile.file_url;
                link.setAttribute(
                  'download',
                  `LabAroma_${downloadFile.name}`
                );

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
			})
			.catch((error) => {
				console.log(error);
			})

	}

	@action hideCurrentMarketingAsset(){
		this.currentMarketingAsset = null;
	}
}

export default MarketingAssetSearchStore;