import { observable, action, computed} from 'mobx';

import IngredientApi from '../../../services/ingredient';
import moment from 'moment'

class ComponentSearchList{

	appStore;

	@observable currentSearches;
	@observable hasMoreSearches;
	@observable searchesPage;

	@observable editDeleteMode;

	@observable fetching;
	
	ingredientApi;
	
	constructor(appStore){
		this.appStore = appStore;
		this.ingredientApi = new IngredientApi(appStore);
		this.initStore();
	}

	initStore(){
		this.fetching = false;
		this.currentSearches = [];
		this.hasMoreSearches = true;
		this.searchesPage = 1;
		this.editDeleteMode = false;
	}

	toggleEditDeleteMode(){
		this.editDeleteMode = !this.editDeleteMode;
	}

	toComponentSearchReport(report){
		let percentageOf = this.appStore.i18n.t('user.component-search.percentage-of');


		report.created_on = moment(report.created_on);
		report.search_criteria = report.search_criteria.map((searchCriteriaEntry) => {
			let compareSign = searchCriteriaEntry.comparison === 'gte' ? '>=' : '<';
			searchCriteriaEntry.description = `${compareSign} ${searchCriteriaEntry.amount} ${percentageOf}`;
			return searchCriteriaEntry;
		});
		return report;
	}

	fetchSearchReports(reset=false){
		this.fetching = true;

		this.ingredientApi.getAllComponentSearchReports(this.searchesPage, null)
			.then((response) => {
				let newSearches = response.component_search_reports.map((b) => this.toComponentSearchReport(b));

				this.currentSearches = reset ? newSearches : this.currentSearches.concat(newSearches);
				this.hasMoreSearches = newSearches.length > 0;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action loadNextSearchesPage(){
		this.searchesPage += 1;
		this.fetchSearchReports();
	}


	@action goToComponentSearchTool(){
		this.appStore.goToComponentSearch();
	}

	@action onDeleteComponentSearchReport(id){
		this.fetching = true;

		this.ingredientApi.deleteComponentSearchReportById(id)
			.then((response) => {
				this.currentSearches = this.currentSearches.filter((c) => c.id != id);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action onEditComponentSearchReport(id){
		this.appStore.goToEditComponentSearch(id);
	}

}

export default ComponentSearchList;