import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import Main from '../../../elements/main';
import {FullGrid} from '../../../elements/grid';

import {Button} from '../../../common/button';
import {SaveIcon, PlusIcon, ExitIcon, EditIcon} from '../../../common/icon';

import { withAuthorisation } from '../../../common/authorisation';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, COSMETIC_REGULATIONS } from '../../../../services/util';

import {SecondaryAppLargeTitle, SecondaryAppMediumTitle, TertiaryAppSmallTitle, Body, ExtraSmallBody} from '../../../common/text'
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormSearchSelect,
	FormInput,
	FormTextArea,
	FormSelect
} from '../../../elements/form';

import ColorPicker from '../../../common/color-picker';
import UploadImage from '../../../common/upload-image';


@observer
class Regulation extends Component{
	constructor(props){
		super(props)
		this.onChangeComponentSearchText = this.onChangeComponentSearchText.bind(this);
		this.onClearComponentSelection = this.onClearComponentSelection.bind(this);
		this.onSelectComponent = this.onSelectComponent.bind(this);
	}

	onChangeComponentSearchText(val){
		let regulationEntry = this.props.regulationEntry;
		this.props.onChangeComponentSearchText(regulationEntry.uuid, val)
	}

	onClearComponentSelection(){
		let regulationEntry = this.props.regulationEntry;
		this.props.onClearComponentSelection(regulationEntry.uuid)
	}


	onSelectComponent(chemicalComponentId){
		let regulationEntry = this.props.regulationEntry;
		this.props.onChooseComponent(regulationEntry.uuid, chemicalComponentId)
	}

	render(){
		let translate = this.props.t;
		let regulationEntry = this.props.regulationEntry;
		return (
			<FullGrid className="mt-8">
				<div className="grid grid-cols-2 gap-8 mt-8">
					<div className="flex flex-col">
						<DataHeader>{translate('superadmin.manage-regulation.chemical-component.name')}</DataHeader>
						
						<FormSearchSelect
							placeholder={translate('superadmin.manage-regulation.chemical-component.placeholder')}
							value={regulationEntry.componentSearchText}
							hasSelection={regulationEntry.chemical_component_id.value != null}
							onChange={this.onChangeComponentSearchText}
							onClear={this.onClearComponentSelection}
							options={regulationEntry.searchComponentOptions}
							onSelection={this.onSelectComponent}
						 />

					</div>
					<div className="flex flex-col">
						<DataHeader>{translate('superadmin.manage-regulation.regulation-limit.name')}</DataHeader>
						<FormInput
							value={regulationEntry.regulation_limit.value}
							error={regulationEntry.regulation_limit.error}
							onChange={(e) => regulationEntry.regulation_limit.onChange(e.target.value)}
						/>
					</div>
					<div className="col-span-2">
						<div className="flex flex-col">
							<DataHeader>{translate('superadmin.manage-regulation.regulation-information.name')}</DataHeader>
							<FormTextArea
								value={regulationEntry.regulation_information.value}
								error={regulationEntry.regulation_information.error}
								onChange={(e) => regulationEntry.regulation_information.onChange(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-span-2">
						<div className="flex w-full flex-row justify-end mt-2">
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="pink-coral"
								fetching={this.props.fetching}
							>
								<div className="flex flex-row items-center justify-center" onClick={() => this.props.onSave(regulationEntry.uuid)}>
									<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-regulation.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						</div>
					</div>
				</div>
			</FullGrid>
		)
	}
}

@inject('manageRegulation')
@observer
class ManageRegulation extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let manageRegulation = this.props.manageRegulation;
		manageRegulation.formSync(this.props.regionId);
		manageRegulation.fetchRegions();
	}

	componentWillUnmount(){
		let manageRegulation = this.props.manageRegulation;
		manageRegulation.initStore();
	}

	render(){
		let translate = this.props.t;
		let manageRegulation = this.props.manageRegulation;
		let region = manageRegulation.currentRegion != null ? manageRegulation.currentRegion.name: null;
		return (
			<Main>
				<div className="flex flex-1 flex-col"> 
					<div className="flex w-full justify-end mt-8">
						{!manageRegulation.editMode && 
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="pink-coral"
								fetching={manageRegulation.fetching}
								onClick={() => manageRegulation.toggleEditMode()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-regulation.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						}
						{manageRegulation.editMode && 
							<Button
								width="w-auto"
								height="h-12"
								className="ml-4 px-4 rounded-lg text-pink-coral"
								bgColor="bg-list-text"
								fetching={manageRegulation.fetching}
								onClick={() => manageRegulation.cancelEditMode()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.manage-regulation.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><ExitIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						}
					</div>
					<div className="flex flex-1 flex-col mt-8">
						
							<SecondaryAppMediumTitle>{translate('superadmin.manage-regulation.title')} {region}</SecondaryAppMediumTitle>	

							<div className="flex flex-col w-full lg:w-1/2">
								{!manageRegulation.editMode && manageRegulation.regulations.map((regulation) =>
									<FullGrid className="mt-8">
										<div className="grid grid-cols-2 gap-8 mt-8">
											<div className="flex flex-col">
												<DataHeader>{translate('superadmin.manage-regulation.chemical-component.name')}</DataHeader>
												<DataEntry>{regulation.chemical_component.name}</DataEntry>
											</div>
											<div className="flex flex-col">
												<DataHeader>{translate('superadmin.manage-regulation.regulation-limit.name')}</DataHeader>
												<DataEntry>{regulation.regulation_limit}</DataEntry>
											</div>
											<div className="col-span-2">
												<div className="flex flex-col">
													<DataHeader>{translate('superadmin.manage-regulation.regulation-information.name')}</DataHeader>
													<DataEntry>{regulation.regulation_information}</DataEntry>
												</div>
											</div>
										</div>
									</FullGrid>
								)}
								{manageRegulation.editMode && manageRegulation.editRegulations.map((regulationEntry) =>
									<Regulation
										id={regulationEntry.id.value}
										t={translate}
										regulationEntry={regulationEntry}
										manageRegulation={manageRegulation}
										fetching={manageRegulation.fetching}
										onChangeComponentSearchText={(id, val) => manageRegulation.onChangeComponentSearchText(id, val)}
										onChooseComponent={(id, val) => manageRegulation.onChooseComponent(id, val)}
										onClearComponentSelection={(id) => manageRegulation.onClearComponentSelection(id)}
										onSave={(uuid) => manageRegulation.save(uuid)}
									/>
								)}
							</div>
							<div className="flex flex-row items-center mt-8">
								<div className="flex"><PlusIcon className="w-8 h-8" color="black" /></div>
								<div className="flex cursor-pointer" onClick={() => manageRegulation.addNewRegulation()}><TertiaryAppSmallTitle>{translate('superadmin.manage-regulation.new-regulation')}</TertiaryAppSmallTitle></div>
							</div>
					</div>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(ManageRegulation, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR], [COSMETIC_REGULATIONS]));