import { observable, action, computed, autorun } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isEmailValidator, isRequiredValidator, isPasswordValidator, validator } from '../../services/validation';
import CurrencyApi from '../../services/currency';
import UserApi from '../../services/user';
import PlanApi from '../../services/plan';
import SubscriptionApi from '../../services/subscription';
import CouponApi from '../../services/coupon';
import CountryApi from '../../services/country';

import {getDisplayPeriod, sortCountriesAlphabetically} from '../../services/util';

class SignUpStore{
	appStore;

	@observable fullName;
	@observable occupation;
	@observable email;
	@observable confirmEmail;
	@observable password;
	@observable confirmPassword;

	@observable addressLine1;
	@observable addressLine2;
	@observable addressCity;
	@observable addressState;
	@observable addressZip;
	@observable taxId;

	@observable createdUserId;
	@observable discountCode;

	periods;
	@observable subscriptionPeriod;

	@observable termsAndConditions;

	@observable selectedCurrency;
	@observable currencies;


	form;
	@observable isValidForm;

	@observable step;
	@observable fetching;

	@observable plans;
	@observable selectedPlan;

	@observable countries;
	@observable selectedCountry;
	@observable selectedBillingCountry;

	@observable subscribing;
	@observable currentCoupon;
	@observable selectedState;

	@observable referral;

	@observable usdSpotRates;

	currencyApi;
	userApi; 
	planApi;
	subscriptionApi;
	couponApi;
	countryApi;

	constructor(appStore){
		this.appStore = appStore;
		this.userApi = new UserApi(appStore);
		this.currencyApi = new CurrencyApi(appStore);
		this.planApi = new PlanApi(appStore);
		this.subscriptionApi = new SubscriptionApi(appStore);
		this.couponApi = new CouponApi(appStore);
		this.countryApi = new CountryApi(appStore);
		this.initStore();
	}

	initStore(){
		this.fullName = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.full-name')));
		this.email = new FieldState('').validators((val) => isEmailValidator(val, this.appStore.i18n));
		this.confirmEmail = new FieldState('').validators((val) => this.email.value.length > 0 && val != this.email.value && this.appStore.i18n.t('validation.non-matching-email-message'));
		this.password = new FieldState('').validators((val) => isPasswordValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.password')));
		this.confirmPassword = new FieldState('').validators((val) => this.password.value.length > 0 && val != this.password.value && this.appStore.i18n.t('validation.non-matching-password-message'));
		this.cardHolderName = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.cardholder-name'))); 
		this.addressLine1 = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.address-line-1')));
		this.addressLine2 = new FieldState('');
		this.addressCity = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.address-city')));
		this.addressState = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.address-state')));
		this.addressZip = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.address-zip')));
		this.taxId = new FieldState(null);
		this.occupation = new FieldState('');
		this.selectedCurrency = null;
		this.selectedState = null
		this.currencies = [];
		this.form = new FormState({
			fullName: this.fullName,
			email: this.email,
			confirmEmail: this.confirmEmail,
			password: this.password,
			confirmPassword: this.confirmPassword,
			occupation: this.occupation
		});
		this.isValidForm = false;
		this.fetching = false;
		this.step = 1;
		this.discountCode = null;
		this.createdUserId = null;
		this.periods = ['Monthly', 'Annually'];
		this.subscriptionPeriod = 'monthly';
		this.termsAndConditions = false;
		this.plans = [];
		this.selectedPlan = null;
		this.subscribing = false;
		this.currentCoupon = null;
		this.countries = [];
		this.selectedCountry = null;
		this.selectedBillingCountry = null;
		this.referral = null;
		this.usdSpotRates = [];
	}

	fetchCurrencies(){
		return this.currencyApi.getAll()
			.then((response) => {
				this.currencies = response.currencies;

				this.fetchSpotRates();
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchSpotRates(){
		this.currencyApi.getCurrencySpotRates(this.currencies.map((c) => c.name).join(','))
			.then((response) => {
				response.json()
					.then((json) => {
						this.usdSpotRates = json.data;
					})
					.catch((error) => {
						console.log('Error getting spot rates');
					})
			})
			.catch((error) => {
				console.log('Error getting spot rates');
			})
	}

	fetchCountries(){
		return this.countryApi.getAll()
			.then((response) => {
				this.countries = response.countries.sort(sortCountriesAlphabetically);
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action onChangeFullName(val){
		this.fullName.onChange(val);
		this.validateForm();
		this.syncCartDetailsToStorage();
	}

	@action onChangePassword(val){
		this.password.onChange(val);
		this.validateForm();
	}

	@action onChangeConfirmPassword(val){
		this.confirmPassword.onChange(val);
		this.validateForm();
	}	

	@action onChangeOccupation(val){
	 	this.occupation.onChange(val);
	 	this.syncCartDetailsToStorage();
	}

	@action onChangeEmail(val){
		this.email.onChange(val);
		this.validateForm();
	}	

	@action onChangeConfirmEmail(val){
		this.confirmEmail.onChange(val);
		this.validateForm();
	}	

	@action syncCartDetailsToStorage(){
		this.appStore.storeCartDetails({
			currencyId: this.selectedCurrency,
			period: this.subscriptionPeriod,
			planId: this.selectedPlan,
			username: this.fullName.value,
			step: this.step,
			country: this.selectedCountry,
			profession: this.occupation.value
		});
	}

	@action activateUpgradeFlow(){
		if(this.appStore.currentUser == null){
			this.appStore.logout();
		}
		this.email.value = this.appStore.currentUser.email;
		let currentCart = this.appStore.getCurrentCart();
		this.fetchCountries();
		this.fetchCurrencies()
			.then(() => {
				if(currentCart != null){
					this.selectedCurrency = currentCart.currencyId;
					this.selectedPlan = currentCart.planId;
					this.subscriptionPeriod = currentCart.period;
				}else{
					this.selectedCurrency = this.currencies.length > 0 ? this.currencies[0].id : null;
				}

				this.fetchPlans();
			})
	}

	@action syncInformationFromUrl(currencyId, period, planId, username = null, step= null, country=null, profession=null){
		this.fetchCountries();
		this.fetchCurrencies()
			.then(() => {
				this.selectedCurrency = currencyId;
				this.subscriptionPeriod = period;
				this.selectedPlan = planId;

				if(username != null){
					this.onChangeFullName(username);
				}

				if(step != null){
					if(step != 3){
						this.step = step;
					}
				}

				if(country != null){
					this.selectedCountry = country;
				}

				if(profession != null){
					this.occupation.value = profession;
				}

				this.fetchPlans();
			});
	}

	@action onChangeCurrency(val){
		this.selectedCurrency = val;
		this.fetchPlans();
	}

	@action onChangeCountry(val){
		this.selectedCountry = val;
		this.selectedBillingCountry = val;
		this.validateForm();
		this.syncCartDetailsToStorage();
	}

	@action onChangeDiscountCode(val){
		this.discountCode = val;
		this.currentCoupon = null;
	}

	@action onChangeSubscriptionPeriod(val){
		this.subscriptionPeriod = val;
		this.fetchPlans();
	}

	@action onToggleTermsAndConditions(){
		this.termsAndConditions = !this.termsAndConditions;
	}

	validateForm(){
		this.form.validate()
			.then((res) => {
				this.isValidForm = !res.hasError;
				if(this.selectedCountry== null){
					this.isValidForm = false;
				}
			})
	}

	@computed get currencyOptions(){
		return this.currencies.map((c) => {
			return {
				value: c.id,
				label: c.name,
				key: c.id
			}
		})
	}

	@computed get subscriptionOptions(){
		return this.periods.map((x) => {
			return {
				value: x.toLowerCase(),
				label: x
			}
		})
	}

	nextStep(){
		this.step += 1;
		this.syncCartDetailsToStorage();
	}

	onCompleteStep1(){
		this.nextStep();
	}

	onCompleteStep2(){
		if(this.selectedCountry == null){
			this.appStore.alertError(this.appStore.i18n.t('validation.country_required'))
			return;
		}

		this.fetching = true;
		this.userApi.signUp({
			name: this.fullName.value,
			email: this.email.value,
			language: this.appStore.currentLanguage,
			password: this.password.value,
			occupation: this.occupation.value,
			country_id: this.selectedCountry,
			currency_id: this.selectedCurrency
		})
		.then((response) => {
			this.createdUserId = response.id;
			this.syncCartDetailsToStorage();
			// Need to login to get a user token for subsequent subscription
			this.appStore.login(this.email.value, this.password.value, true)
				.then(() => {
					this.nextStep();
				})
		})
		.catch((error) => {
			this.fetching = false;
		})
	}

	fetchPlans(){
		let previousPlanName = this.currentPlan != null ? this.currentPlan.name : null;
		return this.planApi.getSpecificPlans(this.selectedCurrency, this.subscriptionPeriod)
			.then((response) => {
				this.plans = response.plans.map((p) => {
					p.displayPeriod = getDisplayPeriod(p.period);
					p.periodValue = `${p.currency.symbol}${p.period_value}`;
					return p
				});

				let newPlanId = null;
				if(this.selectedPlan != null){
					if(previousPlanName != null){
						// Get plan with matching name in another currency
						let planIdx = this.plans.findIndex((x) => x.name == previousPlanName);
						if(planIdx != -1){
							newPlanId = this.plans[planIdx].id;
						}
					}else{
						if(this.selectedPlan != null){
							newPlanId = this.selectedPlan;
						}
					}
				}

				this.selectedPlan = newPlanId == null ? this.plans[0].id : newPlanId;
				this.syncCartDetailsToStorage();
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get planOptions(){
		return this.plans.map((plan) => {
			return {
				value: plan.id,
				label: plan.name
			}
		})
	}

	onChangePlan(val){
		this.selectedPlan = val;
	}

	@computed get currentPlan(){
		if(this.selectedPlan == null) return null;
		return this.plans.find((x) => x.id == this.selectedPlan);
	}

	@computed get currentPlanPeriodValue(){
		if(this.currentPlan == null) return null;
		if(this.currentCoupon == null) return this.currentPlan.period_value;
		let periodValue = this.currentPlan.period_value;
		if(this.currentCoupon != null){
			if(this.currentCoupon.valid){
				if(this.currentCoupon.currency){
					let currentPlanCurrency = this.currentPlan.currency.name.toLowerCase();
					if(currentPlanCurrency == this.currentCoupon.currency){
						let amountOff = this.currentCoupon.amount_off;
						periodValue = Math.max(periodValue - (amountOff/100), 0);
					}
				}else{
					let percentOff = this.currentCoupon.percent_off;
					periodValue = Math.max(periodValue - (periodValue * (percentOff/100)), 0);
				}
			}
		}
		return periodValue.toFixed(2);

	}

	@computed get planDescription(){
		if(this.currentPlan == null) return '';
		let installmentPeriod = this.subscriptionPeriod == 'annually' ? 'Annual installment' : 'Monthly installment';
		let originalPeriodValue = this.currentPlan.period_value;
		let periodValue = this.currentPlan.period_value;
		let currencySymbol = this.currentPlan.currency.symbol;
		let postfixCouponDescription = null;
		if(this.currentCoupon != null){
			if(this.currentCoupon.valid){
				if(this.currentCoupon.duration == 'once'){
					postfixCouponDescription = `This coupon only applies to the first payment. Future payments will be at the original price of ${currencySymbol}${originalPeriodValue.toFixed(2)}`;
				}else if(this.currentCoupon.duration == 'repeating'){
					if(this.currentCoupon.duration_in_months){
						postfixCouponDescription = `This coupon is only valid for the first ${this.currentCoupon.duration_in_months} months. Future payments after ${this.currentCoupon.duration_in_months} months will be at ${currencySymbol}${originalPeriodValue.toFixed(2)}`;
					}
				}
			}
		}
		let planDescription = `${installmentPeriod} of ${currencySymbol}${this.currentPlanPeriodValue}`;
		return postfixCouponDescription == null ? planDescription : `${planDescription} (${postfixCouponDescription})`;
	}

	@computed get hasTaxComplianceDetails(){
		let hasTaxComplianceDetails = true;
		if(this.addressLine1.value == null || this.addressLine1.value.length == 0){
			hasTaxComplianceDetails = false;
		}
		if(this.addressCity.value == null || this.addressCity.value.length == 0){
			hasTaxComplianceDetails = false;
		}

		if(this.showStateDropdown && this.selectedState == null){
			hasTaxComplianceDetails = false;
		}
		if(!this.showStateDropdown){
			if(this.addressState.value == null || this.addressState.value.length == 0){
				hasTaxComplianceDetails = false;
			}
		}
		if(this.addressZip.value == null || this.addressZip.value.length == 0){
			hasTaxComplianceDetails = false;
		}
		if(this.selectedBillingCountry == null){
			hasTaxComplianceDetails = false;
		}

		if(this.cardHolderName.value == null || this.cardHolderName.value.length == 0){
			hasTaxComplianceDetails = false;
		}
		return hasTaxComplianceDetails;
	}

	@computed get canPlaceOrder(){
		return this.hasTaxComplianceDetails & this.subscriptionPeriod != null && this.termsAndConditions == true && !this.subscribing;
	}

	@action subscribe(paymentMethod){
		if(this.selectedPlan == null) {
			this.appStore.alertError(this.appStore.i18n.t('error.missing-plan-message'));
			return;
		}
		if(this.selectedBillingCountry == null) {
			this.appStore.alertError(this.appStore.i18n.t('error.missing-billing-country-message'));
			return;
		}

		if(this.showStateDropdown && this.selectedState == null){
			this.appStore.alertError(this.appStore.i18n.t('error.missing-state-message'));
			return;
		}
		let billingAddressDetails = {
			address_line_1: this.addressLine1.value,
			address_line_2: this.addressLine2.value,
			city: this.addressCity.value,
			state: this.showStateDropdown ? this.selectedState : this.addressState.value,
			zip: this.addressZip.value,
			billing_country_id: this.selectedBillingCountry
		}
		let taxId = this.taxId.value;
		let cardHolderName = this.cardHolderName.value;
		return this.subscriptionApi.subscribe(this.selectedPlan, paymentMethod,  cardHolderName, billingAddressDetails, taxId, this.discountCode, this.referral);
	}

	setSubscribing(){
		this.subscribing = true;
	}

	getCurrentPlanValue(){
		
		let currentCurrency = this.currencies.find((c) => c.id == this.selectedCurrency);

		if(this.currentPlan == null || this.usdSpotRates.length == 0 || currentCurrency == null){
			return 1;
		}
		let planValue = this.currentPlan.period_value;
		let conversionRate = this.usdSpotRates[currentCurrency.name];
		if(conversionRate == null){
			return 1;
		}
		planValue = parseFloat(planValue / conversionRate).toFixed(2);
		if(isNaN(planValue)){
			planValue = 1;
		}
		if(this.currentPlan.period === 'monthly'){
			planValue = planValue * 12;
		}
		return planValue;

	}

	@action onNextScreen(planColor){
		this.subscribing = false;
		this.appStore.clearCart();
		this.appStore.logConversionEvent(this.getCurrentPlanValue());
		this.appStore.goToPaymentConfirmation(planColor);
		
	}

	@action markSubscribed(){
		let planColor = this.currentPlan == null ? 'flat-blue': this.currentPlan.color;
		this.appStore.getCurrentUser()
			.then(() => {
				this.onNextScreen(planColor);
			});	
	}

	@action subscribedWithPaymentIntent(paymentIntent){
		this.subscriptionApi.verifySubscription(paymentIntent)
			.then((response) => {
				this.markSubscribed();
			})
			.catch((error) => {
				this.appStore.alertError(this.appStore.i18n.t("error.verification-failure-message"));
			})
			.finally(() => {
				this.subscribing = false;
			})
	}

	@action applyDiscountCode(){
		if(this.discountCode != null && this.discountCode.length > 0){
			this.couponApi.getCoupon(this.discountCode)
				.then((response) => {
					let coupon = response;
					if(!coupon.valid){
						this.appStore.alertError(this.appStore.i18n.t("error.invalid-coupon-message"));
					}else{
						this.currentCoupon = coupon;
					}
				})
				.catch((error) => {
					this.appStore.alertError(this.appStore.i18n.t("error.invalid-coupon-message"));
				})
		}
	}

	subscriptionFailed(){
		this.subscribing = false;
	}

	@computed get countryOptions(){
		let viableOptions = this.countries.sort((c1, c2) => {
			if(c1.iso_3166_alpha_2_code === 'US' || c1.iso_3166_alpha_2_code === 'GB') return -1;
			if(c2.iso_3166_alpha_2_code === 'US' || c2.iso_3166_alpha_2_code === 'GB') return 1;
			return 0;
		}).map((c) => {
			return {
				value: c.id,
				label: c.name
			}
		})
		return [
			{
				id: null,
				value: this.appStore.i18n.t("select.choose-country")
			},
			...viableOptions
		]
	}

	@computed get showStateDropdown(){
		return this.billingCountryIsoAlpha2LetterCode === 'US';
	}

	@action onReferral(val){
		this.referral = val;
	}

	@computed get billingCountryIsoAlpha2LetterCode(){
		if(this.selectedBillingCountry == null) return null;
		let foundCountry = this.countries.find((c) => c.id == this.selectedBillingCountry);
		return foundCountry?.iso_3166_alpha_2_code;
	}

	stateOptions(){
		let allStates = [
			this.appStore.i18n.t('common.states.option-alabama'),
			this.appStore.i18n.t('common.states.option-alaska'),
			this.appStore.i18n.t('common.states.option-arizona'),
			this.appStore.i18n.t('common.states.option-arkansas'),
			this.appStore.i18n.t('common.states.option-california'),
			this.appStore.i18n.t('common.states.option-colorado'),
			this.appStore.i18n.t('common.states.option-connecticut'),
			this.appStore.i18n.t('common.states.option-delaware'),
			this.appStore.i18n.t('common.states.option-florida'),
			this.appStore.i18n.t('common.states.option-georgia'),
			this.appStore.i18n.t('common.states.option-hawaii'),
			this.appStore.i18n.t('common.states.option-idaho'),
			this.appStore.i18n.t('common.states.option-illinois'),
			this.appStore.i18n.t('common.states.option-indiana'),
			this.appStore.i18n.t('common.states.option-iowa'),
			this.appStore.i18n.t('common.states.option-kansas'),
			this.appStore.i18n.t('common.states.option-kentucky'),
			this.appStore.i18n.t('common.states.option-louisiana'),
			this.appStore.i18n.t('common.states.option-maine'),
			this.appStore.i18n.t('common.states.option-maryland'),
			this.appStore.i18n.t('common.states.option-massachusetts'),
			this.appStore.i18n.t('common.states.option-michigan'),
			this.appStore.i18n.t('common.states.option-minnesota'),
			this.appStore.i18n.t('common.states.option-mississippi'),
			this.appStore.i18n.t('common.states.option-missouri'),
			this.appStore.i18n.t('common.states.option-montana'),
			this.appStore.i18n.t('common.states.option-nebraska'),
			this.appStore.i18n.t('common.states.option-nevada'),
			this.appStore.i18n.t('common.states.option-new-hampshire'),
			this.appStore.i18n.t('common.states.option-new-jersey'),
			this.appStore.i18n.t('common.states.option-new-mexico'),
			this.appStore.i18n.t('common.states.option-new-york'),
			this.appStore.i18n.t('common.states.option-north-carolina'),
			this.appStore.i18n.t('common.states.option-north-dakota'),
			this.appStore.i18n.t('common.states.option-ohio'),
			this.appStore.i18n.t('common.states.option-oklahoma'),
			this.appStore.i18n.t('common.states.option-oregon'),
			this.appStore.i18n.t('common.states.option-pennsylvania'),
			this.appStore.i18n.t('common.states.option-rhode-island'),
			this.appStore.i18n.t('common.states.option-south-carolina'),
			this.appStore.i18n.t('common.states.option-south-dakota'),
			this.appStore.i18n.t('common.states.option-tennessee'),
			this.appStore.i18n.t('common.states.option-texas'),
			this.appStore.i18n.t('common.states.option-utah'),
			this.appStore.i18n.t('common.states.option-vermont'),
			this.appStore.i18n.t('common.states.option-virginia'),
			this.appStore.i18n.t('common.states.option-washington'),
			this.appStore.i18n.t('common.states.option-west Virginia'),
			this.appStore.i18n.t('common.states.option-wisconsin'),
			this.appStore.i18n.t('common.states.option-wyoming')
		]
		return allStates.map((state) => {
			return {
				value: state,
				label: state
			}
		})
	}

	@action onChangeTaxId(val){
		this.taxId.onChange(val);
	}

}

export default SignUpStore;
