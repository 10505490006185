import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {SecondaryAppMediumTitle, TertiaryAppSmallTitle} from '../../common/text'
import { withAuthorisation } from '../../common/authorisation';

import {Button} from '../../common/button';

import {EditIcon, SaveIcon, ExitIcon} from '../../common/icon';
import {
	DataRow,
	DataEntry,
	FormInput,
	FormTextArea,
	FormSelect
} from '../../elements/form';
import Main from '../../elements/main';
import {ArchivedStatus} from '../../elements/list';
import {FullGrid, Grid} from '../../elements/grid';

import { SUPER_ADMINISTRATOR, DISPLAY_DATE_FORMAT } from '../../../services/util';


@inject('manageOrganisationStore')
@observer
class ManageOrganisation extends Component{
	constructor(props){
		super(props);
		
	}

	componentDidMount(){
		let manageOrganisationStore = this.props.manageOrganisationStore;
		manageOrganisationStore.setOrganisationId(this.props.organisationId);
	}

	componentWillUnmount(){
		let manageOrganisationStore = this.props.manageOrganisationStore;
		manageOrganisationStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let manageOrganisationStore = this.props.manageOrganisationStore;
		let currentOrganisation = manageOrganisationStore.currentOrganisation;
		if(currentOrganisation == null) return null;
		let editMode = manageOrganisationStore.editMode;
		return (
			<Main>
				<div className="flex w-full justify-end mt-8">
					{!editMode && 
						<Button
							width="w-auto"
							height="h-12"
							className="px-4 rounded-lg"
							bgColor="pink-coral"
							onClick={() => manageOrganisationStore.toggleEditMode()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{translate('manage-organisation.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
							</div>
						</Button>
					}
					{editMode && 
						<>
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="pink-coral"
								disabled={!manageOrganisationStore.validForm}
								fetching={manageOrganisationStore.fetching}
								onClick={() => manageOrganisationStore.saveOrganisation()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('manage-organisation.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
							<Button
								width="w-auto"
								height="h-12"
								className="ml-4 px-4 rounded-lg text-pink-coral"
								bgColor="bg-list-text"
								fetching={manageOrganisationStore.fetching}
								onClick={() => manageOrganisationStore.cancelEditMode()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('manage-organisation.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
								</div>
							</Button>
						</>
					}
				</div>
				<div className="flex flex-col lg:flex-row mt-8">
					<div className="flex flex-1"> 
						<FullGrid>
							<div className="flex flex-1 flex-row items-center">
								<div className="flex"><SecondaryAppMediumTitle>{translate('manage-organisation.account-title')}</SecondaryAppMediumTitle></div>
								<div className="flex flex-1 justify-end"><ArchivedStatus t={this.props.t} archived={manageOrganisationStore.currentOrganisation.archived} /></div>
							</div>
							<div className="grid grid-cols-2 gap-8 mt-4">
								<DataRow label={translate('manage-organisation.name.label')}>
									{!editMode && <DataEntry>{currentOrganisation.name}</DataEntry>}
									{editMode && 
										<FormInput 
											value={manageOrganisationStore.organisationData.name.value}
											error={manageOrganisationStore.organisationData.name.error}
											onChange={(e) => manageOrganisationStore.organisationData.name.onChange(e.target.value)}
										/>
									}
								</DataRow>
								<DataRow label={translate('manage-organisation.type.label')}>
									{!editMode && <DataEntry>{currentOrganisation.type}</DataEntry>}
									{editMode && 
										<FormInput 
											value={manageOrganisationStore.organisationData.type.value}
											error={manageOrganisationStore.organisationData.type.error}
											onChange={(e) => manageOrganisationStore.organisationData.type.onChange(e.target.value)}
										/>
									}
								</DataRow>
								<DataRow label={translate('manage-organisation.website.label')}>
									{!editMode && <DataEntry>{currentOrganisation.website}</DataEntry>}
									{editMode && 
										<FormInput 
											value={manageOrganisationStore.organisationData.website.value}
											error={manageOrganisationStore.organisationData.website.error}
											onChange={(e) => manageOrganisationStore.organisationData.website.onChange(e.target.value)}
										/>
									}
								</DataRow>
								<DataRow label={translate('manage-organisation.description.label')}>
									{!editMode && <DataEntry>{currentOrganisation.description}</DataEntry>}
									{editMode && 
										<FormTextArea 
											value={manageOrganisationStore.organisationData.description.value}
											error={manageOrganisationStore.organisationData.description.error}
											onChange={(e) => manageOrganisationStore.organisationData.description.onChange(e.target.value)}
										/>
									}
								</DataRow>
								<DataRow label={translate('manage-organisation.status.label')}>
									{!editMode && <DataEntry>{currentOrganisation.archived ?  translate("superadmin.users.list.archived") : translate("superadmin.users.list.active")}</DataEntry>}
									{editMode && 
										<FormSelect 
											options={manageOrganisationStore.statusOptions}
											value={manageOrganisationStore.organisationData.archived.value}
											onChange={(option) => manageOrganisationStore.organisationData.archived.onChange(option.value)}
										/>
									}
								</DataRow>
								<DataRow label={translate('manage-organisation.users.label')}>
									<DataEntry>{currentOrganisation.no_of_users}</DataEntry>
								</DataRow>
								<DataRow label={translate('manage-organisation.active-users.label')}>
									<DataEntry>{currentOrganisation.no_of_active_users}</DataEntry>
								</DataRow>
							</div>
						</FullGrid>
					</div>
				</div>
			</Main>
		)
	}
}
export default withTranslation()(withAuthorisation(ManageOrganisation, SUPER_ADMINISTRATOR));