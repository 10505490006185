import BaseApi from '../services/base';

class LibraryApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAllLibraryCategories(page, sortBy=null, filterBy=null, owned=null, archived=null){
		let queryUrl = `v1/blends/libraries/categories/search`
		let payload = {
			page: page,
			sort_by: sortBy,
			filter_by: filterBy,
			owned: owned,
			archived: archived
		}

		return this.fetch(queryUrl, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getLibraryCategories = async (sortBy=null, filterBy=null, owned=null, archived=null) => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAllLibraryCategories(page, sortBy, filterBy, owned, archived);
			let data = response.blend_library_categories;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}

	newLibraryCategory(payload){
		return this.fetch('v1/blends/libraries/categories', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	updateLibraryCategory(id, payload){
		return this.fetch(`v1/blends/libraries/categories/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	patchLibraryCategoryById(id, payload){
		return this.fetch(`v1/blends/libraries/categories/${id}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	shareLibraryCategoryById(id, payload){
		return this.fetch(`v1/blends/libraries/categories/${id}/share`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getLibraryCategoryById(libraryCategoryId){
		return this.fetch(`v1/blends/libraries/categories/${libraryCategoryId}`, {
			method: 'GET',
			auth: true
		})
	}
}

export default LibraryApi;