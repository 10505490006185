import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';

import BaseModal from '../common/modal';
import {SmallBody, Body, TertiaryAppSmallTitle, SecondaryAppLargeTitle,SecondaryAppSmallTitle, SecondaryAppMediumTitle} from '../common/text';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormSelect,
	FormPasswordInput, 
	FormTab,
	INPUT_CLASS_NAME
} from '../elements/form';

import {Button} from '../common/button';
import {CreditCardIcon, OpenHandIcon, DotsIcon, ArrowRightIcon, DownloadIcon} from '../common/icon';

import { DISPLAY_DATE_FORMAT } from '../../services/util';

import moment from 'moment';

import {EditIcon, ExitIcon, SaveIcon} from '../common/icon';
import {IMAGE_FILE_TYPES} from '../../services/util';

import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';

import runtimeEnv from '@mars/heroku-js-runtime-env';

import creditCardSrc from '../../assets/img/icons/credit-card.svg';

const colors = require('../../color.js');
const env = runtimeEnv();
const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY);


const stripeInputOptions = {
  style: {
    base: {
      color: colors['list-text'],
      fontSize: '18px',
      "::placeholder": {
        color: colors['list-text']
      }
    },
    invalid: {
      color: colors['error']
    }
  }
}

const cardNumberInputOptions = {
	...stripeInputOptions,
	placeholder: 'xxxx xxxx xxxx xxxx'
}

class NavItemEntry extends Component{
	render(){
		return (
			<div className="flex"  onClick={this.props.onClick}><TertiaryAppSmallTitle className="mt-1">{this.props.children}</TertiaryAppSmallTitle></div>
		)
	}
}

@observer
class AccountSettings extends Component{
	componentDidMount(){
		let userProfileStore = this.props.userProfileStore;
		userProfileStore.fetchInvoices();
	}

	render(){
		let translate = this.props.t;
		let userProfileStore = this.props.userProfileStore;
		let currentUser = userProfileStore.appStore.currentUser;
		if(currentUser == null) return null;
		if(userProfileStore.emailEditMode){
			return (
				<div className="flex flex-1 flex-col">
					<div className="mt-8"></div>
					<SecondaryAppLargeTitle>{translate('user.profile.account-settings.edit-email-mode.title')}</SecondaryAppLargeTitle>

					<div className="flex grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
						<DataRow label={translate('user.profile.account-settings.edit-email-mode.email.label')}>
							<FormInput 
								placeholder={translate('user.profile.account-settings.edit-email-mode.email.placeholder')}
								value={userProfileStore.accountSettingsData.email.value}
								error={userProfileStore.accountSettingsData.email.error}
								onChange={(e) => userProfileStore.accountSettingsData.email.onChange(e.target.value)}
							/>
						</DataRow>
						<DataRow label={translate('user.profile.account-settings.edit-email-mode.confirm-email.label')}>
							<FormInput 
								placeholder={translate('user.profile.account-settings.edit-email-mode.email.placeholder')}
								value={userProfileStore.accountSettingsData.confirmEmail.value}
								error={userProfileStore.accountSettingsData.confirmEmail.error}
								onChange={(e) => userProfileStore.accountSettingsData.confirmEmail.onChange(e.target.value)}
							/>
						</DataRow>
					</div>

					<div className="flex flex-1 flex-col lg:flex-row items-end justify-end mb-12 mt-8 lg:mt-0">
						<Button
							width="w-auto"
							height="h-12"
							className="px-8 rounded-lg"
							bgColor="pink-coral"
							disabled={!userProfileStore.validEmailData}
							fetching={userProfileStore.savingNewEmail}
							onClick={() => userProfileStore.updateEmail()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{translate('user.profile.account-settings.edit-email-mode.button')} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
							</div>
						</Button>
						<Button
								width="w-auto"
								height="h-12"
								className="mt-8 lg:mt-0 ml-0 lg:ml-4 px-4 rounded-lg text-pink-coral"
								bgColor="bg-list-text"
								fetching={userProfileStore.savingNewEmail}
								onClick={() => userProfileStore.cancelEmailEditMode()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.profile.account-settings.edit-email-mode.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><ExitIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
					</div>
				</div>
			)
		}else if(userProfileStore.passwordEditMode){
			return (
				<div className="flex flex-1 flex-col">
					<div className="mt-8"></div>
					<SecondaryAppLargeTitle>{translate('user.profile.account-settings.edit-password-mode.title')}</SecondaryAppLargeTitle>

					<div className="flex flex-row mt-8">
						<DataRow label={translate('user.profile.account-settings.edit-password-mode.current-password.label')}>
							<FormPasswordInput 
								placeholder={translate('user.profile.account-settings.edit-password-mode.current-password.placeholder')}
								value={userProfileStore.changePasswordData.currentPassword.value}
								error={userProfileStore.changePasswordData.currentPassword.error}
								onChange={(e) => userProfileStore.changePasswordData.currentPassword.onChange(e.target.value)}
							/>
						</DataRow>
					</div>

					<div className="flex grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
						<DataRow label={translate('user.profile.account-settings.edit-password-mode.password.label')}>
							<FormPasswordInput 
								placeholder={translate('user.profile.account-settings.edit-password-mode.password.placeholder')}
								value={userProfileStore.changePasswordData.newPassword.value}
								error={userProfileStore.changePasswordData.newPassword.error}
								onChange={(e) => userProfileStore.changePasswordData.newPassword.onChange(e.target.value)}
							/>
						</DataRow>
						<DataRow label={translate('user.profile.account-settings.edit-password-mode.confirm-password.label')}>
							<FormPasswordInput 
								placeholder={translate('user.profile.account-settings.edit-password-mode.password.placeholder')}
								value={userProfileStore.changePasswordData.confirmPassword.value}
								error={userProfileStore.changePasswordData.confirmPassword.error}
								onChange={(e) => userProfileStore.changePasswordData.confirmPassword.onChange(e.target.value)}
							/>
						</DataRow>
					</div>

					<div className="flex flex-1 flex-col lg:flex-row items-end justify-end mb-12 mt-8 lg:mt-0">
						<Button
							width="w-auto"
							height="h-12"
							className="px-8 rounded-lg"
							bgColor="pink-coral"
							disabled={!userProfileStore.validPasswordData}
							fetching={userProfileStore.savingPassword}
							onClick={() => userProfileStore.updatePassword()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{translate('user.profile.account-settings.edit-password-mode.button')} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
							</div>
						</Button>
						<Button
							width="w-auto"
							height="h-12"
							className="mt-8 lg:mt-0 ml-0 lg:ml-4 px-4 rounded-lg text-pink-coral"
							bgColor="bg-list-text"
							fetching={userProfileStore.savingPassword}
							onClick={() => userProfileStore.cancelPasswordEditMode()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{translate('user.profile.account-settings.edit-password-mode.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><ExitIcon className="w-4 h-4" color="white" /></div>
							</div>
						</Button>
					</div>
				</div>
			)
		}else{
			return (
				<div className="flex flex-1 flex-col">
					<div className="mt-8"></div>
					<SecondaryAppLargeTitle>{translate('user.profile.account-settings.title')}</SecondaryAppLargeTitle>
					<div className="flex flex-col mt-4">
						<DataHeader>{translate('user.profile.account-settings.email.label')}</DataHeader>
						<div className="flex flex-col lg:flex-row justify-between items-center">
							<DataEntry>{currentUser.email}</DataEntry>
							<Button
								width="w-auto"
								height="h-12"
								className="ml-4 px-4 rounded-lg text-pink-coral"
								bgColor="bg-list-text"
								onClick={() => userProfileStore.setEmailEditMode()}
							>
								<TertiaryAppSmallTitle>{translate('user.profile.account-settings.email.button')}</TertiaryAppSmallTitle>
							</Button>
						</div>
					</div>
					<div className="flex flex-col mt-4">
						<DataHeader>{translate('user.profile.account-settings.password.label')}</DataHeader>
						<div className="flex flex-col lg:flex-row justify-between items-center">
							<DataEntry>••••••••••••</DataEntry>
							<Button
								width="w-auto"
								height="h-12"
								className="ml-4 px-4 rounded-lg text-pink-coral"
								bgColor="bg-list-text"
								onClick={() => userProfileStore.setPasswordEditMode()}
							>
								<TertiaryAppSmallTitle>{translate('user.profile.account-settings.password.button')}</TertiaryAppSmallTitle>
							</Button>
						</div>
					</div>
					<div className="flex flex-col w-full mt-4">
						<div className="flex"><SecondaryAppLargeTitle>{translate('user.profile.account-settings.invoices.title')}</SecondaryAppLargeTitle></div>

						<div className="flex w-full flex-col">
							{userProfileStore.invoices.length == 0 && 
								<div className="flex w-full items-center mt-4">
									<DataEntry>{translate('user.profile.account-settings.invoices.no-invoices')}</DataEntry>
								</div>
							}
							<div className="flex flex-col w-full mt-4 cursor-pointer">
								{userProfileStore.invoices.map((invoice) => 
									<div className="flex w-full border-b py-4 flex-row justify-between items-center">
										<div className="flex"><DataEntry>{invoice.invoice_date}</DataEntry></div>
										<div className="flex"><DataEntry>{invoice.paid ? translate('user.profile.account-settings.invoices.paid') : translate('user.profile.account-settings.invoices.unpaid')}</DataEntry></div>
										<div className="flex flex-row items-center cursor-pointer">
											<div className="flex " onClick={() => window.open(invoice.url, "_blank")}>
												<DotsIcon className="w-4 h-4 cursor-pointer text-nav-inactive-color" />
											</div>
											<div className="flex ml-4" onClick={() => window.open(invoice.pdf_url, "_blank")}>
												<DownloadIcon className="w-4 h-4 cursor-pointer text-nav-inactive-color" />
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

@observer
class UpgradeSubscription extends Component{
	render(){
		let translate = this.props.t;
		let userProfileStore = this.props.userProfileStore;
		let currentUser = userProfileStore.appStore.currentUser;
		if(currentUser == null) return null;
		let currentSubscription = currentUser.subscription;
		if(currentSubscription == null) return null;
		let currentPlan = currentSubscription.plan;
		if(currentUser == null) return null;
		if(userProfileStore.showUpgradedPlan){
			return (
				<div className="flex flex-1 flex-col">
					<div className="flex mt-12 justify-center text-center">
						<SecondaryAppMediumTitle className="text-nav-active-color">{translate('user.profile.subscription.upgraded.part1')} <br /> {translate('user.profile.subscription.upgraded.part2')}</SecondaryAppMediumTitle>
					</div>
					<div className="flex mt-4 justify-center mt-4">
						<OpenHandIcon className="w-64 h-64" />
					</div>
					<div className="flex flex-1 items-end justify-end mb-12">
						<div className="flex cursor-pointer" onClick={() => userProfileStore.returnToChangePlan()}>
							<Body className="bold-font text-nav-active-color">{translate('user.profile.subscription.upgraded.return')}</Body>
						</div>
					</div>
				</div>
			)
		}else if(userProfileStore.showChangePlan){
			return (
				<div className="flex flex-1 flex-col">
					<div className="mt-8"></div>
					<SecondaryAppLargeTitle>{translate('user.profile.subscription.change-plan-title')}</SecondaryAppLargeTitle>

					<div className="flex w-full flex-col mt-8">
						<DataHeader noborder>{translate('user.profile.subscription.current-plan.label')}</DataHeader>
						<div className={`base-input w-full ${INPUT_CLASS_NAME}`}>{currentPlan.name} ({currentPlan.period})</div>
						<div className="mt-6"></div>
						<DataHeader noborder>{translate('user.profile.subscription.new-period.label')}</DataHeader>
						<FormSelect 
							placeholder={translate('user.profile.subscription.new-period.placeholder')}
							options={userProfileStore.periodOptions}
							value={userProfileStore.selectedUpgradePeriod}
							onChange={(option) => userProfileStore.changePlanPeriod(option.value)}
						/>
						{userProfileStore.selectedUpgradePeriod != null &&
							<>
								<div className="mt-6"></div>
								<DataHeader noborder>{translate('user.profile.subscription.new-plan.label')}</DataHeader>
								<FormSelect 
									placeholder={translate('user.profile.subscription.new-plan.placeholder')}
									options={userProfileStore.planOptions}
									value={userProfileStore.selectedUpgradePlan}
									onChange={(option) => userProfileStore.chooseUpgradePlan(option.value)}
								/>
							</>
						}
						{userProfileStore.selectedUpgradePlan != null && userProfileStore.upgradeDescription != null &&
							<div className="flex flex-1 flex-row justify-center mt-6">
								{userProfileStore.upgradePreview.cost >= 0 &&
									<Button
										width="w-full"
										height="h-12"
										className="px-8 rounded-lg"
										bgColor="pink-coral"
										fetching={userProfileStore.upgrading}
										onClick={() => userProfileStore.upgrade()}
									>
										<TertiaryAppSmallTitle>{translate('user.profile.subscription.confirm-upgrade.label')} {userProfileStore.upgradeDescription}</TertiaryAppSmallTitle>
									</Button>
								}
								{userProfileStore.upgradePreview.cost < 0 &&
									<DataEntry>{translate('user.profile.subscription.upgrade-refund-policy')}</DataEntry>
								}
							</div>
						}
					</div>
				</div>
			)
		}else{
			return (
				<div className="flex flex-1 flex-col">
					<div className="mt-8"></div>
					<SecondaryAppLargeTitle>{translate('user.profile.subscription.title')}</SecondaryAppLargeTitle>
					<div className="flex w-full flex-col lg:flex-row items-center">
						<div className="flex w-full lg:w-2/3">
							<div className="flex flex-1 grid grid-cols-1 lg:grid-cols-3 mt-4">
								<DataHeader>{translate('user.profile.subscription.plan.label')}</DataHeader>
								<DataHeader>{translate('user.profile.subscription.period.label')}</DataHeader>
								<DataHeader>{translate('user.profile.subscription.renewal.label')}</DataHeader>
								<DataEntry>{currentPlan.name}</DataEntry>
								<DataEntry>{currentPlan.period}</DataEntry>
								<DataEntry>{moment(currentPlan.current_period_end).format(DISPLAY_DATE_FORMAT)}</DataEntry>
							</div>
						</div>
						{userProfileStore.showCardChangesOrPlanChanges && 
							<div className="flex flex-row ml-0 lg:ml-8 justify-center w-full lg:w-1/3 mt-8 lg:mt-0">
								<Button
									width="w-auto"
									height="h-8"
									className="px-8 rounded-lg"
									bgColor="pink-coral"
									onClick={() => userProfileStore.goToChangePlan()}
								>
									<TertiaryAppSmallTitle>{translate('user.profile.subscription.upgrade.label')}</TertiaryAppSmallTitle>
								</Button>
							</div>
						}
					</div>
				</div>
			)
		}
		
	}
}

@inject('userProfileStore', 'appStore')
@observer
class UserAvatar extends Component{
	constructor(props){
		super(props);
		this.toggleDropdown = this.toggleDropdown.bind(this);
		this.getCurrentUserInitials = this.getCurrentUserInitials.bind(this);
		this.logout = this.logout.bind(this);
		this.renderAvatar = this.renderAvatar.bind(this);
		this.goToProfileSettings = this.goToProfileSettings.bind(this);
		this.onClickPhoto = this.onClickPhoto.bind(this);
		this.onClickProfileImage = this.onClickProfileImage.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);
		this.fileUploadRef = React.createRef();
		this.state = {
			showDropdown: false
		}
	}

	onClickPhoto(){
		this.fileUploadRef.current.click();
	}

	toggleDropdown(){
		this.setState({
			showDropdown: !this.state.showDropdown
		})
	}

	onClickProfileImage(){
		if('preventDropdown' in this.props){
			this.onClickPhoto();
		}else{
			this.toggleDropdown();
		}
	}

	logout(e){
		e.stopPropagation();
		this.props.appStore.logout();
	}

	goToProfileSettings(e){
		this.props.userProfileStore.toggleUserProfileModal();
	}

	onChangeFile(e){
		let translate = this.props.t;
		let files = [...e.target.files];
		let validFiles = files.filter((f) => IMAGE_FILE_TYPES.includes(f.type));
		if(validFiles.length == 0){
			this.props.appStore.alertError(translate('error.valid-image-format-message'));
		}else if(validFiles.length > 1){
			this.props.appStore.alertError(translate('error.one-file-only-message'));
		}else{

			let currentFile = validFiles[0];
			var sizeInMB = (currentFile.size / (1024*1024)).toFixed(2);
			let maxFileSize = 'maxFileSize' in this.props ? this.props.maxFileSize : 2;
			if(sizeInMB > maxFileSize){
				let exceededMaxFileSizeMessage = `${translate('error.max-file-size')} ${maxFileSize}MB`;
				this.props.appStore.alertError(exceededMaxFileSizeMessage);
			}else{
				let userProfileStore = this.props.userProfileStore;
				userProfileStore.changeProfileImage(currentFile);
			}
		}
	}

	getCurrentUserInitials(){
		let appStore = this.props.appStore;
		let currentUser = appStore.currentUser;
		if(currentUser == null) return null;
		let splitName = currentUser.name.split(' ');
		let initials = [];
		for(let entry of splitName){
			initials.push(entry[0])
		}
		return initials.join('');
	}

	renderAvatar(){
		let appStore = this.props.appStore;
		let profileImageUrl = appStore.currentUser?.profile_photo_url;
		let hasAvatar = profileImageUrl != null;
		let border = this.props.noborder ? null : `1px solid ${colors['list-text']}`;
		return (
			<Fragment>
				{hasAvatar && 
					<img 
						style={{
							position: 'absolute',
							width: '100%',
							borderRadius: '50%',
							backgroundColor: colors['list-text'],
							border: border,
							height: '100%',
							objectFit: 'cover',
							alignSelf: 'center',
							imageOrientation: 'from-image',
						}}
						src={profileImageUrl} 
						alt="Profile Photo" />
				}
				{!hasAvatar && 
					<div className={`regular-font ${this.props.large ? 'text-lg' : 'text-xs'}`}>{this.getCurrentUserInitials()}</div>
				}
			</Fragment>
			
		)
	}

	render(){
		let translate = this.props.t;
		let appStore = this.props.appStore;
		let currentUser = appStore.currentUser;
		let sizeClassName = 'w-8 h-8';
		if(this.props.large){
			sizeClassName = 'w-24 h-24'
		}
		let avatarClassName = `flex ${sizeClassName} relative cursor-pointer items-center justify-center rounded-full bg-blue-400`;
		let dropdownAvatarClassName = `${avatarClassName} w-12 h-12`;
		return (
			<div className={avatarClassName} onClick={this.onClickProfileImage}>
				{this.renderAvatar()}
				<input type="file" 
					ref={this.fileUploadRef} 
					onChange={this.onChangeFile} 
					style={{ display: 'none' }} 
				/>

				{this.state.showDropdown &&
					<div className="z-40 bg-white absolute top-0 mt-12 py-4 px-4 border border-black-10 full-shadow right-0 flex flex-col rounded-lg border-black w-auto h-auto">
						<div className="flex flex-1 flex-col items-center" >
							<div className={dropdownAvatarClassName}>
								{this.renderAvatar()}
							</div>
							<div className="flex mt-2"><TertiaryAppSmallTitle>{currentUser != null ? currentUser.name : ''}</TertiaryAppSmallTitle></div>
							<div className="flex break-words"><SmallBody>{currentUser != null ? currentUser.email : ''}</SmallBody></div>
						</div>
						<div className="border-b border-black h-4 w-full mb-2"></div>
						<NavItemEntry onClick={this.goToProfileSettings}>{translate('user.profile.settings')}</NavItemEntry>
						<NavItemEntry onClick={this.logout}>{translate('user.profile.logout')}</NavItemEntry>
					</div>	
				}
			</div>
		)
	}
}


@observer
class PersonalInformation extends Component{
	constructor(props){
		super(props);
		this.showHoverProfileImage = this.showHoverProfileImage.bind(this);
		this.hideHoverProfileImage = this.hideHoverProfileImage.bind(this);
		this.toggleFileUpload = this.toggleFileUpload.bind(this);
		this.userAvatar = React.createRef();
		this.state = {
			hoverProfileImage: false
		}
	}

	showHoverProfileImage(){
		this.setState({
			hoverProfileImage: true
		})
	}

	hideHoverProfileImage(){
		this.setState({
			hoverProfileImage: false
		})
	}

	toggleFileUpload(){
		this.userAvatar.current.onClickPhoto();
	}

	render(){
		let translate = this.props.t;
		let userProfileStore = this.props.userProfileStore;
		let currentUser = userProfileStore.appStore.currentUser;
		if(currentUser == null) return null;
		return (
			<div className="flex flex-col justify-center overflow-y-scroll">
				
				<div className="flex flex-col lg:flex-row items-center justify-between mt-8 ml-4">
					<div className="flex flex-col lg:flex-row items-center">
						<div className="flex">
							<UserAvatar 
								ref={this.userAvatar}
								t={translate} 
								preventDropdown 
								large 
							/>
						</div>
						<div className="flex flex-col cursor-pointer ml-4">
							{userProfileStore.uploadingImage && 
								<div className="flex flex-col items-center cursor-pointer">
									<SecondaryAppMediumTitle className="text-black">{translate('user.profile.uploading-image')}</SecondaryAppMediumTitle>
								</div>
							}
							{!userProfileStore.uploadingImage && !this.state.hoverProfileImage && 
								<div onMouseEnter={this.showHoverProfileImage}>
									<SecondaryAppMediumTitle>{currentUser.name}</SecondaryAppMediumTitle>
									<SmallBody className="text-black">{currentUser.country?.name}</SmallBody>
								</div>
							}
							{!userProfileStore.uploadingImage && this.state.hoverProfileImage && 
								<div className="flex flex-col items-center cursor-pointer" onMouseLeave={this.hideHoverProfileImage} onClick={this.toggleFileUpload}>
									<TertiaryAppSmallTitle className="text-pink-coral">{translate('user.profile.choose-image')}</TertiaryAppSmallTitle>
								</div>
							}
						</div>
					</div>
					<div className="flex flex-col lg:flex-row mt-8 lg:mt-0 justify-end">
						{!userProfileStore.editMode && 
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="pink-coral"
								onClick={() => userProfileStore.setEditMode()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.profile.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						}
						{userProfileStore.editMode && 
							<>
								<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									fetching={userProfileStore.savingPersonalInformationData}
									disabled={!userProfileStore.validPersonalInformationData}
									onClick={() => userProfileStore.savePersonalInformation()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.profile.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
								<Button
									width="w-auto"
									height="h-12"
									className="mt-8 lg:mt-0 ml-0 lg:ml-4 px-4 rounded-lg text-pink-coral"
									bgColor="bg-list-text"
									fetching={userProfileStore.savingPersonalInformationData}
									onClick={() => userProfileStore.cancelEditMode()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.profile.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><ExitIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</>
						}
					</div>
				</div>
				<div className="flex flex-row items-center mt-8 cursor-pointer" onClick={() => window.open('http://help.labaroma.com/en/articles/4745448-11-manage-my-labaroma-tutorial', '_blank')}>
					<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.profile.tutorial')} </TertiaryAppSmallTitle></div>
					<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
				</div>
				<div className="flex flex-col mt-8">
					<div className="flex flex-col grid grid-cols-1 lg:grid-cols-2 gap-8 mt-4">
						<DataRow label={translate('user.profile.name.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.name}</DataEntry>}
							{userProfileStore.editMode && 
								<FormInput 
									value={userProfileStore.personalInformationData.name.value}
									error={userProfileStore.personalInformationData.name.error}
									onChange={(e) => userProfileStore.personalInformationData.name.onChange(e.target.value)}
								/>
							}
						</DataRow>
						<DataRow label={translate('user.profile.occupation.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.occupation}</DataEntry>}
							{userProfileStore.editMode && 
								<FormInput 
									value={userProfileStore.personalInformationData.occupation.value}
									error={userProfileStore.personalInformationData.occupation.error}
									onChange={(e) => userProfileStore.personalInformationData.occupation.onChange(e.target.value)}
								/>
							}
						</DataRow>
						<DataRow label={translate('user.profile.country.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.country?.name}</DataEntry>}
							{userProfileStore.editMode && 
								<FormSelect 
									placeholder={translate('user.profile.country.placeholder')}
									options={userProfileStore.countryOptions}
									value={userProfileStore.personalInformationData.selectedCountry.value}
									onChange={(option) => userProfileStore.personalInformationData.selectedCountry.onChange(option.value)}
								/>
							}
						</DataRow>
						<DataRow label={translate('user.profile.region.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.region.name}</DataEntry>}
							{userProfileStore.editMode && 
								<FormSelect 
									placeholder={translate('user.profile.region.placeholder')}
									options={userProfileStore.regionOptions}
									value={userProfileStore.personalInformationData.selectedRegion.value}
									onChange={(option) => userProfileStore.personalInformationData.selectedRegion.onChange(option.value)}
								/>
							}
						</DataRow>
						<DataRow label={translate('user.profile.billing-country.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.billing_country?.name}</DataEntry>}
							{userProfileStore.editMode && 
								<FormSelect 
									placeholder={translate('user.profile.billing-country.placeholder')}
									options={userProfileStore.countryOptions}
									value={userProfileStore.personalInformationData.selectedBillingCountry.value}
									onChange={(option) => userProfileStore.personalInformationData.selectedBillingCountry.onChange(option.value)}
								/>
							}
						</DataRow>
						<DataRow label={translate('user.profile.address-line-1.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.billing_address_line_1}</DataEntry>}
							{userProfileStore.editMode && 
								<FormInput 
									value={userProfileStore.personalInformationData.addressLine1.value}
									error={userProfileStore.personalInformationData.addressLine1.error}
									onChange={(e) => userProfileStore.personalInformationData.addressLine1.onChange(e.target.value)}
								/>
							}
						</DataRow>
						<DataRow label={translate('user.profile.address-line-2.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.billing_address_line_2}</DataEntry>}
							{userProfileStore.editMode && 
								<FormInput 
									value={userProfileStore.personalInformationData.addressLine2.value}
									error={userProfileStore.personalInformationData.addressLine2.error}
									onChange={(e) => userProfileStore.personalInformationData.addressLine2.onChange(e.target.value)}
								/>
							}
						</DataRow>
						<DataRow label={translate('user.profile.address-city.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.billing_address_city}</DataEntry>}
							{userProfileStore.editMode && 
								<FormInput 
									value={userProfileStore.personalInformationData.addressCity.value}
									error={userProfileStore.personalInformationData.addressCity.error}
									onChange={(e) => userProfileStore.personalInformationData.addressCity.onChange(e.target.value)}
								/>
							}
						</DataRow>
						<DataRow label={translate('user.profile.address-state.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.billing_address_state}</DataEntry>}
							{userProfileStore.editMode && 
								<>
									{!userProfileStore.showStateDropdown && 
										<FormInput 
											value={userProfileStore.personalInformationData.addressState.value}
											error={userProfileStore.personalInformationData.addressState.error}
											onChange={(e) => userProfileStore.personalInformationData.addressState.onChange(e.target.value)}
										/>
									}
									{userProfileStore.showStateDropdown && 
										<FormSelect 
											placeholder={translate('user.profile.address-state.placeholder')}
											options={userProfileStore.stateOptions()}
											value={userProfileStore.personalInformationData.selectedState.value}
											onChange={(option) => userProfileStore.personalInformationData.selectedState.onChange(option.value)}
										/>
									}
								</>
							}
						</DataRow>
						<DataRow label={translate('user.profile.address-zip.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.billing_address_zip}</DataEntry>}
							{userProfileStore.editMode && 
								<FormInput 
									value={userProfileStore.personalInformationData.addressZip.value}
									error={userProfileStore.personalInformationData.addressZip.error}
									onChange={(e) => userProfileStore.personalInformationData.addressZip.onChange(e.target.value)}
								/>
							}
						</DataRow>
						<DataRow label={translate('user.profile.tax-id.label')}>
							{!userProfileStore.editMode && <DataEntry>{currentUser.vat_number}</DataEntry>}
							{userProfileStore.editMode && 
								<FormInput 
									value={userProfileStore.personalInformationData.taxId.value}
									error={userProfileStore.personalInformationData.taxId.error}
									onChange={(e) => userProfileStore.personalInformationData.taxId.onChange(e.target.value)}
								/>
							}
						</DataRow>
					</div>
				</div>
			</div>
		)
	}
}

@observer
class UpdateCardForm extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let userProfileStore = this.props.userProfileStore;
		userProfileStore.fetchClientSecret();
	}

	updateCard = async (event) => {
		event.preventDefault();
		let userProfileStore = this.props.userProfileStore;
		if(userProfileStore.clientSecret == null) return;
		let currentUser = userProfileStore.appStore.currentUser;
		let translate = this.props.t;

		const {stripe, elements} = this.props;
	    if (!stripe || !elements) {
	      return;
	    }
	  	
	  	const cardElement = elements.getElement(CardNumberElement);

	  	userProfileStore.setUpdatingCard();
	  	const result = await stripe.confirmCardSetup(userProfileStore.clientSecret, {
	      payment_method: {
	        card: cardElement,
	        billing_details: {
	          name: currentUser.email
	        },
	      }
	    });

	    if (result.error) {
	      	if('message' in result.error){
	    		userProfileStore.appStore.alertError(result.error.message);
	    	}else{
	    		userProfileStore.appStore.alertError(translate('error.processing-card-error-message'));
	    	}
	    	userProfileStore.finishUpdatingCard()
	    } else {
	      userProfileStore.updateCard(result.setupIntent.payment_method);
	    }

	}

	render(){
		let translate = this.props.t;
		let userProfileStore = this.props.userProfileStore;
		let stripeInputClassName = `base-input ${INPUT_CLASS_NAME}`
		return (
			<div className="flex flex-1 flex-col">
				<div className="flex flex-col w-full lg:w-1/2">
					<div className="flex flex-1 flex-col">
						<DataHeader noborder>{translate('user.profile.payment-details.payment-method')}</DataHeader>
						<div className="flex flex-row items-center justify-between">
							<div className="inline-flex"><span className="text-black">{translate('user.profile.payment-details.cards-accepted')}</span></div>
							<div className="inline-flex justify-end h-8 w-32 -mr-2">
								<img src={creditCardSrc} alt="Accept Visa, MasterCard and American Express" />
							</div>
						</div>
					</div>
					<div className="flex flex-1 flex-col mt-6">
						<DataHeader noborder>{translate('user.profile.payment-details.card-number')}</DataHeader>
						<div className={stripeInputClassName}>
							<CardNumberElement options={cardNumberInputOptions} />
						</div>
					</div>
					<div className="flex flex-1 flex-col lg:flex-row mt-6">
						<div className="flex flex-1 flex-col">
							<DataHeader noborder>{translate('user.profile.payment-details.expiry-date')}</DataHeader>
							<div className={stripeInputClassName}>
								<CardExpiryElement options={stripeInputOptions}/>
							</div>
						</div>
						<div className="flex flex-1 flex-col mt-8 lg:mt-0 ml-0 lg:ml-8">
							<DataHeader noborder>{translate('user.profile.payment-details.cvv')}</DataHeader>
							<div className={stripeInputClassName}>
								<CardCvcElement options={stripeInputOptions} />
							</div>
						</div>
					</div>
				</div>
				{userProfileStore.editCardMode && 
						<div className="flex flex-1 flex-col lg:flex-row items-end justify-end mb-12 mt-8 lg:mt-0">
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="pink-coral"
								fetching={userProfileStore.updatingCard}
								onClick={this.updateCard}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.profile.payment-details.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
							<Button
								width="w-auto"
								height="h-12"
								className="mt-8 lg:mt-0 ml-0 lg:ml-4 px-4 rounded-lg text-pink-coral"
								bgColor="bg-list-text"
								fetching={userProfileStore.updatingCard}
								onClick={() => userProfileStore.cancelEditCardMode()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.profile.payment-details.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><ExitIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						</div>
					}
			</div>
		)
	}
}

@observer
class PaymentDetails extends Component{
	constructor(props){
		super(props)
	}

	render(){
		let translate = this.props.t;
		let userProfileStore = this.props.userProfileStore;
		let currentUser = userProfileStore.appStore.currentUser;
		if(currentUser == null) return null;
		let subscription = currentUser.subscription;

		return (
			<div className="flex flex-1 flex-col">
				<div className="flex flex-1 flex-col">
					<div className="mt-8"></div>
					<SecondaryAppLargeTitle>{translate('user.profile.payment-details.title')}</SecondaryAppLargeTitle>
					<div className="mt-8"></div>
					
					
					{subscription != null && subscription.last_four_digits != null && !userProfileStore.editCardMode &&
						<>
							<SecondaryAppSmallTitle>{translate('user.profile.payment-details.payment-method')}</SecondaryAppSmallTitle>
							<div className="w-full lg:w-1/2 h-auto bg-flat-gray px-8 py-4 rounded-md mt-4">
								<div className="flex flex-1 flex-row items-center">
									<div className="flex"><CreditCardIcon className="w-12 h-12" brand={subscription.card_brand} /></div>
									<div className="flex flex-1 flex-col ml-4">
										<TertiaryAppSmallTitle>{currentUser.name}</TertiaryAppSmallTitle>
										<Body>{`${subscription.last_four_digits}, ${translate('user.profile.payment-details.expires')} ${subscription.expiration_month}/${subscription.expiration_year}`}</Body>
									</div>
								</div>
							</div>
						</>
					}
					{userProfileStore.editCardMode && 
						<Elements stripe={stripePromise}>
							<ElementsConsumer>
							    {({stripe, elements}) => {
							    	return (
							      		<UpdateCardForm 
							      			t={this.props.t}
							      			stripe={stripe}
							      			userProfileStore={userProfileStore}
							      			elements={elements}
							      		/>
							    	)
							    }}
						  </ElementsConsumer>
						</Elements>
					}
				</div>
				{!userProfileStore.editCardMode && userProfileStore.showCardChangesOrPlanChanges &&  
					<div className="flex flex-1 items-end justify-end mb-12 mt-8 lg:mt-0">
						<Button
							width="w-auto"
							height="h-12"
							className="px-4 rounded-lg"
							bgColor="pink-coral"
							onClick={() => userProfileStore.setEditCardMode()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{translate('user.profile.payment-details.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
							</div>
						</Button>
					</div>
				}
			</div>
		)
	}
}

@inject('userProfileStore')
@observer
class ProfileSettings extends Component{
	constructor(props){
		super(props);
		this.onExit = this.onExit.bind(this);
	}

	componentDidMount(){
		let userProfileStore = this.props.userProfileStore;
		userProfileStore.fetchCountries();
		userProfileStore.fetchRegions();
		userProfileStore.fetchPlans();
	}

	componentWillUnmount(){
		let userProfileStore = this.props.userProfileStore;
		userProfileStore.initStore();
	}

	onExit(){
		let userProfileStore = this.props.userProfileStore;
		userProfileStore.hideProfileSettings();
	}

	render(){
		let userProfileStore = this.props.userProfileStore;
		let translate = this.props.t;
		return (
			<BaseModal
	            isOpen={userProfileStore.showUserProfileModal}
	            onClose={this.onExit}
	            label="User Profile Settings"
	        >
				<div className="flex flex-1 flex-col overflow-y-scroll bg-white justify-center rounded-lg">
					<div className="flex flex-1 flex-col justify-center px-16">
						<div className="flex flex-1 flex-col mt-24">
							<div className="flex flex-shrink-0 flex-col lg:flex-row justify-between">
								<FormTab onClick={() => userProfileStore.moveToPersonalInformation()} active={userProfileStore.currentSection == 0}>{translate('user.profile.headers.personal-information')}</FormTab>
								{!userProfileStore.appStore.isSuperAdministrator && <FormTab onClick={() => userProfileStore.moveToPaymentDetails()} active={userProfileStore.currentSection == 1}>{translate('user.profile.headers.payment-details')}</FormTab>}
								{!userProfileStore.appStore.isSuperAdministrator && <FormTab onClick={() => userProfileStore.moveToSubscription()} active={userProfileStore.currentSection == 2}>{translate('user.profile.headers.subscription')}</FormTab>}
								<FormTab onClick={() => userProfileStore.moveToAccountSettings()} active={userProfileStore.currentSection == 3}>{translate('user.profile.headers.account-settings')}</FormTab>
							</div>
							{userProfileStore.currentSection == 0 &&
								<PersonalInformation t={translate} userProfileStore={userProfileStore} />
							}
							{userProfileStore.currentSection == 1 && 
								<PaymentDetails t={translate} userProfileStore={userProfileStore} />
							}
							{userProfileStore.currentSection == 2 && 
								<UpgradeSubscription t={translate} userProfileStore={userProfileStore} />
							}
							{userProfileStore.currentSection == 3 && 
								<AccountSettings t={translate} userProfileStore={userProfileStore} />
							}

						</div>
						
					</div>
				</div>	
			</BaseModal>
		)
	}
}

export {
	ProfileSettings,
	UserAvatar
}