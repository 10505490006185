import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import Main from '../../../../elements/main';
import {FullGrid} from '../../../../elements/grid';

import {Button} from '../../../../common/button';
import {SaveIcon, EditIcon} from '../../../../common/icon';

import { withAuthorisation } from '../../../../common/authorisation';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, CHEMISTRY } from '../../../../../services/util';

import {SecondaryAppLargeTitle, SecondaryAppMediumTitle, TertiaryAppSmallTitle, Body, ExtraSmallBody} from '../../../../common/text'
import {
	DataHeader,
	DataRow,
	FormInput,
	FormTextArea
} from '../../../../elements/form';

import ColorPicker from '../../../../common/color-picker';
import UploadImage from '../../../../common/upload-image';


@inject('manageChemistryFamily')
@observer
class ManageChemistryFamily extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let manageChemistryFamily = this.props.manageChemistryFamily;
		if('chemicalFamilyId' in this.props && this.props.editMode){
			manageChemistryFamily.formSync(this.props.chemicalFamilyId);
		}
	}

	componentWillUnmount(){
		let manageChemistryFamily = this.props.manageChemistryFamily;
		manageChemistryFamily.initStore();
	}

	render(){
		let translate = this.props.t;
		let manageChemistryFamily = this.props.manageChemistryFamily;
		return (
			<Main>
				<div className="flex flex-1 flex-col"> 
					<div className="flex flex-row mt-8 items-center justify-between">
						<div className="flex">
							<SecondaryAppLargeTitle className="text-nav-active-color">{manageChemistryFamily.editMode ?  translate('superadmin.manage-chemical-family.edit-title') : translate('superadmin.manage-chemical-family.new-title')}</SecondaryAppLargeTitle>
						</div>
						<div className="flex justify-end">
							<Button
								width="w-auto"
								height="h-12"
								className="rounded-lg"
								bgColor="pink-coral"
								fetching={manageChemistryFamily.fetching}
								disabled={!manageChemistryFamily.validForm}
								onClick={() => manageChemistryFamily.saveChemistryFamily()}
							>
								<div className="flex flex-row items-center justify-center mx-4">
									<div className="flex"><TertiaryAppSmallTitle>{manageChemistryFamily.editMode ? translate('superadmin.manage-chemical-family.update-button') : translate('superadmin.manage-chemical-family.save-new-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						</div>
					</div>
					<FullGrid className="mt-8">
						<div className="flex flex-row">
							<SecondaryAppMediumTitle>{translate('superadmin.manage-chemical-family.family-title')}</SecondaryAppMediumTitle>
						</div>
						<div className="flex flex-1 flex-col lg:flex-row mt-4">
							<div className="flex flex-1 flex-col">
								<div className="flex flex-1 flex-col lg:flex-row">
									<div className="flex flex-1">
										<DataRow label={translate('superadmin.manage-chemical-family.name.label')}>
											<FormInput 
												placeholder={translate('superadmin.manage-chemical-family.name.placeholder')}
												value={manageChemistryFamily.familyData.name.value}
												error={manageChemistryFamily.familyData.name.error}
												onChange={(e) => manageChemistryFamily.familyData.name.onChange(e.target.value)}
											/>
										</DataRow>
									</div>
									<div className="flex flex-1 lg:ml-8">
										<div className="flex flex-1 flex-col">
											<div className="flex"><DataHeader noborder>{translate('superadmin.manage-chemical-family.hex-colour.label')}</DataHeader></div>
											<div className="flex flex-row items-center">
												<ColorPicker 
													hexColor={manageChemistryFamily.familyData.hexColor}
													onChange={(val) => manageChemistryFamily.familyData.hexColor.onChange(val)} />
											</div>
										</div>
									</div>
								</div>
								<div className="flex flex-1 flex-row">
									<DataRow label={translate('superadmin.manage-chemical-family.bio.label')}>
										<FormTextArea 
											placeholder={translate('superadmin.manage-chemical-family.bio.placeholder')}
											value={manageChemistryFamily.familyData.bio.value}
											error={manageChemistryFamily.familyData.bio.error}
											onChange={(e) => manageChemistryFamily.familyData.bio.onChange(e.target.value)}
										/>
									</DataRow>
								</div>
								<div className="flex flex-1 flex-row">
									<DataRow label={translate('superadmin.manage-chemical-family.molecular-name.label')}>
										<FormTextArea 
											placeholder={translate('superadmin.manage-chemical-family.molecular-name.placeholder')}
											value={manageChemistryFamily.familyData.molecularName.value}
											error={manageChemistryFamily.familyData.molecularName.error}
											onChange={(e) => manageChemistryFamily.familyData.molecularName.onChange(e.target.value)}
										/>
									</DataRow>
								</div>
							</div>
							<div className="flex flex-col w-full lg:w-2/5 lg:ml-8">
								<div className="flex">
									<DataHeader noborder>{translate('superadmin.manage-chemical-family.molecular-image.label')}</DataHeader>
								</div>

								<UploadImage 
									t={this.props.t} 
									maxFileSize={2}
									description={
										(
											<>
												{translate('common.upload-image.valid-files.part1')} <br /> {translate('common.upload-image.valid-files.part2')} 
											</>
										)
									}
									imageFileUrl={manageChemistryFamily.imageFileUrl}
									onFileChange={(file) => manageChemistryFamily.changeMolecularImage(file)} 
									onDeleteFile={() => manageChemistryFamily.deleteMolecularImage()}
								/>
							</div>
						</div>
					</FullGrid>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(ManageChemistryFamily, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR], [CHEMISTRY]));