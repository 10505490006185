import BaseApi from '../services/base';

class LanguageApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getLanguages(){
		return this.fetch(`v1/languages`, {
			method: 'GET'
		})
	}

	getLanguageById(language){
		return this.fetch(`v1/languages/${language}`, {
			method: 'GET'
		})
	}

	getWebTranslations(language){
		return this.fetch(`v1/languages/web/${language}`, {
			method: 'GET',
			auth: true
		})
	}

	getLanguageEntityTranslations(entity, language){
		return this.fetch(`v1/languages/entities/${entity}/${language}`, {
			method: 'GET',
			auth: true
		})
	}

	getIndividualLanguageEntityTranslation(entity, language, entityId){
		return this.fetch(`v1/languages/entities/${entity}/${language}/${entityId}`, {
			method: 'GET',
			auth: true
		})
	}

	updateIndividualLanguageEntityTranslation(entity, language, entityId, payload){
		return this.fetch(`v1/languages/entities/${entity}/${language}/${entityId}`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	declineIndividualLanguageEntityTranslationRequest(translationRequestId){
		return this.fetch(`v1/languages/requests/decline/${translationRequestId}`, {
			method: 'POST',
			auth: true
		})
	}

	approveIndividualLanguageEntityTranslationRequest(translationRequestId){
		return this.fetch(`v1/languages/requests/approve/${translationRequestId}`, {
			method: 'POST',
			auth: true
		})
	}

	getLanguageEntities(){
		return this.fetch(`v1/languages/entities`, {
			method: 'GET',
			auth: true
		})
	}

	updateWebTranslations(language, payload){
		return this.fetch(`v1/languages/web/${language}`, {
			method: 'PUT',
			body: JSON.stringify(payload),
			auth: true
		})
	}

	approveWebTranslationRequest(pendingTranslationRequestId){
		return this.fetch(`v1/languages/web/${pendingTranslationRequestId}/approve`, {
			method: 'POST',
			auth: true
		})
	}

	declineWebTranslationRequest(pendingTranslationRequestId){
		return this.fetch(`v1/languages/web/${pendingTranslationRequestId}/decline`, {
			method: 'POST',
			auth: true
		})
	}
}

export default LanguageApi;