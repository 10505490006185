import React, { Component} from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {Body, SecondaryAppMediumTitle, TertiaryAppSmallTitle} from '../../common/text'
import {ToolTip} from '../../common/input'
import { withAuthorisation } from '../../common/authorisation';

import {Button} from '../../common/button';
import {PlusIcon, EditIcon, ArchiveIcon} from '../../common/icon';
import Main from '../../elements/main';
import {FullGrid} from '../../elements/grid';
import {List, ArchivedStatus,Status,  TableCell, TableRow, TableHeader, SortDialog, SortingField} from '../../elements/list';
import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, USERS } from '../../../services/util';
import {
	FormInput,
} from '../../elements/form';
import {PermissionCircle} from '../../elements/permission';
const colors = require('../../../color.js');


@inject('usersStore')
@observer
class SuperAdministratorUsers extends Component{
	constructor(props){
		super(props);
		this.onPreviousPage = this.onPreviousPage.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPage = this.onPage.bind(this);
		this.renderListHeaders = this.renderListHeaders.bind(this);
		this.renderUserListItem = this.renderUserListItem.bind(this);
		this.toggleSortDialog = this.toggleSortDialog.bind(this);
		this.onOption = this.onOption.bind(this);
		this.onApplySort = this.onApplySort.bind(this);
	}

	componentDidMount(){
		let usersStore = this.props.usersStore;
		usersStore.fetchUsers();
	}

	componentWillUnmount(){
		let usersStore = this.props.usersStore;
		usersStore.initStore();
	}

	renderListHeaders(){
		let translate = this.props.t;
		return (
			<tr>
				<TableHeader>{translate("superadmin.users.headers.id")}</TableHeader>
				<TableHeader>{translate("superadmin.users.headers.name")}</TableHeader>
				<TableHeader>{translate("superadmin.users.headers.email")}</TableHeader>
				<TableHeader>{translate("superadmin.users.headers.organisation")}</TableHeader>
				<TableHeader>{translate("superadmin.users.headers.plan")}</TableHeader>
				<TableHeader>{translate("superadmin.users.headers.status")}</TableHeader>
				<TableHeader>{translate("superadmin.users.headers.access")}</TableHeader>
				<TableHeader className="text-center">{translate("superadmin.users.headers.actions")}</TableHeader>
			</tr>
		)
	}

	renderUserListItem(user){
		let usersStore = this.props.usersStore;
		let translate = this.props.t;

		let statusToRender = (<ArchivedStatus t={translate} archived={user.archived} />);
		if(user.subscription != null && user.subscription.is_manual){
			statusToRender = (
				<Status 
					bgColor={'pink-coral'} 
					color={'candy-pink'} 
					text="Manual" 
				/>
			)
		}

		return (
			<TableRow key={user.id}>
				<TableCell><Body className="text-black">{user.id}</Body></TableCell>
				<TableCell><Body className="text-black">{user.name}</Body></TableCell>
				<TableCell><Body className="text-black">{user.email}</Body></TableCell>
				<TableCell><Body className="text-black">{user.organisation != null ? user.organisation.name : ''}</Body></TableCell>
				<TableCell><Body className="text-black">{user.subscription != null ? user.subscription.plan.stripe_plan_name : ''}</Body></TableCell>
				<TableCell>
					{statusToRender}
				</TableCell>
				<TableCell>
					<div className="flex flex-row">
						{user.permissions.map((permission) => 
								<PermissionCircle permission={permission} />	
							)
						}
					</div>
				</TableCell>
				<TableCell>
					<div className="flex flex-row justify-around">
						<div className="inline-flex relative" 
							onMouseEnter={(e) => usersStore.setEditTooltip(user.id)}
							onMouseLeave={(e) => usersStore.setEditTooltip(null)} 
							onClick={() => usersStore.onEditUser(user.id)}>
							<>
								<EditIcon color='black' className="w-4 h-4 cursor-pointer " />
								
								{usersStore.showEditTooltip == user.id && 
									<ToolTip
										title={translate("superadmin.users.list.edit-tooltip.title")}
										text={translate("superadmin.users.list.edit-tooltip.text")} 
									/>
								}
							</>
						</div>
					</div>
				</TableCell>
			</TableRow>
		);
	}

	onPreviousPage(){
		let usersStore = this.props.usersStore;
		usersStore.onPreviousPage();
	}

	onNextPage(){
		let usersStore = this.props.usersStore;
		usersStore.onNextPage();
	}

	onPage(page){
		let usersStore = this.props.usersStore;
		usersStore.onPage(page);
	}

	toggleSortDialog(){
		let usersStore = this.props.usersStore;
		usersStore.toggleSortDialog();
	}

	onOption(val){
		let usersStore = this.props.usersStore;
		usersStore.setSortByField(val);
	}

	onApplySort(){
		let usersStore = this.props.usersStore;
		usersStore.applySort();
	}

	render(){
		let translate = this.props.t;
		let usersStore = this.props.usersStore;
		let archivedButtonText = usersStore.archived ? translate('superadmin.users.index.active-users-button') : translate('superadmin.users.index.archived-users-button');
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full justify-end mt-8">
						<Button
							width="w-auto"
							height="h-12"
							className="px-4 rounded-lg"
							fgColor={usersStore.archived ? "list-text" : "list-text"}
							bgColor={usersStore.archived ? "gray-bg-button" : "white"}
							borderColor={usersStore.archived ? "gray-bg-button" : "list-text"}
							onClick={() => usersStore.onArchivedUsers()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{archivedButtonText} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><ArchiveIcon color={colors['list-text']} className="w-4 h-4" /></div>
							</div>
						</Button>
						<Button
							width="w-auto"
							height="h-12"
							className="ml-4 px-4 rounded-lg"
							bgColor="pink-coral"
							onClick={() => usersStore.onNewInvite()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.users.index.invite-button')} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><PlusIcon /></div>
							</div>
						</Button>
					</div>
					<FullGrid className="mt-8">
						<div className="flex flex-row justify-between">
							<SecondaryAppMediumTitle>{usersStore.archived ? translate('superadmin.users.index.archived-title') : translate('superadmin.users.index.title')}</SecondaryAppMediumTitle>


							<div className="flex flex-row items-center">
								<div className="flex flex-row items-center">
									<FormInput 
											value={usersStore.searchEmail}
											onChange={(e) => usersStore.onChangeSearchEmail(e.target.value)}
											placeholder={translate('superadmin.users.index.search-email')}
										/>
								</div>
								<div className="relative ml-4 flex flex-row items-center" onClick={this.toggleSortDialog}>
									<SortingField text={translate("superadmin.users.index.sort")} />
									{usersStore.showSortDialog && 
										<SortDialog
										 	t={translate}
											sortOptions={usersStore.sortOptions}
											currentOption={usersStore.sortBy}
											onOption={this.onOption}
											onApply={this.onApplySort}
										/>
									}
								</div>
							</div>
							
						</div>
						<div className="mt-8"></div>
						<List 
							fetching={usersStore.fetching}
						 	renderListHeaders={this.renderListHeaders}
							type={translate('superadmin.users.listname')}
							items={usersStore.users}
							renderListItem={this.renderUserListItem}
							page={usersStore.page}
							pages={usersStore.pages}
							hasNext={usersStore.hasNext}
							hasPrev={usersStore.hasPrev}
							onPreviousPage={this.onPreviousPage}
							onNextPage={this.onNextPage}
							onPage={this.onPage}
						/>
					</FullGrid>
				</div>
			</Main>
		)
	}
}
export default withTranslation()(withAuthorisation(SuperAdministratorUsers, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR], [USERS]));