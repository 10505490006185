import BaseApi from '../services/base';

class NotificationApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getPagedNotifications(page, open=null){
		let queryUrl = `v1/notifications?page=${page}`
		if(open != null){
			queryUrl = `${queryUrl}&open=${open}`
		}
		return this.fetch(queryUrl, {
			method: 'GET',
			auth: true
		})
	}

	readNotificiation(id){
		return this.fetch(`v1/notifications/${id}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify({
				'read': true
			})
		})
	}
}

export default NotificationApi;