import React, {Component} from 'react';
import {observer} from 'mobx-react';

import {SecondaryAppSmallTitle, SecondaryAppMediumTitle, SmallBody} from '../common/text'
import {ArrowDownIcon, ArrowUpIcon} from '../common/icon'
import {FullGrid} from './grid';
import {DateRange, HelpTooltip} from '../common/input'

const colors = require('../../color.js');

@observer
class StatBox extends Component{
	constructor(props){
		super(props);
	}

	render(){
		let expanded = 'expanded' in this.props && this.props.expanded;
		let bgColor = this.props.color;
		let renderArrowView = expanded ? 
				(
					<div className="flex flex-1 mt-2 cursor-pointer" onClick={this.props.onClose}>
						<ArrowUpIcon className="w-4 h-4" color={colors['white']} />
					</div>
				) : (
					<div className="flex flex-1 mt-2 cursor-pointer" onClick={this.props.onOpen}>
						<ArrowDownIcon className="w-4 h-4" color={colors['white']} />
					</div>
				);
		return (
			<div className={`flex py-4 px-2 flex-col items-center justify-center bg-${bgColor} rounded-lg`}>
				<div className="flex flex-1 relative w-full flex-row items-center">
					<div className="flex w-full justify-center">
						<span className="regular-font tracking-wide text-xs break-words text-center text-white">{this.props.title}</span>
					</div>
				</div>
				<div className="flex h-12 mt-4"><SecondaryAppSmallTitle className="text-white">{this.props.value}</SecondaryAppSmallTitle></div>
				{this.props.expandable && renderArrowView}
				{!this.props.expandable && <div className="flex flex-1 mt-2"></div>}
			</div>
		)
	}
}

@observer
class StatOverview extends Component{
	render(){
		let translate = this.props.t;
		return (
			<FullGrid>
				<div className="flex w-full flex-row items-center">
					<div className="flex"><SecondaryAppMediumTitle>{this.props.title}</SecondaryAppMediumTitle></div>
					<HelpTooltip text={this.props.tooltipText} />
				</div>
				<div>
					<div className="flex flex-row flex-1 justify-end items-center">
						<DateRange 
							t={translate}
							startDate={this.props.startDate}
							endDate={this.props.endDate}
							tempStartDate={this.props.tempStartDate}
							tempEndDate={this.props.tempEndDate}
							onDateChange={this.props.onDateChange}
							onApply={this.props.onApply}
						/>
						<div className="flex items-center ml-2 cursor-pointer">
							{this.props.downloadPDF}
						</div>
					</div>
				</div>	
				<div className="flex flex-1 flex-col">
					<div className="flex w-full mt-8 lg:mt-0 lg:w-3/5">
						<SmallBody className="text-black">{this.props.description}</SmallBody>
					</div>
					<div className="grid grid-col-2 lg:grid-cols-4 gap-8 mt-8">
						{this.props.children}
					</div>
				</div>
			</FullGrid>
		)
	}
}

export {
	StatBox,
	StatOverview
}