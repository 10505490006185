import BaseApi from '../services/base';

class OrganisationApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(page, sortBy=null){
		let queryUrl = `v1/organisations?page=${page}`
		if(sortBy != null){
			queryUrl = `${queryUrl}&sort_by=${sortBy}`
		}
		return this.fetch(queryUrl, {
			method: 'GET',
			auth: true
		})
	}

	getOrganisationById(organisationId){
		return this.fetch(`v1/organisations/${organisationId}`, {
			method: 'GET',
			auth: true
		})
	}

	inviteOrganisation(organisationPayload){
		return this.fetch(`v1/organisations/invite`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(organisationPayload)
		})
	}

	updateOrganisation(id, organisationPayload){
		return this.fetch(`v1/organisations/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(organisationPayload)
		})
	}

	archive(id){
		return this.fetch(`v1/organisations/${id}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify({
				'archived': true
			})
		})
	}

	restore(id){
		return this.fetch(`v1/organisations/${id}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify({
				'archived': false
			})
		})
	}
}

export default OrganisationApi;