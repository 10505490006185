import React, { Component, Fragment } from 'react';
import {observer} from 'mobx-react';

import {ExitIcon} from './icon';
import Modal from 'react-modal';

const modalStyles = {
  content : {
  	display: 'flex',
  	flexDirection: 'column',
  	flex: 1,
  	alignItems: 'center',
  	background: 'transparent',
  	border: 0
  },
  overlay: {
  	 position: 'fixed',
  	 top: 0,
  	 left: 0,
  	 right: 0,
  	 bottom: 0,
     backgroundColor: 'rgba(255, 255, 255, 0.1)',
     zIndex: 999
  }
};


@observer
class BaseModal extends Component{
	constructor(props){
		super(props);
		this.exitModalIcon = this.exitModalIcon.bind(this);
	}

	exitModalIcon(){
	    return (
	      <div className="absolute right-0 top-0 cursor-pointer p-5" onClick={this.props.onClose}>
	        <ExitIcon className="h-8 w-8 text-black" />
	      </div>
	    )
	  }

	render(){
		let renderModalView = null;
		if(this.props.small){
			renderModalView = (
				<div className="flex items-center justify-center shadow-lg relative border-2 border-black-30">
					{this.exitModalIcon()}
	          		{this.props.children}
		        </div>
			)
		}else{
			let modalClassName = "flex justify-center p-0 w-full lg:w-2/3 shadow-lg relative border-2 border-black-30 ";
			if(!('autoHeight' in this.props)){
				modalClassName = `${modalClassName} flex-1`
			}
			renderModalView = (
				<div className={modalClassName}>
					{this.exitModalIcon()}
		          	{this.props.children}
		        </div>
		    )
		}


		return (
			<Modal
				ariaHideApp={false}
	            isOpen={this.props.isOpen}
	            onRequestClose={this.props.onClose}
	            style={modalStyles}
	            contentLabel={this.props.label}
	          >
	          	{renderModalView}	
	          </Modal>
		)
	}
}




export default BaseModal;