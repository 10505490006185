import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';

import {VictoryPie, VictoryLine, VictoryVoronoiContainer, VictoryChart, VictoryAxis, VictoryLegend, VictoryContainer, VictoryTooltip} from 'victory'

const colors = require('../../color.js');

import moment from 'moment';

@observer
class PieChart extends Component{
	constructor(props){
		super(props);
		this.chartRef = React.createRef();
	}

	render(){
		let showLabels = 'showLabels' in this.props ? this.props.showLabels : true;
		let labelCols = 'labelCols' in this.props ? this.props.labelCols : 1;
		let labelFontSize = 'labelFontSize' in this.props ? this.props.labelFontSize : 20;
		let y = 'y' in this.props ? this.props.y : 50;
		return (
			<div className={`flex flex-1 items-center h-auto flex-col lg:flex-row overflow-x-scroll`}>
				<VictoryPie
				 containerComponent={
		            <VictoryContainer containerRef={(ref) => { this.chartRef = ref; }}/>
		          }
				  events={[{
				      target: "data",
				      eventHandlers: {
				        onClick: () => {
				          return [
				            {
				              target: "data",
				              mutation: (props) => {
				              	if('onClick' in this.props){
				              		this.props.onClick(props)
				              	}
				                return null;
				              }
				            }
				          ];
				        },
				        onMouseOver: () => {
				          return [
				            {
				              target: "data",
				              mutation: (props) => {
				              	if('onMouseOver' in this.props){
				              		this.props.onMouseOver(props)
				              	}
				                return null;
				              }
				            }
				          ];
				        }
				      }
				  }]}
				  labelComponent={
				  	<VictoryTooltip />
				  }
				  style={{
				    data: { fill: ({datum}) => {
				    	return datum.color
				    } } 
				  }}
				  data={this.props.data}
				/>
				{showLabels && 
					<div className="flex overflow-x-scroll">
						<VictoryLegend 
						  containerComponent={<VictoryContainer height={600} />}	
						  centerTitle
						  y={y}
						  itemsPerRow={labelCols}

						  orientation="horizontal"
						  style={{ labels: { 
						  		fontSize: labelFontSize,
						  		fontFamily: 'Proxima Nova Alt Regular' 
						  }}}
						  data={Object.entries(this.props.data).map((entry) => {
						  	let yData = 'yLabel' in entry[1] ? entry[1]['yLabel'] : entry[1]['y'];

						  	return { 
						  		name: `${entry[1]['x']} - ${yData}%`, symbol: { fill:entry[1]['color'], type: "circle" }}
						  })}
						/>
					</div>
				}
			</div>
		)
	}
}

const sharedAxisStyles = {
  axis: {
    stroke: colors['list-text']
  },
  tickLabels: {
    fill: colors['list-text'],
    fontSize: 12
  },
  axisLabel: {
    fill: colors['list-text'],
    padding: 36,
    fontSize: 12
  }
};

@observer
class MetricChartView extends Component{
	constructor(props){
		super(props);
		this.state = {
			show: false
		}
	}

	componentDidMount(){
		setTimeout(() => {
			this.setState({
				show: true
			})
		}, 2000);
	}


	render(){
		let chartData = this.props.data;
		if(chartData.length == 0){
			chartData = [];
		}

		let allMetrics = chartData.map((x) => x.metric);
		if(allMetrics.length == 0) return null; 
		let maxDataPoint = Math.max(...allMetrics);
		if(maxDataPoint == 0){
			maxDataPoint = 100
		}else{
			maxDataPoint = maxDataPoint * 1.25;
		}
		let allDates = chartData.map((x) => x.date);
		return (
			<VictoryChart 
				domain={{ y: [0, maxDataPoint]}}
				domainPadding={5}
				containerComponent={
				    <VictoryVoronoiContainer
				      labels={({datum}) => {
				      	return `£${parseInt(datum.metric)} (${datum.date.format('DD/MM/YYYY')})`
				      }}
				    />
				  }
				>
				<VictoryAxis
				  style={{
		          	...sharedAxisStyles,
		          }}
		          tickValues={allDates}
		          tickCount={5}
		          tickFormat={(x) => {
		          	if(moment.isMoment(x)){
		          		return x.format('DD/MM')
		          	}else{
		          		return null
		          	}
		          }}
		        />
		        <VictoryAxis
		          style={{
		          	...sharedAxisStyles,
		          	grid: {
		            	fill: colors['list-text'],
		                stroke: colors['list-text']
		           	}
		          }}
		          dependentAxis
		          tickFormat={(x) => (`£${x}`)}
		        />
				<VictoryLine
					cornerRadius={{ top: 5, bottom: 5 }}
					axis={{ stroke: colors['pink-coral'].default }}
					style={{
					  data: { stroke: colors['pink-coral'].default, strokeWidth: 5 }
					}}
			        data={chartData}
			        x="date"
			        y="metric"
			      />
			</VictoryChart>
		)
	}
}

export {
	PieChart,
	MetricChartView
}