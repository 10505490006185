import React, { Component } from 'react';

import {observer} from 'mobx-react';

import {Body, TertiaryAppSmallTitle, TertiaryAppExtraSmallTitle, SmallBody} from '../common/text'
import {TextInput, PasswordInput, TextArea, Select} from '../common/input'
import {ArrowDownIcon, ExitIcon, PlusIcon} from '../common/icon';


@observer
class DataHeader extends Component{
	render(){
		let headerClassName = "flex self-start"
		if(!('noborder' in this.props)){
			headerClassName = `${headerClassName} w-full border-b border-black py-1`;
		}
		let textColor = 'textColor' in this.props ? this.props.textColor : 'black';

		return (
			<div className={headerClassName}>
				<TertiaryAppSmallTitle className={`text-${textColor}`}>{this.props.children}</TertiaryAppSmallTitle>
			</div>
		)
	}
}


@observer
class DataRow extends Component{
	render(){
		let includeBorder = 'includeBorder' in this.props;
		let textColor = 'textColor' in this.props ? this.props.textColor : 'black';
		return (
			<div className="flex flex-1 flex-col mt-2">
				<div className="flex flex-row items-center">
					{includeBorder ? 
						<DataHeader textColor={textColor}>{this.props.label}</DataHeader> : 
						<DataHeader textColor={textColor} noborder>{this.props.label}</DataHeader>
					}
					{'tooltip' in this.props && this.props.tooltip}
				</div>
				<div className="flex flex-1">{this.props.children}</div>
			</div>
		)
	}
}

@observer
class DataEntry extends Component{
	render(){
		let textColor = 'color' in this.props ? this.props.color : "black";
		return (
			<div className="flex py-2 whitespace-pre-line">
				<Body className={`text-${textColor}`}>{this.props.children}</Body>
			</div>
		)
	}
}

const FORM_CLASS_NAME = "flex flex-1"
const INPUT_CLASS_NAME = "bg-flat-gray placeholder-list-text text-black border-0 rounded-lg";

@observer
class FormInput extends Component{
	render(){
		let formClassNames = INPUT_CLASS_NAME;
		if(this.props.extraInputClassNames){
			formClassNames = `${formClassNames} ${this.props.extraInputClassNames}`;
		}
		return (
			<div className={FORM_CLASS_NAME}>
				<TextInput 
					{...this.props}
					extraInputClassNames={formClassNames}
				/>
			</div>
		)
	}
}

@observer
class FormPasswordInput extends Component{
	render(){
		return (
			<div className={FORM_CLASS_NAME}>
				<PasswordInput 
					extraInputClassNames={INPUT_CLASS_NAME}
					{...this.props}
				/>
			</div>
		)
	}
}


@observer
class FormTextArea extends Component{
	render(){
		let textAreaClassName = `${INPUT_CLASS_NAME} p-2 h-32 w-full`;
		if(this.props.extraInputClassNames){
			textAreaClassName = `${textAreaClassName} ${this.props.extraInputClassNames}`;
		}
		return (
			<div className={FORM_CLASS_NAME}>
				<TextArea 
					className={textAreaClassName}
					{...this.props}
				/>
			</div>
		)
	}
}

@observer
class FormSelect extends Component{
	render(){
		let selectClassName = INPUT_CLASS_NAME;
		if(this.props.extraInputClassNames){
			selectClassName = `${selectClassName} ${this.props.extraInputClassNames}`;
		}
		return (
			<Select 
				className={selectClassName}
				{...this.props}
			/>
		)
	}
}

@observer
class FormSearchSelect extends Component{
	constructor(props){
		super(props);
		this.onProxyChange = this.onProxyChange.bind(this);
		this.onProxySelection = this.onProxySelection.bind(this);
		this.changeTimeoutId = null;
		this.state = {
			tempValue: null
		}
	}

	onProxyChange(val){
		this.setState({
			tempValue: val
		})
		let changeHandler = this.props.onChange;
		let timeoutId = this.changeTimeoutId;
		if(timeoutId){
			clearTimeout(timeoutId);
		}
		this.changeTimeoutId = setTimeout(() => {
			changeHandler(val);
		}, 500);
	}

	onProxySelection(val){
		this.setState({
			tempValue: ''
		})
		this.props.onSelection(val);
	}

	componentDidUnmount(){
		if(this.changeTimeoutId){
			clearTimeout(this.changeTimeoutId);
		}
	}

	render(){
		let formClassNames = INPUT_CLASS_NAME;
		if(this.props.extraInputClassNames){
			formClassNames = `${formClassNames} ${this.props.extraInputClassNames}`;
		}

		let hasSelectedValue = 'hasSelection' in this.props ? this.props.hasSelection: false;
		let hasValue = 'value' in this.props ? this.props.value != null && this.props.value.length > 0 : false;
		return (
			<div className="flex flex-1 flex-col">
				<div className={`${FORM_CLASS_NAME} relative`}>
					<TextInput 
						insetoption={!hasSelectedValue ? 
								<ArrowDownIcon color="black" className="w-4 h-4" /> : 
								<ExitIcon onClick={(e) => this.props.onClear()} color="black" className="w-4 h-4" /> }
						
						
						{...this.props}
						value={hasSelectedValue ? this.props.value : this.state.tempValue}
						onChange={(e) => this.onProxyChange(e.target.value)}
						disabled={hasSelectedValue}
						extraInputClassNames={formClassNames}
					/>
					{hasValue && !hasSelectedValue && 
						<div className="absolute top-0 mt-16 z-50 bg-white left-0 flex h-24 overflow-scroll w-full rounded-lg border border-list-text px-4 py-4">
							<div className="flex flex-1 flex-col">
								{'options' in this.props && this.props.options.map((option) => 
									<div className="mt-1" onClick={(e) => this.onProxySelection(option.value)}><SmallBody>{option.label}</SmallBody></div>
								)}
							</div>
						</div>
					}
				</div>
			</div>
		)
	}
}

@observer
class FormTab extends Component{
	render(){
		let borderClassName = "black";
		let borderThickness = "border-b"
		let isActive = this.props.active;
		if(isActive){
			borderClassName = `nav-inactive-color`;
			borderThickness = `border-b-4`
		}
		let titleClassName = isActive ? "text-nav-inactive-color" : "text-black";
		if(this.props.titleClassName){
			titleClassName = `${titleClassName}`
		}
		let title = this.props.small ? <TertiaryAppExtraSmallTitle className={titleClassName}>{this.props.children}</TertiaryAppExtraSmallTitle> : <TertiaryAppSmallTitle className={titleClassName}>{this.props.children}</TertiaryAppSmallTitle>;
		let formTabClassName = `flex flex-1 flex-col lg:flex-row cursor-pointer ${borderThickness} border-${borderClassName} cursor-pointer`;
		if(this.props.extraInputClassNames){
			formTabClassName = `${formTabClassName} ${this.props.extraInputClassNames}`
		}
		return (
			<div className={formTabClassName} onClick={this.props.onClick}>
				<div className="flex px-4 justify-center text-center w-full py-2 cursor-pointer">
					{title}
				</div>
			</div>
		)
	}
}

@observer
class GridSection extends Component{
	render(){
		let bgColor = 'bgColor' in this.props ? this.props.bgColor : 'white';
		return (
			<div className={`flex flex-col bg-${bgColor} rounded-lg px-4 py-8 h-full`}>
				{this.props.children}
			</div>
		)
	}
}

@observer
class TwoColumnReadList extends Component{
	render(){
		let textColor = 'color' in this.props ? this.props.color : 'black';
		return (
			<div className="grid grid-cols-2 col-gap-8 row-gap-0 mt-4">
				<div className="flex flex-row col-span-2 border-b border-black justify-between">
					<DataHeader noborder>{this.props.headers[0]}</DataHeader>
					<DataHeader noborder>{this.props.headers[1]}</DataHeader>
				</div>
				{this.props.data.map((d) => 
					<div className="flex flex-row col-span-2 border-b border-black justify-between">
						<div className="flex"><DataEntry color={textColor}>{d[0]}</DataEntry></div>
						<div className="flex"><DataEntry color={textColor}>{d[1]}</DataEntry></div>
					</div>
				)}
				{this.props.data.length == 0 &&
					<div className="col-span-2 mt-2">
						{this.props.nodata}
					</div>
				}
			</div>
		)
	}
}

@observer
class AddListButton extends Component{
	render(){
		return (
			<div className="flex flex-row items-center cursor-pointer mt-2" onClick={this.props.onClick}>
				<div className="flex"><PlusIcon className="w-8 h-8" color="black" /></div>
				<div className="flex cursor-pointer"><TertiaryAppSmallTitle>{this.props.text}</TertiaryAppSmallTitle></div>
			</div>
		)
	}
}

export {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormPasswordInput,
	FormTextArea,
	FormSelect,
	FormSearchSelect,
	FormTab,
	GridSection,
	TwoColumnReadList,
	AddListButton,
	INPUT_CLASS_NAME
}