import BaseApi from '../services/base';

class RegulationApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getRegulationsForRegion(regionId){
		return this.fetch(`v1/regulations?region_id=${regionId}`, {
			method: 'GET',
			auth: true
		})
	}

	newRegulation(payload){
		return this.fetch(`v1/regulations`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	updateRegulation(regulationId, payload){
		return this.fetch(`v1/regulations/${regulationId}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}
}

export default RegulationApi;