import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';

import {inject, observer} from 'mobx-react';
import { SmallBody, ExtraSmallBody, Body, SecondaryAppLargeTitle, SecondaryAppMediumTitle, SecondaryAppSmallTitle, TertiaryAppSmallTitle,TertiaryAppExtraSmallTitle} from '../../common/text';
import { ColorBookIcon, PlusIcon, ArrowRightIcon, SearchIcon, EvidenceSearchIcon, FilterDialIcon, ListIcon, ArrowDownIcon, TileIcon } from '../../common/icon'
import {TextInput} from '../../common/input';
import {Button} from '../../common/button';
import {ApplyText} from '../../common/text';
import ViewPDF from '../../common/view-pdf';
import Main from '../../elements/main';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormSelect,
	FormPasswordInput
} from '../../elements/form';

import Loader from '../../common/loader';
import {SortDialog, SortingField} from '../../elements/list';
import {TagInput, Tag} from '../../common/tag';

import {SearchResultsOverview, NoSearchResults} from '../search-elements';

const colors = require('../../../color.js');

@observer
class RefineEvidenceSearch extends Component{
	render(){
		let translate = this.props.t;
		let tags = this.props.tags;
		let tagValue = this.props.tagValue;
		let possibleTags = this.props.possibleTags;
		return (
			<div className="flex flex-1 flex-col bg-white rounded-lg mt-8 px-8 py-8">
				<div className="flex">
					<SecondaryAppMediumTitle>{translate('user.advanced-search.refine-search.title')}</SecondaryAppMediumTitle>
				</div>
				<div className="grid grid-cols-5 gap-8 mt-8">
					<div className="col-span-3 flex w-full flex-col">
						<div className="flex flex-row items-center">
							<div className="flex"><FilterDialIcon className="w-4 h-4" color={colors['nav-active-color']} /></div>
							<div className="flex ml-4"><SmallBody className="bold-font text-nav-active-color">{translate('user.advanced-search.refine-search.filter')}</SmallBody></div>
						</div>
						<div className="flex flex-row w-full items-center">
							<div className="flex w-full flex-col justify-start">
								<FormInput 
									placeholder={translate('user.advanced-search.search-input.placeholder')}
									value={this.props.query}
									onChange={(e) => this.props.onChangeQuery(e.target.value)}
								/>
							</div>
							<div className="relative flex w-full flex-col ml-4">
								<div className="flex w-full">
									<TagInput
										 inputValue={tagValue}
										 t={translate}
										 onNewEntry={(tag) => this.props.onNewTag(tag)}
										 onChange={(tags) => this.props.onChangeTags(tags)}
										 onInputChange={(tag) => this.props.onChangeTagInput(tag)}
										 values={tags} 
									/>
								</div>
								{possibleTags.length > 0 && 
									<div className="absolute cursor-pointer bg-white z-50 mt-12 top-0 left-0 flex flex-col w-full border border-nav-inactive-color mt-2 rounded-lg py-4 px-4">
										{possibleTags.map((pt) => 
											<div key={pt.id} className="flex py-1" onClick={(e) => {
												e.stopPropagation();
												this.props.onSelectPossibleTag(pt.id)
											}}>
												<SmallBody>{pt.name}</SmallBody>
											</div>
										)}
									</div>
								}
							</div>
							<div className="flex w-full flex-col ml-4 justify-start">
								
							</div>
						</div>
						<div className="flex mt-1">
							<ApplyText 
								t={translate} 
								text={translate('user.advanced-search.refine-search.apply-filter')} 
								onClick={() => this.props.onTriggerSearch()}
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<div className="flex">
							<SmallBody className="bold-font text-nav-active-color">{translate('user.advanced-search.refine-search.view')}</SmallBody>
						</div>
						<div className="flex flex-row mt-3">
							<div className="flex flex-row items-center cursor-pointer" onClick={() => this.props.onTileMode()}>
								<TileIcon className="w-4 h-4" color={this.props.isTileView ? colors['black'] : colors['nav-inactive-color']} />
								<div className="flex ml-2"><SmallBody className={`text-${this.props.isTileView ? 'black' : 'nav-inactive-color'}`}>{translate('user.advanced-search.refine-search.tile-view')}</SmallBody></div>
							</div>
							<div className="flex flex-row items-center ml-8 cursor-pointer" onClick={() => this.props.onListMode()}>
								<ListIcon className="w-4 h-4" color={this.props.isListView ? colors['black'] : colors['nav-inactive-color']} />
								<div className="flex ml-2"><SmallBody className={`text-${this.props.isListView ? 'black' : 'nav-inactive-color'}`}>{translate('user.advanced-search.refine-search.list-view')}</SmallBody></div>
							</div>
						</div>
					</div>
					<div className="flex flex-col items-start">
						<div className="relative flex w-full justify-end" onClick={() => this.props.onToggleSortDialog()}>
							<div className="flex flex-col items-end">
								<SortingField 
									text={translate("user.advanced-search.refine-search.sort")}
									extraClassNames="bold-font text-nav-active-color text-sm"
								/>
								{this.props.currentSortAttribute != null && 
									<div className="flex flex-row items-center mt-2 cursor-pointer">
										<SmallBody>{this.props.currentSortAttribute}</SmallBody>
										<div className="flex ml-2"><ArrowDownIcon color={colors['nav-inactive-color']} className="w-2 h-2" /></div>
									</div>
								}
							</div>
							{this.props.showSortDialog && 
									<SortDialog
									 	t={translate}
										sortOptions={this.props.sortOptions}
										currentOption={this.props.sortBy}
										onOption={(val) => this.props.onChangeSortOption(val)}
										onApply={() => this.props.onApplySort()}
									/>
								}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

@observer
class CurrentEvidenceOverview extends Component{
	render(){
		let translate = this.props.t;
		let currentEvidence = this.props.currentEvidence;
		
		return (
			<div className="flex flex-col w-full">
				<div className={`flex flex-col overflow-x-scroll w-full bg-white rounded-lg py-8 px-8 rounded-lg border-border-color border cursor-pointer`}>
					<div className="flex w-full flex-row items-center justify-between border-b border-black pb-2">
						<div className="flex"><SecondaryAppMediumTitle>{currentEvidence.title}</SecondaryAppMediumTitle></div>
						<div className="flex"><ExtraSmallBody>{currentEvidence.evidence_date}</ExtraSmallBody></div>
					</div>
					<div className="flex w-full flex-col justify-center mt-2 pb-2">
						<div className="flex"><ExtraSmallBody>{currentEvidence.author}</ExtraSmallBody></div>
						{currentEvidence.evidence_type != null && 
							<div className="flex">
								<ExtraSmallBody className="italic">{currentEvidence.evidence_type.name} | {translate('user.evidence-search.page-number')} {currentEvidence.page_number}</ExtraSmallBody>
							</div>
						}
					</div>
					<div className="flex w-full flex-col justify-center mt-2 pb-2">
						<div className="flex"><Body>{currentEvidence.description}</Body></div>
					</div>
					
				</div>
				{currentEvidence.evidence_file?.file_url && 
					<ViewPDF 
						style={{ width: '100%', height: '800px', marginTop: '2rem', borderRadius: '5px'}} 
						url={currentEvidence.evidence_file?.file_url} 
						type="application/pdf"
						/>
				}
			</div>
		)
	}
}



@observer
class EvidenceResult extends Component{
	render(){
		let translate = this.props.t;
		let evidenceResult = this.props.evidenceResult;
		
		return (
			<div className={`flex flex-col overflow-x-scroll w-full bg-white rounded-lg py-8 px-8 rounded-lg border-border-color border cursor-pointer`}>
				<div className="flex relative w-full flex-row border-b border-black h-48 overflow-scroll">
					<div className="flex w-5/6"><SecondaryAppMediumTitle>{evidenceResult.title}</SecondaryAppMediumTitle></div>
					<div className="absolute right-0 top-0"><ExtraSmallBody>{evidenceResult.evidence_date}</ExtraSmallBody></div>
				</div>
				<div className="flex w-full flex-col justify-center mt-2 border-b border-black pb-2 h-36 overflow-y-scroll">
					<div className="flex"><ExtraSmallBody>{evidenceResult.author}</ExtraSmallBody></div>
					<div className="flex flex-col">
						<div className="flex"><ExtraSmallBody className="italic">{evidenceResult.evidence_type.name} | {translate('user.evidence-search.page-number')} {evidenceResult.page_number}</ExtraSmallBody></div>
						<div className="flex"><ExtraSmallBody className="italic">{translate('user.evidence-search.references')} {evidenceResult.references}</ExtraSmallBody></div>
					</div>
				</div>
				<div className="flex w-full justify-center flex-col mt-2 border-b border-black pb-2 h-36 overflow-y-scroll">
					<div className="flex">
						<SmallBody className="bold-font">{translate('user.evidence-search.ingredients')} / {translate('user.evidence-search.tags')}</SmallBody>
					</div>
					<div className="flex mt-2">
						<SmallBody className="italic">{evidenceResult.ingredients.map((i) => i.name).join(',')}</SmallBody>
					</div>
					<div className="flex mt-1">
						{evidenceResult.tags.map((tag, idx) => 
							<Tag key={tag.id} name={tag.name} />
						)}
					</div>
				</div>
				<div className="flex w-full flex-col mt-2 flex-1">
					<div className="flex">
						<Body>{evidenceResult.description}</Body>
					</div>
				</div>
				
				<div className="flex w-full flex-col mt-12">
					<div className="flex w-full mt-4">
						<Button
							width="w-full"
							height="h-12"
							className="rounded-lg"
							bgColor="nav-active-color"
							onClick={() => this.props.onClickEvidenceResult(evidenceResult.id)}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{translate('user.evidence-search.view-evidence')} &nbsp;</TertiaryAppSmallTitle></div>
							</div>
						</Button>
					</div>
					{this.props.canManageEvidence && 
						<div className="flex w-full mt-4">
							<Button
								width="w-full"
								height="h-12"
								className="rounded-lg"
								bgColor="pink-coral"
								onClick={() => this.props.onManageEvidence(evidenceResult.id)}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.evidence-search.edit-evidence')} &nbsp;</TertiaryAppSmallTitle></div>
								</div>
							</Button>
						</div>
					}
				</div>
			</div>	
		)
	}
}

@inject('evidenceSearchStore')
@observer
class EvidenceSearch extends Component{
	constructor(props){
		super(props);
		this.handleQueryKeyDown = this.handleQueryKeyDown.bind(this);
		this.onSearch = this.onSearch.bind(this);
	}

	componentDidMount(){
		let evidenceSearchStore = this.props.evidenceSearchStore;
		if('evidenceId' in this.props && this.props.evidenceId != null){
			evidenceSearchStore.viewEvidence(this.props.evidenceId);
		}else{
			evidenceSearchStore.fetchEvidence();
		}
	}

	componentWillUnmount(){
		let evidenceSearchStore = this.props.evidenceSearchStore;
		evidenceSearchStore.initStore();
	}

	handleQueryKeyDown(event){
	    let evidenceSearchStore = this.props.evidenceSearchStore;
	    switch (event.key) {
	      case 'Enter':
	      case 'Tab':
	        evidenceSearchStore.triggerSearch();
	        event.preventDefault();
	    }
  	}

  	onSearch(){
  		let evidenceSearchStore = this.props.evidenceSearchStore;
  		if(evidenceSearchStore.query?.length > 0){
  			 evidenceSearchStore.triggerSearch();
  		}
  	}

	
	render(){
		let translate = this.props.t;
		let evidenceSearchStore = this.props.evidenceSearchStore;
		let resultsContainerClassName = "grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8"
		if(evidenceSearchStore.isListView){
			resultsContainerClassName = "grid grid-cols-1 gap-8 mt-8"
		}

		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.evidence-search.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.evidence-search.paragraph.part1')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={() => window.open('http://help.labaroma.com/en/articles/4745442-10-evidence-search-tutorial', '_blank')}>
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.evidence-search.video-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex hidden lg:block ml-8">
							<EvidenceSearchIcon className="w-48 h-48" />
						</div>
					</div>
					{evidenceSearchStore.canUploadEvidence && 
						<div className="flex flex-row w-full mt-8 lg:mt-0 justify-end mb-4">
							<Button
								width="w-auto"
								height="h-12"
								className="ml-4 px-4 rounded-lg"
								bgColor="pink-coral"
								fetching={evidenceSearchStore.fetching}
								onClick={() => evidenceSearchStore.goToAddEvidence()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.evidence-search.new-evidence')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><PlusIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						</div>
					}
					{evidenceSearchStore.viewingCurrentEvidence != null && 
						<div className="flex flex-col w-full">
							<div className="flex flex-row w-full justify-end mb-4">
								<Button
									width="w-auto"
									height="h-12"
									className="ml-4 px-4 rounded-lg"
									bgColor="nav-active-color"
									onClick={() => evidenceSearchStore.backToEvidenceSearch()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.evidence-search.back-to-search')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><ArrowRightIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</div>
							<div className="flex flex-col w-full mt-8">
								<CurrentEvidenceOverview
									currentEvidence={evidenceSearchStore.viewingCurrentEvidence}
									t={translate} 
								/>
							</div>
						</div>
					}
					{evidenceSearchStore.viewingCurrentEvidence == null &&
						<>
							{!evidenceSearchStore.showRefinedSearch && !evidenceSearchStore.searching &&
								<div className="flex flex-row items-center w-full mt-4 cursor-pointer">
									<div className="hidden lg:flex flex items-center justify-center bg-nav-active-color h-16 rounded-l-lg w-48" onClick={this.onSearch}>
										<SmallBody className="text-white">{translate('user.advanced-search.search-input.label')}</SmallBody>
									</div>
									<TextInput 
										value={evidenceSearchStore.query}
										onKeyDown={this.handleQueryKeyDown}
										onChange={(e) => evidenceSearchStore.changeSearchQuery(e.target.value)}
										insetoption={
											<div onClick={this.onSearch}>
												<SearchIcon className="w-4 h-4 mb-1" />
											</div>
										} 
										placeholder={translate('user.advanced-search.search-input.placeholder')}
										extraInputClassNames="pl-8 border-0 h-16 w-full rounded-r-lg text-nav-inactive-color placeholder-nav-inactive-color"
									/>
								</div>	
							}
							{evidenceSearchStore.searching && 
								<div className="flex flex-row items-center w-full mt-4">
									<div className="flex items-center justify-center">
										<Body className="text-lg">{translate('user.advanced-search.searching')} {`'${evidenceSearchStore.currentSearchQuery}'`}</Body>
									</div>
								</div>
							}
							{evidenceSearchStore.showRefinedSearch && !evidenceSearchStore.searching &&
								<RefineEvidenceSearch 
									t={translate} 
									query={evidenceSearchStore.query}
									onChangeQuery={(val) => evidenceSearchStore.changeSearchQuery(val)}
									tags={evidenceSearchStore.currentSearchTags}
									tagValue={evidenceSearchStore.currentTagInput}
									onNewTag={(tag) => console.log(tag)}
									onChangeTags={(tags) => evidenceSearchStore.onChangeTags(tags)}
									onChangeTagInput={(tag) => evidenceSearchStore.onChangeTagInput(tag)}
									possibleTags={evidenceSearchStore.possibleTags}
									onSelectPossibleTag={(id) => evidenceSearchStore.onSelectPossibleTag(id)}
									onTileMode={() => evidenceSearchStore.changeToTileViewMode()}
									onListMode={() => evidenceSearchStore.changeToListViewMode()}
									isTileView={evidenceSearchStore.isTileView}
									isListView={evidenceSearchStore.isListView}
									onToggleSortDialog={() => evidenceSearchStore.onToggleSortDialog()}
									showSortDialog={evidenceSearchStore.showSortDialog}
									sortBy={evidenceSearchStore.sortBy}
									onChangeSortOption={(val) => evidenceSearchStore.onChangeSortOption(val)}
									onApplySort={() => evidenceSearchStore.onApplySort()}
									sortOptions={evidenceSearchStore.sortOptions}	
									currentSortAttribute={evidenceSearchStore.currentSortAttribute}	
									onTriggerSearch={() => evidenceSearchStore.triggerSearch()}						
								/>
							}
							{evidenceSearchStore.showSearchResults && 
								<>	
									{evidenceSearchStore.noOfResults == 0 && 
										<NoSearchResults
										 	onNewSearch={() => evidenceSearchStore.resetSearch()} 
										 	t={translate} 
										 	text={translate('user.evidence-search.new-evidence-search')}
										 />
									}
									{evidenceSearchStore.noOfResults > 0 && !evidenceSearchStore.initialLoading && <SearchResultsOverview t={translate} query={evidenceSearchStore.currentSearchQuery} noOfResults={evidenceSearchStore.noOfResults} />}
									<InfiniteScroll
									  dataLength={evidenceSearchStore.evidenceResults.length}
									  next={() => evidenceSearchStore.nextSearchResultsPage()}
									  hasMore={evidenceSearchStore.hasMoreSearchResults}
									  loader={evidenceSearchStore.initialLoading ? null :<Loader t={translate} />}
									  endMessage={null}
									>
										<div className={resultsContainerClassName}>
											{evidenceSearchStore.evidenceResults.map((evidenceResult, idx) => 
												<EvidenceResult 
													key={idx} 
													onClickEvidenceResult={(id) => evidenceSearchStore.viewEvidence(id)}
													canManageEvidence={evidenceSearchStore.canUploadEvidence}
													onManageEvidence={(id) => evidenceSearchStore.manageEvidence(id)}
													evidenceResult={evidenceResult} 
													flexDirection={evidenceSearchStore.isListView ? "flex-row" : "flex-col"}
													t={translate} 
												/>
											)}
										</div>
									</InfiniteScroll>
								</>
							}
						</>
					}
				</div>
			</Main>
		)
	}
}

export default withTranslation()(EvidenceSearch);