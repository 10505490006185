import React, {Component} from 'react';


const HomepageNavItem = ({children, onClick=null, className=null}) => {
	let elementClassName = "flex text-center text-base text-black semibold-font tracking-widest uppercase cursor-pointer"
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return (<span onClick={onClick} className={elementClassName}>{children}</span>)
}

const HomepageTitle = ({children, className=null}) => {
	let elementClassName = "block bold-font uppercase tracking-widest text-black text-4xl lg:text-5xl xl:text-6xl"
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return (<h1 className={elementClassName}>{children}</h1>)
}

const HomepageSubTitle = ({children, className=null}) => {
	let elementClassName = "block regular-font uppercase tracking-widest text-black text-base"
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return (<h3 className={elementClassName}>{children}</h3>)
}

const PrimaryWebSubtext = ({children, className=null}) => {
	let elementClassName = "text-sm regular-font"
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return (<span className={elementClassName}>{children}</span>)
}

const PrimaryWebBody = ({children, className=null}) => {
	let elementClassName = "text-lg regular-font"
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return (<span className={elementClassName}>{children}</span>)
}

const PrimaryWebMarketing = ({children, className=null}) => {
	let elementClassName = "text-2xl regular-font"
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return (<span className={elementClassName}>{children}</span>)
}

const PrimaryAppSubtext = ({children, className=null}) => {
	let elementClassName = "text-xs regular-font"
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return (<span className={elementClassName}>{children}</span>)
}

const PrimaryAppBody = ({children, className=null}) => {
	let elementClassName = "text-base regular-font"
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return (<span className={elementClassName}>{children}</span>)
}

const SecondaryWebSmallTitle = ({children, className=null}) => {
	let elementClassName = "text-lg bold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const SecondaryWebExtraSmallTitle = ({children, className=null}) => {
	let elementClassName = "text-sm bold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const SecondaryWebMediumTitle = ({children, className=null}) => {
	let elementClassName = "text-2xl bold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const SecondaryWebLargeTitle = ({children, className=null}) => {
	let elementClassName = "text-3xl bold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const SecondaryWebLargerTitle = ({children, className=null}) => {
	let elementClassName = "text-4xl bold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const SecondaryWebExtraLargeTitle = ({children, className=null}) => {
	let elementClassName = "bold-font text-3xl lg:text-4xl xl:text-5xl";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const SecondaryAppLargerTitle = ({children, className=null}) => {
	let elementClassName = "text-4xl bold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const SecondaryAppLargeTitle = ({children, className=null}) => {
	let elementClassName = "text-3xl bold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const SecondaryAppMediumTitle = ({children, className=null}) => {
	let elementClassName = "text-2xl bold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const SecondaryAppSmallTitle = ({children, className=null}) => {
	let elementClassName = "text-xl bold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const SecondaryAppExtraSmallTitle = ({children, className=null}) => {
	let elementClassName = "text-md bold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const TertiaryAppMediumTitle = ({children, className=null}) => {
	let elementClassName = "text-lg semibold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const TertiaryAppSmallTitle = ({children, className=null}) => {
	let elementClassName = "text-base semibold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const TertiaryAppExtraSmallTitle = ({children, className=null}) => {
	let elementClassName = "text-sm semibold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const TertiaryAppExtraExtraSmallTitle = ({children, className=null}) => {
	let elementClassName = "text-xs semibold-font";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

const CTATitle = ({children, className=null}) => {
	let elementClassName = "bold-font text-3xl lg:text-4xl";
	if(className){
		elementClassName = `${elementClassName} ${className}`
	}
	return <span className={elementClassName}>{children}</span>
}

class MarketingBody extends Component{
	render(){
		let bodyClassName = 'bold-font text-base';
		if(this.props.className){
			bodyClassName = `${bodyClassName} ${this.props.className}`;
		}
		return (
			<span className={bodyClassName} {...this.props}>{this.props.children}</span>
		)
	}
}

class Heading extends Component{
	render(){
		return (
			<h1 {...this.props}>{this.props.children}</h1>
		)
	}
}

class SubHeading extends Component{
	render(){
		return (
			<h2 {...this.props}>{this.props.children}</h2>
		)
	}
}


class Label extends Component{
	render(){
		return (
			<label {...this.props} className="bold-font px-2">{this.props.children}{this.props.required && '*'}</label>
		)
	}
}


class Body extends Component{
	render(){
		let bodyClassName = 'text-base whitespace-pre-line';
		if(this.props.className){
			bodyClassName = `${bodyClassName} ${this.props.className}`;
		}
		return (
			<div className={bodyClassName} {...this.props}>{this.props.children}</div>
		)
	}
}

class SmallBody extends Component{
	render(){
		let bodyClassName = "block text-sm whitespace-pre-line";
		if(this.props.className){
			bodyClassName = `${bodyClassName} ${this.props.className}`;
		}
		return (
			<span className={bodyClassName}>{this.props.children}</span>
		)
	}
}

class ExtraSmallBody extends Component{
	render(){
		let bodyClassName = "block text-xs whitespace-pre-line";
		if(this.props.className){
			bodyClassName = `${bodyClassName} ${this.props.className}`
		}
		return (
			<span className={bodyClassName}>{this.props.children}</span>
		)
	}
}



class CallToAction extends Component{
	render(){
		return (
			<div className="font-normal">{this.props.children}</div>
		)
	}
}

class LineRule extends Component{
	render(){
		return <div className="flex flex-1 flex-row w-full border-black border-b-2 py-4"></div>
	}
}

class ApplyText extends Component{
	render(){
		let translate = this.props.t;


		let text = 'text' in this.props ? this.props.text : translate('common.apply');
		return (
			<div className="flex" onClick={this.props.onClick}>
				<SmallBody className="bold-font text-petroleum-blue cursor-pointer">{text}</SmallBody>
			</div>
		)
	}
}

export {
	HomepageNavItem,
	HomepageTitle,
	HomepageSubTitle,
	PrimaryWebSubtext,
	PrimaryWebBody,
	PrimaryWebMarketing,
	PrimaryAppSubtext,
	PrimaryAppBody,
	SecondaryWebExtraSmallTitle,
	SecondaryWebSmallTitle,
	SecondaryWebMediumTitle,
	SecondaryWebLargeTitle,
	SecondaryWebLargerTitle,
	SecondaryWebExtraLargeTitle,
	SecondaryAppLargerTitle,
	SecondaryAppLargeTitle,
	SecondaryAppMediumTitle,
	SecondaryAppSmallTitle,
	SecondaryAppExtraSmallTitle,
	TertiaryAppMediumTitle,
	TertiaryAppSmallTitle,
	TertiaryAppExtraSmallTitle,
	TertiaryAppExtraExtraSmallTitle,
	CTATitle,
	Heading,
	SubHeading,
	Label,
	Body,
	SmallBody,
	ExtraSmallBody,
	CallToAction,
	LineRule,
	ApplyText
};