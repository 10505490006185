import { observable, action, computed } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isRequiredValidator, isNotNullValidator, isPositiveNumberValidator } from '../../../services/validation';

import CommonApi from '../../../services/common';
import EvidenceApi from '../../../services/evidence';
import IngredientApi from '../../../services/ingredient';
import TagApi from '../../../services/tag';
import S3Api from '../../../services/s3';

import uuidv4 from 'uuid/v4';

class ManageEvidence{
	appStore;

	id;

	@observable editMode;
	@observable evidenceData;
	@observable evidenceTypes;

	@observable fetching;
	@observable currentFileUpload;
	@observable currentFileUploadUrl;
	@observable selectedIngredient;

	@observable ingredientSearchText;
	@observable possibleIngredients;
	@observable searchIngredients;

	@observable searchTags;
	@observable currentTagInput;
	@observable possibleTags;

	@observable newFileToAttach;

	commonApi;
	tagApi;
	evidenceApi;
	ingredientApi;
	s3Api;


	constructor(appStore){
		this.appStore = appStore
		this.commonApi = new CommonApi(this.appStore);
		this.evidenceApi = new EvidenceApi(this.appStore);
		this.ingredientApi = new IngredientApi(this.appStore);
		this.tagApi = new TagApi(this.appStore);
		this.s3Api = new S3Api(this.appStore);
		this.initStore();
	}

	initStore(){
		this.evidenceData = {
			title: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			code: new FieldState(null),
			author: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			selectedEvidenceType: new FieldState(null).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			evidenceDate: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			description: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			references: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			pageNumber: new FieldState(null).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n))
		}
		this.currentFileUpload = null;
		this.currentFileUploadUrl = null;
		this.evidenceDataForm = new FormState({
			title: this.evidenceData.title,
			code: this.evidenceData.code,
			author: this.evidenceData.author,
			selectedEvidenceType: this.evidenceData.selectedEvidenceType,
			evidenceDate: this.evidenceData.evidenceDate,
			description: this.evidenceData.description,
			references: this.evidenceData.references,
			pageNumber: this.evidenceData.pageNumber
		})
		this.editMode = false;
		this.fetching = false;
		this.id = null;
		this.evidenceTypes = [];
		this.ingredientSearchText = null;
		this.selectedIngredient = null;
		this.searchIngredients = [];
		this.currentTagInput = '';
		this.searchTags = [];
		this.possibleTags = [];
		this.possibleIngredients = [];
		this.newFileToAttach = false;
	}

	formSync(evidenceId){
		this.fetching = true;
		this.evidenceApi.getEvidenceById(evidenceId)
			.then((response) => {
				this.evidenceData.title.value = response.title;
				this.evidenceData.code.value = response.evidence_code;
				this.evidenceData.author.value = response.author;
				this.evidenceData.selectedEvidenceType.value = response.evidence_type?.id;
				this.evidenceData.evidenceDate.value = response.evidence_date;
				this.evidenceData.description.value = response.description;
				this.evidenceData.references.value = response.references;
				this.evidenceData.pageNumber.value = parseInt(response.page_number, 10);
				this.searchIngredients = response.ingredients.map((i) => {
					return {
						value: i.id,
						label: `${i.name} (${i.latin_name})`
					}
				})
				this.searchTags = response.tags.map((t) => {
					return {
						value: t.id,
						label: t.name
					}
				})
				if(response.evidence_file != null){
					this.currentFileUploadUrl = response.evidence_file.file_url;

					this.currentFileUpload = {
						id: response.evidence_file.id,
						name: response.evidence_file.name,
						file_url: response.evidence_file.file_url,
						uuid: response.evidence_file.uuid,
						file: null
					}
				}
				this.id = response.id;
				this.editMode = true;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})

	}


	fetchEvidenceTypes(){
		this.evidenceApi.getAllEvidenceTypes()
			.then((response) => {
				this.evidenceTypes = response.evidence_types;
			})
			.catch((error) => {
				console.log(error);
			})
	}
	

	@computed get validForm(){
		let requiredFields = ['title', 'code', 'author', 'selectedEvidenceType', 'evidenceDate', 'description', 'references', 'pageNumber'];
		let isValidForm = true;
		for(let field of requiredFields){
			if(this.evidenceData[field].value == null || this.evidenceData[field].value.length == 0){
				isValidForm = false;
			}else{
				let fieldError = this.evidenceData[field].error;
				isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
			}
		}
		return isValidForm;
	}

	handleSuccess(response){
		
	}

	saveEvidence = async () => {
		let res = await this.evidenceDataForm.validate()
		if(res.hasError) return;

		let evidenceFile = null;
		if(this.currentFileUpload != null && this.newFileToAttach){
			evidenceFile = {
				uuid: this.currentFileUpload.uuid,
				file_type: this.currentFileUpload.file.type,
				name: this.currentFileUpload.file.name
			}
		}
		let payload = {
			title: this.evidenceData.title.value,
			evidence_code: this.evidenceData.code.value,
			author: this.evidenceData.author.value,
			evidence_type_id: this.evidenceData.selectedEvidenceType.value,
			evidence_date: this.evidenceData.evidenceDate.value,
			description: this.evidenceData.description.value,
			references: this.evidenceData.references.value,
			page_number: parseInt(this.evidenceData.pageNumber.value, 10),
			ingredient_ids: this.searchIngredients.map((i) => i.value),
			tag_ids: this.searchTags.map((i) => i.value),
			evidence_file: evidenceFile
		}
		this.fetching = true;
		try{
			let response = null;
			if(this.editMode && this.id != null){
				response = await this.evidenceApi.updateEvidence(this.id, payload);
			}else{
				response = await this.evidenceApi.newEvidence(payload);
			}
				
			let presignedPostData = response.presigned_post;
			if(this.currentFileUpload != null && presignedPostData != null){
				try{
					let fileS3Response = await this.s3Api.uploadToS3(this.currentFileUpload.file, presignedPostData.presigned_post);
				}catch(e){
					let uploadFileErrorMsg = `${this.appStore.i18n.t('error.uploading')} ${this.currentFileUpload.file.name}`;
					this.appStore.alertError(uploadFileErrorMsg);
				}
			}
			this.appStore.displaySaveDialog();
			this.appStore.goToEvidenceSearch();
		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}

	changeCurrentUploadFile(files){
		if(files.length == 1){
			let newFile = files[0];
			this.uploading = true;
			this.currentFileUpload = {
				id: null,
				name: newFile.name,
				file_url: newFile.uri,
				uuid: uuidv4(),
				file: newFile
			}
			this.currentFileUploadUrl = URL.createObjectURL(newFile);
			this.uploading = false;
			this.newFileToAttach = true;
		}
		
	}

	@computed get evidenceTypeOptions(){
		return this.evidenceTypes.map((e) => {
			return {
				value: e.id,
				label: e.name
			}
		});
	}

	@action onChangeIngredientSearchText(val){
		this.ingredientSearchText = val;
		if(this.ingredientSearchText.length > 0){
			this.ingredientApi.getAllIngredients(1, this.ingredientSearchText, null,null,null, null, false, true)
				.then((response) => {
					this.possibleIngredients = response.ingredients;
				})
				.catch((error) => {
					console.log(error);
				})
		}
	}

	onNewTag(tag){
	 	if(this.searchTags.map((t) => t.value).includes(tag.id)) return;
	 	this.searchTags.push({
	 		value: tag.id,
	 		label: tag.name
	 	});
	 	this.currentTagInput = '';
	 	this.possibleTags = [];
	}

	onNewIngredient(ingredient){
	 	if(this.searchIngredients.map((i) => i.value).includes(ingredient.id)) return;
	 	this.searchIngredients.push({
	 		value: ingredient.id,
	 		label: `${ingredient.name} (${ingredient.latin_name})`
	 	});
	 	this.ingredientSearchText = '';
	 	this.possibleIngredients = [];
	}
	
	onChangeTagInput(val){
		this.currentTagInput = val;
		if(this.currentTagInput != null && this.currentTagInput.length > 0){
			this.searchPossibleTags();
		}
	}	

	searchPossibleTags(){
		if(this.currentTagInput == null || this.currentTagInput === '') return;
		this.tagApi.searchTags(this.currentTagInput)
			.then((response) => {
				this.possibleTags = response.tags;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onChangeTags(tags){
	 	this.searchTags = tags == null ? [] : tags;
	}

	onChangeIngredients(ingredients){
		this.searchIngredients = ingredients == null ? [] : ingredients;
	}

	onSelectPossibleTag(id){
		let matchingTag = this.possibleTags.find((t) => t.id == id);
		if(matchingTag != null){
			this.onNewTag(matchingTag);
		}
	}

	onSelectPossibleIngredient(id){
		let matchingIngredient = this.possibleIngredients.find((t) => t.id == id);
		if(matchingIngredient != null){
			this.onNewIngredient(matchingIngredient);
		}
	}

	@action deleteEvidenceFile(id){
		let confirmMsg = this.appStore.i18n.t('superadmin.manage-evidence.confirm-delete-file-message');
		let confirmed = window.confirm(confirmMsg);
		if(confirmed){
			this.evidenceApi.deleteEvidenceFile(this.currentFileUpload.id)
				.then((response) => {
					this.resetFileUpload();
				})
				.catch((error) => {
					console.log(error);
				})
		}
	}


	clearUpload(){
		if(this.editMode && this.currentFileUpload?.id != null){
			this.deleteEvidenceFile(this.currentFileUploadUrl.id);
		}else{
			this.resetFileUpload();
		}
		
	}

	resetFileUpload(){
		this.currentFileUploadUrl = null;
		this.currentFileUpload = null;
		this.newFileToAttach = false;
	}
}

export default ManageEvidence;