import BaseApi from '../services/base';

class InvoiceApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(){
		return this.fetch(`v1/invoices`, {
			method: 'GET',
			auth: true
		})
	}
}

export default InvoiceApi;