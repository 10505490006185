import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';

import {inject, observer} from 'mobx-react';
import { SmallBody, Body, SecondaryAppLargeTitle, SecondaryAppMediumTitle, SecondaryAppSmallTitle, TertiaryAppSmallTitle,TertiaryAppExtraSmallTitle, ExtraSmallBody} from '../common/text';
import { ColorBookIcon, ArrowRightIcon, SearchIcon, FilterDialIcon, ListIcon, ArrowDownIcon, TileIcon } from '../common/icon'
import {TextInput} from '../common/input';
import {Button} from '../common/button';
import {ApplyText} from '../common/text';
import Main from '../elements/main';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormSelect,
	FormPasswordInput
} from '../elements/form';

import Loader from '../common/loader';
import {SortDialog, SortingField} from '../elements/list';
import {TagInput, Tag} from '../common/tag';

import {SearchResultsOverview, NoSearchResults} from './search-elements';

const colors = require('../../color.js');

@observer
class RefineSearch extends Component{
	render(){
		let translate = this.props.t;
		let tags = this.props.tags;
		let tagValue = this.props.tagValue;
		let possibleTags = this.props.possibleTags;
		return (
			<div className="flex flex-1 flex-col bg-white rounded-lg mt-8 px-8 py-8">
				<div className="flex">
					<SecondaryAppMediumTitle>{translate('user.advanced-search.refine-search.title')}</SecondaryAppMediumTitle>
				</div>
				<div className="grid grid-cols-3 lg:grid-cols-5 gap-8 mt-8">
					<div className="col-span-3 flex w-full flex-col">
						<div className="flex flex-row items-center">
							<div className="flex"><FilterDialIcon className="w-4 h-4" color={colors['nav-active-color']} /></div>
							<div className="flex ml-4"><SmallBody className="bold-font text-nav-active-color">{translate('user.advanced-search.refine-search.filter')}</SmallBody></div>
						</div>
						<div className="flex flex-row w-full items-center">
							<div className="flex w-full flex-col justify-start">
								<FormInput 
									placeholder={translate('user.advanced-search.search-input.placeholder')}
									value={this.props.query}
									onChange={(e) => this.props.onChangeQuery(e.target.value)}
								/>
							</div>
							<div className="relative flex w-full flex-col ml-4">
								<div className="flex w-full">
									<TagInput
										 inputValue={tagValue}
										 t={translate}
										 onNewEntry={(tag) => this.props.onNewTag(tag)}
										 onChange={(tags) => this.props.onChangeTags(tags)}
										 onInputChange={(tag) => this.props.onChangeTagInput(tag)}
										 values={tags} 
									/>
								</div>
								{possibleTags.length > 0 && 
									<div className="absolute cursor-pointer bg-white z-50 mt-12 top-0 left-0 flex flex-col w-full border border-nav-inactive-color mt-2 rounded-lg py-4 px-4">
										{possibleTags.map((pt) => 
											<div key={pt.id} className="flex py-1" onClick={(e) => {
												e.stopPropagation();
												this.props.onSelectPossibleTag(pt.id)
											}}>
												<SmallBody>{pt.name}</SmallBody>
											</div>
										)}
									</div>
								}
							</div>
							<div className="flex w-full flex-col ml-4 justify-start">
								<FormSelect 
									placeholder={translate('user.advanced-search.refine-search.choose-filter')}
									options={this.props.filterOptions}
									value={this.props.selectedFilter}
									onChange={(option) => this.props.onChangeFilter(option.value)}
								/>
							</div>
						</div>
						<div className="flex mt-1">
							<ApplyText 
								t={translate} 
								text={translate('user.advanced-search.refine-search.apply-filter')} 
								onClick={() => this.props.onTriggerSearch()}
							/>
						</div>
					</div>
					<div className="flex flex-col">
						<div className="flex">
							<SmallBody className="bold-font text-nav-active-color">{translate('user.advanced-search.refine-search.view')}</SmallBody>
						</div>
						<div className="flex flex-col lg:flex-row mt-3">
							<div className="flex flex-row items-center cursor-pointer" onClick={() => this.props.onTileMode()}>
								<TileIcon className="w-4 h-4" color={this.props.isTileView ? colors['black'] : colors['nav-inactive-color']} />
								<div className="flex ml-2"><SmallBody className={`text-${this.props.isTileView ? 'black' : 'nav-inactive-color'}`}>{translate('user.advanced-search.refine-search.tile-view')}</SmallBody></div>
							</div>
							<div className="flex flex-row items-center mt-4 lg:mt-0 ml-0 lg:ml-8 cursor-pointer" onClick={() => this.props.onListMode()}>
								<ListIcon className="w-4 h-4" color={this.props.isListView ? colors['black'] : colors['nav-inactive-color']} />
								<div className="flex ml-2"><SmallBody className={`text-${this.props.isListView ? 'black' : 'nav-inactive-color'}`}>{translate('user.advanced-search.refine-search.list-view')}</SmallBody></div>
							</div>
						</div>
					</div>
					<div className="flex flex-col items-start">
						<div className="relative flex w-full justify-end" onClick={() => this.props.onToggleSortDialog()}>
							<div className="flex flex-col items-end">
								<SortingField 
									text={translate("user.advanced-search.refine-search.sort")}
									extraClassNames="bold-font text-nav-active-color text-sm"
								/>
								{this.props.currentSortAttribute != null && 
									<div className="flex flex-row items-center text-center mt-2 cursor-pointer">
										<SmallBody>{this.props.currentSortAttribute}</SmallBody>
										<div className="flex ml-2"><ArrowDownIcon color={colors['nav-inactive-color']} className="w-2 h-2" /></div>
									</div>
								}
							</div>
							{this.props.showSortDialog && 
									<SortDialog
									 	t={translate}
										sortOptions={this.props.sortOptions}
										currentOption={this.props.sortBy}
										onOption={(val) => this.props.onChangeSortOption(val)}
										onApply={() => this.props.onApplySort()}
									/>
								}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

@observer
class SearchAttribute extends Component{
	render(){
		return (
			<div className="flex flex-col w-full mt-4">
				<div className="flex">
					<SmallBody className="bold-font text-nav-active-color">
						{this.props.attribute}
					</SmallBody>
				</div>
				<div className="flex mt-1">
					{this.props.result}
				</div>
			</div>
		)
	}
}

@observer
class SearchResult extends Component{
	render(){
		let translate = this.props.t;
		let searchResult = this.props.searchResult;
		let flexDirection = this.props.flexDirection;
		let spaceBetween = flexDirection == 'flex-row' ? 'justify-between' : 'items-between';
		// let titleHeight = flexDirection == 'flex-row' ? 'h-12' : 'h-16';
		let titleHeight = 'h-auto';
		return (
			<div className={`flex flex-col overflow-x-scroll w-full bg-white rounded-lg py-8 px-8 rounded-lg border-border-color border cursor-pointer`} onClick={() => this.props.onClickSearchResult(searchResult.uuid)}>
				<div className={`flex ${titleHeight} items-start flex-col border-b border-black overflow-y-scroll pb-4`}>
					<div className="flex"><SecondaryAppMediumTitle>{searchResult.name}</SecondaryAppMediumTitle></div>
					<div className="flex flex-col h-4">
						<SmallBody className="italic">{searchResult.subtitle != null ? searchResult.subtitle : ''}</SmallBody>
					</div>
				</div>
				<div className={`flex flex-1 w-full ${flexDirection} ${spaceBetween}`}>
					<SearchAttribute 
						attribute={translate('user.advanced-search.search-result.found-in')} 
						result={
							<SmallBody>{searchResult.foundIn}</SmallBody>
						}
					/>
					<SearchAttribute 
						attribute={translate('user.advanced-search.search-result.tags')} 
						result={
							<div className="flex flex-row">
								{searchResult.tags.map((tag, idx) => 
									<Tag key={idx} name={tag} />
								)}
							</div>
						}
					/>
				</div>
			</div>	
		)
	}
}

@inject('advancedSearchStore')
@observer
class AdvancedSearch extends Component{
	constructor(props){
		super(props);
		this.handleQueryKeyDown = this.handleQueryKeyDown.bind(this);
		this.onSearch = this.onSearch.bind(this);
	}
	
	componentDidMount(){
		let advancedSearchStore = this.props.advancedSearchStore;
		if('query' in this.props && this.props.query != null){
			advancedSearchStore.searchWithQuery(this.props.query);
		}
	}

	componentWillUnmount(){
		let advancedSearchStore = this.props.advancedSearchStore;
		advancedSearchStore.initStore();
	}

	handleQueryKeyDown(event){
	    let advancedSearchStore = this.props.advancedSearchStore;
	    switch (event.key) {
	      case 'Enter':
	      case 'Tab':
	        advancedSearchStore.triggerSearch();
	        event.preventDefault();
	    }
  	}

  	onSearch(){
  		let advancedSearchStore = this.props.advancedSearchStore;
  		if(advancedSearchStore.query?.length > 0){
  			 advancedSearchStore.triggerSearch();
  		}
  	}

	render(){
		let translate = this.props.t;
		let advancedSearchStore = this.props.advancedSearchStore;


		let resultsContainerClassName = "grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8"
		if(advancedSearchStore.isListView){
			resultsContainerClassName = "grid grid-cols-1 gap-8 mt-8"
		}

		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.advanced-search.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.advanced-search.paragraph.part1')} <br /> {translate('user.advanced-search.paragraph.part2')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8">
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.advanced-search.video-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex hidden lg:block ml-8">
							<ColorBookIcon className="w-48 h-48" />
						</div>
					</div>
					{!advancedSearchStore.showRefinedSearch && !advancedSearchStore.searching &&
						<div className="flex flex-row items-center w-full mt-4">
							<div className="hidden lg:flex flex items-center justify-center bg-nav-active-color h-16 rounded-l-lg w-48 cursor-pointer" onClick={this.onSearch}>
								<SmallBody className="text-white">{translate('user.advanced-search.search-input.label')}</SmallBody>
							</div>
							<TextInput 
								value={advancedSearchStore.query}
								onKeyDown={this.handleQueryKeyDown}
								onChange={(e) => advancedSearchStore.changeSearchQuery(e.target.value)}
								insetoption={
									<div onClick={this.onSearch}>
										<SearchIcon className="w-4 h-4 mb-1" />
									</div>} 
								placeholder={translate('user.advanced-search.search-input.placeholder')}
								extraInputClassNames="pl-8 border-0 h-16 w-full rounded-r-lg text-nav-inactive-color placeholder-nav-inactive-color"
							/>
						</div>	
					}
					{advancedSearchStore.searching && 
						<div className="flex flex-row items-center w-full mt-4">
							<div className="flex items-center justify-center">
								<Body className="text-lg">{translate('user.advanced-search.searching')} {`'${advancedSearchStore.currentSearchQuery}'`}</Body>
							</div>
						</div>
					}
					{advancedSearchStore.showRefinedSearch && !advancedSearchStore.searching &&
						<RefineSearch 
							t={translate} 
							query={advancedSearchStore.query}
							onChangeQuery={(val) => advancedSearchStore.changeSearchQuery(val)}
							tags={advancedSearchStore.currentSearchTags}
							tagValue={advancedSearchStore.currentTagInput}
							onNewTag={(tag) => console.log(tag)}
							onChangeTags={(tags) => advancedSearchStore.onChangeTags(tags)}
							onChangeTagInput={(tag) => advancedSearchStore.onChangeTagInput(tag)}
							possibleTags={advancedSearchStore.possibleTags}
							onSelectPossibleTag={(id) => advancedSearchStore.onSelectPossibleTag(id)}
							onTileMode={() => advancedSearchStore.changeToTileViewMode()}
							onListMode={() => advancedSearchStore.changeToListViewMode()}
							isTileView={advancedSearchStore.isTileView}
							isListView={advancedSearchStore.isListView}
							filterOptions={advancedSearchStore.filterOptions}
							selectedFilter={advancedSearchStore.selectedFilter}
							onChangeFilter={(val) => advancedSearchStore.onChangeFilter(val)}
							onToggleSortDialog={() => advancedSearchStore.onToggleSortDialog()}
							showSortDialog={advancedSearchStore.showSortDialog}
							sortBy={advancedSearchStore.sortBy}
							onChangeSortOption={(val) => advancedSearchStore.onChangeSortOption(val)}
							onApplySort={() => advancedSearchStore.onApplySort()}
							sortOptions={advancedSearchStore.sortOptions}	
							currentSortAttribute={advancedSearchStore.currentSortAttribute}	
							onTriggerSearch={() => advancedSearchStore.triggerSearch()}						
						/>
					}
					
					{advancedSearchStore.showSearchResults && 
						<>	
							{advancedSearchStore.noOfResults == 0 && 
								<NoSearchResults 
									onNewSearch={() => advancedSearchStore.resetSearch()} 
									t={translate}
									text={translate('user.advanced-search.new-advanced-search')}
								/>
							}
							{advancedSearchStore.noOfResults > 0 && <SearchResultsOverview t={translate} query={advancedSearchStore.currentSearchQuery} noOfResults={advancedSearchStore.noOfResults} />}
							<InfiniteScroll
							  dataLength={advancedSearchStore.searchResults.length}
							  next={() => advancedSearchStore.nextSearchResultsPage()}
							  hasMore={advancedSearchStore.hasMoreSearchResults}
							  loader={<Loader t={translate} />}
							  endMessage={null}
							>
								<div className={resultsContainerClassName}>
									{advancedSearchStore.searchResults.map((searchResult, idx) => 
										<SearchResult 
											key={idx} 
											onClickSearchResult={(uuid) => advancedSearchStore.onClickSearchResult(uuid)}
											searchResult={searchResult} 
											flexDirection={advancedSearchStore.isListView ? "flex-row" : "flex-col"}
											t={translate} />
									)}
								</div>
							</InfiniteScroll>
						</>
					}
				</div>
			</Main>
		)
	}
}

export default withTranslation()(AdvancedSearch);