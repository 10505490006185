import React, { Component } from 'react';

import {inject, observer} from 'mobx-react';

import BlendLibrary from './blend-library';

@inject('blendsLibraryStore')
@observer
class BlendLibraryCategory extends Component{
	componentDidMount(){
		let blendsLibraryStore = this.props.blendsLibraryStore;
		blendsLibraryStore.onLoad(false, this.props.libraryCategoryId);
	}

	componentWillUnmount(){
		let blendsLibraryStore = this.props.blendsLibraryStore;
		blendsLibraryStore.initStore();
	}

	componentWillUpdate(nextProps, nextState) {
	  if(nextProps.libraryCategoryId != null && this.props.libraryCategoryId != null){
	  	if(nextProps.libraryCategoryId != this.props.libraryCategoryId){
			let blendsLibraryStore = this.props.blendsLibraryStore;
	  		blendsLibraryStore.initStore();
	  		blendsLibraryStore.onLoad(false, nextProps.libraryCategoryId);
	  	}
	  }
	}
	
	render(){
		return <BlendLibrary 
			blendsLibraryStore={this.props.blendsLibraryStore} 
		/>
	}
}

export default BlendLibraryCategory;