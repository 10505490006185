import { observable, action, computed } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isRequiredValidator, isNotNullValidator, isPositiveNumberValidator, isHexColorValidator } from '../../../services/validation';

import CommonApi from '../../../services/common';
import RegulationApi from '../../../services/regulation';
import ChemistryApi from '../../../services/chemistry';

import uuidv4 from 'uuid/v4';

class ManageRegulation{
	appStore;

	id;

	@observable currentRegionId;
	@observable editMode;

	@observable regions;
	@observable regulations;
	@observable chemicalComponents;

	@observable currentChemicalComponentPage;

	@observable editRegulations;
	@observable fetching;

	@observable existingChemicalComponents;

	commonApi;
	regulationApi;
	chemistryApi;


	constructor(appStore){
		this.appStore = appStore
		this.commonApi = new CommonApi(this.appStore);
		this.regulationApi = new RegulationApi(this.appStore);
		this.chemistryApi = new ChemistryApi(this.appStore);
		this.initStore();
	}

	initStore(){
		this.editMode = false;
		this.regions = [];
		this.regulations = [];
		this.editRegulations = [];
		this.currentRegionId = null;
		this.chemicalComponents = [];
		this.fetching = false;
		this.currentChemicalComponentPage = 1;
		this.existingChemicalComponents = [];
	}

	fetchRegions(){
		this.commonApi.getRegions()
			.then((response) => {
				this.regions = response.regions;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchChemicalComponents(searchText){
		return this.chemistryApi.getAllChemicalComponents(this.currentChemicalComponentPage, searchText);
	}

	@action onChangeComponentSearchText(regulationId, val){
		let regulationIdx = this.editRegulations.findIndex((r) => r.uuid == regulationId);
		if(regulationIdx != -1){
			this.editRegulations[regulationIdx].componentSearchText = val;
			this.editRegulations[regulationIdx].chemical_component_id.value = null;
			this.fetchChemicalComponents(val)
				.then((response) => {
					this.editRegulations[regulationIdx].chemicalComponents = this.editRegulations[regulationIdx].chemicalComponents.concat(response.chemical_components);
				})
				.catch((error) => {
					console.log(error);
				})
		}
		
	}

	@action onClearComponentSelection(regulationId){
		let regulationIdx = this.editRegulations.findIndex((r) => r.uuid == regulationId);
		if(regulationIdx != -1){
			this.editRegulations[regulationIdx].componentSearchText = '';
			this.editRegulations[regulationIdx].chemical_component_id.value = null;
		}
		
	}

	@action onChooseComponent(regulationId, chemicalComponentId){
		let regulationIdx = this.editRegulations.findIndex((r) => r.uuid == regulationId);
		if(regulationIdx != -1){
			let chemicalComponent = this.editRegulations[regulationIdx].chemicalComponents.find((c) => c.id == chemicalComponentId);
			if(chemicalComponent != null){
				this.editRegulations[regulationIdx].chemical_component_id.value = chemicalComponentId;
				this.editRegulations[regulationIdx].componentSearchText = chemicalComponent.name
			}
		}
	}


	@computed get currentRegion(){
		return this.regions.find((r) => r.id == this.currentRegionId);
	}

	@action generateRegulation(id=null, region_id=null, chemical_component_id=null, regulation_limit=null, regulation_information=null, currentChemicalComponent=null){
		return observable.object({
			id: new FieldState(id),
			uuid: uuidv4(),
			region_id: new FieldState(region_id).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			chemical_component_id: new FieldState(chemical_component_id).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			regulation_limit: new FieldState(regulation_limit).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n)),
			regulation_information: new FieldState(regulation_information).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			// ui state variables
			componentSearchText: currentChemicalComponent?.name,
			chemicalComponents: currentChemicalComponent == null ? [] : [currentChemicalComponent],
			get searchComponentOptions(){
				if(this.componentSearchText == null || this.componentSearchText == '') return [];
				return this.chemicalComponents.filter((c) => c.name.toLowerCase().includes(this.componentSearchText)).map((c) => {
					return {
						value: c.id,
						label: c.name
					}
				}).sort((a, b) => a.name > b.name ? 1 : -1);
			}
		})
	}

	@action toggleEditMode(){
		this.editMode = true;
		this.editRegulations = this.regulations.map((r) => {
			return this.generateRegulation(r.id, r.region.id, r.chemical_component.id, r.regulation_limit, r.regulation_information, r.chemical_component)
		});
		this.existingChemicalComponents = this.regulations.map((r) => r.chemical_component);
	}

	formSync(regionId){
		this.currentRegionId = regionId;
		this.regulationApi.getRegulationsForRegion(regionId)
			.then((response) => {
				this.regulations = response.regulations;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	addNewRegulation(){
		if(!this.editMode){
			this.toggleEditMode();
		}
		this.editRegulations.push(this.generateRegulation());
	}

	cancelEditMode(){
		this.editMode = false;
	}

	save = async (uuid) => {
		let regulation = this.editRegulations.find((r) => r.uuid == uuid);
		if(regulation == null) return;

		if(this.currentRegionId == null) return;

		let regulationId = regulation.id.value;
		let newRegulation = regulationId == null;
		
		let payload = {
			region_id: this.currentRegionId,
			chemical_component_id: regulation.chemical_component_id.value,
			regulation_limit: parseFloat(regulation.regulation_limit.value, 2),
			regulation_information: regulation.regulation_information.value
		}
		if(newRegulation){
			this.fetching = true;
			this.regulationApi.newRegulation(payload)
				.then((response) => {
					let newRegulation = response.regulation;
					this.regulations.push(newRegulation);
					this.editMode = false;
					this.appStore.displaySaveDialog();

				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}else{
			this.fetching = true;

			this.regulationApi.updateRegulation(regulationId, payload)
				.then((response) => {
					let updatedRegulation = response.regulation;
					let existingRegulationIdx = this.regulations.findIndex((r) => regulationId);
					if(existingRegulationIdx != -1){
						this.regulations[existingRegulationIdx] = updatedRegulation;
					}
					this.editMode = false;
					this.appStore.displaySaveDialog();

				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}
}

export default ManageRegulation;