import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {Body, SmallBody, SecondaryAppLargeTitle, SecondaryAppMediumTitle, SecondaryAppSmallTitle, ExtraSmallBody, TertiaryAppSmallTitle} from '../common/text'
import {ArrowRightIcon, DownloadIcon, DrawPinkIcon, RaisedHandIcon} from '../common/icon'
import Main from '../elements/main';
import {FullGrid} from '../elements/grid';
import { withAuthorisation } from '../common/authorisation';
import { DownloadPDF, pdfStyles } from '../common/pdf';
import CampaignBreakdown from '../common/campaign-breakdown';
import {MetricChartView} from '../common/chart';
import { ORGANISATION_ADMINISTRATOR } from '../../services/util';

import {StatBox, StatOverview} from '../elements/stat';
import {MarketingAssets, AffiliateLinks} from '../marketing';
import logoPinkSrc from '../../assets/img/logo.png';

import { Page, Text, Image, View, Document, StyleSheet, Font, PDFDownloadLink } from '@react-pdf/renderer';
import * as htmlToImage from 'html-to-image';

const colors = require('../../color.js');

const SalesOverviewPDF = ({logoSrc, dateRange, estimatedCommissionSalesTitle, estimatedCommissionSales, 
		estimatedLabAromaCommissionTitle, estimatedLabAromaCommission, 
		estimatedAffiliateCampaignCommissionTitle, estimatedAffiliateCampaignCommission, 
		commissionRevenueIncreaseTitle, commissionRevenueIncrease }) => (
	<Document creator="LabAroma" producer="LabAroma">
	    <Page orientation="landscape" size="A4" style={pdfStyles.page} wrap={false}>
	      	<View style={pdfStyles.container}>
	      		<View style={pdfStyles.rowBetween}>
	      			<Image src={logoSrc} style={pdfStyles.logo} />
	      			<Text style={[pdfStyles.date]}>{dateRange}</Text>
	      		</View>
	      		<View style={pdfStyles.row}>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{estimatedCommissionSalesTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{estimatedCommissionSales}</Text></View>
		      		</View>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{estimatedLabAromaCommissionTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{estimatedLabAromaCommission}</Text></View>
		      		</View>
		      	</View>
		      	<View style={pdfStyles.row}>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{estimatedAffiliateCampaignCommissionTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{estimatedAffiliateCampaignCommission}</Text></View>
		      		</View>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{commissionRevenueIncreaseTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{commissionRevenueIncrease}</Text></View>
		      		</View>
		      	</View>
	      	</View>
	    </Page>
	</Document>
)

const UsersOverviewPDF = ({logoSrc, dateRange, activeUsersTitle, activeUsers, usersChangeTitle, usersChange, newUsersTitle, newUsers}) => (
	<Document creator="LabAroma" producer="LabAroma">
	    <Page orientation="landscape" size="A4" style={pdfStyles.page} wrap={false}>
	      	<View style={pdfStyles.container}>
	      		<View style={pdfStyles.rowBetween}>
	      			<Image src={logoSrc} style={pdfStyles.logo} />
	      			<Text style={[pdfStyles.date]}>{dateRange}</Text>
	      		</View>
	      		<View style={pdfStyles.row}>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{activeUsersTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{activeUsers}</Text></View>
		      		</View>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{usersChangeTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{usersChange}</Text></View>
		      		</View>
		      		<View style={pdfStyles.gridColumn}>
		      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{newUsersTitle}</Text>
		      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{newUsers}</Text></View>
		      		</View>
		      	</View>
	      	</View>
	    </Page>
	</Document>
)



@observer
class SalesOverview extends Component{
	render(){
		let translate = this.props.t;
		let salesStats = this.props.salesStats;
		let activeColor = "pink-coral";
		let defaultColor = this.props.showStatDialog == null ? activeColor : 'black-30';
		let showingDailyCommissions = this.props.showStatDialog == 'daily_commissions';
		let showingLabaromaCommissions = this.props.showStatDialog == 'labaroma_commissions';
		let showingCampaignCommissions = this.props.showStatDialog == 'campaign_commissions';
		return (
			<StatOverview
				t={translate}
				startDate={this.props.salesStartDate}
				endDate={this.props.salesEndDate}
				tempStartDate={this.props.tempSalesStartDate}
				tempEndDate={this.props.tempSalesEndDate}
				onDateChange={this.props.onDateChange}
				onApply={this.props.onApply}
				title={translate('org-admin.home.sales-overview.title')}
				description={translate('org-admin.home.sales-overview.description')}
				downloadPDF={
					<DownloadPDF 
						t={translate}
						document={
							<SalesOverviewPDF 
								logoSrc={logoPinkSrc}
								dateRange={`${this.props.salesStartDate.format('ll')} - ${this.props.salesEndDate.format('ll')}`}
								estimatedCommissionSalesTitle={translate('org-admin.home.sales-stats.estimated-commission-sales')}
								estimatedCommissionSales={salesStats.total_commission_sales}
								estimatedLabAromaCommissionTitle={translate('org-admin.home.sales-stats.estimated-labaroma-comission')}
								estimatedLabAromaCommission={salesStats.total_labaroma_commission}
								estimatedAffiliateCampaignCommissionTitle={translate('org-admin.home.sales-stats.estimated-labaroma-comission')}
								estimatedAffiliateCampaignCommission={salesStats.total_campaign_commission}
								commissionRevenueIncreaseTitle={translate('org-admin.home.sales-stats.commission-revenue-increase')}
								commissionRevenueIncrease={`${salesStats.commission_revenue_increase}%`}
							/>
						}
						filename="Sales.pdf"
						onDownload={() => console.log('download')}
					/>
				}
				>
					<StatBox
					 title={translate('org-admin.home.sales-stats.estimated-commission-sales')}
					 value={`£${salesStats.total_commission_sales}`}
					 color={!showingDailyCommissions ? defaultColor : activeColor} 
					 expandable={true}
					 onExpand={() =>console.log('todo')}
					 expanded={showingDailyCommissions}
					 onOpen={this.props.onToggleDailyCommissions}
					 onClose={this.props.onToggleDailyCommissions}
					 />
					 
					<StatBox
					 title={translate('org-admin.home.sales-stats.estimated-labaroma-comission')}
					 value={`£${salesStats.total_labaroma_commission}`}
					 color={!showingLabaromaCommissions ? defaultColor : activeColor} 
					 expandable={true}
					 onOpen={this.props.onToggleLabAromaCommissions}
					 onClose={this.props.onToggleLabAromaCommissions}
					 onExpand={() => console.log('todo')}
					 />
					 
					<StatBox
					 title={translate('org-admin.home.sales-stats.estimated-campaign-commission')}
					 value={`£${salesStats.total_campaign_commission}`}
					 color={!showingCampaignCommissions ? defaultColor : activeColor} 
					 expandable={true}
					 onExpand={() => console.log('todo')}
					 onOpen={this.props.onToggleCampaignCommissions}
					 onClose={this.props.onToggleCampaignCommissions}
					 />

					 <StatBox
					 title={translate('org-admin.home.sales-stats.commission-revenue-increase')}
					 value={`${salesStats.commission_revenue_increase}%`}
					 color={defaultColor}
					 onExpand={() => console.log('todo')}
					 />	

					 {this.props.showStatDialog != null &&
					 	<div className='col-span-4 overflow-x-scroll flex flex-1 bg-white mt-4'>
						 	{this.props.showStatDialog == 'daily_commissions' && 
						 		<div className="flex flex-1 flex-col">
									<div className="flex flex-1 mt-4">
										<div className="flex flex-1 flex-col">
											<div className="flex flex-col">
												<SecondaryAppMediumTitle>{translate('org-admin.home.sales-stats.estimated-commission-sales')}</SecondaryAppMediumTitle>
												<SmallBody className="text-list-text">{this.props.queryDates}</SmallBody>
											</div>
											<div className="flex flex-1 flex-col">
												 <MetricChartView 
												 	data={salesStats.daily_commission_sales}
												  />
											</div>
										</div>
									</div>
									<div className="flex items-center justify-center mt-8 cursor-pointer" onClick={this.props.onToggleDailyCommissions}>
										<SmallBody className="text-list-text bold-font underline" >{translate('common.close')}</SmallBody>
									</div>
								</div>	
						 	}
						 	{this.props.showStatDialog == 'labaroma_commissions' && 
						 		<div className="flex flex-1 flex-row w-full overflow-x-scroll">
						 			{salesStats.labaroma_commission_sales.map((campaign_commission_sale, idx) => 
							 			<CampaignBreakdown 
							 				key={idx}
							 				t={translate}
							 				campaign={campaign_commission_sale}
							 				bgColor={activeColor} />
						 			)}
							 	</div>
						 	}
						 	{this.props.showStatDialog == 'campaign_commissions' && 
						 		<>
						 			{salesStats.campaign_commission_sales.map((campaign_commission_sale, idx) => 
							 			<CampaignBreakdown 
							 				key={idx}
							 				t={translate}
							 				campaign={campaign_commission_sale}
							 				bgColor={activeColor} />
						 			)}
							 	</>
						 	}
						</div>
					 }	
			</StatOverview>
		)
	}
}

@observer
class YourTribe extends Component{
	render(){
		let translate = this.props.t;
		let userStats = this.props.userStats;
		return (
			<FullGrid>
				<div className="flex flex-1 flex-col">
					<div className="bg-help-color-20 px-4 py-8 rounded-xl flex flex-1 w-full items-center flex-col">
						<div className="flex w-full text-center items-center justify-center">
							<SecondaryAppMediumTitle className="text-nav-active-color">{translate('org-admin.home.sales-update.title')}</SecondaryAppMediumTitle>
						</div>
						<div className="flex flex-col text-center items-center mt-4">
							{userStats.new_subscribers > 0 && <div className="flex"><Body><span className="bold-font">{translate('org-admin.home.sales-update.part1')}</span></Body></div>}
							<div className="flex"><Body>{translate('org-admin.home.sales-update.part2')}</Body></div>
							<div className="flex mt-2"><SecondaryAppMediumTitle>{userStats.new_subscribers}</SecondaryAppMediumTitle></div>
							<div className="flex mt-4"><Body>{translate('org-admin.home.sales-update.part3')} <br /> {translate('org-admin.home.sales-update.part4')} <br /> {translate('org-admin.home.sales-update.part5')}</Body></div>
						</div>
					</div>
					<div className="flex justify-center mt-4">
						<RaisedHandIcon className="w-48 h-48" />
					</div>
				</div>
			</FullGrid>
		)
	}
}

@observer
class UsersOverview extends Component{
	render(){
		let userStats = this.props.userStats;
		let translate = this.props.t;
		let ochreYellow = 'ochre-yellow';
		return (
			<StatOverview
				t={translate}
				startDate={this.props.usersStartDate}
				endDate={this.props.usersEndDate}
				tempStartDate={this.props.tempUsersStartDate}
				tempEndDate={this.props.tempUsersEndDate}
				onDateChange={this.props.onDateChange}
				onApply={this.props.onApply}
				title={translate('org-admin.home.users-overview.title')}
				description={translate('org-admin.home.users-overview.description')}
				downloadPDF={
					<DownloadPDF 
						t={translate}
						document={
							<UsersOverviewPDF 
								logoSrc={logoPinkSrc} 
								dateRange={`${this.props.usersStartDate.format('ll')} - ${this.props.usersEndDate.format('ll')}`}
								activeUsersTitle={translate('org-admin.home.user-stats.active-users')}
								activeUsers={userStats.active_users}
								usersChangeTitle={translate('org-admin.home.user-stats.user-change')}
								usersChange={`${userStats.user_percentage_change}%`}
								newUsersTitle={translate('org-admin.home.user-stats.new-users')}
								newUsers={userStats.new_users}
							/>
						}
						filename="Users.pdf"
						onDownload={() => console.log('download')}
					/>
				}
				>
					<StatBox
					 title={translate('org-admin.home.user-stats.active-users')}
					 value={userStats.active_users}
					 color="stat-purple" 
					 />
					<StatBox
					 title={translate('org-admin.home.user-stats.user-change')}
					 value={`${userStats.user_percentage_change}%`}
					 color="stat-purple" 
					 />
					<StatBox
					 title={translate('org-admin.home.user-stats.new-users')}
					 value={userStats.new_users}
					 color="stat-purple" 
					 />
			</StatOverview>
		)
	}
}


@inject('appStore', 'adminHomeStore')
@observer
class OrganisationAdministratorHome extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let adminHomeStore = this.props.adminHomeStore;
		adminHomeStore.fetchUserStats();
		adminHomeStore.fetchSalesStats();
		adminHomeStore.fetchUserAffiliateLinks();
		adminHomeStore.fetchLatestMarketingAssets();
	}

	componentWillUnmount(){
		let adminHomeStore = this.props.adminHomeStore;
		adminHomeStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let appStore = this.props.appStore;
		let currentUser = appStore.currentUser;
		if(currentUser == null) return null;
		let adminHomeStore = this.props.adminHomeStore;
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center">
						<div className="flex flex-1 flex-col">
							<SmallBody>{translate('org-admin.home.intro')} {currentUser.name},</SmallBody>
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('org-admin.home.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('org-admin.home.paragraph.part1')} <br /> {translate('org-admin.home.paragraph.part2')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8">
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('org-admin.home.dashboard-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex ml-4">
							<DrawPinkIcon className="w-64 h-48" />
						</div>
					</div>
					<div className="flex flex-1 flex-col lg:flex-row mt-8">
						<div className="flex flex-1 flex-row">
							<div className="flex flex-1 flex-col">
								<SalesOverview 	
									queryDates={adminHomeStore.salesQueryDates}
									salesStats={adminHomeStore.salesStats}
									salesStartDate={adminHomeStore.salesStartDate}
									salesEndDate={adminHomeStore.salesEndDate}
									tempSalesStartDate={adminHomeStore.tempSalesStartDate}
									tempSalesEndDate={adminHomeStore.tempSalesEndDate}
									onDateChange={({startDate, endDate}) => adminHomeStore.changeSalesStartEndDate(startDate, endDate)}
									onApply={() => adminHomeStore.applySalesDateChange()}
									onToggleDailyCommissions={() => adminHomeStore.onToggleDailyCommissions()}
									onToggleLabAromaCommissions={() => adminHomeStore.onToggleLabAromaCommissions()}
									onToggleCampaignCommissions={() => adminHomeStore.onToggleCampaignCommissions()}
									showStatDialog={adminHomeStore.showStatDialog}
									t={translate}
								/>
								<div className="mt-8"></div>
								<UsersOverview 
									userStats={adminHomeStore.userStats} 
									usersStartDate={adminHomeStore.usersStartDate}
									usersEndDate={adminHomeStore.usersEndDate}
									tempUsersStartDate={adminHomeStore.tempUsersStartDate}
									tempUsersEndDate={adminHomeStore.tempUsersEndDate}
									onDateChange={({startDate, endDate}) => adminHomeStore.changeUsersStartEndDate(startDate, endDate)}
									onApply={() => adminHomeStore.applyUsersDateChange()}
									t={translate} 
								/>
							</div>
							<div className="flex flex-col w-1/4 lg:ml-8">
								<YourTribe 
									userStats={adminHomeStore.userStats}
									t={translate} />
							</div>
						</div>
						
					</div>
					<div className="grid grid-cols-2 gap-4 mt-8 flex flex-1">
						<div className="flex flex-1">
							<MarketingAssets 
								t={translate} 
								onMarketingAssets={() => adminHomeStore.goToMarketingAssetSearch()}
								marketingAssets={adminHomeStore.marketingAssets}
							/>
						</div>
						<div className="flex flex-1 ml-8">
							<AffiliateLinks 
								t={translate}
								affiliateLinks={adminHomeStore.affiliateLinks}
							/>
						</div>
					</div>
					
				</div>
			</Main>
		)
	}
}


export default withTranslation()(withAuthorisation(OrganisationAdministratorHome, ORGANISATION_ADMINISTRATOR));