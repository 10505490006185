import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {Body, SecondaryAppMediumTitle, TertiaryAppSmallTitle} from '../../common/text'
import {ToolTip} from '../../common/input'
import { withAuthorisation } from '../../common/authorisation';

import {Button} from '../../common/button';
import {PlusIcon, EditIcon, ArchiveIcon, TickIcon} from '../../common/icon';
import Main from '../../elements/main';
import {FullGrid} from '../../elements/grid';
import {List, Status, TableCell, TableRow, TableHeader, SortDialog, SortingField} from '../../elements/list';
import { SUPER_ADMINISTRATOR } from '../../../services/util';


@inject('organisationsStore')
@observer
class SuperAdministratorOrganisations extends Component{
	constructor(props){
		super(props);
		this.onPreviousPage = this.onPreviousPage.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPage = this.onPage.bind(this);
		this.renderListHeaders = this.renderListHeaders.bind(this);
		this.renderOrganisationListItem = this.renderOrganisationListItem.bind(this);
		this.toggleSortDialog = this.toggleSortDialog.bind(this);
		this.onOption = this.onOption.bind(this);
		this.onApplySort = this.onApplySort.bind(this);
	}

	componentDidMount(){
		let organisationsStore = this.props.organisationsStore;
		organisationsStore.fetchOrganisations();
	}

	componentWillUnmount(){
		let organisationsStore = this.props.organisationsStore;
		organisationsStore.initStore();
	}

	renderListHeaders(){
		let translate = this.props.t;
		return (
			<tr>
				<TableHeader>{translate("superadmin.organisations.headers.id")}</TableHeader>
				<TableHeader>{translate("superadmin.organisations.headers.name")}</TableHeader>
				<TableHeader>{translate("superadmin.organisations.headers.status")}</TableHeader>
				<TableHeader className="text-center">{translate("superadmin.organisations.headers.actions")}</TableHeader>
			</tr>
		)

	}

	renderOrganisationListItem(organisation){
		let translate = this.props.t;
		let organisationsStore = this.props.organisationsStore;

		return (
			<TableRow key={organisation.id}>
				<TableCell><Body className="text-black">{organisation.id}</Body></TableCell>
				<TableCell><Body className="text-black">{organisation.name}</Body></TableCell>
				<TableCell>
					<Status 
						color={organisation.archived ? 'list-text' : 'flat-green'} 
						bgColor={organisation.archived ? 'black-10' : 'active-status' } 
						text={organisation.archived ? translate("superadmin.organisations.list.archived") : translate("superadmin.organisations.list.active")} 
					/>
				</TableCell>
				<TableCell>
					<div className="flex flex-row justify-around">
						<div className="inline-flex relative" 
							onMouseEnter={(e) => organisationsStore.setEditTooltip(organisation.id)}
							onMouseLeave={(e) => organisationsStore.setEditTooltip(null)}
							onClick={() => organisationsStore.onEditOrganisation(organisation.id)}>
							<>
								<EditIcon color='black' className="w-4 h-4" />
								{organisationsStore.showEditTooltip == organisation.id && 
									<ToolTip
										title={translate("superadmin.organisations.list.edit-tooltip.title")}
										text={translate("superadmin.organisations.list.edit-tooltip.text")} 
									/>
								}
							</>
						</div>

						{!organisation.archived && 
							<div className="inline-flex relative"
								onMouseEnter={(e) => organisationsStore.setArchiveTooltip(organisation.id)}
								onMouseLeave={(e) => organisationsStore.setArchiveTooltip(null)}
								onClick={() => organisationsStore.onPromptArchive(organisation.id)}>
								<>
									<ArchiveIcon color='black' className="w-4 h-4" />
									{organisationsStore.showArchiveTooltip == organisation.id && 
										<ToolTip
											title={translate("superadmin.organisations.list.archive-tooltip.title")}
											text={translate("superadmin.organisations.list.archive-tooltip.text")} 
										/>
									}
								</>
							</div>
						}
						{organisation.archived && 
							<div className="inline-flex relative"
								onMouseEnter={(e) => organisationsStore.setArchiveTooltip(organisation.id)}
								onMouseLeave={(e) => organisationsStore.setArchiveTooltip(null)}
								onClick={() => organisationsStore.onPromptRestore(organisation.id)}
								>
								<>
									<TickIcon color='black' className="w-4 h-4" />
									{organisationsStore.showArchiveTooltip == organisation.id && 
										<ToolTip
											title={translate("superadmin.organisations.list.restore-tooltip.title")}
											text={translate("superadmin.organisations.list.restore-tooltip.text")} 
										/>
									}
								</>
							</div>
						}
					</div>
				</TableCell>
			</TableRow>
		);
	}

	onPreviousPage(){
		let organisationsStore = this.props.organisationsStore;
		organisationsStore.onPreviousPage();
	}

	onNextPage(){
		let organisationsStore = this.props.organisationsStore;
		organisationsStore.onNextPage();
	}

	onPage(page){
		let organisationsStore = this.props.organisationsStore;
		organisationsStore.onPage(page);
	}

	toggleSortDialog(){
		let organisationsStore = this.props.organisationsStore;
		organisationsStore.toggleSortDialog();
	}

	onOption(val){
		let organisationsStore = this.props.organisationsStore;
		organisationsStore.setSortByField(val);
	}

	onApplySort(){
		let organisationsStore = this.props.organisationsStore;
		organisationsStore.applySort();
	}

	render(){
		let translate = this.props.t;
		let organisationsStore = this.props.organisationsStore;
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full justify-end mt-8">
						<Button
							width="w-auto"
							height="h-12"
							className="pl-4 rounded-lg"
							bgColor="pink-coral"
						>
							<div className="flex flex-row items-center justify-center" onClick={() => organisationsStore.goToInviteNewOrganisation()}>
								<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.organisations.index.create-button')} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><PlusIcon /></div>
							</div>
						</Button>
					</div>
					<FullGrid className="mt-8">
						<div className="flex flex-row justify-between">
							<SecondaryAppMediumTitle>{translate('superadmin.organisations.index.title')}</SecondaryAppMediumTitle>

							<div className="relative flex flex-row items-center" onClick={this.toggleSortDialog}>
								<SortingField text={translate("superadmin.organisations.index.sort")} />
								{organisationsStore.showSortDialog && 
									<SortDialog
									 	t={translate}
										sortOptions={organisationsStore.sortOptions}
										currentOption={organisationsStore.sortBy}
										onOption={this.onOption}
										onApply={this.onApplySort}
									/>
								}
							</div>
							
						</div>
						<div className="mt-8"></div>
						<List 
							fetching={organisationsStore.fetching}
						 	renderListHeaders={this.renderListHeaders}
							type={translate('superadmin.organisations.listname')}
							items={organisationsStore.organisations}
							renderListItem={this.renderOrganisationListItem}
							page={organisationsStore.page}
							pages={organisationsStore.pages}
							hasNext={organisationsStore.hasNext}
							hasPrev={organisationsStore.hasPrev}
							onPreviousPage={this.onPreviousPage}
							onNextPage={this.onNextPage}
							onPage={this.onPage}
						/>
					</FullGrid>
				</div>
			</Main>
		)
	}
}
export default withTranslation()(withAuthorisation(SuperAdministratorOrganisations, SUPER_ADMINISTRATOR));