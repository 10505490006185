import BaseApi from '../services/base';

class RecaptchaApi extends BaseApi{
	constructor(props){
		super(props);
	}

	verify(response){
		return this.fetch('v1/recaptcha/verify', {
			method: 'POST',
			silent: true,
			body: JSON.stringify({
				response: response
			})
		})

	}
}

export default RecaptchaApi;