import BaseApi from '../services/base';

class RoleApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getRoles(){
		return this.fetch(`v1/roles`, {
			method: 'GET'
		})
	}
}

export default RoleApi;