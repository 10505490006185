import BaseApi from '../services/base';

class UserApi extends BaseApi{
	constructor(props){
		super(props);
	}

	signUp(user){
		return this.fetch(`v1/users`, {
			method: 'POST',
			body: JSON.stringify(user)
		})
	}

	login(email, password){
		return this.fetch(`v1/token`, {
			method: 'POST',
			body: JSON.stringify({
				email: email,
				password: password
			})
		})
	}

	getAll(page, sortBy=null, archived=false, email=null){
		let queryUrl = `v1/users?page=${page}`
		if(sortBy != null){
			queryUrl = `${queryUrl}&sort_by=${sortBy}`
		}
		if(email != null){
			queryUrl = `${queryUrl}&email=${email}`
		}
		if(archived){
			queryUrl = `${queryUrl}&filter_by=archived`
		}
		return this.fetch(queryUrl, {
			method: 'GET',
			auth: true
		})
	}	

	getUser(){
		return this.fetch('v1/users/current', {
			method: 'GET',
			auth: true
		})
	}

	getUserById(userId){
		return this.fetch(`v1/users/${userId}`, {
			method: 'GET',
			auth: true
		})
	}

	updateUser(id, userPayload){
		return this.fetch(`v1/users/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(userPayload)
		})
	}

	forgotPassword(email){
		return this.fetch('v1/password/forgot', {
			method: 'POST',
			body: JSON.stringify({
				email: email
			})
		})
	}

	resetPassword(token, password){
		return this.fetch('v1/password/reset', {
			method: 'POST',
			body: JSON.stringify({
				token: token,
				password: password
			})
		})
	}

	changeUserRegion(userId, regionId){
		return this.fetch(`v1/users/${userId}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify({
				region_id: regionId
			})
		})
	}

	changeUserEmail(userId, email){
		return this.fetch(`v1/users/${userId}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify({
				email: email
			})
		})
	}

	changeCurrentUserPassword(passwordPayload){
		return this.fetch(`v1/users/current/password`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(passwordPayload)
		})
	}

	changeUserProfilePhotoFilename(userId, filename){
		return this.fetch(`v1/users/${userId}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify({
				profile_photo_filename: filename
			})
		})
	}

	inviteUser(userPayload){
		return this.fetch('v1/users/invite', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(userPayload)
		})
	}

	acceptInvite(token, password){
		return this.fetch('v1/users/invite/accept', {
			method: 'POST',
			body: JSON.stringify({
				'token': token,
				'password': password
			})
		})
	}

	getAffiliateLinks(){
		return this.fetch(`v1/users/current/affiliate-links`, {
			method: 'GET',
			auth: true
		})
	}

	updateManualFreeSubscription(id, planId, subscriptionPeriodEnd){
		return this.fetch(`v1/subscriptions/user/${id}/manual`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify({
				plan_id: planId,
				current_period_end: subscriptionPeriodEnd
			})
		})
	}
}

export default UserApi;