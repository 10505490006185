import { observable, action, computed } from 'mobx';

import UserApi from '../../services/user';
import SubscriptionApi from '../../services/subscription';
import CommonApi from '../../services/common';
import CountryApi from '../../services/country';
import PlanApi from '../../services/plan';
import S3Api from '../../services/s3';
import InvoiceApi from '../../services/invoice';

import { isRequiredValidator, isEmailValidator, isPasswordValidator} from '../../services/validation';
import { FieldState, FormState } from 'formstate';

class UserProfileStore{
	appStore;

	userApi;
	subscriptionApi;
	commonApi;
	countryApi;
	planApi;
	s3Api;
	invoiceApi;

	@observable personalInformationData;
	@observable savingPersonalInformationData;

	@observable editMode;

	@observable editCardMode;

	@observable currentSection;
	@observable uploadingImage;

	@observable updatingCard;
	@observable fetching;

	@observable countries;

	@observable clientSecret;

	@observable periods;
	@observable plans;

	@observable showChangePlan;
	@observable showUpgradedPlan;
	@observable selectedUpgradePlan;
	@observable selectedUpgradePeriod;

	@observable upgradePreview;
	@observable upgrading;

	@observable emailEditMode;
	@observable passwordEditMode;

	@observable accountSettingsData;
	@observable savingNewEmail;

	@observable changePasswordData;
	@observable savingPassword;

	@observable invoices;

	constructor(appStore){
		this.appStore = appStore;
		this.userApi = new UserApi(appStore);
		this.commonApi = new CommonApi(appStore);
		this.countryApi = new CountryApi(appStore);
		this.subscriptionApi = new SubscriptionApi(appStore);
		this.s3Api = new S3Api();
		this.planApi = new PlanApi(appStore);
		this.invoiceApi = new InvoiceApi(appStore);
		this.initStore();
	}

	initStore(){
		this.currentSection = 0;
		this.personalInformationData = {
			id: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			name: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			email: new FieldState(null).validators((val) => isEmailValidator(val, this.appStore.i18n)),
			occupation: new FieldState(null),
			selectedCountry: new FieldState(null),
			selectedBillingCountry: new FieldState(null),
			addressLine1: new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.address-line-1'))),
			addressLine2: new FieldState(''),
			addressCity: new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.address-city'))),
			addressState: new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.address-state'))),
			addressZip: new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.address-zip'))),
			taxId: new FieldState(null),
			selectedState: new FieldState(null),
			selectedRegion: new FieldState(null)
		}
		this.savingPersonalInformationData = false;
		this.fetching = false;
		this.countries = [];
		this.uploadingImage = false;
		this.updatingCard = false;
		this.clientSecret = null;
		this.editCardMode = false;
		this.showChangePlan = false;
		this.showUpgradedPlan = false;
		this.periods = ['Monthly', 'Annually'];
		this.plans = [];
		this.selectedUpgradePlan = null;
		this.selectedUpgradePeriod = null;
		this.upgradePreview = null;
		this.upgrading = false;
		this.emailEditMode = false;
		this.passwordEditMode = false;
		this.accountSettingsData = {
			email: new FieldState(null).validators((val) => isEmailValidator(val, this.appStore.i18n)),
			confirmEmail: new FieldState(null).validators((val) => this.accountSettingsData.email.value.length > 0 && val != this.accountSettingsData.email.value && this.appStore.i18n.t('validation.non-matching-email-message')),
		}
		this.savingNewEmail = false;
		this.changePasswordData = {
			currentPassword: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			newPassword: new FieldState(null).validators((val) => isPasswordValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.password'))),
			confirmPassword: new FieldState(null).validators((val) => this.changePasswordData.newPassword.value.length > 0 && val != this.changePasswordData.newPassword.value && this.appStore.i18n.t('validation.non-matching-password-message'))
		}
		this.savingPassword = false;
		this.invoices = [];
	}

	fetchInvoices(){
		this.invoiceApi.getAll()
			.then((response) => {
				console.log(response);
				this.invoices = response.invoices;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchPlans(){
		if(this.appStore.currentUser == null) return;
		if(this.appStore.currentUser.subscription == null) return;
		let currentPlanCurrency = this.appStore.currentUser.subscription.plan.currency.id;
		let currentPeriod = this.selectedUpgradePeriod != null ? this.selectedUpgradePeriod : this.appStore.currentUser.subscription.plan.period;
		this.planApi.getSpecificPlans(currentPlanCurrency, currentPeriod)
			.then((response) => {
				this.plans = response.plans;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get planOptions(){
		let currentPlanId = this.appStore.currentUser.subscription.plan.id;
		return this.plans.filter((p) => p.id != currentPlanId).map((p) => {
			return {
				label: p.name,
				value: p.id
			}
		})
	}

	@action chooseUpgradePlan(val){
		this.selectedUpgradePlan = val;
		this.upgradePreview = null;
		this.getUpgradePreview();
	}

	getUpgradePreview(){
		let upgradePlanId = this.selectedUpgradePlan;
		this.subscriptionApi.previewUpgradeSubscription(upgradePlanId)
			.then((response) => {
				this.upgradePreview = response.preview;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get periodOptions(){
		return this.periods.map((p) => {
			return {
				label: p,
				value: p.toLowerCase()
			}
		})
	}

	@action changePlanPeriod(val){
		this.selectedUpgradePeriod = val;
		this.selectedUpgradePlan = null;
		this.upgradePreview = null;
		this.fetchPlans();
	}

	fetchClientSecret(){
		this.subscriptionApi.getSetupIntent()
			.then((response) => {
				this.clientSecret = response.client_secret;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action moveToPersonalInformation(){
		this.currentSection = 0;
	}

	@action moveToPaymentDetails(){
		this.currentSection = 1;
	}

	@action moveToSubscription(){
		this.currentSection = 2;
	}

	@action moveToAccountSettings(){
		this.currentSection = 3;
	}

	@action toggleUserProfileModal(){
        this.appStore.showUserProfileModal = true;
    }

    @action setEditMode(){
    	this.editMode = true;
    	let currentUser = this.appStore.currentUser;
    	this.personalInformationData.id.onChange(currentUser.id);
		this.personalInformationData.name.onChange(currentUser.name);
		this.personalInformationData.email.onChange(currentUser.email);
		this.personalInformationData.occupation.onChange(currentUser.occupation);
		this.personalInformationData.selectedCountry.onChange(currentUser.country?.id);
		this.personalInformationData.selectedBillingCountry.onChange(currentUser.billing_country?.id);
		this.personalInformationData.addressLine1.onChange(currentUser.billing_address_line_1);
		this.personalInformationData.addressLine2.onChange(currentUser.billing_address_line_2);
		this.personalInformationData.addressCity.onChange(currentUser.billing_address_city);
		this.personalInformationData.addressState.onChange(currentUser.billing_address_state);
		if(this.showStateDropdown){
			this.personalInformationData.selectedState.onChange(currentUser.billing_address_state);
		}
		this.personalInformationData.addressZip.onChange(currentUser.billing_address_zip);
		this.personalInformationData.taxId.onChange(currentUser.vat_number);
		this.personalInformationData.selectedRegion.onChange(currentUser.region?.id);
    }

    @action cancelEditMode(){
    	this.editMode = false;
    }

    @action setEditCardMode(){
    	this.editCardMode = true;
    }

    @action cancelEditCardMode(){
    	this.editCardMode = false;
    }

    @computed get billingCountryIsoAlpha2LetterCode(){
		if(this.personalInformationData.selectedBillingCountry.value == null) return null;
		let foundCountry = this.countries.find((c) => c.id == this.personalInformationData.selectedBillingCountry.value);
		return foundCountry?.iso_3166_alpha_2_code;
	}

	stateOptions(){
		let allStates = [
			this.appStore.i18n.t('common.states.option-alabama'),
			this.appStore.i18n.t('common.states.option-alaska'),
			this.appStore.i18n.t('common.states.option-arizona'),
			this.appStore.i18n.t('common.states.option-arkansas'),
			this.appStore.i18n.t('common.states.option-california'),
			this.appStore.i18n.t('common.states.option-colorado'),
			this.appStore.i18n.t('common.states.option-connecticut'),
			this.appStore.i18n.t('common.states.option-delaware'),
			this.appStore.i18n.t('common.states.option-florida'),
			this.appStore.i18n.t('common.states.option-georgia'),
			this.appStore.i18n.t('common.states.option-hawaii'),
			this.appStore.i18n.t('common.states.option-idaho'),
			this.appStore.i18n.t('common.states.option-illinois'),
			this.appStore.i18n.t('common.states.option-indiana'),
			this.appStore.i18n.t('common.states.option-iowa'),
			this.appStore.i18n.t('common.states.option-kansas'),
			this.appStore.i18n.t('common.states.option-kentucky'),
			this.appStore.i18n.t('common.states.option-louisiana'),
			this.appStore.i18n.t('common.states.option-maine'),
			this.appStore.i18n.t('common.states.option-maryland'),
			this.appStore.i18n.t('common.states.option-massachusetts'),
			this.appStore.i18n.t('common.states.option-michigan'),
			this.appStore.i18n.t('common.states.option-minnesota'),
			this.appStore.i18n.t('common.states.option-mississippi'),
			this.appStore.i18n.t('common.states.option-missouri'),
			this.appStore.i18n.t('common.states.option-montana'),
			this.appStore.i18n.t('common.states.option-nebraska'),
			this.appStore.i18n.t('common.states.option-nevada'),
			this.appStore.i18n.t('common.states.option-new-hampshire'),
			this.appStore.i18n.t('common.states.option-new-jersey'),
			this.appStore.i18n.t('common.states.option-new-mexico'),
			this.appStore.i18n.t('common.states.option-new-york'),
			this.appStore.i18n.t('common.states.option-north-carolina'),
			this.appStore.i18n.t('common.states.option-north-dakota'),
			this.appStore.i18n.t('common.states.option-ohio'),
			this.appStore.i18n.t('common.states.option-oklahoma'),
			this.appStore.i18n.t('common.states.option-oregon'),
			this.appStore.i18n.t('common.states.option-pennsylvania'),
			this.appStore.i18n.t('common.states.option-rhode-island'),
			this.appStore.i18n.t('common.states.option-south-carolina'),
			this.appStore.i18n.t('common.states.option-south-dakota'),
			this.appStore.i18n.t('common.states.option-tennessee'),
			this.appStore.i18n.t('common.states.option-texas'),
			this.appStore.i18n.t('common.states.option-utah'),
			this.appStore.i18n.t('common.states.option-vermont'),
			this.appStore.i18n.t('common.states.option-virginia'),
			this.appStore.i18n.t('common.states.option-washington'),
			this.appStore.i18n.t('common.states.option-west Virginia'),
			this.appStore.i18n.t('common.states.option-wisconsin'),
			this.appStore.i18n.t('common.states.option-wyoming')
		]
		return allStates.map((state) => {
			return {
				value: state,
				label: state
			}
		})
	}

	@computed get showStateDropdown(){
		return this.billingCountryIsoAlpha2LetterCode === 'US';
	}

    savePersonalInformation(){

    	if(this.showStateDropdown && this.personalInformationData.selectedState.value == null){
    		this.appStore.alertError(this.appStore.i18n.t('error.missing-state-message'));
			return;
    	}

        this.savingPersonalInformationData = true;
        let userPayload = {
        	name: this.personalInformationData.name.value,
        	email: this.personalInformationData.email.value,
        	occupation: this.personalInformationData.occupation.value,
        	language: this.appStore.currentUser.language,
        	country_id: this.personalInformationData.selectedCountry.value,
        	region_id: this.personalInformationData.selectedRegion.value,
        	billing_country_id: this.personalInformationData.selectedBillingCountry.value,
        	billing_address_line_1: this.personalInformationData.addressLine1.value,
        	billing_address_line_2: this.personalInformationData.addressLine2.value,
        	billing_address_city: this.personalInformationData.addressCity.value,
        	billing_address_state: this.showStateDropdown ? this.personalInformationData.selectedState.value : this.personalInformationData.addressState.value,
        	billing_address_zip: this.personalInformationData.addressZip.value,
        	vat_number: this.personalInformationData.taxId.value
        }

        this.userApi.updateUser(this.personalInformationData.id.value, userPayload)
	        .then((response) => {
	        	let updatedUser = response;
	        	this.appStore.currentUser = updatedUser;
	        	this.editMode = false;
	        })
	        .catch((error) => {
	        	console.log(error);
	        })
	        .finally(() => {
	        	this.savingPersonalInformationData = false;
	        })
     }

    fetchCountries(){
		this.countryApi.getAll()
			.then((response) => {
				this.countries = response.countries;
			})
			.catch((error) => {
				console.log(error);
			})
	}

    @computed get countryOptions(){
		return this.countries.map((c) => {
			return {
				label: c.name,
				value: c.id
			}
		})
	}

	fetchRegions(){
		this.commonApi.getRegions()
			.then((response) => {
				this.regions = response.regions;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	@computed get regionOptions(){
		return this.regions.map((c) => {
			return {
				label: c.name,
				value: c.id
			}
		})
	}

	@action hideProfileSettings(){
		this.appStore.showUserProfileModal = false;	
	}

	@action changeProfileImage(file){
		this.uploadingImage = true;
		this.commonApi.getPresignedUrl(file.type)
			.then((response) => {
				let filename = response.filename;
				let signedUrlPayload = response.signed_url_payload;
				this.s3Api.uploadToS3(file, signedUrlPayload)
					.then((response) => {
						this.userApi.changeUserProfilePhotoFilename(this.appStore.currentUser.id, filename)
							.then((response) => {
								this.appStore.currentUser.profile_photo_url = response.profile_photo_url;
								this.appStore.alertSuccess(this.appStore.i18n.t('success.profile-photo-saved-message'));
							})
							.catch((error) => {
								this.appStore.alertError(this.appStore.i18n.t('error.uploading-photo-message'));
							})
							.finally(() => {
								this.uploadingImage = false;
							})
					})
					.catch((error) => {
						this.uploadingImage = false;
						this.appStore.alertError(this.appStore.i18n.t('error.uploading-photo-message'));
					});
			})
			.catch((error) => {
				this.uploadingImage = false;
				this.appStore.alertError(this.appStore.i18n.t('error.uploading-photo-message'));
			})
	}

	@action updateCard(paymentMethodId){
		this.updatingCard = true;
		this.subscriptionApi.updateCardForCurrentUser(paymentMethodId)
			.then((response) => {
				let subscription = response.subscription;
				this.appStore.currentUser.subscription = subscription;
				this.appStore.alertSuccess(this.appStore.i18n.t('success.card-saved-message'));
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.finishUpdatingCard();
			})
	}

	@action setUpdatingCard(){
		this.updatingCard = true;
	}

	@action finishUpdatingCard(){
		this.updatingCard = false;
		this.editCardMode = false;
	}

	@action goToChangePlan(){
		this.showChangePlan = true;
	}

	@action returnToChangePlan(){
		this.showUpgradedPlan = false;
		this.showChangePlan = false;
	}

	@computed get upgradeDescription(){
		if(this.selectedUpgradePlan == null || this.selectedUpgradePeriod == null || this.upgradePreview == null) return null;
		return `${this.upgradePreview.plan.currency.symbol}${parseFloat(this.upgradePreview.cost).toFixed(2)}`;
	}

	@action upgrade(){
		if(this.selectedUpgradePlan == null) return;

		this.upgrading = true;
		this.subscriptionApi.upgrade(this.selectedUpgradePlan)
			.then((response) => {
				this.appStore.getCurrentUser()
					.then(() => {
						this.showUpgradedPlan = true;		
					});
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.upgrading = false;
			})
	}

	@action setEmailEditMode(){
		this.emailEditMode = true;
		this.accountSettingsData.email.value = this.appStore.currentUser.email;
		this.accountSettingsData.confirmEmail.value = this.appStore.currentUser.email;
	}

	@action cancelEmailEditMode(){
		this.emailEditMode = false;
	}

	@action setPasswordEditMode(){
		this.passwordEditMode = true;
	}

	@action cancelPasswordEditMode(){
		this.passwordEditMode = false;
	}

	@computed get validPersonalInformationData(){
		let requiredFields = ['name', 'email'];
		let isValidForm = true;
		for(let field of requiredFields){
			let fieldValue = this.personalInformationData[field].value;
			if(fieldValue == null | fieldValue == ''){
				isValidForm = false;
			}
			let fieldError = this.personalInformationData[field].error;
			isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
		}
		if(this.personalInformationData.selectedCountry == null || this.personalInformationData.selectedRegion == null){
			return false;
		}
		return isValidForm;
	}

	@computed get validEmailData(){
		let requiredFields = ['email', 'confirmEmail'];
		let isValidForm = true;
		for(let field of requiredFields){
			let fieldValue = this.accountSettingsData[field].value;
			if(fieldValue == null | fieldValue == ''){
				isValidForm = false;
			}
			let fieldError = this.accountSettingsData[field].error;
			isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
		}
		if(this.accountSettingsData.email.value != this.accountSettingsData.confirmEmail.value){
			return false;
		}
		return isValidForm;
	}

	@computed get validPasswordData(){
		let requiredFields = ['currentPassword', 'newPassword', 'confirmPassword'];
		let isValidForm = true;
		for(let field of requiredFields){
			let fieldValue = this.changePasswordData[field].value;
			if(fieldValue == null | fieldValue == ''){
				isValidForm = false;
			}
			let fieldError = this.changePasswordData[field].error;
			isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
		}
		if(this.changePasswordData.newPassword.value != this.changePasswordData.confirmPassword.value){
			return false;
		}
		return isValidForm;
	}

	@action updateEmail(){
		let currentUser = this.appStore.currentUser;
		this.savingNewEmail = true;
		this.userApi.changeUserEmail(currentUser.id, this.accountSettingsData.email.value)
			.then((response) => {
				this.appStore.currentUser = response;
				this.emailEditMode = false;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.savingNewEmail = false;
			})
	}

	@action updatePassword(){
		let currentUser = this.appStore.currentUser;
		this.savingPassword = true;
		let passwordPayload = {
			current_password: this.changePasswordData.currentPassword.value,
			new_password: this.changePasswordData.newPassword.value
		}
		this.userApi.changeCurrentUserPassword(passwordPayload)
			.then((response) => {
				this.passwordEditMode = false;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.savingPassword = false;
			})
	}

	@computed get showUserProfileModal(){
		return this.appStore.showUserProfileModal;
	}

	@computed get showCardChangesOrPlanChanges(){
		if(this.appStore.currentUser == null) return false;
		if(this.appStore.currentUser.subscription == null) return false;
		if(this.appStore.currentUser.subscription.is_manual) return false;
		return true;
	}
}

export default UserProfileStore;