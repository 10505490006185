import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';

import React, {Component, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'mobx-react';

import AppStore from './stores/app';
import SignUpStore from './stores/registration/sign-up';
import LoginStore from './stores/login';
import NewsletterStore from './stores/home/newsletter';
import ForgotPasswordStore from './stores/home/forgot-password';
import ResetPasswordStore from './stores/home/reset-password';
import AcceptInviteStore from './stores/home/accept-invite';

import PricingStore from './stores/home/pricing';
import TrainingStore from './stores/home/training';
import UserStore from './stores/home/user';
import UserProfileStore from './stores/user/profile';
import TermsStore from './stores/home/terms';
import CartStore from './stores/home/cart';
import ContactStore from './stores/home/contact';

import SuperAdminHomeStore from './stores/superadmin/index';
import AdminHomeStore from './stores/admin/index';
import UsersStore from './stores/admin/users/list';
import ManageUserStore from './stores/admin/users/manage-user';
import InviteUserStore from './stores/admin/users/invite-user';

import OrganisationsStore from './stores/admin/organisations/list';
import ManageOrganisationStore from './stores/admin/organisations/manage-organisation';
import InviteOrganisationStore from './stores/admin/organisations/invite-organisation';

import ChemicalFamiliesStore from './stores/superadmin/chemistry-family/list';
import ManageChemistryFamilyStore from './stores/superadmin/chemistry-family/manage-family';

import ChemicalComponentsStore from './stores/superadmin/chemical-components/list';
import ManageChemistryComponentStore from './stores/superadmin/chemical-components/manage-component';

import AcidFamiliesStore from './stores/superadmin/acid-family/list';
import ManageAcidFamilyStore from './stores/superadmin/acid-family/manage-family';

import AcidComponentsStore from './stores/superadmin/acid-components/list';
import ManageAcidComponentStore from './stores/superadmin/acid-components/manage-component';

import RegulationsStore from './stores/superadmin/regulations/list';
import ManageRegulationStore from './stores/superadmin/regulations/manage-regulation';

import IngredientsLibraryStore from './stores/user/ingredients/library';
import ManageIngredientStore from './stores/user/ingredients/manage-ingredient';

import IngredientComparisonsStore from './stores/user/ingredients/comparison/list';
import IngredientCompareToolStore from './stores/user/ingredients/comparison/manage-tool';

import BlendCategoriesStore from './stores/user/blends/categories/list';
import ManageBlendCategoryStore from './stores/user/blends/categories/manage-blend-category';

import BlendLibraryStore from './stores/user/blends/libraries/library';
import ManageBlendStore from './stores/user/blends/libraries/manage-blend';

import LibraryCategoriesStore from './stores/admin/library-categories/list';
import ManageLibraryCategoryStore from './stores/admin/library-categories/manage-library-category';

import ComponentSearchStore from './stores/user/components/component-search';
import ComponentSearchListStore from './stores/user/components/component-search-list';

import LanguagesStore from './stores/superadmin/languages/list';
import ManageLanguageStore from './stores/superadmin/languages/manage-language';

import AdvancedSearchStore from './stores/user/advanced-search';

import EvidenceSearchStore from './stores/user/evidence/evidence-search';
import ManageEvidenceStore from './stores/superadmin/evidence/manage-evidence';

import MarketingAssetSearchStore from './stores/admin/marketing-assets/marketing-asset-search';
import ManageMarketingAssetStore from './stores/superadmin/marketing-assets/manage-marketing-asset';

import {startRouter} from './router';

import App from './App';
import i18n from './i18n';

function noop() {};
if(process.env.NODE_ENV !== 'development'){ 
	console.log = noop; 
	console.warn = noop; 
	console.error = noop; 
}

let appStore = new AppStore(i18n);
let signUpStore = new SignUpStore(appStore);
let loginStore = new LoginStore(appStore);
let newsletterStore = new NewsletterStore(appStore);
let forgotPasswordStore = new ForgotPasswordStore(appStore);
let resetPasswordStore = new ResetPasswordStore(appStore);
let acceptInviteStore = new AcceptInviteStore(appStore);
let pricingStore = new PricingStore(appStore);
let userStore = new UserStore(appStore);
let userProfileStore = new UserProfileStore(appStore);
let trainingStore = new TrainingStore(appStore);
let termsStore = new TermsStore(appStore);
let contactStore = new ContactStore(appStore);
let cartStore = new CartStore(appStore);
let usersStore = new UsersStore(appStore);
let manageUserStore = new ManageUserStore(appStore);
let inviteUserStore = new InviteUserStore(appStore);
let organisationsStore = new OrganisationsStore(appStore);
let manageOrganisationStore = new ManageOrganisationStore(appStore);
let inviteOrganisationStore = new InviteOrganisationStore(appStore);
let superAdminHomeStore = new SuperAdminHomeStore(appStore);
let adminHomeStore = new AdminHomeStore(appStore);
let chemicalFamiliesStore = new ChemicalFamiliesStore(appStore);
let manageChemistryFamily = new ManageChemistryFamilyStore(appStore);
let chemicalComponentsStore = new ChemicalComponentsStore(appStore);
let manageChemistryComponent = new ManageChemistryComponentStore(appStore);
let acidFamiliesStore = new AcidFamiliesStore(appStore);
let manageAcidFamily = new ManageAcidFamilyStore(appStore);
let acidComponentsStore = new AcidComponentsStore(appStore);
let manageAcidComponent = new ManageAcidComponentStore(appStore);
let regulationsStore = new RegulationsStore(appStore);
let manageRegulation = new ManageRegulationStore(appStore);
let ingredientsLibraryStore = new IngredientsLibraryStore(appStore);
let manageIngredientStore = new ManageIngredientStore(appStore);
let blendCategoriesStore = new BlendCategoriesStore(appStore);
let manageBlendCategoryStore = new ManageBlendCategoryStore(appStore);
let blendsLibraryStore = new BlendLibraryStore(appStore);
let manageBlendStore = new ManageBlendStore(appStore);
let libraryCategoriesStore = new LibraryCategoriesStore(appStore);
let manageLibraryCategoryStore = new ManageLibraryCategoryStore(appStore);
let ingredientComparisonsStore = new IngredientComparisonsStore(appStore);
let ingredientCompareToolStore = new IngredientCompareToolStore(appStore);
let componentSearchStore = new ComponentSearchStore(appStore);
let componentSearchListStore = new ComponentSearchListStore(appStore);
let languagesStore = new LanguagesStore(appStore);
let manageLanguageStore = new ManageLanguageStore(appStore);
let advancedSearchStore = new AdvancedSearchStore(appStore);
let evidenceSearchStore = new EvidenceSearchStore(appStore);
let manageEvidenceStore = new ManageEvidenceStore(appStore);
let marketingAssetSearchStore = new MarketingAssetSearchStore(appStore);
let manageMarketingAssetStore = new ManageMarketingAssetStore(appStore);

startRouter(appStore);

let stores = {appStore, signUpStore, loginStore, newsletterStore,
			  forgotPasswordStore, resetPasswordStore, pricingStore,
			  userStore, userProfileStore, trainingStore, termsStore,
			  cartStore, contactStore, usersStore, manageUserStore,
			  inviteUserStore, acceptInviteStore,
			  organisationsStore, manageOrganisationStore, inviteOrganisationStore,
			  superAdminHomeStore, adminHomeStore, chemicalFamiliesStore, manageChemistryFamily,
			  chemicalComponentsStore, manageChemistryComponent, regulationsStore, manageRegulation,
			  ingredientsLibraryStore, manageIngredientStore, blendCategoriesStore, manageBlendCategoryStore,
			  blendsLibraryStore, manageBlendStore, 
			  acidFamiliesStore, manageAcidFamily, acidComponentsStore, manageAcidComponent,
			  libraryCategoriesStore, manageLibraryCategoryStore, ingredientComparisonsStore, ingredientCompareToolStore,
			  componentSearchStore, componentSearchListStore, languagesStore, manageLanguageStore, advancedSearchStore, evidenceSearchStore, manageEvidenceStore,
			  marketingAssetSearchStore, manageMarketingAssetStore
			  };

class Root extends Component{
	render() {
		return (
	      	<Provider {...stores}>
	      		<Suspense fallback="Loading...">
					<App />
				</Suspense>
    		</Provider>
	    )
	}
}

ReactDOM.render(<Root />, document.getElementById('root'));