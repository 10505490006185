import BaseApi from '../services/base';

class NewsletterApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getIntroSnippet(){
		return this.fetch('v1/snippet/newsletter-intro', {
			method: 'GET'
		})
	}

	subscribe(name, email, countryId){
		return this.fetch(`v1/newsletter`, {
			method: 'POST',
			body: JSON.stringify({
				name: name,
				email: email,
				country_id: countryId
			})
		})
	}
}

export default NewsletterApi;