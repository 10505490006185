import React, { Component, Fragment, useMemo } from 'react';
import {inject, observer} from 'mobx-react';
import { withTranslation } from 'react-i18next';

import {Label, Body, CallToAction, SecondaryWebLargerTitle, SecondaryWebSmallTitle, SecondaryWebMediumTitle, SmallBody} from '../common/text'
import {TextInput, PasswordInput, CheckBox, Select} from '../common/input'
import {Button} from '../common/button';

import { HorizontalFormColumn } from '../common/form';
import LeftBlockRightContent from '../common/left-block-right-content';

import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';

import runtimeEnv from '@mars/heroku-js-runtime-env';

const colors = require('../../color.js');
import creditCardSrc from '../../assets/img/icons/credit-card.svg';

const env = runtimeEnv();
const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY);

const stripeInputOptions = {
  style: {
    base: {
      fontFamily: "Proxima Nova Alt Regular",
      color: colors['black'],
      fontSize: '18px',
      "::placeholder": {
        color: colors['black']
      }
    },
    invalid: {
      color: colors['error']
    }
  }
}


const cardNumberInputOptions = {
	...stripeInputOptions,
	placeholder: 'xxxx xxxx xxxx xxxx'
}

@observer
class CheckoutForm extends React.Component {
	constructor(props){
		super(props);
		this.changeDiscountCode = this.changeDiscountCode.bind(this);
		this.applyDiscountCode = this.applyDiscountCode.bind(this);
		this.changeSubscriptionPeriod = this.changeSubscriptionPeriod.bind(this);
		this.changeTermsAndConditions = this.changeTermsAndConditions.bind(this);
		this.changePlan = this.changePlan.bind(this);
		this.changeCurrency = this.changeCurrency.bind(this);
		this.placeOrder = this.placeOrder.bind(this);
		this.onTerms = this.onTerms.bind(this);
		this.handleReferrer = this.handleReferrer.bind(this);
	}

	componentDidMount(){
		if('Rewardful' in window){
			window.Rewardful.Forms.attach();
		}
		this.handleReferrer();
	}

	handleReferrer(){
		if('Rewardful' in window){
			let referral = window.Rewardful.referral;
			if(referral){
				this.props.onReferral(window.Rewardful.referral);
			}
		}
	}

	changeDiscountCode(e){
		this.props.onChangeDiscountCode(e.target.value);
	}

	changeSubscriptionPeriod(option){
		this.props.onChangeSubscriptionPeriod(option.value);
	}

	changeTermsAndConditions(){
		this.props.onToggleTermsAndConditions();
	}

	changePlan(option){
		this.props.onChangePlan(option.value);
	}

	changeCurrency(option){
		this.props.onChangeCurrency(option);
	}

	applyDiscountCode(){
		this.props.onApplyDiscountCode();
	}

	onTerms(){
		this.props.onTerms();
	}

	placeOrder = async (event) => {
		event.preventDefault();
		let translate = this.props.t;

		const {stripe, elements} = this.props;
	    if (!stripe || !elements) {
	      // Stripe.js has not loaded yet. Make sure to disable
	      // form submission until Stripe.js has loaded.
	      return;
	    }
	  	
	  	const cardElement = elements.getElement(CardNumberElement);

	  	this.props.setSubscribing();
	    const {error, paymentMethod} = await stripe.createPaymentMethod({
	      type: 'card',
	      card: cardElement,
	      billing_details: {
	      	name: this.props.cardHolderName.value,
	        email: this.props.email,
	        address: {
	        	line1: this.props.addressLine1.value,
		        line2: this.props.addressLine2.value,
		        postal_code: this.props.addressZip.value,
		        state: this.props.addressState.value,
		        city: this.props.addressCity.value,
		        country: this.props.billingCountryIsoAlpha2LetterCode
		   }
	      }
	    });

	    if (error) {
	    	if('message' in error){
	    		this.props.appStore.alertError(error.message);
	    	}else{
	    		this.props.appStore.alertError(translate('error.processing-card-error-message'));
	    	}
	    	this.props.onPaymentMethodFailed();
	    } else {
	      this.props.onPaymentMethod(paymentMethod)
	        .then((response) => {
	          // free user with 100% off
	          if(response.payment_intent == null){
	          	this.props.onPaymentMethodSuccess();
	          }else{
	          	const { client_secret, status } = response.payment_intent;
	          	if (status === 'requires_action'){
	            	// Verify payment intent
	            	stripe.confirmCardPayment(client_secret)
			            .then((cardPaymentResponse) => {
			              if(cardPaymentResponse.error) {
			              	if('message' in cardPaymentResponse.error){
			              		this.props.appStore.alertError(cardPaymentResponse.error.message);
			              	}else{
			              		this.props.appStore.alertError(translate('error.processing-card-error-message'));
			              	}
			                
			                this.props.onPaymentMethodFailed();
			              } else {
			                this.props.onPaymentMethodSuccessWithPaymentIntent(cardPaymentResponse.paymentIntent);
			              }
			            });
	          	}else{
	            	this.props.onPaymentMethodSuccess();
	          	}
	          }
	        })
	        .catch((error) => {
	          this.props.onPaymentMethodFailed();
	        });
	    }
	}

  	render() {
  		let translate = this.props.t;
	    return (
	    	<form className="flex flex-col" data-rewardful>
	    		<div className="flex flex-col mt-8">
					<div className="flex"><SecondaryWebMediumTitle className="px-2">{translate('registration.step3.title')}</SecondaryWebMediumTitle></div>
					<div className="mt-8 lg:mt-16"></div>
					{this.props.showOptions && 
						<div className="flex w-full flex-col">
							<div className="flex my-8">
								<SecondaryWebSmallTitle>{translate('registration.step3.subscription-header')}</SecondaryWebSmallTitle>
							</div>
							<HorizontalFormColumn>
								<Label>{translate("registration.step1.subscription.label")}</Label>
								<Select 
									options={this.props.subscriptionOptions} 
									value={this.props.subscriptionPeriod} 
									onChange={this.changeSubscriptionPeriod}
								/>
							</HorizontalFormColumn>
							<HorizontalFormColumn>
								<Label>{translate("registration.step1.currency.label")}</Label>
								<Select 
									options={this.props.currencyOptions}
									value={this.props.selectedCurrency}
									onChange={this.changeCurrency} />
							</HorizontalFormColumn>
							<HorizontalFormColumn>
								<Label>{translate("registration.step1.plan.label")}</Label>
								<Select 
									options={this.props.planOptions}
									value={this.props.selectedPlan}
									onChange={this.changePlan} />
							</HorizontalFormColumn>
						</div>
					}
					<div className="flex my-8">
						<SecondaryWebSmallTitle>{translate('registration.step3.payment-details-header')}</SecondaryWebSmallTitle>
					</div>
					<HorizontalFormColumn>
						<Label>{translate('registration.step3.payment-method.label')}</Label>
						<div className="base-input flex flex-row items-center justify-between">
							<div className="inline-flex"><span className="text-black">{translate('registration.step3.payment-method.placeholder')}</span></div>
							<div className="inline-flex justify-end h-8 w-32 -mr-2">
								<img src={creditCardSrc} alt="Accept Visa, MasterCard and American Express" />
							</div>
						</div>
					</HorizontalFormColumn>
					<HorizontalFormColumn>
						<Label>{translate('registration.step3.cardholder-name.label')}</Label>
						<TextInput
							value={this.props.cardHolderName.value}
							error={this.props.cardHolderName.error}
							onChange={(e) => this.props.onChangeCardHolderName(e.target.value)} 
							placeholder={translate('registration.step3.cardholder-name.placeholder')}
						/>
					</HorizontalFormColumn>
					<HorizontalFormColumn>
						<Label>{translate('registration.step3.card-number.label')}</Label>
						<div className="base-input">
							<CardNumberElement options={cardNumberInputOptions} placeholder='**** **** **** ****' />
						</div>
					</HorizontalFormColumn>
					<div className="flex flex-1 flex-row flex-wrap">
						<div className="flex flex-1 flex-col">
							<Label>{translate('registration.step3.card-expiry.label')}</Label>
							<div className="base-input">
								<CardExpiryElement options={stripeInputOptions} />
							</div>
						</div>
						<div className="flex flex-1 flex-col ml-6">
							<Label>{translate('registration.step3.cvc.label')}</Label>
							<div className="base-input">
								<CardCvcElement options={stripeInputOptions} />
							</div>
						</div>
					</div>
					<HorizontalFormColumn>
						<Label>{translate('registration.step3.discount-code.label')}</Label>
						<TextInput
							value={this.props.discountCode}
							onChange={this.changeDiscountCode} 
							placeholder={translate('registration.step3.discount-code.placeholder')}
							insetoption={
								<span onClick={this.applyDiscountCode}>{translate('common.apply')}</span>
							} 
						/>
					</HorizontalFormColumn>

					<div className="flex flex-col my-8">
						<SecondaryWebSmallTitle>{translate('registration.step3.billing-address-header')}</SecondaryWebSmallTitle>
						<div className="flex mt-1">
							<SmallBody className="nav-inactive-color italic">{translate('registration.step3.billing-address-why')}</SmallBody>
						</div>
					</div>
					<HorizontalFormColumn>
						<Label>{translate('registration.step3.address-line-1.label')}</Label>
						<TextInput
							value={this.props.addressLine1.value}
							error={this.props.addressLine1.error}
							onChange={(e) => this.props.onChangeAddressLine1(e.target.value)} 
							placeholder={translate('registration.step3.address-line-1.placeholder')}
						/>
					</HorizontalFormColumn>


					<HorizontalFormColumn>
						<Label>{translate('registration.step3.address-line-2.label')}</Label>
						<TextInput
							value={this.props.addressLine2.value}
							error={this.props.addressLine2.error}
							onChange={(e) => this.props.onChangeAddressLine2(e.target.value)} 
							placeholder={translate('registration.step3.address-line-2.placeholder')}
						/>
					</HorizontalFormColumn>

					<HorizontalFormColumn>
						<Label>{translate('registration.step3.address-city.label')}</Label>
						<TextInput
							value={this.props.addressCity.value}
							error={this.props.addressCity.error}
							onChange={(e) => this.props.onChangeAddressCity(e.target.value)} 
							placeholder={translate('registration.step3.address-city.placeholder')}
						/>
					</HorizontalFormColumn>

					
					<HorizontalFormColumn>
						<Label>{translate('registration.step3.address-state.label')}</Label>
						{!this.props.showStateDropdown && 
							<TextInput
								value={this.props.addressState.value}
								error={this.props.addressState.error}
								onChange={(e) => this.props.onChangeAddressState(e.target.value)} 
								placeholder={translate('registration.step3.address-state.placeholder')}
							/>
						}
						{this.props.showStateDropdown && 
							<Select 
								placeholder={translate('registration.step3.address-state.placeholder')}
								options={this.props.stateOptions}
								value={this.props.selectedState}
								onChange={(val) => this.props.onChangeState(val)} />
						}
					</HorizontalFormColumn>

					<div className="grid grid-cols-2 gap-4">
						<HorizontalFormColumn>
							<Label>{translate('registration.step3.address-country.label')}</Label>
							<Select 
								placeholder={translate('registration.step3.address-country.placeholder')}
								options={this.props.countryOptions}
								value={this.props.selectedBillingCountry}
								onChange={(val) => this.props.onChangeBillingCountry(val)} />
						</HorizontalFormColumn>
						<HorizontalFormColumn>
							<Label>{translate('registration.step3.address-zip.label')}</Label>
							<TextInput
								value={this.props.addressZip.value}
								error={this.props.addressZip.error}
								onChange={(e) => this.props.onChangeAddressZip(e.target.value)} 
								placeholder={translate('registration.step3.address-zip.placeholder')}
							/>
						</HorizontalFormColumn>
					</div>

					<HorizontalFormColumn>
						<Label>{translate('registration.step3.tax-id.label')}</Label>
						<TextInput
							value={this.props.taxId.value}
							error={this.props.taxId.error}
							onChange={(e) => this.props.onChangeTaxId(e.target.value)} 
							placeholder={translate('registration.step3.tax-id.placeholder')}
						/>
					</HorizontalFormColumn>


					<div className="flex flex-col mt-8">
						<HorizontalFormColumn>
							<Label>{translate('registration.step3.pay.label')}</Label>
							<div className="base-input">
								<Body>{this.props.planDescription}</Body>
							</div>
						</HorizontalFormColumn>

						<div className="flex flex-1 flex-row items-center w-full items-start mt-4">
							<CallToAction>
								<span className="text-sm">
									{translate('registration.step3.terms.prefix')} <span onClick={this.onTerms} className="cursor-pointer underline text-orange">{translate('registration.step3.terms.label')}</span>
									<CheckBox 
										extraInputClassNames="h-5 w-5 border border-black text-black"
										checked={this.props.termsAndConditions}
										onChange={this.changeTermsAndConditions}
										 />
								</span>
							</CallToAction>
						</div>
					</div>
				</div>

				<div className="flex flex-col mt-4">
					<Button
					 width="w-full"
					 bgColor="black"
					 fetching={this.props.subscribing}
					 onClick={this.placeOrder}
					 disabled={!this.props.canPlaceOrder} 
					 >{translate('registration.step3.button')} {this.props.payToday}</Button>
				</div>
			</form>
	    );
 	}
}

@observer
export class InjectedCheckoutForm extends Component{
	render(){
		return (
			<ElementsConsumer>
			    {({stripe, elements}) => {
			    	return (
			      		<CheckoutForm 
			      			t={this.props.t}
			      			stripe={stripe} 
			      			elements={elements}
			      			color={this.props.color}
			      			email={this.props.email}
			      			appStore={this.props.appStore}
			      			setSubscribing={this.props.setSubscribing}
			      			onChangeDiscountCode={this.props.onChangeDiscountCode}
			      			discountCode={this.props.discountCode}
			      			onApplyDiscountCode={this.props.onApplyDiscountCode}
			      			cardHolderName={this.props.cardHolderName}
			      			onChangeCardHolderName={this.props.onChangeCardHolderName}
			      			addressLine1={this.props.addressLine1}
			      			onChangeAddressLine1={this.props.onChangeAddressLine1}
			      			addressLine2={this.props.addressLine2}
			      			onChangeAddressLine2={this.props.onChangeAddressLine2}
			      			addressCity={this.props.addressCity}
			      			onChangeAddressCity={this.props.onChangeAddressCity}
			      			addressState={this.props.addressState}
			      			onChangeAddressState={this.props.onChangeAddressState}
			      			onChangeState={this.props.onChangeState}
			      			selectedState={this.props.selectedState}
			      			stateOptions={this.props.stateOptions}
			      			addressZip={this.props.addressZip}
			      			taxId={this.props.taxId}
			      			countryOptions={this.props.countryOptions}
			      			selectedBillingCountry={this.props.selectedBillingCountry}
			      			onChangeBillingCountry={this.props.onChangeBillingCountry}
			      			onChangeTaxId={this.props.onChangeTaxId}
			      			onChangeAddressZip={this.props.onChangeAddressZip}
			      			termsAndConditions={this.props.termsAndConditions}
			      			onToggleTermsAndConditions={this.props.onToggleTermsAndConditions}
			      			planDescription={this.props.planDescription}
			      			onPaymentMethod={this.props.onPaymentMethod}
			      			onPaymentMethodSuccess={this.props.onPaymentMethodSuccess}
			       			onPaymentMethodSuccessWithPaymentIntent={this.props.onPaymentMethodSuccessWithPaymentIntent}
			       			onPaymentMethodFailed={this.props.onPaymentMethodFailed}
			      			subscribing={this.props.subscribing}
			      			canPlaceOrder={this.props.canPlaceOrder}
			      			onSubmit={this.props.onSubmit}
			      			onTerms={this.props.onTerms}
			      			payToday={this.props.payToday}
			      			showOptions={this.props.showOptions}
			      			currencyOptions={this.props.currencyOptions}
			      			selectedCurrency={this.props.selectedCurrency}
			      			onChangeCurrency={this.props.onChangeCurrency}
			      			planOptions={this.props.planOptions}
			      			selectedPlan={this.props.selectedPlan}
			      			onChangePlan={this.props.onChangePlan}
			      			onChangeSubscriptionPeriod={this.props.onChangeSubscriptionPeriod}
			      			subscriptionPeriod={this.props.subscriptionPeriod}
			      			subscriptionOptions={this.props.subscriptionOptions}
			      			onReferral={this.props.onReferral}
			      			onApplyTaxId={this.props.onApplyTaxId}
			      			billingCountryIsoAlpha2LetterCode={this.props.billingCountryIsoAlpha2LetterCode}
			      			showStateDropdown={this.props.showStateDropdown}
			      		/>
			    	)
			    }}
			  </ElementsConsumer>
		)
	}
}


@inject('signUpStore', 'appStore')
@observer
class RegistrationSignUp extends Component{
	constructor(props){
		super(props);
		this.onChangeFullName = this.onChangeFullName.bind(this);
		this.onChangeOccupation = this.onChangeOccupation.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeConfirmEmail = this.onChangeConfirmEmail.bind(this);
		this.onChangeCurrency = this.onChangeCurrency.bind(this);
		this.onChangeCountry = this.onChangeCountry.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
		this.onChangeDiscountCode = this.onChangeDiscountCode.bind(this);
		this.onApplyDiscountCode = this.onApplyDiscountCode.bind(this);
		this.onChangeSubscriptionPeriod = this.onChangeSubscriptionPeriod.bind(this);
		this.onToggleTermsAndConditions = this.onToggleTermsAndConditions.bind(this);
		this.onChangePlan = this.onChangePlan.bind(this);
		this.setSubscribing = this.setSubscribing.bind(this);
		this.onPaymentMethod = this.onPaymentMethod.bind(this);
		this.onPaymentMethodFailed = this.onPaymentMethodFailed.bind(this);
		this.onPaymentMethodSuccess = this.onPaymentMethodSuccess.bind(this);
		this.onPaymentMethodSuccessWithPaymentIntent = this.onPaymentMethodSuccessWithPaymentIntent.bind(this);
		this.discoverMore = this.discoverMore.bind(this);
		this.onCompleteStep1 = this.onCompleteStep1.bind(this);
		this.onCompleteStep2 = this.onCompleteStep2.bind(this);
		this.onTerms = this.onTerms.bind(this);
		this.onReferral = this.onReferral.bind(this);
	}

	componentDidMount(){
		let appStore = this.props.appStore;
		if(appStore.isAuthenticated){
			this.props.appStore.goToHome();
		}else{
			let signUpStore = this.props.signUpStore;

			if('currencyId' in this.props && 'planId' in this.props && 'period' in this.props){
				signUpStore.syncInformationFromUrl(this.props.currencyId, this.props.period, 
					this.props.planId, this.props.username, this.props.step,
					this.props.country, this.props.profession);
			}else{
				this.props.appStore.alertError('Missing information on sign up');
				this.props.appStore.goToHome();
			}
		}
	}

	componentWillUnmount(){
		let signUpStore = this.props.signUpStore;
		signUpStore.initStore();
	}

	onPaymentMethod(paymentMethod){
		let signUpStore = this.props.signUpStore;
		// Return promise to handle intent/secrets
		return signUpStore.subscribe(paymentMethod);
	}

	onPaymentMethodFailed(){
		let signUpStore = this.props.signUpStore;
		signUpStore.subscriptionFailed();
	}

	onPaymentMethodSuccess(){
		let signUpStore = this.props.signUpStore;
		signUpStore.markSubscribed();
	}

	onPaymentMethodSuccessWithPaymentIntent(paymentIntent){
		let signUpStore = this.props.signUpStore;
		signUpStore.subscribedWithPaymentIntent(paymentIntent);
	}

	onChangeFullName(e){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeFullName(e.target.value);
	}

	onChangeOccupation(e){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeOccupation(e.target.value);
	}

	onChangeEmail(e){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeEmail(e.target.value);
	}	

	onChangeConfirmEmail(e){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeConfirmEmail(e.target.value);
	}	

	onChangeCurrency(option){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeCurrency(option.value);
	}

	onChangeCountry(option){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeCountry(option.value);
	}

	onChangePassword(e){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangePassword(e.target.value);
	}

	onChangeConfirmPassword(e){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeConfirmPassword(e.target.value);
	}

	onChangeDiscountCode(val){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeDiscountCode(val);
	}

	onChangeSubscriptionPeriod(val){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangeSubscriptionPeriod(val);
	}

	onToggleTermsAndConditions(){
		let signUpStore = this.props.signUpStore;
		signUpStore.onToggleTermsAndConditions();
	}

	onChangePlan(value){
		let signUpStore = this.props.signUpStore;
		signUpStore.onChangePlan(value);
	}

	onApplyDiscountCode(){
		let signUpStore = this.props.signUpStore;
		signUpStore.applyDiscountCode();
	}

	setSubscribing(){
		let signUpStore = this.props.signUpStore;
		signUpStore.setSubscribing();
	}

	discoverMore(){
		let appStore = this.props.appStore;
		appStore.goToPickAPlan();
	}

	onCompleteStep1(){
		let signUpStore = this.props.signUpStore;
		signUpStore.onCompleteStep1();
	}

	onCompleteStep2(){
		let signUpStore = this.props.signUpStore;
		signUpStore.onCompleteStep2();
	}

	onReferral(referrer){
		let signUpStore = this.props.signUpStore;
		signUpStore.onReferral(referrer);
	}

	onTerms(){
		let signUpStore = this.props.signUpStore;
		signUpStore.appStore.goToTerms();
	}


	render(){
		let translate = this.props.t;
		let signUpStore = this.props.signUpStore;
		if(signUpStore.currentPlan == null) return null;
		let currentPlan = signUpStore.currentPlan;
		let planName = currentPlan.name.split(' ');
		let lastWord = planName.pop();
		let renderLeftView = (
			<div className="flex flex-col flex-1 justify-center">
				<div className="flex flex-col">
					<Body className="text-center text-black lg:text-left mt-8">{translate('registration.indicator')}</Body>
					<SecondaryWebLargerTitle className="text-center text-black lg:text-left">{planName} <br /> <span className={`uppercase text-${currentPlan.color}`}>{lastWord}</span></SecondaryWebLargerTitle>
				</div>
				<div className="flex flex-col lg:mt-16">
					<Body className="text-black">{currentPlan.short_description}</Body>
					<SecondaryWebLargerTitle className="text-black">{currentPlan.periodValue}<SecondaryWebSmallTitle>/{currentPlan.period}</SecondaryWebSmallTitle></SecondaryWebLargerTitle>
				</div>
			</div>
		)
		if(signUpStore.step == 1){
			return (
				<>
					<LeftBlockRightContent
						leftBgColor={`${currentPlan.color}-25`}
						left={renderLeftView}
						right={
							<div className="flex flex-1 flex-col justify-between mb-24">
								<div className="flex flex-col"></div>
								<div className="flex flex-col">
									<SecondaryWebMediumTitle className="px-2">{translate("registration.step1.title")}</SecondaryWebMediumTitle>
									<div className="mt-8 lg:mt-16"></div>
									<HorizontalFormColumn>
										<Label>{translate("registration.step1.subscription.label")}</Label>
										<Select 
											options={signUpStore.subscriptionOptions} 
											value={signUpStore.subscriptionPeriod} 
											onChange={(option) => this.onChangeSubscriptionPeriod(option.value)}
										/>
									</HorizontalFormColumn>
									<HorizontalFormColumn>
										<Label>{translate("registration.step1.currency.label")}</Label>
										<Select 
											options={signUpStore.currencyOptions}
											value={signUpStore.selectedCurrency}
											onChange={this.onChangeCurrency} />
									</HorizontalFormColumn>
									<HorizontalFormColumn>
										<Label>{translate("registration.step1.topay.label")}</Label>
										<Body className="base-input">{signUpStore.planDescription}</Body>
									</HorizontalFormColumn>
									<div className="mt-8 lg:mt-24"></div>
										
								</div>
								<div className="flex flex-col">
									<Button
										 bgColor="black"
										 width="w-full"
										 fgColor="white"
										 onClick={this.onCompleteStep1}>{translate("registration.step1.button")}</Button>
								</div>
							</div>
						}
					/>
				</>
			)
		}
		if(signUpStore.step == 2){
			return (
				<>
					<LeftBlockRightContent
						leftBgColor={`${currentPlan.color}-25`}
						left={renderLeftView}
						right={
							<div className="flex flex-1 flex-col justify-between mb-24">
								<div className="flex flex-col"></div>
								<div className="flex flex-col">
									<SecondaryWebMediumTitle className="px-2">{translate("registration.step2.title")}</SecondaryWebMediumTitle>
									<div className="mt-8 lg:mt-16"></div>
									<HorizontalFormColumn>
										<Label required>{translate('registration.step2.full-name.label')}</Label>
										<TextInput 
											placeholder={translate('registration.step2.full-name.placeholder')}
											onChange={this.onChangeFullName}
											value={signUpStore.fullName.value} 
											error={signUpStore.fullName.error}  />
									</HorizontalFormColumn>
									<div className="flex flex-1 flex-col lg:flex-row lg:flex-wrap lg:items-center">
										<div className="flex flex-1 flex-col">
											<Label required>{translate("registration.step2.country.label")}</Label>
											<Select 
												placeholder={translate('registration.step2.country.placeholder')}
												options={signUpStore.countryOptions}
												value={signUpStore.selectedCountry}
												onChange={this.onChangeCountry} />
										</div>
										<div className="flex flex-1 flex-col lg:ml-6">
											<Label>{translate('registration.step2.occupation.label')}</Label>
											<TextInput 
												onChange={this.onChangeOccupation}
												value={signUpStore.occupation.value}
											/>
										</div>
									</div>
									<HorizontalFormColumn>
										<Label required>{translate('registration.step2.email.label')}</Label>
										<TextInput 
											placeholder={translate('registration.step2.email.placeholder')}
											onChange={this.onChangeEmail}
											value={signUpStore.email.value}
											error={signUpStore.email.error}
										/>
									</HorizontalFormColumn>
									<HorizontalFormColumn>
										<Label required>{translate('registration.step2.confirm-email.label')}</Label>
										<TextInput 
											placeholder={translate('registration.step2.confirm-email.placeholder')}
											onChange={this.onChangeConfirmEmail}
											value={signUpStore.confirmEmail.value}
											error={signUpStore.confirmEmail.error}
										/>
									</HorizontalFormColumn>
									<HorizontalFormColumn>
										<Label required>{translate('registration.step2.password.label')}</Label>
										<PasswordInput 
											placeholder={translate('registration.step2.password.placeholder')}
											onChange={this.onChangePassword}
											value={signUpStore.password.value}
											error={signUpStore.password.error}
										/>
									</HorizontalFormColumn>
									<HorizontalFormColumn>
										<Label required>{translate('registration.step2.confirm-password.label')}</Label>
										<PasswordInput 
											placeholder={translate('registration.step2.confirm-password.placeholder')}
											onChange={this.onChangeConfirmPassword}
											value={signUpStore.confirmPassword.value}
											error={signUpStore.confirmPassword.error}
										/>
									</HorizontalFormColumn>
								</div>
								
								<div className="flex flex-col">
									<div className="flex flex-1 flex-col">
										<SmallBody>{translate('registration.step2.required-fields')}</SmallBody>
										<div className="mt-4"></div>
										<Button
										 width="w-full"
										 bgColor="black"
										 fetching={signUpStore.fetching}
										 onClick={this.onCompleteStep2}
										 disabled={!signUpStore.isValidForm}>{translate('registration.step2.button')}</Button>
									</div>
								</div>
							</div>
						}
					/>
				</>
			)
		}

		if(signUpStore.step == 3){
			const {stripe} = this.props;

			return (
				<>
					<LeftBlockRightContent
						leftBgColor={`${currentPlan.color}-25`}
						left={
							<div className="flex flex-col flex-1 justify-center">
								<div className="flex flex-col">
									<Body className="text-center text-black lg:text-left mt-8">{translate('registration.indicator')}</Body>
									<SecondaryWebLargerTitle className="text-center text-black lg:text-left">{planName} <br /> <span className={`uppercase text-${currentPlan.color}`}>{lastWord}</span></SecondaryWebLargerTitle>
								</div>
								<div className="flex flex-col lg:mt-16">
									<SecondaryWebSmallTitle className="text-black">{translate('registration.order.title')}</SecondaryWebSmallTitle>
								</div>
								<div className="flex w-full flex-row justify-between mt-3">
									<div className="flex"><Body className="text-black mr-2">{translate('registration.order.subtotal')}</Body></div>
									<div className="flex w-full items-end lg:items-start text-left mr-2">
										<Body className="text-black">{currentPlan.currency.symbol}{signUpStore.currentPlanPeriodValue}</Body>
									</div>
								</div>
								<div className="flex w-full flex-row justify-between mt-3">
									<div className="flex"><SecondaryWebSmallTitle className="text-black bold-font mr-2">{translate('registration.order.total')}</SecondaryWebSmallTitle></div>
									<div className="flex w-full items-end lg:items-start text-left">
										<SecondaryWebSmallTitle className="text-black">{currentPlan.currency.symbol}{signUpStore.currentPlanPeriodValue} <span className="regular-font">/ {currentPlan.displayPeriod}</span></SecondaryWebSmallTitle>
									</div>
								</div>
							</div>
						}
						right={
							<Elements 
								stripe={stripePromise}
								options={{
							        fonts: [
							          {
							            family: "Proxima Nova Alt Regular",
							            src: `url(https://labaroma-production.s3-eu-west-1.amazonaws.com/fonts/ProximaNovaAlt-Regular.woff)`,
							            weight: "normal",
							          },
							        ],
							      }}
								>
								<InjectedCheckoutForm
									t={translate}
									appStore={this.props.appStore}
									color="black"
									setSubscribing={this.setSubscribing}
									email={signUpStore.email.value}
									onChangeDiscountCode={this.onChangeDiscountCode}
					      			discountCode={signUpStore.discountCode}
					      			addressLine1={signUpStore.addressLine1}
					      			onChangeAddressLine1={(val) => signUpStore.addressLine1.onChange(val)}
					      			addressLine2={signUpStore.addressLine2}
					      			onChangeAddressLine2={(val) => signUpStore.addressLine2.onChange(val)}
					      			addressCity={signUpStore.addressCity}
					      			onChangeAddressCity={(val) => signUpStore.addressCity.onChange(val)}
					      			addressState={signUpStore.addressState}
					      			onChangeAddressState={(val) => signUpStore.addressState.onChange(val)}
					      			addressZip={signUpStore.addressZip}
					      			onChangeAddressZip={(val) => signUpStore.addressZip.onChange(val)}
					      			onApplyDiscountCode={this.onApplyDiscountCode}
					      			onToggleTermsAndConditions={this.onToggleTermsAndConditions}
					      			termsAndConditions={signUpStore.termsAndConditions}
					      			planDescription={signUpStore.planDescription}
					      			onSubmit={this.props.onSubmit}
					      			subscribing={signUpStore.subscribing}
					      			canPlaceOrder={signUpStore.canPlaceOrder}
					      			onPaymentMethod={this.onPaymentMethod}
					      			onPaymentMethodSuccess={this.onPaymentMethodSuccess}
					       			onPaymentMethodSuccessWithPaymentIntent={this.onPaymentMethodSuccessWithPaymentIntent}
					       			onPaymentMethodFailed={this.onPaymentMethodFailed}
					       			onTerms={this.onTerms}
					       			payToday={`${signUpStore.currentPlan.currency.symbol}${signUpStore.currentPlanPeriodValue}`}
					       			showOptions={false}
					       			planOptions={signUpStore.planOptions}
					      			selectedPlan={signUpStore.selectedPlan}
					      			onChangePlan={this.onChangePlan}
					      			currencyOptions={signUpStore.currencyOptions}
					      			selectedCurrency={signUpStore.selectedCurrency}
					      			onChangeCurrency={this.onChangeCurrency}
					      			onChangeSubscriptionPeriod={this.onChangeSubscriptionPeriod}
					      			subscriptionPeriod={signUpStore.subscriptionPeriod}
					      			subscriptionOptions={signUpStore.subscriptionOptions}
					      			onReferral={this.onReferral}
					      			taxId={signUpStore.taxId}
					      			onChangeTaxId={(val) => signUpStore.onChangeTaxId(val)}
					      			cardHolderName={signUpStore.cardHolderName}
			      					onChangeCardHolderName={(val) => signUpStore.cardHolderName.onChange(val)}
			      					countryOptions={signUpStore.countryOptions}
			      					selectedBillingCountry={signUpStore.selectedBillingCountry}
			      					onChangeBillingCountry={(option) => signUpStore.selectedBillingCountry = option?.value}
			      					billingCountryIsoAlpha2LetterCode={signUpStore.billingCountryIsoAlpha2LetterCode}
			      					showStateDropdown={signUpStore.showStateDropdown}
			      					onChangeState={(option) => signUpStore.selectedState = option?.value}
			      					selectedState={signUpStore.selectedState}
			      					stateOptions={signUpStore.stateOptions()}
								 />
							</Elements>
						}
					/>
				</>
			)
		}
		
	}
}

export default withTranslation()(RegistrationSignUp);