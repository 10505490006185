import React, {Component} from 'react';
import {observer} from 'mobx-react';

import facebookIconSrc from '../../assets/img/icons/facebook.svg';
import twitterIconSrc from '../../assets/img/icons/twitter.svg';
import instagramIconSrc from '../../assets/img/icons/instagram.svg';
import podcastIconSrc from '../../assets/img/icons/itunes-pod.svg';
import youtubeIconSrc from '../../assets/img/icons/youtube.svg';
import spotifyIconSrc from '../../assets/img/icons/spotify.svg';

import bellIconSrc from '../../assets/img/icons/notification.svg';
import underscoreIconSrc from '../../assets/img/icons/underscore.svg';

import visaIconSrc from '../../assets/img/icons/visa.svg';
import masterCardIconSrc from '../../assets/img/icons/mastercard.svg';
import amexCardIconSrc from '../../assets/img/icons/amex.svg';
import genericCardIconSrc from '../../assets/img/icons/generic.svg';

import ukIconSrc from '../../assets/img/icons/uk.svg';
import franceIconSrc from '../../assets/img/icons/france.svg';
import portugalIconSrc from '../../assets/img/icons/portugal.svg';
import japanIconSrc from '../../assets/img/icons/japan.svg';

import dotsIconSrc from '../../assets/img/icons/dots.svg';

import drawIconSrc from '../../assets/img/icons/draw.svg';
import blendingIconSrc from '../../assets/img/icons/blending.svg';
import blendingTubIconSrc from '../../assets/img/icons/blending.svg';
import envelopeSwooshSrc from '../../assets/img/icons/envelope-swoosh.svg';
import downloadIconSrc from '../../assets/img/icons/download.svg';

import blendLibraryBlueIconSrc from '../../assets/img/icons/blend-library-blue.svg'
import blendLibraryPinkIconSrc from '../../assets/img/icons/blend-library-pink.svg'
import bookColorIconSrc from '../../assets/img/icons/book-color.svg'
import bottleIconSrc from '../../assets/img/icons/blend-bottle.svg'
import chemicalComponentSearchIconSrc from '../../assets/img/icons/chemical-component-search.svg'
import compareIngredientsColorIconSrc from '../../assets/img/icons/compare-ingredients-color.svg'
import compareIngredientsAddIconSrc from '../../assets/img/icons/compare-ingredients-add.svg'
import createNewBlendIconSrc from '../../assets/img/icons/create-new-blend.svg'
import drawColorBlueIconSrc from '../../assets/img/icons/draw-color-blue.svg'
import drawColorPinkIconSrc from '../../assets/img/icons/draw-color-pink.svg'
import editBlendIconSrc from '../../assets/img/icons/edit-blend.svg'
import editIngredientIconSrc from '../../assets/img/icons/edit-ingredient.svg'
import editIngredientLineIconSrc from '../../assets/img/icons/edit-ingredient-line.svg'
import evidenceSearchIconSrc from '../../assets/img/icons/evidence-search.svg'
import handWebsiteIconSrc from '../../assets/img/icons/hands-website.svg'
import libraryColorBlueIconSrc from '../../assets/img/icons/library-color-blue.svg'
import libraryColorPinkIconSrc from '../../assets/img/icons/library-color-pink.svg'
import salesUpdateIconSrc from '../../assets/img/icons/sales-update.svg'
import scentNoteIconSrc from '../../assets/img/icons/scent-note.svg';
import saveIconSrc from '../../assets/img/icons/save.svg';
import supportCenterColorIconSrc from '../../assets/img/icons/support-centre-color.svg';
import testTubeColorIconSrc from '../../assets/img/icons/test-tube-color.svg';
import upgradeIconSrc from  '../../assets/img/icons/upgrade.svg';
import upgradeConfirmationIconSrc from  '../../assets/img/icons/upgrade-confirmation.svg';
import viewBlendColorIconSrc from '../../assets/img/icons/view-blend-color.svg';



class CountryIcon extends Component{
	render(){
		let iconSrc = null;
		switch(this.props.country){
			case 'pt':
				iconSrc = portugalIconSrc;
				break;
			case 'fr':
				iconSrc = franceIconSrc;
				break;
			case 'jp':
				iconSrc = japanIconSrc;
				break;
			case 'en':
			default:
				iconSrc = ukIconSrc;
		}
		return (
			<img className={this.props.className} src={iconSrc} alt="Country Icon" />
		)
	}
}

class LibraryBookIcon extends Component{
	render(){
		let iconSrc = null;
		switch(this.props.color){
			case 'pink':
				iconSrc = libraryColorPinkIconSrc;
				break;
			case 'blue':
			default:
				iconSrc = libraryColorBlueIconSrc;
		}
		return (
			<img className={this.props.className} src={iconSrc} alt="Library Books" />
		)
	}
}

class BlendBottlesIcon extends Component{
	render(){
		let iconSrc = null;
		switch(this.props.color){
			case 'pink':
				iconSrc = blendLibraryPinkIconSrc;
				break;
			case 'blue':
			default:
				iconSrc = blendLibraryBlueIconSrc;
		}
		return (
			<img className={this.props.className} src={iconSrc} alt="Blend Bottles" />
		)
	}
}

class BottleIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={bottleIconSrc} alt="Blend Bottle" />
		)
	}
}

class EditIngredientIcon extends Component{
	render(){
		let iconSrc = null;
		switch(this.props.color){
			case 'transparent':
				iconSrc = editIngredientLineIconSrc;
				break;
			default:
				iconSrc = editIngredientIconSrc;
		}
		return (
			<img className={this.props.className} src={iconSrc} alt="Edit ingredient" />
		)
	}
}


class CreditCardIcon extends Component{
	render(){
		let iconSrc = null;
		switch(this.props.brand){
			case 'visa':
				iconSrc = visaIconSrc;
				break;
			case 'mastercard':
				iconSrc = masterCardIconSrc;
				break;
			case 'amex':
				iconSrc = amexCardIconSrc;
				break;
			default:
				iconSrc = genericCardIconSrc
		}
		return (
			<img className={this.props.className} src={iconSrc} alt="Credit Card Icon" />
		)
	}
}


class SocialMediaIcon extends Component{
	render(){
		return <img onClick={this.props.onClick} className="cursor-pointer w-8 h-8" src={this.props.src} alt={this.props.alt} />
	}
}

class SentIcon extends Component{
	render(){
		return <img className={`cursor-pointer ${this.props.className}`} src={envelopeSwooshSrc} alt={this.props.alt} />
	}
}

class DownloadIcon extends Component{
	render(){
		return <img className={`cursor-pointer ${this.props.className}`} src={downloadIconSrc} alt={this.props.alt} />
	}
}

class TickIcon extends Component{
	render(){
		let className = "cursor-pointer";
		if(this.props.className != null){
			className = `${className} ${this.props.className}`
		}else{
			className = `${className} w-8 h-8`
		}
		let color = this.props.color != null ? this.props.color : 'black';
		return (
		    <svg data-name="Layer 1" viewBox="0 0 19.5 13.5" className={className} {...this.props}>
		      <title>{"Tick"}</title>
		      <path
		        fill="none"
		        stroke={color}
		        strokeLinecap="round"
		        strokeLinejoin="round"
		        strokeWidth={1.5}
		        d="M18.75.75l-12 12-6-6"
		      />
		    </svg>
		  )
	}
}

class WarningIcon extends Component{
	render(){
		return (
		    <svg data-name="Layer 1" viewBox="0 0 21.3 21.3" {...this.props}>
		      <title>{"Error"}</title>
		      <path
		        d="M10.71 15.09a.71.71 0 10.71.71.71.71 0 00-.71-.71z"
		        fill={this.props.color}
		      />
		      <path
		        strokeLinejoin="round"
		        fill="none"
		        stroke={this.props.color}
		        strokeLinecap="round"
		        strokeWidth={1.5}
		        d="M10.71 5.55v7.49"
		      />
		      <circle
		        cx={71.1}
		        cy={71.1}
		        r={9.9}
		        transform="rotate(-45.26 -31.625 113.381)"
		        strokeMiterlimit={10}
		        fill="none"
		        stroke={this.props.color}
		        strokeLinecap="round"
		        strokeWidth={1.5}
		      />
		    </svg>
		  )
	}
}

class DrawIcon extends Component{
	render(){
		return <img className={this.props.className} src={drawIconSrc} alt="Draw Icon" />
	}
}

class DrawPinkIcon extends Component{
	render(){
		return <img className={this.props.className} src={drawColorPinkIconSrc} alt="Draw Icon" />
	}
}

class DrawBlueIcon extends Component{
	render(){
		return <img className={this.props.className} src={drawColorBlueIconSrc} alt="Draw Icon" />
	}
}

class OpenHandIcon extends Component{
	render(){
		return <img className={this.props.className} src={upgradeConfirmationIconSrc} alt="Open Hand with Leaf Icon" />
	}
}

class BlendingIcon extends Component{
	render(){
		return <img className={this.props.className} src={blendingIconSrc} alt="Blending Icon" />
	}
}

class BlendingTubIcon extends Component{
	render(){
		return <img className={this.props.className} src={blendingTubIconSrc} alt="Blending Tub" />
	}
}

class TestTubeIcon extends Component{
	render(){
		return <img className={this.props.className} src={testTubeColorIconSrc} alt="Test Tube" />
	}
}

class UnderScoreIcon extends Component{
	render(){
		return <img className="cursor-pointer w-8 h-8" src={underscoreIconSrc} alt="Tick"/>
	}
}

class FacebookIcon extends Component{
	render(){
		return (
			<SocialMediaIcon onClick={this.props.onClick} src={facebookIconSrc} alt="LabAroma on Facebook" />
		)
	}
}

class TwitterIcon extends Component{
	render(){
		return (
			<SocialMediaIcon onClick={this.props.onClick} src={twitterIconSrc} alt="LabAroma on Twitter" />
		)
	}
}

class SpotifyIcon extends Component{
	render(){
		return (
			<SocialMediaIcon onClick={this.props.onClick} src={spotifyIconSrc} alt="LabAroma on Spotify" />
		)
	}
}

class YouTubeIcon extends Component{
	render(){
		return (
			<SocialMediaIcon onClick={this.props.onClick} src={youtubeIconSrc} alt="LabAroma on YouTube" />
		)
	}
}


class InstagramIcon extends Component{
	render(){
		return (
			<SocialMediaIcon onClick={this.props.onClick} src={instagramIconSrc} alt="LabAroma on Instagram" />
		)
	}
}


class PodcastIcon extends Component{
	render(){
		return (
			<SocialMediaIcon onClick={this.props.onClick} src={podcastIconSrc} alt="LabAroma on Podcast" />
		)
	}
}

class DashboardIcon extends Component{
	render(){
		return (
		    <svg
		      className="w-5 h-5"
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 21.5 18.8"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-dashboard{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Dashboard"}</title>
		      <path className="prefix__cls-dashboard" d="M17.75 6.45v11.6h-14V6.45" />
		      <path
		        className="prefix__cls-dashboard"
		        d="M.75 8.75l10-8 10 8M8.35 18.05v-8h4.8v8"
		      />
		    </svg>
		  )
	}
}

class BarIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 17.5 12.7"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-bar{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"List"}</title>
		      <path className="prefix__cls-bar" d="M.75 6.35h16M.75 11.95h16M.75.75h16" />
		    </svg>
		  )
	}
}

class PeopleIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 27.65 20.9"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-users{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Users"}</title>
		      <circle className="prefix__cls-users" cx={18.5} cy={5.6} r={4.85} />
		      <path
		        className="prefix__cls-users"
		        d="M79.48 69.3a9.69 9.69 0 00-8.4 4.85 9.7 9.7 0 0016.8 0 9.69 9.69 0 00-8.4-4.85z"
		        transform="translate(-60.99 -58.85)"
		      />
		      <circle className="prefix__cls-users" cx={6.03} cy={7.4} r={3.05} />
		      <path
		        className="prefix__cls-users"
		        d="M67 69.3a6.09 6.09 0 00-5.28 3 6.1 6.1 0 0010.56 0 6.09 6.09 0 00-5.28-3z"
		        transform="translate(-60.99 -58.85)"
		      />
		    </svg>
		  )
	}
}


class FilterIcon extends Component{
	render(){
		return (
		    <svg data-name="Layer 1" viewBox="0 0 17.31 23" {...this.props}>
		      <title>{"Blend"}</title>
		      <path
		        fill="none"
		        stroke={this.props.color}
		        strokeLinejoin="round"
		        strokeWidth={1.5}
		        d="M.75.75h15.81L10.42 12.4l-.09 7.95-3.53 1.9V12.4L.75.75z"
		      />
		    </svg>
		  )
	}
}

class FilterDialIcon extends Component{
	render(){
		 return (
		    <svg
		      id="prefix__Layer_filter_dial"
		      data-name="Layer filter-dial"
		      viewBox="0 0 18.3 17.46"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-filter-dial{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Filter"}</title>
		      <path className="prefix__cls-filter-dial" d="M7.05 2.85h10.5" />
		      <circle className="prefix__cls-filter-dial" cx={4.95} cy={2.85} r={2.1} />
		      <path className="prefix__cls-filter-dial" d="M.75 2.85h2.1M7.05 14.61h10.5" />
		      <circle className="prefix__cls-filter-dial" cx={4.95} cy={14.61} r={2.1} />
		      <path className="prefix__cls-filter-dial" d="M.75 14.61h2.1M11.25 8.76H.75" />
		      <circle className="prefix__cls-filter-dial" cx={13.35} cy={8.76} r={2.1} />
		      <path className="prefix__cls-filter-dial" d="M17.55 8.76h-2.1" />
		    </svg>
		  )
	}
}

class DropIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 14.71 21.5"
		      {...this.props}
		    >
		      <defs>
		        <clipPath id="prefix__clip-path" transform="translate(-64 -58.5)">
		          <path fill="none" d="M64 58.5h14.71V80H64z" />
		        </clipPath>
		        <style>
		          {
		            `.prefix__cls-drop{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Blend"}</title>
		      <g clipPath="url(#prefix__clip-path)">
		        <path
		          className="prefix__cls-drop"
		          d="M71.35 59.25c-3.92 6.06-6.6 9.75-6.6 13.4a6.6 6.6 0 1013.21 0c.04-3.65-2.68-7.34-6.61-13.4zM72.52 74.55a2.4 2.4 0 01-3.52-2.1"
		          transform="translate(-64 -58.5)"
		        />
		      </g>
		    </svg>
		  )
	}
}

class LibraryIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 18.17 13.6"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-library-1{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.5px}.prefix__cls-library-2{fill:${this.props.color}}`
		          }
		        </style>
		      </defs>
		      <title>{"Blend library"}</title>
		      <path
		        className="prefix__cls-library-1"
		        d="M5.42 6.8h12M5.42 12.4h12M5.42 1.2h12"
		      />
		      <path
		        className="prefix__cls-library-2"
		        d="M62.89 64.27a1.2 1.2 0 101.2 1.2 1.2 1.2 0 00-1.2-1.2zM62.89 69.85a1.2 1.2 0 101.2 1.2 1.2 1.2 0 00-1.2-1.2zM62.89 75.47a1.2 1.2 0 101.2 1.2 1.2 1.2 0 00-1.2-1.2z"
		        transform="translate(-61.69 -64.27)"
		      />
		    </svg>
		  )
	}
}


class GridIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 15.5 15.5"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-grid{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Chemical Comp"}</title>
		      <path
		        className="prefix__cls-grid"
		        d="M9.15.75h5.6v5.6h-5.6zM.75.75h5.6v5.6H.75zM9.15 9.15h5.6v5.6h-5.6zM.75 9.15h5.6v5.6H.75z"
		      />
		    </svg>
		  )
	}
}


class ChemicalFamilyIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 13.41 18.32"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-chemical-family{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-width:1.5px;stroke-miterlimit:10}`
		          }
		        </style>
		      </defs>
		      <title>{"Chemical family"}</title>
		      <path
		        strokeLinejoin="round"
		        fill="none"
		        stroke={this.props.color}
		        strokeLinecap="round"
		        strokeWidth={1.5}
		        d="M.754.766l11.88-.02.03 16.8-11.88.02z"
		      />
		      <path
		        className="prefix__cls-chemical-family"
		        d="M3.9 5.54l5.6-.01M3.9 8.34l5.6-.01M3.91 11.14h2.8"
		      />
		    </svg>
		  )
	}
}

class ChemistryIcon extends Component{
	render(){
		return (
		    <svg width={20} height={23} viewBox="0 0 20 23" {...this.props}>
		      <title>{"Group 5"}</title>
		      <g stroke={this.props.color} strokeWidth={1.5} fill="none" fillRule="evenodd">
		        <path d="M14.734 2.574l4.516 2.693v5.384l-4.603 2.693-4.603-2.779V5.267L5.44 2.574.75 5.353v5.212l4.69 2.779v5.472l4.517 2.692 4.516-2.519.26-16.415zM5.31 13.387L10 10.608l-4.69 2.779zm11.682 4.777a1.346 1.346 0 11-2.693.002 1.346 1.346 0 012.693-.002zM14.82 2.096a1.346 1.346 0 11-2.692 0 1.346 1.346 0 012.692 0z" />
		        <path
		          strokeLinecap="round"
		          strokeLinejoin="round"
		          d="M3.784 6.63l1.634-.972 1.591.928"
		        />
		      </g>
		    </svg>
		  )
	}
}


class HelpIcon extends Component{
	render(){
		return (
		    <svg data-name="Layer 1" viewBox="0 0 21.3 21.3" {...this.props}>
		      <title>{"Question"}</title>
		      <path
		        d="M11.12 13.44v-1.89a2.43 2.43 0 10-2.43-2.43"
		        strokeLinejoin="round"
		        fill="none"
		        stroke={this.props.color}
		        strokeLinecap="round"
		        strokeWidth={1.5}
		      />
		      <path
		        d="M11.12 15.3a.64.64 0 10.64.64.64.64 0 00-.64-.64z"
		        fill={this.props.color}
		      />
		      <circle
		        cx={71.1}
		        cy={71.1}
		        r={9.9}
		        transform="rotate(-45.26 -31.625 113.381)"
		        strokeMiterlimit={10}
		        fill="none"
		        stroke={this.props.color}
		        strokeLinecap="round"
		        strokeWidth={1.5}
		      />
		    </svg>
		  )
	}
}

class HierarchyIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 23.63 21.53"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-hierarchy{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Organisation"}</title>
		      <path
		        className="prefix__cls-hierarchy"
		        d="M9.15.75h5.39v5.39H9.15zM9.15 15.39h5.39v5.39H9.15zM.75 15.39h5.39v5.39H.75zM17.49 15.39h5.39v5.39h-5.39zM11.85 6.38v9.01"
		      />
		      <path className="prefix__cls-hierarchy" d="M3.45 15.39V10.6H20.19v4.79" />
		    </svg>
		  )
	}
}

class BellIcon extends Component{
	render(){
		return (
			<img className={`cursor-pointer ${this.props.className}`} src={bellIconSrc} alt="Bell Icon" />
		)
	}
}

class SearchIcon extends Component{
	render(){
		let color = 'color' in this.props ? this.props.color : 'black';
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 17.72 17.5"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-search{fill:none;stroke:${color};stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Search"}</title>
		      <circle
		        className="prefix__cls-search"
		        cx={68.58}
		        cy={67.93}
		        r={6.2}
		        transform="rotate(-45 -35.845 111.838)"
		      />
		      <path className="prefix__cls-search" d="M16.97 16.75l-5.53-5.52" />
		    </svg>
		  )
	}
}

class ComponentSearchIcon extends Component{
	render(){
		let color = 'color' in this.props ? this.props.color : 'black';
		return (
		    <svg
		      id="prefix__Layer_componentSearch"
		      data-name="Layer componentSearch"
		      viewBox="0 0 17.72 17.5"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-componentSearch{fill:none;stroke:${color};stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Component Search"}</title>
		      <circle
		        className="prefix__cls-componentSearch"
		        cx={68.58}
		        cy={67.93}
		        r={6.2}
		        transform="rotate(-45 -35.845 111.838)"
		      />
		      <path className="prefix__cls-componentSearch" d="M16.97 16.75l-5.53-5.52" />
		    </svg>
		  )
	}
}

@observer
class ArrowRightIcon extends Component{
	render(){
		return <ArrowDownIcon color={this.props.color} className={`rotate-270 ${this.props.className}`} />
	}
}

@observer
class ArrowUpIcon extends Component{
	render(){
		return <ArrowDownIcon color={this.props.color} className={`rotate-180 ${this.props.className}`} />
	}
}

class ArrowLeftIcon extends Component{
	render(){
		return <ArrowDownIcon color={this.props.color} className={`rotate-90 ${this.props.className}`} />
	}
}

class ArrowDownIcon extends Component{
	render(){
		return (
			<svg width={16} height={10} viewBox="0 0 16 10" {...this.props}>
		      <title>{"Group 3"}</title>
		      <defs>
		        <path id="prefix__arrow-down" d="M0 0h15.6v9.75H0z" />
		      </defs>
		      <g fill="none" fillRule="evenodd">
		        <mask id="prefix__b-arrow-down" fill="#fff">
		          <use xlinkHref="#prefix__arrow-down" />
		        </mask>
		        <path
		          d="M7.8 9.75c-.346 0-.675-.146-.906-.403L.313 2.034A1.219 1.219 0 012.125.404L7.8 6.708 13.475.403a1.22 1.22 0 011.812 1.631L8.706 9.347c-.231.257-.56.403-.906.403"
		          fill={this.props.color}
		          mask="url(#prefix__b-arrow-down)"
		        />
		      </g>
		    </svg>
		)
	}
}

@observer
class PlayIcon extends Component{
	render(){
		return (
			<svg width={80} height={84} viewBox="0 0 80 84" {...this.props}>
		      <title>{"Group 3"}</title>
		      <defs>
		        <path id="prefix__a" d="M0 0h80v83.72H0z" />
		      </defs>
		      <g fill="none" fillRule="evenodd">
		        <mask id="prefix__b" fill="#fff">
		          <use xlinkHref="#prefix__a" />
		        </mask>
		        <path
		          d="M4.651 83.72a4.652 4.652 0 01-4.65-4.65V4.65A4.652 4.652 0 016.816.536l70.698 37.209a4.654 4.654 0 010 8.233L6.817 83.186a4.652 4.652 0 01-2.166.535zm4.651-71.364v59.009l56.06-29.504-56.06-29.505z"
		          fill="#231F20"
		          mask="url(#prefix__b)"
		        />
		      </g>
		    </svg>
		)
	}
}

@observer
class PlusIcon extends Component{
	render(){
		let className = 'className' in this.props ? this.props.className : "w-4 h-4 mr-2";
		return (
			<svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className={className}><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path></svg>
		)
	}
}

@observer 
class UploadIcon extends Component{
	render(){
		let iconClassName = this.props.className != null ? this.props.className : "w-4 h-4";
		return (
			 <svg
		      id="prefix__Layer_upload_1"
		      data-name="Layer 1"
		      viewBox="0 0 51.5 51.5"
		      {...this.props}
		    >
		      <defs>
		        <clipPath id="prefix__clip-upload-path" transform="translate(-44.88 -45.08)">
		          <path fill="none" d="M44 44.21h53v52.52H44z" />
		        </clipPath>
		        <style>
		          {
		            `.prefix__cls-upload-2{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px}`
		          }
		        </style>
		      </defs>
		      <title>{"Upload Large"}</title>
		      <path
		        className="prefix__cls-upload-2"
		        d="M71.11 67.82v17.76M62.9 64.24L71.16 55l8.27 9.21"
		        transform="translate(-44.88 -45.08)"
		      />
		      <g clipPath="url(#prefix__clip-upload-path)">
		        <circle
		          className="prefix__cls-upload-2"
		          cx={70.63}
		          cy={70.83}
		          r={24.75}
		          transform="rotate(-45.26 -5.884 102.122)"
		        />
		      </g>
		    </svg>
		)
	}
}

@observer
class ExitIcon extends Component{
	render(){
		let iconClassName = this.props.className != null ? this.props.className : "w-4 h-4";
		return (
			<svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className={iconClassName} {...this.props}><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
		)
	}
}

@observer
class EditIcon extends Component{
	render(){
		let elementId = this.props.id != null ? `prefix__Layer_editIcon_${this.props.id}` : "prefix__Layer_editIcon"; 
		let elementClassName = this.props.id != null ? `prefix__cls-editIcon_${this.props.id}`: "prefix__cls-editIcon";
		return (
		    <svg
		      id={elementId}
		      data-name="Layer 1"
		      viewBox="0 0 17.1 17.1"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.${elementClassName}{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Edit"}</title>
		      <path
		        className={elementClassName}
		        d="M3.33 13.77l.98-3.66 2.68 2.68-3.66.98z"
		      />
		      <path
		        className={elementClassName}
		        transform="rotate(45 115.943 -41.605)"
		        d="M73.57 60.67h3.79v11.05h-3.79z"
		      />
		      <path className={elementClassName} d="M7.05 3.75H.75v12.6h12.6v-6.3" />
		    </svg>
		  )
	}
}

@observer
class ShareIcon extends Component{
	render(){
		return (
			<svg
		      id="prefix__Layer_share"
		      data-name="Layer 1"
		      viewBox="0 0 21.62 22.33"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-share{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Share"}</title>
		      <circle className="prefix__cls-share" cx={4.06} cy={11.14} r={3.31} />
		      <circle className="prefix__cls-share" cx={17.56} cy={4.06} r={3.31} />
		      <circle className="prefix__cls-share" cx={17.56} cy={18.27} r={3.31} />
		      <path
		        className="prefix__cls-share"
		        d="M14.63 16.73L6.98 12.7M14.63 5.6L6.98 9.58"
		      />
		    </svg>
		)
	}
}

class ArchiveIcon extends Component{
	render(){
		let archiveClassName = `prefix__cls-archive`;
		if(this.props.id){
			archiveClassName = `${archiveClassName}-${this.props.id}`
		}
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 17.1 14.82"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.${archiveClassName}{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Folder"}</title>
		      <path
		        className={archiveClassName}
		        d="M.75 3.15h15.6v10.92H.75zM.75 3.15V.75h7.8v2.4"
		      />
		    </svg>
		  )
	}
}

class ViewIcon extends Component{
	render(){
		return (
			<svg data-name="Layer 1" viewBox="0 0 21.88 13.31" {...this.props}>
		      <title>{"View"}</title>
		      <path
		        d="M21.13 6.65c-11 13.3-20.38 0-20.38 0h0s9.38-13.29 20.38.01"
		        strokeLinecap="round"
		        strokeLinejoin="round"
		        fill="none"
		        stroke={this.props.color}
		        strokeWidth={1.5}
		      />
		      <circle
		        cx={10.94}
		        cy={6.66}
		        r={3.96}
		        strokeMiterlimit={10}
		        fill="none"
		        stroke={this.props.color}
		        strokeWidth={1.5}
		      />
		    </svg>
		 )
	}
}

class DuplicateIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 15.9 15.9"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-duplicate{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Blend categories"}</title>
		      <path className="prefix__cls-duplicate" d="M4.86 4.86h10.29v10.29H4.86z" />
		      <path className="prefix__cls-duplicate" d="M4.86 11.04H.75V.75h10.29v4.11" />
		    </svg>
  )
	}
}

class DeleteIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 11.82 19.9"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-delete{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Delete"}</title>
		      <path
		        className="prefix__cls-delete"
		        d="M.75 6.42h10.32v12.73H.75zM4.05 3.22V.75h3.72v2.47M.75 3.22h10.32M4.05 9.7v9.45M7.77 9.7v9.45"
		      />
		    </svg>
		  )
	}
}

class SortIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 20.7 15.73"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-sort{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Sort"}</title>
		      <path
		        className="prefix__cls-sort"
		        d="M4.58 9.03V.75M.75 10.69l3.85 4.29 3.86-4.29M16.12 6.71v8.27M19.95 5.04L16.1.75l-3.86 4.29"
		      />
		    </svg>
		  )
	}
}

class SaveIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 15.9 15.9"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-save{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Save"}</title>
		      <path className="prefix__cls-save" d="M.75.75h14.4v14.4H.75z" />
		      <path className="prefix__cls-save" d="M12.35.75v7.2h-8.8V.75M9.55.75v7.23" />
		    </svg>
		  )
	}
}

class EmailIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 18.13 13.5"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-email{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Email"}</title>
		      <path className="prefix__cls-email" d="M.75.75h16.63v12H.75z" />
		      <path className="prefix__cls-email" d="M17.38.75l-8.32 7.6L.75.75" />
		    </svg>
		  )
	}
}

class EnvelopeIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 17.5 17.12"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-envelope{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Sent"}</title>
		      <path
		        className="prefix__cls-envelope"
		        d="M.75 8.75l16-8-4.14 14.84-5.92-5.61L.75 8.75z"
		      />
		      <path className="prefix__cls-envelope" d="M16.75.75L6.69 9.98v6.38l3.03-3.51" />
		    </svg>
		  )
	}
}



class CogIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 21.5 21.5"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-cog{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Settings"}</title>
		      <path
		        className="prefix__cls-cog"
		        d="M81 66v-4h-2.26a7.94 7.94 0 00-.85-2.06l1.6-1.6-2.83-2.83-1.6 1.6a7.93 7.93 0 00-2.06-.85V54h-4v2.26a7.93 7.93 0 00-2.06.85l-1.6-1.6-2.83 2.83 1.6 1.6a7.94 7.94 0 00-.85 2.06H61v4h2.26a7.94 7.94 0 00.85 2.06l-1.6 1.6 2.83 2.83 1.6-1.6a8 8 0 002.06.85V74h4v-2.26a8 8 0 002.06-.85l1.6 1.6 2.83-2.83-1.6-1.6a7.94 7.94 0 00.85-2.06z"
		        transform="translate(-60.25 -53.25)"
		      />
		      <circle className="prefix__cls-cog" cx={10.75} cy={10.75} r={3} />
		    </svg>
		  )
	}
}

@observer
class CalendarIcon extends Component{
	render(){
		return (
			<svg width={80} height={84} viewBox="0 0 80 84" {...this.props}>
		      <title>{"Group 3"}</title>
		      <defs>
		        <path id="prefix__a" d="M0 0h80v83.72H0z" />
		      </defs>
		      <g fill="none" fillRule="evenodd">
		        <mask id="prefix__b" fill="#fff">
		          <use xlinkHref="#prefix__a" />
		        </mask>
		        <path
		          d="M4.651 83.72a4.652 4.652 0 01-4.65-4.65V4.65A4.652 4.652 0 016.816.536l70.698 37.209a4.654 4.654 0 010 8.233L6.817 83.186a4.652 4.652 0 01-2.166.535zm4.651-71.364v59.009l56.06-29.504-56.06-29.505z"
		          fill="#231F20"
		          mask="url(#prefix__b)"
		        />
		      </g>
		    </svg>
		)
	}
}

class BlendLibraryIcon extends Component{
	render(){
		return (
			<svg width={26} height={20} viewBox="0 0 26 20" {...this.props}>
		      <title>{"Group 9"}</title>
		      <g fill="none" fillRule="evenodd">
		        <path
		          d="M7.589 9.52h16.8m-16.8 7.84h16.8M7.589 1.68h16.8"
		          stroke={this.props.color}
		          strokeWidth={2.1}
		          strokeLinecap="round"
		        />
		        <path
		          d="M1.68 0a1.68 1.68 0 100 3.36 1.68 1.68 0 000-3.36M1.68 7.805a1.68 1.68 0 100 3.36 1.68 1.68 0 000-3.36M1.68 15.68a1.68 1.68 0 100 3.36 1.68 1.68 0 000-3.36"
		          fill={this.props.color}
		        />
		      </g>
		    </svg>
		)
	}
}

class GlobeIcon extends Component{
	render(){
		return (
			<svg data-name="Layer 1" viewBox="0 0 32 32" {...this.props}>
		      <defs>
		        <clipPath id="prefix__globe" transform="translate(-55 -55.73)">
		          <path fill="none" d="M55 55.73h32v32H55z" />
		        </clipPath>
		      </defs>
		      <title>{"Globe"}</title>
		      <g clipPath="url(#prefix__globe)">
		        <path
		          d="M31 16H1m15 15c4.85-4 7.8-9.22 7.8-15S20.8 5 16 1m-.15 0c-4.85 4-7.8 9.22-7.8 15s3 11 7.8 15M29.16 8.8H2.84m26.32 14.4H2.84M16 1v30m15-15A15 15 0 1116 1a15 15 0 0115 15z"
		          stroke={this.props.color}
		          strokeMiterlimit={10}
		          strokeWidth={2}
		          fill="none"
		        />
		      </g>
		    </svg>
		)
	}
}

class CompareIngredients extends Component{
	render(){
		let elementId = this.props.id != null ? `prefix__Layer_compareIngredients_${this.props.id}` : "prefix__Layer_compareIngredients"; 
		let elementClassName1 = this.props.id != null ? `prefix__cls-1-compare-ingredients_${this.props.id}` : "prefix__cls-1-compare-ingredients";
		let elementClassName2 = this.props.id != null ? `prefix__cls-2-compare-ingredients_${this.props.id}` : "prefix__cls-2-compare-ingredients";
		let elementClassName3 = this.props.id != null ? `prefix__cls-3-compare-ingredients_${this.props.id}` : "prefix__cls-3-compare-ingredients";
		let elementClassName4 = this.props.id != null ? `prefix__cls-4-compare-ingredients_${this.props.id}` : "prefix__cls-4-compare-ingredients";
		let elementClassName5 = this.props.id != null ? `prefix__cls-55-compare-ingredients_${this.props.id}` : "prefix__cls-5-compare-ingredients";
		return (
		    <svg
		      id={elementId}
		      data-name="Layer compareIngredients"
		      viewBox="0 0 21.45 21.4"
		      {...this.props}
		    >
		      <defs>
		        <clipPath id="prefix__clip-path-compare-ingredients" transform="translate(-62.9 -59.61)">
		          <path className="prefix__cls-1-compare-ingredients" d="M62.71 59h7.1v10.33h-7.1z" />
		        </clipPath>
		        <clipPath id="prefix__clip-path-2-compare-ingredients" transform="translate(-62.9 -59.61)">
		          <path className="prefix__cls-1-compare-ingredients" d="M77.5 65.02h7.1v10.33h-7.1z" />
		        </clipPath>
		        <style>
		          {
		            `.${elementClassName1},.${elementClassName3},.${elementClassName5}{fill:none}.${elementClassName3},.${elementClassName5}{stroke-linecap:round;stroke-width:1.5px}.${elementClassName3}{stroke:${this.props.color};stroke-linejoin:round}.${elementClassName5}{stroke:${this.props.color};stroke-miterlimit:10}`
		          }
		        </style>
		      </defs>
		      <title>{"Compare ingredients"}</title>
		      <g clipPath="url(#prefix__clip-path-compare-ingredients)">
		        <path
		          className={elementClassName3}
		          d="M66.23 60.58c-1.53 2.37-2.58 3.81-2.58 5.23a2.58 2.58 0 105.16 0c0-1.42-1.05-2.81-2.58-5.23z"
		          transform="translate(-62.9 -59.61)"
		        />
		      </g>
		      <g clipPath="url(#prefix__clip-path-2-compare-ingredients)">
		        <path
		          className={elementClassName3}
		          d="M81 66.61c-1.53 2.37-2.58 3.81-2.58 5.23a2.58 2.58 0 105.16 0c.02-1.42-1.03-2.84-2.58-5.23z"
		          transform="translate(-62.9 -59.61)"
		        />
		      </g>
		      <path
		        className={elementClassName5}
		        d="M3.57.75l7.23 3.1 7.24 3.1M10.58 20.43l-.07-16.58M2.53 20.65l16.54-.06"
		      />
		    </svg>
		  )
	}
}

class HandIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={supportCenterColorIconSrc} alt="Helping Hand" />
		)
	}
}


class RaisedHandIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={salesUpdateIconSrc} alt="Raised Hands" />
		)
	}
}

class CameraIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_camera"
		      data-name="Layer 1"
		      viewBox="0 0 24.67 20.5"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-camera{fill:none;stroke:${this.props.color};stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Camera"}</title>
		      <circle className="prefix__cls-camera" cx={12.34} cy={11.64} r={4.06} />
		      <path
		        className="prefix__cls-camera"
		        d="M16.04 3.53V.75H8.63v2.78H.75v16.22h23.17V3.53h-7.88z"
		      />
		    </svg>
		  )
	}
}

class EyeIcon extends Component{
	render(){
		return (
		    <svg data-name="Layer Eye" viewBox="0 0 21.88 13.31" {...this.props}>
		      <title>{"View"}</title>
		      <path
		        d="M21.13 6.65c-11 13.3-20.38 0-20.38 0h0s9.38-13.29 20.38.01"
		        strokeLinecap="round"
		        strokeLinejoin="round"
		        fill="none"
		        stroke={this.props.color}
		        strokeWidth={1.5}
		      />
		      <circle
		        cx={10.94}
		        cy={6.66}
		        r={3.96}
		        strokeMiterlimit={10}
		        fill="none"
		        stroke={this.props.color}
		        strokeWidth={1.5}
		      />
		    </svg>
		  )
	}
}

class StarIcon extends Component{
	render(){
		return (
		    <svg data-name="Layer star" viewBox="0 0 23.57 22.49" {...this.props}>
		      <title>{"Favorites"}</title>
		      <path
		        fill={'fill' in this.props ? this.props.fill: 'none'}
		        stroke={this.props.color}
		        strokeLinecap="round"
		        strokeLinejoin="round"
		        strokeWidth={1.5}
		        d="M11.79.75l2.6 8.02h8.43L16 13.72l2.61 8.02-6.82-4.95-6.82 4.95 2.6-8.02L.75 8.77h8.43L11.79.75z"
		      />
		    </svg>
		  )
	}
}

class ViewBlendIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={viewBlendColorIconSrc} alt="View Blend" />
		)
	}
}

class CreateNewBlend extends Component{
	render(){
		return (
			<img className={this.props.className} src={createNewBlendIconSrc} alt="Create Blend" />
		)
	}
}

class UpgradeIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={upgradeIconSrc} alt="Upgrade Icon" />
		)
	}
}

class ScentNoteIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={scentNoteIconSrc} alt="Scent Note Icon" />
		)
	}
}

class DropperIcon extends Component{
	render(){
		return (
			<svg viewBox="0 0 296.135 296.135" {...this.props}>
		      <path d="M284.5 11.635C276.997 4.132 267.021 0 256.411 0s-20.586 4.132-28.089 11.635l-64.681 64.68-6.658-6.658a18.868 18.868 0 00-11.59-5.466 19.019 19.019 0 00-5.438.26 18.879 18.879 0 00-9.786 5.206 18.95 18.95 0 00-4.165 6.273 18.986 18.986 0 00-1.389 7.134c0 1.213.116 2.426.347 3.621a18.863 18.863 0 005.206 9.786l4.319 4.319L21.468 213.811a14.975 14.975 0 00-3.316 5.025l-16.19 40.387c-3.326 8.298-2.338 17.648 2.644 25.013 5.04 7.451 13.356 11.899 22.244 11.899a26.92 26.92 0 0010.063-1.961l40.387-16.19a14.995 14.995 0 005.025-3.316l113.021-113.021 4.318 4.318a18.892 18.892 0 004.59 3.385 18.958 18.958 0 008.818 2.169c.029 0 .058-.004.087-.004a18.998 18.998 0 005.312-.777 18.835 18.835 0 008.009-4.773 18.866 18.866 0 004.772-8.009 19.036 19.036 0 000-10.797 18.868 18.868 0 00-4.772-8.009l-6.658-6.658 64.68-64.68c15.486-15.486 15.486-40.688-.002-56.177zM63.285 251.282l-30.764 12.331 12.332-30.763 110.848-110.848 18.432 18.432L63.285 251.282z" />
		    </svg>
		)
	}
}

class OpenBookIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={compareIngredientsColorIconSrc} alt="Open Book Icon" />
		)
	}
}

class AddIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={compareIngredientsAddIconSrc} alt="Add Icon" />
		)
	}
}


class ChemicalComponentSearchIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={chemicalComponentSearchIconSrc} alt="Search Chemical Component" />
		)
	}
}

class ColorBookIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={bookColorIconSrc} alt="Writing on a book" />
		)
	}
}

@observer
class ListIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 18.17 13.6"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-list-1{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.5px}.prefix__cls-list-2{fill:${this.props.color}}`
		          }
		        </style>
		      </defs>
		      <title>{"Blend library"}</title>
		      <path
		        className="prefix__cls-list-1"
		        d="M5.42 6.8h12M5.42 12.4h12M5.42 1.2h12"
		      />
		      <path
		        className="prefix__cls-list-2"
		        d="M62.89 64.27a1.2 1.2 0 101.2 1.2 1.2 1.2 0 00-1.2-1.2zM62.89 69.85a1.2 1.2 0 101.2 1.2 1.2 1.2 0 00-1.2-1.2zM62.89 75.47a1.2 1.2 0 101.2 1.2 1.2 1.2 0 00-1.2-1.2z"
		        transform="translate(-61.69 -64.27)"
		      />
		    </svg>
		  )
	}
}

@observer
class TileIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 15.5 15.5"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-tile-1{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}`
		          }
		        </style>
		      </defs>
		      <title>{"Chemical Comp"}</title>
		      <path
		        className="prefix__cls-tile-1"
		        d="M9.15.75h5.6v5.6h-5.6zM.75.75h5.6v5.6H.75zM9.15 9.15h5.6v5.6h-5.6zM.75 9.15h5.6v5.6H.75z"
		      />
		    </svg>
		)
	}
}

class EvidenceSearchIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={evidenceSearchIconSrc} alt="Evidence Search" />
		)
	}
}

@observer
class EvidenceIcon extends Component{
	render(){
		return (
		    <svg
		      id="prefix__Layer_1"
		      data-name="Layer 1"
		      viewBox="0 0 21.26 25.07"
		      {...this.props}
		    >
		      <defs>
		        <style>
		          {
		            `.prefix__cls-evidence-1,.prefix__cls-evidence-2{fill:none;stroke:${this.props.color};stroke-linecap:round;stroke-width:1.5px}.prefix__cls-evidence-1{stroke-linejoin:round}.prefix__cls-evidence-2{stroke-miterlimit:10}`
		          }
		        </style>
		      </defs>
		      <title>{"Evidence"}</title>
		      <path
		        className="prefix__cls-evidence-1"
		        transform="rotate(-.1 -32690.166 36159.505)"
		        d="M63.81 57.89h14.22V78H63.81z"
		      />
		      <path
		        className="prefix__cls-evidence-2"
		        d="M4.52 6.48l6.7-.01M4.52 9.84l6.71-.02M4.53 13.19l3.35-.01"
		      />
		      <circle
		        cx={76.38}
		        cy={74.43}
		        r={4.44}
		        transform="rotate(-45 -24.101 121.963)"
		        fill="#fff"
		        strokeMiterlimit={10}
		        stroke={this.props.color}
		        strokeLinecap="round"
		        strokeWidth={1.5}
		      />
		      <path className="prefix__cls-evidence-2" d="M20.51 24.32l-3.95-3.95" />
		      <path className="prefix__cls-evidence-1" d="M15.46 16.37l-2.52 2.53-1.26-1.26" />
		    </svg>
		  )
	}
}


class DotsIcon extends Component{
	render(){
		return (
			<img className={this.props.className} src={dotsIconSrc} alt="Dots Icon" />
		)
	}
}


export {
	DrawIcon,
	DrawPinkIcon,
	DrawBlueIcon,
	CreditCardIcon,
	TickIcon,
	WarningIcon,
	UnderScoreIcon,
	ArrowRightIcon,
	ArrowLeftIcon,
	ArrowDownIcon,
	ArrowUpIcon,
	PlayIcon,
	PlusIcon,
	BarIcon,
	ExitIcon,
	FacebookIcon,
	TwitterIcon,
	InstagramIcon,
	YouTubeIcon,
	SpotifyIcon,
	PodcastIcon,
	UploadIcon,
	DashboardIcon,
	BellIcon,
	SearchIcon,
	ComponentSearchIcon,
	PeopleIcon,
	FilterIcon,
	FilterDialIcon,
	DropIcon,
	LibraryIcon,
	GridIcon,
	ChemistryIcon,
	HelpIcon,
	HierarchyIcon,
	EditIcon,
	ArchiveIcon,
	DeleteIcon,
	SortIcon,
	SaveIcon,
	EmailIcon,
	EnvelopeIcon,
	CogIcon,
	CalendarIcon,
	GlobeIcon,
	CountryIcon,
	BlendingIcon,
	BlendingTubIcon,
	TestTubeIcon,
	BlendLibraryIcon,
	CompareIngredients,
	HandIcon,
	ViewIcon,
	DuplicateIcon,
	RaisedHandIcon,
	SentIcon,
	DownloadIcon,
	OpenHandIcon,
	LibraryBookIcon,
	EditIngredientIcon,
	CameraIcon,
	EyeIcon,
	StarIcon,
	BlendBottlesIcon,
	ViewBlendIcon,
	CreateNewBlend,
	UpgradeIcon,
	ScentNoteIcon,
	DropperIcon,
	BottleIcon,
	ShareIcon,
	OpenBookIcon,
	AddIcon,
	ChemicalComponentSearchIcon,
	ChemicalFamilyIcon,
	ColorBookIcon,
	DotsIcon,
	ListIcon,
	TileIcon,
	EvidenceIcon,
	EvidenceSearchIcon
}