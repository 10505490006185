import { observable, action, computed } from 'mobx';

import AcidApi from '../../../services/acid';

class AcidComponentsStore{

	@observable acidComponents;
	@observable page;
	@observable total;
	@observable has_next;
	@observable has_prev;
	@observable offset;
	@observable limit;
	@observable archived;

	@observable searchText;

	@observable showEditTooltip;

	@observable fetching;

	acidApi;

	constructor(appStore){
		this.appStore = appStore;
		this.acidApi = new AcidApi(this.appStore);
		this.initStore();
	}

	initStore(){
		this.acidComponents = [];
		this.page = 1;
		this.total = 0;
		this.offset = null;
		this.limit = null;
		this.archived = false;
		this.showEditTooltip = null;
		this.searchText = null;
		this.fetching = false;
	}

	fetchAcidComponents(){
		this.fetching = true;
		this.acidApi.getAllAcidComponents(this.page, this.searchText)
			.then((response) => {
				this.acidComponents = response.acid_components;
				this.limit = response.limit;
				this.page = response.page;
				this.total = response.total;
				this.offset = response.offset;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	onNextPage(){
		this.page += 1;
		this.fetchAcidComponents();
	}

	onPreviousPage(){
		this.page = Math.max(this.page - 1, 0);

		this.fetchAcidComponents();
	}

	onPage(page){
		this.page = page;
		this.fetchAcidComponents();
	}

	@computed get hasNext(){
		if(this.total == null || this.page == null || this.limit == null) return false;
		return (this.page * this.limit) < this.total
	}

	@computed get hasPrev(){
		if(this.limit == null || this.offset == null) return false;
		return this.offset >= this.limit;
	}

	@computed get pages(){
		if(this.acidComponents.length == 0 || this.total == null) return [];
		
		let noOfPages = Math.floor(this.total / this.limit);
		let pages = []
		for(let i = 0; i < noOfPages + 1; i++){
			pages.push(i+1)
		}
		return pages;
	}

	onEditAcidComponent(id){
		this.appStore.goToEditAcidComponent(id);
	}

	@action setEditTooltip(id){
		this.showEditTooltip = id;
	}

	@action changeSearchText(val){
		this.page = 1;
		this.searchText = val;
		this.fetchAcidComponents();
	}
}

export default AcidComponentsStore;