import {Router} from 'director/build/director';
import {autorun} from 'mobx';

export function startRouter(store) {
	let routes = {
        "/": () => store.goToIndex(),
        "/home": () => store.goToUserHome(),
    	"/pricing": () => store.goToPricing(),
        "/plans/:languageCode": (languageCode) => store.goToPickAPlanWithLanguage(languageCode),
        "/plans": () => store.goToPickAPlan(),
        "/training": () => store.goToTraining(),
        "/contact": () => store.goToContact(),
        "/terms": () => store.goToTerms(),
        "/login/:languageCode": (languageCode) => store.goToLoginWithLanguage(languageCode),
        "/login": () => store.goToLogin(),
        "/forgot-password": () => store.goToForgotPassword(),
        "/reset-password/:token": (token) => store.goToResetPassword(token),
        "/accept-invite/:token": (token) => store.goToAcceptInvite(token),
        "/register?currencyId=:currencyId&planId=:planId&period=:period": (currencyId, planId, period) => store.goToRegistrationWith(currencyId, period, planId),
        "/style-guide": () => store.goToStyleGuide(),
        "/super/admin/home": () => store.goToSuperAdministratorHome(),
        "/admin/home": () => store.goToOrganisationAdministratorHome(),
        "/users": () => store.goToManageUsers(),
        "/users/invite": () => store.goToInviteNewUser(),
        "/users/:userId": (userId) => store.goToEditUser(userId),
        "/super/admin/organisations": () => store.goToSuperAdministratorManageOrganisations(),
        "/organisations/invite": () => store.goToInviteNewOrganisation(),
        "/organisations/:userId": (userId) => store.goToEditOrganisation(userId),
        "/settings/chemistry": () => store.goToChemistrySettings(),
        "/settings/chemistry/families/new": () => store.goToNewChemistryFamily(),
        "/settings/chemistry/families/:chemicalFamilyId": (chemicalFamilyId) => store.goToEditChemistryFamily(chemicalFamilyId),
        "/settings/chemistry/components/new": () => store.goToNewChemicalComponent(),
        "/settings/chemistry/components/:chemicalFamilyId": (chemicalComponentId) => store.goToEditChemicalComponent(chemicalComponentId),
        "/settings/acids/families/new": () => store.goToNewAcidFamily(),
        "/settings/acids/families/:acidFamilyId": (acidFamilyId) => store.goToEditAcidFamily(acidFamilyId),
        "/settings/acids/components/new": () => store.goToNewAcidComponent(),
        "/settings/acids/components/:chemicalFamilyId": (acidComponentId) => store.goToEditAcidComponent(acidComponentId),
        "/settings/regulations": () => store.goToRegulations(),
        "/settings/regulations/:regionId": (regionId) => store.goToEditRegulations(regionId),
        "/settings/languages": () => store.goToLanguages(),
        "/settings/languages/:lang": (lang) => store.goToEditLanguage(lang),
        "/settings/languages/:lang/entity/:entityKey/:entityId": (lang, entityKey, entityId) => store.goToEntityLanguageTranslation(lang, entityId, entityKey),
        "/settings/languages/:lang/web/:translateKey": (lang, translateKey) => store.goToWebLanguageTranslation(lang, translateKey),
        "/ingredients/new": () => store.goToNewIngredient(),
        "/ingredients/labaroma-library/:ingredientTypeId": (ingredientTypeId) => store.goToLabAromaIngredientLibrary(ingredientTypeId),
        "/ingredients/labaroma-library": () => store.goToLabAromaIngredientLibrary(),
        "/ingredients/my-library/:ingredientTypeId": (ingredientTypeId) => store.goToMyIngredientLibrary(ingredientTypeId),
        "/ingredients/my-library": () => store.goToMyIngredientLibrary(),
        "/ingredients/comparisons": () => store.goToCompareIngredients(),
        "/ingredients/comparisons/new": () => store.goToComparisonTool(),
        "/ingredients/comparisons/:comparisonReportId": (comparisonReportId) => store.goToEditComparisonTool(comparisonReportId),
        "/ingredients/:ingredientId": (ingredientId) => store.goToEditIngredient(ingredientId),
        "/blends/my-library": () => store.goToMyBlendLibrary(),
        "/blends/labaroma-library": () => store.goToLabAromaBlendLibrary(),
        "/blends/library-category/:libraryCategoryId" : (libraryCategoryId) => store.goToBlendLibraryWithCategory(libraryCategoryId),
        "/blends/new": () => store.goToNewBlend(),
        "/blends/categories": () => store.goToBlendCategories(),
        "/blends/categories/new": () => store.goToNewBlendCategory(),
        "/blends/categories/:blendCategoryId": (blendCategoryId) => store.goToEditBlendCategory(blendCategoryId),
        "/blends/:blendId": (blendId) => store.goToEditBlend(blendId, false),
        "/libraries/categories": () => store.goToLibraryCategories(),
        "/libraries/categories/new": () => store.goToNewLibraryCategory(),
        "/libraries/categories/:libraryCategoryId": (libraryCategoryId) => store.goToEditLibraryCategory(libraryCategoryId),
        "/components": () => store.goToComponentSearchList(),
        "/components/search": () => store.goToComponentSearch(),
        "/components/search/:componentSearchId": (componentSearchId) => store.goToEditComponentSearch(componentSearchId),
        "/advanced-search": () => store.goToAdvancedSearch(),
        "/advanced-search/:query": (query) => store.goToAdvancedSearch(query),
        "/evidence": () => store.goToEvidenceSearch(),
        "/evidence/new": () => store.goToNewEvidence(),
        "/evidence/:evidenceId": (evidenceId) => store.goToEditEvidence(evidenceId),
        "/marketing-assets": () => store.goToMarketingAssetSearch(),
        "/marketing-assets/new": () => store.goToNewMarketingAsset(),
        "/marketing-assets/:marketingAssetId": (marketingAssetId) => store.goToEditMarketingAsset(marketingAssetId)
    };
    // update state on url change
    const router = new Router(routes);
    router
    	.configure({
	        notfound: () => store.goToHome(),
	        html5history: true
	    })
	    .init();

     autorun(() => {
            const path = store.currentPath;
            if (path !== window.location.pathname){
                window.history.pushState(null, null, path)
            }
        })
}