import BaseApi from '../services/base';

class CountryApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(){
		return this.fetch(`v1/countries`, {
			method: 'GET'
		})
	}
}

export default CountryApi;