import React, { Component, Fragment } from 'react';
import {inject, observer} from 'mobx-react';

import StyleGuide from './components/style-guide';

import Alert from './components/common/alert';
import {Heading, SubHeading, Label, Body, CallToAction} from './components/common/text'
import {TextInput, CheckBox, Select} from './components/common/input'
import {OrangeButton} from './components/common/button';

import Navbar from './components/nav/navbar';
import Footer from './components/nav/footer';

import {renderView} from './render';
import { withTranslation } from 'react-i18next';

import SavedDialog from './components/common/saved-dialog';
import Newsletter from './components/home/newsletter';
import AbandonedCart from './components/home/cart';
import Modal from 'react-modal';

import 'react-dates/initialize';
import './styles/tailwind.css';
import 'react-dates/lib/css/_datepicker.css';

@inject('appStore')
@observer
class App extends Component {
  constructor(props){
  	super(props);
  	this.changeLanguage = this.changeLanguage.bind(this);
    this.onExitModal = this.onExitModal.bind(this);
    this.exitModalIcon = this.exitModalIcon.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
  }

  componentDidMount(){
  	this.props.appStore.startUp(this.props.i18n);
  }

  componentWillUnmount(){
    let appStore = this.props.appStore;
    appStore.clearExistingIntervals();
  }

  checkForUnsavedChanges(){
    let appStore = this.props.appStore;
    if(appStore.hasUnsavedChanges){
      let confirmed = window.confirm('You have unsaved changes, do you want to discard them?');
      if(confirmed){
        appStore.cancelUnsavedChanges();
      }
    }
  }


  changeLanguage(language){
    this.props.appStore.changeLanguage(language);
  }

  onExitModal(){
    this.props.appStore.closeNewsletterModal();
  }

  exitModalIcon(){
    return (
      <div className="relative left-0 top-0 cursor-pointer" onClick={this.onExitModal}>
        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 h-8"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
      </div>
    )
  }

  _onMouseMove(e){
    this.props.appStore.onMouseMove(e);
  }

  render() {
  	let appStore = this.props.appStore;
    if(!appStore.appHasLoaded) return null;
  	let renderedView = renderView(appStore);

  	let alertsView = appStore.hasAlerts ?
			<Alert message={appStore.alerts[0].message} alertId={appStore.alerts[0].id} alertType={appStore.alerts[0].alertType} /> : null;
    return (
    	<div 
        className="flex flex-1 flex-col min-h-screen w-full overflow-scroll justify-between"
        onMouseMove={this._onMouseMove}
      >
        <div className="h-auto">
          {alertsView}
        </div>
        <div className="flex flex-1">
          <SavedDialog />
          <Newsletter />
          <AbandonedCart />
	    		{renderedView}
    		</div>
    	</div>
    )
  }
}

export default withTranslation()(App);
