import { observable, action, computed, extendObservable } from 'mobx';

import IngredientApi from '../../../services/ingredient';

import {getIngredientAvatarUrl} from './util';

class IngredientsLibraryStore{

	@observable ingredientTypes;
	@observable ingredients;

	@observable currentIngredientTypeId;
	@observable showArchived;

	@observable searchText;

	@observable showSortDialog;
	@observable showFilterDialog;

	@observable currentFilterValue;
	@observable currentFilters;

	@observable searchTagFilters;

	@observable showPublicLibrary;

	@observable sortBy;
	@observable fetching;
	@observable page;

	@observable loaded;
	@observable compareIngredientIds;
	@observable searchTimeout;

	ingredientApi;

	constructor(appStore){
		this.appStore = appStore;
		this.ingredientApi = new IngredientApi(appStore);
		this.initStore();
	}

	initStore(){
		this.ingredientTypes = [];
		this.ingredients = [];
		this.currentIngredientTypeId = null;
		this.searchText = null;
		this.showSortDialog = false;
		this.showFilterDialog = false;
		this.sortBy = null;
		this.currentFilterValue = '';
		this.currentFilters = [];
		this.searchTagFilters = [];
		this.showArchived = false;
		this.showPublicLibrary = false;
		this.fetching = false;
		this.page = 1;
		this.hasMore = true;
		this.loaded = false;
		this.compareIngredientIds = [];
		if (this.searchTimeout != null){
			clearTimeout(this.searchTimeout);
		}
		this.searchTimeout = null;
	}

	onLoad(showPublicLibrary, ingredientTypeId=null){
		this.showPublicLibrary = showPublicLibrary;
		this.fetchIngredientTypes()
			.then(() => {
				let allIngredientTypeIds = this.ingredientTypes.map((i) => i.id);
				if(ingredientTypeId != null){
					let hasIngredientTypeId = allIngredientTypeIds.includes(parseInt(ingredientTypeId, 10));
					if(hasIngredientTypeId){
						this.changeCurrentIngredientType(ingredientTypeId);
					}
				}else if(this.ingredientTypes.length > 0){
					this.changeCurrentIngredientType(this.ingredientTypes[0].id);
				}
			})
	}

	fetchIngredientTypes(){
		return this.ingredientApi.getAllIngredientTypes()
			.then((response) => {
				this.ingredientTypes = response.ingredient_types;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchIngredients(reset=false){
		let ingredientTypeId = this.currentIngredientTypeId != -1 ? this.currentIngredientTypeId : null;
		let favourites = this.currentIngredientTypeId == -1 ? true : null;
		let searchText = this.searchText == '' ? null : this.searchText;
		let sortBy = this.sortBy;
		let filterBy = this.searchTagFilters != null && this.searchTagFilters.length > 0 ? {
			'tags': this.searchTagFilters.map((f) => f.value)
		} : null;

		this.fetching = true;
		this.ingredientApi.getAllIngredients(this.page, this.searchText, sortBy, filterBy, ingredientTypeId, favourites, this.showArchived, this.showPublicLibrary)
			.then((response) => {
				this.ingredients = reset ? response.ingredients : this.ingredients.concat(response.ingredients);
				this.hasMore = response.ingredients.length > 0;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
				this.loaded = true;
			})
	}

	mapIngredient(i){
		return extendObservable(i, {
				editTags: false,
				currentValue: '',
				get tagOptions(){
					return i.tags.map((t) => {
						return {
							label: t.tag.name,
							value: t.tag.id
						}
					}).sort((t1, t2) => t1.id > t2.id)
				}
			});
	}

	@computed get ingredientsWithTags(){
		return this.ingredients.filter((i) => i.archived == this.showArchived).map(this.mapIngredient);
	}

	@action onNewIngredient(){
		this.appStore.goToNewIngredient();
	}

	@action changeCurrentIngredientType(ingredientTypeId){
		this.ingredients = [];
		this.currentIngredientTypeId = ingredientTypeId;
		this.resetFetchIngredients();
		let newPath = newPath = `/ingredients/${this.showPublicLibrary ? 'labaroma-library' : 'my-library'}/${ingredientTypeId}`;
		window.history.pushState(null, null, newPath);
	}

	@action resetFetchIngredients(){
		this.page = 1;
		this.fetchIngredients(true);
	}

	@action onEditIngredient(id){
		this.appStore.goToEditIngredient(id, true);
	}

	@action onViewIngredient(id){
		this.appStore.goToEditIngredient(id);
	}

	@computed get headerOptions(){
		let ingredientTypeOptions = this.ingredientTypes;
		ingredientTypeOptions.push({
			id: -1,
			name: this.appStore.i18n.t('user.my-ingredient-library.favourite-tab')
		});
		return ingredientTypeOptions
	}

	@observable changeSearchText(val){
		this.searchText = val;
		if (this.searchTimeout != null){
			clearTimeout(this.searchTimeout);
		}

		this.searchTimeout = setTimeout(() => {
			this.resetFetchIngredients();	
		}, 500);
	}

	@action onToggleFavouriteIngredient(id){
		let ingredientIdx = this.ingredients.findIndex((i) => i.id == id);
		if(ingredientIdx != -1){
			let ingredient = this.ingredients[ingredientIdx];
			this.ingredientApi.patchIngredient(ingredient.id, {
				favourited: !ingredient.favourited
			})
			.then((response) => {
				let updatedIngredient = response.ingredient;
				this.ingredients[ingredientIdx].favourited = updatedIngredient.favourited;
			})
			.catch((error) => {
				console.log(error)
			})
		}
	}

	onArchiveIngredient(id){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-ingredient-library.archive-confirm'));
		if(confirmed){
			this.onToggleArchivedIngredient(id);
		}
	}

	onRestoreIngredient(id){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-ingredient-library.restore-confirm'));
		if(confirmed){
			this.onToggleArchivedIngredient(id);
		}
	}

	onPublicIngredient(id){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-ingredient-library.public-confirm'));
		if(confirmed){
			this.onTogglePublicIngredient(id);
		}
	}

	@action onTogglePublicIngredient(id){
		let ingredientIdx = this.ingredients.findIndex((i) => i.id == id);
		if(ingredientIdx != -1){
			let ingredient = this.ingredients[ingredientIdx];
			this.fetching = true;
			this.ingredientApi.patchIngredient(ingredient.id, {
				public: !ingredient.public
			})
			.then((response) => {
				let updatedIngredient = response.ingredient;
				this.ingredients[ingredientIdx].public = updatedIngredient.public;
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.fetching = false;
			})
		}
	}

	@action onToggleArchivedIngredient(id){
		let ingredientIdx = this.ingredients.findIndex((i) => i.id == id);
		if(ingredientIdx != -1){
			let ingredient = this.ingredients[ingredientIdx];
			this.fetching = true;
			this.ingredientApi.patchIngredient(ingredient.id, {
				archived: !ingredient.archived
			})
			.then((response) => {
				let updatedIngredient = response.ingredient;
				this.ingredients[ingredientIdx].archived = updatedIngredient.archived;
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.fetching = false;
			})
		}
	}

	@action toggleSortDialog(){
		this.showSortDialog = !this.showSortDialog;
		this.showFilterDialog = false;
	}

	@action toggleFilterDialog(){
		this.showFilterDialog = !this.showFilterDialog;
		this.showSortDialog = false;
	}

	@action setSortByField(val){
		this.sortBy = val;
	}

	@action applySort(){
		this.showSortDialog = false;
		this.resetFetchIngredients();
	}

	@computed get sortOptions(){
		if(this.appStore == null) return [];
		return [
			{
				label: this.appStore.i18n.t("user.my-ingredient-library.sortoptions.name"),
				value: "name"
			},
			{
				label: this.appStore.i18n.t("user.my-ingredient-library.sortoptions.origin"),
				value: "origin"
			},
			{
				label: this.appStore.i18n.t("user.my-ingredient-library.sortoptions.classification"),
				value: "classification"
			},
			{
				label: this.appStore.i18n.t("user.my-ingredient-library.sortoptions.favourite"),
				value: "favourited"
			}
		]
	}

	@action onToggleTagEditMode(id){
		let ingredientIdx = this.ingredients.findIndex((i) => i.id == id);
		if(ingredientIdx != -1){
			this.ingredients[ingredientIdx].editTags = !this.ingredients[ingredientIdx].editTags;
		}
	}

	@action newIngredientTag(ingredientId, tag){
		let ingredientIdx = this.ingredients.findIndex((i) => i.id == ingredientId);
		if(ingredientIdx != -1){
			this.ingredients[ingredientIdx].currentValue = '';

			this.ingredientApi.addIngredientTag(ingredientId, tag)
				.then((response) => {
					let newIngredientTag = response.ingredient_tag;
					this.ingredients[ingredientIdx].tags.push(newIngredientTag);
				})
				.catch((error) => {
					console.log(error);
				})
		}
	}


	@action onIngredientTagsChange(ingredientId, tags){
		let ingredientIdx = this.ingredients.findIndex((i) => i.id == ingredientId);
		if(ingredientIdx != -1){
			let ingredientTagIds = tags != null ? tags.map((t) => t.value) : [];
			this.ingredientApi.updateIngredientTag(ingredientId, ingredientTagIds)
				.then((response) => {
					let updatedTags = response.ingredient_tags;
					this.ingredients[ingredientIdx].tags = updatedTags;
				})
				.catch((error) => {
					console.log(error)
				})
		}

	}

	@action changeIngredientTagInput(id, tag){
		let ingredientIdx = this.ingredients.findIndex((i) => i.id == id);
		if(ingredientIdx != -1){
			this.ingredients[ingredientIdx].currentValue = tag;
		}
	}

	resetFilterValue(){
		this.currentFilterValue = '';
	}

	@action newFilterEntry(val){
		let strippedVal = val.trim();
		if(!this.currentFilters.filter((x) => x.value).includes(strippedVal)){
			this.currentFilters.push({
				label: val,
				value: val
			});
		}
		this.resetFilterValue();
	}

	@action setFilters(values){
		this.currentFilters = values != null ? values : [];
	}

	@action setCurrentFilterValue(val){
		this.currentFilterValue = val;
	}

	@action applyFilters(){
		this.searchTagFilters = this.currentFilters;
		this.showFilterDialog = false;
		this.resetFetchIngredients();
	}

	@action toggleArchived(){
		this.showArchived = !this.showArchived;
		this.resetFetchIngredients();
	}

	@action onDuplicateIngredient(ingredientId){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-ingredient-library.duplicate-confirm'));
		if(confirmed){
			let ingredientIdx = this.ingredients.findIndex((i) => i.id == ingredientId);
			if(ingredientIdx != -1){
				this.fetching = true;
				this.ingredientApi.duplicateIngredient(ingredientId)
					.then((response) => {
						this.ingredients.splice(ingredientIdx, 0, response.ingredient);
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.fetching = false;
					})
			}
		}
	}

	@action hasEditAccessToIngredient(ingredient){
		if(ingredient.public){
			if(this.appStore.isSuperAdministrator){
				return true;
			}else{
				return false;
			}
		}else{
			let currentUser = this.appStore.currentUser;
			return ingredient.user_id == currentUser.id;
		}
	}

	@computed get canCreateIngredients(){
		if(this.appStore.isSuperAdministrator){
			return true;
		}else{
			if(this.showPublicLibrary){
				return false;
			}else{
				return true;
			}
		}
	}

	@computed get showAvailability(){
		return this.appStore.isSuperAdministrator;
	}

	@action loadNextPage(){
		if(this.loaded){
			this.page += 1
			this.fetchIngredients();
		}
	}

	@action toggleIngredientIdToCompare(ingredientId){
		if(this.compareIngredientIds.includes(ingredientId)){
			this.compareIngredientIds = this.compareIngredientIds.filter((i) => i != ingredientId);
		}else{
			this.compareIngredientIds.push(ingredientId);
		}
	}

	@action compareIngredients(){
		this.appStore.goToComparisonTool(this.compareIngredientIds);
	}

	@action getAvatarUrl(ingredient){
		return getIngredientAvatarUrl(ingredient);
	}

	@computed get showCompare(){
		return !this.appStore.isHomeUser;
	}
}

export default IngredientsLibraryStore