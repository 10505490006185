import { observable, action, computed } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isEmailValidator, isRequiredValidator, isUrlValidator } from '../../../services/validation';

import OrganisationApi from '../../../services/organisation';

class ManageOrganisation{
	appStore;
	organisationApi;

	@observable editMode;
	@observable currentOrganisation;

	@observable organisationData;

	@observable fetching;

	constructor(appStore){
		this.appStore = appStore;
		this.organisationApi = new OrganisationApi(appStore);
		this.initStore();
	}

	initStore(){
		this.currentOrganisation = null;
		this.editMode = false;
		this.organisationData = {
			id: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			name: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			description: new FieldState(null),
			type: new FieldState(null),
			archived: new FieldState(null),
			website: new FieldState(null).validators((val) => val != null && val.length > 0 && isUrlValidator(val, this.appStore.i18n))
		}

		this.fetching = false;
	}


	toggleEditMode(){
		this.editMode = true;
		this.organisationData.id.onChange(this.currentOrganisation.id);
		this.organisationData.name.onChange(this.currentOrganisation.name);
		this.organisationData.type.onChange(this.currentOrganisation.type);
		this.organisationData.description.onChange(this.currentOrganisation.description);
		this.organisationData.website.onChange(this.currentOrganisation.website);
		this.organisationData.archived.onChange(this.currentOrganisation.archived ? 1 : -1);
	}

	cancelEditMode(){
		this.editMode = false;
	}

	setOrganisationId(organisationId){
		this.organisationApi.getOrganisationById(organisationId)
			.then((response) => {
				this.currentOrganisation = response;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	saveOrganisation(){
        this.fetching = true;
        let organisationPayload = {
        	name: this.organisationData.name.value,
        	type: this.organisationData.type.value,
        	description: this.organisationData.description.value,
        	website: this.organisationData.website.value,
        	archived: this.organisationData.archived.value == 1 ? true : false
        }

        this.organisationApi.updateOrganisation(this.organisationData.id.value, organisationPayload)
	        .then((response) => {
	        	let updatedOrganisation = response;
	        	this.currentOrganisation = updatedOrganisation;

	        	this.editMode = false;
	        })
	        .catch((error) => {
	        	console.log(error);
	        })
	        .finally(() => {
	        	this.fetching = false;
	        })
     }

	@computed get validForm(){
		let requiredFields = ['name'];

		if(this.organisationData.website.value != null && this.organisationData.website.value.length > 0){
			requiredFields.push('website');
		}

		let isValidForm = true;
		for(let field of requiredFields){
			let fieldError = this.organisationData[field].error;
			isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
		}
		return isValidForm;
	}

	@computed get statusOptions(){
		return [
			{
				value: -1,
				label: this.appStore.i18n.t('superadmin.users.list.active')
			},
			{
				value: 1,
				label: this.appStore.i18n.t('superadmin.users.list.archived')
			}
		]
	}
}

export default ManageOrganisation;