import React, { Component } from 'react';
import {inject, observer} from 'mobx-react';

import { withTranslation } from 'react-i18next';

import {Label, Body, SecondaryWebLargerTitle} from '../common/text';
import {TextInput} from '../common/input'
import LeftBlockRightContent from '../common/left-block-right-content';
import {Button} from '../common/button';
import {HorizontalFormColumn } from '../common/form';

@inject('forgotPasswordStore', 'appStore')
@observer
class ForgotPassword extends Component{
	constructor(props){
		super(props);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.goToLogin = this.goToLogin.bind(this);
		this.onSendReminder = this.onSendReminder.bind(this);
		this.goToSignUp = this.goToSignUp.bind(this);
	}

	componentDidMount(){
		let appStore = this.props.appStore;
		if(appStore.isAuthenticated){
			this.props.appStore.goToHome();
		}
	}

	componentWillUnmount(){
		let forgotPasswordStore = this.props.forgotPasswordStore;
		forgotPasswordStore.initStore();
	}

	onChangeEmail(e){
		let forgotPasswordStore = this.props.forgotPasswordStore;
		forgotPasswordStore.onChangeEmail(e.target.value);
	}	

	goToLogin(){
		this.props.appStore.goToLogin();
	}

	onSendReminder(e){
		e.preventDefault();
		let forgotPasswordStore = this.props.forgotPasswordStore;
		forgotPasswordStore.sendReminder();
	}

	goToSignUp(){
		let appStore = this.props.appStore;
		appStore.goToPickAPlan();
	}

	render(){
		let forgotPasswordStore = this.props.forgotPasswordStore;
		let translate = this.props.t;
		return (
			<LeftBlockRightContent
				leftBgColor='flat-blue-25'
				left={
					<>
						<SecondaryWebLargerTitle className="text-center text-black lg:text-left">{translate('forgot-password.title.part1')} <br /> {translate('forgot-password.title.part2')} </SecondaryWebLargerTitle>
						<Body className="text-center text-black lg:text-left mt-8">{translate('forgot-password.description.part1')}</Body>
						<Body className="text-center text-black lg:text-left mt-8">{translate('forgot-password.description.part2')}</Body>
					</>
				}
				right={
					<div className="flex flex-1 flex-col justify-center">
						<div className="flex flex-col">
							<HorizontalFormColumn>
								<Label>{translate('forgot-password.email.label')}</Label>
								<TextInput 
									onChange={this.onChangeEmail}
									value={forgotPasswordStore.email.value}
									error={forgotPasswordStore.email.error}
									placeholder={translate('forgot-password.email.placeholder')}
								/>
							</HorizontalFormColumn>
						</div>
						<div className="flex flex-col mt-8">
							<Button
							className="uppercase"
							 width='w-full'
							 bgColor='black'
							 fetching={this.props.appStore.loggingIn}
							 onClick={this.onSendReminder}
							disabled={!forgotPasswordStore.isValidForm}>{translate('forgot-password.button')}</Button>
							 <div className="flex flex-1 flex-row mb-4 mt-2">
								<Body 
									className="inline-flex tracking-wider" 
									onClick={this.goToSignUp}>{translate('forgot-password.no-account.part1')}&nbsp;</Body>
								<div className="inline-flex bold-font underline hover:text-flat-blue cursor-pointer" onClick={this.goToSignUp}>{translate('forgot-password.no-account.part2')}</div>
							</div>
						</div>
					</div>
				}
			/>			
		)
	}
}

export default withTranslation()(ForgotPassword);