import React, {Component} from 'react';

import {Heading, SubHeading, Body, CallToAction} from './common/text';

import {OrangeButton, MidGreenButton} from './common/button';

import {TextInput, TextArea, Select} from './common/input';

import Logo from './common/logo';

class ColourBox extends Component{
	render(){
		return (
			<div className={`flex w-full sm:w-full md:w-1/4 py-2 justify-center ${this.props.bgClassName}`}>{this.props.name}</div>
		)
	}
}


class Row extends Component{
	render(){
		return <div className="flex flex-row">{this.props.children}</div>
	}
}

class StyleGuide extends Component{
	render(){
		return (
			<div class="flex flex-1 h-full justify-center items-center">
				<div className="w-3/4 h-1/2 p-8 border-orange border-2 ">
					<Logo />
					<Heading>Typography</Heading>
					<Heading>This is an example of a Heading</Heading>
					<SubHeading>This is an example of a Subheading</SubHeading>
					<Body>	
						Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
						Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
						when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
						It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
						It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with 
						desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
					</Body>
					<CallToAction>This is a call to action</CallToAction>

					<Heading>Colour Palette</Heading>
					<Row>
						<ColourBox bgClassName="bg-light-blue" name="Light Blue" />
						<ColourBox bgClassName="bg-light-blue-80" name="Light Blue (80%)" />
						<ColourBox bgClassName="bg-light-blue-60" name="Light Blue (60%)" />
						<ColourBox bgClassName="bg-light-blue-40" name="Light Blue (40%)" />
					</Row>
					<Row>
						<ColourBox bgClassName="bg-orange" name="Orange" />
						<ColourBox bgClassName="bg-orange-80" name="Orange (80%)" />
						<ColourBox bgClassName="bg-orange-60" name="Orange (60%)" />
						<ColourBox bgClassName="bg-orange-40" name="Orange (40%)" />
					</Row>
					<Row>
						<ColourBox bgClassName="bg-charcoal" name="Charcoal" />
						<ColourBox bgClassName="bg-charcoal-80" name="Charcoal (80%)" />
						<ColourBox bgClassName="bg-charcoal-60" name="Charcoal (60%)" />
						<ColourBox bgClassName="bg-charcoal-40" name="Charcoal (40%)" />
					</Row>
					<Row>
						<ColourBox bgClassName="bg-mid-green" name="Mid-Green" />
						<ColourBox bgClassName="bg-mid-green-80" name="Mid-Green (80%)" />
						<ColourBox bgClassName="bg-mid-green-60" name="Mid-Green (60%)" />
						<ColourBox bgClassName="bg-mid-green-40" name="Mid-Green (40%)" />
					</Row>
					<Row>
						<ColourBox bgClassName="bg-mid-yellow" name="Mid-Yellow" />
						<ColourBox bgClassName="bg-mid-yellow-80" name="Mid-Yellow (80%)" />
						<ColourBox bgClassName="bg-mid-yellow-60" name="Mid-Yellow (60%)" />
						<ColourBox bgClassName="bg-mid-yellow-40" name="Mid-Yellow (40%)" />
					</Row>
					<Row>
						<ColourBox bgClassName="bg-pink" name="Pink" />
						<ColourBox bgClassName="bg-pink-80" name="Pink (80%)" />
						<ColourBox bgClassName="bg-pink-60" name="Pink (60%)" />
						<ColourBox bgClassName="bg-pink-40" name="Pink (40%)" />
					</Row>
					<Row>
						<ColourBox bgClassName="bg-dark-blue" name="Dark Blue" />
						<ColourBox bgClassName="bg-dark-blue-80" name="Dark Blue (80%)" />
						<ColourBox bgClassName="bg-dark-blue-60" name="Dark Blue (60%)" />
						<ColourBox bgClassName="bg-dark-blue-40" name="Dark Blue (40%)" />
					</Row>
					<Row>
						<ColourBox bgClassName="bg-black text-white" name="Black" />
						<ColourBox bgClassName="bg-white" name="White" />
					</Row>
					<Heading>Buttons</Heading>

					<OrangeButton>Hello</OrangeButton>
					<MidGreenButton>Submit</MidGreenButton>

					<Heading>Inputs</Heading>

					<SubHeading>Text Input</SubHeading>
					<TextInput value="Hello my name is Sean asdsa"  />
					
					<SubHeading>Text Area</SubHeading>
					<TextArea>
					Thiada
					</TextArea>

					<SubHeading>Select Box</SubHeading>
					<Select options={[
						{
							value:0,
							label: 'First'
						},
						{
							value:1,
							label: 'Second'
						}
					]} />
				</div>
			</div>
		)
	}
}

export default StyleGuide;