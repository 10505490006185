import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {SecondaryAppMediumTitle, TertiaryAppSmallTitle} from '../../common/text'
import { withAuthorisation } from '../../common/authorisation';

import {Button} from '../../common/button';

import {EnvelopeIcon} from '../../common/icon';

import Main from '../../elements/main';
import {Grid} from '../../elements/grid';

import {CosmeticRegulationPermission, ChemistryPermission, LanguagesPermission, UsersPermission} from '../../elements/permission';

import { SUPER_ADMINISTRATOR } from '../../../services/util';

import {
	DataHeader,
	DataRow,
	FormInput,
	FormSelect,
	FormTextArea
} from '../../elements/form';


@inject('inviteOrganisationStore')
@observer
class InviteOrganisation extends Component{
	constructor(props){
		super(props);
		this.onChangeCurrency = this.onChangeCurrency.bind(this);
		this.onChangePlan = this.onChangePlan.bind(this);
		
	}

	componentDidMount(){
		let inviteOrganisationStore = this.props.inviteOrganisationStore;
		inviteOrganisationStore.fetchCurrencies();
		inviteOrganisationStore.fetchPermissions();
	}

	componentWillUnmount(){
		let inviteOrganisationStore = this.props.inviteOrganisationStore;
		inviteOrganisationStore.initStore();
	}

	onChangeCurrency(option){
		let inviteOrganisationStore = this.props.inviteOrganisationStore;
		inviteOrganisationStore.onChangeCurrency(option.value);
	}

	onChangePlan(option){
		let inviteOrganisationStore = this.props.inviteOrganisationStore;
		inviteOrganisationStore.onChangePlan(option.value);
	}

	render(){
		let translate = this.props.t;
		let inviteOrganisationStore = this.props.inviteOrganisationStore;
		return (
			<Main>
				<div className="flex flex-1 flex-col"> 
					<div className="flex w-full justify-end mt-8">
						<Button
							width="w-auto"
							height="h-12"
							className="px-4 rounded-lg"
							bgColor="pink-coral"
							fetching={inviteOrganisationStore.fetching}
							disabled={!inviteOrganisationStore.validForm}
							onClick={() => inviteOrganisationStore.invite()}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppSmallTitle>{translate('invite-organisation.invite-button')} &nbsp;</TertiaryAppSmallTitle></div>
								<div className="flex"><EnvelopeIcon className="w-4 h-4" color="white" /></div>
							</div>
						</Button>
					</div>
					<Grid className="mt-4">
						<SecondaryAppMediumTitle>{translate('invite-organisation.title')}</SecondaryAppMediumTitle>

						<div className="flex flex-1 flex-col">
							<div className="flex flex-1 flex-col mt-4">
								<div className="flex flex-1 flex-row flex-wrap">
									<DataRow label={translate('invite-organisation.name.label')}>
										<FormInput 
											placeholder={translate('invite-organisation.name.placeholder')}
											value={inviteOrganisationStore.organisationData.name.value}
											error={inviteOrganisationStore.organisationData.name.error}
											onChange={(e) => inviteOrganisationStore.organisationData.name.onChange(e.target.value)}
										/>
									</DataRow>
									<div className="mt-4 lg:mt-0 ml-0 lg:ml-8"></div>
									<DataRow label={translate('invite-organisation.email.label')}>
										<FormInput 
											placeholder={translate('invite-organisation.email.placeholder')}
											value={inviteOrganisationStore.organisationData.email.value}
											error={inviteOrganisationStore.organisationData.email.error}
											onChange={(e) => inviteOrganisationStore.organisationData.email.onChange(e.target.value)}
										/>
									</DataRow>
								</div>
								<div className="flex flex-1 flex-row flex-wrap mt-4">
									<DataRow label={translate('invite-organisation.currency.label')}>
										<FormSelect 
											placeholder={translate('invite-organisation.currency.placeholder')}
											options={inviteOrganisationStore.currencyOptions}
											value={inviteOrganisationStore.organisationData.selectedCurrency.value}
											onChange={this.onChangeCurrency}
											/>
									</DataRow>
									<div className="mt-4 lg:mt-0 ml-0 lg:ml-8"></div>
									<DataRow label={translate('invite-organisation.plan.label')}>
										<FormSelect 
											placeholder={translate('invite-organisation.plan.placeholder')}
											options={inviteOrganisationStore.planOptions}
											value={inviteOrganisationStore.organisationData.selectedPlan.value}
											onChange={this.onChangePlan}
											/>
									</DataRow>
								</div>
								<div className="flex flex-1">
									<DataRow label={translate('invite-organisation.message.label')}>
										<FormTextArea
											placeholder={translate('invite-organisation.message.placeholder')}
											value={inviteOrganisationStore.organisationData.message.value}
											error={inviteOrganisationStore.organisationData.message.error}
											onChange={(e) => inviteOrganisationStore.organisationData.message.onChange(e.target.value)}
										/>
									</DataRow>
								</div>
								<div className="flex flex-1 flex-row mt-4">
									<div className="flex w-1/3">
										<DataRow label={translate('invite-organisation.language.label')}>
											<FormSelect 
												placeholder={translate('invite-organisation.language.placeholder')}
												options={inviteOrganisationStore.appStore.languageOptions}
												value={inviteOrganisationStore.organisationData.selectedLanguage.value}
												onChange={(option) => inviteOrganisationStore.organisationData.selectedLanguage.onChange(option.value)}
											/>
										</DataRow>
									</div>
									<div className="ml-0 lg:ml-8"></div>
									<div className="flex flex-1 flex-col">
										<DataHeader noborder>{translate('invite-organisation.access.label')}</DataHeader>
										<div className="flex flex-1 grid grid-cols-4 gap-4">
											<CosmeticRegulationPermission 
												checked={inviteOrganisationStore.hasCosmeticRegulationPermission}
												onChange={(e) => inviteOrganisationStore.setCosmeticRegulationPermission(e.target.checked)}
												 />
											<ChemistryPermission checked={inviteOrganisationStore.hasChemistryPermission} 
												onChange={(e) => inviteOrganisationStore.setChemistryPermission(e.target.checked)}/>
											<LanguagesPermission checked={inviteOrganisationStore.hasLanguagesPermission}
												 onChange={(e) => inviteOrganisationStore.setLanguagesPermission(e.target.checked)}/>
											<UsersPermission checked={inviteOrganisationStore.hasUsersPermission}
												 onChange={(e) => inviteOrganisationStore.setUsersPermission(e.target.checked)}/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Grid>
				</div>
			</Main>
		)
	}
}
export default withTranslation()(withAuthorisation(InviteOrganisation, SUPER_ADMINISTRATOR));