import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';

import {inject, observer} from 'mobx-react';
import { SmallBody, ExtraSmallBody, Body, SecondaryAppLargeTitle, SecondaryAppMediumTitle, SecondaryAppSmallTitle, TertiaryAppSmallTitle,TertiaryAppExtraSmallTitle} from '../../common/text';
import { ColorBookIcon, PlusIcon, ArrowRightIcon, EditIcon, DownloadIcon, SearchIcon, FilterDialIcon, ListIcon, ArrowDownIcon, TileIcon } from '../../common/icon'
import {TextInput} from '../../common/input';
import {Button} from '../../common/button';
import {ApplyText} from '../../common/text';
import BaseModal from '../../common/modal';
import ViewPDF from '../../common/view-pdf';
import Main from '../../elements/main';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormSelect,
	FormPasswordInput
} from '../../elements/form';

import { withAuthorisation } from '../../common/authorisation';
import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR } from '../../../services/util';

import Loader from '../../common/loader';
import {SortDialog, SortingField} from '../../elements/list';
import {TagInput, Tag} from '../../common/tag';

import {SearchResultsOverview, NoSearchResults} from '../../user/search-elements';

const colors = require('../../../color.js');

@observer
class MarketingAsset extends Component{
	constructor(props){
		super(props);
		this.onView = this.onView.bind(this);
		this.onEdit = this.onEdit.bind(this);
	}

	onView(e){
		e.stopPropagation();
		let marketingAsset = this.props.marketingAsset;
		this.props.onView(marketingAsset.id);
	}

	onEdit(e){
		e.stopPropagation();
		let marketingAsset = this.props.marketingAsset;
		this.props.onEdit(marketingAsset.id);
	}

	render(){
		let translate = this.props.t;
		let marketingAsset = this.props.marketingAsset;
		return (
			<div className={`flex flex-col overflow-x-scroll w-full bg-white rounded-lg py-8 px-8 rounded-lg border-border-color border cursor-pointer`} onClick={this.onView}>
				<div className={`flex flex-row pb-2 w-full justify-between items-center border-b border-black overflow-y-scroll`}>
					<div className="flex">
						<SecondaryAppMediumTitle>{marketingAsset.title}</SecondaryAppMediumTitle>
					</div>

					<div className="flex" onClick={(e) => {
						e.stopPropagation();
						this.props.onDownload(marketingAsset.id)
					}}>
						<DownloadIcon className="w-4 h-4" />
					</div>
				</div>
				<div className={`flex flex-1 w-full flex-col mt-2`}>
					<div className="flex">
						<SmallBody className="italic">{marketingAsset.subtitle}</SmallBody>
					</div>
					<div className="flex flex-1">
						{marketingAsset.marketing_asset_file?.file_url != null && 
							<ViewPDF 
								style={{ width: '100%', height: '250px', marginTop: '2rem', borderRadius: '5px'}} 
								url={marketingAsset.marketing_asset_file?.file_url} 
								type={marketingAsset.marketing_asset_file?.file_type}
								/>
						}
					</div>
					<div className="flex flex-row w-full items-center justify-end mt-8" onClick={this.onView}>
						<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('org-admin.marketing-asset-search.view-file')} </TertiaryAppSmallTitle></div>
						<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
					</div>
					{this.props.canManage && 
						<div className="flex flex-row w-full items-center justify-end mt-8" onClick={this.onEdit}>
							<div className="flex"><TertiaryAppSmallTitle className="text-error">{translate('org-admin.marketing-asset-search.edit-file')} </TertiaryAppSmallTitle></div>
							<div className="flex ml-2"><EditIcon className="w-4 h-4" color={colors['error']} /></div>
						</div>
					}
				</div>
			</div>	
		)
	}
}

@inject('marketingAssetSearchStore')
@observer
class MarketingAssetSearch extends Component{
	constructor(props){
		super(props);
		this.handleQueryKeyDown = this.handleQueryKeyDown.bind(this);
	}

	componentWillUnmount(){
		let marketingAssetSearchStore = this.props.marketingAssetSearchStore;
		marketingAssetSearchStore.initStore();
	}

	handleQueryKeyDown(event){
	    let marketingAssetSearchStore = this.props.marketingAssetSearchStore;
	    switch (event.key) {
	      case 'Enter':
	      case 'Tab':
	        marketingAssetSearchStore.triggerSearch();
	        event.preventDefault();
	    }
  	}

	
	render(){
		let translate = this.props.t;
		let marketingAssetSearchStore = this.props.marketingAssetSearchStore;
		let resultsContainerClassName = "grid grid-cols-3 gap-4 mt-8"
		if(marketingAssetSearchStore.isListView){
			resultsContainerClassName = "grid grid-cols-1 gap-8 mt-8"
		}

		let currentMarketingAsset = marketingAssetSearchStore.currentMarketingAsset;
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<BaseModal
			            isOpen={currentMarketingAsset != null}
			            onClose={() => marketingAssetSearchStore.hideCurrentMarketingAsset()}
			            label="Marketing Asset View Overview"
			        >
			        	<div className="flex flex-col flex-1 bg-white py-16 px-16 overflow-y-scroll">
							{currentMarketingAsset != null &&
								<>
									<div className={`flex flex-col w-full`}>
										<div className="flex border-b border-black overflow-y-scroll">
											<SecondaryAppMediumTitle>{currentMarketingAsset.title}</SecondaryAppMediumTitle>
										</div>
										<div className="flex mt-1">
											<SmallBody className="italic">{currentMarketingAsset.subtitle}</SmallBody>
										</div>
										<div className="flex mt-1">
											<SmallBody>{currentMarketingAsset.description}</SmallBody>
										</div>
									</div>
									<div className={`flex flex-1 flex-row w-full mt-2`}>
										{currentMarketingAsset.marketing_asset_file?.file_url != null && 
											<ViewPDF 
												style={{ width: '100%', height: '100%', marginTop: '2rem', borderRadius: '5px'}} 
												url={currentMarketingAsset.marketing_asset_file?.file_url} 
												type={currentMarketingAsset.marketing_asset_file?.file_type}
												/>

										}
									</div>
								</>
							}
						</div>	
			        </BaseModal>
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('org-admin.marketing-asset-search.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('org-admin.marketing-asset-search.paragraph.part1')} <br /> {translate('org-admin.marketing-asset-search.paragraph.part2')}</SmallBody>
						</div>
					</div>
					{marketingAssetSearchStore.canUploadMarketingAsset && 
						<div className="flex flex-row w-full justify-end mb-4">
							<Button
								width="w-auto"
								height="h-12"
								className="ml-4 px-4 rounded-lg"
								bgColor="pink-coral"
								fetching={marketingAssetSearchStore.fetching}
								onClick={() => marketingAssetSearchStore.goToAddMarketingAsset()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('org-admin.marketing-asset-search.new-marketing-asset')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><PlusIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						</div>
					}
						
					<div className="flex flex-row items-center w-full mt-16">
						<div className="flex items-center justify-center bg-nav-active-color h-16 rounded-l-lg w-48">
							<SmallBody className="text-white">{translate('user.advanced-search.search-input.label')}</SmallBody>
						</div>
						<TextInput 
							value={marketingAssetSearchStore.query}
							onKeyDown={this.handleQueryKeyDown}
							onChange={(e) => marketingAssetSearchStore.changeSearchQuery(e.target.value)}
							insetoption={<SearchIcon className="w-4 h-4 mb-1" />} 
							placeholder={translate('user.advanced-search.search-input.placeholder')}
							extraInputClassNames="pl-8 border-0 h-16 w-full rounded-r-lg text-nav-inactive-color placeholder-nav-inactive-color"
						/>
					</div>	
					<div className="flex flex-1 flex-col mt-8" >
						{marketingAssetSearchStore.hasSearched && marketingAssetSearchStore.marketingAssetResults.length == 0 && 
							<div className="flex w-full items-center justify-center">
								<SmallBody>{translate('org-admin.marketing-asset-search.nodata')}</SmallBody>
							</div>
						}

						<InfiniteScroll
							  dataLength={marketingAssetSearchStore.marketingAssetResults.length}
							  next={() => marketingAssetSearchStore.nextMarketingAssetsPage()}
							  hasMore={marketingAssetSearchStore.hasMoreMarketingAssetResults}
							  loader={null}
							  endMessage={null}
						>
							<div className={resultsContainerClassName}>
								{marketingAssetSearchStore.marketingAssetResults.map((marketingAsset) => 
									<MarketingAsset 
										key={marketingAsset.id} 
										onView={(id) => marketingAssetSearchStore.viewMarketingAsset(id)}
										onEdit={(id) => marketingAssetSearchStore.editMarketingAsset(id)}
										onDownload={(id) => marketingAssetSearchStore.downloadMarketingAsset(id)}
										marketingAsset={marketingAsset} 
										canManage={marketingAssetSearchStore.canUploadMarketingAsset}
										t={translate} />
								)}
							</div>
						</InfiniteScroll>
					</div>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(MarketingAssetSearch, [ORGANISATION_ADMINISTRATOR, SUPER_ADMINISTRATOR]));