import React from 'react';

import Index from './components/index';
import Pricing from './components/home/pricing';
import Home from './components/home/index';
import Login from './components/home/login';
import RegistrationSignUp from './components/registration/sign-up';
import UpgradeSubscription from './components/registration/upgrade-subscription';
import PaymentConfirmation from './components/registration/payment-confirmation';

import Contact from './components/home/contact';
import Training from './components/home/training';
import PickPlan from './components/home/pick-plan';
import Newsletter from './components/home/newsletter';
import ForgotPassword from './components/home/forgot-password';
import PasswordReset from './components/home/password-reset';
import AcceptInvite from './components/home/accept-invite';
import Terms from './components/home/terms';

import UserProfile from './components/user/profile';

import SuperAdministratorHome from './components/superadmin/index';
import OrganisationAdministratorHome from './components/admin/index';
import AdministratorUsers from './components/admin/users/index';

import ManageUser from './components/admin/users/manage-user';
import InviteUser from './components/admin/users/invite-user';

import SuperAdministratorOrganisations from './components/superadmin/organisations/index';
import ManageOrganisation from './components/superadmin/organisations/manage-organisation';
import InviteOrganisation from './components/superadmin/organisations/invite-organisation';

import ChemistrySettings from './components/superadmin/settings/chemistry/index';

import ManageChemistryFamily from './components/superadmin/settings/chemistry/families/manage-family'
import ManageChemicalComponent from './components/superadmin/settings/chemistry/components/manage-component'

import ManageAcidFamily from './components/superadmin/settings/chemistry/acid-families/manage-family'
import ManageAcidComponent from './components/superadmin/settings/chemistry/acid-components/manage-component'

import Regulations from './components/superadmin/settings/regulations/index';
import ManageRegulation from './components/superadmin/settings/regulations/manage-regulation';

import LabAromaIngredientLibrary from './components/user/ingredients/labaroma-library';
import MyIngredientLibrary from './components/user/ingredients/my-library';
import ManageIngredient from './components/user/ingredients/manage-ingredient'
import IngredientComparison from './components/user/ingredients/comparison/index'
import IngredientComparisonTool from './components/user/ingredients/comparison/manage-tool';

import BlendCategories from './components/user/blends/categories/index';
import ManageBlendCategory from './components/user/blends/categories/manage-category';

import BlendLibrary from './components/user/blends/libraries/my-library';
import LabAromaBlendLibrary from './components/user/blends/libraries/labaroma-library';
import BlendLibraryCategory from './components/user/blends/libraries/blend-library-category';

import ManageBlend from './components/user/blends/libraries/manage-blend';

import LibraryCategories from './components/admin/library-categories/index';
import ManageLibraryCategory from './components/admin/library-categories/manage-category';

import ComponentSearch from './components/user/components/component-search';
import ComponentSearchList from './components/user/components/index';

import Languages from './components/superadmin/settings/languages/index';
import ManageLanguage from './components/superadmin/settings/languages/manage-language';

import EvidenceSearch from './components/user/evidence/evidence-search';
import ManageEvidence from './components/superadmin/evidence/manage-evidence';

import MarketingAssetSearch from './components/admin/marketing-assets/marketing-asset-search';
import ManageMarketingAsset from './components/superadmin/marketing-assets/manage-marketing-asset';

import AdvancedSearch from './components/user/advanced-search';

export const renderView = (store) => {
    const view = store.currentView;

    if('Intercom' in window){
        window.Intercom("update");
    }

    if(view.name != 'edit-web-language-translation-request' || view.name != 'edit-entity-language-translation-request'){
        window.scrollTo(0, 0);
    }

    if(store.showUpgradeSubscription){
        return <UpgradeSubscription view={view} />
    }
    
    switch (view.name) {
    	case 'home':
    		return <Home view={view} />
    	case 'login':
    		return <Login view={view} />
        case 'registration':
            window.location.href = 'https://www.labaroma.com';
            return <Login view={view} />
        case 'payment-confirmation':
            return <PaymentConfirmation view={view} color={view.color} />
        case 'pick-plan':
            return <PickPlan view={view} />
    	case 'training':
        	return <Training view={view} />
        case 'contact':
        	return <Contact view={view} />
        case 'newsletter':
            return <Newsletter view={view} />
        case 'forgot-password':
            return <ForgotPassword view={view} />
        case 'reset-password':
            return <PasswordReset view={view} token={view.token} />
        case 'accept-invite':
            return <AcceptInvite view={view} token={view.token} />
        case 'terms':
            return <Terms view={view} />
        case 'user-profile':
            return <UserProfile view={view} userId={view.userId} />
        case 'super-administrator-home':
            return <SuperAdministratorHome view={view} />
        case 'super-administrator-organisations':
            return <SuperAdministratorOrganisations view={view} />
        case 'organisation-administrator-home':
            return <OrganisationAdministratorHome view={view} />
        case 'administrator-users':
            return <AdministratorUsers view={view} />
        case 'edit-organisation':
            return <ManageOrganisation view={view} organisationId={view.organisationId} />
        case 'edit-user':
            return <ManageUser view={view} userId={view.userId} />
        case 'invite-user':
            return <InviteUser view={view} />
        case 'invite-organisation':
            return <InviteOrganisation view={view} />
        case 'chemistry-settings':
            return <ChemistrySettings view={view} />
        case 'new-chemistry-family':
            return <ManageChemistryFamily view={view} />
        case 'edit-chemistry-family':
            return <ManageChemistryFamily view={view} editMode={true} chemicalFamilyId={view.chemicalFamilyId} />
        case 'new-chemical-component':
            return <ManageChemicalComponent view={view} />
        case 'edit-chemical-component':
            return <ManageChemicalComponent view={view} editMode={true} chemicalComponentId={view.chemicalComponentId} />
        case 'new-acid-family':
            return <ManageAcidFamily view={view} />
        case 'edit-acid-family':
            return <ManageAcidFamily view={view} editMode={true} acidFamilyId={view.acidFamilyId} />
        case 'new-acid-component':
            return <ManageAcidComponent view={view} />
        case 'edit-acid-component':
            return <ManageAcidComponent view={view} editMode={true} acidComponentId={view.acidComponentId} />
        case 'cosmetic-regulations':
            return <Regulations view={view} />
        case 'edit-cosmetic-regulations':
            return <ManageRegulation view={view} regionId={view.regionId} />
        case 'labaroma-ingredient-library':
            return <LabAromaIngredientLibrary view={view} ingredientTypeId={view.ingredientTypeId} />
        case 'my-ingredient-library':
            return <MyIngredientLibrary view={view} ingredientTypeId={view.ingredientTypeId} />
        case 'new-ingredient':
            return <ManageIngredient view={view} />
        case 'edit-ingredient':
            return <ManageIngredient view={view} ingredientId={view.ingredientId} editMode={view.editMode} />
        case 'compare-ingredients':
            return <IngredientComparison view={view} />
        case 'comparison-tool':
            return <IngredientComparisonTool view={view} ingredientIds={view.ingredientIds} />
        case 'edit-comparison-tool':
            return <IngredientComparisonTool view={view} editMode={view.editMode} comparisonReportId={view.comparisonReportId} />
        case 'blend-categories':
            return <BlendCategories view={view} />
        case 'new-blend-category':
            return <ManageBlendCategory view={view} />
        case 'edit-blend-category':
            return <ManageBlendCategory view={view} blendCategoryId={view.blendCategoryId} />
        case 'my-blend-library':
            return <BlendLibrary view={view} />
        case 'labaroma-blend-library':
            return <LabAromaBlendLibrary view={view} />
        case 'blend-library-for-category':
            return <BlendLibraryCategory view={view} libraryCategoryId={view.libraryCategoryId} />
        case 'new-blend':
            return <ManageBlend view={view} ingredients={view.ingredients} />
        case 'edit-blend':
            return <ManageBlend view={view} blendId={view.blendId} editMode={view.editMode} />
         case 'edit-blend-with-ingredients':
            return <ManageBlend view={view} blendId={view.blendId} editMode={true} ingredients={view.ingredients} />
        case 'library-categories':
            return <LibraryCategories view={view} />
        case 'new-library-category':
            return <ManageLibraryCategory view={view} />
        case 'edit-library-category':
            return <ManageLibraryCategory view={view} editMode={view.editMode} libraryCategoryId={view.libraryCategoryId} />
        case 'component-search-list':
            return <ComponentSearchList view={view} />

        case 'component-search':
            return <ComponentSearch view={view} />
        case 'edit-component-search':
            return <ComponentSearch view={view} componentSearchId={view.componentSearchId} editMode={true} />
        case 'languages':
            return <Languages view={view} />
        case 'edit-language':
            return <ManageLanguage view={view} language={view.language} />
        case 'edit-web-language-translation-request':
            return <ManageLanguage 
                view={view} 
                language={view.language} 
                languageOption={0}
                translateKey={view.translateKey} />
        case 'edit-entity-language-translation-request':
            return <ManageLanguage 
                view={view} 
                language={view.language} 
                entityId={view.entityId}
                languageOption={1}
                entityKey={view.entityKey} />
        case 'advanced-search':
            return <AdvancedSearch view={view} query={view.query} />
        case 'evidence-search':
            return <EvidenceSearch view={view} evidenceId={view.evidenceId} />
        case 'new-evidence':
            return <ManageEvidence view={view} />
        case 'manage-evidence':
            return <ManageEvidence view={view} editMode={view.editMode} evidenceId={view.evidenceId} />
        case 'marketing-asset-search':
            return <MarketingAssetSearch view={view} marketingAssetId={view.marketingAssetId} />
        case 'new-marketing-asset':
            return <ManageMarketingAsset view={view} />
        case 'manage-marketing-asset':
            return <ManageMarketingAsset view={view} editMode={view.editMode} marketingAssetId={view.marketingAssetId} />
        case 'pricing':
            return <Pricing view={view} />
        default:
            return <Index view={view} />
    }
}