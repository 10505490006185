module.exports = {
	'black': {
		default: 'rgba(0,0,0, 1)',
		'20': 'rgba(0,0,0, 0.2)'
	},
	'mint-green': {
		default: 'rgba(190, 205, 196, 1)',
		hex: '#becdc4'
	},
	'dark-green': {
		default: 'rgba(37, 57, 46, 1)',
		'60': 'rgba(37, 57, 46, 0.6)',
		hex: '#25392e'
	},
	'petroleum-blue': {
		default: 'rgba(60, 120, 137, 1)',
		'25': 'rgba(60, 120, 137, 0.25)',
	},
	'aubergine': {
		default: 'rgba(78, 58, 77, 1)'
	},
	'mid-blue': {
		default: 'rgba(0, 102, 153, 1)'
	},
	'pink-coral': {
		default: 'rgba(236, 108, 110, 1)',
		'25': 'rgba(236, 108, 110, 0.25)',
		hex: '#ec6c6e'
	},
	'flat-pink':{
		default: 'rgba(251, 145, 159, 1)'
	},
	'flat-purple':{
		default: 'rgba(87, 77, 102, 1)'
	},
	'flat-blue': {
		default: 'rgba(0, 100, 132, 1)',
		'25': 'rgba(0, 100, 132, 0.25)'
	},
	'flat-green': {
		default: 'rgba(89, 120, 115, 1)',
		'25': 'rgba(89, 120, 115, 0.25)'
	},
	'candy-pink': {
		default: 'rgba(247, 194, 187, 1)',
		hex: '#f7c2bb'
	},
	'ochre-yellow': {
		default: 'rgba(248, 166, 40, 1)',
		'20': 'rgba(248, 166, 40, 0.2)',
		'25': 'rgba(248, 166, 40, 0.25)',
	},
	'grey-beige': {
		default: 'rgba(144, 134, 125, 1)'
	},
	'flat-gray': {
		default: 'rgba(242,245,244, 1)'
	},
	'approve-green': {
		default: '#899d98'
	},
	'la-home': {
		default: 'rgba(255, 137, 128, 1)',
		'25': 'rgba(255, 137, 128, 0.25)'
	},
	'la-grow': {
		default: 'rgba(116, 147, 142, 1)',
		'25': 'rgba(116, 147, 142, 0.25)',
	},
	'la-pro': {
		default: 'rgba(69,115,138, 1)',
		'25': 'rgba(69,115,138, 0.25)'
	},
	'success-green': '#A9EAB7',
	'tooltip-bg': '#17273c',
	'nav-active-color': '#1d354d',
	'nav-inactive-color': '#a4a4a4',
	'light-pink': 'rgba(255, 154, 147, 0.2)',
	'cosmetic-pink': 'rgba(236, 108, 110, 1)',
	'error': 'rgba(248, 113, 113, 1)',
	'error-25': 'rgba(210, 35, 42, 0.25)',
	'dark-error': '#ac1222',
	'black': 'rgba(0,0,0, 1)',
	'black-10': 'rgba(0,0,0, 0.1)',
	'black-30': 'rgba(0,0,0, 0.3)',
	'gray-bg-button': 'rgba(217, 219, 220, 1)', 
	'range-option-inactive-bg-color': '#e8eeef',
	'range-option-inactive-fg-color': '#b4bbbb',
	'range-option-active-bg-color': '#d1dee0',
	'range-option-active-fg-color': '#618c98',
	'superadmin-color': '#326676',
	'admin-color': '#e5545c',
	'user-color': '#c3f4a3',
	'list-text': '#97989c',
	'stat-purple': 'rgba(77, 76, 172, 1)',
	'white': '#FFFFFF',
	'stat-label': '#F2FCAE',
	'active-status': '#cadbc2',
	'compliance-bg': '#f1c4bd',
	'pale-yellow': '#FEFFA6',
	'pale-orange': '#F8BA91',
	'pale-red': '#F48681',
	'pale-purple': '#A6ADC6',
	'pale-teal': '#BCD2D2',
	'help-color':{
		default: 'rgba(187, 199, 196, 1)',
		'20': 'rgba(187, 199, 196, 0.2)'
	},
	'modal-color': '#DDDAD7',
	'hover-bg': '#FEE4CB'
};