import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import logoWhiteSrc from '../../assets/img/icons/logo-pink-dot-white.svg';
import logoGreenDotSrc from '../../assets/img/icons/logo-green-dot.svg';
import logoSuperAdminSrc from '../../assets/img/icons/logo-superadmin.svg';
import logoAdminSrc from '../../assets/img/icons/logo-admin.svg';

@inject('appStore')
@observer
class Logo extends Component{
	constructor(props){
		super(props);
		this.onLogo = this.onLogo.bind(this);
	}

	onLogo(){
		window.location.href = 'https://www.labaroma.com';
	}

	render(){
		let renderLogoSrc = null;

		if(this.props.superAdmin){
			renderLogoSrc = logoSuperAdminSrc;
		}else if(this.props.admin){
			renderLogoSrc = logoAdminSrc;
		}else{
			switch(this.props.color){
				case 'white':
					renderLogoSrc = logoWhiteSrc;
					break;
				default:
					 renderLogoSrc = logoGreenDotSrc;
			}
		}

		let logoClassName = 'inline-flex';
		if(this.props.small){
			logoClassName = `${logoClassName} small-logo`
		}else{
			logoClassName = `${logoClassName} logo`
		}

		if(this.props.className){
			logoClassName = `${logoClassName} ${this.props.className}`
		}
		
		return (
			<div className={logoClassName}><img className="cursor-pointer" src={renderLogoSrc} alt="LabAroma Logo" onClick={this.onLogo} /></div>)
	}
}

export default Logo;