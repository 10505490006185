import React, { Component } from 'react';

import {inject, observer} from 'mobx-react';

import {ExitIcon} from '../common/icon';

import CreatableSelect from 'react-select/creatable';

const colors = require('../../color.js');

@observer
class TagInput extends Component{
	constructor(props){
		super(props);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.preventPropagation = this.preventPropagation.bind(this);
	}

	preventPropagation(e){
		e.stopPropagation();
	}

  	handleKeyDown(event){
	    if (!this.props.inputValue) return;
	    switch (event.key) {
	      case 'Enter':
	      case 'Tab':
	        this.props.onNewEntry(this.props.inputValue);
	        event.preventDefault();
	    }
  	}

	render(){
		let translate = this.props.t;
		let placeholder = 'hidePlaceholder' in this.props ? null : 'placeholder' in this.props ? this.props.placeholder : translate('common.tags.placeholder');
		let isSearchable = 'hideIsSearchable' in this.props ? false : true;
		const style = {
		    control: base => ({
		      ...base,
		      border: 0,
		      fontSize: "1rem",
		      // This line disable the blue border
		      boxShadow: "none",
		      textOverflow: "clip",
		      backgroundColor: colors['flat-gray'].default
		    })
		 };
		let containerClassName = "flex w-full";
	    return (
	    	<div className={containerClassName} onClick={this.preventPropagation}>
		      <CreatableSelect	
		      	styles={style}
		      	className="w-full text-sm rounded-md bg-flat-gray placeholder-list-text text-black border-0 rounded-lg"
		        components={{
				  DropdownIndicator: null,
				}}
		        inputValue={this.props.inputValue}
		        value={this.props.values}
		        isClearable={false}
		        isSearchable={isSearchable}
		        isMulti
		        menuIsOpen={false}
		        onChange={this.props.onChange}
		        onInputChange={this.props.onInputChange}
		        onKeyDown={this.handleKeyDown}
		        placeholder={placeholder}
		      />
		    </div>
		)
	}
}

@observer
class Tag extends Component{
	render(){
		return (
			<div className="inline-flex w-auto mr-2 items-center justify-center text-center px-2 py-1 bg-black-10 rounded-lg">
				<span className="text-xs text-black truncate">{this.props.name.toLowerCase()}</span>
				{this.props.canDelete && 
					<div className="cursor-pointer" onClick={() => this.props.onDelete(this.props.id)}>
						<ExitIcon className="w-4 h-4 ml-2" color={colors['list-text']} />
					</div>
				}
			</div>
		)
	}
}

@observer
class AddTag extends Component{
	render(){
		return (
			<div className="flex items-center justify-center rounded-lg border border-gray-400 px-4 py-1 ">
				<span className="text-sm">{this.props.text}</span>
				<span className="ml-2 cursor-pointer" onClick={() => this.props.onDelete(this.props.id)}>
					<ExitIcon className='w-4 h-4' color='black' />
				</span>
 			</div>
		)
	}
}

export {
	Tag,
	TagInput,
	AddTag
}