import React, {Component} from 'react';
import {observer} from 'mobx-react';

import { Body} from './text';

@observer
class Loader extends Component{
	render(){
		let translate = this.props.t;
		return (
			<div className="flex mt-4 rounded-lg items-center justify-center py-2 px-2">
				<Body>{translate('common.loading')}</Body>
			</div>
		)
	}
}

export default Loader