import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import Main from '../../../../elements/main';
import {FullGrid} from '../../../../elements/grid';

import {Button} from '../../../../common/button';
import {SaveIcon, EditIcon} from '../../../../common/icon';

import { withAuthorisation } from '../../../../common/authorisation';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, CHEMISTRY } from '../../../../../services/util';

import {SecondaryAppLargeTitle, SecondaryAppMediumTitle, TertiaryAppSmallTitle, Body, ExtraSmallBody} from '../../../../common/text'
import {
	DataHeader,
	DataRow,
	FormInput,
	FormSelect,
	FormTextArea
} from '../../../../elements/form';

import UploadImage from '../../../../common/upload-image';


@inject('manageChemistryComponent')
@observer
class ManageChemistryComponent extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let manageChemistryComponent = this.props.manageChemistryComponent;
		manageChemistryComponent.fetchChemicalFamilies();
		if('chemicalComponentId' in this.props && this.props.editMode){
			manageChemistryComponent.formSync(this.props.chemicalComponentId);
		}
	}

	componentWillUnmount(){
		let manageChemistryComponent = this.props.manageChemistryComponent;
		manageChemistryComponent.initStore();
	}

	render(){
		let translate = this.props.t;
		let manageChemistryComponent = this.props.manageChemistryComponent;
		return (
			<Main>
				<div className="flex flex-1 flex-col"> 
					<div className="flex flex-row mt-8 items-center justify-between">
						<div className="flex">
							<SecondaryAppLargeTitle className="text-nav-active-color">{manageChemistryComponent.editMode ?  translate('superadmin.manage-chemical-component.edit-title') : translate('superadmin.manage-chemical-component.new-title')}</SecondaryAppLargeTitle>
						</div>
						<div className="flex justify-end">
							<Button
								width="w-auto"
								height="h-12"
								className="rounded-lg"
								bgColor="pink-coral"
								fetching={manageChemistryComponent.fetching}
								disabled={!manageChemistryComponent.validForm}
								onClick={() => manageChemistryComponent.saveChemistryComponent()}
							>
								<div className="flex flex-row items-center justify-center mx-4">
									<div className="flex"><TertiaryAppSmallTitle>{manageChemistryComponent.editMode ? translate('superadmin.manage-chemical-component.update-button') : translate('superadmin.manage-chemical-component.save-new-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						</div>
					</div>
					<FullGrid className="mt-8">
						<div className="flex flex-row">
							<SecondaryAppMediumTitle>{translate('superadmin.manage-chemical-component.component-title')}</SecondaryAppMediumTitle>
						</div>
						<div className="flex flex-1 flex-col lg:flex-row mt-4">
							<div className="flex flex-1 flex-col">
								<div className="flex flex-1 flex-col lg:flex-row">
									<div className="flex flex-1">
										<DataRow label={translate('superadmin.manage-chemical-component.name.label')}>
											<FormInput 
												placeholder={translate('superadmin.manage-chemical-component.name.placeholder')}
												value={manageChemistryComponent.componentData.name.value}
												error={manageChemistryComponent.componentData.name.error}
												onChange={(e) => manageChemistryComponent.componentData.name.onChange(e.target.value)}
											/>
										</DataRow>
									</div>
									<div className="flex flex-1 lg:ml-8">
										<div className="flex flex-1 flex-col">
											<div className="flex"><DataHeader noborder>{translate('superadmin.manage-chemical-component.chemical-family.label')}</DataHeader></div>
											<div className="flex flex-row items-center">
												<FormSelect 
													placeholder={translate('superadmin.manage-chemical-component.chemical-family.placeholder')}
													options={manageChemistryComponent.chemicalFamilyOptions}
													value={manageChemistryComponent.componentData.selectedFamily.value}
													onChange={(option) => manageChemistryComponent.componentData.selectedFamily.onChange(option.value)}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="flex flex-1 flex-row">
									<DataRow label={translate('superadmin.manage-chemical-component.bio.label')}>
										<FormTextArea 
											placeholder={translate('superadmin.manage-chemical-component.bio.placeholder')}
											value={manageChemistryComponent.componentData.bio.value}
											error={manageChemistryComponent.componentData.bio.error}
											onChange={(e) => manageChemistryComponent.componentData.bio.onChange(e.target.value)}
										/>
									</DataRow>
								</div>
							</div>
							<div className="flex flex-col w-full lg:w-2/5 lg:ml-8">
								<div className="flex">
									<DataHeader noborder>{translate('superadmin.manage-chemical-component.molecular-image.label')}</DataHeader>
								</div>

								<UploadImage 
									t={this.props.t} 
									maxFileSize={2}
									description={
										(
											<>
												{translate('common.upload-image.valid-files.part1')} <br /> {translate('common.upload-image.valid-files.part2')} 
											</>
										)
									}
									imageFileUrl={manageChemistryComponent.imageFileUrl}
									onFileChange={(file) => manageChemistryComponent.changeMolecularImage(file)} 
									onDeleteFile={() => manageChemistryComponent.deleteMolecularImage()}
								/>
							</div>
						</div>
					</FullGrid>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(ManageChemistryComponent, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR], [CHEMISTRY]));