import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import {inject, observer} from 'mobx-react';

import {Body, SecondaryWebLargerTitle, SmallBody, SecondaryWebSmallTitle, SecondaryWebExtraSmallTitle, SecondaryWebMediumTitle} from '../common/text';
import { Select } from '../common/input';
import {TickIcon, UnderScoreIcon} from '../common/icon';
import { Button } from '../common/button';

import PricingPlanDetail from './plan-detail';
import BaseModal from '../common/modal';

@observer
class PricingPlan extends Component{
	constructor(props){
		super(props);
		this.discoverMore = this.discoverMore.bind(this);
	}

	discoverMore(){
		this.props.onDiscoverMore(this.props.planId);
	}

	render(){
		let translate = this.props.t;
		let planName = this.props.name.split(' ');
		let lastWord = planName.pop();
		let availablePlans = 'availablePlans' in this.props ? this.props.availablePlans : [];
		return (
			<div className={`flex flex-1 flex-col bg-white mb-8 xl:mb-0 border border-black py-16 px-16`}>
				<div className="flex flex-col w-full overflow-y-scroll" style={{ height: '18rem' }}>
					<SecondaryWebLargerTitle>{planName} <br /> <span className={`text-${this.props.color}`}>{lastWord}</span></SecondaryWebLargerTitle>
					<div className="flex flex-1 w-full mx-auto mt-4">
						<Body>{this.props.description}</Body>
					</div>
				</div>
				<div className="flex text-center justify-center w-full mb-4">
					<SmallBody className="italic">{translate('pricing.money-back-guarantee')}</SmallBody>
				</div>
				<div className="flex flex-col w-full mt-4">
					<>
						{availablePlans.sort((p1, p2) => {
							return p2.period_value > p1.period_value ? -1 : 1;
						}).map((availablePlan) =>
							<div key={availablePlan.id} className="flex flex-col items-start mb-8">
								<Body className="text-black capitalize">{availablePlan.period} {translate('pricing.detail.name')}</Body>
								<SecondaryWebLargerTitle>{availablePlan.periodValue}<SecondaryWebSmallTitle>/{availablePlan.period}</SecondaryWebSmallTitle></SecondaryWebLargerTitle>
								<Body className="text-gray-500 mt-1">{availablePlan.periodDescription}</Body>
							</div>
						)}
					</>
					<div className="flex w-full mt-8">
						{this.props.button}
					</div>
					<div className="flex flex-row mt-4 justify-center" onClick={this.discoverMore}>
						<SecondaryWebExtraSmallTitle className={`cursor-pointer pb-1 border-b border-black`}>{translate("pricing.plan.discover")}</SecondaryWebExtraSmallTitle>
					</div>
				</div>
				<div className="flex flex-col flex-1 mt-8">
					{this.props.largestFeatureSet.map((feature) => {
							let renderIcon = this.props.features.includes(feature) ? <TickIcon /> : <UnderScoreIcon />;
							return (
								<div className="flex flex-1 flex-row justify-between items-center mt-4">
									<Body>{feature}</Body>
									<div>{renderIcon}</div>
								</div>
							)
						})}
				</div>
			</div>
		)
	}
}

@observer 
class ServiceInfoBox extends Component{
	render(){
		return (
			<div className={`flex flex-1 flex-col items-center bg-white border border-black mb-8 xl:${this.props.margin} xl:mb-0 px-8 py-16`}>
				<div className="flex flex-1 flex-col">
					<SecondaryWebMediumTitle className={`text-center`}>{this.props.prefix} <span className={`uppercase text-black`}> <br /> {this.props.service}</span></SecondaryWebMediumTitle>
				</div>
				<div className="flex flex-1 flex-col text-center w-3/4 mt-8 mb-8">
					<Body>{this.props.description}</Body>
					<Body className={`block mt-8`}>{this.props.extraDescription}</Body>
				</div>
				<div className="flex h-16 flex-col items-end">
					<div className="flex justify-center">
						{this.props.button}
					</div>
				</div>
			</div>
		)
	}
}

@inject('pricingStore', 'appStore')
@observer
class Pricing extends Component{
	constructor(props){
		super(props);
		this.onChangePeriod = this.onChangePeriod.bind(this);
		this.onChangeCurrency = this.onChangeCurrency.bind(this);
		this.renderPlanButton = this.renderPlanButton.bind(this);
		this.goToSignUpWithPlan = this.goToSignUpWithPlan.bind(this);
		this.onApprovedLabaromaInstitute = this.onApprovedLabaromaInstitute.bind(this);
		this.onLabaromaElite = this.onLabaromaElite.bind(this);
		this.onDiscoverMore = this.onDiscoverMore.bind(this);
		this.closeDiscoverMoreModal = this.closeDiscoverMoreModal.bind(this);
		this.changeLanguage = this.changeLanguage.bind(this);
	}

	componentDidMount(){
		let pricingStore = this.props.pricingStore;
		pricingStore.initStore();

		pricingStore.fetchCurrencies();
		pricingStore.fetchPlans();
	}

	componentWillUnmount(){
		let pricingStore = this.props.pricingStore;
		pricingStore.initStore();
	}

	onChangePeriod(option){
		let pricingStore = this.props.pricingStore;
		pricingStore.onChangePeriod(option.value);
	}

	onChangeCurrency(option){
		let pricingStore = this.props.pricingStore;
		pricingStore.onChangeCurrency(option.value);
	}

	goToSignUpWithPlan(planId){
		let pricingStore = this.props.pricingStore;
		pricingStore.goToSignUpWithPlan(planId);
	}

	renderPlanButton(plan){
		let translate = this.props.t;
		let planCTA = `${translate("pricing.plan.button.signup")}`;
		return (<Button 
				width="w-full"
				fgColor="white" 
				bgColor={plan.color}
				onClick={this.goToSignUpWithPlan.bind(this, plan.id)}>{planCTA}</Button>)
	}

	onLabaromaElite(){
		let appStore = this.props.appStore;
		appStore.goToContact();
	}

	onApprovedLabaromaInstitute(){
		let appStore = this.props.appStore;
		appStore.goToContact();
	}

	onDiscoverMore(planId){
		let pricingStore = this.props.pricingStore;
		pricingStore.discoverMore(planId);
	}

	closeDiscoverMoreModal(){
		let pricingStore = this.props.pricingStore;
		pricingStore.closeDiscoverMoreModal();
	}

	changeLanguage(option){
    	this.props.appStore.changeLanguage(option.value);
	}

	render(){
		let pricingStore = this.props.pricingStore;
		let appStore = this.props.appStore;
		let translate = this.props.t;
		let t = this.props.t;


		return (
			<Fragment>
				<BaseModal
		            isOpen={pricingStore.showDiscoverMoreModal}
		            onClose={this.closeDiscoverMoreModal}
		            label={translate("pricing.plan.discover")}>
						{pricingStore.currentPlan != null && 
							<PricingPlanDetail
								t={translate}
								color={pricingStore.currentPlan.color}
								planId={pricingStore.currentPlan.id}
								name={pricingStore.currentPlan.name}
								largestFeatureSet={pricingStore.largestFeatureSet}
								features={pricingStore.currentPlan.features}
								detailed_features={pricingStore.currentPlan.detailed_features}
								detail_description={pricingStore.currentPlan.detail_description}
								button={this.renderPlanButton(pricingStore.currentPlan)}
								availablePlans={pricingStore.getPlansForAvailablePeriods}
								signUpWithPlan={this.goToSignUpWithPlan}
							/>
						}
		        </BaseModal>
				<div className="flex flex-1 flex-col items-center w-full h-min-screen mb-24">
					<div className="flex w-full flex-col text-center bg-white mt-24">
						<SecondaryWebLargerTitle>{t('pricing.title')}</SecondaryWebLargerTitle>
						<div className="flex flex-1 w-1/2 mx-auto justify-center mt-16">
							<Body>{translate("pricing.description")}</Body>
						</div>
					</div>
					<div className="flex flex-1 flex-col w-full h-auto px-8 xl:px-40 mt-24">
						<div className="flex flex-row justify-end">
							<div className="flex flex-col items-center xl:flex-row w-full justify-center xl:w-2/3 xl:justify-between">
								<div className="flex flex-row items-center">
									<Body className="inline-flex uppercase tracking-wider">{translate('pricing.billing')}:</Body>
									<Select 
										uppercase
										value={pricingStore.currentPeriod}
										options={pricingStore.periodOptions}
										onChange={this.onChangePeriod}
										iconColor="black"
									/>
								</div>
								<div className="flex flex-row items-center mt-6 xl:mt-0">
									<Body className="inline-flex uppercase tracking-wider">{translate('pricing.currency')}:</Body>
									<Select 
										uppercase
										value={pricingStore.currentCurrency}
										options={pricingStore.currencyOptions}
										onChange={this.onChangeCurrency} 
										iconColor="black"
									/>
								</div>
								<div className="flex flex-row items-center mt-6 xl:mt-0">
									<Body className="inline-flex uppercase tracking-wider">{translate('pricing.language')}:</Body>
									<Select 
										uppercase
										value={appStore.currentLanguage}
										options={appStore.languageOptions}
										onChange={this.changeLanguage} 
										iconColor="black"
									/>
								</div>
							</div>
						</div>
						
						<div className="flex flex-1 flex-row w-full mt-6">
							<div className="flex flex-1 grid grid-cols-1 xl:grid-cols-3 gap-8">
								{pricingStore.currentPlans.map((plan, idx) => 
										<PricingPlan
											t={translate}
											planId={plan.id}
											color={plan.color}
											name={plan.name}
											description={plan.long_description}
											periodDescription={plan.short_description}
											features={plan.features}
											availablePlans={pricingStore.getAllPlansForName(plan.name)}
											largestFeatureSet={pricingStore.largestFeatureSet}
											button={this.renderPlanButton(plan)}
											onDiscoverMore={this.onDiscoverMore}
										/>
								)}
							</div>

						</div>
					</div>
					<div className="flex w-full flex-col xl:flex-row w-full xl:mt-8 p-8 xl:px-40 grid grid-cols-1 xl:grid-cols-2 gap-8">
						<ServiceInfoBox 
							prefix={translate("pricing.elite.prefix")}
							service={translate("pricing.elite.service")}
							color="flat-pink"
							description={
								<>
									<Body>{translate("pricing.elite.part1")}</Body>
									<Body className="block mt-4">{translate("pricing.elite.part2")} <br /> <SecondaryWebSmallTitle><a href="mailto:elite@labaroma.com">elite@labaroma.com</a></SecondaryWebSmallTitle></Body>
								</>
							}
							extraDescription={translate("pricing.elite.extraDescription")}
							button={
								<Button
									className="w-full px-4"
									fgColor="white"
									bgColor="grey-beige"
									onClick={this.onLabaromaElite}><SmallBody>{translate("pricing.discover")}</SmallBody></Button>
							}
						 />
						<ServiceInfoBox 
							prefix={translate("pricing.institute.prefix")}
							service={translate("pricing.institute.service")}
							description={translate("pricing.institute.description")}
							color="flat-purple"
							button={
								<Button
									className="w-full px-4"
									fgColor="white"
									bgColor="grey-beige"
									onClick={this.onApprovedLabaromaInstitute}><SmallBody>{translate("pricing.discover")}</SmallBody></Button>
							}
						/>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default withTranslation()(Pricing);