import React, {Component, Fragment} from 'react'
import {inject, observer} from 'mobx-react';

import { withTranslation } from 'react-i18next';

import {Button} from './common/button';
import { Select } from './common/input';
import Logo from './common/logo';
import Footer from './nav/footer';

import Pricing from './home/pricing';

import {PlayIcon, ArrowDownIcon, BarIcon, ExitIcon, GlobeIcon, CountryIcon} from './common/icon'

import {Body, HomepageNavItem, HomepageTitle, HomepageSubTitle, SecondaryWebExtraLargeTitle, SecondaryWebSmallTitle, CTATitle} from './common/text';

import bgHomeJarSrc from '../assets/img/bg-home-jar.jpg';


class HalfFeatureHalfCTA extends Component{
	render(){
		return (
			<div className={`flex flex-1 flex-col justify-between lg:flex-row h-auto lg:h-720-px p-8 lg:py-32 lg:px-40 bg-${this.props.color}`}>
	          <div className="flex justify-center items-center mb-16 lg:mb-0">
	           	{this.props.left}
	          </div>
	          <div className="flex w-32"></div>
	          <div className="flex flex-1 justify-center items-center ">
	            {this.props.right}
	          </div>
	        </div>
		)
	}
}

@observer
class MarketingNavBar extends Component{
	constructor(props){
		super(props);

		this.toggleMobileNav = this.toggleMobileNav.bind(this);
		this.toggleLanguageNav = this.toggleLanguageNav.bind(this);
		this.renderLanguageOptions = this.renderLanguageOptions.bind(this);
		this.state = {
			showMobileNav: false,
			showLanguageNav: false
		}
	}

	toggleMobileNav(){
		this.setState({
			showMobileNav: !this.state.showMobileNav
		})
	}

	toggleLanguageNav(){
		this.setState({
			showLanguageNav: !this.state.showLanguageNav
		})
	}

	renderLanguageOptions(){
		let appStore = this.props.appStore;
		return appStore.languages.map((language) => {
			return {
	            label: (
	                <div className="flex flex-row items-center justify-end">
	                    <div className="w-4 h-4 mr-2"><CountryIcon country={language.id} className="rounded-full" /></div>
	                    <span>{language.id.toUpperCase()}</span>
	                </div>
	            ),
	            value: language.id
	        }
		})
	}

	render(){
		let translate = this.props.translate;
		let appStore = this.props.appStore;
		return (
			<div className="absolute flex flex-1 w-full items-center justify-between p-8 lg:px-16 lg:py-8 z-40">
	            <div className="flex">
	            	<Logo small />
	            </div>
	            <div className="flex flex-row justify-between hidden lg:inline-flex">
	              	<HomepageNavItem onClick={this.props.onPricing}>{translate('index.nav.item1')}</HomepageNavItem>
	              	<HomepageNavItem onClick={this.props.onAbout}className="ml-10">{translate('index.nav.item2')}</HomepageNavItem>
	              	<HomepageNavItem onClick={this.props.onContact} className="ml-10">{translate('index.nav.item3')}</HomepageNavItem>
	            </div>
	            <div className="flex flex-row items-center hidden lg:inline-flex">
	            	<div className="flex flex-row items-center mr-8">
	              		<div className="flex relative mt-1 mr-2">
		              		<Select 
		              			bgColor="transparent"
		              			textColor="black"
		              			iconColor="black"
		              			borderColor="transparent"
								value={appStore.currentLanguage}
								options={this.renderLanguageOptions()}
								onChange={this.props.onChangeLanguage}
							/>
						</div>
	          			<div className="flex"><GlobeIcon color="black" className="w-6 h-6" /></div>
		          	</div>
	              <Button
	                bgColor="transparent"
	                fgColor="black"
	                borderColor="black"
	                onClick={this.props.onLogin}
	              >{translate('index.nav.login')}</Button>
	              <Button
	                bgColor="black"
	                fgColor="white"
	                borderColor="black"
	                onClick={this.props.onSignUp}
	                className="ml-10"
	              >{translate('index.nav.signup')}</Button>
	            </div>
	            <div className="block lg:hidden">
	            	<BarIcon className="w-8 h-8 cursor-pointer" color="#000" onClick={this.toggleMobileNav} />
	            </div>
	            {this.state.showMobileNav && 
	            	<div className="absolute top-0 inset-x-0 transition transform origin-top-right lg:hidden">
				        <div className="bg-white overflow-hidden">
				          <div className="pl-8 pt-8 pr-8 flex items-center justify-between">
				            <div>
				              <Logo small />
				            </div>
				            <div className="-mr-2">
				              <button type="button" className="inline-flex items-center justify-center p-2 rounded-md text-black">
				                <ExitIcon className="w-8 h-8" onClick={this.toggleMobileNav} />
				              </button>
				            </div>
				          </div>
				          <div className="ml-8">
				          	<HomepageNavItem onClick={this.props.onPricing} className="text-black mt-10">{translate('index.nav.item1')}</HomepageNavItem>
		              		<HomepageNavItem className="text-black mt-10">{translate('index.nav.item2')}</HomepageNavItem>
		              		<HomepageNavItem onClick={this.props.onContact} className="text-black mt-10">{translate('index.nav.item3')}</HomepageNavItem>
				          </div>
				          <div className="mt-6">
				            <div className="flex flex-row items-center">
			              		<div className="flex relative ml-8">
				              		<Select 
				              			bgColor="white"
				              			textColor="black"
				              			iconColor="black"
				              			borderColor="transparent"
										value={appStore.currentLanguage}
										options={this.renderLanguageOptions()}
										onChange={this.props.onChangeLanguage}
									/>
								</div>
			          			<div className="flex"><GlobeIcon color="white" className="w-6 h-6" /></div>
				          	</div>
				            <Button
				                bgColor="black"
				                fgColor="white"
				                borderColor="black"
				                className="block w-full"
				                 onClick={this.props.onLogin}
				              >{translate('index.nav.login')}</Button>
				            <Button
				                bgColor="white"
				                fgColor="black"
				                borderColor="white"
				                className="block w-full"
				                onClick={this.props.onSignUp}
				              >{translate('index.nav.signup')}</Button>
				          </div>
				        </div>
				    </div>
				}
          	</div>
		)
	}
}

class FeatureSection extends Component{
	render(){
		let iconSection = (
          <div className="flex flex-1 flex-col w-full h-full mb-16 lg:mb-0 sm:w-auto sm:h-auto items-start justify-center">
            {this.props.featureIcon}
          </div>
		)

		let featureSection = (
			<div className="flex flex-1">
              <div className="flex flex-1 flex-col items-start justify-center">
                  <SecondaryWebExtraLargeTitle className="flex w-full justify-center text-center lg:block lg:text-left">{this.props.title}</SecondaryWebExtraLargeTitle>
                  <div className="mt-8 lg:mt-16 w-full lg:w-3/4">
                  	<Body className="tracking-widest text-center lg:text-left">{this.props.description}</Body>
                  </div>
              </div>
            </div>
		)

		let learnSection = this.props.learn ? (
			<div className="flex mt-16 w-full items-start justify-center">
	            <div className="flex flex-col text-center justify-center items-center">
	            	<SecondaryWebSmallTitle className="uppercase tracking-wider">{this.props.learn}</SecondaryWebSmallTitle>
	            	<div className="mt-4">{this.props.learnIcon}</div>
	            </div>
	          </div>
		) : null;

		let sectionOrdering = this.props.right ? [iconSection, featureSection] : [featureSection, iconSection];

		let height = this.props.height ? this.props.height : 'h-screen';
		return (
			<div className={`flex flex-1 flex-col w-full h-auto bg-white px-8 lg:px-40 lg:py-16 mb-8`}>
	         	<div className="flex flex-1 flex-col lg:flex-row items-center justify-center">
	          		{sectionOrdering[0]}
	          		<div className="flex hidden lg:block w-64"></div>
	          		{sectionOrdering[1]}
	          	</div>
	          	{learnSection}
	        </div>
		)
	}
}


@inject('appStore')
@observer
class Index extends Component{
	constructor(props){
		super(props);
		this.goToPickAPlan = this.goToPickAPlan.bind(this);
		this.scrollToPricing = this.scrollToPricing.bind(this);
		this.goToContact = this.goToContact.bind(this);
		this.goToLogin = this.goToLogin.bind(this);
		this.exploreFeatures = this.exploreFeatures.bind(this);

		this.pricingRef = React.createRef() 
	}

	componentDidMount(){
		if(this.props.goTo != null){
			if(this.props.goTo == 'pricing'){
				this.scrollToPricing();
			}
		}
	}

	scrollToPricing(){
		window.scrollTo(0, this.pricingRef.current.offsetTop)
	}

	goToPickAPlan(){
		this.props.appStore.goToPickAPlan();
	}

	goToContact(){
		this.props.appStore.goToContact();
	}

	goToLogin(){
		this.props.appStore.goToLogin();
	}

	exploreFeatures(){
		this.props.appStore.goToPickAPlan();
	}

	render(){
		let translate = this.props.t;
		let appStore = this.props.appStore;
		return (
			<div>
        		<div className="flex flex-1 flex-row h-screen">
        			<MarketingNavBar 
        				translate={translate} 
        				appStore={appStore}
        				onChangeLanguage={(option) => appStore.changeLanguage(option.value)}
        				onPricing={this.scrollToPricing}
        				onAbout={() => appStore.goToAbout()}
        				onSignUp={this.goToPickAPlan}
        				onContact={this.goToContact}
        				onLogin={this.goToLogin}
        				 />
        			<div className="flex w-full lg:w-1/3 bg-white relative">
			            <div className="flex flex-1">
			            </div>
			        </div>
			        <div className="flex lg:w-2/3 bg-white">
			        	<div className="lg:block relative w-0 flex-1">
			            	<img className="absolute inset-0 h-full w-full object-cover" src={bgHomeJarSrc} alt="LabAroma Power of Plants" />
			            </div>
			        </div>
			        <div className="absolute w-full h-full flex flex-1 flex-col justify-center px-16 mt-16">
			            <HomepageSubTitle className="text-center lg:text-left">{translate('index.main.subtitle')}</HomepageSubTitle>
			            <HomepageTitle className="text-center lg:text-left">{translate('index.main.title.part1')} <br /> {translate('index.main.title.part2')}</HomepageTitle>
			            
			            <div className="mt-32">
			            	<div className="flex justify-center lg:justify-start">
				              <Button
				                  bgColor="black"
				                  fgColor="white"
				                  borderColor="black"
				                  width="w-64"
				                  onClick={this.goToPickAPlan}
				                >{translate('index.main.actionBtn')}</Button>
				            </div>
			            </div>
			          </div>
        		</div>
	        	<div ref={this.pricingRef}>
	        		<Pricing />
	        	</div>
		        <Footer />
        	</div>
		)
	}
}

export default withTranslation()(Index);