import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';

import {SecondaryAppMediumTitle, ExtraSmallBody, SmallBody} from '../common/text';
import { withTranslation } from 'react-i18next';
import {HelpTooltip} from '../common/input';

@observer
class NotificationCenter extends Component{
	constructor(props){
		super(props);
		this.onNotification = this.onNotification.bind(this);
	}

	onNotification(e, notification){
		e.stopPropagation();
		this.props.goToNotification(notification);
	}

	render(){
		let translate = this.props.t;
		let totalNotifications = this.props.totalNotifications;
		let notifications = this.props.notifications;
		return (
			<div className="flex flex-1 flex-col">
				<div className="flex flex-row items-center">
					<div className="flex flex-col">
						<div className="flex flex-row items-center">
							<SecondaryAppMediumTitle>{translate('superadmin.home.notification-preview.title')}</SecondaryAppMediumTitle>
							<HelpTooltip text={this.props.tooltipText} />
						</div>
						{totalNotifications != null && totalNotifications > 0 && <SmallBody>{totalNotifications} {translate('superadmin.home.notification-preview.subtitle')}</SmallBody>}
					</div>
				</div>
				<div className="flex flex-1 flex-col mt-4 overflow-y-scroll">
					{notifications != null && notifications.map((notification) => 
						<div className="flex flex-1 flex-row items-center justify-between mt-8">
							<div className="flex flex-row items-start w-2/3">
								<div className={`flex flex-shrink-0 w-8 h-8 justify-center items-center rounded-lg bg-${notification.color}`}>
									<SmallBody className="text-white bold-font">{notification.notification_metadata.type.charAt(0).toUpperCase()}</SmallBody>
								</div>
								<div className="flex flex-row items-start ml-2">
									<div className="flex flex-col ml-2">
										<div className="flex"><SmallBody className="text-black">{notification.title}</SmallBody></div>
										<div className="flex" onClick={(e) => this.onNotification(e, notification)}><ExtraSmallBody className="mt-2 bold-font text-petroleum-blue cursor-pointer">{notification.action_text}</ExtraSmallBody></div>
									</div>
								</div>
							</div>
							{notification.is_open && 
								<div className="flex w-3 h-3 border-radius-50 bg-pink-coral"></div>
							}
						</div>
					)}
					{notifications != null && notifications.length == 0 && 
						<div className="flex flex-row">
							<SmallBody>{translate('superadmin.home.notification-preview.none-found')}</SmallBody>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default withTranslation()(NotificationCenter);