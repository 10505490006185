
import { observable, action, computed, extendObservable } from 'mobx';
import { FieldState, FormState } from 'formstate';
import { isRequiredValidator, isNotNullValidator, isPositiveNumberValidator, validator } from '../../../../services/validation';

import IngredientApi from '../../../../services/ingredient';
import BlendApi from '../../../../services/blend';
import CommonApi from '../../../../services/common';
import CurrencyApi from '../../../../services/currency';
import S3Api from '../../../../services/s3';
import ChemistryApi from '../../../../services/chemistry';
import LibraryApi from '../../../../services/library';

import uuidv4 from 'uuid/v4';
import moment from 'moment';

const colors = require('../../../../color.js');

import {MEASUREMENTS} from '../../../../services/util';


class ManageBlendStore{
	blendApi;
	ingredientApi;
	commonApi;
	currencyApi;
	s3Api;
	chemistryApi;
	libraryApi;

	id;

	@observable formulating;
	@observable currentBlend;
	@observable blendData;
	@observable blendCategories;
	@observable ingredients;
	@observable blends;

	@observable ingredientTypes;
	@observable currentIngredientTypeId;

	@observable ingredientSearchText;

	@observable currentBlendVolume;
	@observable showDetailedBlendResults;

	@observable physicalRankings;
	@observable psychologicalRankings;

	@observable chemicalFamilies;

	@observable regions;

	@observable currentBlendResults;

	@observable showChemicalComponentBreakdown;
	@observable fetching;
	@observable addMode;
	@observable editMode;
	@observable viewMode;

	@observable currentHoverChemicalFamily;
	@observable currentLargeHoverChemicalFamily;

	@observable showChemicalFamilyModal;
	@observable currentChemicalFamily;

	@observable ingredientsPage;
	@observable hasMoreIngredients;
	@observable fetchingIngredients;

	@observable blendsPage;
	@observable hasMoreBlends;
	@observable fetchingBlends;

	@observable renderedPieChartBase64Url;
	@observable renderedBlendJarBase64Url;

	@observable blendLibraryCategories;

	@observable displayMeasurement;

	@observable currencies;
	@observable currentCurrency;
	@observable usdSpotRates;

	@observable showPDFDownloadModal;
	@observable pdfDownloadOptions;
	@observable pdfGenerating;

	@observable currentAction;
	@observable searchTimeout;

	INITIAL_BREAKDOWN_LIMIT = 7;
	
	constructor(appStore){
		this.appStore = appStore;
		this.blendApi = new BlendApi(appStore);
		this.ingredientApi = new IngredientApi(appStore);
		this.commonApi = new CommonApi(appStore);
		this.currencyApi = new CurrencyApi(appStore);
		this.s3Api = new S3Api(appStore);
		this.chemistryApi = new ChemistryApi(appStore);
		this.libraryApi = new LibraryApi(appStore);
		this.initStore();
	}

	initStore(){
		this.id = null;
		this.blendData = {
			name: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			selectedCategory: new FieldState(null),
			selectedLibrary: new FieldState(null),
			currentTag: null,
			tags: [],
			essentialOilBlendPercentage: new FieldState(100).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n)),
			totalBlendVolume: new FieldState(100).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n)),
			selectedBlendMeasurement: new FieldState(null).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			selectedBlendRegion: new FieldState(null),
			currentBlendIngredients: [],
			editBlendIngredient: null,
			editBlendVolume: new FieldState(null).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n)),
			editBlendMeasurement: new FieldState(null).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			defaultBlendMeasurement: null,
			uploadedFiles: [],
			editingBlendIngredient: false,
			blendNotes: [],
			legacyBlend: false,
			selectedBlendLibraryCategory: null,
			blendLibraryCategories: []
		}
		this.fetchingIngredients = false;
		this.displayMeasurement = null;
		this.currentBlend = null;
		this.currentIngredientTypeId = null;
		this.blendCategories = [];
		this.ingredients = [];
		this.blends = [];
		this.ingredientTypes = [];
		this.ingredientSearchText = null;
		this.showDetailedBlendResults = true;
		this.currentBlendResults = null;
		this.physicalRankings = [];
		this.psychologicalRankings = [];
		this.showChemicalComponentBreakdown = false;
		this.regions = [];
		this.chemicalFamilies = [];
		this.fetching = false;
		this.addMode = true;
		this.editMode = false;
		this.viewMode = false;
		this.currentHoverChemicalFamily = null;
		this.currentLargeHoverChemicalFamily = null;
		this.showChemicalFamilyModal = false;
		this.currentChemicalFamily = null;
		this.ingredientsPage = 1;
		this.hasMoreIngredients = true;
		this.blendsPage = 1;
		this.hasMoreBlends = true;
		this.fetchingBlends = false;
		this.renderedPieChartBase64Url = null;
		this.renderedBlendJarBase64Url = null;
		this.blendLibraryCategories = [];
		this.currencies = [];
		this.currentCurrency = null;
		this.usdSpotRates = [];
		this.showPDFDownloadModal = false;
		this.pdfDownloadOptions = [];
		this.currentAction = null;
		this.pdfGenerating = false;
		if (this.searchTimeout != null){
			clearTimeout(this.searchTimeout);
		}
		this.searchTimeout = null;
	}

	setupPDFOptions(){
		this.pdfDownloadOptions = [
			{
				label: `${this.appStore.i18n.t("user.manage-blend.download-all")}`,
				id: 0, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-blend.blend-lab.title")}`,
				id: 1,
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-blend.chemical-families.title")}`,
				id: 2, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-blend.chemical-breakdown.title")}`,
				id: 3, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-blend.compliance.title")}`,
				id: 4, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-blend.show-effects")}`,
				id: 5, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-blend.blend-notes.title")}`,
				id: 6, 
				checked: true
			},
			{
				label: `${this.appStore.i18n.t("user.manage-blend.blend-pricing.title")}`,
				id: 7, 
				checked: true
			}
		];
		
	}

	toggleShowPDFDownload(){
		this.showPDFDownloadModal = !this.showPDFDownloadModal;
	}

	showPDFDownload(action){
		this.showPDFDownloadModal = true;
		this.currentAction = action;
	}


	resetPDFModal(){
		this.currentAction = null;
		this.showPDFDownloadModal = false;
		this.pdfGenerating = false;
	}

	togglePDFGenerating(){
		this.pdfGenerating = !this.pdfGenerating;
	}

	setRenderedPieChartUrl(base64url){
		this.renderedPieChartBase64Url = base64url;
	}

	setRenderedBlendJarUrl(base64url){
		this.renderedBlendJarBase64Url = base64url;
	}

	@computed get canDownloadPDF(){
		return this.renderedBlendJarBase64Url != null;
	}

	fetchBlendCategories(){
		this.fetchBlendCategoriesAsync();
	}

	fetchBlendCategoriesAsync = async () => {
		try{
			this.blendCategories = await this.blendApi.getBlendCategories();
		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}

	retrieveAllIngredients(){
		this.ingredientApi.getAllIngredients(page, this.ingredientSearchText, null, null, ingredientTypeId, favourites, false, null)
				.then((response) => {
					let newIngredients = response.ingredients;
					if(newIngredients.length == 0){
						dataRemaining = false;
					}else{
						this.ingredients = this.ingredients.concat(newIngredients);
						page += 1;
					}
				})
				.catch((error) => {
					dataRemaining = false;
				})
	}

	fetchIngredients(reset=false){
		let ingredientTypeId = this.currentIngredientTypeId != -1 && this.currentIngredientTypeId != -2 ? this.currentIngredientTypeId : null;
		let favourites = this.currentIngredientTypeId == -1 ? true : null;
		let showMyIngredients = this.currentIngredientTypeId == -2 ? true : null;
		let showPublic = showMyIngredients ? false : null;
		this.fetchingIngredients = true;
		this.ingredientApi.getAllIngredients(this.ingredientsPage, this.ingredientSearchText, null, null, ingredientTypeId, favourites, false, showPublic)
				.then((response) => {
					let newIngredients = response.ingredients;
					this.ingredients = reset ? response.ingredients : this.ingredients.concat(newIngredients);
					this.hasMoreIngredients = response.ingredients.length > 0;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetchingIngredients = false;
				})
	}

	fetchBlends(reset=false){
		if(!this.showingBlends) return;
		this.fetchingBlends = true;
		this.blendApi.getAllBlends(this.blendsPage, this.ingredientSearchText, 'name', null, null, false, null, null, null, null)
				.then((response) => {
					let newBlends = response.blends;
					this.blends = reset ? response.blends : this.blends.concat(newBlends);
					this.hasMoreBlends = response.blends.length > 0;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetchingBlends = false;
				})
	}

	fetchIngredientTypes(){
		this.fetchIngredientTypesAsync();
	}

	fetchIngredientTypesAsync = async () => {
		this.fetching = true;
		try{
			let response = await this.ingredientApi.getAllIngredientTypes()
			this.ingredientTypes = response.ingredient_types;
			if(this.ingredientTypes.length > 0){
				this.currentIngredientTypeId = this.ingredientTypes[0].id;
			}
		}catch(e){
			console.log(e);
		}finally{
			this.fetching = false;
		}
	}

	fetchChemicalFamilies(){
		this.fetchChemicalFamiliesAsync();
	}

	fetchChemicalFamiliesAsync = async () => {
		this.fetching = true;
		try{
			this.chemicalFamilies = await this.chemistryApi.getChemicalFamilies()
		}catch(e){
			console.log(e);
		}finally{
			this.fetching = false;
		}
	}

	setDefaultBlendMeasurement(){
		if(this.measurementOptions.length > 0){
			this.blendData.selectedBlendMeasurement.value = this.measurementOptions[0].value;
		}
	}

	@computed get blendCategoryOptions(){
		return this.blendCategories.map((b) => {
			return {
				value: b.id,
				label: b.name
			}
		})
	}

	@computed get ingredientTypeOptions(){
		let ingredientTypeOptions = this.ingredientTypes.map((x) => x);
		ingredientTypeOptions.push({
			id: -1,
			name: this.appStore.i18n.t('user.manage-blend.ingredients.favourite-tab')
		});
		ingredientTypeOptions.push({
			id: -2,
			name: this.appStore.i18n.t('user.manage-blend.ingredients.my-ingredients-tab')
		});
		ingredientTypeOptions.push({
			id: -3,
			name: this.appStore.i18n.t('user.manage-blend.ingredients.my-blends-tab')
		});
		
		return ingredientTypeOptions
	}

	@computed get showingBlends(){
		return this.currentIngredientTypeId === -3;
	}

	@computed get currentIngredients(){
		let isFavourited = this.currentIngredientTypeId == -1;
		let isMyIngredients = this.currentIngredientTypeId == -2;
		let allIngredients = this.ingredients;

		let currentBlendIngredients = this.blendData.currentBlendIngredients.map((i) => parseInt(i.ingredient.id, 10));
		allIngredients = allIngredients.filter((i) => !currentBlendIngredients.includes(i.id));
		if(isFavourited){
			return allIngredients.filter((i) => i.favourited == true);
		}else if(isMyIngredients){
			return allIngredients;
		}else{
			return allIngredients.filter((i) => i.ingredient_type?.id == this.currentIngredientTypeId);
		}
	}

	@computed get currentBlends(){
		if(!this.showingBlends) return [];

		return this.blends;
	}

	@action removeBlendIngredientByUUID(uuid){
		this.blendData.currentBlendIngredients = this.blendData.currentBlendIngredients.filter((b) => b.uuid != uuid);
		this.getBlendPreview();
	}

	changeIngredientSearchText(val){
		this.ingredientSearchText = val;

		if (this.searchTimeout != null){
			clearTimeout(this.searchTimeout);
		}

		this.searchTimeout = setTimeout(() => {
			this.resetFetchIngredients();	
		}, 500);
	}

	changeCurrentIngredientType(val){
		this.currentIngredientTypeId = val;
		this.resetFetchIngredients();
	}

	changeEssentialOilBlendPercentage(val, refresh=false){
		let parsedInput = val;

		let parsedFloatInput = parseFloat(val);
		if(parsedFloatInput < 0){
			val = 0;
		} 
		if(parsedFloatInput > 100){
			val = 100
		} 
		if(isNaN(parsedFloatInput)){
			parsedInput = '0'
		}

		this.blendData.essentialOilBlendPercentage.value = parsedInput;
		if(refresh){
			this.getBlendPreview();
		}
	}

	@action changeBlendVolume(val){
		let parsedInput = parseFloat(val);
		if(isNaN(parsedInput)) return;
		if(parsedInput < 0){
			parsedInput = 0
		} 
		this.blendData.totalBlendVolume.value = parsedInput;
	}

	@action changeBlendMeasurement(val){
		this.blendData.selectedBlendMeasurement.value = val;
		this.getBlendPreview();
	}

	@action changeDisplayBlendMeasurement(val){
		this.displayMeasurement = val;
	}

	@computed get measurementOptions(){
		return [
			{
				value: MEASUREMENTS.ml,
				label: this.appStore.i18n.t('user.manage-blend.measurements.ml')
			},
			{
				value: MEASUREMENTS.drops,
				label: this.appStore.i18n.t('user.manage-blend.measurements.drops')
			},
			{
				value: MEASUREMENTS.grams,
				label: this.appStore.i18n.t('user.manage-blend.measurements.grams')
			},
			{
				value: MEASUREMENTS.ounces,
				label: this.appStore.i18n.t('user.manage-blend.measurements.ounces')
			},
			{
				value: MEASUREMENTS.percentage,
				label: this.appStore.i18n.t('user.manage-blend.measurements.percentage')
			}
		]
	}

	@computed get displayMeasurementOptions(){
		// all but percentage
		return this.measurementOptions.filter((m) => m.value != MEASUREMENTS.percentage);
	}

	@action getBlendEntryAmountToShow(entry){
		if(this.displayMeasurement == MEASUREMENTS.drops){
			return `${entry.amount_in_drops} ${this.appStore.i18n.t('user.manage-blend.measurements.drops')}`
		}else if(this.displayMeasurement == MEASUREMENTS.ounces){
			return `${entry.amount_in_ounces} ${this.appStore.i18n.t('user.manage-blend.measurements.ounces')}`	
		}else if(this.displayMeasurement == MEASUREMENTS.grams){
			return `${entry.amount_in_grams} ${this.appStore.i18n.t('user.manage-blend.measurements.grams')}`;
		}else{
			return `${entry.amount_in_ml} ${this.appStore.i18n.t('user.manage-blend.measurements.ml')}`;
		}
	}

	@action goToIngredient(id){
		window.open(`/ingredients/${id}`, '_blank');
	}

	@action goToBlend(id){
		window.open(`/blends/${id}`, '_blank');
	}

	@action onAddIngredientToBlend(id){
		let ingredient = this.ingredients.find((i) => i.id == id);
		if(ingredient != null){
			let hasIngredientInBlend = this.blendData.currentBlendIngredients.map((b) => b.ingredient.id).includes(ingredient.id);
			if(!hasIngredientInBlend){
				this.blendData.editBlendIngredient = ingredient;
				this.blendData.editingBlendIngredient = false;
				if(this.blendData.editBlendMeasurement.value == null){
					this.blendData.editBlendMeasurement.value = this.blendData.selectedBlendMeasurement.value;
				}
			}
		}
	}

	@action onAddBlendToBlend(id){
		this.formulating = true;
		this.blendApi.getBlendById(id)
			.then((response) => {
				let blendIngredients = response.blend.blend_ingredients;

				let currentBlendIngredientsId = this.blendData.currentBlendIngredients.map((b) => b.ingredient.id);

				let addIngredients = [];
				blendIngredients.map((blendIngredient) => {
					let ingredient = blendIngredient.ingredient;
					let newBlendIngredientEntry = observable.object({
						uuid: uuidv4(),
						ingredient: ingredient,
						blend_volume: blendIngredient.volume,
						measurement: blendIngredient.volume_measurement
					})
					addIngredients.push(newBlendIngredientEntry);
				})

				let netIngredientsToAdd = addIngredients.filter((addIngredient) => !currentBlendIngredientsId.includes(addIngredient.ingredient.id));
				if(netIngredientsToAdd.length > 0){
					this.blendData.currentBlendIngredients.push(...netIngredientsToAdd);
					this.getBlendPreview();
				}

			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.formulating = false;
			})
	}

	@action setCurrentUserBlendRegion(){
		if(this.appStore.currentUser== null || this.appStore.currentUser.region == null) return;
		this.blendData.selectedBlendRegion.value = this.appStore.currentUser.region.id;
	}

	getBlendPreview(blendId=null){
		this.getBlendPreviewAsync(blendId);
	}

	getBlendPreviewAsync = async (blendId=null) => {
		let essentialOilBlendPercentage = parseFloat(this.blendData.essentialOilBlendPercentage.value).toFixed(2);
		if(isNaN(essentialOilBlendPercentage)) return;
		this.formulating = true;
		this.fetching = true;
		try{
			let payload = {
				essential_oil_vs_base_percentage: essentialOilBlendPercentage,
				final_blend_volume: parseInt(this.blendData.totalBlendVolume.value, 10),
				final_blend_volume_measurement: this.blendData.selectedBlendMeasurement.value,
				blend_ingredients: this.blendData.currentBlendIngredients.map((blendIngredient) => {
					return {
						ingredient_id: blendIngredient.ingredient.id,
						volume: parseFloat(blendIngredient.blend_volume),
						volume_measurement: blendIngredient.measurement
					}
				}),
				blending_region_id: this.blendData.selectedBlendRegion.value,
				legacy_blend: this.blendData.legacyBlend,
				blend_id: blendId
			}
			let response = await this.blendApi.getBlendPreview(payload);
			let blendResults = response.blend_results;
			blendResults.blend_breakdown = blendResults.blend_breakdown.map((blendBreakdown) => {
				let amount = blendBreakdown.amount.split(' ');
				let volume = amount[0];
				let measurement = amount[1];
				if(measurement === 'ml'){
					blendBreakdown.amount = `${volume} ${this.appStore.i18n.t('user.manage-blend.measurements.ml')}`;
				}else if(measurement === 'grams'){
					blendBreakdown.amount = `${volume} ${this.appStore.i18n.t('user.manage-blend.measurements.grams')}`;
				}else if(measurement === 'drops'){
					blendBreakdown.amount = `${volume} ${this.appStore.i18n.t('user.manage-blend.measurements.drops')}`;
				}else if(measurement === 'ounces'){
					blendBreakdown.amount = `${volume} ${this.appStore.i18n.t('user.manage-blend.measurements.ounces')}`;
				}else if(measurement == '%'){
					blendBreakdown.amount = `${volume} ${this.appStore.i18n.t('user.manage-blend.measurements.percentage')}`;
				}
				return blendBreakdown;
			});
			this.currentBlendResults = blendResults;

		}catch(e){
			console.log(e);
		}finally{
			this.fetching = false;
			this.formulating = false;
		}
	}

	@action resetEditBlendIngredient(){
		this.blendData.editBlendVolume.value = null;
		this.blendData.editBlendIngredient = null;
		this.blendData.editingBlendIngredient = false;

		this.blendData.editBlendMeasurement.value = this.blendData.defaultBlendMeasurement;
	}

	@action toggleDetailedBlendResults(){
		this.showDetailedBlendResults = !this.showDetailedBlendResults;
	}

	@action onUpgradeForCompliance(){
		this.appStore.displayUserProfileModal();
	}

	@computed get chemicalFamilyBreakdownData(){
		if(this.currentBlendResults == null) return [];
		let chemicalFamilyBreakdown = this.currentBlendResults.chemical_family_breakdown;

		return chemicalFamilyBreakdown.sort((cf1, cf2) => {
			return parseFloat(cf1.percentage) > cf2.percentage ? -1 : 1;
		}).map((d) => {
		  	return {
		  		x: d.chemical_family.name, 
		  		y: d.actual_percentage,
		  		yLabel: d.actual_percentage,
		  		family: d.chemical_family,
		  		color: d.chemical_family.hex_color
		  	}
		  })
		return pieData;
	}

	sortByDescendingAmount(cfBreakdown1, cfBreakdown2){
		let totalBreakdown1 = cfBreakdown1[1].map((component) => component.percentage).reduce((sum, x) => sum + x, 0)
		let totalBreakdown2 = cfBreakdown2[1].map((component) => component.percentage).reduce((sum, x) => sum + x, 0)
		return totalBreakdown1 > totalBreakdown2 ? -1 : 1;
	}

	@computed get chemicalComponentBreakdownByFamily(){
		if(this.currentBlendResults == null) return [];
		let chemicalComponentBreakdown = this.currentBlendResults.chemical_component_breakdown;
		let acidComponentBreakdown = this.currentBlendResults.acid_component_breakdown;

		let chemicalBreakdownMap = new Map();
		chemicalComponentBreakdown.map((entry) => {
			let key = entry.chemical_component.chemical_family.name;
			if(chemicalBreakdownMap.has(key)){
				let existingEntries = chemicalBreakdownMap.get(key);
				chemicalBreakdownMap.set(key, [...existingEntries, entry]);
			}else{
				chemicalBreakdownMap.set(key, [entry]);
			}
		});
		let acidBreakdownMap = new Map();
		acidComponentBreakdown.map((entry) => {
			let key = entry.acid_component.acid_family.name;
			if(acidBreakdownMap.has(key)){
				let existingEntries = acidBreakdownMap.get(key);
				acidBreakdownMap.set(key, [...existingEntries, entry]);
			}else{
				acidBreakdownMap.set(key, [entry]);
			}
		});
		let chemicalResults = Array.from(chemicalBreakdownMap);
		let acidResults = Array.from(acidBreakdownMap);
		chemicalResults = chemicalResults.sort(this.sortByDescendingAmount);
		acidResults = acidResults.sort(this.sortByDescendingAmount);
		return [...chemicalResults, ...acidResults];
	}

	@computed get scentNoteBreakdown(){
		if(this.currentBlendResults == null) return [];
		return Array.from(this.currentBlendResults.scent_notes).sort((sn1, sn2) => {
			if(sn1.scent_note.order > sn2.scent_note.order) return 1;
			if(sn2.scent_note.order > sn1.scent_note.order) return -1;
			return 0;
		});
	}

	@computed get physicalEffectsBreakdown(){
		if(this.currentBlendResults == null) return [];
		let results = Array.from(this.currentBlendResults.physical_effects);
		results.sort(this.sortByScore)
		return results;
	}

	sortByScore = (entry1, entry2) => {
		return entry2.score - entry1.score
	}

	@computed get psychologicalEffectsBreakdown(){
		if(this.currentBlendResults == null) return [];
		let results = Array.from(this.currentBlendResults.psychological_effects);
		results.sort(this.sortByScore)
		return results;
	}

	@computed get complianceWarnings(){
		if(this.currentBlendResults == null) return [];
		return Array.from(this.currentBlendResults.compliance_warnings);
	}

	fetchPsychologicalRankings(){
		this.ingredientApi.getAllPsychologicalRankings()
			.then((response) => {
				this.psychologicalRankings = response.psychological_rankings;
			})
			.catch((error) => {
				console.log(error);
			})
	}


	fetchPhysicalRankings(){
		this.ingredientApi.getAllPhysicalRankings()
			.then((response) => {
				this.physicalRankings = response.physical_rankings;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchRegions(){
		this.commonApi.getRegions()
			.then((response) => {
				this.regions = response.regions;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchCurrencies(){
		
		this.currencyApi.getAll()
			.then((response) => {
				this.currencies = response.currencies;
				this.fetchSpotRates();
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchSpotRates(){
		this.currencyApi.getCurrencySpotRates(this.currencies.map((c) => c.name).join(','))
			.then((response) => {response.json()
				.then((json) => {
					if('data' in json){
						this.usdSpotRates = json.data;

					}
				})
				.catch((error) => {
					console.log(error);
				})
			})	
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get canViewCompliance(){
		return this.appStore.isProUser;
	}

	@computed get regionOptions(){
		return this.regions.map((r) => {
			return {
				value: r.id,
				label: r.name
			}
		})
	}

	@computed get currencyOptions(){
		return this.currencies.map((c) => {
			return {
				value: c.id,
				label: c.name
			}
		})
	}

	@computed get allPsychologicalRankingScores(){
		let results = this.psychologicalRankings.map((r) => {
			let psychologicalEffect = this.psychologicalEffectsBreakdown.find((effect) => effect.psychological_ranking.id == r.id)
			return {
				ranking: r, 
				score: psychologicalEffect != null ? psychologicalEffect.score : 0
			}
		})
		results.sort(this.sortByScore)
		return results;
	}
	
	@computed get allPhysicalRankingScores(){
		let results = this.physicalRankings.map((r) => {
			let physicalEffect = this.physicalEffectsBreakdown.find((effect) => effect.physical_ranking.id == r.id)
			return {
				ranking: r, 
				score: physicalEffect != null ? physicalEffect.score : 0
			}
		})
		results.sort(this.sortByScore)
		return results;
	}

	@action onUploadFiles(files){
		this.fetching = true;
		for(let newFile of files){
			this.blendData.uploadedFiles.push({
				id: null,
				name: newFile.name,
				file_url: newFile.uri,
				uuid: uuidv4(),
				file: newFile
			});	
		}
		this.fetching = false;
	}

	@action onEditBlendIngredient(uuid){
		if(this.viewMode) return;
		let blendIngredient = this.blendData.currentBlendIngredients.find((b) => b.uuid == uuid);
		if(blendIngredient != null){
			this.blendData.editBlendIngredient = blendIngredient.ingredient;
			this.blendData.editingBlendIngredient = true;
			this.blendData.editBlendVolume.value = parseFloat(blendIngredient.blend_volume);
			this.blendData.editBlendMeasurement.value = blendIngredient.measurement;
		}
	}

	@action onEditBlendIngredientById(id){
		if(this.viewMode) return;
		let blendIngredient = this.blendData.currentBlendIngredients.find((b) => b.ingredient.id == id);
		if(blendIngredient != null){
			this.blendData.editBlendIngredient = blendIngredient.ingredient;
			this.blendData.editingBlendIngredient = true;
			this.blendData.editBlendVolume.value = parseFloat(blendIngredient.blend_volume);
			this.blendData.editBlendMeasurement.value = blendIngredient.measurement;
		}
	}

	@computed get canAddToBlend(){
		if(this.blendData.editBlendIngredient == null) return false;
		let blendVolume = parseFloat(this.blendData.editBlendVolume.value);
		if(!validator.isPositiveNumber(blendVolume)) return false;

		let blendMeasurement = this.blendData.editBlendMeasurement.value;
		if(validator.isNullOrUndefined(blendMeasurement)) return false;

		return true;
	}

	@action addToBlend(){
		if(!this.canAddToBlend) return;

		let blendVolume = parseFloat(this.blendData.editBlendVolume.value);
		let blendMeasurement = this.blendData.editBlendMeasurement.value;
		if(this.blendData.defaultBlendMeasurement == null){
			this.blendData.defaultBlendMeasurement = blendMeasurement;
		}
		let newBlendEntry = observable.object({
			uuid: uuidv4(),
			ingredient: this.blendData.editBlendIngredient,
			blend_volume: blendVolume.toFixed(2),
			measurement: blendMeasurement
		})
		this.blendData.currentBlendIngredients.push(newBlendEntry);

		this.getBlendPreview();
		this.resetEditBlendIngredient();
	}

	@action updateBlendIngredient(){
		if(!this.canAddToBlend) return;

		let blendVolume = parseFloat(this.blendData.editBlendVolume.value);
		let blendMeasurement = this.blendData.editBlendMeasurement.value;

		// only 1 ingredient for each in blend
		let blendIngredientIdx = this.blendData.currentBlendIngredients.findIndex((x) => x.ingredient.id == this.blendData.editBlendIngredient.id);
		if(blendIngredientIdx != -1){
			this.blendData.currentBlendIngredients[blendIngredientIdx].blend_volume = blendVolume.toFixed(2);
			this.blendData.currentBlendIngredients[blendIngredientIdx].measurement = blendMeasurement;
			this.blendData.currentBlendIngredients[blendIngredientIdx].editMode = false;
			this.getBlendPreview();
			this.resetEditBlendIngredient();
		}
	}

	@action deleteCurrentBlendIngredient(){
		this.blendData.currentBlendIngredients = this.blendData.currentBlendIngredients.filter((x) => x.ingredient.id != this.blendData.editBlendIngredient.id);
		this.getBlendPreview();
		this.resetEditBlendIngredient();
	}

	@action onChangeBlendRegion(val){
		this.blendData.selectedBlendRegion.value = val;
		this.getBlendPreview();
	}

	@computed get showBlendResults(){
		return this.currentBlendResults != null && this.blendData.currentBlendIngredients.length > 0 && parseInt(this.blendData.totalBlendVolume.value, 10) > 0;
	}

	@computed get essentialOilBlends(){
		if(this.currentBlendResults == null) return [];
		return this.currentBlendResults.blend_breakdown.filter((b) => b.is_essential_oil);
	}

	@computed get baseOilBlends(){
		if(this.currentBlendResults == null) return [];
		return this.currentBlendResults.blend_breakdown.filter((b) => !b.is_essential_oil);
	}

	@action addNoteSection(){
		this.blendData.blendNotes.push({
			id: new FieldState(null),
			uuid: uuidv4(),
			note_section: new FieldState(null),
			note: new FieldState(null)
		})
	}

	@action deleteBlendNoteSection(uuid){
		this.fetching = true;
		this.blendData.blendNotes = this.blendData.blendNotes.filter((b) => b.uuid != uuid);
		this.fetching = false;
	}

	@computed get selectedCurrency(){
		if(this.currentCurrency == null) return null;
		let matchingCurrency = this.currencies.find((c) => c.id == this.currentCurrency);
		if(matchingCurrency == null) return null;
		return matchingCurrency;
	}

	@computed get costEstimate(){
		if(this.currentBlendResults == null) return null;
		let costEstimateInDollars = parseFloat(this.currentBlendResults.cost_estimate_per_15ml).toFixed(2);
		let selectedCurrency = this.selectedCurrency;
		let currencySymbol = selectedCurrency == null ? '$': selectedCurrency.symbol;
		let costEstimate = null;
		if(selectedCurrency != null && this.usdSpotRates != null){
			let spotRateItem = this.usdSpotRates[selectedCurrency.name];
			if(spotRateItem != null){
				costEstimate = parseFloat(costEstimateInDollars * spotRateItem.value).toFixed(2);
			}
		}else{
			costEstimate = costEstimateInDollars;
		}
		return `${currencySymbol}${costEstimate}`;
	}

	@action deleteFileByUUID(id){
		let confirmMsg = this.appStore.i18n.t('user.manage-blend.confirm-delete-file-message');
		let confirmed = window.confirm(confirmMsg);

		if(confirmed){
			this.blendApi.deleteBlendFile(id)
				.then((response) => {
					this.blendData.uploadedFiles = this.blendData.uploadedFiles.filter((b) => b.id != id);
				})
				.catch((error) => {
					console.log(error);
				})
		}
	}


	@action onShowChemicalComponentBreakdown(){
		this.showChemicalComponentBreakdown = true;
	}

	@action onCloseChemicalComponentBreakdown(){
		this.showChemicalComponentBreakdown = false;
	}

	@computed get blendJarIngredients(){
		let baseBlendIngredients = this.blendData.currentBlendIngredients;

		let ingredientTypeIds = this.ingredientTypeOptions.filter((it) => it.id > 0);

		return baseBlendIngredients.sort((blendIngredient1, blendIngredient2) => {
			let ingredientType1Idx = ingredientTypeIds.findIndex((it) => it.id == blendIngredient1.ingredient.ingredient_type.id);
			let ingredientType2Idx = ingredientTypeIds.findIndex((it) => it.id == blendIngredient2.ingredient.ingredient_type.id);

			return ingredientType1Idx < ingredientType2Idx? -1 : 1; 
		});
	}

	@action getIngredientHeight(ingredientId){
		let computedHeight = 0;
		
		if(this.formulating && this.currentBlendResults == null){
			return '50px';
		}else{
			if(this.currentBlendResults != null){
				let totalAmountInml = this.currentBlendResults.total_amount_in_ml;
				let foundIngredientBreakdown = this.currentBlendResults.blend_breakdown.find((b) => b.ingredient.id == ingredientId);
				let maxHeight = 340;
				if(foundIngredientBreakdown != null){
					let percentage = Math.floor((foundIngredientBreakdown.amount_in_ml / totalAmountInml) * 100);
					computedHeight = Math.max(Math.ceil(Math.min(maxHeight * (percentage/100))), 10);
				}
			}
		}
		return `${computedHeight}px`;
	}

	@computed get canSaveBlend(){
		return true;
	}

	@action saveBlend = async (draft=false) => {
		if(validator.isNullOrUndefined(this.blendData.name.value)){
			this.appStore.alertError(this.appStore.i18n.t('user.manage-blend.validation.please-name-blend'))
			return;
		};
		if(!validator.isPositiveNumber(this.blendData.totalBlendVolume.value)){
			this.appStore.alertError(this.appStore.i18n.t('user.manage-blend.validation.please-total-blend-volume'))
			return;
		};
		if(!validator.isPositiveNumber(this.blendData.essentialOilBlendPercentage.value)){
			this.appStore.alertError(this.appStore.i18n.t('user.manage-blend.validation.essential-oil-blend-percentage'))
			return;
		};

		if(!draft){
			if(this.blendData.selectedBlendMeasurement.value == MEASUREMENTS.percentage){
				let totalPercentage = this.blendData.currentBlendIngredients.map((x) => x.blend_volume).reduce((sum, x) => sum + x, 0)
				if(totalPercentage < 100){
					this.appStore.alertError(`${this.appStore.i18n.t('user.manage-blend.validation.partial-blend')} ${totalPercentage}%`)
					return;
				}else if (totalPercentage > 100){
					this.appStore.alertError(this.appStore.i18n.t('user.manage-blend.validation.too-much-blend'))
					return;
				}
			}
		}

		try{
			this.fetching = true;
			let newFileMap = this.blendData.uploadedFiles.filter((file) => file.id == null).map((file) => {
				return {
					uuid: file.uuid,
					file: file.file,
				}
			})

			let payload = {
				name: this.blendData.name.value,
				blend_category_id: this.blendData.selectedCategory.value,
				blend_library_id: this.blendData.selectedLibrary.value,
				essential_oil_vs_base_percentage: parseFloat(this.blendData.essentialOilBlendPercentage.value),
				final_blend_volume: parseInt(this.blendData.totalBlendVolume.value, 10),
				final_blend_volume_measurement: this.blendData.selectedBlendMeasurement.value,
				tags: this.blendData.tags,
				blend_ingredients: this.blendData.currentBlendIngredients.map((blendIngredient) => {
					return {
						ingredient_id: blendIngredient.ingredient.id,
						volume: parseFloat(blendIngredient.blend_volume),
						volume_measurement: blendIngredient.measurement
					}
				}),
				blend_notes: this.blendData.blendNotes.map((b) => {
					return {
						note_section: b.note_section.value,
						note: b.note.value
					}
				}),
				blend_files: newFileMap.map((fileEntry) => {
					return {
						uuid: fileEntry.uuid,
						file_type: fileEntry.file.type,
						name: fileEntry.file.name
					}
				}),
				blend_library_category_ids: this.blendData.blendLibraryCategories.map((l) => l.id),
				draft: draft,
				custom_currency_id: this.currentCurrency
			}
			let response = null;
			if(this.id != null){
				response = await this.blendApi.updateBlend(this.id, payload);
			}else{
				response = await this.blendApi.newBlend(payload);
			}
			
			let filesPresignedPost = response.files_presigned_post;
			for(let fileToPost of filesPresignedPost){
				let uuid = fileToPost.uuid;
				let matchingFileEntry = newFileMap.find((f) => f.uuid == uuid);
				if(matchingFileEntry != null){
					try{
						let fileS3Response = await this.s3Api.uploadToS3(matchingFileEntry.file, fileToPost.presigned_post);
					}catch(e){
						let uploadFileErrorMsg = `${this.appStore.i18n.t('error.uploading')} ${matchingFileEntry.file.name}`;
						this.appStore.alertError(uploadFileErrorMsg);
					}
					
				}
			}
			this.appStore.changesSaved();
			this.appStore.goToMyBlendLibrary();
			this.appStore.displaySaveDialog();

		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false
		}
	}

	syncBlend(blend){
		this.id = blend.id;
		this.blendData.name.value = blend.name;
		this.blendData.selectedCategory.value = blend.blend_category?.id;
		this.blendData.essentialOilBlendPercentage.value = blend.essential_oil_vs_base_percentage;
		this.blendData.totalBlendVolume.value = blend.final_blend_volume;
		this.blendData.selectedBlendMeasurement.value = blend.final_blend_volume_measurement;
		this.blendData.currentBlendIngredients = blend.blend_ingredients.map((blendIngredient) => {
			return {
				uuid: uuidv4(),
				ingredient: blendIngredient.ingredient,
				blend_volume: blendIngredient.volume,
				measurement: blendIngredient.volume_measurement
			}
		})
		this.blendData.blendNotes = blend.notes.map((blendNote) => {
			return {
				id: new FieldState(null),
				uuid: uuidv4(),
				note_section: new FieldState(blendNote.note_section),
				note: new FieldState(blendNote.note)
			}
		})
		this.blendData.uploadedFiles = blend.files.map((blendFile) => {
			return {
				id: blendFile.id,
				name: blendFile.name,
				file_url: blendFile.file_url,
				uuid: blendFile.uuid,
				file: null
			}
		});
		this.blendData.tags = blend.tags;
		this.blendData.legacyBlend = blend.legacy_blend;
		this.blendData.blendLibraryCategories = blend.library_categories.map((entry) => {
			return entry.blend_library_category;
		});

		if(blend.custom_currency != null){
			this.changeCurrency(blend.custom_currency.id);
		}
		this.getBlendPreview(blend.id);
	}

	formSync = async (blendId, editMode=false, ingredients=null) => {
		this.fetching = true;
		try{
			let response = await this.blendApi.getBlendById(blendId);
			let blend = response.blend;
			this.currentBlend = blend;
			this.syncBlend(blend);
			if(editMode){
				this.addMode = false;
				this.editMode = true;
				this.viewMode = false;
			}else{
				this.addMode = false;
				this.editMode = false;
				this.viewMode = true;
			}
			

			if(ingredients != null){
				this.syncIngredients(ingredients);
			}

			
		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}


	syncWithIngredients = async (ingredients) => {
		this.fetching = true;
		try{
			this.syncIngredients(ingredients);
		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}

	@action toggleEditMode(){
		this.addMode = false;
		this.viewMode = false;
		this.editMode = true;
	}

	@action toggleViewMode(){
		this.syncBlend(this.currentBlend);
		this.addMode = false;
		this.viewMode = true;
		this.editMode = false;
		this.blendData.editingBlendIngredient = false;
		this.blendData.editBlendIngredient = null;
	}

	 onNewTag(tag){
	 	if(this.blendData.tags.includes(tag)) return;
	 	this.blendData.tags.push(tag);
	 	this.blendData.currentTag = '';
	 }

	 onChangeTags(tags){
	 	this.blendData.tags = tags == null ? [] : tags.map((t) => t.value);
	 }

	 onChangeTag(tag){
	 	this.blendData.currentTag = tag;
	 }

	 @computed get currentTags(){
	 	const toTagOption = (t) => {
	 		return {
	 			value: t,
	 			label: t
	 		}
	 	}
	 	if(this.editMode || this.addMode){
	 		return this.blendData.tags.map(toTagOption);
	 	}else{
	 		return this.currentBlend?.tags.map(toTagOption);
	 	}
	 }

	 @action showLargeFamilyLabel(props){
	 	let familyId = props.datum.family.id;
	 	let chemicalFamily = this.chemicalFamilies.find((f) => f.id == familyId);
	 	if(this.currentLargeHoverChemicalFamily != null && chemicalFamily.id == this.currentLargeHoverChemicalFamily.family.id) return;
	 	
	 	this.currentLargeHoverChemicalFamily = {
	 		family: chemicalFamily,
	 		percentage: parseFloat(props.datum.y).toFixed(2)
	 	}
	 }

	 @action hideLargeFamilyLabel(){
	 	this.currentLargeHoverChemicalFamily = null;
	 }

	 @action showFamilyLabel(props){
	 	let familyId = props.datum.family.id;
	 	let chemicalFamily = this.chemicalFamilies.find((f) => f.id == familyId);
	 	if(this.currentHoverChemicalFamily != null && chemicalFamily.id == this.currentHoverChemicalFamily.family.id) return;
	 	
	 	this.currentHoverChemicalFamily = {
	 		family: chemicalFamily,
	 		percentage: parseFloat(props.datum.y).toFixed(2),
	 		mouseX: this.appStore.mouseCoordinates.x - 30,
	 		mouseY: this.appStore.mouseCoordinates.y - 30
	 	}
	 }

	 @action hideFamilyLabel(){
	 	this.currentHoverChemicalFamily = null;
	 }

	 @action showFamilyDialog(props){
	 	let familyId = props.datum.family.id;
	 	let chemicalFamily = this.chemicalFamilies.find((f) => f.id == familyId);
	 	if(chemicalFamily != null){
	 		this.showChemicalFamilyModal = true;
	 		this.currentChemicalFamily = chemicalFamily;
	 	}
	 }

	 @action hideFamilyModal(){
	 	this.showChemicalFamilyModal = false;
	 	this.currentChemicalFamily = null;
	 }

	 @action duplicateBlend(){
		if(this.currentBlend == null) return;
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-blend-library.duplicate-confirm'));
		if(confirmed){
			this.fetching = true;
			this.blendApi.duplicateBlend(this.currentBlend.id)
				.then((response) => {
					this.appStore.displaySaveDialog();
					this.appStore.goToMyBlendLibrary();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}

	 @computed get currentChemicalFamilyComponents(){
	 	if(this.currentChemicalFamily == null) return [];
	 	if(this.currentBlendResults == null) return [];

	 	let results = this.currentBlendResults.chemical_component_breakdown.filter((c) => c.chemical_component.chemical_family.id == this.currentChemicalFamily.id).map((c) => c.chemical_component);
	 	return results;
	 }

	 @action resetFetchIngredients(){
	 	if(!this.showingBlends){
			this.ingredientsPage = 1;
			this.fetchIngredients(true);
		}else{
			this.blendsPage = 1;
			this.fetchBlends(true);
		}
	}

	 @action loadNextIngredientsPage(){
		this.ingredientsPage += 1;
		this.fetchIngredients();
	}

	@action loadNextBlendsPage(){
		this.blendsPage += 1;
		this.fetchBlends();
	}

	@computed get currentComplianceRegionName(){
		let selectedRegion = this.blendData.selectedBlendRegion.value;
		if(selectedRegion == null) return null;
		let currentRegion = this.regions.find((r) => r.id == selectedRegion);
		if(currentRegion == null) return null;
		return currentRegion.name;
	}

	@computed get blendPDFName(){
		let dateTime = moment().format('DD_MM_YYYY HH:MM');
		if(this.currentBlend == null) return `LabAroma_${dateTime}.pdf`
		return `LabAroma_${this.currentBlend.name.replace(' ', '_')}_${dateTime}.pdf`
	}

	fetchBlendLibraryCategories(){
		this.fetchBlendLibraryCategoriesAsync();
	}

	fetchBlendLibraryCategoriesAsync = async () => {
		let libraryCategories = await this.libraryApi.getLibraryCategories();
		this.blendLibraryCategories = libraryCategories;
	}

	@action onChangeSelectedBlendLibraryCategory(option){
		this.blendData.selectedBlendLibraryCategory = option?.value;
	}

	@action onAddBlendLibraryCategory(){
		let selectedLibraryCategoryId = this.blendData.selectedBlendLibraryCategory 
		if(selectedLibraryCategoryId == null) return;
		let existingLibraryCategory = this.blendData.blendLibraryCategories.find((b) => b.id == selectedLibraryCategoryId);
		if(existingLibraryCategory != null) return;
		let libraryCategory = this.blendLibraryCategories.find((l) => l.id == selectedLibraryCategoryId);
		if(libraryCategory == null) return;
		this.blendData.blendLibraryCategories.push(libraryCategory);
		this.blendData.selectedBlendLibraryCategory = null;
	}

	@computed get blendLibraryCategoryOptions(){
		return this.blendLibraryCategories.map((b) => {
			return {
				value: b.id,
				label: b.name
			}
		})
	}

	@action removeLibraryCategoryById(id){
		this.blendData.blendLibraryCategories = this.blendData.blendLibraryCategories.filter((b) => b.id != id); 
	}

	@computed get canEditCurrentBlend(){
		if(this.currentBlend == null) return false;
		if(this.currentBlend.public){
			if(this.appStore.isSuperAdministrator){
				return true;
			}else{
				return false;
			}
		}else{
			let currentUser = this.appStore.currentUser;
			return this.currentBlend.user_id == currentUser.id;
		}
	}

	@action syncIngredients(ingredients){
		let existingIngredientIds = this.blendData.currentBlendIngredients.map((b) => b.ingredient.id);

		for(let blendIngredientToAdd of ingredients){
			let ingredientToAdd =  blendIngredientToAdd['ingredient'];
			if(existingIngredientIds.includes(ingredientToAdd.id)) continue;
			let newBlendEntry = observable.object({
				uuid: uuidv4(),
				ingredient: ingredientToAdd,
				blend_volume: parseFloat(blendIngredientToAdd['volume']).toFixed(2),
				measurement: blendIngredientToAdd['measurement']
			})
			this.blendData.currentBlendIngredients.push(newBlendEntry);
		}
		this.getBlendPreview();
	}

	@action alphabetOptions(){
		let letterOptions 
		let letters = [
			this.appStore.i18n.t('user.manage-blend.alphabet.a'),
			this.appStore.i18n.t('user.manage-blend.alphabet.b'),
			this.appStore.i18n.t('user.manage-blend.alphabet.c'),
			this.appStore.i18n.t('user.manage-blend.alphabet.d'),
			this.appStore.i18n.t('user.manage-blend.alphabet.e'),
			this.appStore.i18n.t('user.manage-blend.alphabet.f'),
			this.appStore.i18n.t('user.manage-blend.alphabet.g'),
			this.appStore.i18n.t('user.manage-blend.alphabet.h'),
			this.appStore.i18n.t('user.manage-blend.alphabet.i'),
			this.appStore.i18n.t('user.manage-blend.alphabet.j'),
			this.appStore.i18n.t('user.manage-blend.alphabet.k'),
			this.appStore.i18n.t('user.manage-blend.alphabet.l'),
			this.appStore.i18n.t('user.manage-blend.alphabet.m'),
			this.appStore.i18n.t('user.manage-blend.alphabet.n'),
			this.appStore.i18n.t('user.manage-blend.alphabet.o'),
			this.appStore.i18n.t('user.manage-blend.alphabet.p'),
			this.appStore.i18n.t('user.manage-blend.alphabet.q'),
			this.appStore.i18n.t('user.manage-blend.alphabet.r'),
			this.appStore.i18n.t('user.manage-blend.alphabet.s'),
			this.appStore.i18n.t('user.manage-blend.alphabet.t'),
			this.appStore.i18n.t('user.manage-blend.alphabet.u'),
			this.appStore.i18n.t('user.manage-blend.alphabet.v'),
			this.appStore.i18n.t('user.manage-blend.alphabet.w'),
			this.appStore.i18n.t('user.manage-blend.alphabet.x'),
			this.appStore.i18n.t('user.manage-blend.alphabet.y'),
			this.appStore.i18n.t('user.manage-blend.alphabet.z')
		]

		let allLetters = letters.map((letter) => {
			return {
				value: letter,
				label: letter
			}
		})
		allLetters.unshift({
			value: -1,
			label: this.appStore.i18n.t('user.manage-blend.alphabet.all'),
		})
		return allLetters;
	}

	triggerSearch(val){
		if(val == -1){
			this.ingredientSearchText = null;
		}else{
			this.ingredientSearchText = val;
		}
		this.resetFetchIngredients();
	}

	sortByDescendingPercentageEntry(entry1, entry2){
		return entry1.percentage > entry2.percentage ? -1 : 1;
	}

	@computed get flattenChemicalComponentBreakdownByFamily(){		
		let results = [];
		this.chemicalComponentBreakdownByFamily.map((entry) => {
			if(!results.includes(entry[0])){
				results.push({
					type: 'title',
					name: entry[0]
				})
			}
			entry[1].sort(this.sortByDescendingPercentageEntry).map((componentEntry) => {
				results.push({
					type: 'data',
					entry: componentEntry
				})
			})
		});
		return results;
	}

	@computed get isHomeUser(){
		return this.appStore.isHomeUser;
	}

	changeCurrency(val){
		this.currentCurrency = val;
	}

	setDefaultCurrency(){
		let currentUser = this.appStore.currentUser;
		if(currentUser != null){
			if(currentUser.currency != null){
				this.changeCurrency(currentUser.currency.id);
			}
		}
	}

	markChange(){
		this.appStore.markDirty();
	}

	onChangeDownloadOption(id, checked){
		let pdfDownloadIdx = this.pdfDownloadOptions.findIndex((p) => p.id == id);
		if(pdfDownloadIdx != -1){
			let pdfOption = this.pdfDownloadOptions[pdfDownloadIdx];
			if(pdfOption.id == 0){
				this.pdfDownloadOptions = this.pdfDownloadOptions.map((p) => {
					p.checked = checked;
					return p;
				})
			}else{
				this.pdfDownloadOptions[pdfDownloadIdx].checked = checked;
				if(!checked){
					this.pdfDownloadOptions[0].checked = checked;
				}
			}
		}
	}

	@computed get currentPDFDownloadOptions(){
		let currentPDFDownloadOptions = this.pdfDownloadOptions;
		if(!this.canViewCompliance){
			currentPDFDownloadOptions = currentPDFDownloadOptions.filter((x) => x.id != 4);
		}
		if(this.isHomeUser){
			currentPDFDownloadOptions = currentPDFDownloadOptions.filter((x) => ![2, 3].includes(x.id));
		}
		return currentPDFDownloadOptions;
	}


	@computed get showBlendLab(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 1 && x.checked == true);
	}

	@computed get showChemicalFamilyBreakdown(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 2 && x.checked == true);
	}

	@computed get showChemicalBreakdown(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 3 && x.checked == true);
	}

	@computed get showEffects(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 5 && x.checked == true);
	}

	@computed get showNotes(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 6 && x.checked == true);
	}

	@computed get showPricing(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 7 && x.checked == true);
	}

	@computed get showCompliance(){
		return this.currentPDFDownloadOptions.some((x) => x.id == 4 && x.checked == true);
	}

	toggleFavouriteIngredient(id){
		let currentIngredientIdx = this.currentIngredients.findIndex((i) => i.id == id);
		if(currentIngredientIdx == -1) return;
		let currentIngredient = this.currentIngredients[currentIngredientIdx];
 		this.ingredientApi.patchIngredient(currentIngredient.id, {
			favourited: !currentIngredient.favourited
		})
			.then((response) => {
				let favourited = response.ingredient.favourited;
				this.currentIngredients[currentIngredientIdx].favourited = favourited;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	showDownloadSaveDialog(){
		this.appStore.displaySaveDialog(null, this.appStore.i18n.t('common.download-pdf-automatically'), true);
	}
}

export default ManageBlendStore;