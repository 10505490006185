import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';

import {inject, observer} from 'mobx-react';
import { SmallBody, Body, SecondaryAppLargeTitle, SecondaryAppMediumTitle, SecondaryAppSmallTitle, TertiaryAppSmallTitle,TertiaryAppExtraSmallTitle, ExtraSmallBody} from '../common/text';
import { ColorBookIcon, ArrowRightIcon, SearchIcon, FilterDialIcon, ListIcon, ArrowDownIcon, TileIcon } from '../common/icon'
import {TextInput} from '../common/input';
import {Button} from '../common/button';
import {ApplyText} from '../common/text';
import Main from '../elements/main';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormSelect,
	FormPasswordInput
} from '../elements/form';

import Loader from '../common/loader';
import {SortDialog, SortingField} from '../elements/list';
import {TagInput, Tag} from '../common/tag';

const colors = require('../../color.js');

@observer
class SearchResultsOverview extends Component{
	render(){
		let translate = this.props.t;
		let noOfResults = this.props.noOfResults;
		let query = this.props.query;
		let resultsText = noOfResults > 1 ? translate('user.advanced-search.search-results.results') : translate('user.advanced-search.search-results.result');
		return (
			<div className="flex flex-row w-full mt-8">
				<Body className="text-lg">{translate('user.advanced-search.search-results.part1')} '{query}' {translate('user.advanced-search.search-results.part2')} <span className="bold-font">{noOfResults}</span> {resultsText}</Body>
			</div>
		)
	}
}

@observer
class NoSearchResults extends Component{
	render(){
		let translate = this.props.t;
		return (
			<div className="flex flex-1 flex-col bg-white rounded-lg items-center text-center justify-between mt-8 py-32">
				<div className="flex"><Body className="text-xl text-nav-inactive-color">{translate('user.advanced-search.no-results.title')}</Body></div>
				<div className="flex mt-16"><SecondaryAppSmallTitle className="text-nav-active-color">{translate('user.advanced-search.no-results.encouragement')}</SecondaryAppSmallTitle></div>
				<div className="flex mt-16">
					<Button
						width="w-auto px-8"
						height="h-12"
						className="px-4 rounded-lg"
						bgColor="nav-active-color"
						onClick={() => this.props.onNewSearch()}
					>
						<div className="flex flex-row items-center justify-center">
							<div className="flex"><TertiaryAppSmallTitle>{this.props.text} &nbsp;</TertiaryAppSmallTitle></div>
						</div>
					</Button>
				</div>
			</div>
		)
	}
}

export {
	SearchResultsOverview,
	NoSearchResults
}