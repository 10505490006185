import { observable, action, computed } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isEmailValidator, isRequiredValidator, isPasswordValidator } from '../../../services/validation';

import UserApi from '../../../services/user';
import CommonApi from '../../../services/common';

import { CHEMISTRY, COSMETIC_REGULATIONS, LANGUAGES, USERS } from '../../../services/util';

class InviteUser{
	appStore;
	userApi;
	commonApi;

	@observable fetching;

	@observable userData;
	@observable permissions;

	constructor(appStore){
		this.appStore = appStore;
		this.userApi = new UserApi(appStore);
		this.commonApi = new CommonApi(appStore);
		this.initStore();
	}

	initStore(){
		this.fetching = false;
		this.permissions = [];
		this.userData = {
			name: new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.full-name'))),
			email: new FieldState('').validators((val) => isEmailValidator(val, this.appStore.i18n)),
			message: new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, this.appStore.i18n.t('validation.message'))),
			selectedLanguage: new FieldState(null),
			discountCode: new FieldState(''),
			selectedPermissions: new FieldState([])
		}
	}

	fetchPermissions(){
		if(!this.appStore.isSuperAdministrator) return;
		this.commonApi.getPermissions()
			.then((response) => {
				this.permissions = response.permissions;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	@computed get validForm(){
		let requiredFields = ['name', 'email', 'message'];
		let isValidForm = true;
		for(let field of requiredFields){
			if(this.userData[field].value == null || this.userData[field].value.length == 0){
				isValidForm = false;
			}else{
				let fieldError = this.userData[field].error;
				isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
			}
		}
		return isValidForm;
	}

	getPermissionIds(){
		let selectedPerms = this.userData.selectedPermissions.value;
		let permissionIds = [];
		for(let perm of selectedPerms){
			let matchingPermission = this.permissions.find((x) => x.name == perm);
			if(matchingPermission != null){
				permissionIds.push(matchingPermission.id)
			}
		}
		return permissionIds;
	}

	invite(){
		let userLanguage = this.userData.selectedLanguage.value;
		if(userLanguage == null){
			if(this.appStore.isSuperAdministrator){
				this.appStore.alertError(this.appStore.i18n.t('validation.select-language-message'));
				return false;
			}else{
				userLanguage = this.appStore.currentUser.language; 
			}
		}

		this.fetching = true;
		this.userApi.inviteUser({
			name: this.userData.name.value,
			email: this.userData.email.value,
			message: this.userData.message.value,
			language: userLanguage,
			discount_code: this.userData.discountCode.value,
			permission_ids: this.getPermissionIds()
		})
		.then((response) => {
			this.appStore.goToManageUsers();
			this.appStore.displaySaveDialog('invite', this.appStore.i18n.t('alert.invited-new-user'));
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			this.fetching = false;
		})
	}

	handlePermissionChange(checked, permission){
		let currentPermissions = this.userData.selectedPermissions.value;
		if(checked && !currentPermissions.includes(permission)){
			this.userData.selectedPermissions.value.push(permission);
		}else{
			this.userData.selectedPermissions.value = this.userData.selectedPermissions.value.filter((p) => p != permission);
		}
	}

	@action setCosmeticRegulationPermission(checked){
		this.handlePermissionChange(checked, COSMETIC_REGULATIONS);
	}

	@action setChemistryPermission(checked){
		this.handlePermissionChange(checked, CHEMISTRY);
	}

	@action setLanguagesPermission(checked){
		this.handlePermissionChange(checked, LANGUAGES);
	}

	@action setUsersPermission(checked){
		this.handlePermissionChange(checked, USERS);
	}

}

export default InviteUser;