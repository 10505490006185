import { observable, action, computed, runInAction, when } from 'mobx';

import {computeUserLanguage} from '../services/util';

import UserApi from '../services/user';
import LibraryApi from '../services/library';
import LanguageApi from '../services/language';
import NotificationApi from '../services/notification';
import CommonApi from '../services/common';

import uuidv4 from 'uuid/v4';
import moment from 'moment';
import 'moment-timezone';

const DEFAULT_LANGUAGE = computeUserLanguage();

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, USER, CHEMISTRY, COSMETIC_REGULATIONS, LANGUAGES, USERS } from '../services/util';

import ReactGA from 'react-ga';

class AppStore {
    ACCESS_TOKEN_KEY = '@user:labaroma_access_token';
    REFRESH_TOKEN_KEY = '@user:labaroma_refresh_token';
    HIDE_NEWSLETTER_KEY = '@user:labaroma_hide_newsletter';
    LANGUAGE_KEY = '@user:labaroma_language';
    ABANDONED_CART_KEY = '@user:labaroma_cart'

	@observable currentView;
    @observable pendingNetworkRequests;
    @observable alerts;

    @observable currentToken;
    @observable currentRefreshToken;
    @observable currentUser;

    @observable showUserProfileModal;

    @observable loggingIn;
    @observable appHasLoaded;

    @observable currentLanguage;

    @observable showNewsletterModal;

    @observable i18n;
    @observable currentMenuOptions;

    @observable previousView;

    @observable showAbandonedCartModal;

    @observable showSaveDialog;

    @observable ownedLibraryCategories;

    @observable languages;
    @observable notifications;
    @observable showNotificationDropdown;
    @observable totalNotifications;

    @observable searchQuery;
    @observable hasUnsavedChanges;

    @observable tooltipMap;

    userApi;
    libraryApi;
    languageApi;
    notificationApi;
    commonApi;

    @observable mouseCoordinates =  {
        x: null,
        y: null
    };

    constructor(i18n){
        this.i18n = i18n;
    	this.initStore();
    }

    initStore(){
        when(
            () => !this.isAuthenticated,
            () => this.goToLogin()
        );
    	this.currentView = {
        	name: '/'
    	}
        this.userApi = new UserApi(this);
        this.libraryApi = new LibraryApi(this);
        this.languageApi = new LanguageApi(this);
        this.notificationApi = new NotificationApi(this);
        this.commonApi = new CommonApi(this);
        this.pendingNetworkRequests = 0;
        this.alerts = [];
        this.currentToken = null;
        this.currentRefreshToken = null;
        this.currentUser = null;
        this.loggingIn = false;
        this.appHasLoaded = false;
        this.currentLanguage = DEFAULT_LANGUAGE;
        this.showNewsletterModal = false;
        this.currentMenuOptions = [];
        this.previousView = null;
        this.showAbandonedCartModal = false;
        this.showUserProfileModal = false;
        this.ownedLibraryCategories = [];
        this.languages = [];
        this.showSaveDialog = {
            display: false,
            icon: null,
            message:null,
            hideButton: false
        }
        this.notifications = [];
        this.showNotificationDropdown = false;
        this.totalNotifications = null;
        this.notificationIntervalTimer = null;
        this.searchQuery = null;
        this.hasUnsavedChanges = false;
        this.tooltipMap = new Map();
    }

    @action onMouseMove(e) {
        const YOFFSET = 50;
        const XOFFSET = 10;
        let currentY = e.pageY;
        let currentX = e.pageX;
        this.mouseCoordinates = {
          x: currentX, 
          y: currentY
        }
        
        let headingForExit = this.mouseCoordinates.y < YOFFSET && this.mouseCoordinates.x < XOFFSET;
        if(headingForExit){
            // this.checkForNewsletterPopup();
        }
      }

    @action seti18n(i18n){
        this.i18n = i18n;
    }

    @action goToSuperAdministratorHome(){
        this.goToView('super-administrator-home');
    }

    @action goToManageUsers(){
        this.goToView('administrator-users');
    }

    @action goToInviteNewUser(){
        this.goToView('invite-user');
    }

    @action goToEditUser(id){
        this.goToView('edit-user', {
            userId: id
        });
    }

    @action goToSuperAdministratorManageOrganisations(){
        this.goToView('super-administrator-organisations');
    }

    @action goToInviteNewOrganisation(){
        this.goToView('invite-organisation');
    }

    @action goToEditOrganisation(id){
        this.goToView('edit-organisation', {
            organisationId: id
        });
    }

    @action goToIndex(){
        this.goToView('index');
    }

    @action goToUserHome(){
        this.goToView('home');
    }

    @action goToPickAPlanWithLanguage(languageCode){
        this.changeLanguage(languageCode);
        this.goToView('pick-plan');
    }

    @action goToPickAPlan(){
        this.goToView('pick-plan');
    }

    @action goToHome(){
        if(this.isSuperAdministrator){
            this.goToSuperAdministratorHome();
        }else if(this.isOrganisationAdministrator){
            this.goToOrganisationAdministratorHome();
        }else{
            this.goToUserHome();
        }
    }

    @action goToLoginWithLanguage(languageCode){
        this.changeLanguage(languageCode);
        this.goToView('login');
    }

    @action goToLogin(){
    	this.goToView('login');
    }

    @action goToAbout(){
        window.location.href = 'https://www.labaroma.com/our-story'
    }

    @action goToPricing(){
    	this.goToView('pricing');
    }

    @action goToTraining(){
    	this.goToView('training');
    }

    @action goToContact(){
        this.goToView('contact');
    }

    @action goToPaymentConfirmation(color){
        this.goToView('payment-confirmation', {
            color: color
        });
    }

    @action goToOrganisationAdministratorHome(){
        this.goToView('organisation-administrator-home');
    }

    @action backToPreviousView(){
        if(this.previousView != null){
            this.goToView(this.previousView)
        }else{
            this.goToIndex();
        }
    }

    @action goToStyleGuide(){
        this.goToView('style-guide');
    }

    @action goToNewsletter(){
        this.goToView('newsletter');
    }

    @action goToForgotPassword(){
        this.goToView('forgot-password');
    }

    @action goToResetPassword(token){
        this.goToView('reset-password', {
            token: token
        })
    }

    @action goToAcceptInvite(token){
        this.goToView('accept-invite', {
            token: token
        })
    }

    @action goToTerms(){
        window.open('https://www.labaroma.com/terms-conditions', '_blank');
    }

    @action goToChemistrySettings(){
        this.goToView('chemistry-settings')
    }

    @action goToNewChemistryFamily(){
        this.goToView('new-chemistry-family');
    }

    goToEditChemistryFamily(chemicalFamilyId){
         this.goToView('edit-chemistry-family', {
            chemicalFamilyId: chemicalFamilyId
         });
    }

    @action goToNewChemicalComponent(){
        this.goToView('new-chemical-component');
    }

    @action goToEditChemicalComponent(chemicalComponentId){
         this.goToView('edit-chemical-component', {
            chemicalComponentId: chemicalComponentId
         });
    }

    @action goToNewAcidFamily(){
        this.goToView('new-acid-family');
    }

    goToEditAcidFamily(acidFamilyId){
         this.goToView('edit-acid-family', {
            acidFamilyId: acidFamilyId
         });
    }

    @action goToNewAcidComponent(){
        this.goToView('new-acid-component');
    }

    @action goToEditAcidComponent(acidComponentId){
         this.goToView('edit-acid-component', {
            acidComponentId: acidComponentId
         });
    }

    @action goToRegulations(){
        this.goToView('cosmetic-regulations');
    }

    @action goToLanguages(){
        this.goToView('languages');
    }

    @action goToEditLanguage(lang){
        this.goToView('edit-language', {
            language: lang
        })
    }

    @action goToEntityLanguageTranslation(lang, id, entityKey){
         this.goToView('edit-entity-language-translation-request', {
            language: lang,
            entityId: id,
            entityKey: entityKey
        })
    }

    @action goToWebLanguageTranslation(lang, translateKey){
        this.goToView('edit-web-language-translation-request', {
            language: lang,
            translateKey: translateKey
        })
    }

    @action goToEditRegulations(regionId){
         this.goToView('edit-cosmetic-regulations', {
            regionId: regionId
         });
    }

    @action goToLabAromaIngredientLibrary(ingredientTypeId=null){
        this.goToView('labaroma-ingredient-library', {
            ingredientTypeId: ingredientTypeId
        });
    }

    @action goToMyIngredientLibrary(ingredientTypeId=null){
        this.goToView('my-ingredient-library', {
            ingredientTypeId: ingredientTypeId
        });
    }

    @action goToNewIngredient(){
        this.goToView('new-ingredient');
    }

    @action goToMyBlendLibrary(){
        this.goToView('my-blend-library');
    }

    @action goToLabAromaBlendLibrary(){
        this.goToView('labaroma-blend-library');   
    }

    @action goToBlendLibraryWithCategory(libraryCategoryId){
        this.goToView('blend-library-for-category', {
            libraryCategoryId: libraryCategoryId
        })
    }

    @action isCurrentLibraryCategory(libraryCategoryId){
        return (this.currentView.name == 'blend-library-for-category') && (this.currentView.libraryCategoryId == libraryCategoryId);
    }

    @action goToNewBlend(){
        this.goToView('new-blend');
    }

     @action goToNewBlendWithIngredients(ingredients){
        this.goToView('new-blend', {
            ingredients: ingredients
        })
    }

    @action goToBlendCategories(){
        this.goToView('blend-categories');
    }

    @action goToNewBlendCategory(){
        this.goToView('new-blend-category');
    }

    @action goToEditBlendCategory(id){
        this.goToView('edit-blend-category', {
            blendCategoryId: id
        });   
    }

    @action goToLibraryCategories(){
        this.goToView('library-categories');
    }

    @action goToNewLibraryCategory(){
        this.goToView('new-library-category');
    }

     @action goToEditLibraryCategory(id){
        this.goToView('edit-library-category', {
            libraryCategoryId: id
        });   
    }

    @action goToEditIngredient(ingredientId, editMode=false){
        this.goToView('edit-ingredient', {
            ingredientId: ingredientId,
            editMode: editMode
        })
    }


    @action goToEditBlend(blendId, editMode=false){
        this.goToView('edit-blend', {
            blendId: blendId,
            editMode: editMode
        })
    }

    @action goToEditBlendWithIngredients(blendId, ingredients){
         this.goToView('edit-blend-with-ingredients', {
            blendId: blendId,
            editMode: true,
            ingredients: ingredients
        })
    }

    @action goToCompareIngredients(){
        this.goToView('compare-ingredients');
    }


    @action goToComparisonTool(ingredientIds=null){
        this.goToView('comparison-tool', {
            ingredientIds: ingredientIds
        });
    }

    @action goToEditComparisonTool(comparisonReportId, editMode=false){
        this.goToView('edit-comparison-tool', {
            comparisonReportId: comparisonReportId,
            editMode: editMode
        });   
    }

    @action goToAdvancedSearch(query=null){
        this.goToView('advanced-search', {
            query: query
        });
    }

    @action goToAdvancedSearchWithQuery(){
        this.goToView('advanced-search', {
            query: this.searchQuery
        });
        this.searchQuery = null;
    }

    @computed get isOnAdvancedSearch(){
        return this.currentView.name == 'advanced-search';
    }

    @action goToComponentSearch(){
        this.goToView('component-search');
    }

    @action goToEditComponentSearch(componentSearchId){
        this.goToView('edit-component-search', {
            componentSearchId: componentSearchId
        });   
    }

    @action goToComponentSearchList(){
        this.goToView('component-search-list');   
    }

    @action goToEvidenceSearch(){
        this.goToView('evidence-search');
    }

    @action goToNewEvidence(){
        this.goToView('new-evidence')
    }

    @action goToViewEvidence(evidenceId){
        this.goToView('evidence-search', {
            evidenceId: evidenceId
        });
    }

    @action goToEditEvidence(evidenceId, editMode=false){
        this.goToView('manage-evidence', {
            evidenceId: evidenceId,
            editMode: editMode
        })
    }

    @action goToMarketingAssetSearch(){
        this.goToView('marketing-asset-search');
    }

    @action goToNewMarketingAsset(){
        this.goToView('new-marketing-asset')
    }

    @action goToViewMarketingAsset(marketingAssetId){
        this.goToView('marketing-asset-search', {
            marketingAssetId: marketingAssetId
        });
    }

    @action goToEditMarketingAsset(marketingAssetId, editMode=false){
        this.goToView('manage-marketing-asset', {
            marketingAssetId: marketingAssetId,
            editMode: editMode
        })
    }

    @action goToHelpCenter(){
        let helpCenterUrl = 'http://help.labaroma.com';
        window.open(helpCenterUrl, "_blank");
    }

    @action goToView(name, data=null){
        if(this.hasUnsavedChanges){
            let confirmed = window.confirm(this.i18n.t('common.discard-changes'));
            if(confirmed){
                this.cancelUnsavedChanges();
            }else{
                return;
            }
        }

        this.previousView = this.currentView.name;
        let viewObject = {
            name: name
        }
        if(data != null){
            viewObject = Object.assign({}, viewObject, data);
        }
        this.currentView = viewObject;
    }

    @action startFetch(){
        this.pendingNetworkRequests = this.pendingNetworkRequests + 1;
    }
    
    @action completeFetch(){
        this.pendingNetworkRequests = this.pendingNetworkRequests - 1;
        if(this.pendingNetworkRequests < 0){
            this.pendingNetworkRequests = 0;
        }
    }

    @action logout(){
        this.currentUser = null;
        this.currentToken = null;
        this.currentRefreshToken = null;
        this.searchQuery = null;
        this.clearExistingIntervals();
        this.notifications = [];
        
        this.ownedLibraryCategories = [];
        localStorage.removeItem(this.ACCESS_TOKEN_KEY);
        localStorage.removeItem(this.REFRESH_TOKEN_KEY);

        if('Intercom' in window){
            window.Intercom('shutdown');
        }

        this.goToLogin();
    }

    @action addAlert(message, alertType){
        let alertId = uuidv4();
        let alert = {
            id: alertId,
            message: message,
            alertType: alertType
        };
     
        this.alerts.push(alert);
        setTimeout(() => {
            this.removeAlert(alertId);
        }, 7500);
    }

    @action alertError(message){
        this.addAlert(message, 'error');
    }

    @action alertUnauthorised(){
        this.alertError(this.i18n.t('common.unauthorised'));
    }

    @action alertWarning(message){
        this.addAlert(message, 'warning');
    }

    @action alertSuccess(message){
        this.addAlert(message, 'success');
    }

    @action alertInfo(message){
        this.addAlert(message, 'info');
    }

    @action goToRegistrationWith(currencyId, period, planId, username=null, step=null, country=null, profession=null){
        this.goToView('registration', {
            currencyId: currencyId,
            period: period,
            planId: planId,
            username: username,
            step: step,
            country: country,
            profession: profession
        });
    }

    resetErrors(){
        this.alerts = this.alerts.filter((x) => x.alertType !== 'error');
    }

    @action removeAlert(id){
        this.alerts = this.alerts.filter(alert => alert.id != id);
    }

    @computed get hasAlerts(){
        return this.alerts.length > 0;
    }

     @computed get currentPath() {
        switch(this.currentView.name) {
        	case "home": return "/home"
        	case "login": return "/login"
            case "registration": 
                if('currencyId' in this.currentView && 'planId' in this.currentView && 'period' in this.currentView){
                    return `/register?currencyId=${this.currentView.currencyId}&planId=${this.currentView.planId}&period=${this.currentView.period}`
                }else{
                    return "/register"
                }
        	case "pricing": return "/pricing"
            case "pick-plan": return "/plans"
            case "terms": return "/terms"
        	case "contact": return "/contact"
            case "style-guide": return "/style-guide"
            case "newsletter": return "/newsletter"
            case "forgot-password": return "/forgot-password"
            case "reset-password": 
                return `/reset-password/${this.currentView.token}`
            case "accept-invite":
                 return `/accept-invite/${this.currentView.token}`
            case "user-profile":
                return `/user/${this.currentView.userId}/profile`
            case "super-administrator-home":
                return "/super/admin/home"
            case "organisation-administrator-home":
                return "/admin/home"
            case "administrator-users":
                return "/users"
            case "edit-user":
                return `/users/${this.currentView.userId}`
             case "edit-organisation":
                return `/organisations/${this.currentView.organisationId}`
            case "invite-user":
                return "/users/invite"
            case "super-administrator-organisations":
                return "/super/admin/organisations"
            case "invite-organisation":
                return "/organisations/invite"
            case "chemistry-settings":
                return "/settings/chemistry"
            case "new-chemistry-family":
                return "/settings/chemistry/families/new"
            case "edit-chemistry-family":
                return `/settings/chemistry/families/${this.currentView.chemicalFamilyId}`
            case "new-chemical-component":
                return "/settings/chemistry/components/new"
            case "edit-chemical-component":
                return `/settings/chemistry/components/${this.currentView.chemicalComponentId}`
             case "new-acid-family":
                return "/settings/acids/families/new"
            case "edit-acid-family":
                return `/settings/acids/families/${this.currentView.acidFamilyId}`
            case "new-acid-component":
                return "/settings/acids/components/new"
            case "edit-acid-component":
                return `/settings/acids/components/${this.currentView.acidComponentId}`
            case "cosmetic-regulations": 
                return `/settings/regulations`
            case "languages": 
                return `/settings/languages`
            case "edit-language":
                return `/settings/languages/${this.currentView.language}`
            case "edit-entity-language-translation-request":
                return `/settings/languages/${this.currentView.language}/entity/${this.currentView.entityKey}/${this.currentView.entityId}`
            case "edit-web-language-translation-request":
                return `/settings/languages/${this.currentView.language}/web/${this.currentView.translateKey}`
            case "edit-cosmetic-regulations": 
                return `/settings/regulations/${this.currentView.regionId}`
            case "labaroma-ingredient-library":
                let labaromaIngredientLibraryBaseUrl = "/ingredients/labaroma-library";
                if(this.currentView.ingredientTypeId != null){
                    return `${labaromaIngredientLibraryBaseUrl}/${this.currentView.ingredientTypeId}`;
                }else{
                    return labaromaIngredientLibraryBaseUrl;
                }
            case "my-ingredient-library":
                let myIngredientLibraryBaseUrl = "/ingredients/my-library";
                if(this.currentView.ingredientTypeId != null){
                    return `${myIngredientLibraryBaseUrl}/${this.currentView.ingredientTypeId}`;
                }else{
                    return myIngredientLibraryBaseUrl;
                }
            case "compare-ingredients":
                return "/ingredients/comparisons"
            case "comparison-tool":
                return "/ingredients/comparisons/new"
            case "edit-comparison-tool":
                return `/ingredients/comparisons/${this.currentView.comparisonReportId}`
            case "my-blend-library":
                return "/blends/my-library"
            case "labaroma-blend-library":
                return "/blends/labaroma-library"
            case "blend-library-for-category":
                return `/blends/library-category/${this.currentView.libraryCategoryId}`
            case "new-blend":
                return "/blends/new"
            case "edit-blend":
            case "edit-blend-with-ingredients":
                return `/blends/${this.currentView.blendId}`
            case "new-ingredient":
                return "/ingredients/new"
            case "edit-ingredient":
                return `/ingredients/${this.currentView.ingredientId}`
            case "blend-categories":
                return "/blends/categories"
            case "new-blend-category":
                return "/blends/categories/new"
            case "edit-blend-category":
                return `/blends/categories/${this.currentView.blendCategoryId}`
            case "library-categories":
                return "/libraries/categories"
            case "new-library-category":
                return "/libraries/categories/new"
            case "edit-library-category":
                return `/libraries/categories/${this.currentView.libraryCategoryId}`
            case "component-search-list":
                return "/components"
            case "component-search":
                return "/components/search"
            case "edit-component-search":
                return `/components/search/${this.currentView.componentSearchId}`
            case "advanced-search":
                return "/advanced-search"
            case "evidence-search":
                return "/evidence"
            case "new-evidence":
                return "/evidence/new"
            case "manage-evidence":
                return `/evidence/${this.currentView.evidenceId}`
             case "marketing-asset-search":
                return "/marketing-assets"
            case "new-marketing-asset":
                return "/marketing-assets/new"
            case "manage-marketing-asset":
                return `/marketing-assets/${this.currentView.marketingAssetId}`
        	case "index":
            default:
        		return "/"
        }
    }

    @action setCurrentToken(token){
        this.currentToken = token;
    }

    @action setCurrentRefreshToken(token){
        this.currentRefreshToken = token;
    }

    @computed get isAuthenticated(){
        return this.currentUser !== null || this.currentToken !== null;
    }

    @action checkForAbandonedCartPopup(){
        let cartDetails = this.getCurrentCart();
        if(cartDetails != null){
            this.showAbandonedCartModal = true;
        }else{
            this.showAbandonedCartModal = false;
        }
    }

    @action getCurrentCart(){
        let abandonedCart = localStorage.getItem(this.ABANDONED_CART_KEY);
        if(abandonedCart != null){
            return JSON.parse(abandonedCart);
        }else{
            return null;
        }
    }

    getOwnedBlendLibraryCategories = async () => {
        let libraryCategories = await this.libraryApi.getLibraryCategories(null, null, true, false);
        this.ownedLibraryCategories = libraryCategories;
    }

    @action getAllToolTips(){
        this.commonApi.getTooltips()
            .then((response) => {
                let data = response;
                data.forEach((element) => {
                    this.tooltipMap.set(element.snippet_name, element.html);
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    @action getTooltip(key){
        return this.tooltipMap.get(key);
    }

    @action startUp(i18n){
        this.seti18n(i18n);

        this.currentMenuOptions = [
            {
                value: 'home',
                label: this.i18n.t('menu.home')
            },
            {
                value: 'tutorials',
                label: this.i18n.t('menu.tutorials')
            },
            {
                value: 'recipe-library',
                label: this.i18n.t('menu.recipe-library')
            },
            {
                value: 'search-component',
                label: this.i18n.t('menu.search-by-component')
            },
            {
                value: 'suppliers',
                label: this.i18n.t('menu.suppliers')
            },
            {
                value: 'faq',
                label: this.i18n.t('menu.faq')
            },
            {
                value: 'logout',
                label: this.i18n.t('menu.logout')
            }
        ];

        let language = localStorage.getItem(this.LANGUAGE_KEY);
        if(language != null){
            this.changeLanguage(language);
        }else{
            this.changeLanguage(i18n.language);
        }

        this.checkForAbandonedCartPopup();
        this.checkForNewsletterPopup();
        this.getAllToolTips();

        this.languageApi.getLanguages()
            .then((response) => {
                this.languages = response.languages;
            })
            .catch((error) => {
                console.log(error);
            })

        let token = localStorage.getItem(this.ACCESS_TOKEN_KEY);
        if(token != null){
            this.setCurrentToken(token);
            this.getCurrentUser().then(() => {
                if(this.currentUser == null){
                    this.appHasLoaded = true;
                    this.logout();
                }else{
                    if(this.currentUser.language != this.currentLanguage){
                        this.changeLanguage(this.currentUser.language);
                    }
                    this.showAbandonedCartModal = false;
                    this.showNewsletterModal = false;
                    this.appHasLoaded = true;
                    this.getOwnedBlendLibraryCategories();
                    this.pollForNotifications();
                    
                }
                this.bootIntercom();
            });
        }else{
            this.appHasLoaded = true;
            this.bootIntercom();
        }

        // boot google
        if(process.env.NODE_ENV === 'production'){
            ReactGA.initialize('UA-45214624-1');
        }
    }  

    @action clearExistingIntervals(){
        clearInterval(this.notificationIntervalTimer);
    }

    @action pollForNotifications(){
        this.clearExistingIntervals()
        this.notificationIntervalTimer = setInterval(() => {
            this.fetchLatestNotifications();    
        }, 60 * 1000);
        this.fetchLatestNotifications();
    }

    @action toggleNotificationDropdown(){
        this.showNotificationDropdown = !this.showNotificationDropdown;
    }

    @action fetchLatestNotifications(){
        this.notificationApi.getPagedNotifications(1, true)
            .then((response) => {
                this.notifications = response.notifications;
                this.totalNotifications = response.total_new;
            })
            .catch((error) => {
                console.log(error);
            })
    }

    @action goToNotification(notification){
        let metadata = notification.notification_metadata; 
        switch(notification.notification_metadata.type){
            case "pending_entity_language_translation_request":
                this.goToEntityLanguageTranslation(metadata.language, metadata.entity_id, metadata.entity_key);
                break;
            case "declined_entity_language_translation_request":
                this.goToEntityLanguageTranslation(metadata.language, metadata.entity_id, metadata.entity_key);
                break;
            case "pending_web_language_translation_request":
                this.goToWebLanguageTranslation(metadata.language, metadata.translate_key); 
                break;
            case "decline_web_language_translation_request":
                this.goToWebLanguageTranslation(metadata.language, metadata.translate_key);
                break;
        }

        this.showNotificationDropdown = false;
        this.markNotificationAsRead(notification.id);
    }

    @action markNotificationAsRead(id){
        this.notificationApi.readNotificiation(id)
            .then((response) => {
                this.notifications = this.notifications.filter((n) => n.id != id);
                this.totalNotifications = Math.max(this.totalNotifications - 1, 0);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    @computed get hasNotifications(){
        return this.notifications.length > 0;
    }

    @action checkForNewsletterPopup(){
        // Don't show newsletter if abandoned cart is showing
        if(this.showAbandonedCartModal) return;
        let hideNewsletter = localStorage.getItem(this.HIDE_NEWSLETTER_KEY);
        // if(hideNewsletter != null){
        //     this.showNewsletterModal = !JSON.parse(hideNewsletter);
        // }else{
        //     this.showNewsletterModal = true;
        // }
        this.showNewsletterModal = false;

    }

    @action setCurrentUser(user){
        this.currentUser = user;
    }

    @action getCurrentUser(){
        return this.userApi.getUser()
            .then((response) => {
                let user = response;
                this.setCurrentUser(user);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    bootIntercom(){
        if(process.env.NODE_ENV !== 'production') return;
        if('Intercom' in window){
            let currentUser = this.currentUser;
            let intercomPayload = {
              app_id: "xr45c30b",
              name: currentUser?.name,
              email: currentUser?.email,
              created_at: currentUser?.joined_on,
              user_id: currentUser?.id,
              user_hash: currentUser?.hmac_user_id
            }
            window.Intercom("boot", intercomPayload);
        }
    }

    openIntercom(){
        if('Intercom' in window){
            window.Intercom('showNewMessage');
        }
    }

    @action login(email, password, skipNavigation=false){
        this.loggingIn = true;
        return this.userApi.login(email, password)
            .then((response) => {
                runInAction(() => {
                    localStorage.setItem(this.ACCESS_TOKEN_KEY, response.access_token);
                    localStorage.setItem(this.REFRESH_TOKEN_KEY, response.refresh_token);
                    this.setCurrentToken(response.access_token);
                    this.setCurrentRefreshToken(response.refresh_token);

                    runInAction(() => {
                        return this.getCurrentUser()
                            .then(() => {
                                this.changeLanguage(this.currentUser.language);
                                if(!skipNavigation){
                                    this.goToHome();
                                }
                                this.pollForNotifications();
                                this.getOwnedBlendLibraryCategories();
                                this.bootIntercom();
                            })
                            .catch((error) => {
                                this.alertError(this.i18n.t('error.retrieve-user-account-message'));
                            })
                    })
                })

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.loggingIn = false;
            })
    }

    @action changeLanguage(lang){
        this.i18n.changeLanguage(lang);
        localStorage.setItem(this.LANGUAGE_KEY, lang);
        this.currentLanguage = lang;
    }

    @computed get isJapaneseUser(){
        return this.currentLanguage == 'ja' || this.currentLanguage == 'jp';
    }

    closeNewsletterModal(){
        this.showNewsletterModal = false;
    }

    openNewsletterModal(){
        this.showNewsletterModal = true;
    }

    @computed get acceptLanguageHeader(){
        let acceptLanguageHeader =  this.currentLanguage == null ? DEFAULT_LANGUAGE : this.currentLanguage;
        if(acceptLanguageHeader === 'jp'){
            acceptLanguageHeader = 'ja';
        }
        return acceptLanguageHeader;
    }

    @computed get languageOptions(){
        return this.languages.map((language) => {
            return {
                label: language.name,
                value: language.id
            }
        })
    }

    @computed get showLanguageSelector(){
        if(this.currentView == null) return false;
        if(this.currentView.name == 'pricing'){
            return true;
        }

        if(this.isAuthenticated){
            return true;
        }else {
            return false;
        }
    }

    @action userHasRoleName(roleName){
        if(this.currentUser == null) return false;
        let roles = this.currentUser.roles;
        let roleNames = roles.map((r) => r.role_name);
        return roleNames.includes(roleName);
    }

    @computed get userRoles(){
        return this.currentUser != null ? this.currentUser.roles.map((r) => r.role_name) : [];
    }

    @computed get isSuperAdministrator(){
        return this.userHasRoleName(SUPER_ADMINISTRATOR);
    }

    @computed get isOrganisationAdministrator(){
        return this.userHasRoleName(ORGANISATION_ADMINISTRATOR);
    }
    
    @computed get isUser(){
        return this.userHasRoleName(USER);
    }

    @computed get currentUserPermissions(){
        if(this.currentUser == null) return [];
        return this.currentUser.permissions.map((p) => p.name);
    }

    @computed get hasCosmeticRegulationPermission(){
        return this.currentUserPermissions.includes(COSMETIC_REGULATIONS);
    }

    @computed get hasChemistryPermission(){
        return this.currentUserPermissions.includes(CHEMISTRY);
    }

    @computed get hasLanguagesPermission(){
        return this.currentUserPermissions.includes(LANGUAGES);
    }

    @computed get hasUsersPermission(){
        return this.currentUserPermissions.includes(USERS);
    }

    @computed get menuOptions(){
        return this.currentMenuOptions;
    }

    @computed get currentMenuOption(){
        if(this.menuOptions.length == 0) return defaultMenuOption;
        let defaultMenuOption = this.menuOptions[0].value;
        if(this.currentView == null) return defaultMenuOption;
        let matchingMenuOption = this.menuOptions.find((x) => x.value == this.currentView.name);
        if(matchingMenuOption != null){
            return matchingMenuOption.value;
        }else{
            return defaultMenuOption;
        }
    }

    @action onChangeMenuOption(route){
        if(route == 'logout'){
            this.logout();
        }else{
            this.goToView(route);
        }
    }

    @action goToCurrentUserProfile(){
        this.goToView('user-profile', {
            userId: this.currentUser.id
        })
    }

    @computed get validUserSubscription(){
        if(this.currentUser == null) return true;
        if(this.isSuperAdministrator) return true;
        if(this.currentUser.subscription == null) return false;
        let currentPeriodEnd = moment.utc(this.currentUser.subscription.current_period_end);
        if(currentPeriodEnd.isBefore(moment.utc())) return false;
        return true;
    }

    @action closeCartModal(){
        this.showAbandonedCartModal = false;
    }

    @action storeCartDetails(data){
        localStorage.setItem(this.ABANDONED_CART_KEY, JSON.stringify(data));
    }

    @action clearCart(){
        this.showAbandonedCartModal = false;
        localStorage.removeItem(this.ABANDONED_CART_KEY);
    }

    @computed get showUpgradeSubscription(){
        if(this.currentView.name != 'registration' && this.currentView.name != 'terms' && !this.validUserSubscription){
            return true;
        }else{
            return false;
        }
    }

    resetSaveDialog(){
        this.showSaveDialog = {
            display: false,
            icon: null,
            message: null,
            hideButton: false
        }
    }

    @action onReturnSaveDialog(){
        this.resetSaveDialog();
    }

    @action displaySaveDialog(icon=null, message=null, hideButton=false){
        this.showSaveDialog.display = true;
        this.showSaveDialog.message = message;
        this.showSaveDialog.icon = icon;
        this.showSaveDialog.hideButton = hideButton;
    }

    @action hideSaveDialog(){
        this.resetSaveDialog();
    }

    @action displayUserProfileModal(){
        this.showUserProfileModal = true;
    }

    @computed get isProUser(){
        let currentUser = this.currentUser;
        if(currentUser == null) return false;
        return currentUser.is_pro_user;
    }

    @computed get isGrowUser(){
        let currentUser = this.currentUser;
        if(currentUser == null) return false;
        return currentUser.is_grow_user;
    }

    @computed get isHomeUser(){
        let currentUser = this.currentUser;
        if(currentUser == null) return false;
        return currentUser.is_home_user;
    }

    @computed get timezone(){
        return moment.tz.guess();
    }

    changeSearchQuery(val){
        this.searchQuery = val;
    }

    markDirty(){
        this.hasUnsavedChanges = true;
    }

    cancelUnsavedChanges(){
        this.hasUnsavedChanges = false;
    }

    changesSaved(){
        this.hasUnsavedChanges = false;
    }

    logConversionEvent(value=1){
        if(process.env.NODE_ENV === 'production'){
            ReactGA.event({
              category: 'LabAromaSubscription',
              action: 'LabAromaSubscription',
              label: 'LabAromaSubscription',
              value: value
            });

            if('fbq' in window){
                window.fbq('trackCustom', 'Subscriptions', {
                    value: value
                });
            }
        }
    }
}

export default AppStore;