import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {inject, observer} from 'mobx-react';

import Logo from '../common/logo';
import Footer from '../nav/footer';

import {Body, SmallBody, SecondaryWebLargerTitle, SecondaryWebExtraLargeTitle, SecondaryWebSmallTitle} from '../common/text';
import { Select } from '../common/input';
import {TickIcon, UnderScoreIcon} from '../common/icon';
import { Button } from '../common/button';

import PricingPlanDetail from './plan-detail';
import BaseModal from '../common/modal';

@observer
class Plan extends Component{
	constructor(props){
		super(props);
		this.discoverMore = this.discoverMore.bind(this);
	}

	discoverMore(){
		this.props.onDiscoverMore(this.props.planId);
	}
	render(){
		let planName = this.props.name.split(' ');
		let lastWord = planName.pop();
		let translate = this.props.t;
		return (
			<div className={`flex flex-col bg-white justify-between py-16 px-8 border border-black`}>
				<div className="flex flex-1 flex-col w-full lg:w-full overflow-x-scroll">
					<SecondaryWebLargerTitle className="text-black text-center lg:text-left">{planName} <br /> <span className={`text-${this.props.color}`}>{lastWord}</span></SecondaryWebLargerTitle>
					<div className="flex flex-1 w-full mt-4">
						<Body className="text-black text-center lg:text-left">{this.props.description}</Body>
					</div>
					<div className="flex text-center justify-center w-full mt-4">
						<SmallBody className="italic">{translate('pricing.money-back-guarantee')}</SmallBody>
					</div>
					{this.props.availablePlans.sort((p1, p2) => {
							return p2.period_value > p1.period_value ? -1 : 1;
						}).map((availablePlan) => 
						<div key={availablePlan.id} className="flex flex-col mt-6 text-center lg:text-left">
							<Body className="text-black"><span className="capitalize">{availablePlan.displayPeriod}</span> {translate('pricing.detail.name')}</Body>
							<SecondaryWebLargerTitle className="text-black">{availablePlan.periodValue}<SecondaryWebSmallTitle> /{availablePlan.displayPeriod}</SecondaryWebSmallTitle></SecondaryWebLargerTitle>
						</div>
					)}
				</div>
				<div className="flex flex-col mt-8">
					<div className="flex flex-1 flex-col items-center w-full">
						{this.props.button}
					</div>
					<div className="flex flex-1 flex-row justify-center mt-4" onClick={this.discoverMore}>
						<SecondaryWebSmallTitle className={`text-black cursor-pointer`}>{translate("pricing.plan.discover")}</SecondaryWebSmallTitle>
					</div>
				</div>
				<div className="flex flex-col flex-1 mt-8">
					{this.props.largestFeatureSet.map((feature) => {
							let renderIcon = this.props.features != null && this.props.features.includes(feature) ? <TickIcon /> : <UnderScoreIcon />;
							return (
								<div className="flex flex-1 flex-row justify-between items-center mt-4">
									<Body>{feature}</Body>
									<div>{renderIcon}</div>
								</div>
							)
						})}
				</div>
			</div>
		)
	}
}

@inject('pricingStore', 'appStore')
@observer
class PickPlan extends Component{
	constructor(props){
		super(props);
		this.renderPlanButton = this.renderPlanButton.bind(this);
		this.goToSignUpWithPlan = this.goToSignUpWithPlan.bind(this);
		this.onDiscoverMore = this.onDiscoverMore.bind(this);
		this.onChangeCurrency = this.onChangeCurrency.bind(this);
		this.closeDiscoverMoreModal = this.closeDiscoverMoreModal.bind(this);
	}

	componentDidMount(){
		let pricingStore = this.props.pricingStore;
		pricingStore.initStore();

		pricingStore.fetchCurrencies();
		pricingStore.fetchPlans();
	}

	goToSignUpWithPlan(planId){
		let pricingStore = this.props.pricingStore;
		pricingStore.goToSignUpWithPlan(planId);
	}

	renderPlanButton(plan){
		let translate = this.props.t;
		let planCTA = `${translate("pricing.plan.button.signup")}`;
		
		return (<Button 
			width="w-full"
			fgColor="white" 
			bgColor={plan.color}
			borderColor="white"
			onClick={this.goToSignUpWithPlan.bind(this, plan.id)}>{planCTA}</Button>)
		
	}

	onDiscoverMore(planId){
		let pricingStore = this.props.pricingStore;
		pricingStore.discoverMore(planId);
	}

	onChangeCurrency(option){
		let pricingStore = this.props.pricingStore;
		pricingStore.onChangeCurrency(option.value);
	}

	closeDiscoverMoreModal(){
		let pricingStore = this.props.pricingStore;
		pricingStore.closeDiscoverMoreModal();
	}

	render(){
		let translate = this.props.t;
		let pricingStore = this.props.pricingStore;
		return (
			<div className="relative flex flex-1 flex-col">
				<BaseModal
		            isOpen={pricingStore.showDiscoverMoreModal}
		            onClose={this.closeDiscoverMoreModal}
		            label={translate("pricing.plan.discover")}>
						{pricingStore.currentPlan != null && 
							<PricingPlanDetail
								t={translate}
								color={pricingStore.currentPlan.color}
								planId={pricingStore.currentPlan.id}
								name={pricingStore.currentPlan.name}
								largestFeatureSet={pricingStore.largestFeatureSet}
								features={pricingStore.currentPlan.features}
								detailed_features={pricingStore.currentPlan.detailed_features}
								detail_description={pricingStore.currentPlan.detail_description}
								button={this.renderPlanButton(pricingStore.currentPlan)}
								availablePlans={pricingStore.getPlansForAvailablePeriods}
								signUpWithPlan={this.goToSignUpWithPlan}
							/>
						}
		        </BaseModal>
		        
				<div className="flex flex-1 grid grid-cols-1 lg:grid-cols-4 gap-8 px-16 py-16">
					<div className="flex flex-col">
						<div className="flex justify-start lg:w-auto">
							<Logo small />
						</div>
						<div className="flex flex-1 flex-col justify-start py-20 bg-white mt-8 lg:mt-0">
							<SecondaryWebExtraLargeTitle className="text-center lg:text-left">{translate('plans.title')}</SecondaryWebExtraLargeTitle>
							<Body className="text-center lg:text-left mt-8">{translate('plans.description')}</Body>
							<div className="flex flex-row items-center justify-center lg:justify-start mt-6 lg:mt-24">
								<Body className="inline-flex uppercase tracking-wider">{translate('pricing.currency')}:</Body>
								<Select 
									uppercase
									value={pricingStore.currentCurrency}
									options={pricingStore.currencyOptions}
									onChange={this.onChangeCurrency} 
								/>
							</div>
						</div>
					</div>
					{pricingStore.currentPlans.map((plan, idx) => 
						<Plan
						 	key={plan.id}
							t={translate}
							planId={plan.id}
							color={plan.color}
							name={plan.name}
							description={plan.long_description}
							displayPeriod={plan.displayPeriod}
							periodValue={`${plan.currency.symbol}${plan.period_value}`}
							periodDescription={plan.short_description}
							onDiscoverMore={this.onDiscoverMore}
							button={this.renderPlanButton(plan)}
							largestFeatureSet={pricingStore.largestFeatureSet}
							features={plan.features}
							color={plan.color}
							availablePlans={pricingStore.getAllPlansForName(plan.name)}
						/>
					)}
				</div>
				<Footer />
			</div>
		)
	}
}


export default withTranslation()(PickPlan);