import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import { withTranslation } from 'react-i18next';
import LeftBlockRightContent from '../common/left-block-right-content';
import { Body, SecondaryWebLargerTitle} from '../common/text';

import parse from 'html-react-parser';

@inject('termsStore')
@observer
class Terms extends Component{
	componentDidMount(){
		let termsStore = this.props.termsStore;
		termsStore.fetchTerms();
	}

	componentWillUnmount(){
		let termsStore = this.props.termsStore;
		termsStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let termsStore = this.props.termsStore;
		let termsAndConditionsHTML = termsStore.termsAndConditionsHTML != null ? parse(termsStore.termsAndConditionsHTML) : null;
		if(termsAndConditionsHTML == null || termsStore.lastUpdated == null) return null;
		return (
			<LeftBlockRightContent
				leftBgColor='flat-blue-25'
				left={
					<>
						<Body className="text-center text-black lg:text-left mt-8">{translate('terms.updated')} {termsStore.lastUpdated.format('LL')}</Body>
						<SecondaryWebLargerTitle className="text-center text-black lg:text-left">{translate('terms.title')}</SecondaryWebLargerTitle>
					</>
				}
				right={
					<div className="flex flex-1 items-center">
						<Body>
							{termsAndConditionsHTML}
						</Body>
					</div>
				}
			/>
		)
	}
}

export default withTranslation()(Terms);