import BaseApi from '../services/base';

class AcidApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAllAcidFamilies(page, searchText=null){
		let queryUrl = `v1/acids/families?page=${page}`
		if(searchText != null){
			queryUrl = `${queryUrl}&search_text=${searchText}`
		}
		return this.fetch(queryUrl, {
			method: 'GET',
			auth: true
		})
	}	

	getAcidFamilies = async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAllAcidFamilies(page);
			let data = response.acid_families;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}

	newAcidFamily(payload){
		return this.fetch('v1/acids/families', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getAcidFamilyById(id){
		return this.fetch(`v1/acids/families/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	updateAcidFamily(id, payload){
		return this.fetch(`v1/acids/families/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getAllAcidComponents(page, searchText=null){
		let queryUrl = `v1/acids/components?page=${page}`
		if(searchText != null){
			queryUrl = `${queryUrl}&search_text=${searchText}`
		}
		return this.fetch(queryUrl, {
			method: 'GET',
			auth: true
		})
	}	

	getAcidComponents = async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAllAcidComponents(page);
			let data = response.acid_components;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}

	newAcidComponent(payload){
		return this.fetch('v1/acids/components', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getAcidComponentById(id){
		return this.fetch(`v1/acids/components/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	updateAcidComponent(id, payload){
		return this.fetch(`v1/acids/components/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}
}

export default AcidApi;