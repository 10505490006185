import { observable, action, computed, extendObservable } from 'mobx';
import { FieldState, FormState } from 'formstate';
import { isRequiredValidator } from '../../../services/validation';
import LanguageApi from '../../../services/language';

class ManageLanguage{

	@observable fetching;
	@observable translateToLanguage;
	@observable webTranslations;
	@observable languageOption;

	@observable currentPrimarySection;
	@observable currentTranslatingEntityKey;

	@observable currentLanguageEntitiesToTranslate;
	@observable currentTranslatingEntity;

	@observable savingEntityTranslation;
	@observable translatableEntities;

	@observable goToEntityId;

	constructor(appStore){
		this.appStore = appStore;
		this.languageApi = new LanguageApi(appStore)
		this.initStore();
	}

	initStore(){
		this.translateToLanguage = null;
		this.fetching = false;
		this.languageOption = 0;
		this.currentPrimarySection = 0;
		this.webTranslations = [];
		this.translatableEntities = [];
		this.currentTranslatingEntityKey = null;
		this.currentLanguageEntitiesToTranslate = [];
		this.currentTranslatingEntity = null;
		this.savingEntityTranslation = false;
		this.goToEntityId = null;
	}

	fetchLanguageEntities(){
		return this.languageApi.getLanguageEntities()
			.then((response) => {
				this.translatableEntities = response;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	setCurrentPrimarySection(section){
		this.currentPrimarySection = section;
	}

	formSync(language){
		this.fetching = true;
		this.languageApi.getLanguageById(language)
			.then((response) => {
				this.translateToLanguage = response;
				this.getWebTranslations();
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	individualEntityFormSync(language, languageOption, entityKey, entityId){
		this.languageApi.getLanguageById(language)
			.then((response) => {
				this.translateToLanguage = response;
				this.fetchLanguageEntities()
					.then(() => {
						this.languageOption = languageOption;
						this.setTranslatableEntitySection(entityKey)
							.then(() => {
								this.goToEntityId = entityId;
								this.onEditEntity(entityId);									
							})
					});
			})
			.catch((error) => {
				console.log(error)
			})

	}

	individualWebFormSync(language, languageOption, translateKey){
		this.languageApi.getLanguageById(language)
			.then((response) => {
				this.languageOption = languageOption;
				this.translateToLanguage = response;
				this.getWebTranslations()
					.then(() => {
						this.webTranslations = this.webTranslations.sort((t1, t2) => {
							return t1.translate_key == translateKey ? -1 : 1;
						})
					})
				this.fetchLanguageEntities();
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	toWebTranslations(response){
		return response.data.map((x) => {
			x.dirty = false;
			return x;
		});
	}

	getWebTranslations(){
		if(this.translateToLanguage == null) return;
		return this.languageApi.getWebTranslations(this.translateToLanguage.id)
			.then((response) => {
				this.webTranslations = this.toWebTranslations(response);
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get isSubsequentCopy(){
		return this.languageOption === 1;
	}

	@computed get isPrimaryCopy(){
		return this.languageOption === 0;
	}

	@action setLanguageOption(id){
		this.languageOption = id;
		if(this.isSubsequentCopy && this.translatableEntities.length > 0){
			this.setTranslatableEntitySection(this.translatableEntities[0].entity_key);
		}
		if(this.isPrimaryCopy){
			this.getWebTranslations();
		}
	}

	@action setTranslatableEntitySection(entityKey){
		if(this.translateToLanguage == null) return;
		this.currentLanguageEntitiesToTranslate = [];
		this.currentTranslatingEntityKey = entityKey;
		this.fetching = true;
		this.currentTranslatingEntity = null;
		return this.languageApi.getLanguageEntityTranslations(entityKey, this.translateToLanguage.id)
			.then((response) => {
				this.currentLanguageEntitiesToTranslate = response;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action onChangeWebTranslation(uuid, newTranslation){
		let webTranslationIdx = this.webTranslations.findIndex((w) => w.uuid == uuid);
		if(webTranslationIdx != -1){
			this.webTranslations[webTranslationIdx].translation = newTranslation;
			this.webTranslations[webTranslationIdx].dirty = true;
		}
	}

	@action saveWebTranslations(){
		if(this.translateToLanguage == null) return;
		let changedTranslations = this.webTranslations.filter((t) => t.dirty === true && t.translation != null && t.translation.length > 0);
		if(changedTranslations.length > 0){
			let payload = {
				data: changedTranslations.map((translation) => {
					return {
						translate_key: translation.translate_key,
						translation: translation.translation
					}
				})
			}
			this.fetching = true;
			this.languageApi.updateWebTranslations(this.translateToLanguage.id, payload)
				.then((response) => {
					this.webTranslations = this.toWebTranslations(response);
					this.appStore.displaySaveDialog();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}

	@action onApproveWebTranslation(uuid){
		let webTranslationIdx = this.webTranslations.findIndex((w) => w.uuid == uuid);
		if(webTranslationIdx != -1){
			let webTranslation = this.webTranslations[webTranslationIdx];
			if(webTranslation.translation_request == null) return;
			this.fetching = true;
			this.languageApi.approveWebTranslationRequest(webTranslation.translation_request.id)
				.then((response) => {
					this.webTranslations[webTranslationIdx].translation_request.status = 'approved';		
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}

	@action onDeclineWebTranslation(uuid){
		let webTranslationIdx = this.webTranslations.findIndex((w) => w.uuid == uuid);
		if(webTranslationIdx != -1){
			let webTranslation = this.webTranslations[webTranslationIdx];
			if(webTranslation.translation_request == null) return;
			this.fetching = true;
			this.languageApi.declineWebTranslationRequest(webTranslation.translation_request.id)
				.then((response) => {
					this.webTranslations[webTranslationIdx].translation_request.status = 'denied';	
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}

	@action onEditEntity(entityId){
		if(this.translateToLanguage == null) return;
		this.fetching = true;
		this.languageApi.getIndividualLanguageEntityTranslation(this.currentTranslatingEntityKey, this.translateToLanguage.id, entityId)
			.then((response) => {
				this.currentTranslatingEntity = response;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action onCancelEditEntity(){
		this.currentTranslatingEntity = null;
	}

	@computed get currentEntityName(){
		if(this.currentTranslatingEntityKey == null) return null;
 		let matchedTranslatableEntity = this.translatableEntities.find((t) => t.entity_key == this.currentTranslatingEntityKey);
		return matchedTranslatableEntity?.name;
	}

	@action onApproveEntityTranslation(id){
		if(this.currentTranslatingEntity == null) return;
		if(this.translateToLanguage == null) return;
		let entity = this.currentTranslatingEntity.entity;
		let entityId = this.currentTranslatingEntity.id;
		let toLanguage = this.translateToLanguage.id;
		this.savingEntityTranslation = true;
		this.languageApi.approveIndividualLanguageEntityTranslationRequest(id)
			.then((response) => {
				let translatableEntityIdx = this.currentLanguageEntitiesToTranslate.findIndex((t) => t.id == this.currentTranslatingEntity.id);
				if(translatableEntityIdx != -1){
					this.currentLanguageEntitiesToTranslate[translatableEntityIdx].status = 'approved';
					this.currentTranslatingEntity = null;
				}

				this.appStore.displaySaveDialog();
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.savingEntityTranslation = false;
			})
	}

	@action onDeclineEntityTranslation(id){
		if(this.currentTranslatingEntity == null) return;
		if(this.translateToLanguage == null) return;
		let entity = this.currentTranslatingEntity.entity;
		let entityId = this.currentTranslatingEntity.id;
		let toLanguage = this.translateToLanguage.id;
		this.savingEntityTranslation = true;
		this.languageApi.declineIndividualLanguageEntityTranslationRequest(id)
			.then((response) => {
				this.currentTranslatingEntity = response;
				let translatableEntityIdx = this.currentLanguageEntitiesToTranslate.findIndex((t) => t.id == this.currentTranslatingEntity.id);
				if(translatableEntityIdx != -1){
					this.currentLanguageEntitiesToTranslate[translatableEntityIdx].status = 'denied';
					this.currentTranslatingEntity = null;
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.savingEntityTranslation = false;
			})

	}

	@action onSaveEntityTranslation(id){
		if(this.currentTranslatingEntity == null) return;
		if(this.translateToLanguage == null) return;
		let entity = this.currentTranslatingEntity.entity;
		let entityId = this.currentTranslatingEntity.id;
		let toLanguage = this.translateToLanguage.id;
		let payload = {
			'translations': this.currentTranslatingEntity.entity_translations.map((x) => {
				return {
					key: x.key,
					translation: x.translation
				}
			})
		}
		this.savingEntityTranslation = true;
		this.languageApi.updateIndividualLanguageEntityTranslation(entity, toLanguage, entityId, payload)
			.then((response) => {
				this.currentTranslatingEntity = response;
				let translatableEntityIdx = this.currentLanguageEntitiesToTranslate.findIndex((t) => t.id == this.currentTranslatingEntity.id);
				if(translatableEntityIdx != -1){
					this.currentLanguageEntitiesToTranslate[translatableEntityIdx].status = 'pending';
					this.currentTranslatingEntity = null;
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.savingEntityTranslation = false;
			})
	}

	@computed get sortedCurrentLanguageEntitiesToTranslate(){
		return this.currentLanguageEntitiesToTranslate.sort((l1, l2) => {
			
			if(this.goToEntityId != null){
				if(l1.id === this.goToEntityId){
					return -1;
				}
				if(l2.id === this.goToEntityId){
					return 1;
				}
			}

			if(l1.status == 'approved'){
				return -1;
			}
			if(l2.status == 'approved'){
				return -1;
			}
			if(l1.status === 'pending'){
				return -1;
			}
			if(l2.status === 'pending'){
				return -1;
			}

			return 1;
		})
	}
}

export default ManageLanguage;