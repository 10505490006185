import React, {Component} from 'react';

import {Body} from '../common/text';
import {CheckBox} from '../common/input';

import {observer} from 'mobx-react';

import {LANGUAGES, CHEMISTRY, COSMETIC_REGULATION, USERS} from '../../services/util';

const LANGUAGE_COLOR = 'nav-active-color';
const CHEMISTRY_COLOR = 'petroleum-blue';
const COSMETIC_REGULATION_COLOR = 'cosmetic-pink';
const USERS_COLOR = 'ochre-yellow'

@observer
class PermissionCircle extends Component{
	render(){
		let color = null;
		let permission = this.props.permission;
		switch(permission.name){
			case LANGUAGES:
				color = LANGUAGE_COLOR;
				break;
			case CHEMISTRY:
				color = CHEMISTRY_COLOR;
				break;
			case USERS:
				color = USERS_COLOR;
				break;
			case COSMETIC_REGULATION:
			default:
				color = COSMETIC_REGULATION_COLOR
		}
		return (<div className={`bg-${color} rounded-full w-4 h-4 ml-1`}></div>)
	}
}

@observer
class PermissionCheckbox extends Component{
	render(){
		return (
			<div className="flex flex-1 flex-row items-center">
				<div className="flex"><Body className="text-black">{this.props.title}</Body></div>
				<div className="flex ml-4">
					<CheckBox extraInputClassNames={this.props.className} onChange={this.props.onChange} checked={this.props.checked} />
				</div>
			</div>
		)
	}
}

@observer
class CosmeticRegulationPermission extends Component{
	render(){
		return (
			<PermissionCheckbox 
				onChange={this.props.onChange}
				checked={this.props.checked} 
				title="Cosmetic Regulations" 
				className={`w-4 h-4 border-list-text text-${COSMETIC_REGULATION_COLOR} cursor-pointer  rounded-full`} />
		)
	}
}

@observer
class ChemistryPermission extends Component{
	render(){
		return (
			<PermissionCheckbox 
				checked={this.props.checked}
				onChange={this.props.onChange}
				title="Chemistry" className={`w-4 h-4 border-list-text cursor-pointer text-${CHEMISTRY_COLOR} rounded-full`} />
		)
	}
}

@observer
class LanguagesPermission extends Component{
	render(){
		return (
			<PermissionCheckbox 
				checked={this.props.checked} 
				onChange={this.props.onChange}
				title="Languages" 
				className={`w-4 h-4 border-list-text text-${LANGUAGE_COLOR} cursor-pointer rounded-full`} />
		)
	}
}

@observer
class UsersPermission extends Component{
	render(){
		return (
			<PermissionCheckbox 
				onChange={this.props.onChange}
				checked={this.props.checked} 
				title="Users" 
				className={`w-4 h-4 border-list-text text-${USERS_COLOR} cursor-pointer rounded-full`} />
		)
	}
}

export {
	PermissionCircle,
	CosmeticRegulationPermission,
	ChemistryPermission,
	LanguagesPermission,
	UsersPermission
}