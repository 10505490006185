import { observable, action, computed } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isRequiredValidator, isNotNullValidator, isPositiveNumberValidator } from '../../../services/validation';

import CommonApi from '../../../services/common';
import MarketingAssetApi from '../../../services/marketing-asset';
import IngredientApi from '../../../services/ingredient';
import TagApi from '../../../services/tag';
import S3Api from '../../../services/s3';

import uuidv4 from 'uuid/v4';

class ManageMarketingAsset{
	appStore;

	id;

	@observable editMode;
	@observable marketingAssetData;

	@observable fetching;
	@observable currentFileUpload;
	@observable currentFileUploadUrl;

	@observable newFileToAttach;

	@observable languages;

	commonApi;
	marketingAssetApi;
	s3Api;


	constructor(appStore){
		this.appStore = appStore
		this.commonApi = new CommonApi(this.appStore);
		this.marketingAssetApi = new MarketingAssetApi(this.appStore);
		this.s3Api = new S3Api(this.appStore);
		this.initStore();
	}

	initStore(){
		this.marketingAssetData = {
			title: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			subtitle: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			selectedLanguage: new FieldState(null).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			description: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
		}
		this.currentFileUpload = null;
		this.currentFileUploadUrl = null;
		this.marketingAssetDataForm = new FormState({
			title: this.marketingAssetData.title,
			subtitle: this.marketingAssetData.subtitle,
			selectedLanguage: this.marketingAssetData.selectedLanguage,
			description: this.marketingAssetData.description
		})
		this.editMode = false;
		this.fetching = false;
		this.id = null;
		this.languages = [];
		this.newFileToAttach = false;
	}

	formSync(marketingAssetId){
		this.fetching = true;
		this.marketingAssetApi.getMarketingAssetById(marketingAssetId)
			.then((response) => {
				this.marketingAssetData.title.value = response.title;
				this.marketingAssetData.subtitle.value = response.subtitle;
				this.marketingAssetData.selectedLanguage.value = response.language_code;
				this.marketingAssetData.description.value = response.description;
				
				if(response.marketing_asset_file != null){
					this.currentFileUploadUrl = response.marketing_asset_file.file_url;

					this.currentFileUpload = {
						id: response.marketing_asset_file.id,
						name: response.marketing_asset_file.name,
						file_url: response.marketing_asset_file.file_url,
						uuid: response.marketing_asset_file.uuid,
						file: null
					}
				}
				this.id = response.id;
				this.editMode = true;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})

	}


	@computed get validForm(){
		let requiredFields = ['title', 'subtitle', 'selectedLanguage', 'description'];
		let isValidForm = true;
		for(let field of requiredFields){
			if(this.marketingAssetData[field].value == null || this.marketingAssetData[field].value.length == 0){
				isValidForm = false;
			}else{
				let fieldError = this.marketingAssetData[field].error;
				isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
			}
		}
		return isValidForm;
	}

	fetchLanguages(){
		this.commonApi.getLanguages()
			.then((response) => {
				this.languages = response.languages;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get languageOptions(){
		return this.languages.map((l) => {
			return {
				value: l.id,
				label: l.name
			}
		})
	}


	saveMarketingAsset = async () => {
		let res = await this.marketingAssetDataForm.validate()
		if(res.hasError) return;

		let marketingAssetFile = null;
		if(this.currentFileUpload != null && this.newFileToAttach){
			marketingAssetFile = {
				uuid: this.currentFileUpload.uuid,
				file_type: this.currentFileUpload.file.type,
				name: this.currentFileUpload.file.name
			}
		}
		let payload = {
			title: this.marketingAssetData.title.value,
			subtitle: this.marketingAssetData.subtitle.value,
			language_code: this.marketingAssetData.selectedLanguage.value,
			description: this.marketingAssetData.description.value,
			marketing_asset_file: marketingAssetFile
		}
		this.fetching = true;
		try{
			let response = null;
			if(this.editMode && this.id != null){
				response = await this.marketingAssetApi.updateMarketingAsset(this.id, payload);
			}else{
				response = await this.marketingAssetApi.newMarketingAsset(payload);
			}
				
			let presignedPostData = response.presigned_post;
			if(this.currentFileUpload != null && presignedPostData != null){
				try{
					let fileS3Response = await this.s3Api.uploadToS3(this.currentFileUpload.file, presignedPostData.presigned_post);
				}catch(e){
					let uploadFileErrorMsg = `${this.appStore.i18n.t('error.uploading')} ${this.currentFileUpload.file.name}`;
					this.appStore.alertError(uploadFileErrorMsg);
				}
			}
			this.appStore.displaySaveDialog();
			this.appStore.goToMarketingAssetSearch();
		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}

	changeCurrentUploadFile(files){
		if(files.length == 1){
			let newFile = files[0];
			this.uploading = true;
			this.currentFileUpload = {
				id: null,
				name: newFile.name,
				file_url: newFile.uri,
				uuid: uuidv4(),
				file: newFile
			}
			this.currentFileUploadUrl = URL.createObjectURL(newFile);
			this.uploading = false;
			this.newFileToAttach = true;
		}
		
	}
	

	@action deleteMarketingAssetFile(id){
		let confirmMsg = this.appStore.i18n.t('superadmin.manage-marketingAsset.confirm-delete-file-message');
		let confirmed = window.confirm(confirmMsg);
		if(confirmed){
			this.marketingAssetApi.deleteMarketingAssetFile(this.currentFileUpload.id)
				.then((response) => {
					this.resetFileUpload();
				})
				.catch((error) => {
					console.log(error);
				})
		}
	}


	clearUpload(){
		if(this.editMode && this.currentFileUpload?.id != null){
			this.deleteMarketingAssetFile(this.currentFileUploadUrl.id);
		}else{
			this.resetFileUpload();
		}
		
	}

	resetFileUpload(){
		this.currentFileUploadUrl = null;
		this.currentFileUpload = null;
		this.newFileToAttach = false;
	}
}

export default ManageMarketingAsset;