import React, { Component } from 'react';

import {observer} from 'mobx-react';

import { SecondaryAppMediumTitle, TertiaryAppSmallTitle, ExtraSmallBody} from './common/text'
import {EditIcon, ArrowRightIcon, DownloadIcon} from './common/icon'
import {Button} from './common/button';
import {FullGrid} from './elements/grid';

const colors = require('../color.js');

@observer
class AffiliateLink extends Component{
	constructor(props){
		super(props);
		let translate = this.props.t;
		this.state = {
			defaultText: translate('org-admin.home.affilliate-links.copy-button'),
			copyText: translate('org-admin.home.affilliate-links.copied-button'),
			isCopying: false,
			buttonText: translate('org-admin.home.affilliate-links.copy-button')
		}
		this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	copyToClipboard(){
		let affiliateLink = this.props.affiliateLink;
		if('clipboard' in navigator){
			navigator.clipboard.writeText(affiliateLink.url);
			this.setState({
				buttonText: this.state.copyText
			});

			setTimeout(() => {
				this.setState({
					buttonText: this.state.defaultText
				});
			}, 2500)
		}
	}

	render(){
		let affiliateLink = this.props.affiliateLink;
		let translate = this.props.t;
		return (
			<div className="flex flex-col xl:flex-row justify-between w-full items-center mt-4">
				<div className="flex flex-col">
					<ExtraSmallBody>{affiliateLink.url}</ExtraSmallBody>
					<ExtraSmallBody className="text-nav-inactive-color">{translate('org-admin.home.affilliate-links.visitors.part1')} {affiliateLink.visitors} {translate('org-admin.home.affilliate-links.visitors.part2')} {affiliateLink.conversions} {translate('org-admin.home.affilliate-links.visitors.part3')}</ExtraSmallBody>
				</div>
				<div className="flex w-1/3 mt-4 xl:mt-0">
					<Button
						width="w-auto"
						height="h-auto"
						className="px-4 py-2 rounded-xl"
						textSize="text-xs"
		                bgColor="nav-active-color"
		                borderColor="white"
		                onClick={this.copyToClipboard}
		              >{this.state.buttonText}</Button>
		        </div>
			</div>
		)
	}
}

@observer
class AffiliateLinks extends Component{
	render(){
		let translate = this.props.t;
		return (
			<FullGrid>
				<div className="flex w-full flex-col">
					
					<SecondaryAppMediumTitle>{translate('org-admin.home.affilliate-links.title')}</SecondaryAppMediumTitle>
					<div className="flex mt-2">
						<ExtraSmallBody className="text-nav-inactive-color">{translate('org-admin.home.affilliate-links.tagline')}</ExtraSmallBody>
					</div>

					<div className="flex w-full flex-col mt-4">
						{this.props.affiliateLinks.map((affiliateLink, idx) => 
							<AffiliateLink t={translate} affiliateLink={affiliateLink} key={idx} /> 
						)}
					</div>
				</div>
			</FullGrid>
		)
	}
}

@observer
class MarketingAssets extends Component{
	constructor(props){
		super(props);
		this.onDownload = this.onDownload.bind(this);
	}

	onDownload(downloadMarketingAsset){
		let downloadFile = downloadMarketingAsset.marketing_asset_file;
		if(downloadFile == null) return;

		const link = document.createElement('a');
        link.href = downloadFile.file_url;
        link.setAttribute(
          'download',
          `${downloadFile.name}`
        );

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
	}

	render(){
		let translate = this.props.t;
		return (
			<FullGrid>
				<div className="flex flex-col">
					<div className="flex flex-row items-center">
						<div className="flex flex-col">
							<SecondaryAppMediumTitle>{translate('org-admin.home.marketing-assets.title')}</SecondaryAppMediumTitle>
						</div>
						<div className="flex flex-1 flex-row items-center justify-end cursor-pointer" onClick={() => this.props.onMarketingAssets()}>
							<ExtraSmallBody className="bold-font text-nav-active-color">{translate('org-admin.home.marketing-assets.goto')}</ExtraSmallBody>
							<div className="flex ml-2"><ArrowRightIcon className="w-4 h-4" color={colors['nav-active-color']} /></div>
						</div>
					</div>
					<div className="flex flex-col h-32 mt-2">
						<ExtraSmallBody className="text-nav-inactive-color">{translate('org-admin.home.marketing-assets.part1')} <br /> {translate('org-admin.home.marketing-assets.part2')}</ExtraSmallBody>
					</div>
					<div className="flex w-full grid grid-cols-2 gap-4 mt-4">
						{this.props.marketingAssets.length == 0 && 
							<div className="col-span-2">
								<ExtraSmallBody>{translate('org-admin.home.marketing-assets.nodata')}</ExtraSmallBody>
							</div>
						}
						{this.props.marketingAssets.map((marketingAsset) => 
							<div className="flex flex-row justify-between items-center cursor-pointer w-full border rounded-lg border-border-color py-2 px-2" onClick={() => this.onDownload(marketingAsset)}>
								<div className="flex flex-col">
									<ExtraSmallBody className="bold-font">{marketingAsset.title}</ExtraSmallBody>
									<ExtraSmallBody>{marketingAsset.subtitle}</ExtraSmallBody>
								</div>
								<div className="flex">
									<DownloadIcon className="w-4 h-4" color={colors['nav-active-color']} />
								</div>
							</div>
						)}
					</div>
				</div>
			</FullGrid>
		)
	}
}

export {
	AffiliateLinks,
	MarketingAssets
}