import { observable, action, computed, extendObservable} from 'mobx';

import IngredientApi from '../../../../services/ingredient';
import BlendApi from '../../../../services/blend';
import { isRequiredValidator, isPositiveNumberValidator, isNotNullValidator } from '../../../../services/validation';
import {MEASUREMENTS} from '../../../../services/util';

import {getIngredientAvatarUrl} from '../util'
import { FieldState, FormState } from 'formstate';

import moment from 'moment'

class IngredientCompareToolStore{

	appStore;

	id;
	@observable fetching;
		
	@observable selectedIngredientOption;
	@observable ingredients;
	@observable ingredientSearchText;

	@observable name;
	@observable notes;
	@observable comparisons;
	@observable createdOn;

	@observable currentComparisonReport;

	@observable showSaveModal;
	@observable viewMode;
	@observable editMode;
	@observable addMode;
	@observable searchTimeout;

	ingredientApi;
	blendApi;
	
	constructor(appStore){
		this.appStore = appStore;
		this.ingredientApi = new IngredientApi(appStore);
		this.blendApi = new BlendApi(appStore);
		this.initStore();
	}

	initStore(){
		this.id = null;
		this.fetching = false;
		this.selectedIngredientOption = null;
		this.ingredients = [];
		this.comparisons = [];
		this.name = new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n));
		this.notes = new FieldState(null);
		this.showSaveModal = false;
		this.addMode = true;
		this.viewMode = false;
		this.editMode = false;
		this.currentComparisonReport = null;
		this.createdOn = null;
		if (this.searchTimeout != null){
			clearTimeout(this.searchTimeout);
		}
		this.searchTimeout = null;
	}

	@action toggleEditMode(){
		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	@action cancelEditMode(){
		this.editMode = false;
		this.viewMode = true;
		this.addMode = false;
		this.syncComparisonReport(this.currentComparisonReport);
	}

	syncComparisonReport(comparisonReport){
		this.id = comparisonReport.id;
		this.name.value = comparisonReport.name;
		this.notes.value = comparisonReport.comparison_notes;
		this.comparisons = comparisonReport.ingredients.map((i) => this.toIngredient(i));
		this.createdOn = moment(comparisonReport.created_on);
	}

	formSync(comparisonReportId, editMode=false){
		this.fetching = true;
		this.editMode = editMode;
		this.viewMode = !editMode;
		this.addMode = false;

		this.ingredientApi.getComparisonReportById(comparisonReportId)
			.then((response) => {
				let comparisonReport = response.comparison_report;
				this.currentComparisonReport = comparisonReport;
				this.syncComparisonReport(comparisonReport);
				
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	fetchIngredients(){
		this.ingredientApi.getAllIngredients(1, this.ingredientSearchText)
			.then((response) => {
				this.ingredients = response.ingredients;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action onChangeIngredientSearchText(val){
		this.ingredientSearchText = val;

		if (this.searchTimeout != null){
			clearTimeout(this.searchTimeout);
		}

		this.searchTimeout = setTimeout(() => {
			this.fetchIngredients();
		}, 500);
	}

	@action onClearSelectedIngredient(){
		this.ingredientSearchText = '';
		this.selectedIngredientOption = null;
		this.ingredients = [];
	}

	@action onSelectIngredient(val){
		let ingredient = this.ingredients.find((i) => i.id == val);
		if(ingredient != null){
			let name = ingredient.name;
			if(ingredient.latin_name != null){
				name = `${name} (${ingredient.latin_name})`
			}
			this.ingredientSearchText = name;
			this.selectedIngredientOption = ingredient.id;
		}
	}

	@computed get ingredientOptions(){
		let currentIngredientIds = this.comparisons.map((i) => i.id);
		return this.ingredients.filter((i) => !currentIngredientIds.includes(i.id)).map((i) => {
			let name = i.name;
			if(i.latin_name != null){
				name = `${name} - ${i.latin_name}`
			}
			if(i.origin != null){
				name = `${name} (${i.origin})`
			}
			return {
				value: i.id,
				label: name
			}
		})
	}

	@action removeComparison(id){
		this.comparisons = this.comparisons.filter((c) => c.id != id);
	}

	@action toggleFavourite(id){
		let ingredientIdx = this.comparisons.findIndex((c) => c.id == id);
		if(ingredientIdx == -1) return;
		let ingredient = this.comparisons[ingredientIdx];
		let newValue = !ingredient.favourited;

		this.fetching = true;
		this.ingredientApi.patchIngredient(id, {
			favourited: newValue
		})	
		.then((response) => {
			let updatedIngredient = response.ingredient;
			this.comparisons[ingredientIdx].favourited = updatedIngredient.favourited;
		})
		.catch((error) => {
			console.log(error);
		})
		.finally(() => {
			this.fetching = false;
		})
	}

	@computed get measurementOptions(){
		return [
			{
				value: MEASUREMENTS.ml,
				label: this.appStore.i18n.t('user.manage-blend.measurements.ml')
			},
			{
				value: MEASUREMENTS.drops,
				label: this.appStore.i18n.t('user.manage-blend.measurements.drops')
			},
			{
				value: MEASUREMENTS.grams,
				label: this.appStore.i18n.t('user.manage-blend.measurements.grams')
			},
			{
				value: MEASUREMENTS.ounces,
				label: this.appStore.i18n.t('user.manage-blend.measurements.ounces')
			},
			{
				value: MEASUREMENTS.percentage,
				label: this.appStore.i18n.t('user.manage-blend.measurements.percentage')
			}
		]
	}

	@action toIngredient(ingredient){
		extendObservable(ingredient, {
			selectedBlend: new FieldState(null),
			blendSearchText: new FieldState(null),
			volume: new FieldState(0).validators((val) => isPositiveNumberValidator(val, this.appStore.i18n)),
			selectedVolumeMeasurement: new FieldState(this.measurementOptions[0].value).validators((val) => isNotNullValidator(val, this.appStore.i18n)),
			blendOptions: new FieldState([]),
			chemicalFamilySrc: null,
			acidFamilySrc: null,
			get canAddToBlend() {
				return this.selectedBlend.value != null && this.volume.value != null && this.volume.value.length > 0 && this.volume.error == null && this.selectedVolumeMeasurement.value != null;
			}
		})
		return ingredient;
	}

	@action onAddToComparison(){
		let currentIngredientId = this.selectedIngredientOption;

		if(currentIngredientId == null) return null;
		this.fetching = true;
		this.ingredientApi.getIngredientById(currentIngredientId)
			.then((response) => {
				let ingredient = this.toIngredient(response.ingredient);
				if(ingredient != null){
					this.comparisons.push(ingredient);
					this.ingredientSearchText = '';
					this.selectedIngredientOption = null;
					this.ingredients = [];
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action hideSaveModal(){
		this.showSaveModal = false;
	}

	@action displaySaveModal(){
		this.showSaveModal = true;
	}

	@computed get canSaveReport(){
		return this.comparisons.length > 0 && this.name.value?.length > 0;
	}

	@action onBlendSelection(id, blendId){
		let ingredientIdx = this.comparisons.findIndex((i) => i.id == id);
		if(ingredientIdx == -1) return;
		let blendOption = this.comparisons[ingredientIdx].blendOptions.value.find((b) => b.value == blendId);
		if(blendOption == null) return;
		this.comparisons[ingredientIdx].selectedBlend.value = blendOption.value;
		this.comparisons[ingredientIdx].blendSearchText.value = blendOption.label;
	}

	@action onChangeBlendSearchText(id, val){
		let ingredientIdx = this.comparisons.findIndex((i) => i.id == id);
		if(ingredientIdx == -1) return;
		this.comparisons[ingredientIdx].blendSearchText.onChange(val);
		this.fetching = true;
		this.blendApi.getAllBlends(1, this.comparisons[ingredientIdx].blendSearchText.value, null, null, null, null, null, null, true)
			.then((response) => {
				let blends = response.blends;
				this.comparisons[ingredientIdx].blendOptions.value = blends.map((blend) => {
					return {
						value: blend.id,
						label: blend.name
					}
				})
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action onClearSelectedBlend(id){
		let ingredientIdx = this.comparisons.findIndex((i) => i.id == id);
		if(ingredientIdx == -1) return;
		this.comparisons[ingredientIdx].selectedBlend.value = null;
		this.comparisons[ingredientIdx].blendSearchText.value = '';
	}

	fetchIngredientsWithIds = async (ingredientIds) => {
		this.fetching = true;
		try{
			let ingredients = await this.ingredientApi.getAllIngredientByIds(ingredientIds)
			let mappedIngredients = ingredients.map((i) => this.toIngredient(i));
			this.comparisons = this.comparisons.concat(mappedIngredients);
		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}


	@action addIngredientToBlend(ingredientId, blendId){
		let ingredientIdx = this.comparisons.findIndex((i) => i.id == ingredientId);
		if(ingredientIdx == -1) return;
		let ingredientVolume = parseFloat(this.comparisons[ingredientIdx].volume.value);
		if(isNaN(ingredientVolume)) return;
		let ingredientMeasurement = this.comparisons[ingredientIdx].selectedVolumeMeasurement.value;
		if(ingredientMeasurement == null) return;
		this.appStore.goToEditBlendWithIngredients(blendId, [{
			'ingredient': this.comparisons[ingredientIdx],
			'volume': ingredientVolume.toFixed(2),
			'measurement': ingredientMeasurement
		}]);
	}

	@action addIngredientToNewBlend(ingredientId){
		let ingredientIdx = this.comparisons.findIndex((i) => i.id == ingredientId);
		if(ingredientIdx == -1) return;

		let ingredientVolume = parseFloat(this.comparisons[ingredientIdx].volume.value);
		if(isNaN(ingredientVolume)){
			ingredientVolume = 10;
		}

		let ingredientMeasurement = this.comparisons[ingredientIdx].selectedVolumeMeasurement.value;
		if(ingredientMeasurement == null){
			ingredientMeasurement = 'ml'
		}

		this.appStore.goToNewBlendWithIngredients([{
			'ingredient': this.comparisons[ingredientIdx],
			'volume': ingredientVolume,
			'measurement': ingredientMeasurement
		}]);
	}

	save(){
		this.fetching = true;
		let payload = {
			name: this.name.value,
			comparison_notes: this.notes.value,
			ingredient_ids: this.comparisons.map((c) => c.id)
		}

		if(this.editMode){
			this.ingredientApi.updateIngredientComparisonReportById(this.id, payload)
				.then((response) => {
					this.appStore.displaySaveDialog();
					this.appStore.goToCompareIngredients();
					this.showSaveModal = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}else{
			this.ingredientApi.newIngredientComparisonReport(payload)
				.then((response) => {
					this.appStore.displaySaveDialog();
					this.appStore.goToCompareIngredients();
					this.showSaveModal = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}

	@action getAvatarUrl(ingredient, usePng=false){
		return getIngredientAvatarUrl(ingredient, usePng);
	}

	@computed get compareIngredientPDFName(){
		if(!this.viewMode) return null;
		return `${this.name.value}.pdf`;
	}

	@computed get canDownloadPDF(){
		return true;
	}

	@action setIngredientChemicalFamilyChartSrc(id, dataUrl){
		let ingredientIdx = this.comparisons.findIndex((c) => c.id == id);
		if(ingredientIdx == -1) return;
		this.comparisons[ingredientIdx].chemicalFamilySrc = dataUrl;
	}

	@action setIngredientAcidFamilyChartSrc(id, dataUrl){
		let ingredientIdx = this.comparisons.findIndex((c) => c.id == id);
		if(ingredientIdx == -1) return;
		this.comparisons[ingredientIdx].acidFamilySrc = dataUrl;
	}

}

export default IngredientCompareToolStore;