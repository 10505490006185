import BaseApi from '../services/base';

class StatApi extends BaseApi{
	constructor(props){
		super(props);
	}

	fetchSalesStats(startDate, endDate){
		return this.fetch(`v1/stats/sales?start_date=${startDate}&end_date=${endDate}`, {
			method: 'GET',
			auth: true
		}) 
	}

	fetchOrganisationSalesStats(startDate, endDate){
		return this.fetch(`v1/stats/organisations/sales?start_date=${startDate}&end_date=${endDate}`, {
			method: 'GET',
			auth: true
		}) 
	}

	fetchOrganisationStats(startDate, endDate){
		return this.fetch(`v1/stats/organisations?start_date=${startDate}&end_date=${endDate}`, {
			method: 'GET',
			auth: true
		}) 
	}

	fetchUserStats(startDate, endDate){
		return this.fetch(`v1/stats/users?start_date=${startDate}&end_date=${endDate}`, {
			method: 'GET',
			auth: true
		}) 
	}

	fetchMRRGeographyBreakdown(){
		return this.fetch(`v1/stats/mrr/geography`, {
			method: 'GET',
			auth: true
		}) 
	}

	fetchOrganisationUserStats(startDate, endDate){
		return this.fetch(`v1/stats/organisations/users?start_date=${startDate}&end_date=${endDate}`, {
			method: 'GET',
			auth: true
		}) 
	}

	fetchCurrentUserTopStats(){
		return this.fetch(`v1/stats/top`, {
			method: 'GET',
			auth: true
		}) 
	}
}

export default StatApi;