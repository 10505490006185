import { observable, action, computed, autorun} from 'mobx';
import { isEmailValidator, isRequiredValidator, validator } from '../../services/validation';
import { FieldState, FormState } from 'formstate';

import ContactApi from '../../services/contact';


import moment from 'moment'

class ContactStore{
	appStore;

	contactApi;

    @observable email;
    @observable name;
    @observable subject;
    @observable message;

    @observable fetching;

	@observable form;

    @observable sent;
    @observable previousView;

	constructor(appStore){
    	this.appStore = appStore;
    	this.initStore();
        this.contactApi = new ContactApi(appStore);
    }

    initStore(){
        this.name = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n));
    	this.email = new FieldState('').validators((val) => isEmailValidator(val, this.appStore.i18n));
        this.subject = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n));
        this.message = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n));
        this.form = new FormState({
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message
        });
        this.fetching = false;
        this.sent = false;
        this.previousView = null;
    }

    @computed get isValidForm(){
        let hasName = this.name.value != '' && this.name.error == null;
        let hasEmail = this.email.value != '' && this.email.error == null;
        let hasSubject = this.subject.value != '' &&this.subject.error == null;
        let hasMessage = this.message.value != '' && this.message.error == null;
        return hasName && hasEmail && hasSubject && hasMessage;
    }

    send = async () => {
        const res = await this.form.validate();
        if (res.hasError) {
          return;
        }
        
        let message = {
            name: this.name.value,
            email: this.email.value,
            subject: this.subject.value,
            message: this.message.value
        }
        this.fetching = true;
        this.contactApi.submit(message)
            .then((response) => {
                this.sent = true;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.fetching = false;
            })

    };

    @action setPreviousView(view){
        this.previousView = view;
    }

    @action onExit(){
        this.appStore.backToPreviousView();
    }
}

export default ContactStore;