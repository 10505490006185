import { observable, action, computed } from 'mobx';

import PlanApi from '../../services/plan';
import CurrencyApi from '../../services/currency';

import {getDisplayPeriod} from '../../services/util';

class PricingStore{
	appStore; 
	
	planApi;
	currencyApi;

	@observable currencies;
	@observable plans;
	@observable periods;

	@observable currentPeriod;
	@observable currentCurrency;

	@observable currentPlan;
	@observable showDiscoverMoreModal;

	constructor(appStore){
		this.appStore = appStore;
		this.planApi = new PlanApi(appStore);
		this.currencyApi = new CurrencyApi(appStore);
		this.initStore();
	}

	initStore(){
		this.currencies = [];
		this.plans = [];
		this.periods = ['Monthly', 'Annually'];
		this.currentPeriod = this.periods[0].toLowerCase();
		this.currentCurrency = null;
		this.currentPlan = null;
		this.showDiscoverMoreModal = false;
	}

	fetchCurrencies(){
		this.currencyApi.getAll()
			.then((response) => {
				this.currencies = response.currencies;
				if(this.currencies.length > 0){
					this.currentCurrency = this.currencies[0].id;
				}
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchPlans(){
		this.planApi.getAll()
			.then((response) => {
				this.plans = response.plans.map((p) => {
					p.displayPeriod = getDisplayPeriod(p.period);
					p.periodValue = `${p.currency.symbol}${p.period_value}`;
					return p
				});
			})
			.catch((error) => {
				console.log(error)
			})
	}

	@computed get getPlansForAvailablePeriods(){
		if(this.currentPlan == null) return [];
		let planName = this.currentPlan.name;
		let currentCurrency = this.currentPlan.currency.name;
		return this.plans.filter((p) => p.name == planName && p.currency.name == currentCurrency);
	}

	@action getAllPlansForName(planName){
		if(this.currentCurrency == null) return [];

		let foundCurrency = this.currencies.find((c) => c.id == this.currentCurrency);
		if(foundCurrency == null) return [];
		return this.plans.filter((p) => p.name == planName && p.currency.name == foundCurrency.name);
	}

	@action onChangeCurrency(val){
		this.currentCurrency = val;
	}

	@action onChangePeriod(val){
		this.currentPeriod = val;
	}

	@action goToSignUpWithPlan(planId){
		let plan = this.plans.find((p) => p.id == planId);
		if(plan != null){
			this.appStore.goToRegistrationWith(plan.currency.id, plan.period, plan.id);
		}
	}

	@computed get currencyOptions(){
		return this.currencies.map((x) => {
			return {
				value: x.id,
				label: x.name
			}
		})
	}

	@computed get periodOptions(){
		return this.periods.map((x) => {
			return {
				value: x.toLowerCase(),
				label: x
			}
		})
	}

	@computed get currentPlans(){
		if(this.currentCurrency == null || this.currentPeriod == null) return [];

		return this.plans.filter((plan) => {
			if(plan.currency.id == this.currentCurrency && plan.period == this.currentPeriod){
				return plan;
			}
		}).sort((plan1, plan2) => {
			if(plan1.period_value < plan2.period_value){
				return -1;
			}else if(plan2.period_value < plan1.period_value){
				return 1;
			}else{
				return 0;
			}
		});
	}

	@action discoverMore(planId){
		this.showDiscoverMoreModal = true;
		this.currentPlan = this.currentPlans.find((p) => p.id == planId);
	}

	@action closeDiscoverMoreModal(){
		this.showDiscoverMoreModal = false;
	}

	@computed get largestFeatureSet(){
		if(this.currentPlans.length == 0){
			return [];
		}else{
			let largestFeatures = null;
			for(let plan of this.currentPlans){
				if(largestFeatures == null){
					largestFeatures = plan.features;
				}else{
					if(plan.features != null && plan.features.length > largestFeatures.length){
						largestFeatures = plan.features;
					}
				}
			}
			return largestFeatures;
		}
	}

	@computed get smallestFeatureSet(){
		if(this.currentPlans.length == 0){
			return [];
		}else{
			let smallestFeatures = null;
			for(let plan of this.currentPlans){
				if(smallestFeatures == null){
					smallestFeatures = plan.features;
				}else{
					if(plan.features.length < smallestFeatures.length){
						smallestFeatures = plan.features;
					}
				}
			}
			return smallestFeatures;
		}
	}
}

export default PricingStore;