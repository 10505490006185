import BaseApi from '../services/base';

class TrainingApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(){
		return this.fetch('v1/training', {
			method: 'GET'
		})
	}
}

export default TrainingApi;