import { observable, action, computed} from 'mobx';

import BlendApi from '../../../../services/blend';

import moment from 'moment'

class BlendCategories{

	appStore;

	@observable showSortDialog;
	@observable showFilterDialog;

	@observable currentFilterValue;
	@observable currentFilters;

	@observable searchTagFilters;

	@observable sortBy;
	@observable fetching;

	@observable blendCategories;

	@observable selectedBlendCategory;

	@observable currentBlends;
	@observable hasMoreBlends;
	@observable blendsPage;

	@observable fetchingBlends;

	@observable page;
	@observable hasMore;

	@observable favouriteBlendCategories;
	@observable archived;

	blendApi;
	
	constructor(appStore){
		this.appStore = appStore;
		this.blendApi = new BlendApi(appStore);
		this.initStore();
	}

	initStore(){
		this.showSortDialog = false;
		this.showFilterDialog = false;
		this.sortBy = null;
		this.currentFilterValue = '';
		this.currentFilters = [];
		this.searchTagFilters = [];
		this.fetching = false;
		this.blendCategories = [];
		this.selectedBlendCategory = null;
		this.currentBlends = [];
		this.page = 1;
		this.hasMore = true;
		this.hasMoreBlends = true;
		this.blendsPage = 1;
		this.favouriteBlendCategories = [];
		this.fetchingBlends = false;
		this.archived = false;
	}

	fetchFavouriteBlendCategories = async () => {
		this.fetching = true;
		this.blendApi.getAllBlendCategories(1, null, null, true, 4)
			.then((response) => {
				this.favouriteBlendCategories = response.blend_categories;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
		
	}

	fetchBlendCategories(reset=false){
		this.fetching = true;

		let sortBy = this.sortBy;
		let filterBy = this.searchTagFilters != null && this.searchTagFilters.length > 0 ? {
			'tags': this.searchTagFilters.map((f) => f.value)
		} : null;

		this.blendApi.getAllBlendCategories(this.page, sortBy, filterBy, null, null, this.archived)
			.then((response) => {
				this.blendCategories = reset ? response.blend_categories : this.blendCategories.concat(response.blend_categories);
				this.hasMore = response.blend_categories.length > 0;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action onNewCategory(){
		this.appStore.goToNewBlendCategory();
	}

	@action onEditCategory(id){
		this.appStore.goToEditBlendCategory(id);
	}

	@action toggleSortDialog(){
		this.showSortDialog = !this.showSortDialog;
		this.showFilterDialog = false;
	}

	@action toggleFilterDialog(){
		this.showFilterDialog = !this.showFilterDialog;
		this.showSortDialog = false;
	}

	@action setSortByField(val){
		this.sortBy = val;
	}

	@action applySort(){
		this.showSortDialog = false;
		this.resetBlendCategories();
	}

	@computed get sortOptions(){
		if(this.appStore == null) return [];
		return [
			{
				label: this.appStore.i18n.t("user.my-blend-categories.sortoptions.name"),
				value: "name"
			}
		]
	}

	resetFilterValue(){
		this.currentFilterValue = '';
	}

	@action newFilterEntry(val){
		let strippedVal = val.trim();
		if(!this.currentFilters.map((f) => f.value).includes(strippedVal)){
			this.currentFilters.push({
				label: val,
				value: val
			});
		}
		this.resetFilterValue();
	}

	@action setFilters(values){
		this.currentFilters = values != null ? values : [];
	}

	@action setCurrentFilterValue(val){
		this.currentFilterValue = val;
	}

	@action applyFilters(){
		this.searchTagFilters = this.currentFilters;
		this.showFilterDialog = false;
		this.resetBlendCategories();
	}

	toBlend(b){
		b.last_edited_at = moment(b.last_edited_at).tz(this.appStore.timezone);
		return b;
	}

	fetchBlendsForSelectedBlendCategory(reset=false){
		this.fetchingBlends = true;
		this.blendApi.getAllBlends(this.blendsPage, null, null, null, this.selectedBlendCategory?.id, null, null)
			.then((response) => {
				let mappedBlends = response.blends.map((b) => this.toBlend(b));
				this.currentBlends = reset ? mappedBlends : this.currentBlends.concat(mappedBlends);
				this.hasMoreBlends = mappedBlends.length > 0;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetchingBlends = false;
			});	
	}

	@action onSelectBlendCategory(id){
		let blendCategory = this.blendCategories.find((b) => b.id == id);
		if(blendCategory != null){
			this.selectedBlendCategory = blendCategory;
			this.resetFetchBlendsForSelectedBlendCategory();
		}
	}

	@action resetFetchBlendsForSelectedBlendCategory(){
		this.blendsPage = 1;
		this.fetchBlendsForSelectedBlendCategory(true);
	}

	@action loadNextBlendsPage(){
		this.blendsPage += 1;
		this.fetchBlendsForSelectedBlendCategory();
	}

	@action onBackToCategories(){
		this.selectedBlendCategory = null;
		this.currentBlends = [];
	}

	@action resetBlendCategories(){
		this.page = 1;
		this.fetchBlendCategories(true);
	}

	@action loadNextPage(){
		this.page += 1;
		this.fetchBlendCategories()
	}

	@action onViewBlend(id){
		this.appStore.goToEditBlend(id, false);
	}

	@action toggleFavouriteBlendCategoryById(id){
		let blendCategoryIdx = this.blendCategories.findIndex((b) => b.id == id);
		if(blendCategoryIdx != null){
			let blendCategory = this.blendCategories[blendCategoryIdx];
			this.blendApi.patchBlendCategory(blendCategory.id, {
				favourited: !blendCategory.favourited
			})
			.then((response) => {
				this.blendCategories[blendCategoryIdx].favourited = response.favourited;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
		}
	}

	@action toggleArchivedBlendCategoryById(id){
		
		let blendCategoryIdx = this.blendCategories.findIndex((b) => b.id == id);
		if(blendCategoryIdx != null){
			let blendCategory = this.blendCategories[blendCategoryIdx];

			let confirmed = window.confirm(blendCategory.archived ? this.appStore.i18n.t('user.my-blend-categories.restore-message') : this.appStore.i18n.t('user.my-blend-categories.archive-message'));
			if(confirmed){

				this.blendApi.patchBlendCategory(blendCategory.id, {
					archived: !blendCategory.archived
				})
				.then((response) => {
					this.blendCategories[blendCategoryIdx].archived = response.archived;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
			}
		}
		
	}

	@action toggleFavouriteBlendCategory(){
		if(this.selectedBlendCategory == null) return null;
		this.fetching = true;

		this.blendApi.patchBlendCategory(this.selectedBlendCategory.id, {
			favourited: !this.selectedBlendCategory.favourited
		})
			.then((response) => {
				this.selectedBlendCategory.favourited = response.favourited;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action toggleArchivedBlendCategory(){
		if(this.selectedBlendCategory == null) return null;
		
		let confirmed = window.confirm(this.selectedBlendCategory.archived ? this.appStore.i18n.t('user.my-blend-categories.restore-message') : this.appStore.i18n.t('user.my-blend-categories.archive-message'));
		if(confirmed){
			this.fetching = true;

			this.blendApi.patchBlendCategory(this.selectedBlendCategory.id, {
				archived: !this.selectedBlendCategory.archived
			})
				.then((response) => {
					this.selectedBlendCategory.archived = response.archived;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}

	onArchiveBlend(id){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-blend-library.archive-confirm'));
		if(confirmed){
			this.onToggleArchivedBlend(id);
		}
	}

	onRestoreBlend(id){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-blend-library.restore-confirm'));
		if(confirmed){
			this.onToggleArchivedBlend(id);
		}
	}

	@action onToggleArchivedBlend(id){
		let blendIdx = this.currentBlends.findIndex((i) => i.id == id);
		if(blendIdx != -1){
			let blend = this.currentBlends[blendIdx];
			this.fetching = true;
			this.blendApi.patchBlend(blend.id, {
				archived: !blend.archived
			})
			.then((response) => {
				let updatedBlend = response.blend;
				this.currentBlends[blendIdx].archived = updatedBlend.archived;
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.fetching = false;
			})
		}
	}

	onDuplicateBlend(blendId){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-blend-library.duplicate-confirm'));
		if(confirmed){
			let blendIdx = this.currentBlends.findIndex((i) => i.id == blendId);
			if(blendIdx != -1){
				this.fetching = true;
				this.blendApi.duplicateBlend(blendId)
					.then((response) => {
						this.currentBlends.splice(blendIdx, 0, this.toBlend(response.blend));
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.fetching = false;
					})
			}
		}
	}

	onPublicBlend(id){
		let confirmed = window.confirm(this.appStore.i18n.t('user.my-blend-library.public-confirm'));
		if(confirmed){
			this.onTogglePublicBlend(id);
		}
	}

	onToggleArchived(){
		this.page = 1;
		this.archived = !this.archived;
		this.fetchBlendCategories(true);
	}

	@action onTogglePublicBlend(id){
		let blendIdx = this.currentBlends.findIndex((i) => i.id == id);
		if(blendIdx != -1){
			let blend = this.currentBlends[blendIdx];
			this.fetching = true;
			this.blendApi.patchBlend(blend.id, {
				public: !blend.public
			})
			.then((response) => {
				let updatedBlend = response.blend;
				this.currentBlends[blendIdx].public = updatedBlend.public;
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				this.fetching = false;
			})
		}
	}

}

export default BlendCategories;