import BaseApi from '../services/base';

class SubscriptionApi extends BaseApi{
	constructor(props){
		super(props);
	}

	subscribe(planId, paymentMethod, cardholderName, billingAddress, taxId=null, discountCode=null, referral=null){
		return this.fetch('v1/subscriptions', {
			method: 'POST',
			body: JSON.stringify({
				plan_id: planId,
				cardholder_name: cardholderName,
				billing_address: billingAddress,
				tax_id: taxId,
				payment_method: paymentMethod,
				discount_code: discountCode,
				referral: referral
			}),
			auth: true
		})
	}

	verifySubscription(paymentIntent=null){
		return this.fetch('v1/subscriptions/verify', {
			method: 'POST',
			body: JSON.stringify({
				payment_intent: paymentIntent
			}),
			auth: true
		})
	}

	getSetupIntent(){
		return this.fetch('v1/subscriptions/user/setup-intent', {
			method: 'GET',
			auth: true
		})
	}

	updateCardForCurrentUser(paymentMethodId){
		return this.fetch('v1/subscriptions/user/card', {
			method: 'PUT',
			body: JSON.stringify({
				payment_method_id: paymentMethodId
			}),
			auth: true
		})
	}

	previewUpgradeSubscription(planId){
		return this.fetch('/v1/subscriptions/upgrade/preview', {
			method: 'POST',
			body: JSON.stringify({
				new_plan_id: planId
			}),
			auth: true
		})
	}

	upgrade(planId){
		return this.fetch('/v1/subscriptions/upgrade', {
			method: 'POST',
			body: JSON.stringify({
				new_plan_id: planId
			}),
			auth: true
		})
	}
}

export default SubscriptionApi;