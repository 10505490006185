import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import Main from '../../../elements/main';

import InfiniteScroll from 'react-infinite-scroll-component';

import { SmallBody, Body, SecondaryAppLargeTitle, SecondaryAppExtraSmallTitle, TertiaryAppSmallTitle} from '../../../common/text';
import { BlendBottlesIcon, ArrowRightIcon, ViewIcon, PlusIcon, EditIcon, EyeIcon, DuplicateIcon, ArchiveIcon, TickIcon} from '../../../common/icon'
import {Button} from '../../../common/button';
import {ToolTipItem} from '../../../common/input';
import {TagInput} from '../../../common/tag';
import {SortDialog, FilterField, FilterDialog, Status, SortingField} from '../../../elements/list';
import BlendPreview from '../blend-preview';
const colors = require('../../../../color.js');

@observer
class MyBlendLibrary extends Component{
	constructor(props){
		super(props);
		this.onSortOption = this.onSortOption.bind(this);
		this.onApplySort = this.onApplySort.bind(this);
		this.onViewTutorial = this.onViewTutorial.bind(this);
	}

	componentWillUnmount(){
		let blendsLibraryStore = this.props.blendsLibraryStore;
		blendsLibraryStore.initStore();
	}

	onSortOption(val){
		let blendsLibraryStore = this.props.blendsLibraryStore;
		blendsLibraryStore.setSortByField(val);
	}

	onApplySort(){
		let blendsLibraryStore = this.props.blendsLibraryStore;
		blendsLibraryStore.applySort();
	}

	onViewTutorial(){
		let blendsLibraryStore = this.props.blendsLibraryStore;
		let url = null;
		if(blendsLibraryStore.showPublicLibrary){
			url = 'http://help.labaroma.com/en/articles/4745403-3-labaroma-blend-library-tutorial';
		}else{
			url = 'http://help.labaroma.com/en/articles/4745268-2-my-blend-library-tutorial';
		}
		window.open(url, '_blank');
	}

	render(){
		let blendsLibraryStore = this.props.blendsLibraryStore;
		let translate = this.props.t;
		let archivedButtonText = !blendsLibraryStore.showArchived ? translate('user.my-blend-library.archived-blends-button') : translate('user.my-blend-library.active-blends-button');
		let isPublicLibrary = blendsLibraryStore.showPublicLibrary;
		let blendLibraryCategory = blendsLibraryStore.currentLibraryCategory;
		let title = null;
		if(blendLibraryCategory != null){
			title = `${blendLibraryCategory.name} ${translate('user.blend-library-category.blends')}`;
		}else{
			if(isPublicLibrary){
				title = translate('user.labaroma-blend-library.tagline');
			}else{
				title = translate('user.my-blend-library.tagline')
			}
		}
		
		let tagline = null;
		if(blendLibraryCategory != null){
			tagline = blendLibraryCategory.bio != null ? blendLibraryCategory.bio : translate('user.blend-library-category.default-tagline');
		}else{
			if(isPublicLibrary){
				tagline = (
					<SmallBody>{translate('user.labaroma-blend-library.paragraph.part1')} <br /> {translate('user.labaroma-blend-library.paragraph.part2')}</SmallBody>
				)
			}else{
				tagline = (
					<SmallBody>{translate('user.my-blend-library.paragraph.part1')} <br /> {translate('user.my-blend-library.paragraph.part2')}</SmallBody>
				)
			}
		}
		
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{title}</SecondaryAppLargeTitle>
							{tagline}
							
							<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={this.onViewTutorial}>
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.my-blend-library.video-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						{blendLibraryCategory == null && 
							<div className="flex hidden lg:block ml-4">
								<BlendBottlesIcon color={isPublicLibrary ? 'pink' : 'blue'} className="w-48 h-32" />
							</div>
						}
					</div>
					<div className="flex w-full justify-end mt-2">
						<div className="flex">
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								fgColor={blendsLibraryStore.showArchived ? "list-text" : "list-text"}
								bgColor={blendsLibraryStore.showArchived ? "gray-bg-button" : "white"}
								borderColor={blendsLibraryStore.showArchived ? "gray-bg-button" : "list-text"}
								onClick={() => blendsLibraryStore.toggleArchived()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{archivedButtonText} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><ArchiveIcon color={colors['list-text']} className="w-4 h-4" /></div>
								</div>
							</Button>
						</div>
						<div className="flex ml-4">
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor="pink-coral"
								onClick={() => blendsLibraryStore.onNewBlend()}
							>
								<div className="flex flex-row items-center justify-center">
									<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-blend-library.new-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><PlusIcon /></div>
								</div>
							</Button>
						</div>
					</div>

					<div className="flex flex-col mt-4">
						<div className="relative flex flex-row justify-end items-center">
							<div className="relative flex flex-row items-center">
								<FilterField 
									onClick={() => blendsLibraryStore.toggleFilterDialog()}
									text={translate('user.my-blend-library.filter')} />
								
								{blendsLibraryStore.showFilterDialog && 
									<FilterDialog
									 	t={translate}
									 	onApply={() => blendsLibraryStore.applyFilters()}
									>
										<div className="flex w-32 flex-col">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.my-blend-library.tags-filter')}</TertiaryAppSmallTitle></div>
											<div className="flex mt-2">
												<TagInput
													 t={translate}
													 inputValue={blendsLibraryStore.currentFilterValue}
													 onInputChange={(val) => blendsLibraryStore.setCurrentFilterValue(val)}
													 onChange={(values) => blendsLibraryStore.setFilters(values)}
													 onNewEntry={(val) => blendsLibraryStore.newFilterEntry(val)}
													 values={blendsLibraryStore.currentFilters} 
												/>
											</div>
										</div>
									</FilterDialog>
								}
							</div>
							<div className="flex ml-8">
								<div className="relative flex flex-row items-center">
									<SortingField 
										text={translate('user.my-blend-library.sort')}
										onClick={() => blendsLibraryStore.toggleSortDialog()}
									/>
									{blendsLibraryStore.showSortDialog && 
										<SortDialog
										 	t={translate}
											sortOptions={blendsLibraryStore.sortOptions}
											currentOption={blendsLibraryStore.sortBy}
											onOption={this.onSortOption}
											onApply={this.onApplySort}
										/>
									}

							</div>
							</div>
						</div>
					</div>
					<div className="flex flex-1 flex-col">
						<InfiniteScroll
							  dataLength={blendsLibraryStore.blends.length}
							  next={() => blendsLibraryStore.loadNextPage()}
							  hasMore={blendsLibraryStore.hasMore}
							  loader={null}
							  endMessage={null}
						>
							{blendsLibraryStore.blends.map((blend) => 
								<BlendPreview 
									t={translate} 
									blend={blend}
									onView={(id) => blendsLibraryStore.onViewBlend(id)}
									onEdit={(id) => blendsLibraryStore.onEditBlend(id)}
									onArchive={(id) => blendsLibraryStore.onArchiveBlend(id)}
									onRestore={(id) => blendsLibraryStore.onRestoreBlend(id)}
									onDuplicate={(id) => blendsLibraryStore.onDuplicateBlend(id)}
									onToggleAvailable={(id) => blendsLibraryStore.onPublicBlend(id)}
									canManage={blendsLibraryStore.hasEditAccessToBlend(blend)}
									canViewCompliance={blendsLibraryStore.canViewCompliance} 
									showAvailability={blendsLibraryStore.showAvailability}
								/>
							)}
							{blendsLibraryStore.blends.length == 0 && !blendsLibraryStore.fetching &&
								<div className="flex w-full justify-center mt-8">
									<Body>{translate('user.my-blend-library.no-blends')}</Body>
								</div>
							}
							{blendsLibraryStore.blends.length == 0 && blendsLibraryStore.fetching &&
								<div className="flex w-full justify-center mt-8">
									<Body>{translate('user.my-blend-library.fetching-blends')}</Body>
								</div>
							}
						</InfiniteScroll>
					</div>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(MyBlendLibrary);