import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';

import {inject, observer} from 'mobx-react';
import { SmallBody, Body, SecondaryAppLargeTitle, SecondaryAppSmallTitle, TertiaryAppSmallTitle,TertiaryAppExtraSmallTitle, ExtraSmallBody} from '../../../common/text';
import { OpenBookIcon, DeleteIcon, DownloadIcon, EditIngredientIcon, ArrowRightIcon, PlusIcon, StarIcon, EditIcon, EyeIcon, DuplicateIcon, ArchiveIcon, TickIcon} from '../../../common/icon'
import {Button} from '../../../common/button';
import Main from '../../../elements/main';

const colors = require('../../../../color.js');


class IngredientCircle extends Component{
	render(){
		return (
			<div className={`w-20 h-20 xl:w-28 xl:h-28 flex items-center justify-center border-radius-50 bg-${this.props.color}`}>
				<EditIngredientIcon color="transparent" className="w-1/2 h-1/2" />
			</div>
		)
	}
}

@observer
class ComparisonOverview extends Component{
	render(){
		let translate = this.props.t;
		let comparison = this.props.comparison;
		return (
			<div className="flex flex-1 flex-col bg-white rounded-lg px-6 py-8">
				<div className="flex w-full flex-col border-black border-b py-2">
					<SecondaryAppSmallTitle>{comparison.name}</SecondaryAppSmallTitle>
					<div className="flex flex-row w-full items-center justify-between mt-2">
						<SmallBody>{comparison.created_on.format('DD MMM, YYYY')}</SmallBody>
					</div>
				</div>
				<div className="flex flex-1 w-full flex-col border-black border-b py-8">
					<TertiaryAppSmallTitle>{translate('user.compare-ingredients.ingredients')}</TertiaryAppSmallTitle>

					<div className="flex flex-col mt-4">
						{comparison.ingredients.map((ingredient) =>
							<div className="flex flex-row items-center mt-1">
								<div className="w-3 h-3 border-radius-50" style={{ backgroundColor: ingredient.hex_color}}></div>
								<div className="ml-4"><SmallBody>{ingredient.name}</SmallBody></div>
							</div>
						)}
					</div>
					
				</div>
				<div className="flex flex-1 w-full flex-col border-black py-4">
					<TertiaryAppSmallTitle>{translate('user.compare-ingredients.comparison-notes')}</TertiaryAppSmallTitle>

					<SmallBody className="mt-2">{comparison.comparison_notes != null ? comparison.comparison_notes.substring(0, 100) : translate('user.compare-ingredients.no-notes')}</SmallBody>

					<div className="flex flex-col justify-end mt-8">
						<Button
							width="w-full"
							height="h-12"
							className="px-4 rounded-lg"
							bgColor="nav-active-color"
							fetching={false}
							onClick={() => this.props.onEdit(comparison.id)}
						>
							<div className="flex flex-row items-center justify-center">
								<div className="flex"><TertiaryAppExtraSmallTitle>{translate('user.compare-ingredients.view-edit-button')} &nbsp;</TertiaryAppExtraSmallTitle></div>
								<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
							</div>
						</Button>
						{this.props.editDeleteMode && 
							<div className="flex mt-4">
								<Button
									width="w-full"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="error"
									fetching={this.props.fetching}
									onClick={() => this.props.onDelete(comparison.id)}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppExtraSmallTitle>{translate('user.compare-ingredients.delete-report-button')} &nbsp;</TertiaryAppExtraSmallTitle></div>
										<div className="flex"><DeleteIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

@inject('ingredientComparisonsStore')
@observer
class IngredientComparison extends Component{
	componentDidMount(){
		let ingredientComparisonsStore = this.props.ingredientComparisonsStore;
		ingredientComparisonsStore.fetchComparisonReports();
	}

	componentWillUnmount(){
		let ingredientComparisonsStore = this.props.ingredientComparisonsStore;
		ingredientComparisonsStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let ingredientComparisonsStore = this.props.ingredientComparisonsStore;
		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.compare-ingredients.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.compare-ingredients.paragraph.part1')} <br /> {translate('user.compare-ingredients.paragraph.part2')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={() => window.open('http://help.labaroma.com/en/articles/4745433-8-compare-ingredients-tutorial', '_blank')}>
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.compare-ingredients.video-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex ml-4">
							<OpenBookIcon color='pink' className="w-48 h-48" />
						</div>
					</div>
					<div className="flex flex-row flex-wrap w-full rounded-lg px-4 py-8 bg-white mt-8 cursor-pointer" onClick={() => ingredientComparisonsStore.goToComparisonTool()}>
						<div className="grid grid-cols-4 gap-4 xl:w-3/5 justify-between ">
							<IngredientCircle color="pale-yellow" />
							<IngredientCircle color="pale-orange" />
							<IngredientCircle color="pale-red" />
							<IngredientCircle color="pale-teal" />
						</div>
						<div className="flex justify-center mt-4 xl:mt-0 xl:justify-end w-full xl:w-2/5 flex-row items-center">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.compare-ingredients.comparison-tool')}</SecondaryAppLargeTitle>
							<ArrowRightIcon className="w-6 h-6 ml-2" color={colors["nav-active-color"]} />
						</div>
					</div>
					<div className="flex flex-row justify-between items-center w-full mt-8">
						<SecondaryAppLargeTitle className="text-nav-active-color">{translate('user.compare-ingredients.comparison-reports.title')}</SecondaryAppLargeTitle>
						<div className="flex justify-end">
							<Button
								width="w-auto"
								height="h-12"
								className="px-4 rounded-lg"
								bgColor={ingredientComparisonsStore.editDeleteMode ? "transparent" : "pink-coral"}
								fgColor={ingredientComparisonsStore.editDeleteMode ? "pink-coral": "white"}
								onClick={() => ingredientComparisonsStore.toggleEditDeleteMode()}
							>
								<div className="flex flex-row items-center justify-center">
									{!ingredientComparisonsStore.editDeleteMode && 
										<>
											<div className="flex">
												<TertiaryAppSmallTitle>{translate('user.compare-ingredients.edit-delete-button')} &nbsp;</TertiaryAppSmallTitle>
											</div>
											<div className="flex"><EditIcon className="w-4 h-4" color={colors['white']} /></div>
										</>
									}
									{ingredientComparisonsStore.editDeleteMode && 
										<>
											<div className="flex">
												<TertiaryAppSmallTitle>{translate('user.compare-ingredients.cancel-button')} &nbsp;</TertiaryAppSmallTitle>
											</div>
										</>
									}
								</div>
							</Button>
						</div>
					</div>
					{ingredientComparisonsStore.currentComparisons.length == 0 && !ingredientComparisonsStore.fetching &&
						<div className="flex flex-row items-center justify-center mt-8">
							<SmallBody>{translate('user.compare-ingredients.no-reports')}</SmallBody>
						</div>
					}
					{ingredientComparisonsStore.currentComparisons.length == 0 && ingredientComparisonsStore.fetching &&
						<div className="flex flex-row items-center justify-center mt-8">
							<SmallBody>{translate('user.compare-ingredients.fetching-reports')}</SmallBody>
						</div>
					}
					<InfiniteScroll
						  dataLength={ingredientComparisonsStore.currentComparisons.length}
						  next={() => ingredientComparisonsStore.loadNextComparisonsPage()}
						  hasMore={ingredientComparisonsStore.hasMoreComparisons}
						  loader={null}
						  endMessage={null}
						>
							<div className="flex grid grid-cols-3 gap-8 mt-8">
								{ingredientComparisonsStore.currentComparisons.map((comparison) => 
									<ComparisonOverview
										t={translate}
										editDeleteMode={ingredientComparisonsStore.editDeleteMode}
										onDelete={(id) => ingredientComparisonsStore.onDeleteComparisonReport(id)}
										onEdit={(id) => ingredientComparisonsStore.onEditComparisonReport(id)}
										key={comparison.id}
										fetching={ingredientComparisonsStore.fetching}
										comparison={comparison}
									/>
								)}
							</div>
					</InfiniteScroll>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(IngredientComparison);