import React, { Component } from 'react';

class Form extends Component{
	render(){
		return <form {...this.props}>{this.props.children}</form>
	}
}

class HorizontalFormRow extends Component{
	render(){
		return <div className="flex flex-row w-full">{this.props.children}</div>
	}
}

class HorizontalFormColumn extends Component{
	render(){
		return <div className="flex flex-col w-full">{this.props.children}</div>
	}
}

class FormButtonContainer extends Component{
	render(){
		return (
			<div className="flex flex-1 mt-16 justify-center">
			{this.props.children}
			</div>
		)
	}
}

export {
	Form,
	HorizontalFormRow,
	HorizontalFormColumn,
	FormButtonContainer
}