import { observable, action, computed } from 'mobx';

import {SERVER_DATE_FORMAT} from '../../services/util';
import StatApi from '../../services/stat';
import UserApi from '../../services/user';
import MarketingAssetApi from '../../services/marketing-asset';

import moment from 'moment';

class OrganisationAdminHomeStore{

	appStore;

	statApi;
	marketingAssetApi;

	@observable userStats;
	@observable salesStats;

	@observable salesStartDate;
	@observable salesEndDate;

	@observable tempSalesStartDate;
	@observable tempSalesEndDate;

	@observable usersStartDate;
	@observable usersEndDate;

	@observable tempUsersStartDate;
	@observable tempUsersEndDate;

	@observable showStatDialog;

	@observable affiliateLinks;
	@observable marketingAssets;


	constructor(appStore){
		this.appStore = appStore;
		this.statApi = new StatApi(appStore);
		this.userApi = new UserApi(appStore);
		this.marketingAssetApi = new MarketingAssetApi(appStore);
		this.initStore();
	}

	initStore(){
		this.salesStartDate = moment().subtract(30, 'days');
		this.salesEndDate = moment();

		this.tempSalesStartDate = this.salesStartDate.clone();
		this.tempSalesEndDate = this.salesEndDate.clone();

		this.usersStartDate = moment().subtract(30, 'days');
		this.usersEndDate = moment();

		this.tempUsersStartDate = this.usersStartDate.clone();
		this.tempUsersEndDate = this.usersEndDate.clone();

		this.showStatDialog = null;
		this.userStats = {
			active_users:0,
			user_percentage_change: 0,
			new_users: 0,
			new_subscribers: 0
		};
		this.salesStats = {
			mrr: [],
			arr: [],
			mrr_percentage_change: 0,
			affiliate_commissions: 0,
			total_commission_sales: 0,
			daily_commission_sales: [],
			commission_revenue_increase: 0,
			total_labaroma_commission: 0,
			total_campaign_commission: 0,
			labaroma_commission_sales: [],
			campaign_commission_sales: []
		}
		this.affiliateLinks = [];
		this.marketingAssets = [];
	}

	fetchUserAffiliateLinks(){
		this.userApi.getAffiliateLinks()
			.then((response) => {
				this.affiliateLinks = response;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchUserStats(){
		let startDate = this.usersStartDate.format(SERVER_DATE_FORMAT);
		let endDate = this.usersEndDate.format(SERVER_DATE_FORMAT);
		this.statApi.fetchOrganisationUserStats(startDate, endDate)
			.then((response) => {
				this.userStats = response;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchSalesStats(){
		let startDate = this.salesStartDate.format(SERVER_DATE_FORMAT);
		let endDate = this.salesEndDate.format(SERVER_DATE_FORMAT);
		this.statApi.fetchOrganisationSalesStats(startDate, endDate)
			.then((response) => {
				let salesStats = response;
				salesStats.daily_commission_sales = salesStats.daily_commission_sales.map((x) => {
					x.date = moment(x.date);
					x.metric = x.total;
					return x;
				});
				salesStats.campaign_commission_sales = salesStats.campaign_commission_sales.map((x) => {
					x.created_at = moment(x.created_at);
					return x;
				});
				salesStats.labaroma_commission_sales = salesStats.labaroma_commission_sales.map((x) => {
					x.created_at = moment(x.created_at);
					return x;
				});
				this.salesStats = salesStats;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action onToggleDailyCommissions(){
		this.showStatDialog = this.showStatDialog == null ? 'daily_commissions' : null;
	}

	@action onToggleCampaignCommissions(){
		this.showStatDialog = this.showStatDialog == null ? 'campaign_commissions' : null;	
	}

	@action onToggleLabAromaCommissions(){
		this.showStatDialog = this.showStatDialog == null ? 'labaroma_commissions' : null;	
	}

	@computed get salesQueryDates(){
		return `${this.salesStartDate.format('ll')} - ${this.salesEndDate.format('ll')}`
	}


	@action changeSalesStartEndDate(startDate, endDate){
		this.tempSalesStartDate = startDate;
		this.tempSalesEndDate = endDate;
	}

	@action changeUsersStartEndDate(startDate, endDate){
		this.tempUsersStartDate = startDate;
		this.tempUsersEndDate = endDate;
	}

	@action applySalesDateChange(){
		this.salesStartDate = this.tempSalesStartDate;
		this.salesEndDate = this.tempSalesEndDate;
		this.fetchSalesStats();
	}

	@action applyUsersDateChange(){
		this.usersStartDate = this.tempUsersStartDate;
		this.usersEndDate = this.tempUsersEndDate;
		this.fetchUserStats();
	}

	@action goToMarketingAssetSearch(){
		this.appStore.goToMarketingAssetSearch();
	}

	@action fetchLatestMarketingAssets(){
		this.marketingAssetApi.searchMarketingAssets(1, null, null, 4)
			.then((response) => {
				this.marketingAssets = response.marketing_assets;
			})
			.catch((error) => {
				console.log(error);
			})
	}
	
}

export default OrganisationAdminHomeStore;