import BaseApi from '../services/base';

class BlendApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAllBlends(page, searchText=null, sortBy=null, filterBy=null, blendCategoryId=null, archived=null, showPublic=null, blendLibraryCategoryId=null, owned=null, limit=null){
		let queryUrl = `v1/blends/search?page=${page}`
		let payload = {
			page: page,
			search_text: searchText,
			sort_by: sortBy,
			filter_by: filterBy,
			blend_category_id: blendCategoryId,
			blend_library_category_id: blendLibraryCategoryId,
			archived: archived,
			public: showPublic,
			owned: owned
		}
		if(limit != null){
			payload.limit = limit;
		}

		return this.fetch(queryUrl, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getBlendComplianceStatusById(id){
		return this.fetch(`v1/blends/${id}/compliance`, {
			method: 'GET',
			auth: true
		})
	}

	getBlends = async (searchText=null, sortBy=null, filterBy=null, blendCategoryId=null, archived=null, showPublic=null) => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAllBlends(page, searchText, sortBy, filterBy, blendCategoryId, archived, showPublic);
			let data = response.blends;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}

	newBlend(payload){
		return this.fetch('v1/blends', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	updateBlend(id, payload){
		return this.fetch(`v1/blends/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	patchBlend(id, payload){
		return this.fetch(`v1/blends/${id}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	duplicateBlend(id){
		return this.fetch(`v1/blends/${id}/duplicate`, {
			method: 'POST',
			auth: true
		})
	}

	newBlendCategory(payload){
		return this.fetch('v1/blends/categories', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	patchBlendCategory(id, payload){
		return this.fetch(`v1/blends/categories/${id}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getBlendPreview(payload){
		return this.fetch('v1/blends/preview', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	deleteBlendFile(id){
		return this.fetch(`v1/blends/files/${id}`, {
			method: 'DELETE',
			auth: true
		})
	}

	getAllBlendCategories(page, sortBy=null, filterBy=null, favourited=null, limit=null, archived=false){
		let queryUrl = `v1/blends/categories/search`
		let payload = {
			page: page,
			sort_by: sortBy,
			filter_by: filterBy,
			favourited: favourited,
			archived: archived
		}

		if(limit != null){
			payload.limit = limit;
		}
		
		return this.fetch(queryUrl, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getBlendCategories = async (sortBy=null, filterBy=null, favourited=null) => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAllBlendCategories(page, sortBy, filterBy, favourited);
			let data = response.blend_categories;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}

	getBlendById(id){
		return this.fetch(`v1/blends/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	getBlendCategoryById(id){
		return this.fetch(`v1/blends/categories/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	updateBlendCategory(id, payload){
		return this.fetch(`v1/blends/categories/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(payload)
		})
	}
}

export default BlendApi;