import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import { withTranslation } from 'react-i18next';
import LeftBlockRightContent from '../common/left-block-right-content';
import { Form, HorizontalFormColumn } from '../common/form';
import {Label, Body, SecondaryWebLargerTitle} from '../common/text';
import {TextInput, TextArea} from '../common/input'
import {Button} from '../common/button';

import envelopeSwooshSrc from '../../assets/img/icons/envelope-swoosh.svg';

@inject('contactStore')
@observer
class Contact extends Component{
	constructor(props){
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.onExit = this.onExit.bind(this);
	}
	componentWillUnmount(){
		let contactStore = this.props.contactStore;
		contactStore.initStore();
	}

	onSubmit(){
		let contactStore = this.props.contactStore;
		contactStore.send();
	}

	onExit(){
		let contactStore = this.props.contactStore;
		contactStore.onExit();
	}

	render(){
		let translate = this.props.t;
		let contactStore = this.props.contactStore;

		let rightRenderView = null;
		let leftRenderView = null;
		if(!contactStore.sent){
			leftRenderView = (
				<>
						<SecondaryWebLargerTitle className="text-center text-black lg:text-left">{translate('contact.title.part1')} <br /> {translate('contact.title.part2')}</SecondaryWebLargerTitle>
						<Body className="text-center text-black lg:text-left cursor-pointer mt-8"><a href="mailto:support@labaroma.com">{translate('contact.description')}</a></Body>
					</>
			);
			rightRenderView = (
				<Form className="flex flex-1 flex-col p-8 lg:p-0 lg:w-2/3 justify-center">
					<div className="flex flex-col">
						<HorizontalFormColumn>
							<Label>{translate("contact.name.label")}</Label>
							<TextInput 
								placeholder={translate("newsletter.name.placeholder")}
								onChange={(e) => contactStore.name.onChange(e.target.value)}
								value={contactStore.name.value}
								error={contactStore.name.error}
							/>
						</HorizontalFormColumn>
						<HorizontalFormColumn>
							<div className="w-full">
								<Label>{translate("newsletter.email.label")}</Label>
								<TextInput 
									placeholder={translate("contact.email.placeholder")}
									onChange={(e) => contactStore.email.onChange(e.target.value)}
									value={contactStore.email.value}
									error={contactStore.email.error}
								/>
							</div>
						</HorizontalFormColumn>
						<HorizontalFormColumn>
							<div className="w-full">
								<Label>{translate("contact.subject.label")}</Label>
								<TextInput 
									placeholder={translate("contact.subject.placeholder")}
									onChange={(e) => contactStore.subject.onChange(e.target.value)}
									value={contactStore.subject.value}
									error={contactStore.subject.error}
								/>
							</div>
						</HorizontalFormColumn>
						<HorizontalFormColumn>
							<div className="w-full">
								<Label>{translate("contact.message.label")}</Label>
								<TextArea 
									rows={4}
									placeholder={translate("contact.message.placeholder")}
									onChange={(e) => contactStore.message.onChange(e.target.value)}
									value={contactStore.message.value}
									error={contactStore.message.error}
								/>
							</div>
						</HorizontalFormColumn>
					</div>

					<div className="flex flex-col mb-6">
						<Button
						 bgColor="black"
						 width="w-full"
						 fgColor="white"
						 fetching={contactStore.fetching}
						 disabled={!contactStore.isValidForm}
						 onClick={this.onSubmit}>{translate("contact.button")}</Button>
					</div>
				</Form>
			)
		}else{
			leftRenderView = (
				<>
						<SecondaryWebLargerTitle className="text-center text-black lg:text-left">{translate('contact.thankyou.part1')} <br /> {translate('contact.thankyou.part2')}</SecondaryWebLargerTitle>
						<Body className="text-center text-black lg:text-left mt-8">{translate('contact.soon')}</Body>
					</>
			)
			rightRenderView = (
				<div className="flex flex-1 flex-col justify-center items-center p-8 lg:p-0 lg:w-2/3">
				<div className="flex flex-1"></div>
					<div className="flex items-center">
						<img className="w-64 h-64" src={envelopeSwooshSrc} alt="Subscription received" />
					</div>

					<div className="flex flex-1 mb-12 items-center lg:items-end w-full mt-8 lg:mt-0">
						<Button
							 bgColor="black"
							 width="w-full"
							 fgColor="white"
							 onClick={this.onExit}>{translate("contact.return")}</Button>
					</div>
				</div>
			)
		}

		return (
			<LeftBlockRightContent
				leftBgColor='modal-color'
				left={leftRenderView}
				right={rightRenderView}
			/>
		)
	}
}

export default withTranslation()(Contact);