import dropIconBeigeSrc from '../../../assets/img/icons/drop-icon-beige.svg'
import dropIconBeigePngSrc from '../../../assets/img/icons/drop-icon-beige.png'

import dropIconGreenSrc from '../../../assets/img/icons/drop-icon-green.svg'
import dropIconGreenPngSrc from '../../../assets/img/icons/drop-icon-green.png'

import dropIconGreySrc from '../../../assets/img/icons/drop-icon-grey.svg'
import dropIconGreyPngSrc from '../../../assets/img/icons/drop-icon-grey.png'

import dropIconLightBlueSrc from '../../../assets/img/icons/drop-icon-light-blue.svg'
import dropIconLightBluePngSrc from '../../../assets/img/icons/drop-icon-light-blue.png'

import dropIconPinkSrc from '../../../assets/img/icons/drop-icon-pink.svg'
import dropIconPinkPngSrc from '../../../assets/img/icons/drop-icon-pink.png'

import dropIconYellowSrc from '../../../assets/img/icons/drop-icon-yellow.svg'
import dropIconYellowPngSrc from '../../../assets/img/icons/drop-icon-yellow.png'

export const getIngredientAvatarUrl = (ingredient, usePng=false) => {
	if(ingredient == null) return null;
	if(ingredient.ingredient_type == null) return null;
	let englishIngredientTypeName = ingredient.ingredient_type.english_name.toLowerCase();
	if(englishIngredientTypeName === 'carrier oils'){
		return usePng ? dropIconBeigePngSrc: dropIconBeigeSrc;
	}else if(englishIngredientTypeName === 'essential oils'){
		return usePng ? dropIconYellowPngSrc :dropIconYellowSrc;
	}else if(englishIngredientTypeName === 'extracts'){
		return usePng ? dropIconGreyPngSrc :dropIconGreySrc;
	}else if(englishIngredientTypeName === 'herbal'){
		return usePng ? dropIconGreenPngSrc:dropIconGreenSrc;
	}else if(englishIngredientTypeName === 'cosmetic'){
		return usePng ? dropIconLightBluePngSrc:dropIconLightBlueSrc;
	}else if(ingredient.favourited){
		return usePng ? dropIconPinkPngSrc:dropIconPinkSrc;
	}
}