import React, { Component } from 'react';

import {inject, observer} from 'mobx-react';

import IngredientLibrary from './ingredient-library';

@inject('ingredientsLibraryStore')
@observer
class LabAromaIngredientLibrary extends Component{
	componentDidMount(){
		let ingredientsLibraryStore = this.props.ingredientsLibraryStore;
		ingredientsLibraryStore.onLoad(true, this.props.ingredientTypeId);
	}

	componentWillUnmount(){
		let ingredientsLibraryStore = this.props.ingredientsLibraryStore;
		ingredientsLibraryStore.initStore();
	}
	
	render(){
		return <IngredientLibrary 
			ingredientsLibraryStore={this.props.ingredientsLibraryStore} />
	}
}

export default LabAromaIngredientLibrary;