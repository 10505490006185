import React, { Component } from 'react';
import {observer} from 'mobx-react';

import {Body, SmallBody, SecondaryWebLargerTitle} from '../common/text';
import {TickIcon, UnderScoreIcon, ArrowDownIcon, ArrowUpIcon} from '../common/icon';
import { Button } from '../common/button';

@observer
class PricingPlanDetail extends Component{
	constructor(props){
		super(props);
		this.onPlanSelected = this.onPlanSelected.bind(this);
		this.toggleFeatures = this.toggleFeatures.bind(this);
		this.state = {
			showFeatures: false
		}
	}

	toggleFeatures(){
		this.setState({
			showFeatures: !this.state.showFeatures
		})
	}

	onPlanSelected(id){
		this.props.signUpWithPlan(id);
	}

	render(){
		let translate = this.props.t;
		let planName = this.props.name.split(' ');
		let lastWord = planName.pop();
		return (
			<div className={`flex flex-1 w-full flex-col bg-white p-12 lg:p-24 overflow-y-scroll`}>
				<div className="flex flex-1 grid grid-cols-1 lg:grid-cols-2">
					<div className="flex flex-1 flex-col">
						<div className="flex flex-1 flex-col">
							<div className="flex w-full justify-center lg:justify-start lg:w-2/5">
								<SecondaryWebLargerTitle className="text-center lg:text-left">{planName} <br /> <span className={`text-${this.props.color}`}>{lastWord}</span></SecondaryWebLargerTitle>
							</div>
							<div className="flex w-full lg:w-3/5 mt-4 lg:mt-0">
								<Body className="bold-font text-center lg:text-left">{this.props.detail_description}</Body>
							</div>
						</div>
						<div className="flex text-left w-full mt-4">
							<SmallBody className="italic">{translate('pricing.money-back-guarantee')}</SmallBody>
						</div>
						<div className="flex flex-col items-center w-full lg:justify-between lg:items-start lg:w-2/5">
							{this.props.availablePlans.sort((p1, p2) => {
								return p2.period_value > p1.period_value ? -1 : 1;
							}).map((availablePlan) => 
								<div key={availablePlan.id} className="flex flex-col mt-8 text-center lg:text-left">
									<Body className="text-gray-500 capitalize">{availablePlan.displayPeriod} {translate('pricing.detail.name')}</Body>
									<SecondaryWebLargerTitle>{availablePlan.periodValue}<span className="secondary-web-sm-title">/{availablePlan.displayPeriod}</span></SecondaryWebLargerTitle>
									<Body className="text-gray-500 mt-1">{availablePlan.short_description}</Body>
									<div className="mt-4">
										<Button 
											width="w-full"
											bgColor={this.props.color}
											onClick={this.onPlanSelected.bind(this, availablePlan.id)}
											>{translate('pricing.detail.button.signup')} {availablePlan.period}</Button>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="border-0 lg:border-l lg:border-black pl-0 lg:pl-16 flex flex-1 flex-col mt-6 lg:mt-0">
						{this.props.largestFeatureSet.sort((f1, f2) => {
							let hasFeature1 = this.props.features.includes(f1);
							let hasFeature2 = this.props.features.includes(f2);
							if(hasFeature1 && !hasFeature2){
								return -1
							}else if(hasFeature2 && !hasFeature1){
								return 1;
							}else{
								return 0;
							}
						}).map((feature) => {
							let renderIcon = this.props.features.includes(feature) ? <TickIcon /> : <UnderScoreIcon />;
							return (
								<div className="flex flex-1 flex-row justify-between items-center mt-4">
									<Body>{feature}</Body>
									<div>{renderIcon}</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="flex flex-1 flex-col w-full mt-12">
					<div className="flex flex-row w-full items-center justify-center border border-black py-4 px-4 cursor-pointer" onClick={() => this.toggleFeatures()}>
						<Body className="bold-font">{translate("pricing.plan.features")}</Body>
						<div className="flex ml-2">
							{!this.state.showFeatures ? <ArrowDownIcon className="w-4 h-4" color="black" /> :  <ArrowUpIcon className="w-4 h-4" color="black" />}
						</div>
					</div>
					{this.state.showFeatures && 
						<ul className="mt-6">
							{this.props.detailed_features.map((feature, idx) => 
								<li className="list-disc ml-4" key={idx}>{feature}</li>
							)}
						</ul>
					}
				</div>
			</div>
		)
	}
}

export default PricingPlanDetail;