import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {Body, SmallBody, SecondaryAppLargeTitle, SecondaryAppMediumTitle, SecondaryAppSmallTitle, ExtraSmallBody, TertiaryAppSmallTitle} from './text'
import {ArrowRightIcon, DownloadIcon, DrawPinkIcon, RaisedHandIcon} from './icon'

@observer
class CampaignBreakdown extends Component{
	render(){
		let campaign = this.props.campaign;
		let translate = this.props.t;
		return (
			<div className={`flex mr-8 flex-col w-64 h-auto px-8 rounded-lg py-8 bg-${this.props.bgColor}`}>
	 			<div className="flex flex-col">
					<SecondaryAppSmallTitle className="text-white">{campaign.campaign}</SecondaryAppSmallTitle>
					<ExtraSmallBody className="text-stat-label">{translate('org-admin.home.sales-stats.campaign.created-at')} {campaign.created_at?.format('ddd DD MMM YYYY')}</ExtraSmallBody>
				</div>
				<div className="flex flex-col mt-16">
					<ExtraSmallBody className="text-stat-label">{translate('org-admin.home.sales-stats.campaign.commission-earned')}</ExtraSmallBody>
					<div className="flex w-full items-center mt-4 justify-center text-center">
						<SecondaryAppSmallTitle className="text-white">£{campaign.total_commission}</SecondaryAppSmallTitle>
					</div>
				</div>
				<div className="flex flex-col mt-16">
					{campaign.plan_breakdown.length > 0 && 
						<>
							<ExtraSmallBody className="text-stat-label">{translate('org-admin.home.sales-stats.campaign.plans-sold')}</ExtraSmallBody>
							{campaign.plan_breakdown.map((planBreakdownEntry) => 
								<div className="flex mt-2 flex-row w-full justify-between">
									<ExtraSmallBody className="text-white">{planBreakdownEntry.plan}</ExtraSmallBody>
									<ExtraSmallBody className="text-white">{planBreakdownEntry.plans_sold} (£{planBreakdownEntry.total_commission})</ExtraSmallBody>
								</div>
							)}
						</>
					}
				</div>
	 		</div>
		)
	}
}

export default CampaignBreakdown;