import { observable, action, computed } from 'mobx';

import {SERVER_DATE_FORMAT} from '../../services/util';
import StatApi from '../../services/stat';
import UserApi from '../../services/user';
import NotificationApi from '../../services/notification';
import MarketingAssetApi from '../../services/marketing-asset';

import moment from 'moment';

class SuperAdminHomeStore{

	appStore;

	statApi;
	userApi;
	notificationApi;
	marketingAssetApi;

	@observable userStats;
	@observable organisationStats;
	@observable salesStats;

	@observable salesStartDate;
	@observable salesEndDate;

	@observable tempSalesStartDate;
	@observable tempSalesEndDate;

	@observable usersStartDate;
	@observable usersEndDate;

	@observable tempUsersStartDate;
	@observable tempUsersEndDate;

	@observable organisationsStartDate;
	@observable organisationsEndDate;

	@observable tempOrganisationsStartDate;
	@observable tempOrganisationsEndDate;

	@observable mrrGeographyBreakdown;

	@observable showStatDialog;

	@observable affiliateLinks;

	@observable notifications;
	@observable totalNewNotifications;
	@observable totalNotifications;
	@observable notificationsPage;

	@observable marketingAssets;


	constructor(appStore){
		this.appStore = appStore;
		this.statApi = new StatApi(appStore);
		this.userApi = new UserApi(appStore);
		this.notificationApi = new NotificationApi(appStore);
		this.marketingAssetApi = new MarketingAssetApi(appStore);
		this.initStore();
	}

	initStore(){
		this.salesStartDate = moment().subtract(30, 'days');
		this.salesEndDate = moment();

		this.tempSalesStartDate = this.salesStartDate.clone();
		this.tempSalesEndDate = this.salesEndDate.clone();

		this.usersStartDate = moment().subtract(30, 'days');
		this.usersEndDate = moment();

		this.tempUsersStartDate = this.usersStartDate.clone();
		this.tempUsersEndDate = this.usersEndDate.clone();

		this.organisationsStartDate = moment().subtract(30, 'days');
		this.organisationsEndDate = moment();

		this.tempOrganisationsStartDate = this.organisationsStartDate.clone();
		this.tempOrganisationsEndDate = this.organisationsEndDate.clone();

		this.userStats = {
			active_users:0,
			user_percentage_change: 0,
			new_users: 0,
			new_subscribers: 0
		};
		this.organisationStats = {
			active_organisations:0,
			organisation_percentage_change: 0,
			new_organisations: 0
		}
		this.salesStats = {
			mrr: [],
			arr: [],
			mrr_percentage_change: 0,
			total_affiliate_commissions: 0,
			affiliate_commission_sales: []
		}
		this.mrrGeographyBreakdown = [];
		this.showStatDialog = null;
		this.affiliateLinks = [];
		this.notifications = [];
		this.notificationsPage = 1;
		this.totalNewNotifications = null;
		this.totalNotifications = null;
		this.marketingAssets = [];
	}

	fetchUserAffiliateLinks(){
		this.userApi.getAffiliateLinks()
			.then((response) => {
				this.affiliateLinks = response;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchUserStats(){
		let startDate = this.usersStartDate.format(SERVER_DATE_FORMAT);
		let endDate = this.usersEndDate.format(SERVER_DATE_FORMAT);
		this.statApi.fetchUserStats(startDate, endDate)
			.then((response) => {
				this.userStats = response;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchLatestNotifications(){
		this.notificationApi.getPagedNotifications(this.notificationsPage, null)
			.then((response) => {
				this.notifications = response.notifications;
				this.totalNewNotifications = response.total_new;
				this.totalNotifications = response.total;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {

			})
	}

	fetchOrganisationStats(){
		let startDate = this.organisationsStartDate.format(SERVER_DATE_FORMAT);
		let endDate = this.organisationsEndDate.format(SERVER_DATE_FORMAT);
		this.statApi.fetchOrganisationStats(startDate, endDate)
			.then((response) => {
				this.organisationStats = response;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchSalesStats(){
		let startDate = this.salesStartDate.format(SERVER_DATE_FORMAT);
		let endDate = this.salesEndDate.format(SERVER_DATE_FORMAT);
		this.statApi.fetchSalesStats(startDate, endDate)
			.then((response) => {
				let data = response;
				data.mrr = data.mrr.map((x) => {
					x.date = moment(x.date)
					return x;
				});
				data.arr = data.arr.map((x) => {
					x.date = moment(x.date)
					return x;
				})
				data.affiliate_commission_sales = data.affiliate_commission_sales.map((x) => {
					x.created_at = moment(x.created_at);
					return x;
				});
				this.salesStats = data;

			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchMRRGeographyBreakdown(){
		this.statApi.fetchMRRGeographyBreakdown()
			.then((response) => {
				this.mrrGeographyBreakdown = Object.entries(response.countries_breakdown).map((entry) => {
					return {
						country: entry[0],
						metric: entry[1].metric,
						percentage: entry[1].percentage
					}
				});
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action changeSalesStartEndDate(startDate, endDate){
		this.tempSalesStartDate = startDate;
		this.tempSalesEndDate = endDate;
	}

	@action changeUsersStartEndDate(startDate, endDate){
		this.tempUsersStartDate = startDate;
		this.tempUsersEndDate = endDate;
	}

	@action changeOrganisationsStartEndDate(startDate, endDate){
		this.tempOrganisationsStartDate = startDate;
		this.tempOrganisationsEndDate = endDate;
	}

	@action applySalesDateChange(){
		this.salesStartDate = this.tempSalesStartDate;
		this.salesEndDate = this.tempSalesEndDate;
		this.fetchSalesStats();
	}

	@action applyOrganisationsDateChange(){
		this.organisationsStartDate = this.tempOrganisationsStartDate;
		this.organisationsEndDate = this.tempOrganisationsEndDate;
		this.fetchOrganisationStats();
	}

	@action applyUsersDateChange(){
		this.usersStartDate = this.tempUsersStartDate;
		this.usersEndDate = this.tempUsersEndDate;
		this.fetchUserStats();
	}

	@computed get salesQueryDates(){
		return `${this.salesStartDate.format('ll')} - ${this.salesEndDate.format('ll')}`
	}

	@action onToggleAffiliateCampaign(){
		this.showStatDialog = this.showStatDialog == null ? 'affiliate-campaign' : null;
	}

	@action onToggleMRR(){
		this.showStatDialog = this.showStatDialog == null ? 'mrr' : null;
	}

	@action onToggleARR(){
		this.showStatDialog = this.showStatDialog == null ? 'arr' : null;
	}

	@computed get sortedNotifications(){
		return this.notifications.sort((n1, n2) => {
			if(n1.is_open && !n2.is_open) return -1;
			if(n2.is_open && !n1.is_open) return 1;
			return n1.created_on > n2.created_on ?  -1 : 1
		})
	}

	@action goToMarketingAssetSearch(){
		this.appStore.goToMarketingAssetSearch();
	}

	@action fetchLatestMarketingAssets(){
		this.marketingAssetApi.searchMarketingAssets(1, null, null, 4)
			.then((response) => {
				this.marketingAssets = response.marketing_assets;
			})
			.catch((error) => {
				console.log(error);
			})
	}
}

export default SuperAdminHomeStore;