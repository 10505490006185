import BaseApi from '../services/base';

class PlanApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(){
		return this.fetch('v1/plans', {
			method: 'GET'
		})
	}

	getSpecificPlans(currencyId, period){
		return this.fetch(`v1/plans?currency_id=${currencyId}&period=${period}`, {
			method: 'GET'
		})
	}
}

export default PlanApi;