import runtimeEnv from '@mars/heroku-js-runtime-env';

function timeout(ms, promise) {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      reject(new Error("timeout"))
    }, ms)
    promise.then(resolve, reject)  })
}


export function apiFetch(url, options, appStore) {
    let baseHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': appStore.acceptLanguageHeader
    };

    if(options === undefined){
        options = {};
    }
    let token = localStorage.getItem(appStore.ACCESS_TOKEN_KEY);
    if('auth' in options && token !== null){
         baseHeaders['Authorization'] = `Bearer ${token}`;
    }

    let apiOptions = Object.assign({}, options, {
        mode: 'cors',
        headers: baseHeaders
    });

    let silent = 'silent' in options ? options.silent : false;
    if(!silent){
        appStore.startFetch();
    }

    const env = runtimeEnv();
    let apiUri = `${env.REACT_APP_API_URL}/${url}`;
    return timeout(30 * 1000, fetch(apiUri, apiOptions))
            .then((response) => {
                return handleResponse(response, appStore);
            })
            .catch((error) => {
                // Timeout error or something to that effect
                appStore.completeFetch();
                throw new Error('An error has occurred.');
            });
}

function handleResponse(response, appStore){
    appStore.completeFetch();
    if (response.ok) {
        appStore.resetErrors();
        return Promise.resolve(response.json());
    } else {
        if(response.status === 401){
            appStore.logout();
        }

        return response.json().then((json) => {
            let errorMsg = 'message' in json ? json.message : appStore.i18n.t('common.error');
            appStore.alertError(errorMsg);
            return Promise.reject(new Error(errorMsg));
        });
    }
}