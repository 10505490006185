import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import { UploadIcon} from './icon';
import {TertiaryAppSmallTitle, Body, ExtraSmallBody} from './text'
const IMAGE_FILE_TYPES = ['image/jpeg', 'image/png'];

const colors = require('../../color.js');

@inject('appStore')
@observer
class UploadImage extends Component{
	constructor(props){
		super(props);
		this.onClickPhoto = this.onClickPhoto.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);
		this.onRemoveFile = this.onRemoveFile.bind(this);
		this.fileUploadRef = React.createRef();
	}

	onClickPhoto(){
		this.fileUploadRef.current.click();
	}

	onChangeFile(e){
		let translate = this.props.t;
		let files = [...e.target.files];
		let validFiles = files.filter((f) => IMAGE_FILE_TYPES.includes(f.type));
		if(validFiles.length == 0){
			this.props.appStore.alertError(translate('error.valid-image-format-message'));
		}else if(validFiles.length > 1){
			this.props.appStore.alertError(translate('error.one-file-only-message'));
		}else{
			let currentFile = validFiles[0];
			var sizeInMB = (currentFile.size / (1024*1024)).toFixed(2);
			let maxFileSize = 'maxFileSize' in this.props ? this.props.maxFileSize : 2;
			if(sizeInMB > maxFileSize){
				let exceededMaxFileSizeMessage = `${translate('error.max-file-size')} ${maxFileSize}MB`;
				this.props.appStore.alertError(exceededMaxFileSizeMessage);
			}else{
				this.props.onFileChange(currentFile);
			}
		}
	}

	onRemoveFile(e){
		e.stopPropagation();
		this.props.onDeleteFile();
	}


	render(){
		let translate = this.props.t;

		let hasImage = this.props.imageFileUrl != null;
		return (
			<div className="flex w-64 h-64 overflow-hidden border border-black rounded-lg mt-4" onClick={this.onClickPhoto}>
				<>
					<input type="file" 
						ref={this.fileUploadRef} 
						onChange={this.onChangeFile} 
						style={{ display: 'none' }} 
					/>
				</>
				{!hasImage && 
					<div className="flex flex-1 flex-col text-center items-center justify-center ">
						<div className="flex">
							<UploadIcon className="h-12" color={colors['list-text']} />
						</div>
						<div className="flex mt-4">
							<TertiaryAppSmallTitle className="text-nav-active-color">{translate('common.upload-image.title')}</TertiaryAppSmallTitle>
						</div>
						<div className="flex mt-2">
							<ExtraSmallBody className="text-black">{this.props.description}</ExtraSmallBody>
						</div>
						
					</div>
				}
				{hasImage && 
						<div className="flex flex-1 relative">
							<img src={this.props.imageFileUrl} alt="Molecular Image" style={{
								height: '100%',
								width:'100%',
								imageOrientation: 'from-image'
							}} />
							<div className='absolute bottom-0 left-0 h-12 flex w-full bg-tooltip-bg'>
								<div className="flex flex-1 items-center justify-center cursor-pointer" onClick={this.onRemoveFile}>
									<TertiaryAppSmallTitle className="text-white">{translate('common.delete-photo')}</TertiaryAppSmallTitle>
								</div>
							</div>
						</div>
				}
			</div>
		)
	}
}

export default withTranslation()(UploadImage);