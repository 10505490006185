import { observable, action, computed } from 'mobx';
import { FieldState, FormState } from 'formstate';
import { isRequiredValidator } from '../../../services/validation';
import CommonApi from '../../../services/common';

class RegulationsStore{

	@observable regions;

	@observable showEditTooltip;

	@observable addMode;
	@observable regionName;
	@observable fetching;

	@observable regionForm;

	commonApi;

	constructor(appStore){
		this.appStore = appStore;
		this.commonApi = new CommonApi(this.appStore);
		this.initStore();
	}

	initStore(){
		this.regions = [];
		this.showEditTooltip = null;
		this.addMode = false;
		this.regionName = new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n));
		this.regionForm = new FormState({
			regionName: this.regionName
		})
		this.fetching = false;
	}

	fetchRegions(){
		this.commonApi.getRegions()
			.then((response) => {
				this.regions = response.regions;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	toggleAddMode(){
		this.addMode = !this.addMode;
	}

	onEditRegulationRegion(regionId){
		this.appStore.goToEditRegulations(regionId);
	}

	@action setEditTooltip(id){
		this.showEditTooltip = id;
	}

	@computed get validForm(){
		return this.regionName.value != null && this.regionName.value.length > 0;
	}

	saveNewRegion = async () => {
		let res = await this.regionForm.validate();
		if(res.hasError) return;

		let payload = {
			name: this.regionName.value
		}
		this.fetching = true;
		this.commonApi.newRegion(payload)
			.then((response) => {
				this.regions.push(response.region);
				this.appStore.displaySaveDialog();
				this.regionName.value = null;
				this.addMode = false;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}
}

export default RegulationsStore;