import React, {Component} from 'react';

import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';
import Main from '../../elements/main';
import { SmallBody, Body, SecondaryAppLargeTitle, TertiaryAppSmallTitle, SecondaryAppMediumTitle} from '../../common/text';
import { TestTubeIcon, ArrowRightIcon, PlusIcon, ExitIcon, SaveIcon, EditIcon} from '../../common/icon'
import {Button} from '../../common/button';
import {SortDialog, FilterField, FilterDialog, SortingField} from '../../elements/list';
import {FullGrid} from '../../elements/grid';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormTextArea,
	FormSearchSelect
} from '../../elements/form';
import ColorPicker from '../../common/color-picker';
import UploadFiles from '../../common/upload-files';
import {AddTag} from '../../common/tag';
const colors = require('../../../color.js');

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR } from '../../../services/util';
import { withAuthorisation } from '../../common/authorisation';

@inject('manageLibraryCategoryStore')
@observer
class ManageLibraryCategory extends Component{
	componentDidMount(){
		let manageLibraryCategoryStore = this.props.manageLibraryCategoryStore;
		if('libraryCategoryId' in this.props){
			manageLibraryCategoryStore.formSync(this.props.libraryCategoryId);
		}
	}

	componentWillUnmount(){
		let manageLibraryCategoryStore = this.props.manageLibraryCategoryStore;
		manageLibraryCategoryStore.initStore();
	}
	
	render(){
		let translate = this.props.t;
		let manageLibraryCategoryStore = this.props.manageLibraryCategoryStore;

		let editMode = manageLibraryCategoryStore.editMode;

		let managingMode = manageLibraryCategoryStore.addMode || manageLibraryCategoryStore.editMode;

		let title = translate('org-admin.manage-library-categories.new-tagline');
		if(editMode || manageLibraryCategoryStore.viewMode){
			title = translate('org-admin.manage-library-categories.edit-tagline');
		}

		let currentLibraryCategory = manageLibraryCategoryStore.currentLibraryCategory;

		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{title}</SecondaryAppLargeTitle>
							<SmallBody>{translate('org-admin.manage-library-categories.new-paragraph.part1')} <br /> {translate('org-admin.manage-library-categories.new-paragraph.part2')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8">
								<div className="flex flex-row">
									<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('org-admin.manage-library-categories.library-category-tutorial')} </TertiaryAppSmallTitle></div>
									<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-1 flex-row justify-end">
						{manageLibraryCategoryStore.viewMode && 
							<>
								<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									fetching={manageLibraryCategoryStore.fetching}
									onClick={() => manageLibraryCategoryStore.toggleEditMode()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('org-admin.manage-library-categories.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</>
						}
						{manageLibraryCategoryStore.editMode && 
								<Button
									width="w-auto"
									height="h-12"
									className="ml-4 px-4 rounded-lg text-pink-coral"
									bgColor="bg-list-text"
									fetching={manageLibraryCategoryStore.fetching}
									onClick={() => manageLibraryCategoryStore.cancelChanges()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('org-admin.manage-library-categories.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
									</div>
								</Button>
						}
						{managingMode && 
							<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									fetching={manageLibraryCategoryStore.fetching}
									onClick={() => manageLibraryCategoryStore.save()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('org-admin.manage-library-categories.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
						}
					</div>
					
					{managingMode &&
						<>
							<FullGrid className="mt-8">
								<div className="flex flex-row">
									<SecondaryAppMediumTitle>{translate('org-admin.manage-library-categories.new-category-title')}</SecondaryAppMediumTitle>
								</div>
								<div className="flex grid grid-cols-2 gap-8">
									<div className="flex flex-1 flex-row items-center grid grid-cols-2 gap-4">
										<div className="flex self-start">
											<DataRow label={translate('org-admin.manage-library-categories.library-category.label')}>
												{managingMode &&
													<FormInput 
														placeholder={translate('org-admin.manage-library-categories.library-category.placeholder')}
														value={manageLibraryCategoryStore.libraryCategoryData.name.value}
														error={manageLibraryCategoryStore.libraryCategoryData.name.error}
														onChange={(e) => manageLibraryCategoryStore.libraryCategoryData.name.onChange(e.target.value)}
													/>
												}
											</DataRow>
										</div>
										<div className="flex self-start">
											<DataRow label={translate('org-admin.manage-library-categories.hex-color.label')}>
												{managingMode &&
													<div className="flex flex-row items-center -mt-2">
														<ColorPicker
															hexColor={manageLibraryCategoryStore.libraryCategoryData.hexColor}
															onChange={(val) => manageLibraryCategoryStore.libraryCategoryData.hexColor.onChange(val)}
														/>
													</div>
												}
											</DataRow>
										</div>
										<div className="col-span-2">
											<DataRow label={translate('org-admin.manage-library-categories.bio.label')}>
												<FormTextArea 
													placeholder={translate('org-admin.manage-library-categories.bio.placeholder')}
													value={manageLibraryCategoryStore.libraryCategoryData.bio.value}
													error={manageLibraryCategoryStore.libraryCategoryData.bio.error}
													onChange={(e) => manageLibraryCategoryStore.libraryCategoryData.bio.onChange(e.target.value)}
												/>
											</DataRow>
										</div>
										<div className="col-span-2">
											<div className="flex self-start">
												<DataRow label={translate('org-admin.manage-library-categories.blends.label')}>
													{managingMode &&
														<div className="flex w-full flex-row items-center">
															<FormSearchSelect
																placeholder={translate('org-admin.manage-library-categories.blends.placeholder')}
																value={manageLibraryCategoryStore.libraryCategoryData.blendSearchText.value}
																hasSelection={manageLibraryCategoryStore.libraryCategoryData.selectedBlend.value != null}
																onChange={(val) => manageLibraryCategoryStore.onChangeBlendSearchText(val)}
																onClear={() => manageLibraryCategoryStore.clearBlendSelection()}
																options={manageLibraryCategoryStore.blendOptions}
																onSelection={(id) => manageLibraryCategoryStore.onChooseBlend(id)}
															 />
														</div>
													}
												</DataRow>
											</div>
											{manageLibraryCategoryStore.libraryCategoryData.addedBlends.length  > 0 && 
												<div className="flex self-start">
													<DataRow label={translate('org-admin.manage-library-categories.blends-added.label')}>
														{managingMode &&
															<div className="grid grid-cols-2 gap-4 mt-2">
																{manageLibraryCategoryStore.libraryCategoryData.addedBlends.map((blend) => 
																	<AddTag 
																			id={blend.id}
																			key={blend.id}
																			text={blend.name} 
																			onDelete={(id) => manageLibraryCategoryStore.deleteAddedBlend(id)}
																		/>
																)}
															</div>
														}
													</DataRow>
												</div>
											}
										</div>
										<div className="flex flex-row mt-8" onClick={() => manageLibraryCategoryStore.addNewBlend()}>
											<div className="flex"><PlusIcon className="w-8 h-8" color="black" /></div>
											<div className="flex cursor-pointer">
												<TertiaryAppSmallTitle>{translate('org-admin.manage-library-categories.new-blend-button')}</TertiaryAppSmallTitle>
											</div>
										</div>
									</div>
									<div className="grid grid-cols-1 gap-4">
										<div className="flex self-start">
											<DataRow label={translate('org-admin.manage-library-categories.files.label')}>
												<div className="flex flex-1 flex-col">
													<div className="mt-2">
														<SmallBody className="text-nav-inactive-color">{translate('org-admin.manage-library-categories.files.description.part1')} <br /> {translate('org-admin.manage-library-categories.files.description.part2')}</SmallBody> 
														<SmallBody>
															<a href="https://labaroma-production.s3-eu-west-1.amazonaws.com/csv-files/example.csv" download>{translate('org-admin.manage-library-categories.download-example-csv-file-description')}</a>
														</SmallBody>
													</div>
													<UploadFiles
														t={translate} 
														uploading={manageLibraryCategoryStore.uploading}
														uploadFileTypes={['text/csv']}
														validFileFormatMessage={translate('error.valid-csv-file-format-message')}
														maxFileSize={2}
														description={
															(
																<>
																	{translate('common.upload-csv-files.valid-files.part1')} <br /> {translate('common.upload-csv-files.valid-files.part2')} 
																</>
															)
														}
														onChangeFiles={(files) => manageLibraryCategoryStore.onReadCSVFiles(files)}
														onDeleteFile={() => console.log('delete')}
													 />
												</div>
											</DataRow>
										</div>
									</div>
								</div>
							</FullGrid>
							<FullGrid className="mt-8">
								<div className="flex flex-row">
									<SecondaryAppMediumTitle>{translate('org-admin.manage-library-categories.add-users-title')}</SecondaryAppMediumTitle>
								</div>
								<div className="flex mt-4 grid grid-cols-2 gap-8">
									<div className="flex self-start">
										<DataRow label={translate('org-admin.manage-library-categories.users.label')}>
											{managingMode &&
												<div className="flex w-full flex-row items-center">
													<FormInput 
														placeholder={translate('org-admin.manage-library-categories.users.placeholder')}
														value={manageLibraryCategoryStore.libraryCategoryData.currentUserEmail.value}
														error={manageLibraryCategoryStore.libraryCategoryData.currentUserEmail.error}
														onChange={(e) => manageLibraryCategoryStore.libraryCategoryData.currentUserEmail.onChange(e.target.value)}
													/>
												</div>
											}
										</DataRow>
									</div>
									<div className="flex self-start">
										{manageLibraryCategoryStore.libraryCategoryData.addedUserEmails.length > 0 &&
											<DataRow label={translate('org-admin.manage-library-categories.users-added.label')}>
												<div className="grid grid-cols-2 gap-4 mt-2">
													{manageLibraryCategoryStore.libraryCategoryData.addedUserEmails.map((userEmail) => 
														<AddTag 
															id={userEmail}
															key={userEmail}
															text={userEmail} 
															onDelete={(id) => manageLibraryCategoryStore.deleteAddedUserByEmail(id)}
														/>
													)}
												</div>
											</DataRow>
										}
									</div>
									<div className="flex flex-row mt-8" onClick={() => manageLibraryCategoryStore.addNewUserEmail()}>
										<div className="flex"><PlusIcon className="w-8 h-8" color="black" /></div>
										<div className="flex cursor-pointer">
											<TertiaryAppSmallTitle>{translate('org-admin.manage-library-categories.users.label')}</TertiaryAppSmallTitle>
										</div>
									</div>
								</div>
							</FullGrid>
						</>
					}
					{manageLibraryCategoryStore.viewMode && 
						<>
							<FullGrid className="mt-8">
								<div className="flex flex-row">
									<SecondaryAppMediumTitle>{translate('org-admin.manage-library-categories.new-category-title')}</SecondaryAppMediumTitle>
								</div>
								<div className="flex grid grid-cols-2 gap-8">
									<div className="flex flex-1 flex-row items-center grid grid-cols-2 gap-4">
										<div className="flex flex-1 flex-col self-start">
											<DataHeader>{translate('org-admin.manage-library-categories.library-category.label')}</DataHeader>
											<DataEntry>{manageLibraryCategoryStore.currentLibraryCategory?.name}</DataEntry>
										</div>
										<div className="flex flex-1 flex-row items-center self-start">
											<div className="flex flex-1 flex-col">
												<DataHeader>{translate('org-admin.manage-library-categories.hex-color.label')}</DataHeader>
												<DataEntry>{manageLibraryCategoryStore.currentLibraryCategory?.hex_color}</DataEntry>
											</div>
											<div className="ml-4 w-12 h-8 rounded-md" style={{ backgroundColor: manageLibraryCategoryStore.currentLibraryCategory?.hex_color}}></div>
										</div>
										<div className="col-span-2">
											<div className="flex flex-col">
												<DataHeader>{translate('org-admin.manage-library-categories.bio.label')}</DataHeader>
												<DataEntry>{manageLibraryCategoryStore.currentLibraryCategory?.bio}</DataEntry>
											</div>
										</div>
										<div className="col-span-2">
											<div className="flex flex-col">
												<DataHeader>{translate('org-admin.manage-library-categories.blends.label')}</DataHeader>
												<div className="flex flex-col overflow-scroll w-full h-64">
													{manageLibraryCategoryStore.currentLibraryCategory?.blends.map((blend) => 
														<DataEntry>{blend.name}</DataEntry>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="flex flex-1 flex-row items-center self-start">
										<div className="flex flex-1 flex-col">
											<DataHeader>{translate('org-admin.manage-library-categories.users-added.label')}</DataHeader>
											<div className="flex flex-col overflow-scroll w-full h-64">
												{manageLibraryCategoryStore.currentLibraryCategory?.user_emails.map((userEmail) => 
													<div className="flex flex-row justify-between items-center">
														<DataEntry>{userEmail.user_email}</DataEntry>
														<DataEntry>{userEmail.added_on.format('DD/MM/YYYY')}</DataEntry>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</FullGrid>
						</>
					}
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(ManageLibraryCategory, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR]));
