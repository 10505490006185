import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import { UploadIcon} from './icon';
import {TertiaryAppSmallTitle, Body, ExtraSmallBody} from './text'
import {UPLOAD_FILE_TYPES} from '../../services/util';

const colors = require('../../color.js');

@inject('appStore')
@observer
class UploadFiles extends Component{
	constructor(props){
		super(props);
		this.onClickPhoto = this.onClickPhoto.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);
		this.onRemoveFile = this.onRemoveFile.bind(this);
		this.fileUploadRef = React.createRef();
	}

	onClickPhoto(){
		this.fileUploadRef.current.click();
	}

	onChangeFile(e){
		let translate = this.props.t;
		let uploadFileTypes = 'uploadFileTypes' in this.props ? this.props.uploadFileTypes : UPLOAD_FILE_TYPES;
		let files = [...e.target.files];
		let validFiles = files.filter((f) => uploadFileTypes.includes(f.type));
		if(validFiles.length == 0){
			let validFileFormatMessage = 'validFileFormatMessage' in this.props ? this.props.validFileFormatMessage : translate('error.valid-file-format-message')
			this.props.appStore.alertError(validFileFormatMessage);
		}else{
			let allUnderMaxFileSize = true;
			let maxFileSize = 'maxFileSize' in this.props ? this.props.maxFileSize : 2;
			for(let currentFile of validFiles){
				var sizeInMB = (currentFile.size / (1024*1024)).toFixed(2);
				if(sizeInMB > maxFileSize){
					allUnderMaxFileSize = false;
				}
			}

			if(!allUnderMaxFileSize){
				let exceededMaxFileSizeMessage = `${translate('error.max-file-size')} ${maxFileSize}MB`;
				this.props.appStore.alertError(exceededMaxFileSizeMessage);
			}else{
				this.props.onChangeFiles(validFiles);
			}
		}
	}

	onRemoveFile(e){
		e.stopPropagation();
		this.props.onDeleteFile();
	}


	render(){
		let translate = this.props.t;
		let uploading = 'uploading' in this.props ? this.props.uploading : false;

		let title = 'title' in this.props ? this.props.title : translate('common.upload-files.title');
		return (
			<div className="flex w-full h-48 overflow-hidden border border-black rounded-lg mt-4" onClick={this.onClickPhoto}>
				<>
					<input type="file" 
						ref={this.fileUploadRef} 
						onChange={this.onChangeFile} 
						style={{ display: 'none' }} 
					/>
				</>
				<div className="flex flex-1 flex-col text-center items-center justify-center ">
					<div className="flex">
						<UploadIcon className="h-12" color={colors['list-text']} />
					</div>
					<div className="flex mt-4">
						<TertiaryAppSmallTitle className="text-nav-active-color">{uploading ? translate('common.uploading') : title}</TertiaryAppSmallTitle>
					</div>
					{!uploading && 
						<div className="flex mt-2">
							<ExtraSmallBody className="text-black">{this.props.description}</ExtraSmallBody>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default withTranslation()(UploadFiles);