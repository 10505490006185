import BaseApi from '../services/base';

class TermsApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getTermsSnippet(){
		return this.fetch('v1/snippet/terms-and-conditions', {
			method: 'GET'
		})
	}
}

export default TermsApi;