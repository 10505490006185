import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {observer} from 'mobx-react';

import { ChromePicker } from 'react-color';
import { DropperIcon } from './icon';
import {
	FormInput,
} from '../elements/form';

@observer
class ColorPicker extends Component{
	constructor(props){
		super(props);
		this.togglePicker = this.togglePicker.bind(this);
		this.closePicker = this.closePicker.bind(this);
		this.onColorChange = this.onColorChange.bind(this);
		this.state = {
			showPicker: false
		}
	}

	togglePicker(){
		this.setState({
			showPicker: !this.state.showPicker
		})
	}

	closePicker(){
		this.setState({
			showPicker: false
		})
	}

	onColorChange(color, event){
		this.props.onChange(color.hex);
	}

	render(){
		let translate = this.props.t;

		const popover = {
	      position: 'absolute',
	      zIndex: '2',
	    }
	    const cover = {
	      position: 'fixed',
	      top: '0px',
	      right: '0px',
	      bottom: '0px',
	      left: '0px',
	    }

		return (
			<div className="flex flex-1 flex-row mt-2">
				<div className="flex flex-1">
					<FormInput 
						value={this.props.hexColor?.value}
						error={this.props.hexColor?.error}
						onChange={(e) => this.props.onChange(e.target.value)}
						placeholder={translate('superadmin.manage-chemical-family.hex-colour.placeholder')}
					/>
				</div>
				<div className="flex flex-1 flex-row mt-2 ml-4">
					<div className="flex flex-1 h-8">
						<div className="flex flex-1 rounded-lg" style={{
							backgroundColor: this.props.hexColor?.value
						}}></div>
						{this.state.showPicker && 
							<div style={ popover }>
								<div style={ cover } onClick={ this.closePicker }/>
								<ChromePicker color={this.props.hexColor?.value} onChangeComplete={this.onColorChange} />
							</div>
						}
					</div>
					<div className="flex flex-1 h-8 justify-center items-center ml-2 rounded-lg bg-tooltip-bg" onClick={this.togglePicker}>
						<DropperIcon className="w-4 h-4" fill="white" color="white" />
					</div>
				</div>
			</div>
		)
	}
}


export default withTranslation()(ColorPicker);