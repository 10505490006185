import { apiFetch } from '../services/fetch';

class BaseApi{
	constructor(appStore){
		this.appStore = appStore;
		this.fetch = this.fetchProxy;
	}

	fetchProxy(url, options){
		return apiFetch(url, options, this.appStore);
	}
}

export default BaseApi;