import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import Main from '../../elements/main';
import {FullGrid} from '../../elements/grid';

import {Button} from '../../common/button';
import {SaveIcon, PlusIcon, DeleteIcon, EditIcon} from '../../common/icon';

import { withAuthorisation } from '../../common/authorisation';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, COSMETIC_REGULATIONS } from '../../../services/util';

import {SecondaryAppLargeTitle, SmallBody, SecondaryAppMediumTitle, TertiaryAppSmallTitle, Body, ExtraSmallBody} from '../../common/text'
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormSearchSelect,
	FormInput,
	FormTextArea,
	FormSelect
} from '../../elements/form';

import UploadFiles from '../../common/upload-files';
import {TagInput, Tag} from '../../common/tag';

const colors = require('../../../color.js');


@inject('manageMarketingAssetStore')
@observer
class ManageMarketingAsset extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let manageMarketingAssetStore = this.props.manageMarketingAssetStore;
		if('marketingAssetId' in this.props){
			manageMarketingAssetStore.formSync(this.props.marketingAssetId);
		}
		manageMarketingAssetStore.fetchLanguages();
	}

	componentWillUnmount(){
		let manageMarketingAssetStore = this.props.manageMarketingAssetStore;
		manageMarketingAssetStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let manageMarketingAssetStore = this.props.manageMarketingAssetStore;
		return (
			<Main>
				<div className="flex flex-1 flex-col"> 
					<div className="flex flex-row mt-8 items-center justify-between">
						<div className="flex">
							<SecondaryAppLargeTitle className="text-nav-active-color">{manageMarketingAssetStore.editMode ?  translate('superadmin.manage-marketing-asset.edit-title') : translate('superadmin.manage-marketing-asset.new-title')}</SecondaryAppLargeTitle>
						</div>
						<div className="flex justify-end">
							<Button
								width="w-auto"
								height="h-12"
								className="rounded-lg"
								bgColor="pink-coral"
								fetching={manageMarketingAssetStore.fetching}
								disabled={!manageMarketingAssetStore.validForm}
								onClick={() => manageMarketingAssetStore.saveMarketingAsset()}
							>
								<div className="flex flex-row items-center justify-center mx-4">
									<div className="flex"><TertiaryAppSmallTitle>{manageMarketingAssetStore.editMode ? translate('superadmin.manage-marketing-asset.update-button') : translate('superadmin.manage-marketing-asset.save-new-button')} &nbsp;</TertiaryAppSmallTitle></div>
									<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
								</div>
							</Button>
						</div>
					</div>
					<FullGrid className="mt-8">
						<div className="flex flex-row">
							<SecondaryAppMediumTitle>{translate('superadmin.manage-marketing-asset.title')}</SecondaryAppMediumTitle>
						</div>
						<div className="flex flex-1 flex-row mt-4">
							<div className="flex flex-1 grid grid-cols-2 gap-4 flex-col">
								<DataRow label={translate('superadmin.manage-marketing-asset.title.label')}>
									<FormInput 
										placeholder={translate('superadmin.manage-marketing-asset.title.placeholder')}
										value={manageMarketingAssetStore.marketingAssetData.title.value}
										error={manageMarketingAssetStore.marketingAssetData.title.error}
										onChange={(e) => manageMarketingAssetStore.marketingAssetData.title.onChange(e.target.value)}
									/>
								</DataRow>
								<DataRow label={translate('superadmin.manage-marketing-asset.subtitle.label')}>
									<FormInput 
										placeholder={translate('superadmin.manage-marketing-asset.subtitle.placeholder')}
										value={manageMarketingAssetStore.marketingAssetData.subtitle.value}
										error={manageMarketingAssetStore.marketingAssetData.subtitle.error}
										onChange={(e) => manageMarketingAssetStore.marketingAssetData.subtitle.onChange(e.target.value)}
									/>
								</DataRow>
								
								<DataRow label={translate('superadmin.manage-marketing-asset.language.label')}>
									<FormSelect 
										placeholder={translate('superadmin.manage-marketing-asset.language.placeholder')}
										options={manageMarketingAssetStore.languageOptions}
										value={manageMarketingAssetStore.marketingAssetData.selectedLanguage.value}
										onChange={(option) => manageMarketingAssetStore.marketingAssetData.selectedLanguage.onChange(option.value)}
									/>
								</DataRow>
								

								<div className="col-span-2">
									<DataRow label={translate('superadmin.manage-marketing-asset.description.label')}>
										<FormTextArea
											placeholder={translate('superadmin.manage-marketing-asset.description.placeholder')}
											value={manageMarketingAssetStore.marketingAssetData.description.value}
											error={manageMarketingAssetStore.marketingAssetData.description.error}
											onChange={(e) => manageMarketingAssetStore.marketingAssetData.description.onChange(e.target.value)}
										/>
									</DataRow>
								</div>
							</div>
							<div className="flex flex-col w-2/5 lg:ml-8">
								{manageMarketingAssetStore.currentFileUpload == null && 
									<UploadFiles
										t={translate} 
										maxFileSize={20}
										uploading={manageMarketingAssetStore.uploading}
										description={
											(
												<>
													{translate('superadmin.manage-marketing-asset.upload.part1')} <br /> {translate('superadmin.manage-marketing-asset.upload.part2')} 
												</>
											)
										}
										onChangeFiles={(files) => manageMarketingAssetStore.changeCurrentUploadFile(files)}
										onDeleteFile={() => console.log('delete')}
									 />
								}
								{manageMarketingAssetStore.currentFileUploadUrl != null && 
									<div className="flex w-full flex-col mt-2">
										<DataHeader noborder>{translate('superadmin.manage-marketing-asset.file.label')}</DataHeader>
										<div className="flex flex-row w-full items-center justify-between mt-2">
											<div className="flex"><a className="text-nav-active-color bold-font" href={manageMarketingAssetStore.currentFileUploadUrl}>{translate('superadmin.manage-marketing-asset.marketing asset-file')} </a></div>
											<div className="flex cursor-pointer" onClick={() => manageMarketingAssetStore.clearUpload()}>
												<DeleteIcon className="w-4 h-4" color={colors['error']} />
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</FullGrid>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(ManageMarketingAsset, [SUPER_ADMINISTRATOR]));