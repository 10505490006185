import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import { withTranslation } from 'react-i18next';

import {FacebookIcon, TwitterIcon, SpotifyIcon, YouTubeIcon, InstagramIcon, PodcastIcon} from '../common/icon';

import {PrimaryWebSubtext} from '../common/text'

const YOUTUBE_URL = 'https://www.youtube.com/channel/UCrwg4u2ThEA8hcmimb3B17g';
const TWITTER_URL = 'https://twitter.com/labaroma';
const FACEBOOK_URL = 'https://www.facebook.com/LabAroma';
const INSTAGRAM_URL = 'https://www.instagram.com/lab_aroma';
const PODCAST_URL = 'https://podcasts.apple.com/gb/podcast/the-labaroma-podcast-by-colleen-quinn/id1458434685';
const SPOTIFY_URL = 'https://open.spotify.com/show/5fQfjGaQdSK1R5hYtgFJCy?si=rUnPHCMMTuesqSAt70tWmg';


@inject('appStore')
@observer
class Footer extends Component{
	constructor(props){
		super(props);
		this.onPricing = this.onPricing.bind(this);
		this.onTraining = this.onTraining.bind(this);
		this.onContact = this.onContact.bind(this);
		this.onSignUp = this.onSignUp.bind(this);
		this.onTerms = this.onTerms.bind(this);
		this.onFacebook = this.onFacebook.bind(this);
		this.onTwitter = this.onTwitter.bind(this);
		this.onInstagram = this.onInstagram.bind(this);
		this.onYouTube = this.onYouTube.bind(this);
		this.onPodcast = this.onPodcast.bind(this);
	}

	onPricing(){
		this.props.appStore.goToPricing();
	}

	onTraining(){
		this.props.appStore.goToTraining();
	}

	onContact(){
		this.props.appStore.goToContact();
	}

	onSignUp(){
		this.props.appStore.goToPickAPlan();
	}

	onTerms(){
		this.props.appStore.goToTerms();
	}

	onFacebook(){
		window.open(FACEBOOK_URL)
	}

	onTwitter(){
		window.open(TWITTER_URL);
	}

	onInstagram(){
		window.open(INSTAGRAM_URL);
	}

	onYouTube(){
		window.open(YOUTUBE_URL)
	}

	onSpotify(){
		window.open(SPOTIFY_URL);
	}

	onPodcast(){
		window.open(PODCAST_URL);
	}

	render(){
		let appStore = this.props.appStore;
		let translate = this.props.t;
		return (
			<div className="flex flex-1 flex-row border-t border-black">
    			<div className="flex flex-1 flex-col items-center mt-16 mb-16">
    				<div className="flex grid grid-cols-3 gap-8 lg:grid-cols-6 flex-row justify-between items-center">
    					<FacebookIcon onClick={this.onFacebook} />
    					<TwitterIcon onClick={this.onTwitter} />
    					<InstagramIcon onClick={this.onInstagram}  />
    					<YouTubeIcon onClick={this.onYouTube} />
    					<SpotifyIcon onClick={this.onSpotify}  />
    					<PodcastIcon  onClick={this.onPodcast} />
    				</div>
    				<ul className="flex w-3/4 lg:w-1/4 grid grid-cols-2 gap-8 lg:grid-cols-4 justify-around text-center mt-16">
    					<li className="cursor-pointer" onClick={this.onPricing}><PrimaryWebSubtext>{translate('footer.pricing')}</PrimaryWebSubtext></li>
    					<li className="cursor-pointer" onClick={this.onContact}><PrimaryWebSubtext>{translate('footer.contact')}</PrimaryWebSubtext></li>
    					<li className="cursor-pointer" onClick={this.onSignUp}><PrimaryWebSubtext>{translate('footer.signup')}</PrimaryWebSubtext></li>
    					<li className="cursor-pointer" onClick={this.onTerms}><PrimaryWebSubtext>{translate('footer.tandc')}</PrimaryWebSubtext></li>
    				</ul>
	    			<div className="flex flex-1 text-center mt-16">
	    				<PrimaryWebSubtext>© Copyright LabAroma {new Date().getFullYear()}</PrimaryWebSubtext>
	    			</div>
	    			<div className="flex flex-1 items-center justify-center text-center mt-8 w-1/2">
	    				<PrimaryWebSubtext>{translate('footer.browser-support.part1')} <br /> {translate('footer.browser-support.part2')}</PrimaryWebSubtext>
	    			</div>
    			</div>
    		</div>
		)
	}
}

export default withTranslation()(Footer);