import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import Main from '../../../elements/main';
import {FullGrid} from '../../../elements/grid';

import {Button} from '../../../common/button';
import {PlusIcon, EditIcon, SaveIcon, SearchIcon} from '../../../common/icon';
import {
	FormInput
} from '../../../elements/form';
import {ToolTip} from '../../../common/input'
import {SecondaryAppMediumTitle, Body, TertiaryAppSmallTitle} from '../../../common/text'
import {List, TableCell, TableHeader, TableRow} from '../../../elements/list';

import { withAuthorisation } from '../../../common/authorisation';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, LANGUAGES } from '../../../../services/util';

@inject('appStore', 'languagesStore')
@observer
class Languages extends Component{
	constructor(props){
		super(props);
		this.renderLanguageListHeaders = this.renderLanguageListHeaders.bind(this);
		this.renderLanguageListItem = this.renderLanguageListItem.bind(this);
	}

	componentDidMount(){
		let languagesStore = this.props.languagesStore;
		languagesStore.fetchLanguages();
	}

	componentWillUnmount(){
		let languagesStore = this.props.languagesStore;
		languagesStore.initStore();
	}

	renderLanguageListHeaders(){
		let translate = this.props.t;
		return (
			<tr>
				<TableHeader>{translate("superadmin.languages.headers.language")}</TableHeader>
				<TableHeader className="text-center">{translate("superadmin.languages.headers.actions")}</TableHeader>
			</tr>
		)

	}

	renderLanguageListItem(item){
		let languagesStore = this.props.languagesStore;
		let translate = this.props.t;
		return (
			<TableRow key={item.id}>
				<TableCell><Body className="text-black">{item.name}</Body></TableCell>
				<TableCell>
					<div className="flex flex-row justify-around">
						<div className="inline-flex relative" 
							onMouseEnter={(e) => languagesStore.setEditTooltip(item.id)}
							onMouseLeave={(e) => languagesStore.setEditTooltip(null)} 
							onClick={() => languagesStore.onEditLanguage(item.id)}>
							<div className="cursor-pointer ">
								<EditIcon color='black' className="w-4 h-4 " />
								
								{languagesStore.showEditTooltip == item.id && 
									<ToolTip
										title={translate("superadmin.languages.list.edit-tooltip.title")}
										text={translate("superadmin.languages.list.edit-tooltip.text")} 
									/>
								}
							</div>
						</div>
					</div>
				</TableCell>
			</TableRow>
		);
	}

	render(){
		let translate = this.props.t;
		let languagesStore = this.props.languagesStore;
		return (
			<Main>
				<div className="flex flex-col">
					<FullGrid className="mt-8">
						<div className="flex flex-col">
							<SecondaryAppMediumTitle>{translate('superadmin.languages.title')}</SecondaryAppMediumTitle>
							<div className="flex mt-4">
								<List 
									fetching={languagesStore.fetching}
								 	renderListHeaders={this.renderLanguageListHeaders}
									type={translate('superadmin.languages.listname')}
									items={languagesStore.languages}
									renderListItem={this.renderLanguageListItem}
									pages={[]}
								/>
							</div>		
						</div>
					</FullGrid>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(Languages, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR], [LANGUAGES]));