import { observable, action, computed, autorun } from 'mobx';
import { promiseComputed } from 'computed-async-mobx';

import { FieldState, FormState } from 'formstate';

import { isEmailValidator, isRequiredValidator } from '../services/validation';
import UserApi from '../services/user';

class LoginStore{
	appStore;

	@observable email;
	@observable password;

	userApi; 
	form;
	@observable isValidForm;

	constructor(appStore){
		this.appStore = appStore;
		this.userApi = new UserApi(appStore);
		this.initStore();

	}

	initStore(){
		this.email = new FieldState('').validators((val) => isEmailValidator(val, this.appStore.i18n));
		this.password = new FieldState('').validators((val) => isRequiredValidator(val, this.appStore.i18n, 'password'));
		this.form = new FormState({
			email: this.email,
			password: this.password,
		});
		this.isValidForm = false;
		this.fetching = false;
	}

	@action onChangePassword(val){
		this.password.onChange(val);
		this.validateForm();
	}

	@action onChangeEmail(val){
		this.email.onChange(val);
		this.validateForm();
	}	

	validateForm(){
		this.form.validate()
			.then((res) => {
				this.isValidForm = !res.hasError;
			})
	}

	login(){
		this.appStore.login(this.email.value, this.password.value);
	}
}

export default LoginStore;
