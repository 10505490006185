import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Logo from '../common/logo';
import Footer from '../nav/footer';

@observer
class LeftBlockRightContent extends Component{
	render(){
		let leftBgColor = this.props.leftBgColor != null ? this.props.leftBgColor : 'white';
		let rightBgColor = this.props.rightBgColor != null ? this.props.rightBgColor : 'white';

		return (
			<div className="flex flex-1 flex-col">
				<div className="flex flex-col lg:flex-row">
					<div className={`flex flex-1 flex-col p-20 w-full h-auto lg:w-1/3 bg-${leftBgColor}`} >
						<div className="flex justify-center lg:justify-start lg:w-auto mb-8">
							<Logo small />
						</div>
						<div className="flex h-auto lg:min-h-screen flex-col justify-center text-center lg:text-left mt-8 lg:mt-0">
							{this.props.left}
						</div>
					</div>
					<div className={`flex lg:w-2/3 items-center justify-center bg-${rightBgColor} overflow-y-scroll mt-8 lg:mt-0 mb-8`}>
						<div className="flex w-3/4 lg:w-1/2">
							{this.props.right}
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

export default LeftBlockRightContent;