import { observable, action, computed, extendObservable } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isEmailValidator, isRequiredValidator, isNotNullValidator, isPositiveNumberValidator, isHexColorValidator } from '../../../../services/validation';

import BlendApi from '../../../../services/blend';

class ManageBlendCategoryStore{
	@observable addMode;
	@observable viewMode;
	@observable editMode;

	@observable fetching;

	@observable currentBlendCategory;

	@observable blendCategoryData;

	@observable currentTag;

	@observable fetching;

	id;

	blendApi;

	constructor(appStore){
		this.appStore = appStore;
		this.blendApi = new BlendApi(appStore);
		this.initStore();
	}

	initStore(){
		this.addMode = true;
		this.viewMode = false;
		this.editMode = false;
		this.fetching = false;
		this.currentBlendCategory = null;
		this.blendCategoryData = {
			name: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			currentTag: new FieldState(null),
			hexColor: new FieldState('#17273c').validators((val) => isHexColorValidator(val, this.appStore.i18n)),
			tags: new FieldState([])
		}

		this.blendCategoryForm = new FormState({
			name: this.blendCategoryData.name,
			currentTag: this.blendCategoryData.currentTag,
			hexColor: this.blendCategoryData.hexColor,
		})

		this.fetching = false;
	}

	formSync = async(id) => {
		this.fetching = true;

		try{
			let response = await this.blendApi.getBlendCategoryById(id);
			this.currentBlendCategory = response;
			this.viewMode = true;
			this.addMode = false;
			this.editMode = false;
			this.id = id;
		}
		catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}

	@action cancelChanges(){
		this.editMode = false;
		this.addMode = false;
		this.viewMode = true;
	}

	@action toggleEditMode(){
		this.blendCategoryData.name.value = this.currentBlendCategory.name;
		this.blendCategoryData.hexColor.value = this.currentBlendCategory.hex_color;
		this.blendCategoryData.tags.value = this.currentBlendCategory.tags.map((t) => t.tag.name);
		this.editMode = true;
		this.addMode = false;
		this.viewMode = false;
	}

	@action onSaveTag(){
		let currentTagValue = this.blendCategoryData.currentTag.value;
		if(currentTagValue == null || currentTagValue == ' ') return;
		let existingTags = this.blendCategoryData.tags.value;
		if(existingTags.includes(currentTagValue)){
			return;
		}
		this.blendCategoryData.tags.value.push(currentTagValue.trim());
		this.blendCategoryData.currentTag.value = '';
	}

	@action onChangeTags(values){
		this.blendCategoryData.tags.value = values != null ? values.map((v) => v.value): [];
	}

	save = async () => {
		let res = await this.blendCategoryForm.validate()
		if(res.hasError) return;

		let payload = {
			name: this.blendCategoryData.name.value,
			hex_color: this.blendCategoryData.hexColor.value,
			tags: this.blendCategoryData.tags.value
		}

		this.fetching = true;

		try{
			if(this.addMode){
				let response = await this.blendApi.newBlendCategory(payload);
			}else{
				let response = await this.blendApi.updateBlendCategory(this.id, payload);
			}
			this.appStore.displaySaveDialog();
			this.appStore.goToBlendCategories();
		}catch(e){
			console.log(e);
		}finally{
			this.fetching = false;
		}
	}
}

export default ManageBlendCategoryStore;