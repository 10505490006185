import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import Main from '../../../elements/main';
import {FullGrid} from '../../../elements/grid';

import {Button} from '../../../common/button';
import {PlusIcon, EditIcon, SaveIcon, SearchIcon} from '../../../common/icon';
import {
	FormInput
} from '../../../elements/form';
import {ToolTip} from '../../../common/input'
import {SecondaryAppMediumTitle, Body, TertiaryAppSmallTitle} from '../../../common/text'
import {List, TableCell, TableHeader, TableRow} from '../../../elements/list';

import { withAuthorisation } from '../../../common/authorisation';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, COSMETIC_REGULATIONS } from '../../../../services/util';

@inject('appStore', 'regulationsStore')
@observer
class Regulations extends Component{
	constructor(props){
		super(props);
		this.renderRegulationListHeaders = this.renderRegulationListHeaders.bind(this);
		this.renderRegulationRegionListItem = this.renderRegulationRegionListItem.bind(this);
	}

	componentDidMount(){
		let regulationsStore = this.props.regulationsStore;
		regulationsStore.fetchRegions();
	}

	componentWillUnmount(){
		let regulationsStore = this.props.regulationsStore;
		regulationsStore.initStore();
	}

	renderRegulationListHeaders(){
		let translate = this.props.t;
		return (
			<tr>
				<TableHeader>{translate("superadmin.regulations.headers.region")}</TableHeader>
				<TableHeader className="text-center">{translate("superadmin.regulations.headers.actions")}</TableHeader>
			</tr>
		)

	}

	renderRegulationRegionListItem(item){
		let regulationsStore = this.props.regulationsStore;
		let translate = this.props.t;
		return (
			<TableRow key={item.id}>
				<TableCell><Body className="text-black">{item.name}</Body></TableCell>
				<TableCell>
					<div className="flex flex-row justify-around">
						<div className="inline-flex relative" 
							onMouseEnter={(e) => regulationsStore.setEditTooltip(item.id)}
							onMouseLeave={(e) => regulationsStore.setEditTooltip(null)} 
							onClick={() => regulationsStore.onEditRegulationRegion(item.id)}>
							<div className="cursor-pointer ">
								<EditIcon color='black' className="w-4 h-4 " />
								
								{regulationsStore.showEditTooltip == item.id && 
									<ToolTip
										title={translate("superadmin.regulations.list.edit-tooltip.title")}
										text={translate("superadmin.regulations.list.edit-tooltip.text")} 
									/>
								}
							</div>
						</div>
					</div>
				</TableCell>
			</TableRow>
		);
	}

	render(){
		let translate = this.props.t;
		let regulationsStore = this.props.regulationsStore;
		return (
			<Main>
				<div className="flex flex-col">
					{regulationsStore.addMode && 
						<div className="flex flex-row items-center w-full justify-end mt-8">
							<div className="flex">
								<Button
									width="w-auto"
									height="h-12"
									className="px-4 rounded-lg"
									bgColor="pink-coral"
									disabled={!regulationsStore.validForm}
									fetching={regulationsStore.fetching}
									onClick={() => regulationsStore.saveNewRegion()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.regulations.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</div>
						</div>
					}
					<FullGrid className="mt-8">
						<div className="flex flex-col">
							<SecondaryAppMediumTitle>{translate('superadmin.regulations.title')}</SecondaryAppMediumTitle>
							<div className="flex mt-4">
								<List 
									fetching={regulationsStore.fetching}
								 	renderListHeaders={this.renderRegulationListHeaders}
									type={translate('superadmin.regulations.listname')}
									items={regulationsStore.regions}
									renderListItem={this.renderRegulationRegionListItem}
									pages={[]}
								/>
							</div>		
						</div>
						<div className="flex flex-col mt-8">
							<div className="flex flex-row items-center">
								<div className="flex"><PlusIcon className="w-8 h-8" color="black" /></div>
								<div className="flex cursor-pointer" onClick={() => regulationsStore.toggleAddMode()}><TertiaryAppSmallTitle>{translate('superadmin.regulations.new-region')}</TertiaryAppSmallTitle></div>
							</div>
							{regulationsStore.addMode &&
								<div className="flex w-full lg:w-1/3 flex-row mt-2">
									<FormInput 
										placeholder={translate('superadmin.regulations.region.name.placeholder')}
										value={regulationsStore.regionName.value}
										error={regulationsStore.regionName.error}
										onChange={(e) => regulationsStore.regionName.onChange(e.target.value)}
									/>
								</div>
							}
						</div>
					</FullGrid>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(Regulations, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR], [COSMETIC_REGULATIONS]));