import { observable, action, computed} from 'mobx';

import moment from 'moment'
import TermsApi from '../../services/terms';

class TermsStore{
	appStore;

	@observable termsAndConditionsHTML;
	@observable lastUpdated;

	termsApi;

	form;

	constructor(appStore){
    	this.appStore = appStore;
    	this.initStore();
		this.termsApi = new TermsApi(appStore);
    }

    initStore(){
    	this.termsAndConditionsHTML = null;
    	this.lastUpdated = null;
    }

    fetchTerms(){
    	return this.termsApi.getTermsSnippet()
    		.then((response) => {
    			this.termsAndConditionsHTML = response.html;
    			this.lastUpdated = response.updated_on != null ? moment(response.updated_on) : null;

    		})
    		.catch((error) => {
    			console.log(error);
    		})
    }
}

export default TermsStore;