import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import Main from '../../../elements/main';
import {FullGrid} from '../../../elements/grid';

import {Button} from '../../../common/button';
import {PlusIcon, EditIcon, SearchIcon} from '../../../common/icon';
import {
	FormInput
} from '../../../elements/form';
import {ToolTip} from '../../../common/input'
import {SecondaryAppMediumTitle, Body, TertiaryAppSmallTitle} from '../../../common/text'
import {List, TableCell, TableHeader, TableRow} from '../../../elements/list';

import { withAuthorisation } from '../../../common/authorisation';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR, CHEMISTRY } from '../../../../services/util';

@inject('appStore', 'chemicalFamiliesStore', 'chemicalComponentsStore', 'acidFamiliesStore', 'acidComponentsStore')
@observer
class ChemistrySettings extends Component{
	constructor(props){
		super(props);
		
		this.renderChemicalFamilyListHeaders = this.renderChemicalFamilyListHeaders.bind(this);
		this.renderChemicalFamilyListItem = this.renderChemicalFamilyListItem.bind(this);
		this.onPreviousFamilyPage = this.onPreviousFamilyPage.bind(this);
		this.onNextFamilyPage = this.onNextFamilyPage.bind(this);
		this.onFamilyPage = this.onFamilyPage.bind(this);

		this.renderChemicalComponentListHeaders = this.renderChemicalComponentListHeaders.bind(this);
		this.renderChemicalComponentListItem = this.renderChemicalComponentListItem.bind(this);
		this.onPreviousComponentPage = this.onPreviousComponentPage.bind(this);
		this.onNextComponentPage = this.onNextComponentPage.bind(this);
		this.onComponentPage = this.onComponentPage.bind(this);

		this.renderAcidFamilyListHeaders = this.renderAcidFamilyListHeaders.bind(this);
		this.renderAcidFamilyListItem = this.renderAcidFamilyListItem.bind(this);
		this.onPreviousAcidFamilyPage = this.onPreviousAcidFamilyPage.bind(this);
		this.onNextAcidFamilyPage = this.onNextAcidFamilyPage.bind(this);
		this.onAcidFamilyPage = this.onAcidFamilyPage.bind(this);

		this.renderAcidComponentListHeaders = this.renderAcidComponentListHeaders.bind(this);
		this.renderAcidComponentListItem = this.renderAcidComponentListItem.bind(this);
		this.onPreviousAcidComponentPage = this.onPreviousAcidComponentPage.bind(this);
		this.onNextAcidComponentPage = this.onNextAcidComponentPage.bind(this);
		this.onAcidComponentPage = this.onAcidComponentPage.bind(this);
	}

	componentDidMount(){
		let chemicalFamiliesStore = this.props.chemicalFamiliesStore;
		chemicalFamiliesStore.fetchChemicalFamilies();

		let chemicalComponentsStore = this.props.chemicalComponentsStore;
		chemicalComponentsStore.fetchChemicalComponents();

		let acidFamiliesStore = this.props.acidFamiliesStore;
		acidFamiliesStore.fetchAcidFamilies();

		let acidComponentsStore = this.props.acidComponentsStore;
		acidComponentsStore.fetchAcidComponents();
	}

	componentWillUnmount(){
		let chemicalFamiliesStore = this.props.chemicalFamiliesStore;
		chemicalFamiliesStore.initStore();

		let chemicalComponentsStore = this.props.chemicalComponentsStore;
		chemicalComponentsStore.initStore();

		let acidFamiliesStore = this.props.acidFamiliesStore;
		acidFamiliesStore.initStore();

		let acidComponentsStore = this.props.acidComponentsStore;
		acidComponentsStore.initStore();
	}

	renderChemicalFamilyListHeaders(){
		let translate = this.props.t;
		return (
			<tr>
				<TableHeader>{translate("superadmin.chemistry-settings.chemical-families.headers.chemical-family")}</TableHeader>
				<TableHeader>{translate("superadmin.chemistry-settings.chemical-families.headers.hex-colour")}</TableHeader>
				<TableHeader className="text-center">{translate("superadmin.chemistry-settings.chemical-families.headers.actions")}</TableHeader>
			</tr>
		)

	}

	renderChemicalFamilyListItem(item){
		let chemicalFamiliesStore = this.props.chemicalFamiliesStore;
		let translate = this.props.t;
		return (
			<TableRow key={item.id}>
				<TableCell><Body className="text-black">{item.name}</Body></TableCell>
				<TableCell>
					<div className="flex flex-row items-center">
						<div className="flex w-3 h-3 rounded-full" style={{ backgroundColor: item.hex_color}}></div>
						<div className="flex ml-2"><Body className="text-black">{item.hex_color}</Body></div>
					</div>
				</TableCell>
				<TableCell>
					<div className="flex flex-row justify-around">
						<div className="inline-flex relative" 
							onMouseEnter={(e) => chemicalFamiliesStore.setEditTooltip(item.id)}
							onMouseLeave={(e) => chemicalFamiliesStore.setEditTooltip(null)} 
							onClick={() => chemicalFamiliesStore.onEditChemicalFamily(item.id)}>
							<div className="cursor-pointer ">
								<EditIcon color='black' className="w-4 h-4 " />
								
								{chemicalFamiliesStore.showEditTooltip == item.id && 
									<ToolTip
										title={translate("superadmin.chemistry-settings.chemical-families.list.edit-tooltip.title")}
										text={translate("superadmin.chemistry-settings.chemical-families.list.edit-tooltip.text")} 
									/>
								}
							</div>
						</div>
					</div>
				</TableCell>
			</TableRow>
		);
	}

	onPreviousFamilyPage(){
		let chemicalFamiliesStore = this.props.chemicalFamiliesStore;
		chemicalFamiliesStore.onPreviousPage();
	}

	onNextFamilyPage(){
		let chemicalFamiliesStore = this.props.chemicalFamiliesStore;
		chemicalFamiliesStore.onNextPage();
	}

	onFamilyPage(page){
		let chemicalFamiliesStore = this.props.chemicalFamiliesStore;
		chemicalFamiliesStore.onPage(page);
	}



	renderAcidFamilyListHeaders(){
		let translate = this.props.t;
		return (
			<tr>
				<TableHeader>{translate("superadmin.chemistry-settings.acid-families.headers.acid-family")}</TableHeader>
				<TableHeader>{translate("superadmin.chemistry-settings.acid-families.headers.hex-colour")}</TableHeader>
				<TableHeader className="text-center">{translate("superadmin.chemistry-settings.acid-families.headers.actions")}</TableHeader>
			</tr>
		)

	}

	renderAcidFamilyListItem(item){
		let acidFamiliesStore = this.props.acidFamiliesStore;
		let translate = this.props.t;
		return (
			<TableRow key={item.id}>
				<TableCell><Body className="text-black">{item.name}</Body></TableCell>
				<TableCell>
					<div className="flex flex-row items-center">
						<div className="flex w-3 h-3 rounded-full" style={{ backgroundColor: item.hex_color}}></div>
						<div className="flex ml-2"><Body className="text-black">{item.hex_color}</Body></div>
					</div>
				</TableCell>
				<TableCell>
					<div className="flex flex-row justify-around">
						<div className="inline-flex relative" 
							onMouseEnter={(e) => acidFamiliesStore.setEditTooltip(item.id)}
							onMouseLeave={(e) => acidFamiliesStore.setEditTooltip(null)} 
							onClick={() => acidFamiliesStore.onEditAcidFamily(item.id)}>
							<div className="cursor-pointer ">
								<EditIcon color='black' className="w-4 h-4 " />
								
								{acidFamiliesStore.showEditTooltip == item.id && 
									<ToolTip
										title={translate("superadmin.chemistry-settings.acid-families.list.edit-tooltip.title")}
										text={translate("superadmin.chemistry-settings.acid-families.list.edit-tooltip.text")} 
									/>
								}
							</div>
						</div>
					</div>
				</TableCell>
			</TableRow>
		);
	}

	onPreviousAcidFamilyPage(){
		let acidFamiliesStore = this.props.acidFamiliesStore;
		acidFamiliesStore.onPreviousPage();
	}

	onNextAcidFamilyPage(){
		let acidFamiliesStore = this.props.acidFamiliesStore;
		acidFamiliesStore.onNextPage();
	}

	onAcidFamilyPage(page){
		let acidFamiliesStore = this.props.acidFamiliesStore;
		acidFamiliesStore.onPage(page);
	}

	renderChemicalComponentListHeaders(){
		let translate = this.props.t;
		return (
			<tr>
				<TableHeader>{translate("superadmin.chemistry-settings.chemical-components.headers.chemical-component")}</TableHeader>
				<TableHeader>{translate("superadmin.chemistry-settings.chemical-components.headers.chemical-family")}</TableHeader>
				<TableHeader className="text-center">{translate("superadmin.chemistry-settings.chemical-components.headers.actions")}</TableHeader>
			</tr>
		)

	}

	renderChemicalComponentListItem(item){
		let chemicalComponentsStore = this.props.chemicalComponentsStore;
		let translate = this.props.t;
		return (
			<TableRow key={item.id}>
				<TableCell><Body className="text-black">{item.name}</Body></TableCell>
				<TableCell>
					<Body className="text-black">{item.chemical_family.name}</Body>
				</TableCell>
				<TableCell>
					<div className="flex flex-row justify-around">
						<div className="inline-flex relative" 
							onMouseEnter={(e) => chemicalComponentsStore.setEditTooltip(item.id)}
							onMouseLeave={(e) => chemicalComponentsStore.setEditTooltip(null)} 
							onClick={() => chemicalComponentsStore.onEditChemicalComponent(item.id)}>
							<div className="cursor-pointer ">
								<EditIcon color='black' className="w-4 h-4 " />
								
								{chemicalComponentsStore.showEditTooltip == item.id && 
									<ToolTip
										title={translate("superadmin.chemistry-settings.chemical-components.list.edit-tooltip.title")}
										text={translate("superadmin.chemistry-settings.chemical-components.list.edit-tooltip.text")} 
									/>
								}
							</div>
						</div>
					</div>
				</TableCell>
			</TableRow>
		);
	}

	onPreviousComponentPage(){
		let chemicalComponentsStore = this.props.chemicalComponentsStore;
		chemicalComponentsStore.onPreviousPage();
	}

	onNextComponentPage(){
		let chemicalComponentsStore = this.props.chemicalComponentsStore;
		chemicalComponentsStore.onNextPage();
	}

	onComponentPage(page){
		let chemicalComponentsStore = this.props.chemicalComponentsStore;
		chemicalComponentsStore.onPage(page);
	}

	renderAcidComponentListHeaders(){
		let translate = this.props.t;
		return (
			<tr>
				<TableHeader>{translate("superadmin.chemistry-settings.acid-components.headers.acid-component")}</TableHeader>
				<TableHeader>{translate("superadmin.chemistry-settings.acid-components.headers.acid-family")}</TableHeader>
				<TableHeader className="text-center">{translate("superadmin.chemistry-settings.acid-components.headers.actions")}</TableHeader>
			</tr>
		)

	}

	renderAcidComponentListItem(item){
		let acidComponentsStore = this.props.acidComponentsStore;
		let translate = this.props.t;
		return (
			<TableRow key={item.id}>
				<TableCell><Body className="text-black">{item.name}</Body></TableCell>
				<TableCell>
					<Body className="text-black">{item.acid_family.name}</Body>
				</TableCell>
				<TableCell>
					<div className="flex flex-row justify-around">
						<div className="inline-flex relative" 
							onMouseEnter={(e) => acidComponentsStore.setEditTooltip(item.id)}
							onMouseLeave={(e) => acidComponentsStore.setEditTooltip(null)} 
							onClick={() => acidComponentsStore.onEditAcidComponent(item.id)}>
							<div className="cursor-pointer ">
								<EditIcon color='black' className="w-4 h-4 " />
								
								{acidComponentsStore.showEditTooltip == item.id && 
									<ToolTip
										title={translate("superadmin.chemistry-settings.acid-components.list.edit-tooltip.title")}
										text={translate("superadmin.chemistry-settings.acid-components.list.edit-tooltip.text")} 
									/>
								}
							</div>
						</div>
					</div>
				</TableCell>
			</TableRow>
		);
	}

	onPreviousAcidComponentPage(){
		let acidComponentsStore = this.props.acidComponentsStore;
		acidComponentsStore.onPreviousPage();
	}

	onNextAcidComponentPage(){
		let acidComponentsStore = this.props.acidComponentsStore;
		acidComponentsStore.onNextPage();
	}

	onAcidComponentPage(page){
		let acidComponentsStore = this.props.acidComponentsStore;
		acidComponentsStore.onPage(page);
	}


	render(){
		let translate = this.props.t;
		let chemicalFamiliesStore = this.props.chemicalFamiliesStore;
		let chemicalComponentsStore = this.props.chemicalComponentsStore;
		let acidFamiliesStore = this.props.acidFamiliesStore;
		let acidComponentsStore = this.props.acidComponentsStore;
		return (
			<Main>
				<div className="flex flex-col">
					<FullGrid className="mt-8">
						<div className="flex flex-row items-center w-full justify-end mt-8">
							<div className="flex">
								<FormInput 
									extraInputClassNames="h-12 w-64"
									placeholder={translate('common.search')}
									insetoption={<SearchIcon className="w-4 h-4 mb-1" />}
									value={chemicalFamiliesStore.searchText}
									onChange={(e) => chemicalFamiliesStore.changeSearchText(e.target.value)}
								/>
							</div>
							<div className="flex">
								<Button
									width="w-auto"
									height="h-12"
									className="ml-8 pl-4 rounded-lg"
									bgColor="pink-coral"
									onClick={() => this.props.appStore.goToNewChemistryFamily()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.chemistry-settings.new-chemical-family')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><PlusIcon /></div>
									</div>
								</Button>
							</div>
						</div>
						<div className="flex flex-col">
							<SecondaryAppMediumTitle>{translate('superadmin.chemistry-settings.chemical-families.title')}</SecondaryAppMediumTitle>
							<div className="flex mt-4">
								<List 
									fetching={chemicalFamiliesStore.fetching}
								 	renderListHeaders={this.renderChemicalFamilyListHeaders}
									type={translate('superadmin.chemistry-settings.chemical-families.listname')}
									items={chemicalFamiliesStore.chemicalFamilies}
									renderListItem={this.renderChemicalFamilyListItem}
									page={chemicalFamiliesStore.page}
									pages={chemicalFamiliesStore.pages}
									hasNext={chemicalFamiliesStore.hasNext}
									hasPrev={chemicalFamiliesStore.hasPrev}
									onPreviousPage={this.onPreviousFamilyPage}
									onNextPage={this.onNextFamilyPage}
									onPage={this.onFamilyPage}
								/>
							</div>		
						</div>
					</FullGrid>
				</div>
				<div className="flex flex-col mt-8">
					<FullGrid className="mt-8">
						<div className="flex flex-row items-center w-full justify-end mt-8">
							<div className="flex">
								<FormInput 
									extraInputClassNames="h-12 w-64"
									placeholder={translate('common.search')}
									insetoption={<SearchIcon className="w-4 h-4 mb-1" />}
									value={chemicalComponentsStore.searchText}
									onChange={(e) => chemicalComponentsStore.changeSearchText(e.target.value)}
								/>
							</div>
							<div className="flex">
								<Button
									width="w-auto"
									height="h-12"
									className="ml-8 pl-4 rounded-lg"
									bgColor="pink-coral"
									onClick={() => this.props.appStore.goToNewChemicalComponent()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.chemistry-settings.new-chemical-component')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><PlusIcon /></div>
									</div>
								</Button>
							</div>
						</div>
						<div className="flex flex-col">
							<SecondaryAppMediumTitle>{translate('superadmin.chemistry-settings.chemical-components.title')}</SecondaryAppMediumTitle>
							<div className="flex mt-4">
								<List 
									fetching={chemicalComponentsStore.fetching}
								 	renderListHeaders={this.renderChemicalComponentListHeaders}
									type={translate('superadmin.chemistry-settings.chemical-components.listname')}
									items={chemicalComponentsStore.chemicalComponents}
									renderListItem={this.renderChemicalComponentListItem}
									page={chemicalComponentsStore.page}
									pages={chemicalComponentsStore.pages}
									hasNext={chemicalComponentsStore.hasNext}
									hasPrev={chemicalComponentsStore.hasPrev}
									onPreviousPage={this.onPreviousComponentPage}
									onNextPage={this.onNextComponentPage}
									onPage={this.onComponentPage}
								/>
							</div>		
						</div>
					</FullGrid>
				</div>
				<div className="flex flex-col mt-8">
					<FullGrid className="mt-8">
						<div className="flex flex-row items-center w-full justify-end mt-8">
							<div className="flex">
								<FormInput 
									extraInputClassNames="h-12 w-64"
									placeholder={translate('common.search')}
									insetoption={<SearchIcon className="w-4 h-4 mb-1" />}
									value={acidFamiliesStore.searchText}
									onChange={(e) => acidFamiliesStore.changeSearchText(e.target.value)}
								/>
							</div>
							<div className="flex">
								<Button
									width="w-auto"
									height="h-12"
									className="ml-8 pl-4 rounded-lg"
									bgColor="pink-coral"
									onClick={() => this.props.appStore.goToNewAcidFamily()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.chemistry-settings.new-acid-family')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><PlusIcon /></div>
									</div>
								</Button>
							</div>
						</div>
						<div className="flex flex-col">
							<SecondaryAppMediumTitle>{translate('superadmin.chemistry-settings.acid-families.title')}</SecondaryAppMediumTitle>
							<div className="flex mt-4">
								<List 
									fetching={acidFamiliesStore.fetching}
								 	renderListHeaders={this.renderAcidFamilyListHeaders}
									type={translate('superadmin.chemistry-settings.acid-families.listname')}
									items={acidFamiliesStore.acidFamilies}
									renderListItem={this.renderAcidFamilyListItem}
									page={acidFamiliesStore.page}
									pages={acidFamiliesStore.pages}
									hasNext={acidFamiliesStore.hasNext}
									hasPrev={acidFamiliesStore.hasPrev}
									onPreviousPage={this.onPreviousAcidFamilyPage}
									onNextPage={this.onNextAcidFamilyPage}
									onPage={this.onAcidFamilyPage}
								/>
							</div>		
						</div>
					</FullGrid>
				</div>
				<div className="flex flex-col mt-8">
					<FullGrid className="mt-8">
						<div className="flex flex-row items-center w-full justify-end mt-8">
							<div className="flex">
								<FormInput 
									extraInputClassNames="h-12 w-64"
									placeholder={translate('common.search')}
									insetoption={<SearchIcon className="w-4 h-4 mb-1" />}
									value={acidComponentsStore.searchText}
									onChange={(e) => acidComponentsStore.changeSearchText(e.target.value)}
								/>
							</div>
							<div className="flex">
								<Button
									width="w-auto"
									height="h-12"
									className="ml-8 pl-4 rounded-lg"
									bgColor="pink-coral"
									onClick={() => this.props.appStore.goToNewAcidComponent()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('superadmin.chemistry-settings.new-acid-component')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><PlusIcon /></div>
									</div>
								</Button>
							</div>
						</div>
						<div className="flex flex-col">
							<SecondaryAppMediumTitle>{translate('superadmin.chemistry-settings.acid-components.title')}</SecondaryAppMediumTitle>
							<div className="flex mt-4">
								<List 
									fetching={acidComponentsStore.fetching}
								 	renderListHeaders={this.renderAcidComponentListHeaders}
									type={translate('superadmin.chemistry-settings.acid-components.listname')}
									items={acidComponentsStore.acidComponents}
									renderListItem={this.renderAcidComponentListItem}
									page={acidComponentsStore.page}
									pages={acidComponentsStore.pages}
									hasNext={acidComponentsStore.hasNext}
									hasPrev={acidComponentsStore.hasPrev}
									onPreviousPage={this.onPreviousAcidComponentPage}
									onNextPage={this.onNextAcidComponentPage}
									onPage={this.onAcidComponentPage}
								/>
							</div>		
						</div>
					</FullGrid>
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(ChemistrySettings, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR], [CHEMISTRY]));