import React, { Component } from 'react';
import {inject, observer} from 'mobx-react';
import { withTranslation } from 'react-i18next';

import BaseModal from '../common/modal';

import {Button} from '../common/button';
import {DrawIcon, SentIcon} from '../common/icon';
import {Body, SecondaryAppMediumTitle, TertiaryAppSmallTitle} from '../common/text';

@inject('appStore')
@observer
class SavedDialog extends Component{
	constructor(props){
		super(props);
		this.onDashboard = this.onDashboard.bind(this);
		this.onExit = this.onExit.bind(this);
	}

	onDashboard(){
		let appStore = this.props.appStore;
		appStore.onReturnSaveDialog();
	}

	onExit(){
		let appStore = this.props.appStore;
		appStore.hideSaveDialog();
	}

	render(){
		let translate = this.props.t;
		let appStore = this.props.appStore;
		
		let icon = (<DrawIcon className="w-full h-full" />)
		if(appStore.showSaveDialog.icon == 'invite'){
			icon = (<SentIcon className="w-64 h-64" />)
		}

		let message = translate("common.changes-saved")
		if(appStore.showSaveDialog.message != null){
			message = appStore.showSaveDialog.message;
		}

		return (
			<BaseModal
				small
	            isOpen={appStore.showSaveDialog.display}
	            onClose={this.onExit}
	            label="Saved Dialog"
	        >
				<div className="flex px-16 py-8 lg:px-64 lg:py-32 items-center justify-center flex-col lg:flex-row bg-white rounded-lg">
					<div className="flex flex-col items-center justify-center">
						<div className="flex flex-1">
							{icon}
						</div>
						<div className="flex flex-col mt-8">
							<div className="flex">
								<SecondaryAppMediumTitle className="text-nav-active-color">{message}</SecondaryAppMediumTitle>
							</div>
							{!appStore.showSaveDialog.hideButton && 
								<div className="flex flex-col mt-8">
									<Button onClick={this.onDashboard} className="w-full h-16 rounded-lg" bgColor="nav-active-color" fgColor="white">{translate("common.go-to-dashboard")}</Button>
								</div>
							}
							<div className="flex flex-col items-center mt-4">
								{!appStore.showSaveDialog.hideButton && <div className="flex mt-1"><Body className="text-nav-active-color">{translate("common.or")}</Body></div>}
								<div className="flex mt-1 cursor-pointer" onClick={this.onExit}><TertiaryAppSmallTitle className="tracking-wider underline text-nav-active-color">{translate("common.close-and-return")}</TertiaryAppSmallTitle></div>
							</div>
						</div>
					</div>
				</div>
			</BaseModal>
		)
	}
}

export default withTranslation()(SavedDialog);