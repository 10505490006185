import { observable, action, computed} from 'mobx';

class CartStore{
	appStore;


	constructor(appStore){
    	this.appStore = appStore;
    	this.initStore();
    }

    initStore(){
    	
    }
	
	@action submit(){
		
	}


	@action exitCart(){
		this.appStore.closeCartModal();
	}

	@action continue(){
		let cart = this.currentCart();
		if(cart != null){
			this.appStore.goToRegistrationWith(cart.currencyId, cart.period, cart.planId, cart.username, cart.step, cart.country, cart.profession);

		}
		this.appStore.closeCartModal();
	}

	@action currentCart(){
		return this.appStore.getCurrentCart();
	}

}

export default CartStore;