import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import {SecondaryWebSmallTitle, SecondaryAppMediumTitle, SecondaryAppLargeTitle, Body, SmallBody, TertiaryAppSmallTitle, ApplyText} from '../../common/text'
import {PlusIcon, ArrowRightIcon, EvidenceIcon, EditIngredientIcon, CompareIngredients, SaveIcon, CameraIcon, EyeIcon, StarIcon, EditIcon, ExitIcon,DuplicateIcon, DeleteIcon} from '../../common/icon';
import Main from '../../elements/main';
import {Button} from '../../common/button';
import BaseModal from '../../common/modal';
import {DownloadPDF, pdfStyles} from '../../common/pdf';
import ChemicalFamilyDialog from '../modals';
import {
	DataHeader,
	DataRow,
	DataEntry,
	FormInput,
	FormSelect,
	FormSearchSelect,
	FormTextArea,
	FormPasswordInput,
	TwoColumnReadList,
	AddListButton,
	GridSection
} from '../../elements/form';
import {RadioButton, ImageAvatar, FileEntry, HelpTooltip, CheckBox} from '../../common/input';
import {PieChart} from '../../common/chart';
import {Tag} from '../../common/tag';
import UploadFiles from '../../common/upload-files';
import logoPinkSrc from '../../../assets/img/logo.png';
import {parseAndSanitizeHtml} from '../../common/html';

const colors = require('../../../color.js');

import { pdf, Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import * as htmlToImage from 'html-to-image';

import printJS from 'print-js'

const sortByPercentage = (c1, c2) => c1.amount > c2.amount ? -1 : 1;

const IngredientPDF = ({logoSrc, ingredientSrc,
					nameTitle, name,
					latinNameTitle, latinName,
					originTitle, origin,
					chemicalFamilyBreakdown,
					chemicalFamilyBreakdownTitle,
					ingredientTypeTitle, ingredientType,
					benchmarkPricePer15mlTitle, benchmarkPricePer15ml,
					botanicalFamilyTitle, botanicalFamily,
					partOfPlantTitle, partOfPlant,
					safetyNoteTitle, safetyNote,
					conservationStatusTitle, conservationStatus, conservationStatusDescription,
					profileTitle, profile,
					therapeuticPropertiesTitle, therapeuticProperties,
					comedogenicScaleTitle, comedogenicScale,
					formulationGuidanceTitle, formulationGuidance,
					synergyTitle, synergy,
					energeticsTitle, energetics,
					scentNoteTitle, scentNote,
					chemicalComponentBreakdownTitle, chemicalComponentBreakdownByFamily,
					acidComponentBreakdownTitle, acidComponentBreakdownByFamily,
					acidFamilyBreakdownTitle, acidFamilyBreakdown,
					ingredientNotesTitle, ingredientNotes,
					psychologicalRankingsTitle, psychologicalRankings,
					physicalRankingsTitle, physicalRankings,
					showComedogenicScale=false, showFormulationGuidance=false, 
					showEnergetics=false,
					showFattyAcidBreakdown=false,
					chartSrc=null, acidChartSrc=null,
					isHomeUser=false,
					showProfile=true,
					showTherapeuticProperties=true,
					showEnergeticsDownload=true,
					showChemicalBreakdown=true,
					showScentNotes=true,
					showEffects=true,
					showNotes=true,
					showSynergy=true
				}) => {
	let footer = (
		<View style={pdfStyles.rowBetweenNoMargin} wrap={false}>
  			<Image src={logoSrc} style={pdfStyles.logo} />
  			<Text style={[pdfStyles.title, pdfStyles.italic]}>www.labaroma.com</Text>
  		</View>
  	);						
	return (
		<Document creator="LabAroma" producer="LabAroma">
		    <Page orientation="portrait" size="A4" style={pdfStyles.page}>
		      	<View style={pdfStyles.container}>
		      		<View style={pdfStyles.rowBetweenAlignCenter}>
		      			<Image src={logoSrc} style={pdfStyles.logo} />
		      			{ingredientSrc != null && 
		      				<Image src={ingredientSrc} style={{
			      				width: 60,
			      				height: 60
			      			}} />
			      		}
		      		</View>
			      	{showProfile &&
			      		<View>
				      		<View style={pdfStyles.row}>
					      		<View style={pdfStyles.gridColumn}>
					      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{nameTitle}</Text>
					      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{name}</Text></View>
					      		</View>
					      		<View style={pdfStyles.gridColumn}>
					      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{latinNameTitle}</Text>
					      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{latinName}</Text></View>
					      		</View>
					      		<View style={pdfStyles.gridColumn}>
					      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{originTitle}</Text>
					      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{origin}</Text></View>
					      		</View>
					      	</View>
					      	<View style={pdfStyles.row}>
					      		<View style={pdfStyles.gridColumn}>
					      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{ingredientTypeTitle}</Text>
					      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{ingredientType}</Text></View>
					      		</View>
					      		<View style={pdfStyles.gridColumn}>
					      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{benchmarkPricePer15mlTitle}</Text>
					      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{benchmarkPricePer15ml != null && `$${benchmarkPricePer15ml}`}</Text></View>
					      		</View>
					      		<View style={pdfStyles.gridColumn}>
					      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{botanicalFamilyTitle}</Text>
					      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{botanicalFamily}</Text></View>
					      		</View>
					      	</View>
					      	<View style={pdfStyles.row}>
					      		<View style={pdfStyles.gridColumn}>
					      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{partOfPlantTitle}</Text>
					      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{partOfPlant}</Text></View>
					      		</View>
					      		<View style={pdfStyles.gridColumn}>
					      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{safetyNoteTitle}</Text>
					      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{safetyNote}</Text></View>
					      		</View>
					      		<View style={pdfStyles.gridColumn}>
					      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{conservationStatusTitle}</Text>
					      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{conservationStatus}</Text></View>
					      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{conservationStatusDescription}</Text></View>
					      		</View>
					      	</View>
					    </View>
					}
			      	
			      	<View style={pdfStyles.row}>
			      		{showProfile &&
			      			<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{profileTitle}</Text>
				      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{profile}</Text></View>
				      		</View>
				      	}
			      		{showTherapeuticProperties && 
			      			<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{therapeuticPropertiesTitle}</Text>
				      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{therapeuticProperties}</Text></View>
				      		</View>
				      	}
			      		{showProfile && showComedogenicScale && 
			      			<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{comedogenicScaleTitle}</Text>
				      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{comedogenicScaleTitle}</Text></View>
				      		</View>
				      	}
			      	</View>
			      	<View style={pdfStyles.row}>
			      		{showFormulationGuidance && showProfile &&
			      			<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{formulationGuidanceTitle}</Text>
				      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{formulationGuidance}</Text></View>
				      		</View>
				      	}
			      		{showSynergy && 
			      			<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{synergyTitle}</Text>
				      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{synergy}</Text></View>
				      		</View>
				      	}
			      		{showEnergetics && showEnergeticsDownload &&
			      			<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{energeticsTitle}</Text>
				      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{energetics}</Text></View>
				      		</View>
				      	}
				    </View>
				    <View style={pdfStyles.row}>
				      	{showScentNotes &&
				      		<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{scentNoteTitle}</Text>
				      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{scentNote}</Text></View>
				      		</View>
				      	}
			      		{showEffects && 
			      			<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{psychologicalRankingsTitle}</Text>
				      			{psychologicalRankings.map((ranking) => 
				      				<View style={[pdfStyles.rowBetween, pdfStyles.smallMarginTop]}>
				      					<View><Text style={pdfStyles.datapoint}>{ranking.psychological_ranking?.name}</Text></View>
				      					<View><Text style={pdfStyles.datapoint}>{ranking.score}</Text></View>
				      				</View>
				      			)}
				      			
				      		</View>
				      	}
			      		{showEffects && 
			      			<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{physicalRankingsTitle}</Text>
				      			{physicalRankings.map((ranking) => 
				      				<View style={[pdfStyles.rowBetween, pdfStyles.smallMarginTop]}>
				      					<View><Text style={pdfStyles.datapoint}>{ranking.physical_ranking?.name}</Text></View>
				      					<View><Text style={pdfStyles.datapoint}>{ranking.score}</Text></View>
				      				</View>
				      			)}
				      			
				      		</View>
				      	}
			      	</View>
			      	{showNotes && 
			      		<View style={pdfStyles.row}>
				      		<View style={pdfStyles.gridColumn}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{ingredientNotesTitle}</Text>
				      			<View style={pdfStyles.smallMarginTop}><Text style={pdfStyles.datapoint}>{ingredientNotes}</Text></View>
				      		</View>
				      	</View>
				    }
			    </View>
			</Page>
		   {!isHomeUser && chemicalFamilyBreakdown.length > 0 && showChemicalBreakdown &&
			   	<Page orientation="portrait" size="A4" style={pdfStyles.page}>
			    	<View style={pdfStyles.container}>
			      		<View style={pdfStyles.row}>
			      			<Image src={logoSrc} style={pdfStyles.logo} />
			      		</View>
			      		<View style={pdfStyles.row}>
				      		{chartSrc != null && <Image src={chartSrc} style={pdfStyles.pieChartImage} />}
				      		<View style={pdfStyles.list}>
				      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{chemicalFamilyBreakdownTitle}</Text>
				      			{chemicalFamilyBreakdown.map((familyBreakdown) =>
				      				<View style={pdfStyles.listItem}>
					      				<Text style={pdfStyles.smalldatapoint}>{familyBreakdown.x}</Text>
					      				<Text style={pdfStyles.smalldatapoint}>{familyBreakdown.y}%</Text>
					      			</View>
				      			)}
				      		</View>
				      	</View>
				      	{!isHomeUser && chemicalComponentBreakdownByFamily.length > 0 &&
					      	<View style={pdfStyles.container}>
					      		<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{chemicalComponentBreakdownTitle}</Text>
								
								<View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1, flexWrap: 'wrap'}}>
									{chemicalComponentBreakdownByFamily.sort(sortByPercentage).map((entry, idx) => 
										<View style={{flexDirection: 'column', width: "40%", marginLeft: idx % 2 > 0 ? "5%" : 0 }}>
											<View style={pdfStyles.tableListItem}>
					      						<Text style={pdfStyles.tableTitle}>{entry[0]}</Text>
					      					</View>
					      					{entry[1].map((component) => 
					      						<View style={pdfStyles.tableListItem}>
					      							<Text style={pdfStyles.smalldatapoint}>{'chemical_component' in component ? component.chemical_component.name : component.acid_component.name}</Text>
					      							<Text style={pdfStyles.smalldatapoint}>{component.amount}%</Text>
					      						</View>
					      					)}
					      				</View>
									)}
								</View>
					      	</View>
						}
						{!isHomeUser && showFattyAcidBreakdown && acidComponentBreakdownByFamily.length > 0 &&
					    	<View style={pdfStyles.container}>
					      		<View style={pdfStyles.row}>
					      			<Image src={logoSrc} style={pdfStyles.logo} />
					      		</View>
					      		<View style={pdfStyles.row}>
						      		{acidChartSrc != null && <Image src={acidChartSrc} style={pdfStyles.pieChartImage} />}
						      		<View style={pdfStyles.list}>
						      			<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{acidFamilyBreakdownTitle}</Text>
						      			{acidFamilyBreakdown.map((familyBreakdown) =>
						      				<View style={pdfStyles.listItem}>
							      				<Text style={pdfStyles.smalldatapoint}>{familyBreakdown.x}</Text>
							      				<Text style={pdfStyles.smalldatapoint}>{familyBreakdown.y}%</Text>
							      			</View>
						      			)}
						      		</View>
						      	</View>
						      	{footer}
					      	</View>
						}
						{!isHomeUser && showFattyAcidBreakdown && acidComponentBreakdownByFamily.length > 0 &&
					      	<View style={pdfStyles.container}>
					      		<Text style={[pdfStyles.title, pdfStyles.borderBottom]}>{acidComponentBreakdownTitle}</Text>
								
								<View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1, flexWrap: 'wrap'}}>
									{acidComponentBreakdownByFamily.sort(sortByPercentage).map((entry, idx) => 
										<View style={{flexDirection: 'column', width: "40%", marginLeft: idx % 2 > 0 ? "5%" : 0 }}>
											<View style={pdfStyles.tableListItem}>
					      						<Text style={pdfStyles.tableTitle}>{entry[0]}</Text>
					      					</View>
					      					{entry[1].map((component) => 
					      						<View style={pdfStyles.tableListItem}>
					      							<Text style={pdfStyles.smalldatapoint}>{'chemical_component' in component ? component.chemical_component.name : component.acid_component.name}</Text>
					      							<Text style={pdfStyles.smalldatapoint}>{component.amount}%</Text>
					      						</View>
					      					)}
					      				</View>
									)}
								</View>
								{footer}
					      	</View>
						}
				      	{footer}
			      	</View>
			    </Page>
			}
		</Document>
	)
}

@observer
class NoListItems extends Component{
	render(){
		return (
			<div className="col-span-2">
				<div className="flex justify-center mt-2 mb-2">
					<SmallBody>{this.props.text}</SmallBody>
				</div>
			</div>
		)
	}
}

@observer
class ChemicalComponentEntry extends Component{
	constructor(props){
		super(props);
		this.toggleMouseEnter = this.toggleMouseEnter.bind(this);
		this.toggleMouseLeave = this.toggleMouseLeave.bind(this);
		this.state = {
			hovering: false
		}
	}

	toggleMouseEnter(){
		this.setState({
			hovering: true
		})
	}

	toggleMouseLeave(){
		this.setState({
			hovering: false
		})
	}

	render(){
		let entry = this.props.entry;
		let bio = null;
		if('chemical_component' in entry){
			if(entry.chemical_component.bio != null && entry.chemical_component.bio.length > 0){
				bio = entry.chemical_component.bio;
			}
		}

		if('acid_component' in entry){
			if(entry.acid_component.bio != null && entry.acid_component.bio.length > 0){
				bio = entry.acid_component.bio;
			}
		}
		let name = 'chemical_component' in entry ? entry.chemical_component.name : entry.acid_component.name;
		return (
			<div className="relative flex w-full flex-row justify-between border-b border-black py-1" onMouseOver={this.toggleMouseEnter} onMouseOut={this.toggleMouseLeave}>
				<div className="flex flex-col">
					<SmallBody>{name}</SmallBody>
				</div>
				<div className="flex justify-end flex-col">
					<SmallBody>{`${entry.amount}%`}</SmallBody>
				</div>
				{this.state.hovering && bio != null &&
					<div className="absolute flex flex-col z-50 top-0 left-0 mt-4 ml-16 mx-auto border border-black-10 px-2 py-2 bg-hover-bg w-48 h-auto shadow-lg rounded-lg">
						<div className="flex"><SmallBody className="bold-font text-black">{name}</SmallBody></div>
						<div className="flex"><SmallBody className="text-black">{bio}</SmallBody></div>
					</div>
				}
			</div>
		)
	}
}

@observer
class ChemicalComponentHeader extends Component{
	render(){
		return (
			<div className="flex flex-col border-b border-black">
				<SmallBody className="bold-font">{this.props.header}</SmallBody>
			</div>
		)
	}
}

@inject('manageIngredientStore')
@observer
class ManageIngredient extends Component{
	constructor(props){
		super(props);
		this.chemicalFamilyPieChartRef = React.createRef();
		this.acidFamilyPieChartRef = React.createRef();
		this.onSelectComponent = this.onSelectComponent.bind(this);
		this.onSelectAcidComponent = this.onSelectAcidComponent.bind(this);
		this.renderPieChartToImage = this.renderPieChartToImage.bind(this);
		this.renderIngredientImageToImage = this.renderIngredientImageToImage.bind(this);
		this.renderAcidPieChartToImage = this.renderAcidPieChartToImage.bind(this);
		this.onViewTutorial = this.onViewTutorial.bind(this);
		this.markChange = this.markChange.bind(this);
		this.triggerPDFAction = this.triggerPDFAction.bind(this);
		this.handleModalAction = this.handleModalAction.bind(this);
		this.getRenderedIngredientPDF = this.getRenderedIngredientPDF.bind(this);
		this.intervalId = null;
	}

	componentDidMount(){
		let manageIngredientStore = this.props.manageIngredientStore;
		manageIngredientStore.fetchScores();
		manageIngredientStore.fetchChemicalFamilies();
		manageIngredientStore.fetchAcidFamilies();
		manageIngredientStore.fetchIngredientTypes();
		manageIngredientStore.fetchConservationStatuses();
		manageIngredientStore.fetchScentNotes();
		manageIngredientStore.fetchPsychologicalRankings();
		manageIngredientStore.fetchPhysicalRankings();
		manageIngredientStore.setupPDFOptions();

		if(this.props.ingredientId){
			manageIngredientStore.formSync(this.props.ingredientId, this.props.editMode);
		}else{
			manageIngredientStore.loadComplete();
		}

		this.intervalId = setInterval(() => {
			this.renderPieChartToImage();
			this.renderAcidPieChartToImage();
			this.renderIngredientImageToImage();
		}, 5000);
	}

	componentWillUnmount(){
		let manageIngredientStore = this.props.manageIngredientStore;
		manageIngredientStore.initStore();

		clearInterval(this.intervalId);
	}

	getRenderedIngredientPDF(){
		let manageIngredientStore = this.props.manageIngredientStore;
		let viewMode = manageIngredientStore.viewMode;
		let manageMode = manageIngredientStore.editMode || manageIngredientStore.addMode;
		let translate = this.props.t;
		let currentIngredient = manageIngredientStore.currentIngredient;
		let energeticsHtml = null;
		if(viewMode){
			energeticsHtml = currentIngredient.energetics != null && currentIngredient.energetics.length > 0 ? parseAndSanitizeHtml(currentIngredient.energetics) : null;
		}

		let synergyHtml = null;
		let showSynergy = (viewMode || (manageMode && !manageIngredientStore.appStore.isSuperAdministrator));
		if(showSynergy){
			synergyHtml = currentIngredient != null && currentIngredient.synergy != null && currentIngredient.synergy.length > 0 ? parseAndSanitizeHtml(currentIngredient.synergy) : null;
		}

		return (
				<IngredientPDF 
					logoSrc={logoPinkSrc}
					ingredientSrc={manageIngredientStore.ingredientImageBase64Url}
					nameTitle={translate('user.manage-ingredient.name.label')}
					name={currentIngredient?.name}
					latinNameTitle={translate('user.manage-ingredient.latin-name.label')}
					latinName={currentIngredient?.latin_name}
					originTitle={translate('user.manage-ingredient.country-of-origin.label')}
					origin={currentIngredient?.origin}
					chartSrc={manageIngredientStore.renderedPieChartBase64Url}
					acidChartSrc={manageIngredientStore.renderedAcidPieChartBase64Url}
					chemicalFamilyBreakdownTitle={translate('user.manage-ingredient.chemical-families.title')}
					chemicalFamilyBreakdown={manageIngredientStore.chemicalFamilyPieData}
					acidFamilyBreakdownTitle={translate('user.manage-ingredient.acid-families.title')}
					acidFamilyBreakdown={manageIngredientStore.acidFamilyPieData}
					ingredientTypeTitle={translate('user.manage-ingredient.type.label')}
					ingredientType={currentIngredient?.ingredient_type.name}
					benchmarkPricePer15mlTitle={translate('user.manage-ingredient.benchmark-price-15ml.label')}
					benchmarkPricePer15ml={currentIngredient?.benchmark_price_per_15ml}
					botanicalFamilyTitle={translate('user.manage-ingredient.botanical-family.label')}
					botancialFamily={currentIngredient?.botanical_family}
					partOfPlantTitle={translate('user.manage-ingredient.part-of-plant.label')}
					partOfPlant={currentIngredient?.part_of_plant}
					safetyNoteTitle={translate('user.manage-ingredient.safety-note.label')}
					safetyNote={currentIngredient?.safety_note}
					conservationStatusTitle={translate('user.manage-ingredient.conservation-status.label')}
					conservationStatus={currentIngredient?.conservation_status?.name}
					conservationStatusDescription={currentIngredient?.conservation_status?.description}
					profileTitle={translate('user.manage-ingredient.profile.label')}
					profile={currentIngredient?.profile}
					therapeuticPropertiesTitle={translate('user.manage-ingredient.therapeutic-properties.label')}
					therapeuticProperties={currentIngredient?.therapeutic_properties}
					comedogenicScaleTitle={translate('user.manage-ingredient.comedogenic-scale.label')}
					comedogenicScale={currentIngredient?.comedogenic_scale}
					formulationGuidanceTitle={translate('user.manage-ingredient.formulation-guidance.label')}
					formulationGuidance={currentIngredient?.formulation_guidance}
					synergyTitle={translate('user.manage-ingredient.synergy.label')}
					synergy={synergyHtml}
					energeticsTitle={translate('user.manage-ingredient.energetics.label')}
					energetics={energeticsHtml}
					showEnergetics={manageIngredientStore.showEnergetics}
					isHomeUser={manageIngredientStore.isHomeUser}
					showComedogenicScale={manageIngredientStore.showComedogenicScale}
					showFormulationGuidance={manageIngredientStore.showFormulationGuidance}
					scentNoteTitle={translate('user.manage-ingredient.scent-notes.label')}
					scentNote={currentIngredient?.scent_note?.name}
					chemicalComponentBreakdownTitle={translate('user.manage-ingredient.chemical-breakdown.title')}
					chemicalComponentBreakdownByFamily={manageIngredientStore.chemicalComponentBreakdownByFamily}
					acidComponentBreakdownTitle={translate('user.manage-ingredient.acid-breakdown.title')}
					acidComponentBreakdownByFamily={manageIngredientStore.acidComponentBreakdownByFamily}
					showFattyAcidBreakdown={manageIngredientStore.showFattyAcidBreakdown}
					ingredientNotesTitle={translate('user.manage-ingredient.ingredient-notes.label')}
					ingredientNotes={currentIngredient?.ingredient_notes}
					psychologicalRankingsTitle={translate('user.manage-ingredient.psychological-rankings.label')}
					psychologicalRankings={currentIngredient?.psychological_rankings}
					physicalRankingsTitle={translate('user.manage-ingredient.physical-rankings.label')}
					physicalRankings={currentIngredient?.physical_rankings}
					showProfile={manageIngredientStore.showProfile}
					showSynergy={manageIngredientStore.showSynergy}
					showTherapeuticProperties={manageIngredientStore.showTherapeuticProperties}
					showEnergeticsDownload={manageIngredientStore.showEnergeticsDownload}
					showChemicalBreakdown={manageIngredientStore.showChemicalBreakdown}
					showScentNotes={manageIngredientStore.showScentNotes}
					showEffects={manageIngredientStore.showEffects}
					showNotes={manageIngredientStore.showNotes}
				/>
			)
	}

	renderPieChartToImage(){
		let manageIngredientStore = this.props.manageIngredientStore;

		if(this.chemicalFamilyPieChartRef != null && this.chemicalFamilyPieChartRef.chartRef != null && this.chemicalFamilyPieChartRef.chartRef.children != null){
			let svgHtml = this.chemicalFamilyPieChartRef.chartRef.children[0];
			htmlToImage.toPng(svgHtml)
		  		.then((dataUrl) => {
		  			manageIngredientStore.setRenderedPieChartUrl(dataUrl);
		  		})
		  		.catch((error) => {
		  			console.log(error);
		  		})
		}
	}

	renderAcidPieChartToImage(){
		let manageIngredientStore = this.props.manageIngredientStore;

		if(this.acidFamilyPieChartRef != null && this.acidFamilyPieChartRef.chartRef != null && this.acidFamilyPieChartRef.chartRef.children != null){
			let svgHtml = this.acidFamilyPieChartRef.chartRef.children[0];
			htmlToImage.toPng(svgHtml)
		  		.then((dataUrl) => {
		  			manageIngredientStore.setRenderedAcidPieChartUrl(dataUrl);
		  		})
		  		.catch((error) => {
		  			console.log(error);
		  		})
		}
	}

	renderIngredientImageToImage(){
		let manageIngredientStore = this.props.manageIngredientStore;

		if(this.ingredientImageRef != null && this.ingredientImageRef.children != null){
			let svgHtml = this.ingredientImageRef.children[0];
			htmlToImage.toPng(svgHtml)
		  		.then((dataUrl) => {
		  			manageIngredientStore.setIngredientImageUrl(dataUrl);
		  		})
		  		.catch((error) => {
		  			console.log(error);
		  		})
		}
	}

	onSelectComponent(id){
		let manageIngredientStore = this.props.manageIngredientStore;
		manageIngredientStore.onChooseComponent(id);
	}

	onSelectAcidComponent(id){
		let manageIngredientStore = this.props.manageIngredientStore;
		manageIngredientStore.onChooseAcidComponent(id);
	}

	markChange(){
		let manageIngredientStore = this.props.manageIngredientStore;
		manageIngredientStore.markChange();
	}

	onViewTutorial(){
		let url = null;
		let manageIngredientStore = this.props.manageIngredientStore;
		if(manageIngredientStore.addMode){
			url = 'http://help.labaroma.com/en/articles/4745485-13-add-your-own-ingredient';
		}else{
			url = 'http://help.labaroma.com/en/articles/4745421-7-ingredient-tutorial';
		}
		window.open(url, '_blank');
	}

	triggerPDFAction = async () => {
		let manageIngredientStore = this.props.manageIngredientStore;
		if(!manageIngredientStore.canDownloadPDF) return;
		manageIngredientStore.togglePDFGenerating();
		const myIngredientPDF = pdf({});
		myIngredientPDF.updateContainer(this.getRenderedIngredientPDF());
		const blob = await myIngredientPDF.toBlob();
		
		let blobUrl = URL.createObjectURL(blob);
		if(manageIngredientStore.currentAction == 'download'){
			const link = document.createElement('a');
	      	link.href = blobUrl;
			link.setAttribute(
				'download',
				manageIngredientStore.ingredientPDFName
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);

			manageIngredientStore.showDownloadSaveDialog();
		}else{
			 printJS(blobUrl);
		}
		manageIngredientStore.resetPDFModal();
	}

	handleModalAction(action){
		let manageIngredientStore = this.props.manageIngredientStore;
		manageIngredientStore.showPDFDownload(action);
	}

	render(){
		let translate = this.props.t;
		let manageIngredientStore = this.props.manageIngredientStore;

		if(!manageIngredientStore.loaded) return null;
		let viewMode = manageIngredientStore.viewMode;
		let manageMode = manageIngredientStore.editMode || manageIngredientStore.addMode;

		let title = translate('user.manage-ingredient.add-title');
		if(viewMode){
			title = translate('user.manage-ingredient.view-title');
		}
		if(manageIngredientStore.editMode){
			title = translate('user.manage-ingredient.edit-title');
		}

		let currentIngredient = manageIngredientStore.currentIngredient;
		let currentChemicalFamily = manageIngredientStore.currentChemicalFamily;
		let currentAcidFamily = manageIngredientStore.currentAcidFamily;

		let newInformationSoon = (
			<div className="flex w-full justify-center mt-4">
				<SmallBody>{translate('common.information-soon')}</SmallBody>
			</div>
		);
		let energeticsHtml = null;
		if(viewMode){
			energeticsHtml = currentIngredient.energetics != null && currentIngredient.energetics.length > 0 ? parseAndSanitizeHtml(currentIngredient.energetics) : null;
		}

		let synergyHtml = null;
		let showSynergy = (viewMode || (manageMode && !manageIngredientStore.appStore.isSuperAdministrator));
		if(showSynergy){
			synergyHtml = currentIngredient != null && currentIngredient.synergy != null && currentIngredient.synergy.length > 0 ? parseAndSanitizeHtml(currentIngredient.synergy) : null;
		}

		let saveBtn = (
			<Button
				width="w-auto"
				height="h-12"
				className="px-4 rounded-lg"
				bgColor="pink-coral"
				fetching={manageIngredientStore.fetching}
				onClick={() => manageIngredientStore.save()}
			>
				<div className="flex flex-row items-center justify-center">
					<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-ingredient.save-button')} &nbsp;</TertiaryAppSmallTitle></div>
					<div className="flex"><SaveIcon className="w-4 h-4" color="white" /></div>
				</div>
			</Button>
		)

		let renderedIngredientPDF = null;
		if(manageIngredientStore.canDownloadPDF){
			renderedIngredientPDF = this.getRenderedIngredientPDF();
		}

		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<BaseModal
						autoHeight
			            isOpen={manageIngredientStore.showPDFDownloadModal}
			            onClose={() => manageIngredientStore.toggleShowPDFDownload()}
			            label="PDF Download Modal"
			        >
			        	<div className="flex w-full h-auto py-16 px-24 flex-col overflow-scroll bg-white rounded-lg">
			        		<div className="flex flex-row justify-center">
			        			<SecondaryAppMediumTitle className="text-nav-active-color">{translate('user.manage-ingredient.pdf-modal-details.title')}</SecondaryAppMediumTitle>
			        		</div>
			        		{manageIngredientStore.currentPDFDownloadOptions.map((downloadOption) =>
			        			<div className="flex flex-row justify-between items-center mt-2">
			        				
			        				<div className="flex">
			        					<TertiaryAppSmallTitle>{downloadOption.label}</TertiaryAppSmallTitle>
			        				</div>
			        				<CheckBox 
										extraInputClassNames="h-5 w-5 border border-black text-pink-coral border-radius-50"
										checked={downloadOption.checked}
										onChange={(e) => manageIngredientStore.onChangeDownloadOption(downloadOption.id, e.target.checked)}
										 />
			        			</div>
			        		)}

			        		{manageIngredientStore.currentPDFDownloadOptions.some((p) => p.checked) && 
			        			<div className="flex justify-center flex-row items-center mt-8">
									<Button
										width="w-full"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="nav-active-color"
										disabled={manageIngredientStore.pdfGenerating}
										onClick={this.triggerPDFAction}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{manageIngredientStore.pdfGenerating ? translate('common.loading') : translate('user.manage-ingredient.save-pdf-settings')} &nbsp;</TertiaryAppSmallTitle></div>
										</div>
									</Button>
								</div>
							}
			        	</div>
			        </BaseModal>


					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{title}</SecondaryAppLargeTitle>
							<SmallBody>{translate('user.manage-ingredient.add-paragraph.part1')} <br /> {translate('user.manage-ingredient.add-paragraph.part2')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8 cursor-pointer" onClick={this.onViewTutorial}>
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('user.manage-ingredient.ingredient-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
						<div className="flex hidden lg:block ml-4">
							<EditIngredientIcon className="w-32 h-32" />
						</div>
					</div>
					<BaseModal
			            isOpen={manageIngredientStore.showChemicalFamilyModal}
			            onClose={() => manageIngredientStore.hideFamilyModal()}
			            label="Chemical Family Overview"
			        >
			        	{currentChemicalFamily != null && 
			        		<ChemicalFamilyDialog 
				        		chemicalFamily={currentChemicalFamily}
				        		currentChemicalFamilyComponents={manageIngredientStore.currentChemicalFamilyComponents}
				        	/>
				        }
			        </BaseModal>
			        <BaseModal
			            isOpen={manageIngredientStore.showAcidFamilyModal}
			            onClose={() => manageIngredientStore.hideAcidFamilyModal()}
			            label="Acid Family Overview"
			        >
			        	{currentAcidFamily != null && 
			        		<ChemicalFamilyDialog 
				        		chemicalFamily={currentAcidFamily}
				        		currentChemicalFamilyComponents={manageIngredientStore.currentAcidFamilyComponents}
				        	/>
				        }
			        </BaseModal>
					<div className="flex w-full justify-end mt-4">
						{viewMode && 
							<div className="flex flex-row items-center">
								{!currentIngredient?.favourited && 
									<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="dark-green-60"
										fetching={manageIngredientStore.fetching}
										onClick={() => manageIngredientStore.toggleFavouriteIngredient()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-ingredient.favourite-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><StarIcon fill="white" className="w-4 h-4" color="white" /></div>
										</div>
									</Button>
								}
								{currentIngredient.favourited && 
									<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="list-text"
										fetching={manageIngredientStore.fetching}
										onClick={() => manageIngredientStore.toggleFavouriteIngredient()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-ingredient.unfavourite-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><StarIcon className="w-4 h-4" color="white" /></div>
										</div>
									</Button>
								}
								{!manageIngredientStore.isHomeUser &&
									<Button
										width="w-auto"
										height="h-12"
										className="ml-4 px-4 rounded-lg"
										bgColor="pink-coral"
										fetching={manageIngredientStore.fetching}
										onClick={() => manageIngredientStore.compareIngredients()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-ingredient.compare-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><CompareIngredients id={2} className="w-4 h-4" color="white" /></div>
										</div>
									</Button>
								}
								
								{manageIngredientStore.hasEditAccessToIngredient && 
									<>
										<Button
											width="w-auto"
											height="h-12"
											className="ml-4 px-4 rounded-lg"
											bgColor="pink-coral"
											fetching={manageIngredientStore.fetching}
											onClick={() => manageIngredientStore.toggleEditMode()}
										>
											<div className="flex flex-row items-center justify-center">
												<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-ingredient.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
												<div className="flex"><EditIcon className="w-4 h-4" color="white" /></div>
											</div>
										</Button>
									</>
								}
								<Button
									width="w-auto"
									height="h-12"
									className="ml-4 px-4 rounded-lg"
									bgColor="pink-coral"
									fetching={manageIngredientStore.fetching}
									onClick={() => manageIngredientStore.duplicateIngredient()}
								>
									<div className="flex flex-row items-center justify-center">
										<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-ingredient.duplicate-button')} &nbsp;</TertiaryAppSmallTitle></div>
										<div className="flex"><DuplicateIcon className="w-4 h-4" color="white" /></div>
									</div>
								</Button>
							</div>
						}
						{manageMode && 
							<>
								{!manageIngredientStore.addMode && 
									<Button
										width="w-auto"
										height="h-12"
										className="ml-4 px-4 rounded-lg text-pink-coral"
										bgColor="bg-list-text"
										fetching={manageIngredientStore.fetching}
										onClick={() => manageIngredientStore.cancelEditMode()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-ingredient.cancel-button')} &nbsp;</TertiaryAppSmallTitle></div>
										</div>
									</Button>
								}
								{saveBtn}
							</>
						}
					</div>
					<div className="flex flex-1 grid grid-cols-6 gap-4 mt-8">
						<div className="col-span-6 lg:col-span-2">
							<GridSection>
								<div className="flex flex-1 flex-col">
									<div className="flex flex-1 justify-center mt-2 mb-2" ref={(ref) => { this.ingredientImageRef = ref; }}>
										<ImageAvatar 
											disabled={viewMode}
											backupAvatarUrl={manageIngredientStore.getAvatarUrl(currentIngredient)}
											t={translate}
											noborder
											url={viewMode ? currentIngredient?.photo_url : manageIngredientStore.ingredientData.currentImageUrl.value}
											onChangeFile={(file) => {
												this.markChange();
												manageIngredientStore.onChangeAvatar(file)
											}} />
									</div>
									<DataRow label={translate('user.manage-ingredient.name.label')}>
										{viewMode && 
											<div className="flex flex-col">
												<div className="flex"><DataEntry>{currentIngredient?.name}</DataEntry></div>
												
											</div>
										}
										{manageMode && 
											<FormInput 
												placeholder={translate('user.manage-ingredient.name.placeholder')}
												value={manageIngredientStore.ingredientData.name.value}
												error={manageIngredientStore.ingredientData.name.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.name.onChange(e.target.value)
												}}
											/>
										}
									</DataRow>
									
									<DataRow label={translate('user.manage-ingredient.latin-name.label')}>
										{viewMode && 
											<div className="flex"><DataEntry><span className="italic">{currentIngredient?.latin_name}</span></DataEntry></div>
										}
										{manageMode && 
											<FormInput 
												extraInputClassNames="italic"
												placeholder={translate('user.manage-ingredient.latin-name.placeholder')}
												value={manageIngredientStore.ingredientData.latinName.value}
												error={manageIngredientStore.ingredientData.latinName.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.latinName.onChange(e.target.value)
												}}
											/>
										}
									</DataRow>
									<DataRow label={translate('user.manage-ingredient.country-of-origin.label')}>
										{manageMode && 
											<FormInput 
												placeholder={translate('user.manage-ingredient.country-of-origin.placeholder')}
												value={manageIngredientStore.ingredientData.origin.value}
												error={manageIngredientStore.ingredientData.origin.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.origin.onChange(e.target.value)
												}}
											/>
										}
										{viewMode && 
											<DataEntry>{currentIngredient.origin}</DataEntry>
										}
									</DataRow>
									<DataRow label={translate('user.manage-ingredient.type.label')}>
										{manageMode && 
											<FormSelect 
												placeholder={translate('user.manage-ingredient.type.placeholder')}
												options={manageIngredientStore.ingredientTypeOptions}
												value={manageIngredientStore.ingredientData.selectedIngredientType.value}
												error={manageIngredientStore.ingredientData.selectedIngredientType.error}
												onChange={(option) => {
													this.markChange();
													manageIngredientStore.ingredientData.selectedIngredientType.onChange(option.value)
												}}
											/>
										}
										{viewMode && 
											<DataEntry>{currentIngredient.ingredient_type?.name}</DataEntry>
										}
									</DataRow>
									<DataRow label={translate('user.manage-ingredient.benchmark-price-15ml.label')}>
										{manageMode &&
											<FormInput 
												placeholder={translate('user.manage-ingredient.benchmark-price-15ml.placeholder')}
												value={manageIngredientStore.ingredientData.benchmarkPrice.value}
												error={manageIngredientStore.ingredientData.benchmarkPrice.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.benchmarkPrice.onChange(e.target.value)
												}}
											/>
										}
										{viewMode && 
											<DataEntry>{currentIngredient.benchmark_price_per_15ml != null && `$${parseFloat(currentIngredient.benchmark_price_per_15ml).toFixed(2)}`}</DataEntry>
										}
									</DataRow>
									{manageIngredientStore.appStore.isSuperAdministrator &&
										<DataRow label={translate('user.manage-ingredient.legacy-item-code.label')}>
											{manageMode &&
												<FormInput 
													placeholder={translate('user.manage-ingredient.legacy-item-code.placeholder')}
													value={manageIngredientStore.ingredientData.legacyItemCode.value}
													error={manageIngredientStore.ingredientData.legacyItemCode.error}
													onChange={(e) => {
														this.markChange();
														manageIngredientStore.ingredientData.legacyItemCode.onChange(e.target.value)
													}}
												/>
											}
											{viewMode && 
												<DataEntry>{currentIngredient.legacy_item_code}</DataEntry>
											}
										</DataRow>
									}
								</div>
							</GridSection>
						</div>
						<div className="col-span-6 lg:col-span-2">
							<GridSection>
								<div className="flex w-full flex-col">
									<DataRow label={translate('user.manage-ingredient.botanical-family.label')}>
										{manageMode && 
											<FormInput 
												placeholder={translate('user.manage-ingredient.botanical-family.placeholder')}
												value={manageIngredientStore.ingredientData.botanicalFamily.value}
												error={manageIngredientStore.ingredientData.botanicalFamily.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.botanicalFamily.onChange(e.target.value)
												}}
											/>
										}
										{viewMode && 
											<DataEntry>{currentIngredient.botanical_family}</DataEntry>
										}
									</DataRow>
									<DataRow label={translate('user.manage-ingredient.part-of-plant.label')}>
										{manageMode && 
											<FormInput 
												placeholder={translate('user.manage-ingredient.part-of-plant.placeholder')}
												value={manageIngredientStore.ingredientData.partOfPlant.value}
												error={manageIngredientStore.ingredientData.partOfPlant.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.partOfPlant.onChange(e.target.value)
												}}
											/>
										}
										{viewMode && 
											<DataEntry>{currentIngredient.part_of_plant}</DataEntry>
										}
									</DataRow>
									<DataRow label={translate('user.manage-ingredient.safety-note.label')}>
										{manageMode &&
											<FormTextArea 
												placeholder={translate('user.manage-ingredient.safety-note.placeholder')}
												value={manageIngredientStore.ingredientData.safetyNote.value}
												error={manageIngredientStore.ingredientData.safetyNote.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.safetyNote.onChange(e.target.value)
												}}
											/>
										}
										{viewMode && 
											<DataEntry>{currentIngredient.safety_note}</DataEntry>
										}
									</DataRow>
									
									{manageMode && 
										<DataRow label={translate('user.manage-ingredient.conservation-status.label')}>
											<div className="flex flex-col w-full">
												<div className="flex flex-col">
													<FormSelect 
														placeholder={translate('user.manage-ingredient.conservation-status.placeholder')}
														options={manageIngredientStore.conservationStatusOptions}
														value={manageIngredientStore.ingredientData.selectedConservationStatus.value}
														error={manageIngredientStore.ingredientData.selectedConservationStatus.error}
														onChange={(option) => {
															this.markChange();
															manageIngredientStore.ingredientData.selectedConservationStatus.onChange(option?.value)
														}}
													/>
												</div>
												{manageIngredientStore.currentConservationStatusDescription != null && 
													<div className="flex flex-col mt-1">
														<DataEntry>{manageIngredientStore.currentConservationStatusDescription}</DataEntry>
													</div>	
												}
											</div>
										</DataRow>
									}
									{viewMode && 
										<DataRow label={translate('user.manage-ingredient.conservation-status.label')}>
											<div className="flex flex-col w-full">
												<DataEntry>{currentIngredient.conservation_status?.name}</DataEntry>
												{currentIngredient.conservation_status?.description != null && 
													<DataEntry>{currentIngredient.conservation_status?.description}</DataEntry>
												}
											</div>
										</DataRow>
									}

									<DataRow label={translate('user.manage-ingredient.profile.label')}>
										{manageMode &&
											<FormTextArea 
												placeholder={translate('user.manage-ingredient.profile.placeholder')}
												value={manageIngredientStore.ingredientData.profile.value}
												error={manageIngredientStore.ingredientData.profile.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.profile.onChange(e.target.value)
												}}
											/>
										}
										{viewMode && 
											<DataEntry>{currentIngredient.profile}</DataEntry>
										}
									</DataRow>
								</div>
							</GridSection>
						</div>
						<div className="col-span-6 lg:col-span-2 relative">
							<GridSection>

								{manageIngredientStore.viewMode && 
									<div className="absolute top-0 right-0 mr-4 mt-4 cursor-pointer">
										<DownloadPDF 
											t={translate}
											showModal={true}
											isJapanese={manageIngredientStore.appStore.isJapaneseUser}
											onShowModal={this.handleModalAction}
											document={manageIngredientStore.canDownloadPDF ? renderedIngredientPDF : null}
											filename={manageIngredientStore.ingredientPDFName}
											onDownload={() => console.log()}
											onPrint={() => {}}
										/>
									</div>
								}
								<div className="flex flex-1 flex-col">
									<DataRow label={translate('user.manage-ingredient.therapeutic-properties.label')} className="flex flex-1">
										{manageMode &&
											<FormTextArea
												extraInputClassNames="h-full" 
												placeholder={translate('user.manage-ingredient.therapeutic-properties.placeholder')}
												value={manageIngredientStore.ingredientData.therapeuticProperties.value}
												error={manageIngredientStore.ingredientData.therapeuticProperties.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.therapeuticProperties.onChange(e.target.value)
												}}
											/>
										}
										{viewMode && 
											<DataEntry>{currentIngredient.therapeutic_properties}</DataEntry>
										}
									</DataRow>
									{manageIngredientStore.showComedogenicScale && 
										<DataRow label={translate('user.manage-ingredient.comedogenic-scale.label')} className="flex flex-1">
											{manageMode &&
												<FormTextArea
													extraInputClassNames="h-full" 
													placeholder={translate('user.manage-ingredient.comedogenic-scale.placeholder')}
													value={manageIngredientStore.ingredientData.comedogenicScale.value}
													error={manageIngredientStore.ingredientData.comedogenicScale.error}
													onChange={(e) => {
														this.markChange();
														manageIngredientStore.ingredientData.comedogenicScale.onChange(e.target.value)
													}}
												/>
											}
											{viewMode && 
												<DataEntry>{currentIngredient.comedogenic_scale}</DataEntry>
											}
										</DataRow>
									}
								</div>
							</GridSection>
						</div>
						<div className="col-span-6 lg:col-span-2">
							<GridSection>
								<div className="flex flex-1 flex-col">
									<DataRow label={translate('user.manage-ingredient.synergy.label')} 
											tooltip={<HelpTooltip text={manageIngredientStore.appStore.getTooltip('tooltip-synergy')} />}
										>
										{manageMode && manageIngredientStore.appStore.isSuperAdministrator &&
											<FormTextArea 
												disabled={!manageIngredientStore.appStore.isSuperAdministrator}
												extraInputClassNames="h-50vh" 
												placeholder={translate('user.manage-ingredient.synergy.placeholder')}
												value={manageIngredientStore.ingredientData.synergy.value}
												error={manageIngredientStore.ingredientData.synergy.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.synergy.onChange(e.target.value)
												}}
											/>
										}
										{showSynergy && synergyHtml != null && <DataEntry>{synergyHtml}</DataEntry>}
										{showSynergy && synergyHtml == null && newInformationSoon}
									</DataRow>
								</div>
							</GridSection>
						</div>
						{!manageIngredientStore.isHomeUser && 
							<div className="col-span-6 lg:col-span-2">
								<GridSection>
									<div className="flex flex-col">
										<DataRow label={translate('user.manage-ingredient.evidence.label')} tooltip={<HelpTooltip text={manageIngredientStore.appStore.getTooltip('tooltip-evidence')} />}>
											<div className="flex w-full overflow-x-scroll flex-col">
												{manageIngredientStore.linkedEvidence.map((evidence) => 
													<div key={evidence.id} className="flex flex-row justify-between items-center w-full  rounded-lg border py-4 px-4 mt-4 cursor-pointer" onClick={() => manageIngredientStore.viewEvidence(evidence.id)}>
														<div className="flex w-full overflow-x-scroll bg-white flex-col">
															<div className="flex"><TertiaryAppSmallTitle>{evidence.title}</TertiaryAppSmallTitle></div>
															<div className="flex"><SmallBody className="italic">{evidence.author}</SmallBody></div>
															<div className="flex mt-1 flex-row">
																{evidence.tags.map((tag) => 
																	<Tag key={tag.id} name={tag.name} />
																)}
															</div>
														</div>
														<div className="flex"><EvidenceIcon className="w-8 h-8" color={colors['black']} /></div>
													</div>
												)}
												{manageIngredientStore.linkedEvidence.length == 0 && 
													<div className="flex w-full justify-center mt-4">
														<SmallBody>{translate('user.manage-ingredient.no-evidence-found')}</SmallBody>
													</div>
												}
											</div>
										</DataRow>
									</div>
								</GridSection>
							</div>
						}
						{manageIngredientStore.showEnergetics && 
							<div className="col-span-6 lg:col-span-2">
								<GridSection>
									<div className="flex flex-1 flex-col">
										<DataRow label={translate('user.manage-ingredient.energetics.label')} tooltip={<HelpTooltip text={manageIngredientStore.appStore.getTooltip('tooltip-energetics')} />}>
											{manageMode && 
												<FormTextArea 
													extraInputClassNames="h-50vh" 
													placeholder={translate('user.manage-ingredient.energetics.placeholder')}
													value={manageIngredientStore.ingredientData.energetics.value}
													error={manageIngredientStore.ingredientData.energetics.error}
													onChange={(e) => {
														this.markChange();
														manageIngredientStore.ingredientData.energetics.onChange(e.target.value)
													}}
												/>
											}
											{viewMode && energeticsHtml != null && <DataEntry>{energeticsHtml}</DataEntry>}
											{viewMode && energeticsHtml == null && newInformationSoon}
										</DataRow>
									</div>
								</GridSection>
							</div>
						}
						{manageIngredientStore.showFormulationGuidance && 
							<div className="col-span-6 lg:col-span-2">
								<GridSection>
									<div className="flex flex-1 flex-col">
										<DataHeader noborder>{translate('user.manage-ingredient.formulation-guidance.label')}</DataHeader>
										{manageMode && 
											<FormTextArea 
												extraInputClassNames="h-full" 
												placeholder={translate('user.manage-ingredient.formulation-guidance.placeholder')}
												value={manageIngredientStore.ingredientData.formulationGuidance.value}
												error={manageIngredientStore.ingredientData.formulationGuidance.error}
												onChange={(e) => {
													this.markChange();
													manageIngredientStore.ingredientData.formulationGuidance.onChange(e.target.value)
												}}
											/>
										}
										{viewMode && 
											<DataEntry>{currentIngredient.formulation_guidance}</DataEntry>
										}
									</div>
								</GridSection>
							</div>
						}
						{manageMode && !manageIngredientStore.isHomeUser && 
							<>
								{manageIngredientStore.isEssentialOil && 
									<div className="col-span-6">
										<GridSection>
											<SecondaryWebSmallTitle>{translate('user.manage-ingredient.chemical-breakdown.title')}</SecondaryWebSmallTitle>
											<div className="flex flex-1 flex-col lg:flex-row">
												<div className="flex flex-1 flex-col">
													<div className="grid grid-cols-2 gap-4 mt-2">
														<DataHeader noborder>{translate('user.manage-ingredient.chemical-breakdown.add-chemical-component-title')}</DataHeader>
														<DataHeader noborder>{translate('user.manage-ingredient.chemical-breakdown.add-chemical-component-amount')}</DataHeader>
														<div className="flex flex-col">
															<FormSearchSelect
																placeholder={translate('user.manage-ingredient.chemical-breakdown.search-chemical-component')}
																value={manageIngredientStore.componentSearchText}
																hasSelection={manageIngredientStore.selectedComponent != null}
																onChange={(val) => {
																	this.markChange();
																	manageIngredientStore.onChangeComponentSearchText(val)
																}}
																onClear={() => manageIngredientStore.onClearComponentSelection()}
																options={manageIngredientStore.searchComponentOptions}
																onSelection={this.onSelectComponent}
															 />
														</div>
														<div className="flex flex-row justify-start items-start">
															<div className="flex flex-row w-full items-center">
																<div className="flex w-2/3">
																	<FormInput 
																		insetoption={<SmallBody className="text-list-text">%</SmallBody>}
																		value={manageIngredientStore.addAmount.value}
																		error={manageIngredientStore.addAmount.error}
																		onChange={(e) => {
																			this.markChange();
																			manageIngredientStore.addAmount.value = e.target.value
																		}}
																	/>
																</div>
																<div className="flex ml-4">
																	<ApplyText 
																		t={translate}
																		onClick={() => manageIngredientStore.onAddComponent()}
																		 />
																</div>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col"> 
														{manageIngredientStore.editIngredientChemicalComponents.map((chemicalComponent) => 
															<div className="flex flex-1 flex-row items-center"> 
																<div className="flex flex-1">
																	<FormSelect 
																		placeholder={translate('user.manage-ingredient.chemical-components.effect.placeholder')}
																		options={manageIngredientStore.chemicalComponentOptions}
																		value={chemicalComponent.$.chemicalComponentId.value}
																		onChange={(option) => {
																			this.markChange();
																			chemicalComponent.$.chemicalComponentId.onChange(option.value)
																		}}
																	/>
																</div>
																<div className="flex flex-1 ml-4">
																	<FormInput 
																		value={chemicalComponent.$.amount.value}
																		error={chemicalComponent.$.amount.error}
																		onChange={(e) => {
																			this.markChange();
																			chemicalComponent.$.amount.onChange(e.target.value)
																		}}
																	/>
																</div>
																<div className="flex flex-row ml-4 cursor-pointer" onClick={() => manageIngredientStore.deleteChemicalComponent(chemicalComponent.$.uuid)}>
																	<DeleteIcon className="w-4 h-4" color={colors['error']} />
																</div>
															</div>
														)}
													</div>
												</div>
												<div className="flex flex-1 flex-col ml-4">
													<DataHeader noborder>{translate('user.manage-ingredient.chemical-breakdown.family-title')}</DataHeader>
													<div className="flex flex-col mt-4">
														{manageIngredientStore.currentIngredientFamilyBreakdown.map((chemicalFamily) => 
															<div className="flex flex-row mt-4">
																<Body>{`${chemicalFamily['family'].name} - ${chemicalFamily.percentage}%`}</Body>
															</div>
														)}
													</div>
												</div>
											</div>
										</GridSection>
									</div>
								}
								{manageIngredientStore.showFattyAcidBreakdown && 
									<div className="col-span-6">
										<GridSection>
											<SecondaryWebSmallTitle>{translate('user.manage-ingredient.acid-breakdown.title')}</SecondaryWebSmallTitle>
											<div className="flex flex-1 flex-row">
												<div className="flex flex-1 flex-col">
													<div className="grid grid-cols-2 gap-4 mt-2">
														<DataHeader noborder>{translate('user.manage-ingredient.acid-breakdown.add-acid-component-title')}</DataHeader>
														<DataHeader noborder>{translate('user.manage-ingredient.acid-breakdown.add-acid-component-amount')}</DataHeader>
														<div className="flex flex-col">
															<FormSearchSelect
																placeholder={translate('user.manage-ingredient.acid-breakdown.search-acid-component')}
																value={manageIngredientStore.acidComponentSearchText}
																hasSelection={manageIngredientStore.selectedAcidComponent != null}
																onChange={(val) => {
																	this.markChange();
																	manageIngredientStore.onChangeAcidComponentSearchText(val)
																}}
																onClear={() => manageIngredientStore.onClearAcidComponentSelection()}
																options={manageIngredientStore.searchAcidComponentOptions}
																onSelection={this.onSelectAcidComponent}
															 />
														</div>
														<div className="flex flex-row justify-start items-start">
															<div className="flex flex-row w-full items-center">
																<div className="flex w-2/3">
																	<FormInput 
																		insetoption={<SmallBody className="text-list-text">%</SmallBody>}
																		value={manageIngredientStore.addAcidAmount.value}
																		error={manageIngredientStore.addAcidAmount.error}
																		onChange={(e) => {
																			this.markChange();
																			manageIngredientStore.addAcidAmount.value = e.target.value
																		}}
																	/>
																</div>
																<div className="flex ml-4">
																	<ApplyText 
																		t={translate}
																		onClick={() => manageIngredientStore.onAddAcidComponent()}
																		 />
																</div>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col"> 
														{manageIngredientStore.ingredientData.acidComponents.map((acidComponent) => 
																<div className="flex flex-1 flex-row items-center"> 
																	<div className="flex flex-1">
																		<FormSelect 
																			placeholder={translate('user.manage-ingredient.acid-components.effect.placeholder')}
																			options={manageIngredientStore.acidComponentOptions}
																			value={acidComponent.$.acidComponentId.value}
																			onChange={(option) => {
																				this.markChange();
																				acidComponent.$.acidComponentId.onChange(option.value)
																			}}
																		/>
																	</div>
																	<div className="flex flex-1 ml-4">
																		<FormInput 
																			value={acidComponent.$.amount.value}
																			error={acidComponent.$.amount.error}
																			onChange={(e) => {
																				this.markChange();
																				acidComponent.$.amount.onChange(e.target.value)
																			}}
																		/>
																	</div>
																	<div className="flex flex-row ml-4 cursor-pointer" onClick={() => manageIngredientStore.deleteAcidComponent(acidComponent.$.uuid)}>
																		<DeleteIcon className="w-4 h-4" color={colors['error']} />
																	</div>
																</div>
														)}
													</div>
												</div>
												<div className="flex flex-1 flex-col">
													<DataHeader noborder>{translate('user.manage-ingredient.acid-breakdown.family-title')}</DataHeader>
													<div className="flex flex-col mt-4">
														{manageIngredientStore.currentIngredientAcidFamilyBreakdown.map((acidFamily) => 
															<div className="flex flex-row mt-4">
																<Body>{`${acidFamily['family'].name} - ${acidFamily.percentage}%`}</Body>
															</div>
														)}
													</div>
												</div>
											</div>
										</GridSection>
									</div>
								}
						</>

						}
						{viewMode && !manageIngredientStore.isHomeUser && 
							<>
								{!manageIngredientStore.showFattyAcidBreakdown && manageIngredientStore.flattenChemicalComponentBreakdownByFamily.length > 0 &&
									<>
										<div className="col-span-6 lg:col-span-3">
											<GridSection>
												<div className="flex flex-row items-center">
													<SecondaryWebSmallTitle>{translate('user.manage-ingredient.chemical-breakdown.title')}</SecondaryWebSmallTitle>
													<HelpTooltip text={manageIngredientStore.appStore.getTooltip('tooltip-ingredient-chemical-breakdown')} />
												</div>
												
												<div className="flex flex-1 flex-col mt-4">
													{manageIngredientStore.flattenChemicalComponentBreakdownByFamily.length == 0 &&
														<div className="flex w-full justify-center items-center">
															<SmallBody>{translate('user.manage-ingredient.chemical-breakdown.nodata')}</SmallBody>
														</div>
													}
													{manageIngredientStore.flattenChemicalComponentBreakdownByFamily.slice(0, manageIngredientStore.INITIAL_BREAKDOWN_LIMIT).map((entry) => 
														<div className="flex w-full flex-col mt-2">
															{entry.type == 'title' && <ChemicalComponentHeader header={entry.name} />}
															{entry.type == 'data' && 
																<ChemicalComponentEntry entry={entry.entry} key={entry.entry.id} />
															}
														</div>
													)}
													{manageIngredientStore.flattenChemicalComponentBreakdownByFamily.length > manageIngredientStore.INITIAL_BREAKDOWN_LIMIT &&
														<div className="relative flex w-full flex-row justify-center border-b border-black py-1">
															<SmallBody>...</SmallBody>
														</div>
													}
													{manageIngredientStore.flattenChemicalComponentBreakdownByFamily.length > manageIngredientStore.INITIAL_BREAKDOWN_LIMIT && 
														<div className="flex w-full items-center justify-center mt-8">
															<Button
																width="w-1/3"
																height="h-12"
																className="px-4 rounded-lg"
																bgColor="pink-coral"
																onClick={() => manageIngredientStore.onShowChemicalComponentBreakdown()}
															>
																<div className="flex flex-row items-center justify-center">
																	<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-ingredient.chemical-breakdown.complete-breakdown')} &nbsp;</TertiaryAppSmallTitle></div>
																</div>
															</Button>
															
														</div>	
													}

													<BaseModal
											            isOpen={manageIngredientStore.showChemicalComponentBreakdown}
											            onClose={() => manageIngredientStore.onCloseChemicalComponentBreakdown()}
											            label="Chemical Component Breakdown"
													 >
												        	<div className="flex w-full h-full overflow-scroll bg-white">
												        		<div className="w-full mt-4 grid grid-cols-1  bg-white px-8 py-8 mt-8">
													        		{manageIngredientStore.chemicalComponentBreakdownByFamily.map((entry) => 
													        			<div className="flex w-full flex-col mt-2">
																			<ChemicalComponentHeader header={entry[0]} />
																			{entry[1].sort(sortByPercentage).map((entry) => 
																				<ChemicalComponentEntry key={entry.id} entry={entry} />
																			)}
																		</div>
													        		)}
													        	</div>
												        	</div>
											        </BaseModal>
												</div>
											</GridSection>
										</div>
										<div className="col-span-6 lg:col-span-3">
											<div className="col-span-6 lg:col-span-3">
												<GridSection>
													<div className="flex flex-row items-center">
														<SecondaryWebSmallTitle>{translate('user.manage-ingredient.chemical-families.title')}</SecondaryWebSmallTitle>
														<HelpTooltip text={manageIngredientStore.appStore.getTooltip('tooltip-ingredient-family-bio')} />
													</div>
													<PieChart
														labelCols={1}
														ref={(ref) => { this.chemicalFamilyPieChartRef = ref; }}
														fontSize={14}
														data={manageIngredientStore.chemicalFamilyPieData}
														onClick={(props) => manageIngredientStore.showFamilyDialog(props)}
													/>
												</GridSection>
											</div>
										</div>
									</>
								}


								{manageIngredientStore.showFattyAcidBreakdown && manageIngredientStore.flattenAcidComponentBreakdownByFamily.length > 0 &&
									<>
										<div className="col-span-6 lg:col-span-3">
											<GridSection>
												<SecondaryWebSmallTitle>{translate('user.manage-ingredient.acid-breakdown.title')}</SecondaryWebSmallTitle>
												<div className="flex flex-1 flex-col mt-4">
													{manageIngredientStore.flattenAcidComponentBreakdownByFamily.length == 0 &&
														<div className="flex w-full justify-center items-center">
															<SmallBody>{translate('user.manage-ingredient.acid-breakdown.nodata')}</SmallBody>
														</div>
													}
													{manageIngredientStore.flattenAcidComponentBreakdownByFamily.slice(0, manageIngredientStore.INITIAL_BREAKDOWN_LIMIT).map((entry) => 
														<div className="flex w-full flex-col mt-2">
															{entry.type == 'title' && <ChemicalComponentHeader header={entry.name} />}
															{entry.type == 'data' && 
																<ChemicalComponentEntry entry={entry.entry} key={entry.entry.id} />
															}
														</div>
													)}
													{manageIngredientStore.flattenAcidComponentBreakdownByFamily.length > manageIngredientStore.INITIAL_BREAKDOWN_LIMIT &&
														<div className="relative flex w-full flex-row justify-center border-b border-black py-1">
															<SmallBody>...</SmallBody>
														</div>
													}
													{manageIngredientStore.flattenAcidComponentBreakdownByFamily.length > manageIngredientStore.INITIAL_BREAKDOWN_LIMIT && 
														<div className="flex w-full items-center justify-center mt-8">
															<Button
																width="w-1/3"
																height="h-12"
																className="px-4 rounded-lg"
																bgColor="pink-coral"
																onClick={() => manageIngredientStore.onShowAcidComponentBreakdown()}
															>
																<div className="flex flex-row items-center justify-center">
																	<div className="flex"><TertiaryAppSmallTitle>{translate('user.manage-ingredient.acid-breakdown.complete-breakdown')} &nbsp;</TertiaryAppSmallTitle></div>
																</div>
															</Button>
															
														</div>	
													}

													<BaseModal
												        isOpen={manageIngredientStore.showAcidComponentBreakdown}
												        onClose={() => manageIngredientStore.onCloseAcidComponentBreakdown()}
												        label="Acid Component Breakdown"
													 >
												        	<div className="flex w-full h-full overflow-scroll bg-white">
												        		<div className="w-full mt-4 grid grid-cols-1 bg-white px-8 py-8 mt-8">
													        		{manageIngredientStore.acidComponentBreakdownByFamily.map((entry) => 
													        			<div className="flex w-full flex-col mt-2">
																			<ChemicalComponentHeader header={entry[0]} />
																			{entry[1].sort(sortByPercentage).map((entry) => 
																				<ChemicalComponentEntry key={entry.id} entry={entry} />
																			)}
																		</div>
													        		)}
													        	</div>
												        	</div>
												    </BaseModal>
												</div>
											</GridSection>
										</div>
										<div className="col-span-6 lg:col-span-3">
											<div className="col-span-3">
												<GridSection>
													<SecondaryWebSmallTitle>{translate('user.manage-ingredient.acid-families.title')}</SecondaryWebSmallTitle>
													<PieChart
														data={manageIngredientStore.acidFamilyPieData}
														ref={(ref) => { this.acidFamilyPieChartRef = ref; }}
														onClick={(props) => manageIngredientStore.showAcidFamilyDialog(props)}
													/>
												</GridSection>
											</div>
										</div>
									</>
								}
							</>
						}
						{manageIngredientStore.showIngredientEffects && 
							<>
								<div className="col-span-6 lg:col-span-2">
									<GridSection>
										<div className="flex flex-1 flex-col">
											<DataHeader>{translate('user.manage-ingredient.scent-notes.label')}</DataHeader>
											<div className="flex flex-col mt-4">
												{manageIngredientStore.scentNotes.map((scentNote) => 
													<div className="flex flex-1 flex-row items-center mt-2">
														<div className="flex">
															<RadioButton 
																disabled={false}
																checked={manageMode ? scentNote.id == manageIngredientStore.ingredientData.selectedScentNote.value : scentNote.id == currentIngredient?.scent_note?.id}
																onChange={(e) => {
																	this.markChange();
																	manageIngredientStore.ingredientData.selectedScentNote.onChange(scentNote.id)
																}}
																textColor={manageIngredientStore.selectedScentNoteColor}
																 />
														</div>
														<div className="flex ml-8"><Body>{scentNote.name}</Body></div>
													</div>
												)}
											</div>
										</div>
									</GridSection>
								</div>
								<div className="col-span-6 lg:col-span-2">
									<GridSection>
										<div className="flex flex-1 flex-col">
											<div className="flex flex-row items-center border-b border-black py-1">
												<DataHeader noborder>{translate('user.manage-ingredient.psychological-rankings.label')}</DataHeader>
												<HelpTooltip text={manageIngredientStore.appStore.getTooltip('tooltip-ingredient-psychological-effects')} />
											</div>
											{manageMode && 
												<div className="grid grid-cols-2 col-gap-8 row-gap-0 mt-4">
													{manageIngredientStore.ingredientData.psychologicalRankings.length > 0 && 
														<>
															<DataHeader noborder>{translate('user.manage-ingredient.psychological-rankings.effect.label')}</DataHeader>
															<DataHeader noborder>{translate('user.manage-ingredient.psychological-rankings.score.label')}</DataHeader>
														</>
													}
													{manageIngredientStore.ingredientData.psychologicalRankings.length == 0 &&
														<NoListItems text={translate('user.manage-ingredient.psychological-rankings.none-found')} />
													}
													{manageIngredientStore.ingredientData.psychologicalRankings.map((psychRanking) => 
														<>
															<FormSelect 
																placeholder={translate('user.manage-ingredient.psychological-rankings.effect.placeholder')}
																options={manageIngredientStore.psychologicalRankingsOptions}
																value={psychRanking.$.selectedEffect.value}
																onChange={(option) => {
																	this.markChange();
																	psychRanking.$.selectedEffect.onChange(option.value)
																}}
															/>
															<div className="flex flex-row items-center">
																<FormSelect 
																	placeholder={translate('user.manage-ingredient.psychological-rankings.score.placeholder')}
																	options={manageIngredientStore.scoreOptions}
																	value={psychRanking.$.selectedScore.value}
																	onChange={(option) => {
																		this.markChange();
																		psychRanking.$.selectedScore.onChange(option.value)
																	}}
																/>
																<div className="flex ml-4 flex-row items-center cursor-pointer" onClick={() => manageIngredientStore.deletePsychologicalRanking(psychRanking.$.uuid)}>
																	<DeleteIcon className="w-4 h-4" color={colors['error']} />
																</div>
															</div>
														</>
													)}
													
													<AddListButton onClick={() => manageIngredientStore.addPsychologicalRanking()} text={translate('user.manage-ingredient.psychological-rankings.add-button')} />
												</div>
											}
											{viewMode && 
												<TwoColumnReadList
													headers={
														[
															translate('user.manage-ingredient.psychological-rankings.effect.label'),
															translate('user.manage-ingredient.psychological-rankings.score.label')
														]
													}
													data={currentIngredient?.psychological_rankings.map((r) => [r.psychological_ranking?.name, r.score])}
													nodata={translate('user.manage-ingredient.psychological-rankings.nodata')}
												/>
											}
										</div>
									</GridSection>
								</div>
								<div className="col-span-6 lg:col-span-2">
									<GridSection>
										<div className="flex flex-1 flex-col">
											<div className="flex flex-row items-center border-b border-black py-1">
												<DataHeader noborder>{translate('user.manage-ingredient.physical-rankings.label')}</DataHeader>
												<HelpTooltip text={manageIngredientStore.appStore.getTooltip('tooltip-ingredient-physical-effects')} />
											</div>

											{manageMode && 
												<div className="grid grid-cols-2 col-gap-8 row-gap-0 mt-4">
													{manageIngredientStore.ingredientData.physicalRankings.length > 0 && 
														<>
															<DataHeader noborder>{translate('user.manage-ingredient.physical-rankings.effect.label')}</DataHeader>
															<DataHeader noborder>{translate('user.manage-ingredient.physical-rankings.score.label')}</DataHeader>
														</>
													}
													{manageIngredientStore.ingredientData.physicalRankings.length == 0 &&
														<NoListItems text={translate('user.manage-ingredient.physical-rankings.none-found')} />
													}
													{manageIngredientStore.ingredientData.physicalRankings.map((physicalRanking) => 
														<>
															<FormSelect 
																placeholder={translate('user.manage-ingredient.physical-rankings.effect.placeholder')}
																options={manageIngredientStore.physicalRankingsOptions}
																value={physicalRanking.$.selectedEffect.value}
																onChange={(option) => {
																	this.markChange();
																	physicalRanking.$.selectedEffect.onChange(option.value)
																}}
															/>
															<div className="flex flex-row items-center">
																<FormSelect 
																	placeholder={translate('user.manage-ingredient.physical-rankings.score.placeholder')}
																	options={manageIngredientStore.scoreOptions}
																	value={physicalRanking.$.selectedScore.value}
																	onChange={(option) => {
																		this.markChange();
																		physicalRanking.$.selectedScore.onChange(option.value)
																	}}
																/>
																<div className="flex flex-row ml-4 cursor-pointer" onClick={() => manageIngredientStore.deletePhysicalRanking(physicalRanking.$.uuid)}>
																	<DeleteIcon className="w-4 h-4" color={colors['error']} />
																</div>
															</div>
														</>
													)}

													<AddListButton onClick={() => manageIngredientStore.addPhysicalRanking()} text={translate('user.manage-ingredient.physical-rankings.add-button')} />
												</div>
											}
											{viewMode && 
												<TwoColumnReadList
													headers={
														[
															translate('user.manage-ingredient.physical-rankings.effect.label'),
															translate('user.manage-ingredient.physical-rankings.score.label')
														]
													}
													data={currentIngredient?.physical_rankings.map((r) => [r.physical_ranking?.name, r.score])}
													nodata={translate('user.manage-ingredient.physical-rankings.nodata')}
												/>
											}
										</div>
									</GridSection>
								</div>
							</>
						}
						

						<div className="col-span-6 lg:col-span-4">
							<GridSection>
								<div className="flex flex-1 flex-col">
									<DataHeader>{translate('user.manage-ingredient.ingredient-notes.label')}</DataHeader>
									{manageMode && 
										<FormTextArea 
											extraInputClassNames="h-full" 
											placeholder={translate('user.manage-ingredient.ingredient-notes.placeholder')}
											value={manageIngredientStore.ingredientData.ingredientNotes.value}
											error={manageIngredientStore.ingredientData.ingredientNotes.error}
											onChange={(e) => {
												this.markChange();
												manageIngredientStore.ingredientData.ingredientNotes.onChange(e.target.value)
											}}
										/>
									}
									{viewMode && 
										<DataEntry>{currentIngredient.ingredient_notes}</DataEntry>
									}
								</div>
							</GridSection>
						</div>
						
						<div className="col-span-6 lg:col-span-2">
							<GridSection>
								<div className="flex flex-1 flex-col">
									<div className="flex flex-row items-center border-b border-black py-1">
										<DataHeader noborder>{translate('user.manage-ingredient.files.label')}</DataHeader>
										<HelpTooltip text={manageIngredientStore.appStore.getTooltip('tooltip-ingredient-files')} />
									</div>
									
									{manageMode && 
										<>
											<div className="flex flex-1 flex-col">
												{manageIngredientStore.ingredientData.uploadedFiles.$.map((file) => 
													<FileEntry 
														file={file}
														editMode={true}
													/>
												)}
											</div>
											<UploadFiles
												t={this.props.t} 
												maxFileSize={2}
												description={
													(
														<>
															{translate('common.upload-files.valid-files.part1')} <br /> {translate('common.upload-files.valid-files.part2')} 
														</>
													)
												}
												onChangeFiles={(files) => {
													this.markChange();
													manageIngredientStore.onUploadFiles(files)
												}}
												onDeleteFile={() => console.log('delete')}
											 />
										
										</>
									}
									{viewMode && 
										<TwoColumnReadList
											headers={
												[
													translate('user.manage-ingredient.files.name.label'),
													translate('user.manage-ingredient.files.view.label')
												]
											}
											data={currentIngredient?.files.map((f) => [
												f.name, (
													<div className="flex flex-1 flex-row">
														<div className="flex cursor-pointer"><EyeIcon className="w-4 h-4" color={colors['black']} onClick={() => window.open(f.file_url)} /></div>
													</div>
													) 
												])}
											nodata={translate('user.manage-ingredient.files.nodata')}
										/>
									}
								</div>
							</GridSection>
						</div>
						{manageMode && 
							<div className="col-span-6 flex w-full justify-end mt-8">
								{saveBtn}
							</div>
						}
					</div>
				</div>
			</Main>
		)
	}
}
export default withTranslation()(ManageIngredient);