import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import {Heading, SubHeading, Body, LineRule} from '../common/text';

import {inject, observer} from 'mobx-react';

@observer
class Tutorial extends Component{
	constructor(props){
		super(props);
	}

	render(){
		let tutorial = this.props.tutorial;
		let videoId = tutorial.url.split('/').pop();
		return (
			<div className={`flex w-1/2 flex-col mb-16`}>
				<div className={`bg-white ${this.props.margin} py-8 px-16 border-2 border-black`}>
					<div className="flex flex-col h-64 sm:h-64 md:h-64 lg:h-24 mb-8">
						<SubHeading className={`text-center text-${this.props.color} font-bold`}>{tutorial.title}</SubHeading>
						<div className="flex flex-1 w-full mx-auto mt-4">
							<Body className="text-center">{tutorial.description}</Body>
						</div>
					</div>
					<div
				      className="video"
				      style={{
				      	display: 'flex',
				      	flex: 1,
				        position: "relative",
				        paddingBottom: "56.25%",
				        paddingTop: 25,
				        height: 0,

				      }}
				    >
				      <iframe
				        style={{
				          position: "absolute",
				          top: 0,
				          left: 0,
				          width: "100%",
				          height: "100%"
				        }}
				        src={`https://www.youtube.com/embed/${videoId}`}
				        frameBorder="0"
				      />
				    </div>
					<div className="flex flex-col h-16 mt-8 items-center">
						<div className={`flex justify-center text-${this.props.color} font-bold`}>
							{tutorial.created_on.format('Do MMMM YYYY')}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

@inject('trainingStore')
@observer
class Training extends Component{
	constructor(props){
		super(props);
		this.getTutorialColor = this.getTutorialColor.bind(this);
	}

	componentDidMount(){
		let trainingStore = this.props.trainingStore;
		trainingStore.fetchTutorials();
	}

	getTutorialColor(idx){
		let remainder = idx % 8;
		switch(remainder){
			case 0:
				return 'mid-green'
			case 1:
				return 'orange'
			case 2:
				return 'charcoal'
			case 3:
				return 'dark-blue'
			case 4:
				return 'mid-yellow'
			case 5:
				return 'pink'
			case 6:
				return 'dark-blue-80'
			case 7:
				return 'mid-green-80'
			default:
				return 'orange';
		}
	}

	render(){
		let trainingStore = this.props.trainingStore;
		let translate = this.props.t;
		return (
			<Fragment>
				<div className="flex flex-1 flex-col items-center w-full px-8 h-min-screen sm:h-auto">
					<div className="flex flex-1 flex-col w-full items-center bg-white py-8 px-8 mb-8 border-black border-2">
						<Heading className="text-mid-green">{translate('training.title')}</Heading>
						<div className="mt-8 w-1/2 text-center">
							<Body>{translate('training.description')}</Body>
						</div>
						<LineRule />
					</div>
					<div className="flex flex-1 flex-row flex-wrap w-full h-min-screen mt-8 -mx-4">
						{trainingStore.tutorials.map((tutorial, idx) => 
							<Tutorial 
								key={tutorial.id}
								margin={idx % 2 == 0 ? 'mr-6' : 'ml-6'}
								tutorial={tutorial}
								color={this.getTutorialColor(idx)}
								 />
						)}
					</div>
				</div>
			</Fragment>
		)
	}
}

export default withTranslation()(Training);