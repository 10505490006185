import { observable, action, computed } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isRequiredValidator, isHexColorValidator } from '../../../services/validation';

import CommonApi from '../../../services/common';
import ChemistryApi from '../../../services/chemistry';
import S3Api from '../../../services/s3';

class ManageChemistryFamily{
	appStore;

	id;

	@observable editMode;

	@observable familyData;

	@observable fetching;

	@observable imageFileUrl;
	@observable imageFile;

	@observable changedPhoto;

	commonApi;
	s3Api;


	constructor(appStore){
		this.appStore = appStore
		this.commonApi = new CommonApi(this.appStore);
		this.chemistryApi = new ChemistryApi(this.appStore);
		this.s3Api = new S3Api(this.appStore);
		this.initStore();
	}

	initStore(){
		this.familyData = {
			name: new FieldState(null).validators((val) => isRequiredValidator(val, this.appStore.i18n)),
			hexColor: new FieldState('#17273c').validators((val) => isHexColorValidator(val, this.appStore.i18n)),
			bio: new FieldState(null),
			molecularName: new FieldState(null)
		}
		this.familyDataForm = new FormState({
			name: this.familyData.name,
			hexColor: this.familyData.hexColor,
			bio: this.familyData.bio,
			molecularName: this.familyData.molecularName
		})
		this.editMode = false;
		this.fetching = false;
		this.imageFileUrl = null;
		this.imageFile = null;
		this.id = null;
	}

	@action changeMolecularImage(file){
		this.imageFile = file;
		this.imageFileUrl = URL.createObjectURL(file);
		this.changedPhoto = true;
	}

	@action deleteMolecularImage(){
		this.imageFileUrl = null;
		this.imageFile = null;
		this.changedPhoto = true;
	}

	@computed get validForm(){
		let requiredFields = ['name', 'hexColor'];
		let isValidForm = true;
		for(let field of requiredFields){
			if(this.familyData[field].value == null || this.familyData[field].value.length == 0){
				isValidForm = false;
			}else{
				let fieldError = this.familyData[field].error;
				isValidForm = isValidForm && (fieldError == null || fieldError == undefined);
			}
		}
		return isValidForm;
	}

	formSync(id){
		this.chemistryApi.getChemicalFamilyById(id)
			.then((response) => {
				let chemicalFamily = response;
				this.editMode = true;
				this.id = id;
				this.familyData.name.value = chemicalFamily.name;
				this.familyData.hexColor.value = chemicalFamily.hex_color;
				this.familyData.bio.value = chemicalFamily.bio;
				this.imageFileUrl = chemicalFamily.photo_url;
				this.familyData.molecularName.value = chemicalFamily.molecular_name;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	handleSuccess(response){
		let signedUrlPayload = response.presigned_url;
		if(signedUrlPayload == null){
			this.appStore.displaySaveDialog();
			this.appStore.goToChemistrySettings();
			this.fetching = false;
		}else{
			this.s3Api.uploadToS3(this.imageFile, signedUrlPayload)
				.then((response) => {
					this.appStore.displaySaveDialog();
					this.appStore.goToChemistrySettings();
				})
				.catch((error) => {
					this.appStore.alertError(this.appStore.i18n.t('error.uploading-photo-message'));
				})
				.finally(() => {
					this.fetching = false;
				});
		}
	}

	saveChemistryFamily = async () => {
		let res = await this.familyDataForm.validate()
		if(res.hasError) return;

		this.fetching = true;

		
		if(this.editMode){
			let chemicalFamilyPayload = {
				name: this.familyData.name.value,
				hex_color: this.familyData.hexColor.value,
				bio: this.familyData.bio.value,
				has_photo: this.imageFile != null,
				changed_photo: this.changedPhoto,
				file_type: this.imageFile != null ? this.imageFile.type : null,
				molecular_name: this.familyData.molecularName.value
			}
			this.chemistryApi.updateChemicalFamily(this.id, chemicalFamilyPayload)
				.then((response) => {
					this.handleSuccess(response);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}else{
			let chemicalFamilyPayload = {
				name: this.familyData.name.value,
				hex_color: this.familyData.hexColor.value,
				bio: this.familyData.bio.value,
				has_photo: this.imageFile != null,
				file_type: this.imageFile != null ? this.imageFile.type : null,
				molecular_name: this.familyData.molecularName.value
			}
			this.chemistryApi.newChemicalFamily(chemicalFamilyPayload)
				.then((response) => {
					this.handleSuccess(response)
				})
				.catch((error) => {
					console.log(error);
					this.fetching = false;
				})
				.finally(() => {
					this.fetching = false;
				})
		}

		
	}
}

export default ManageChemistryFamily;