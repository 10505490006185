import { observable, action, computed, autorun } from 'mobx';

import { FieldState, FormState } from 'formstate';

import { isPasswordValidator, validator } from '../../services/validation';
import UserApi from '../../services/user';

class ResetPasswordStore{
	appStore;

	@observable password;
	@observable confirmPassword;

	token;

	userApi; 
	form;
	@observable isValidForm;

	constructor(appStore){
		this.appStore = appStore;
		this.userApi = new UserApi(appStore);
		this.initStore();
	}

	initStore(){
		this.password = new FieldState('').validators((val) => isPasswordValidator(val, this.appStore.i18n,this.appStore.i18n.t('validation.password')));
		this.confirmPassword = new FieldState('').validators((val) => this.password.value.length > 0 && val != this.password.value && this.appStore.i18n.t('validation.non-matching-password-message'));
		this.form = new FormState({
			password: this.password,
			confirmPassword: this.confirmPassword,
		});
		this.isValidForm = false;
		this.fetching = false;
	}

	setToken(token){
		this.token = token;
	}

	@action onChangePassword(val){
		this.password.onChange(val);
		this.validateForm();
	}

	@action onChangeConfirmPassword(val){
		this.confirmPassword.onChange(val);
		this.validateForm();
	}

	validateForm(){
		this.form.validate()
			.then((res) => {
				this.isValidForm = !res.hasError;
			})
	}

	resetPassword(){
		this.userApi.resetPassword(this.token, this.password.value)
			.then((response) => {
				this.appStore.alertSuccess(this.appStore.i18n.t('success.password-reset-message'));
				this.appStore.goToLogin();
			})
			.catch((error) => {
				console.log(error);
			})
	}

	
}

export default ResetPasswordStore;
