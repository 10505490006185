import React, {Component} from 'react';

import { withTranslation } from 'react-i18next';

import {inject, observer} from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroll-component';

import Main from '../../elements/main';
import { SmallBody, Body, SecondaryAppLargeTitle, TertiaryAppSmallTitle, SecondaryAppMediumTitle, ExtraSmallBody} from '../../common/text';
import { TestTubeIcon, ShareIcon, SearchIcon, ArchiveIcon, ArrowRightIcon, PlusIcon, EditIcon, DuplicateIcon} from '../../common/icon'
import {Button} from '../../common/button';
import {SortDialog, FilterField, FilterDialog, SortingField, ListTile} from '../../elements/list';
import {TagInput, AddTag} from '../../common/tag';
import {ToolTipItem} from '../../common/input';
import BaseModal from '../../common/modal';
import BlendPreview from '../../user/blends/blend-preview';
import {
	FormInput,
	FormSelect,
	FormTextArea,
} from '../../elements/form';

import { SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR } from '../../../services/util';
import { withAuthorisation } from '../../common/authorisation';

const colors = require('../../../color.js');

@observer
class ExpandedBlendLibraryCategory extends Component{
	render(){
		let blendLibraryCategory = this.props.blendLibraryCategory;
		let translate = this.props.t;
		let currentBlends = this.props.currentBlends;
		return (
			<div className="flex flex-1 flex-col">
				<div className="flex flex-row rounded-lg h-32">
					<div className="flex w-1/5 rounded-l-lg" style={{ backgroundColor: blendLibraryCategory.hex_color}}>
					</div>
					<div className="flex flex-1 flex-col justify-center py-4 bg-white rounded-r-lg px-4">
						<div className="flex"><TertiaryAppSmallTitle>{blendLibraryCategory.name}</TertiaryAppSmallTitle></div>
						<div className="flex mt-1"><SmallBody>{blendLibraryCategory.bio?.substring(0, 100)}</SmallBody></div>
						<div className="flex mt-1"><ExtraSmallBody className="italic">{blendLibraryCategory.blends.length} {translate('org-admin.library-categories.blends')}</ExtraSmallBody></div>
						
						<div className="flex flex-col w-full relative items-end mt-1">
							<ToolTipItem
							 	onClick={() => this.props.onArchive(blendLibraryCategory.id)}
							 	title={translate('org-admin.library-categories.blends.archive-tooltip.title')}
							 	text={translate('org-admin.library-categories.blends.archive-tooltip.text')}
							 	icon={<ArchiveIcon className="w-4 h-4" color={colors['black']} />}
							 />
						</div>
					</div>
				</div>
				<div className="flex flex-1 flex-col">
					{currentBlends.length == 0 && 
						<div className="flex flex-1 flex-col items-center mt-8">
							<Body>{translate('org-admin.library-categories.no-blends')}</Body>
						</div>
					}
					<InfiniteScroll
					  dataLength={currentBlends.length}
					  next={() => this.props.loadNextBlendsPage()}
					  hasMore={this.props.hasMoreBlends}
					  loader={null}
					  endMessage={null}
					>
						{currentBlends.map((blend) => 
							<BlendPreview 
								t={translate} 
								canManage={false} 
								blend={blend}
								onView={(id) => this.props.onViewBlend(id)}
							/>
						)}
					</InfiniteScroll>
				</div>
			</div>
		);
	}
}


@inject('libraryCategoriesStore')
@observer
class LibraryCategories extends Component{
	componentDidMount(){
		let libraryCategoriesStore = this.props.libraryCategoriesStore;
		libraryCategoriesStore.fetchLibraryCategories();
	}

	componentWillUnmount(){
		let libraryCategoriesStore = this.props.libraryCategoriesStore;
		libraryCategoriesStore.initStore();
	}

	render(){
		let translate = this.props.t;
		let libraryCategoriesStore = this.props.libraryCategoriesStore;

		let filterView = (
			<div className="relative flex flex-row items-center">
				<FilterField text={translate('org-admin.library-categories.filter')} onClick={() => libraryCategoriesStore.toggleFilterDialog()}/>
				{libraryCategoriesStore.showFilterDialog && 
							<FilterDialog
							 	t={translate}
							 	onApply={() => libraryCategoriesStore.applyFilters()}
							>
								<div className="flex flex-col w-32">
									<div className="flex"><TertiaryAppSmallTitle>{translate('org-admin.library-categories.headers.tags')}</TertiaryAppSmallTitle></div>
									<div className="flex">
										<TagInput
										 inputValue={libraryCategoriesStore.currentFilterValue}
										 onInputChange={(val) => libraryCategoriesStore.setCurrentFilterValue(val)}
										 t={translate}
										 onChange={(values) => libraryCategoriesStore.setFilters(values)}
										 onNewEntry={(val) => libraryCategoriesStore.newFilterEntry(val)}
										 values={libraryCategoriesStore.currentFilters} 
										/>
									</div>
								</div>
							</FilterDialog>
						}
			</div>
		);

		return (
			<Main>
				<div className="flex flex-1 flex-col">
					<div className="flex w-full xl:w-3/5 flex-row items-center mt-8">
						<div className="flex flex-1 flex-col">
							<SecondaryAppLargeTitle className="text-nav-active-color">{translate('org-admin.library-categories.tagline')}</SecondaryAppLargeTitle>
							<SmallBody>{translate('org-admin.library-categories.paragraph.part1')} <br /> {translate('org-admin.library-categories.paragraph.part2')}</SmallBody>
							
							<div className="flex flex-1 flex-row items-center mt-8">
								<div className="flex"><TertiaryAppSmallTitle className="text-nav-active-color">{translate('org-admin.library-categories.video-tutorial')} </TertiaryAppSmallTitle></div>
								<div className="flex ml-2"><ArrowRightIcon color={colors['nav-active-color']} /></div>
							</div>
						</div>
					</div>
					{libraryCategoriesStore.selectedBlendLibraryCategory == null && 
						<>
							<div className="flex w-full justify-end mt-8 lg:mt-2 ">
								<div className="flex ml-4">
									<Button
										width="w-auto"
										height="h-12"
										className="px-4 rounded-lg"
										bgColor="pink-coral"
										onClick={() => libraryCategoriesStore.onNewLibraryCategory()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('org-admin.library-categories.new-button')} &nbsp;</TertiaryAppSmallTitle></div>
											<div className="flex"><PlusIcon /></div>
										</div>
									</Button>
								</div>
							</div>
							<div className="relative flex flex-row justify-end items-center mt-4">
								<div className="flex ml-8">
									<div className="relative flex flex-row items-center">
										<SortingField 
											text={translate('org-admin.library-categories.sort')}
											onClick={() => libraryCategoriesStore.toggleSortDialog()}
										/>
										{libraryCategoriesStore.showSortDialog && 
												<SortDialog
												 	t={translate}
													sortOptions={libraryCategoriesStore.sortOptions}
													currentOption={libraryCategoriesStore.sortBy}
													onOption={(val) => libraryCategoriesStore.setSortByField(val)}
													onApply={(val) => libraryCategoriesStore.applySort()}
												/>
											}
									</div>
								</div>
							</div>
							<InfiniteScroll
							  dataLength={libraryCategoriesStore.libraryCategories.length}
							  next={() => libraryCategoriesStore.loadNextLibrariesPage()}
							  hasMore={libraryCategoriesStore.hasMoreLibraries}
							  loader={null}
							  endMessage={null}
							>
								<div className="flex w-full grid grid-cols-1 lg:grid-cols-4 col-gap-8 row-gap-16 mt-8">
									{libraryCategoriesStore.libraryCategories.map((blendLibraryCategory) => 
										<ListTile
											key={blendLibraryCategory.id}
											id={blendLibraryCategory.id}
											name={blendLibraryCategory.name}
											hexColor={blendLibraryCategory.hex_color}
											t={translate} 
											description={`${blendLibraryCategory.blends.length} ${translate('org-admin.library-categories.blends')}`}
											viewDescription={translate('org-admin.library-categories.view-blends')}
											onEdit={(id) => libraryCategoriesStore.onEditLibraryCategory(id)}
											onView={(id) => libraryCategoriesStore.onSelectLibraryCategory(id)}
											onArchive={(id) => window.alert(id)}
											/>
									)}
									{libraryCategoriesStore.libraryCategories.length == 0 && !libraryCategoriesStore.fetching &&
										<div className="col-span-4 flex justify-center mt-8">
											<Body>{translate('org-admin.library-categories.no-blend-library-categories')}</Body>
										</div>
									}
									{libraryCategoriesStore.libraryCategories.length == 0 && libraryCategoriesStore.fetching &&
										<div className="col-span-4 flex justify-center mt-8">
											<Body>{translate('org-admin.library-categories.fetching-blend-library-categories')}</Body>
										</div>
									}
								</div>
							</InfiniteScroll>
						</>
					}
					{libraryCategoriesStore.selectedBlendLibraryCategory != null &&
						<>
							<div className="flex w-full justify-end mt-2">
								<div className="flex ml-2">
									<Button
										width="w-auto"
										height="h-12"
										className="ml-4 px-4 rounded-lg text-pink-coral"
										bgColor="bg-list-text"
										onClick={() => libraryCategoriesStore.onBackToCategories()}
									>
										<div className="flex flex-row items-center justify-center">
											<div className="flex"><TertiaryAppSmallTitle>{translate('org-admin.library-categories.back-button')} &nbsp;</TertiaryAppSmallTitle></div>
										</div>
									</Button>
								</div>
								<div className="flex flex-row ml-4">
									<div className="flex">
										<Button
											width="w-auto"
											height="h-12"
											className="px-4 rounded-lg"
											bgColor="pink-coral"
											onClick={() => libraryCategoriesStore.onShareCurrentLibraryCategory()}
										>
											<div className="flex flex-row items-center justify-center">
												<div className="flex"><TertiaryAppSmallTitle>{translate('org-admin.library-categories.share-button')} &nbsp;</TertiaryAppSmallTitle></div>
												<div className="flex"><ShareIcon className="w-4 h-4" color={colors['white']} /></div>
											</div>
										</Button>
									</div>
									<div className="flex ml-4">
										<Button
											width="w-auto"
											height="h-12"
											className="px-4 rounded-lg"
											bgColor="pink-coral"
											onClick={() => libraryCategoriesStore.onEditLibraryCategory(libraryCategoriesStore.selectedBlendLibraryCategory.id)}
										>
											<div className="flex flex-row items-center justify-center">
												<div className="flex"><TertiaryAppSmallTitle>{translate('org-admin.library-categories.edit-button')} &nbsp;</TertiaryAppSmallTitle></div>
												<div className="flex"><EditIcon id={libraryCategoriesStore.selectedBlendLibraryCategory.id} className="w-4 h-4" color={colors['white']} /></div>
											</div>
										</Button>
									</div>
								</div>
							</div>
							<div className="flex flex-1 flex-col mt-8">
								<ExpandedBlendLibraryCategory
									t={translate}
									blendLibraryCategory={libraryCategoriesStore.selectedBlendLibraryCategory}
									currentBlends={libraryCategoriesStore.currentBlends}
									hasMoreBlends={libraryCategoriesStore.hasMoreBlends}
									loadNextBlendsPage={() => libraryCategoriesStore.loadNextBlendsPage()}
									onViewBlend={(id) => libraryCategoriesStore.onViewBlend(id)}
									onArchive={(id) => libraryCategoriesStore.onArchiveBlendLibraryCategory(id)}
								/>
							</div>
						</>
					}
					<BaseModal
					    isOpen={libraryCategoriesStore.showShareModal}
					    onClose={() => libraryCategoriesStore.closeShareModal()}
					    label="Share Library Category"
					>
						<div className="flex flex-1 flex-col lg:flex-row rounded-lg">
							<div className="flex flex-1 flex-col bg-white rounded-lg">
								<div className="flex flex-1 flex-col px-12 mt-16">
									<div className="flex w-full justify-center border-b border-black py-4">
										<SecondaryAppMediumTitle className="text-nav-active-color">{libraryCategoriesStore.selectedBlendLibraryCategory?.name} - {translate('org-admin.library-categories.share-modal.title')}</SecondaryAppMediumTitle>
									</div>
									<div className="flex border-b border-black py-4">
										<FormTextArea 
											placeholder={translate('org-admin.library-categories.share-modal.notification.placeholder')}
											value={libraryCategoriesStore.notificationText.value}
											error={libraryCategoriesStore.notificationText.error}
											onChange={(e) => libraryCategoriesStore.notificationText.onChange(e.target.value)}
										/>
									</div>
									<div className="flex flex-row items-center mt-4">
										<FormInput 
												insetoption={
													<SearchIcon className='w-4 h-4' colors={colors['nav-inactive-color']} />
												}
												placeholder={translate('org-admin.library-categories.share-modal.email.placeholder')}
												value={libraryCategoriesStore.currentUserEmail.value}
												error={libraryCategoriesStore.currentUserEmail.error}
												onChange={(e) => libraryCategoriesStore.currentUserEmail.onChange(e.target.value)}
											/>
										{libraryCategoriesStore.currentUserEmail.value != null && 
											<div className="flex flex-row items-center ml-4" onClick={() => libraryCategoriesStore.addNewUserEmail()}>
												<div className="flex"><PlusIcon className="w-8 h-8" color="black" /></div>
												<div className="flex cursor-pointer">
													<TertiaryAppSmallTitle>{translate('org-admin.manage-library-categories.users.label')}</TertiaryAppSmallTitle>
												</div>
											</div>
										}
									</div>
									<div className="grid grid-cols-2 gap-4 mt-2">
										{libraryCategoriesStore.selectedBlendLibraryCategory?.user_emails.map((entry) => 
											<AddTag
												id={entry.user_email}
												key={entry.user_email}
												text={entry.user_email} 
												canDelete
												onDelete={(userEmail) => libraryCategoriesStore.deleteUserEmailFromBlendCategory(userEmail)}
											/>
										)}
									</div>
									{libraryCategoriesStore.selectedBlendLibraryCategory?.user_emails.length > 0 &&  libraryCategoriesStore.canShareBlendLibraryCategory &&
										<div className="flex w-full justify-center mt-8">
											<Button
												width="w-64"
												height="h-12"
												className="px-4 rounded-lg"
												bgColor="pink-coral"
												fetching={libraryCategoriesStore.fetching}
												onClick={() => libraryCategoriesStore.shareCurrentLibraryCategory()}
											>
												<div className="flex flex-row items-center justify-center">
													<div className="flex"><TertiaryAppSmallTitle>{translate('org-admin.library-categories.share-modal.share-button')} &nbsp;</TertiaryAppSmallTitle></div>
												</div>
											</Button>
										</div>
									}
								</div>
							</div>
						</div>
					</BaseModal>		
				</div>
			</Main>
		)
	}
}

export default withTranslation()(withAuthorisation(LibraryCategories, [SUPER_ADMINISTRATOR, ORGANISATION_ADMINISTRATOR]));
