import React, { Component } from 'react';
import {inject, observer} from 'mobx-react';

@observer
class ViewPDF extends Component{
	render(){
		let encodedURL = window.encodeURIComponent(this.props.url); 
		return (
			<object data={this.props.url} type={this.props.type} style={this.props.style}>
				<embed src={this.props.url} type={this.props.type} />
			</object>
		)
	}
}

export default ViewPDF;